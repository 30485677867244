const inactivityTime = 60 * 1000 // every minute

export class ElmWebsocketWrapper {
  #connections;
  #lastID = 0;
  #incomingMsgPort;

  constructor(outgoingPort, incomingPort) {
    this.#connections = new Map()
    this.#incomingMsgPort = incomingPort

    outgoingPort.subscribe((message) => {
      switch (message.command) {
        case "open":
          this.#openPrivate(message.url, message.authToken)
          break

        case "send":
          if (this.#connections.has(message.id)) {
            console.log("sending", message.data)
            this.#connections.get(message.id).socket.send(JSON.stringify(message.data))
          }
          break

        case "health":
          console.log(this.#connections)
          if (this.#connections.has(message.id)) {
            this.#connections.get(message.id).lastChecked = Date.now()
          }
          break

        case "close":
          this.#close(message.id)
          break

        default:
          console.log(`error: unexpected WS command: ${message.command}`)
          break
      }

    })
  }

  #openPrivate(url, wsToken) {
    // assuming all the WS connections are made by managers
    let ws = new WebSocket(`wss://${window.location.host}/${url}?token=${wsToken}`)

    let id = this.#lastID++

    ws.onopen = () => console.log(`Connected WS #${id}`);

    ws.onmessage = (evt) => {
      console.log(JSON.parse(evt.data))
      this.#incomingMsgPort.send({
        id: id,
        from: "receive",
        data: JSON.parse(evt.data),
      })
    }

    ws.onerror = (evt) => {
      console.log(evt)
      this.#incomingMsgPort.send({
        id: id,
        from: "error",
        // FIXME
        data: evt,
      })
    }

    ws.onclose = (evt) => this.#incomingMsgPort.send({
      id: id,
      from: "close",
      data: evt.reason,
    })

    let wsInfo = {
      socket: ws,
      lastChecked: Date.now(),
      healthChecker: null,
    }
    this.#connections.set(id, wsInfo)

    wsInfo.healthChecker = setInterval(() => {
      let missing = !this.#connections.has(id)
      let notResponding = Date.now() - wsInfo.lastChecked > inactivityTime
      if (missing || notResponding) {
        this.#connections.delete(id)
        clearInterval(wsInfo.healthChecker)
        ws.close()
      }
    }, inactivityTime)

    this.#incomingMsgPort.send({
      id: id,
      url: url,
      from: "open",
    })
  }

  #close(id) {
    if (!this.#connections.has(id)) {
      this.#incomingMsgPort.send({
        id: id,
        from: "close",
        error: "could not close - does not exist",
      })
      return
    }

    this.#connections.get(id).socket.close()
    this.#incomingMsgPort.send({
      id: id,
      from: "close",
      error: "could not close - does not exist",
    })
  }
};
