import tinymce from "tinymce/tinymce"
import "tinymce-i18n/langs7/ru"
import "tinymce/themes/silver/theme"
import "tinymce/models/dom/model"

class RichEditor extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    // tinymce gets confused about Elm's DOM manipulations
    tinymce.remove() // remove all editors (yeah, a dirty hack)

    const initContent = this.getAttribute("initial-content")

    const textarea = document.createElement("textarea")
    textarea.id = this.id + "-textarea"
    textarea.innerHTML = initContent

    const shadow = this.attachShadow({ mode: 'open' })
    shadow.appendChild(textarea)

    tinymce.init({
      base_url: 'https://cdn.jsdelivr.net/npm/tinymce@7.3.0/', // NOTE: keep the version matching
      branding: true,
      language: 'ru',
      language_load: false,
      license_key: "gpl",
      menubar: false,
      plugins: "image fullscreen",
      promotion: false,
      target: textarea,
      toolbar: [
        'undo redo',
        'blocks',
        'bold italic',
        'alignleft aligncenter alignright alignjustify',
        'outdent indent',
        'image',
        'fullscreen'
      ].join("|")
    })
  }
}

export function registerRichEditor(fromElmPort, toElmPort) {
  window.customElements.define('rich-editor', RichEditor)
  fromElmPort.subscribe(function(id) {
    const content = tinymce.get(`${id}-textarea`).getContent()
    toElmPort.send(content)
  })
}
