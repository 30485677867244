import L from 'leaflet/dist/leaflet'
import 'leaflet/dist/leaflet.css'
import MarkerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import MarkerIcon from 'leaflet/dist/images/marker-icon.png'
import MarkerShadow from 'leaflet/dist/images/marker-shadow.png'

import route from './route.json'

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: MarkerIcon2x,
  iconUrl: MarkerIcon,
  shadowUrl: MarkerShadow,
});

async function getCarPositions() {
  try {
    const response = await fetch("/minibuses/positions")
    if (!response.ok) {
      console.error("could not get minibus positions:", response);
      return {}
    }

    return await response.json()
  } catch (err) {
    return {}
  }
}


function busIcon(course, timestamp, online) {
  const timeDiffInSeconds = Math.floor((Date.now() - Date.parse(timestamp)) / 1000)
  const seconds = timeDiffInSeconds % 60
  const minutes = Math.floor(timeDiffInSeconds / 60)
  const hours = Math.floor(timeDiffInSeconds / (60 * 60))

  const values = hours == 0 ? [minutes, seconds] : [hours, minutes, seconds]
  const duration = values.map(x => String(x).padStart(2, '0')).join(':')

  return L.divIcon({
    className: `map-div-icon${online ? '' : ' map-offline'} material-symbols-outlined`,
    html: `<span>directions_bus</span>
<div style="rotate:${course - 90}deg;"><span>play_arrow</span></div>
<time class="ff-days-onesans-serif">
  <svg>
    <g>
      <rect x="0%" y="0%" width="100%" height="100%" rx="5%"></rect>
      <text y="50%" x="50%" dominant-baseline="middle" text-anchor="middle">${duration}</text>
    </g>
  </svg>
</time>`,
    iconSize: [38, 38],
    iconAnchor: [19, 19]
  });
}


class MinibusMap extends HTMLElement {
  constructor() {
    super()
    this.minibusPositions = {}
  }

  connectedCallback() {
    this.innerHTML = ``
    const mapDiv = document.createElement("div")
    mapDiv.setAttribute("id", "minibusMap")
    mapDiv.setAttribute("style", "width: 100%; height: 100%; z-index: 0;")
    this.appendChild(mapDiv)

    const map = L.map(mapDiv.id);
    const defaultCenter = [52.025, 113.4];
    const defaultZoom = 14;

    const southWest = L.latLng(51.96, 113.32)
    const northEast = L.latLng(52.09, 113.61)
    map.setMaxBounds(L.latLngBounds(southWest, northEast))

    map.setMaxZoom(18)
    map.setMinZoom(12)

    const basemap = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      detectRetina: true,
    });
    basemap.addTo(map);

    var geojsonMarkerOptions = {
      radius: 5,
      fillColor: "#ff7800",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.0
    };

    L.geoJSON(route, {
      style: {
        "color": "#aa3030",
        "weight": 8,
        "opacity": 0.5
      },
      pointToLayer: function(feature, latlng) {
        return L.circleMarker(latlng, geojsonMarkerOptions);
      }
    }).addTo(map);

    map.setView(defaultCenter, defaultZoom);

    const carMarkers = new Map();

    getCarPositions().then(carPositions => {
      this.minibusPositions = carPositions

      for (const [id, car] of Object.entries(carPositions)) {
        const marker = L.marker([car.lat, car.lon], {
          icon: busIcon(car.course, car.time, true),
        });
        carMarkers.set(id, marker);
        marker.addTo(map);
      }

      setInterval(() => {
        getCarPositions().then(newPositions => {
          let valid = true
          for (const [id, marker] of carMarkers) {
            let source = newPositions
            if (!(id in newPositions)) {
              source = this.minibusPositions
              valid = false
            }

            marker.
              setLatLng([source[id].lat, source[id].lon]).
              setIcon(busIcon(source[id].course, source[id].time, valid)).
              update(carMarkers)
          }

          if (valid) {
            this.minibusPositions = newPositions
          }
        })
      }, 5000)
    });


    // sucks having to remove this, but here we go
    map.attributionControl.setPrefix('&copy <a href="https://github.com/Leaflet/Leaflet/blob/main/LICENSE">Leaflet</a>')
  }
}

export function registerMinibusMap() {
  window.customElements.define('minibus-map', MinibusMap)
}
