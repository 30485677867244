(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}

console.warn('Compiled in DEV mode. Follow the advice at https://elm-lang.org/0.19.1/optimize for better performance and smaller assets.');


// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**_UNUSED/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**_UNUSED/
	if (typeof x.$ === 'undefined')
	//*/
	/**/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0_UNUSED = 0;
var _Utils_Tuple0 = { $: '#0' };

function _Utils_Tuple2_UNUSED(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3_UNUSED(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr_UNUSED(c) { return c; }
function _Utils_chr(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil_UNUSED = { $: 0 };
var _List_Nil = { $: '[]' };

function _List_Cons_UNUSED(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log_UNUSED = F2(function(tag, value)
{
	return value;
});

var _Debug_log = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString_UNUSED(value)
{
	return '<internals>';
}

function _Debug_toString(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash_UNUSED(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.start.line === region.end.line)
	{
		return 'on line ' + region.start.line;
	}
	return 'on lines ' + region.start.line + ' through ' + region.end.line;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap(value) { return { $: 0, a: value }; }
function _Json_unwrap(value) { return value.a; }

function _Json_wrap_UNUSED(value) { return value; }
function _Json_unwrap_UNUSED(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**_UNUSED/
	var node = args['node'];
	//*/
	/**/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**_UNUSED/''//*//**/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**_UNUSED/''//*//**/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**_UNUSED/''//*//**/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		message: func(record.message),
		stopPropagation: record.stopPropagation,
		preventDefault: record.preventDefault
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.message;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.stopPropagation;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.preventDefault) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var view = impl.view;
			/**_UNUSED/
			var domNode = args['node'];
			//*/
			/**/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.setup && impl.setup(sendToApp)
			var view = impl.view;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.body);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.title) && (_VirtualDom_doc.title = title = doc.title);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.onUrlChange;
	var onUrlRequest = impl.onUrlRequest;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		setup: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.protocol === next.protocol
							&& curr.host === next.host
							&& curr.port_.a === next.port_.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		init: function(flags)
		{
			return A3(impl.init, flags, _Browser_getUrl(), key);
		},
		view: impl.view,
		update: impl.update,
		subscriptions: impl.subscriptions
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hidden: 'hidden', change: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hidden: 'mozHidden', change: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hidden: 'msHidden', change: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hidden: 'webkitHidden', change: 'webkitvisibilitychange' }
		: { hidden: 'hidden', change: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		scene: _Browser_getScene(),
		viewport: {
			x: _Browser_window.pageXOffset,
			y: _Browser_window.pageYOffset,
			width: _Browser_doc.documentElement.clientWidth,
			height: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		width: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		height: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			scene: {
				width: node.scrollWidth,
				height: node.scrollHeight
			},
			viewport: {
				x: node.scrollLeft,
				y: node.scrollTop,
				width: node.clientWidth,
				height: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			scene: _Browser_getScene(),
			viewport: {
				x: x,
				y: y,
				width: _Browser_doc.documentElement.clientWidth,
				height: _Browser_doc.documentElement.clientHeight
			},
			element: {
				x: x + rect.left,
				y: y + rect.top,
				width: rect.width,
				height: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.multiline) { flags += 'm'; }
	if (options.caseInsensitive) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.expect.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.expect.b, xhr)); });
		$elm$core$Maybe$isJust(request.tracker) && _Http_track(router, xhr, request.tracker.a);

		try {
			xhr.open(request.method, request.url, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.url));
		}

		_Http_configureRequest(xhr, request);

		request.body.a && xhr.setRequestHeader('Content-Type', request.body.a);
		xhr.send(request.body.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.headers; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.timeout.a || 0;
	xhr.responseType = request.expect.d;
	xhr.withCredentials = request.allowCookiesFromOtherDomains;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		url: xhr.responseURL,
		statusCode: xhr.status,
		statusText: xhr.statusText,
		headers: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			sent: event.loaded,
			size: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			received: event.loaded,
			size: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $author$project$Main$UrlChanged = function (a) {
	return {$: 'UrlChanged', a: a};
};
var $author$project$Main$UrlRequested = function (a) {
	return {$: 'UrlRequested', a: a};
};
var $elm$core$Basics$EQ = {$: 'EQ'};
var $elm$core$Basics$GT = {$: 'GT'};
var $elm$core$Basics$LT = {$: 'LT'};
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0.a;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (node.$ === 'SubTree') {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 'Err', a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 'Failure', a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 'Field', a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 'Index', a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 'Ok', a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 'OneOf', a: a};
};
var $elm$core$Basics$False = {$: 'False'};
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 'Just', a: a};
};
var $elm$core$Maybe$Nothing = {$: 'Nothing'};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 'Field':
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 'Nothing') {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'Index':
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'OneOf':
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 'Array_elm_builtin', a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 'Leaf', a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 'SubTree', a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.nodeListSize) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.tail);
		} else {
			var treeLen = builder.nodeListSize * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.nodeList) : builder.nodeList;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.nodeListSize);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.tail);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{nodeList: nodeList, nodeListSize: (len / $elm$core$Array$branchFactor) | 0, tail: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = {$: 'True'};
var $elm$core$Result$isOk = function (result) {
	if (result.$ === 'Ok') {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 'Normal':
			return 0;
		case 'MayStopPropagation':
			return 1;
		case 'MayPreventDefault':
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 'External', a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 'Internal', a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = function (a) {
	return {$: 'NotFound', a: a};
};
var $elm$url$Url$Http = {$: 'Http'};
var $elm$url$Url$Https = {$: 'Https'};
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {fragment: fragment, host: host, path: path, port_: port_, protocol: protocol, query: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 'Nothing') {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Http,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Https,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0.a;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = function (a) {
	return {$: 'Perform', a: a};
};
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(_Utils_Tuple0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0.a;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return _Utils_Tuple0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(_Utils_Tuple0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0.a;
		return $elm$core$Task$Perform(
			A2($elm$core$Task$map, tagger, task));
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			$elm$core$Task$Perform(
				A2($elm$core$Task$map, toMessage, task)));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Framework$ClearLocalStorage = {$: 'ClearLocalStorage'};
var $author$project$UI$Navbar$Closed = {$: 'Closed'};
var $mdgriffith$elm_ui$Element$Device = F2(
	function (_class, orientation) {
		return {_class: _class, orientation: orientation};
	});
var $author$project$Main$DeviceClassified = function (a) {
	return {$: 'DeviceClassified', a: a};
};
var $author$project$Main$EffectMsg = function (a) {
	return {$: 'EffectMsg', a: a};
};
var $author$project$Main$EndOnSessionRefreshed = {$: 'EndOnSessionRefreshed'};
var $author$project$Session$Guest = {$: 'Guest'};
var $author$project$Main$Model = F5(
	function (page, navbar, modal, websockets, popUps) {
		return {modal: modal, navbar: navbar, page: page, popUps: popUps, websockets: websockets};
	});
var $mdgriffith$elm_ui$Element$Phone = {$: 'Phone'};
var $mdgriffith$elm_ui$Element$Portrait = {$: 'Portrait'};
var $author$project$Session$Session = F2(
	function (a, b) {
		return {$: 'Session', a: a, b: b};
	});
var $author$project$Main$SessionRefreshed = function (a) {
	return {$: 'SessionRefreshed', a: a};
};
var $author$project$Main$Waiting = F4(
	function (a, b, c, d) {
		return {$: 'Waiting', a: a, b: b, c: c, d: d};
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			$elm$core$Task$Perform(
				A2(
					$elm$core$Task$onError,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Err),
					A2(
						$elm$core$Task$andThen,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
							$elm$core$Result$Ok),
						task))));
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Main$Auth = F3(
	function (a, b, c) {
		return {$: 'Auth', a: a, b: b, c: c};
	});
var $author$project$Route$Auth = F2(
	function (a, b) {
		return {$: 'Auth', a: a, b: b};
	});
var $author$project$Main$AuthMsg = function (a) {
	return {$: 'AuthMsg', a: a};
};
var $author$project$API$Error$Bug = function (a) {
	return {$: 'Bug', a: a};
};
var $author$project$Main$Cart = F2(
	function (a, b) {
		return {$: 'Cart', a: a, b: b};
	});
var $author$project$Main$CartMsg = function (a) {
	return {$: 'CartMsg', a: a};
};
var $author$project$Main$ConfirmEmailMsg = function (a) {
	return {$: 'ConfirmEmailMsg', a: a};
};
var $author$project$Main$Contacts = F2(
	function (a, b) {
		return {$: 'Contacts', a: a, b: b};
	});
var $author$project$Main$EmailConfirm = F2(
	function (a, b) {
		return {$: 'EmailConfirm', a: a, b: b};
	});
var $author$project$Main$Error = F2(
	function (a, b) {
		return {$: 'Error', a: a, b: b};
	});
var $author$project$Route$Error = function (a) {
	return {$: 'Error', a: a};
};
var $author$project$API$Error$Forbidden = {$: 'Forbidden'};
var $author$project$Main$Manager = F2(
	function (a, b) {
		return {$: 'Manager', a: a, b: b};
	});
var $author$project$Main$ManagerAuth = F3(
	function (a, b, c) {
		return {$: 'ManagerAuth', a: a, b: b, c: c};
	});
var $author$project$Route$ManagerAuth = F2(
	function (a, b) {
		return {$: 'ManagerAuth', a: a, b: b};
	});
var $author$project$Main$ManagerAuthMsg = function (a) {
	return {$: 'ManagerAuthMsg', a: a};
};
var $author$project$Main$ManagerMsg = function (a) {
	return {$: 'ManagerMsg', a: a};
};
var $author$project$Main$MinibusRoute = F2(
	function (a, b) {
		return {$: 'MinibusRoute', a: a, b: b};
	});
var $author$project$Main$MinibusRouteMsg = function (a) {
	return {$: 'MinibusRouteMsg', a: a};
};
var $author$project$Main$Notification = F2(
	function (a, b) {
		return {$: 'Notification', a: a, b: b};
	});
var $author$project$Main$NotificationList = F2(
	function (a, b) {
		return {$: 'NotificationList', a: a, b: b};
	});
var $author$project$Main$NotificationListMsg = function (a) {
	return {$: 'NotificationListMsg', a: a};
};
var $author$project$Main$NotificationMsg = function (a) {
	return {$: 'NotificationMsg', a: a};
};
var $author$project$Main$Orders = F2(
	function (a, b) {
		return {$: 'Orders', a: a, b: b};
	});
var $author$project$Main$OrdersMsg = function (a) {
	return {$: 'OrdersMsg', a: a};
};
var $author$project$Main$ResetPassword = F2(
	function (a, b) {
		return {$: 'ResetPassword', a: a, b: b};
	});
var $author$project$Main$ResetPasswordMsg = function (a) {
	return {$: 'ResetPasswordMsg', a: a};
};
var $author$project$Main$Service = F2(
	function (a, b) {
		return {$: 'Service', a: a, b: b};
	});
var $author$project$Main$ServiceCategory = F2(
	function (a, b) {
		return {$: 'ServiceCategory', a: a, b: b};
	});
var $author$project$Main$ServiceCategoryMsg = function (a) {
	return {$: 'ServiceCategoryMsg', a: a};
};
var $author$project$Main$ServiceMsg = function (a) {
	return {$: 'ServiceMsg', a: a};
};
var $author$project$Main$Sync1C = F2(
	function (a, b) {
		return {$: 'Sync1C', a: a, b: b};
	});
var $author$project$Main$Sync1CMsg = function (a) {
	return {$: 'Sync1CMsg', a: a};
};
var $author$project$Main$Tenant = F2(
	function (a, b) {
		return {$: 'Tenant', a: a, b: b};
	});
var $author$project$Main$TenantMsg = function (a) {
	return {$: 'TenantMsg', a: a};
};
var $author$project$Main$Topic = F2(
	function (a, b) {
		return {$: 'Topic', a: a, b: b};
	});
var $author$project$Main$TopicMsg = function (a) {
	return {$: 'TopicMsg', a: a};
};
var $author$project$Main$Topics = F2(
	function (a, b) {
		return {$: 'Topics', a: a, b: b};
	});
var $author$project$Main$TopicsMsg = function (a) {
	return {$: 'TopicsMsg', a: a};
};
var $author$project$Main$VerifyEmail = F3(
	function (a, b, c) {
		return {$: 'VerifyEmail', a: a, b: b, c: c};
	});
var $author$project$Route$VerifyEmail = F2(
	function (a, b) {
		return {$: 'VerifyEmail', a: a, b: b};
	});
var $author$project$Main$VerifyEmailMsg = function (a) {
	return {$: 'VerifyEmailMsg', a: a};
};
var $author$project$Framework$AccessDenied = {$: 'AccessDenied'};
var $author$project$Framework$Protected = function (a) {
	return {$: 'Protected', a: a};
};
var $author$project$Framework$Public = {$: 'Public'};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Framework$erasedPageType = function (pageType) {
	if (pageType.$ === 'Public') {
		return $author$project$Framework$Public;
	} else {
		return $author$project$Framework$Protected(
			$elm$core$Basics$always($author$project$Framework$AccessDenied));
	}
};
var $author$project$Main$extractCommon = function (wrapped) {
	var getBase = function (model) {
		return {device: model.device, session: model.session};
	};
	switch (wrapped.$) {
		case 'Auth':
			var _v1 = wrapped.b;
			var pageType = _v1.a;
			var model = wrapped.c;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'VerifyEmail':
			var _v2 = wrapped.b;
			var pageType = _v2.a;
			var model = wrapped.c;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Error':
			var _v3 = wrapped.a;
			var pageType = _v3.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Tenant':
			var _v4 = wrapped.a;
			var pageType = _v4.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Waiting':
			var _v5 = wrapped.c;
			var pageType = _v5.a;
			var model = wrapped.d;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Notification':
			var _v6 = wrapped.a;
			var pageType = _v6.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'NotificationList':
			var _v7 = wrapped.a;
			var pageType = _v7.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Contacts':
			var _v8 = wrapped.a;
			var pageType = _v8.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'ServiceCategory':
			var _v9 = wrapped.a;
			var pageType = _v9.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Service':
			var _v10 = wrapped.a;
			var pageType = _v10.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Cart':
			var _v11 = wrapped.a;
			var pageType = _v11.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Orders':
			var _v12 = wrapped.a;
			var pageType = _v12.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Topic':
			var _v13 = wrapped.a;
			var pageType = _v13.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Topics':
			var _v14 = wrapped.a;
			var pageType = _v14.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'ManagerAuth':
			var _v15 = wrapped.b;
			var pageType = _v15.a;
			var model = wrapped.c;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Sync1C':
			var _v16 = wrapped.a;
			var pageType = _v16.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'Manager':
			var _v17 = wrapped.a;
			var pageType = _v17.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'EmailConfirm':
			var _v18 = wrapped.a;
			var pageType = _v18.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		case 'ResetPassword':
			var _v19 = wrapped.a;
			var pageType = _v19.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
		default:
			var _v20 = wrapped.a;
			var pageType = _v20.a;
			var model = wrapped.b;
			return _Utils_Tuple2(
				getBase(model),
				$author$project$Framework$erasedPageType(pageType));
	}
};
var $author$project$Session$getViewer = function (_v0) {
	var viewer = _v0.b;
	return viewer;
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Framework$EffectUpdate = function (a) {
	return {$: 'EffectUpdate', a: a};
};
var $author$project$Framework$Page = F2(
	function (a, b) {
		return {$: 'Page', a: a, b: b};
	});
var $author$project$Framework$PlainView = function (a) {
	return {$: 'PlainView', a: a};
};
var $author$project$Framework$AccessGranted = {$: 'AccessGranted'};
var $author$project$Framework$Skip = {$: 'Skip'};
var $author$project$Page$Auth$accessFilter = function (_v0) {
	var session = _v0.session;
	var _v1 = $author$project$Session$getViewer(session);
	if (_v1.$ === 'Authenticated') {
		return $author$project$Framework$Skip;
	} else {
		return $author$project$Framework$AccessGranted;
	}
};
var $author$project$Page$Auth$GotBuildingList = function (a) {
	return {$: 'GotBuildingList', a: a};
};
var $author$project$API$Status$Loading = {$: 'Loading'};
var $author$project$Page$Auth$LogIn = {$: 'LogIn'};
var $author$project$UI$Form$Text$Password = F2(
	function (a, b) {
		return {$: 'Password', a: a, b: b};
	});
var $author$project$UI$Form$Text$Regular = function (a) {
	return {$: 'Regular', a: a};
};
var $author$project$UI$Form$Required = {$: 'Required'};
var $author$project$Page$Auth$SetBuildingDropdownOpen = function (a) {
	return {$: 'SetBuildingDropdownOpen', a: a};
};
var $author$project$Page$Auth$UpdatedBuildingField = function (a) {
	return {$: 'UpdatedBuildingField', a: a};
};
var $author$project$Page$Auth$UpdatedEmailField = function (a) {
	return {$: 'UpdatedEmailField', a: a};
};
var $author$project$Page$Auth$UpdatedFlatField = function (a) {
	return {$: 'UpdatedFlatField', a: a};
};
var $author$project$Page$Auth$UpdatedNameField = function (a) {
	return {$: 'UpdatedNameField', a: a};
};
var $author$project$Page$Auth$UpdatedPasswordAgainField = function (a) {
	return {$: 'UpdatedPasswordAgainField', a: a};
};
var $author$project$Page$Auth$UpdatedPasswordField = function (a) {
	return {$: 'UpdatedPasswordField', a: a};
};
var $author$project$Page$Auth$UpdatedPatronymicField = function (a) {
	return {$: 'UpdatedPatronymicField', a: a};
};
var $author$project$Page$Auth$UpdatedSurnameField = function (a) {
	return {$: 'UpdatedSurnameField', a: a};
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $mdgriffith$elm_ui$Element$Input$TextInputNode = function (a) {
	return {$: 'TextInputNode', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Attr = function (a) {
	return {$: 'Attr', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Class = F2(
	function (a, b) {
		return {$: 'Class', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$NoAttribute = {$: 'NoAttribute'};
var $mdgriffith$elm_ui$Internal$Model$NodeName = function (a) {
	return {$: 'NodeName', a: a};
};
var $mdgriffith$elm_ui$Element$Input$TextArea = {$: 'TextArea'};
var $mdgriffith$elm_ui$Internal$Model$Unkeyed = function (a) {
	return {$: 'Unkeyed', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Describe = function (a) {
	return {$: 'Describe', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$LivePolite = {$: 'LivePolite'};
var $mdgriffith$elm_ui$Element$Region$announce = $mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$LivePolite);
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsColumn = {$: 'AsColumn'};
var $mdgriffith$elm_ui$Internal$Model$asColumn = $mdgriffith$elm_ui$Internal$Model$AsColumn;
var $mdgriffith$elm_ui$Internal$Model$AsEl = {$: 'AsEl'};
var $mdgriffith$elm_ui$Internal$Model$asEl = $mdgriffith$elm_ui$Internal$Model$AsEl;
var $mdgriffith$elm_ui$Internal$Model$AsRow = {$: 'AsRow'};
var $mdgriffith$elm_ui$Internal$Model$asRow = $mdgriffith$elm_ui$Internal$Model$AsRow;
var $mdgriffith$elm_ui$Internal$Style$classes = {above: 'a', active: 'atv', alignBottom: 'ab', alignCenterX: 'cx', alignCenterY: 'cy', alignContainerBottom: 'acb', alignContainerCenterX: 'accx', alignContainerCenterY: 'accy', alignContainerRight: 'acr', alignLeft: 'al', alignRight: 'ar', alignTop: 'at', alignedHorizontally: 'ah', alignedVertically: 'av', any: 's', behind: 'bh', below: 'b', bold: 'w7', borderDashed: 'bd', borderDotted: 'bdt', borderNone: 'bn', borderSolid: 'bs', capturePointerEvents: 'cpe', clip: 'cp', clipX: 'cpx', clipY: 'cpy', column: 'c', container: 'ctr', contentBottom: 'cb', contentCenterX: 'ccx', contentCenterY: 'ccy', contentLeft: 'cl', contentRight: 'cr', contentTop: 'ct', cursorPointer: 'cptr', cursorText: 'ctxt', focus: 'fcs', focusedWithin: 'focus-within', fullSize: 'fs', grid: 'g', hasBehind: 'hbh', heightContent: 'hc', heightExact: 'he', heightFill: 'hf', heightFillPortion: 'hfp', hover: 'hv', imageContainer: 'ic', inFront: 'fr', inputLabel: 'lbl', inputMultiline: 'iml', inputMultilineFiller: 'imlf', inputMultilineParent: 'imlp', inputMultilineWrapper: 'implw', inputText: 'it', italic: 'i', link: 'lnk', nearby: 'nb', noTextSelection: 'notxt', onLeft: 'ol', onRight: 'or', opaque: 'oq', overflowHidden: 'oh', page: 'pg', paragraph: 'p', passPointerEvents: 'ppe', root: 'ui', row: 'r', scrollbars: 'sb', scrollbarsX: 'sbx', scrollbarsY: 'sby', seButton: 'sbt', single: 'e', sizeByCapital: 'cap', spaceEvenly: 'sev', strike: 'sk', text: 't', textCenter: 'tc', textExtraBold: 'w8', textExtraLight: 'w2', textHeavy: 'w9', textJustify: 'tj', textJustifyAll: 'tja', textLeft: 'tl', textLight: 'w3', textMedium: 'w5', textNormalWeight: 'w4', textRight: 'tr', textSemiBold: 'w6', textThin: 'w1', textUnitalicized: 'tun', transition: 'ts', transparent: 'clr', underline: 'u', widthContent: 'wc', widthExact: 'we', widthFill: 'wf', widthFillPortion: 'wfp', wrapped: 'wrp'};
var $mdgriffith$elm_ui$Internal$Model$Generic = {$: 'Generic'};
var $mdgriffith$elm_ui$Internal$Model$div = $mdgriffith$elm_ui$Internal$Model$Generic;
var $mdgriffith$elm_ui$Internal$Model$NoNearbyChildren = {$: 'NoNearbyChildren'};
var $mdgriffith$elm_ui$Internal$Model$columnClass = $mdgriffith$elm_ui$Internal$Style$classes.any + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.column);
var $mdgriffith$elm_ui$Internal$Model$gridClass = $mdgriffith$elm_ui$Internal$Style$classes.any + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.grid);
var $mdgriffith$elm_ui$Internal$Model$pageClass = $mdgriffith$elm_ui$Internal$Style$classes.any + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.page);
var $mdgriffith$elm_ui$Internal$Model$paragraphClass = $mdgriffith$elm_ui$Internal$Style$classes.any + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.paragraph);
var $mdgriffith$elm_ui$Internal$Model$rowClass = $mdgriffith$elm_ui$Internal$Style$classes.any + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.row);
var $mdgriffith$elm_ui$Internal$Model$singleClass = $mdgriffith$elm_ui$Internal$Style$classes.any + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.single);
var $mdgriffith$elm_ui$Internal$Model$contextClasses = function (context) {
	switch (context.$) {
		case 'AsRow':
			return $mdgriffith$elm_ui$Internal$Model$rowClass;
		case 'AsColumn':
			return $mdgriffith$elm_ui$Internal$Model$columnClass;
		case 'AsEl':
			return $mdgriffith$elm_ui$Internal$Model$singleClass;
		case 'AsGrid':
			return $mdgriffith$elm_ui$Internal$Model$gridClass;
		case 'AsParagraph':
			return $mdgriffith$elm_ui$Internal$Model$paragraphClass;
		default:
			return $mdgriffith$elm_ui$Internal$Model$pageClass;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Keyed = function (a) {
	return {$: 'Keyed', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$NoStyleSheet = {$: 'NoStyleSheet'};
var $mdgriffith$elm_ui$Internal$Model$Styled = function (a) {
	return {$: 'Styled', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unstyled = function (a) {
	return {$: 'Unstyled', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addChildren = F2(
	function (existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 'NoNearbyChildren':
				return existing;
			case 'ChildrenBehind':
				var behind = nearbyChildren.a;
				return _Utils_ap(behind, existing);
			case 'ChildrenInFront':
				var inFront = nearbyChildren.a;
				return _Utils_ap(existing, inFront);
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					behind,
					_Utils_ap(existing, inFront));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$addKeyedChildren = F3(
	function (key, existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 'NoNearbyChildren':
				return existing;
			case 'ChildrenBehind':
				var behind = nearbyChildren.a;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					existing);
			case 'ChildrenInFront':
				var inFront = nearbyChildren.a;
				return _Utils_ap(
					existing,
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						inFront));
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					_Utils_ap(
						existing,
						A2(
							$elm$core$List$map,
							function (x) {
								return _Utils_Tuple2(key, x);
							},
							inFront)));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsParagraph = {$: 'AsParagraph'};
var $mdgriffith$elm_ui$Internal$Model$asParagraph = $mdgriffith$elm_ui$Internal$Model$AsParagraph;
var $mdgriffith$elm_ui$Internal$Flag$Flag = function (a) {
	return {$: 'Flag', a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Second = function (a) {
	return {$: 'Second', a: a};
};
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $mdgriffith$elm_ui$Internal$Flag$flag = function (i) {
	return (i > 31) ? $mdgriffith$elm_ui$Internal$Flag$Second(1 << (i - 32)) : $mdgriffith$elm_ui$Internal$Flag$Flag(1 << i);
};
var $mdgriffith$elm_ui$Internal$Flag$alignBottom = $mdgriffith$elm_ui$Internal$Flag$flag(41);
var $mdgriffith$elm_ui$Internal$Flag$alignRight = $mdgriffith$elm_ui$Internal$Flag$flag(40);
var $mdgriffith$elm_ui$Internal$Flag$centerX = $mdgriffith$elm_ui$Internal$Flag$flag(42);
var $mdgriffith$elm_ui$Internal$Flag$centerY = $mdgriffith$elm_ui$Internal$Flag$flag(43);
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$core$Set$Set_elm_builtin = function (a) {
	return {$: 'Set_elm_builtin', a: a};
};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: 'RBEmpty_elm_builtin'};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Set$empty = $elm$core$Set$Set_elm_builtin($elm$core$Dict$empty);
var $mdgriffith$elm_ui$Internal$Model$lengthClassName = function (x) {
	switch (x.$) {
		case 'Px':
			var px = x.a;
			return $elm$core$String$fromInt(px) + 'px';
		case 'Content':
			return 'auto';
		case 'Fill':
			var i = x.a;
			return $elm$core$String$fromInt(i) + 'fr';
		case 'Min':
			var min = x.a;
			var len = x.b;
			return 'min' + ($elm$core$String$fromInt(min) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
		default:
			var max = x.a;
			var len = x.b;
			return 'max' + ($elm$core$String$fromInt(max) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$core$Basics$round = _Basics_round;
var $mdgriffith$elm_ui$Internal$Model$floatClass = function (x) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(x * 255));
};
var $mdgriffith$elm_ui$Internal$Model$transformClass = function (transform) {
	switch (transform.$) {
		case 'Untransformed':
			return $elm$core$Maybe$Nothing;
		case 'Moved':
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'mv-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(x) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(y) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(z))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			return $elm$core$Maybe$Just(
				'tfrm-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ty) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ox) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oz) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(angle))))))))))))))))))));
	}
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $mdgriffith$elm_ui$Internal$Model$getStyleName = function (style) {
	switch (style.$) {
		case 'Shadows':
			var name = style.a;
			return name;
		case 'Transparency':
			var name = style.a;
			var o = style.b;
			return name;
		case 'Style':
			var _class = style.a;
			return _class;
		case 'FontFamily':
			var name = style.a;
			return name;
		case 'FontSize':
			var i = style.a;
			return 'font-size-' + $elm$core$String$fromInt(i);
		case 'Single':
			var _class = style.a;
			return _class;
		case 'Colored':
			var _class = style.a;
			return _class;
		case 'SpacingStyle':
			var cls = style.a;
			var x = style.b;
			var y = style.c;
			return cls;
		case 'PaddingStyle':
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 'BorderWidth':
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 'GridTemplateStyle':
			var template = style.a;
			return 'grid-rows-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.rows)) + ('-cols-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.columns)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.spacing.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.spacing.b)))))));
		case 'GridPosition':
			var pos = style.a;
			return 'gp grid-pos-' + ($elm$core$String$fromInt(pos.row) + ('-' + ($elm$core$String$fromInt(pos.col) + ('-' + ($elm$core$String$fromInt(pos.width) + ('-' + $elm$core$String$fromInt(pos.height)))))));
		case 'PseudoSelector':
			var selector = style.a;
			var subStyle = style.b;
			var name = function () {
				switch (selector.$) {
					case 'Focus':
						return 'fs';
					case 'Hover':
						return 'hv';
					default:
						return 'act';
				}
			}();
			return A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (sty) {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$getStyleName(sty);
						if (_v1 === '') {
							return '';
						} else {
							var styleName = _v1;
							return styleName + ('-' + name);
						}
					},
					subStyle));
		default:
			var x = style.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				$mdgriffith$elm_ui$Internal$Model$transformClass(x));
	}
};
var $elm$core$Dict$Black = {$: 'Black'};
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: 'RBNode_elm_builtin', a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = {$: 'Red'};
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Red')) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) && (left.d.$ === 'RBNode_elm_builtin')) && (left.d.a.$ === 'Red')) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1.$) {
				case 'LT':
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 'EQ':
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0.a;
		return $elm$core$Set$Set_elm_builtin(
			A3($elm$core$Dict$insert, key, _Utils_Tuple0, dict));
	});
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1.$) {
					case 'LT':
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 'EQ':
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (_v0.$ === 'Just') {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0.a;
		return A2($elm$core$Dict$member, key, dict);
	});
var $mdgriffith$elm_ui$Internal$Model$reduceStyles = F2(
	function (style, nevermind) {
		var cache = nevermind.a;
		var existing = nevermind.b;
		var styleName = $mdgriffith$elm_ui$Internal$Model$getStyleName(style);
		return A2($elm$core$Set$member, styleName, cache) ? nevermind : _Utils_Tuple2(
			A2($elm$core$Set$insert, styleName, cache),
			A2($elm$core$List$cons, style, existing));
	});
var $mdgriffith$elm_ui$Internal$Model$Property = F2(
	function (a, b) {
		return {$: 'Property', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Style = F2(
	function (a, b) {
		return {$: 'Style', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$dot = function (c) {
	return '.' + c;
};
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (_v0.$ === 'Just') {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $mdgriffith$elm_ui$Internal$Model$formatColor = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return 'rgba(' + ($elm$core$String$fromInt(
		$elm$core$Basics$round(red * 255)) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(green * 255))) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(blue * 255))) + (',' + ($elm$core$String$fromFloat(alpha) + ')')))));
};
var $mdgriffith$elm_ui$Internal$Model$formatBoxShadow = function (shadow) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					shadow.inset ? $elm$core$Maybe$Just('inset') : $elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.offset.a) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.offset.b) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.blur) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.size) + 'px'),
					$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Internal$Model$formatColor(shadow.color))
				])));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $mdgriffith$elm_ui$Internal$Model$renderFocusStyle = function (focus) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.focusedWithin) + ':focus-within',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.borderColor),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.backgroundColor),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										blur: shadow.blur,
										color: shadow.color,
										inset: false,
										offset: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.offset)),
										size: shadow.size
									}));
						},
						focus.shadow),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					]))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any) + ':focus .focusable, ') + (($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any) + '.focusable:focus, ') + ('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any) + ' .focusable-thumb'))),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.borderColor),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.backgroundColor),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										blur: shadow.blur,
										color: shadow.color,
										inset: false,
										offset: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.offset)),
										size: shadow.size
									}));
						},
						focus.shadow),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					])))
		]);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlJson(value));
	});
var $mdgriffith$elm_ui$Internal$Style$AllChildren = F2(
	function (a, b) {
		return {$: 'AllChildren', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Batch = function (a) {
	return {$: 'Batch', a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Child = F2(
	function (a, b) {
		return {$: 'Child', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Class = F2(
	function (a, b) {
		return {$: 'Class', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Descriptor = F2(
	function (a, b) {
		return {$: 'Descriptor', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Left = {$: 'Left'};
var $mdgriffith$elm_ui$Internal$Style$Prop = F2(
	function (a, b) {
		return {$: 'Prop', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Right = {$: 'Right'};
var $mdgriffith$elm_ui$Internal$Style$Self = function (a) {
	return {$: 'Self', a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Supports = F2(
	function (a, b) {
		return {$: 'Supports', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Content = function (a) {
	return {$: 'Content', a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Bottom = {$: 'Bottom'};
var $mdgriffith$elm_ui$Internal$Style$CenterX = {$: 'CenterX'};
var $mdgriffith$elm_ui$Internal$Style$CenterY = {$: 'CenterY'};
var $mdgriffith$elm_ui$Internal$Style$Top = {$: 'Top'};
var $mdgriffith$elm_ui$Internal$Style$alignments = _List_fromArray(
	[$mdgriffith$elm_ui$Internal$Style$Top, $mdgriffith$elm_ui$Internal$Style$Bottom, $mdgriffith$elm_ui$Internal$Style$Right, $mdgriffith$elm_ui$Internal$Style$Left, $mdgriffith$elm_ui$Internal$Style$CenterX, $mdgriffith$elm_ui$Internal$Style$CenterY]);
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $mdgriffith$elm_ui$Internal$Style$contentName = function (desc) {
	switch (desc.a.$) {
		case 'Top':
			var _v1 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.contentTop);
		case 'Bottom':
			var _v2 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.contentBottom);
		case 'Right':
			var _v3 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.contentRight);
		case 'Left':
			var _v4 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.contentLeft);
		case 'CenterX':
			var _v5 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.contentCenterX);
		default:
			var _v6 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.contentCenterY);
	}
};
var $mdgriffith$elm_ui$Internal$Style$selfName = function (desc) {
	switch (desc.a.$) {
		case 'Top':
			var _v1 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignTop);
		case 'Bottom':
			var _v2 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignBottom);
		case 'Right':
			var _v3 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignRight);
		case 'Left':
			var _v4 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignLeft);
		case 'CenterX':
			var _v5 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignCenterX);
		default:
			var _v6 = desc.a;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignCenterY);
	}
};
var $mdgriffith$elm_ui$Internal$Style$describeAlignment = function (values) {
	var createDescription = function (alignment) {
		var _v0 = values(alignment);
		var content = _v0.a;
		var indiv = _v0.b;
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$contentName(
					$mdgriffith$elm_ui$Internal$Style$Content(alignment)),
				content),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(
							$mdgriffith$elm_ui$Internal$Style$Self(alignment)),
						indiv)
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$elDescription = _List_fromArray(
	[
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hasBehind),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.behind),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.seButton),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.text),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFill),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthFill),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'auto !important')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightContent),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFill),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthFill),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthFillPortion),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthContent),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
			])),
		$mdgriffith$elm_ui$Internal$Style$describeAlignment(
		function (alignment) {
			switch (alignment.$) {
				case 'Top':
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
							]));
				case 'Bottom':
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
							]));
				case 'Right':
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
							]));
				case 'Left':
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							]));
				case 'CenterX':
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
									]))
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
							]));
			}
		})
	]);
var $mdgriffith$elm_ui$Internal$Style$gridAlignments = function (values) {
	var createDescription = function (alignment) {
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(
							$mdgriffith$elm_ui$Internal$Style$Self(alignment)),
						values(alignment))
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$Above = {$: 'Above'};
var $mdgriffith$elm_ui$Internal$Style$Behind = {$: 'Behind'};
var $mdgriffith$elm_ui$Internal$Style$Below = {$: 'Below'};
var $mdgriffith$elm_ui$Internal$Style$OnLeft = {$: 'OnLeft'};
var $mdgriffith$elm_ui$Internal$Style$OnRight = {$: 'OnRight'};
var $mdgriffith$elm_ui$Internal$Style$Within = {$: 'Within'};
var $mdgriffith$elm_ui$Internal$Style$locations = function () {
	var loc = $mdgriffith$elm_ui$Internal$Style$Above;
	var _v0 = function () {
		switch (loc.$) {
			case 'Above':
				return _Utils_Tuple0;
			case 'Below':
				return _Utils_Tuple0;
			case 'OnRight':
				return _Utils_Tuple0;
			case 'OnLeft':
				return _Utils_Tuple0;
			case 'Within':
				return _Utils_Tuple0;
			default:
				return _Utils_Tuple0;
		}
	}();
	return _List_fromArray(
		[$mdgriffith$elm_ui$Internal$Style$Above, $mdgriffith$elm_ui$Internal$Style$Below, $mdgriffith$elm_ui$Internal$Style$OnRight, $mdgriffith$elm_ui$Internal$Style$OnLeft, $mdgriffith$elm_ui$Internal$Style$Within, $mdgriffith$elm_ui$Internal$Style$Behind]);
}();
var $mdgriffith$elm_ui$Internal$Style$baseSheet = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		'html,body',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		_Utils_ap(
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any),
			_Utils_ap(
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.single),
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.imageContainer))),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFill),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-height', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthFill),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any) + ':focus',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'outline', 'none')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.root),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFill)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFill),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.inFront),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nearby),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nearby),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.single),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				$mdgriffith$elm_ui$Internal$Style$Batch(
				function (fn) {
					return A2($elm$core$List$map, fn, $mdgriffith$elm_ui$Internal$Style$locations);
				}(
					function (loc) {
						switch (loc.$) {
							case 'Above':
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.above),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFill),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthFill),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
												])),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 'Below':
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.below),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFill),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												]))
										]));
							case 'OnRight':
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.onRight),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 'OnLeft':
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.onLeft),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'right', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 'Within':
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.inFront),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							default:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.behind),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
						}
					}))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'resize', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'box-sizing', 'border-box'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-size', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-family', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'inherit'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.wrapped),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-wrap', 'wrap')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.noTextSelection),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-moz-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-webkit-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-ms-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'user-select', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cursorPointer),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'pointer')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cursorText),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.passPointerEvents),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.capturePointerEvents),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.transparent),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.opaque),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.hover, $mdgriffith$elm_ui$Internal$Style$classes.transparent)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.hover, $mdgriffith$elm_ui$Internal$Style$classes.opaque)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.focus, $mdgriffith$elm_ui$Internal$Style$classes.transparent)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.focus, $mdgriffith$elm_ui$Internal$Style$classes.opaque)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.active, $mdgriffith$elm_ui$Internal$Style$classes.transparent)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.active, $mdgriffith$elm_ui$Internal$Style$classes.opaque)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.transition),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Prop,
						'transition',
						A2(
							$elm$core$String$join,
							', ',
							A2(
								$elm$core$List$map,
								function (x) {
									return x + ' 160ms';
								},
								_List_fromArray(
									['transform', 'opacity', 'filter', 'background-color', 'color', 'font-size']))))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.scrollbars),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.scrollbarsX),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.row),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.scrollbarsY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.column),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.single),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.clip),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.clipX),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.clipY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthContent),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', 'auto')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.borderNone),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.borderDashed),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dashed')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.borderDotted),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dotted')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.borderSolid),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.text),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.inputText),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1.05'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background', 'transparent'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'inherit')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.single),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.row),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthExact),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.link),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFill),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFillPortion),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthFill),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.container),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerRight,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterX,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignCenterX),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-left', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterX,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignCenterX),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-right', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterX,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignCenterY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterX + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.alignContainerRight + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterX)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment.$) {
								case 'Top':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 'Bottom':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 'Right':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_Nil);
								case 'Left':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_Nil);
								case 'CenterX':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.spaceEvenly),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.inputLabel),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'baseline')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.column),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0px'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', 'min-content'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightExact),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.heightFill),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthFill),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthFillPortion),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthContent),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerBottom,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterY,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignCenterY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterY,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignCenterY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterY,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.alignCenterY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterY + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.alignContainerBottom + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterY)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment.$) {
								case 'Top':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
											]));
								case 'Bottom':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto')
											]));
								case 'Right':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 'Left':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 'CenterX':
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.container),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.spaceEvenly),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.grid),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', '-ms-grid'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'.gp',
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Supports,
						_Utils_Tuple2('display', 'grid'),
						_List_fromArray(
							[
								_Utils_Tuple2('display', 'grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$gridAlignments(
						function (alignment) {
							switch (alignment.$) {
								case 'Top':
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
										]);
								case 'Bottom':
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
										]);
								case 'Right':
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
										]);
								case 'Left':
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
										]);
								case 'CenterX':
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
										]);
								default:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
										]);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.page),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any + ':first-child'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.any + ($mdgriffith$elm_ui$Internal$Style$selfName(
								$mdgriffith$elm_ui$Internal$Style$Self($mdgriffith$elm_ui$Internal$Style$Left)) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.any))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.any + ($mdgriffith$elm_ui$Internal$Style$selfName(
								$mdgriffith$elm_ui$Internal$Style$Self($mdgriffith$elm_ui$Internal$Style$Right)) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.any))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment.$) {
								case 'Top':
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 'Bottom':
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 'Right':
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 'Left':
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 'CenterX':
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.inputMultiline),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background-color', 'transparent')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.inputMultilineWrapper),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.single),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.inputMultilineParent),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.inputMultilineFiller),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'transparent')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.paragraph),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-wrap', 'break-word'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hasBehind),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.behind),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.text),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.paragraph),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::after',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::before',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.single),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.widthExact),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.inFront),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.behind),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.above),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.below),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.onRight),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.onLeft),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.text),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.row),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.column),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.grid),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment.$) {
								case 'Top':
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 'Bottom':
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 'Right':
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right')
											]));
								case 'Left':
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left')
											]));
								case 'CenterX':
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.hidden',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textThin),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '100')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textExtraLight),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '200')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textLight),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '300')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textNormalWeight),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '400')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textMedium),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '500')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textSemiBold),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '600')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bold),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '700')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textExtraBold),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '800')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textHeavy),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '900')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.italic),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'italic')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.strike),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.underline),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.underline),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.strike)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textUnitalicized),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'normal')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textJustify),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textJustifyAll),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify-all')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textCenter),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'center')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textRight),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'right')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.textLeft),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'left')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.modal',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none')
					]))
			]))
	]);
var $mdgriffith$elm_ui$Internal$Style$fontVariant = function (_var) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + _var,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\"'))
				])),
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + (_var + '-off'),
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\" 0'))
				]))
		]);
};
var $mdgriffith$elm_ui$Internal$Style$commonValues = $elm$core$List$concat(
	_List_fromArray(
		[
			A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.border-' + $elm$core$String$fromInt(x),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'border-width',
							$elm$core$String$fromInt(x) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 6)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 8, 32)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.p-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'padding',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 24)),
			_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'small-caps')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp-off',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'normal')
					]))
			]),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('zero'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('onum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('liga'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('dlig'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('ordn'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('tnum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('afrc'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('frac')
		]));
var $mdgriffith$elm_ui$Internal$Style$explainer = '\n.explain {\n    border: 6px solid rgb(174, 121, 15) !important;\n}\n.explain > .' + ($mdgriffith$elm_ui$Internal$Style$classes.any + (' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n.ctr {\n    border: none !important;\n}\n.explain > .ctr > .' + ($mdgriffith$elm_ui$Internal$Style$classes.any + ' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n')));
var $mdgriffith$elm_ui$Internal$Style$inputTextReset = '\ninput[type="search"],\ninput[type="search"]::-webkit-search-decoration,\ninput[type="search"]::-webkit-search-cancel-button,\ninput[type="search"]::-webkit-search-results-button,\ninput[type="search"]::-webkit-search-results-decoration {\n  -webkit-appearance:none;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$sliderReset = '\ninput[type=range] {\n  -webkit-appearance: none; \n  background: transparent;\n  position:absolute;\n  left:0;\n  top:0;\n  z-index:10;\n  width: 100%;\n  outline: dashed 1px;\n  height: 100%;\n  opacity: 0;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$thumbReset = '\ninput[type=range]::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-moz-range-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-ms-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range][orient=vertical]{\n    writing-mode: bt-lr; /* IE */\n    -webkit-appearance: slider-vertical;  /* WebKit */\n}\n';
var $mdgriffith$elm_ui$Internal$Style$trackReset = '\ninput[type=range]::-moz-range-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-ms-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-webkit-slider-runnable-track {\n    background: transparent;\n    cursor: pointer;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$overrides = '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.row) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any) + (' { flex-basis: auto !important; } ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.row) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.container) + (' { flex-basis: auto !important; }}' + ($mdgriffith$elm_ui$Internal$Style$inputTextReset + ($mdgriffith$elm_ui$Internal$Style$sliderReset + ($mdgriffith$elm_ui$Internal$Style$trackReset + ($mdgriffith$elm_ui$Internal$Style$thumbReset + $mdgriffith$elm_ui$Internal$Style$explainer)))))))))))))));
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $mdgriffith$elm_ui$Internal$Style$Intermediate = function (a) {
	return {$: 'Intermediate', a: a};
};
var $mdgriffith$elm_ui$Internal$Style$emptyIntermediate = F2(
	function (selector, closing) {
		return $mdgriffith$elm_ui$Internal$Style$Intermediate(
			{closing: closing, others: _List_Nil, props: _List_Nil, selector: selector});
	});
var $mdgriffith$elm_ui$Internal$Style$renderRules = F2(
	function (_v0, rulesToRender) {
		var parent = _v0.a;
		var generateIntermediates = F2(
			function (rule, rendered) {
				switch (rule.$) {
					case 'Prop':
						var name = rule.a;
						var val = rule.b;
						return _Utils_update(
							rendered,
							{
								props: A2(
									$elm$core$List$cons,
									_Utils_Tuple2(name, val),
									rendered.props)
							});
					case 'Supports':
						var _v2 = rule.a;
						var prop = _v2.a;
						var value = _v2.b;
						var props = rule.b;
						return _Utils_update(
							rendered,
							{
								others: A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Style$Intermediate(
										{closing: '\n}', others: _List_Nil, props: props, selector: '@supports (' + (prop + (':' + (value + (') {' + parent.selector))))}),
									rendered.others)
							});
					case 'Adjacent':
						var selector = rule.a;
						var adjRules = rule.b;
						return _Utils_update(
							rendered,
							{
								others: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.selector + (' + ' + selector), ''),
										adjRules),
									rendered.others)
							});
					case 'Child':
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								others: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.selector + (' > ' + child), ''),
										childRules),
									rendered.others)
							});
					case 'AllChildren':
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								others: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.selector + (' ' + child), ''),
										childRules),
									rendered.others)
							});
					case 'Descriptor':
						var descriptor = rule.a;
						var descriptorRules = rule.b;
						return _Utils_update(
							rendered,
							{
								others: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(
											$mdgriffith$elm_ui$Internal$Style$emptyIntermediate,
											_Utils_ap(parent.selector, descriptor),
											''),
										descriptorRules),
									rendered.others)
							});
					default:
						var batched = rule.a;
						return _Utils_update(
							rendered,
							{
								others: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.selector, ''),
										batched),
									rendered.others)
							});
				}
			});
		return $mdgriffith$elm_ui$Internal$Style$Intermediate(
			A3($elm$core$List$foldr, generateIntermediates, parent, rulesToRender));
	});
var $mdgriffith$elm_ui$Internal$Style$renderCompact = function (styleClasses) {
	var renderValues = function (values) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return x + (':' + (y + ';'));
				},
				values));
	};
	var renderClass = function (rule) {
		var _v2 = rule.props;
		if (!_v2.b) {
			return '';
		} else {
			return rule.selector + ('{' + (renderValues(rule.props) + (rule.closing + '}')));
		}
	};
	var renderIntermediate = function (_v0) {
		var rule = _v0.a;
		return _Utils_ap(
			renderClass(rule),
			$elm$core$String$concat(
				A2($elm$core$List$map, renderIntermediate, rule.others)));
	};
	return $elm$core$String$concat(
		A2(
			$elm$core$List$map,
			renderIntermediate,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v1, existing) {
						var name = _v1.a;
						var styleRules = _v1.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$mdgriffith$elm_ui$Internal$Style$renderRules,
								A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, name, ''),
								styleRules),
							existing);
					}),
				_List_Nil,
				styleClasses)));
};
var $mdgriffith$elm_ui$Internal$Style$rules = _Utils_ap(
	$mdgriffith$elm_ui$Internal$Style$overrides,
	$mdgriffith$elm_ui$Internal$Style$renderCompact(
		_Utils_ap($mdgriffith$elm_ui$Internal$Style$baseSheet, $mdgriffith$elm_ui$Internal$Style$commonValues)));
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $mdgriffith$elm_ui$Internal$Model$staticRoot = function (opts) {
	var _v0 = opts.mode;
	switch (_v0.$) {
		case 'Layout':
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'div',
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$elm$virtual_dom$VirtualDom$node,
						'style',
						_List_Nil,
						_List_fromArray(
							[
								$elm$virtual_dom$VirtualDom$text($mdgriffith$elm_ui$Internal$Style$rules)
							]))
					]));
		case 'NoStaticStyleSheet':
			return $elm$virtual_dom$VirtualDom$text('');
		default:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'elm-ui-static-rules',
				_List_fromArray(
					[
						A2(
						$elm$virtual_dom$VirtualDom$property,
						'rules',
						$elm$json$Json$Encode$string($mdgriffith$elm_ui$Internal$Style$rules))
					]),
				_List_Nil);
	}
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(_Utils_Tuple0),
				entries));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(_Utils_Tuple0),
			pairs));
};
var $mdgriffith$elm_ui$Internal$Model$fontName = function (font) {
	switch (font.$) {
		case 'Serif':
			return 'serif';
		case 'SansSerif':
			return 'sans-serif';
		case 'Monospace':
			return 'monospace';
		case 'Typeface':
			var name = font.a;
			return '\"' + (name + '\"');
		case 'ImportFont':
			var name = font.a;
			var url = font.b;
			return '\"' + (name + '\"');
		default:
			var name = font.a.name;
			return '\"' + (name + '\"');
	}
};
var $mdgriffith$elm_ui$Internal$Model$isSmallCaps = function (_var) {
	switch (_var.$) {
		case 'VariantActive':
			var name = _var.a;
			return name === 'smcp';
		case 'VariantOff':
			var name = _var.a;
			return false;
		default:
			var name = _var.a;
			var index = _var.b;
			return (name === 'smcp') && (index === 1);
	}
};
var $mdgriffith$elm_ui$Internal$Model$hasSmallCaps = function (typeface) {
	if (typeface.$ === 'FontWith') {
		var font = typeface.a;
		return A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$isSmallCaps, font.variants);
	} else {
		return false;
	}
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $mdgriffith$elm_ui$Internal$Model$renderProps = F3(
	function (force, _v0, existing) {
		var key = _v0.a;
		var val = _v0.b;
		return force ? (existing + ('\n  ' + (key + (': ' + (val + ' !important;'))))) : (existing + ('\n  ' + (key + (': ' + (val + ';')))));
	});
var $mdgriffith$elm_ui$Internal$Model$renderStyle = F4(
	function (options, maybePseudo, selector, props) {
		if (maybePseudo.$ === 'Nothing') {
			return _List_fromArray(
				[
					selector + ('{' + (A3(
					$elm$core$List$foldl,
					$mdgriffith$elm_ui$Internal$Model$renderProps(false),
					'',
					props) + '\n}'))
				]);
		} else {
			var pseudo = maybePseudo.a;
			switch (pseudo.$) {
				case 'Hover':
					var _v2 = options.hover;
					switch (_v2.$) {
						case 'NoHover':
							return _List_Nil;
						case 'ForceHover':
							return _List_fromArray(
								[
									selector + ('-hv {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(true),
									'',
									props) + '\n}'))
								]);
						default:
							return _List_fromArray(
								[
									selector + ('-hv:hover {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(false),
									'',
									props) + '\n}'))
								]);
					}
				case 'Focus':
					var renderedProps = A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$renderProps(false),
						'',
						props);
					return _List_fromArray(
						[
							selector + ('-fs:focus {' + (renderedProps + '\n}')),
							('.' + ($mdgriffith$elm_ui$Internal$Style$classes.any + (':focus ' + (selector + '-fs  {')))) + (renderedProps + '\n}'),
							(selector + '-fs:focus-within {') + (renderedProps + '\n}'),
							('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.any) + (' .focusable-thumb' + (selector + '-fs {')))) + (renderedProps + '\n}')
						]);
				default:
					return _List_fromArray(
						[
							selector + ('-act:active {' + (A3(
							$elm$core$List$foldl,
							$mdgriffith$elm_ui$Internal$Model$renderProps(false),
							'',
							props) + '\n}'))
						]);
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderVariant = function (_var) {
	switch (_var.$) {
		case 'VariantActive':
			var name = _var.a;
			return '\"' + (name + '\"');
		case 'VariantOff':
			var name = _var.a;
			return '\"' + (name + '\" 0');
		default:
			var name = _var.a;
			var index = _var.b;
			return '\"' + (name + ('\" ' + $elm$core$String$fromInt(index)));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderVariants = function (typeface) {
	if (typeface.$ === 'FontWith') {
		var font = typeface.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$renderVariant, font.variants)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$transformValue = function (transform) {
	switch (transform.$) {
		case 'Untransformed':
			return $elm$core$Maybe$Nothing;
		case 'Moved':
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'translate3d(' + ($elm$core$String$fromFloat(x) + ('px, ' + ($elm$core$String$fromFloat(y) + ('px, ' + ($elm$core$String$fromFloat(z) + 'px)'))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			var translate = 'translate3d(' + ($elm$core$String$fromFloat(tx) + ('px, ' + ($elm$core$String$fromFloat(ty) + ('px, ' + ($elm$core$String$fromFloat(tz) + 'px)')))));
			var scale = 'scale3d(' + ($elm$core$String$fromFloat(sx) + (', ' + ($elm$core$String$fromFloat(sy) + (', ' + ($elm$core$String$fromFloat(sz) + ')')))));
			var rotate = 'rotate3d(' + ($elm$core$String$fromFloat(ox) + (', ' + ($elm$core$String$fromFloat(oy) + (', ' + ($elm$core$String$fromFloat(oz) + (', ' + ($elm$core$String$fromFloat(angle) + 'rad)')))))));
			return $elm$core$Maybe$Just(translate + (' ' + (scale + (' ' + rotate))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderStyleRule = F3(
	function (options, rule, maybePseudo) {
		switch (rule.$) {
			case 'Style':
				var selector = rule.a;
				var props = rule.b;
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, selector, props);
			case 'Shadows':
				var name = rule.a;
				var prop = rule.b;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, 'box-shadow', prop)
						]));
			case 'Transparency':
				var name = rule.a;
				var transparency = rule.b;
				var opacity = A2(
					$elm$core$Basics$max,
					0,
					A2($elm$core$Basics$min, 1, 1 - transparency));
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'opacity',
							$elm$core$String$fromFloat(opacity))
						]));
			case 'FontSize':
				var i = rule.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			case 'FontFamily':
				var name = rule.a;
				var typefaces = rule.b;
				var features = A2(
					$elm$core$String$join,
					', ',
					A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Internal$Model$renderVariants, typefaces));
				var families = _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-family',
						A2(
							$elm$core$String$join,
							', ',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$fontName, typefaces))),
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'font-feature-settings', features),
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-variant',
						A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$hasSmallCaps, typefaces) ? 'small-caps' : 'normal')
					]);
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, '.' + name, families);
			case 'Single':
				var _class = rule.a;
				var prop = rule.b;
				var val = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, prop, val)
						]));
			case 'Colored':
				var _class = rule.a;
				var prop = rule.b;
				var color = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							prop,
							$mdgriffith$elm_ui$Internal$Model$formatColor(color))
						]));
			case 'SpacingStyle':
				var cls = rule.a;
				var x = rule.b;
				var y = rule.c;
				var yPx = $elm$core$String$fromInt(y) + 'px';
				var xPx = $elm$core$String$fromInt(x) + 'px';
				var single = '.' + $mdgriffith$elm_ui$Internal$Style$classes.single;
				var row = '.' + $mdgriffith$elm_ui$Internal$Style$classes.row;
				var wrappedRow = '.' + ($mdgriffith$elm_ui$Internal$Style$classes.wrapped + row);
				var right = '.' + $mdgriffith$elm_ui$Internal$Style$classes.alignRight;
				var paragraph = '.' + $mdgriffith$elm_ui$Internal$Style$classes.paragraph;
				var page = '.' + $mdgriffith$elm_ui$Internal$Style$classes.page;
				var left = '.' + $mdgriffith$elm_ui$Internal$Style$classes.alignLeft;
				var halfY = $elm$core$String$fromFloat(y / 2) + 'px';
				var halfX = $elm$core$String$fromFloat(x / 2) + 'px';
				var column = '.' + $mdgriffith$elm_ui$Internal$Style$classes.column;
				var _class = '.' + cls;
				var any = '.' + $mdgriffith$elm_ui$Internal$Style$classes.any;
				return $elm$core$List$concat(
					_List_fromArray(
						[
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (row + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (wrappedRow + (' > ' + any)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin', halfY + (' ' + halfX))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (column + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_Utils_ap(_class, paragraph),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							'textarea' + (any + _class),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)')),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'height',
									'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::after'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-top',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::before'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-bottom',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								]))
						]));
			case 'PaddingStyle':
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'padding',
							$elm$core$String$fromFloat(top) + ('px ' + ($elm$core$String$fromFloat(right) + ('px ' + ($elm$core$String$fromFloat(bottom) + ('px ' + ($elm$core$String$fromFloat(left) + 'px')))))))
						]));
			case 'BorderWidth':
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'border-width',
							$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
						]));
			case 'GridTemplateStyle':
				var template = rule.a;
				var toGridLengthHelper = F3(
					function (minimum, maximum, x) {
						toGridLengthHelper:
						while (true) {
							switch (x.$) {
								case 'Px':
									var px = x.a;
									return $elm$core$String$fromInt(px) + 'px';
								case 'Content':
									var _v2 = _Utils_Tuple2(minimum, maximum);
									if (_v2.a.$ === 'Nothing') {
										if (_v2.b.$ === 'Nothing') {
											var _v3 = _v2.a;
											var _v4 = _v2.b;
											return 'max-content';
										} else {
											var _v6 = _v2.a;
											var maxSize = _v2.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v2.b.$ === 'Nothing') {
											var minSize = _v2.a.a;
											var _v5 = _v2.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + 'max-content)'));
										} else {
											var minSize = _v2.a.a;
											var maxSize = _v2.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 'Fill':
									var i = x.a;
									var _v7 = _Utils_Tuple2(minimum, maximum);
									if (_v7.a.$ === 'Nothing') {
										if (_v7.b.$ === 'Nothing') {
											var _v8 = _v7.a;
											var _v9 = _v7.b;
											return $elm$core$String$fromInt(i) + 'fr';
										} else {
											var _v11 = _v7.a;
											var maxSize = _v7.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v7.b.$ === 'Nothing') {
											var minSize = _v7.a.a;
											var _v10 = _v7.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(i) + ('fr' + 'fr)'))));
										} else {
											var minSize = _v7.a.a;
											var maxSize = _v7.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 'Min':
									var m = x.a;
									var len = x.b;
									var $temp$minimum = $elm$core$Maybe$Just(m),
										$temp$maximum = maximum,
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
								default:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = minimum,
										$temp$maximum = $elm$core$Maybe$Just(m),
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
							}
						}
					});
				var toGridLength = function (x) {
					return A3(toGridLengthHelper, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, x);
				};
				var xSpacing = toGridLength(template.spacing.a);
				var ySpacing = toGridLength(template.spacing.b);
				var rows = function (x) {
					return 'grid-template-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.rows)));
				var msRows = function (x) {
					return '-ms-grid-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.columns)));
				var msColumns = function (x) {
					return '-ms-grid-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.columns)));
				var gapY = 'grid-row-gap:' + (toGridLength(template.spacing.b) + ';');
				var gapX = 'grid-column-gap:' + (toGridLength(template.spacing.a) + ';');
				var columns = function (x) {
					return 'grid-template-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.columns)));
				var _class = '.grid-rows-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.rows)) + ('-cols-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.columns)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.spacing.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.spacing.b)))))));
				var modernGrid = _class + ('{' + (columns + (rows + (gapX + (gapY + '}')))));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msColumns + (msRows + '}')));
				return _List_fromArray(
					[base, supports]);
			case 'GridPosition':
				var position = rule.a;
				var msPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'-ms-grid-row: ' + ($elm$core$String$fromInt(position.row) + ';'),
							'-ms-grid-row-span: ' + ($elm$core$String$fromInt(position.height) + ';'),
							'-ms-grid-column: ' + ($elm$core$String$fromInt(position.col) + ';'),
							'-ms-grid-column-span: ' + ($elm$core$String$fromInt(position.width) + ';')
						]));
				var modernPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'grid-row: ' + ($elm$core$String$fromInt(position.row) + (' / ' + ($elm$core$String$fromInt(position.row + position.height) + ';'))),
							'grid-column: ' + ($elm$core$String$fromInt(position.col) + (' / ' + ($elm$core$String$fromInt(position.col + position.width) + ';')))
						]));
				var _class = '.grid-pos-' + ($elm$core$String$fromInt(position.row) + ('-' + ($elm$core$String$fromInt(position.col) + ('-' + ($elm$core$String$fromInt(position.width) + ('-' + $elm$core$String$fromInt(position.height)))))));
				var modernGrid = _class + ('{' + (modernPosition + '}'));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msPosition + '}'));
				return _List_fromArray(
					[base, supports]);
			case 'PseudoSelector':
				var _class = rule.a;
				var styles = rule.b;
				var renderPseudoRule = function (style) {
					return A3(
						$mdgriffith$elm_ui$Internal$Model$renderStyleRule,
						options,
						style,
						$elm$core$Maybe$Just(_class));
				};
				return A2($elm$core$List$concatMap, renderPseudoRule, styles);
			default:
				var transform = rule.a;
				var val = $mdgriffith$elm_ui$Internal$Model$transformValue(transform);
				var _class = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				var _v12 = _Utils_Tuple2(_class, val);
				if ((_v12.a.$ === 'Just') && (_v12.b.$ === 'Just')) {
					var cls = _v12.a.a;
					var v = _v12.b.a;
					return A4(
						$mdgriffith$elm_ui$Internal$Model$renderStyle,
						options,
						maybePseudo,
						'.' + cls,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Model$Property, 'transform', v)
							]));
				} else {
					return _List_Nil;
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$encodeStyles = F2(
	function (options, stylesheet) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				function (style) {
					var styled = A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing);
					return _Utils_Tuple2(
						$mdgriffith$elm_ui$Internal$Model$getStyleName(style),
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, styled));
				},
				stylesheet));
	});
var $mdgriffith$elm_ui$Internal$Model$bracket = F2(
	function (selector, rules) {
		var renderPair = function (_v0) {
			var name = _v0.a;
			var val = _v0.b;
			return name + (': ' + (val + ';'));
		};
		return selector + (' {' + (A2(
			$elm$core$String$join,
			'',
			A2($elm$core$List$map, renderPair, rules)) + '}'));
	});
var $mdgriffith$elm_ui$Internal$Model$fontRule = F3(
	function (name, modifier, _v0) {
		var parentAdj = _v0.a;
		var textAdjustment = _v0.b;
		return _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + (', ' + ('.' + (name + (' .' + modifier))))))), parentAdj),
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.text + (', .' + (name + (' .' + (modifier + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.text)))))))))), textAdjustment)
			]);
	});
var $mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule = F3(
	function (fontToAdjust, _v0, otherFontName) {
		var full = _v0.a;
		var capital = _v0.b;
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_Utils_ap(
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.sizeByCapital, capital),
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.fullSize, full)));
	});
var $mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule = F2(
	function (fontToAdjust, otherFontName) {
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.sizeByCapital + (', ' + ('.' + (name + (' .' + $mdgriffith$elm_ui$Internal$Style$classes.sizeByCapital))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('line-height', '1')
						])),
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.sizeByCapital + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.text + (', .' + (name + (' .' + ($mdgriffith$elm_ui$Internal$Style$classes.sizeByCapital + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.text)))))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('vertical-align', '0'),
							_Utils_Tuple2('line-height', '1')
						]))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$adjust = F3(
	function (size, height, vertical) {
		return {height: height / size, size: size, vertical: vertical};
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $mdgriffith$elm_ui$Internal$Model$convertAdjustment = function (adjustment) {
	var lines = _List_fromArray(
		[adjustment.capital, adjustment.baseline, adjustment.descender, adjustment.lowercase]);
	var lineHeight = 1.5;
	var normalDescender = (lineHeight - 1) / 2;
	var oldMiddle = lineHeight / 2;
	var descender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.descender,
		$elm$core$List$minimum(lines));
	var newBaseline = A2(
		$elm$core$Maybe$withDefault,
		adjustment.baseline,
		$elm$core$List$minimum(
			A2(
				$elm$core$List$filter,
				function (x) {
					return !_Utils_eq(x, descender);
				},
				lines)));
	var base = lineHeight;
	var ascender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.capital,
		$elm$core$List$maximum(lines));
	var capitalSize = 1 / (ascender - newBaseline);
	var capitalVertical = 1 - ascender;
	var fullSize = 1 / (ascender - descender);
	var fullVertical = 1 - ascender;
	var newCapitalMiddle = ((ascender - newBaseline) / 2) + newBaseline;
	var newFullMiddle = ((ascender - descender) / 2) + descender;
	return {
		capital: A3($mdgriffith$elm_ui$Internal$Model$adjust, capitalSize, ascender - newBaseline, capitalVertical),
		full: A3($mdgriffith$elm_ui$Internal$Model$adjust, fullSize, ascender - descender, fullVertical)
	};
};
var $mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules = function (converted) {
	return _Utils_Tuple2(
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'block')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2(
				'line-height',
				$elm$core$String$fromFloat(converted.height)),
				_Utils_Tuple2(
				'vertical-align',
				$elm$core$String$fromFloat(converted.vertical) + 'em'),
				_Utils_Tuple2(
				'font-size',
				$elm$core$String$fromFloat(converted.size) + 'em')
			]));
};
var $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment = function (typefaces) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (face, found) {
				if (found.$ === 'Nothing') {
					if (face.$ === 'FontWith') {
						var _with = face.a;
						var _v2 = _with.adjustment;
						if (_v2.$ === 'Nothing') {
							return found;
						} else {
							var adjustment = _v2.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.full;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment))),
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.capital;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment)))));
						}
					} else {
						return found;
					}
				} else {
					return found;
				}
			}),
		$elm$core$Maybe$Nothing,
		typefaces);
};
var $mdgriffith$elm_ui$Internal$Model$renderTopLevelValues = function (rules) {
	var withImport = function (font) {
		if (font.$ === 'ImportFont') {
			var url = font.b;
			return $elm$core$Maybe$Just('@import url(\'' + (url + '\');'));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var fontImports = function (_v2) {
		var name = _v2.a;
		var typefaces = _v2.b;
		var imports = A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$filterMap, withImport, typefaces));
		return imports;
	};
	var allNames = A2($elm$core$List$map, $elm$core$Tuple$first, rules);
	var fontAdjustments = function (_v1) {
		var name = _v1.a;
		var typefaces = _v1.b;
		var _v0 = $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment(typefaces);
		if (_v0.$ === 'Nothing') {
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule(name),
					allNames));
		} else {
			var adjustment = _v0.a;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					A2($mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule, name, adjustment),
					allNames));
		}
	};
	return _Utils_ap(
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontImports, rules)),
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontAdjustments, rules)));
};
var $mdgriffith$elm_ui$Internal$Model$topLevelValue = function (rule) {
	if (rule.$ === 'FontFamily') {
		var name = rule.a;
		var typefaces = rule.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(name, typefaces));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$toStyleSheetString = F2(
	function (options, stylesheet) {
		var combine = F2(
			function (style, rendered) {
				return {
					rules: _Utils_ap(
						rendered.rules,
						A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing)),
					topLevel: function () {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$topLevelValue(style);
						if (_v1.$ === 'Nothing') {
							return rendered.topLevel;
						} else {
							var topLevel = _v1.a;
							return A2($elm$core$List$cons, topLevel, rendered.topLevel);
						}
					}()
				};
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			combine,
			{rules: _List_Nil, topLevel: _List_Nil},
			stylesheet);
		var topLevel = _v0.topLevel;
		var rules = _v0.rules;
		return _Utils_ap(
			$mdgriffith$elm_ui$Internal$Model$renderTopLevelValues(topLevel),
			$elm$core$String$concat(rules));
	});
var $mdgriffith$elm_ui$Internal$Model$toStyleSheet = F2(
	function (options, styleSheet) {
		var _v0 = options.mode;
		switch (_v0.$) {
			case 'Layout':
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			case 'NoStaticStyleSheet':
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			default:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'elm-ui-rules',
					_List_fromArray(
						[
							A2(
							$elm$virtual_dom$VirtualDom$property,
							'rules',
							A2($mdgriffith$elm_ui$Internal$Model$encodeStyles, options, styleSheet))
						]),
					_List_Nil);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$embedKeyed = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.focus)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'static-stylesheet',
				$mdgriffith$elm_ui$Internal$Model$staticRoot(opts)),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
				children)) : A2(
			$elm$core$List$cons,
			_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
			children);
	});
var $mdgriffith$elm_ui$Internal$Model$embedWith = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.focus)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Internal$Model$staticRoot(opts),
			A2($elm$core$List$cons, dynamicStyleSheet, children)) : A2($elm$core$List$cons, dynamicStyleSheet, children);
	});
var $mdgriffith$elm_ui$Internal$Flag$heightBetween = $mdgriffith$elm_ui$Internal$Flag$flag(45);
var $mdgriffith$elm_ui$Internal$Flag$heightFill = $mdgriffith$elm_ui$Internal$Flag$flag(37);
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$core$Basics$not = _Basics_not;
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$core$Bitwise$and = _Bitwise_and;
var $mdgriffith$elm_ui$Internal$Flag$present = F2(
	function (myFlag, _v0) {
		var fieldOne = _v0.a;
		var fieldTwo = _v0.b;
		if (myFlag.$ === 'Flag') {
			var first = myFlag.a;
			return _Utils_eq(first & fieldOne, first);
		} else {
			var second = myFlag.a;
			return _Utils_eq(second & fieldTwo, second);
		}
	});
var $elm$html$Html$s = _VirtualDom_node('s');
var $elm$html$Html$u = _VirtualDom_node('u');
var $mdgriffith$elm_ui$Internal$Flag$widthBetween = $mdgriffith$elm_ui$Internal$Flag$flag(44);
var $mdgriffith$elm_ui$Internal$Flag$widthFill = $mdgriffith$elm_ui$Internal$Flag$flag(39);
var $mdgriffith$elm_ui$Internal$Model$finalizeNode = F6(
	function (has, node, attributes, children, embedMode, parentContext) {
		var createNode = F2(
			function (nodeName, attrs) {
				if (children.$ === 'Keyed') {
					var keyed = children.a;
					return A3(
						$elm$virtual_dom$VirtualDom$keyedNode,
						nodeName,
						attrs,
						function () {
							switch (embedMode.$) {
								case 'NoStyleSheet':
									return keyed;
								case 'OnlyDynamic':
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, false, opts, styles, keyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, true, opts, styles, keyed);
							}
						}());
				} else {
					var unkeyed = children.a;
					return A2(
						function () {
							switch (nodeName) {
								case 'div':
									return $elm$html$Html$div;
								case 'p':
									return $elm$html$Html$p;
								default:
									return $elm$virtual_dom$VirtualDom$node(nodeName);
							}
						}(),
						attrs,
						function () {
							switch (embedMode.$) {
								case 'NoStyleSheet':
									return unkeyed;
								case 'OnlyDynamic':
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, false, opts, styles, unkeyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, true, opts, styles, unkeyed);
							}
						}());
				}
			});
		var html = function () {
			switch (node.$) {
				case 'Generic':
					return A2(createNode, 'div', attributes);
				case 'NodeName':
					var nodeName = node.a;
					return A2(createNode, nodeName, attributes);
				default:
					var nodeName = node.a;
					var internal = node.b;
					return A3(
						$elm$virtual_dom$VirtualDom$node,
						nodeName,
						attributes,
						_List_fromArray(
							[
								A2(
								createNode,
								internal,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.any + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.single))
									]))
							]));
			}
		}();
		switch (parentContext.$) {
			case 'AsRow':
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignRight, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.any, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.container, $mdgriffith$elm_ui$Internal$Style$classes.contentCenterY, $mdgriffith$elm_ui$Internal$Style$classes.alignContainerRight])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerX, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.any, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.container, $mdgriffith$elm_ui$Internal$Style$classes.contentCenterY, $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterX])))
						]),
					_List_fromArray(
						[html])) : html));
			case 'AsColumn':
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerY, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.any, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.container, $mdgriffith$elm_ui$Internal$Style$classes.alignContainerCenterY])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignBottom, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.any, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.container, $mdgriffith$elm_ui$Internal$Style$classes.alignContainerBottom])))
						]),
					_List_fromArray(
						[html])) : html));
			default:
				return html;
		}
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $mdgriffith$elm_ui$Internal$Model$textElementClasses = $mdgriffith$elm_ui$Internal$Style$classes.any + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.text + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.widthContent + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.heightContent)))));
var $mdgriffith$elm_ui$Internal$Model$textElement = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$textElementFillClasses = $mdgriffith$elm_ui$Internal$Style$classes.any + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.text + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.widthFill + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.heightFill)))));
var $mdgriffith$elm_ui$Internal$Model$textElementFill = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementFillClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$createElement = F3(
	function (context, children, rendered) {
		var gatherKeyed = F2(
			function (_v8, _v9) {
				var key = _v8.a;
				var child = _v8.b;
				var htmls = _v9.a;
				var existingStyles = _v9.b;
				switch (child.$) {
					case 'Unstyled':
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles);
					case 'Styled':
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.html, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.styles : _Utils_ap(styled.styles, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.html, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.styles : _Utils_ap(styled.styles, existingStyles));
					case 'Text':
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str)),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		var gather = F2(
			function (child, _v6) {
				var htmls = _v6.a;
				var existingStyles = _v6.b;
				switch (child.$) {
					case 'Unstyled':
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles);
					case 'Styled':
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.html, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.styles : _Utils_ap(styled.styles, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.html, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.styles : _Utils_ap(styled.styles, existingStyles));
					case 'Text':
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		if (children.$ === 'Keyed') {
			var keyedChildren = children.a;
			var _v1 = A3(
				$elm$core$List$foldr,
				gatherKeyed,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				keyedChildren);
			var keyed = _v1.a;
			var styles = _v1.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.styles : _Utils_ap(rendered.styles, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.has,
						rendered.node,
						rendered.attributes,
						$mdgriffith$elm_ui$Internal$Model$Keyed(
							A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.children)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						html: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.has,
							rendered.node,
							rendered.attributes,
							$mdgriffith$elm_ui$Internal$Model$Keyed(
								A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.children))),
						styles: allStyles
					});
			}
		} else {
			var unkeyedChildren = children.a;
			var _v3 = A3(
				$elm$core$List$foldr,
				gather,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				unkeyedChildren);
			var unkeyed = _v3.a;
			var styles = _v3.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.styles : _Utils_ap(rendered.styles, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.has,
						rendered.node,
						rendered.attributes,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.children)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						html: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.has,
							rendered.node,
							rendered.attributes,
							$mdgriffith$elm_ui$Internal$Model$Unkeyed(
								A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.children))),
						styles: allStyles
					});
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Single = F3(
	function (a, b, c) {
		return {$: 'Single', a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$Transform = function (a) {
	return {$: 'Transform', a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Field = F2(
	function (a, b) {
		return {$: 'Field', a: a, b: b};
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $mdgriffith$elm_ui$Internal$Flag$add = F2(
	function (myFlag, _v0) {
		var one = _v0.a;
		var two = _v0.b;
		if (myFlag.$ === 'Flag') {
			var first = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, first | one, two);
		} else {
			var second = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, one, second | two);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehind = function (a) {
	return {$: 'ChildrenBehind', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront = F2(
	function (a, b) {
		return {$: 'ChildrenBehindAndInFront', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenInFront = function (a) {
	return {$: 'ChildrenInFront', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$nearbyElement = F2(
	function (location, elem) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					function () {
						switch (location.$) {
							case 'Above':
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.nearby, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.above]));
							case 'Below':
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.nearby, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.below]));
							case 'OnRight':
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.nearby, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.onRight]));
							case 'OnLeft':
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.nearby, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.onLeft]));
							case 'InFront':
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.nearby, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.inFront]));
							default:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.nearby, $mdgriffith$elm_ui$Internal$Style$classes.single, $mdgriffith$elm_ui$Internal$Style$classes.behind]));
						}
					}())
				]),
			_List_fromArray(
				[
					function () {
					switch (elem.$) {
						case 'Empty':
							return $elm$virtual_dom$VirtualDom$text('');
						case 'Text':
							var str = elem.a;
							return $mdgriffith$elm_ui$Internal$Model$textElement(str);
						case 'Unstyled':
							var html = elem.a;
							return html($mdgriffith$elm_ui$Internal$Model$asEl);
						default:
							var styled = elem.a;
							return A2(styled.html, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, $mdgriffith$elm_ui$Internal$Model$asEl);
					}
				}()
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$addNearbyElement = F3(
	function (location, elem, existing) {
		var nearby = A2($mdgriffith$elm_ui$Internal$Model$nearbyElement, location, elem);
		switch (existing.$) {
			case 'NoNearbyChildren':
				if (location.$ === 'Behind') {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						_List_fromArray(
							[nearby]));
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						_List_fromArray(
							[nearby]));
				}
			case 'ChildrenBehind':
				var existingBehind = existing.a;
				if (location.$ === 'Behind') {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						A2($elm$core$List$cons, nearby, existingBehind));
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						_List_fromArray(
							[nearby]));
				}
			case 'ChildrenInFront':
				var existingInFront = existing.a;
				if (location.$ === 'Behind') {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						_List_fromArray(
							[nearby]),
						existingInFront);
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						A2($elm$core$List$cons, nearby, existingInFront));
				}
			default:
				var existingBehind = existing.a;
				var existingInFront = existing.b;
				if (location.$ === 'Behind') {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						A2($elm$core$List$cons, nearby, existingBehind),
						existingInFront);
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						A2($elm$core$List$cons, nearby, existingInFront));
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Embedded = F2(
	function (a, b) {
		return {$: 'Embedded', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$addNodeName = F2(
	function (newNode, old) {
		switch (old.$) {
			case 'Generic':
				return $mdgriffith$elm_ui$Internal$Model$NodeName(newNode);
			case 'NodeName':
				var name = old.a;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, name, newNode);
			default:
				var x = old.a;
				var y = old.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$alignXName = function (align) {
	switch (align.$) {
		case 'Left':
			return $mdgriffith$elm_ui$Internal$Style$classes.alignedHorizontally + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.alignLeft);
		case 'Right':
			return $mdgriffith$elm_ui$Internal$Style$classes.alignedHorizontally + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.alignRight);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.alignedHorizontally + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.alignCenterX);
	}
};
var $mdgriffith$elm_ui$Internal$Model$alignYName = function (align) {
	switch (align.$) {
		case 'Top':
			return $mdgriffith$elm_ui$Internal$Style$classes.alignedVertically + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.alignTop);
		case 'Bottom':
			return $mdgriffith$elm_ui$Internal$Style$classes.alignedVertically + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.alignBottom);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.alignedVertically + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.alignCenterY);
	}
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Model$FullTransform = F4(
	function (a, b, c, d) {
		return {$: 'FullTransform', a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$Moved = function (a) {
	return {$: 'Moved', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$composeTransformation = F2(
	function (transform, component) {
		switch (transform.$) {
			case 'Untransformed':
				switch (component.$) {
					case 'MoveX':
						var x = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, 0, 0));
					case 'MoveY':
						var y = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, y, 0));
					case 'MoveZ':
						var z = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, 0, z));
					case 'MoveXYZ':
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 'Rotate':
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var xyz = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							xyz,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			case 'Moved':
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				switch (component.$) {
					case 'MoveX':
						var newX = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(newX, y, z));
					case 'MoveY':
						var newY = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, newY, z));
					case 'MoveZ':
						var newZ = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, y, newZ));
					case 'MoveXYZ':
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 'Rotate':
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var scale = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							scale,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			default:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				var scaled = transform.b;
				var origin = transform.c;
				var angle = transform.d;
				switch (component.$) {
					case 'MoveX':
						var newX = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(newX, y, z),
							scaled,
							origin,
							angle);
					case 'MoveY':
						var newY = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, newY, z),
							scaled,
							origin,
							angle);
					case 'MoveZ':
						var newZ = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, y, newZ),
							scaled,
							origin,
							angle);
					case 'MoveXYZ':
						var newMove = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, newMove, scaled, origin, angle);
					case 'Rotate':
						var newOrigin = component.a;
						var newAngle = component.b;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, scaled, newOrigin, newAngle);
					default:
						var newScale = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, newScale, origin, angle);
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$height = $mdgriffith$elm_ui$Internal$Flag$flag(7);
var $mdgriffith$elm_ui$Internal$Flag$heightContent = $mdgriffith$elm_ui$Internal$Flag$flag(36);
var $mdgriffith$elm_ui$Internal$Flag$merge = F2(
	function (_v0, _v1) {
		var one = _v0.a;
		var two = _v0.b;
		var three = _v1.a;
		var four = _v1.b;
		return A2($mdgriffith$elm_ui$Internal$Flag$Field, one | three, two | four);
	});
var $mdgriffith$elm_ui$Internal$Flag$none = A2($mdgriffith$elm_ui$Internal$Flag$Field, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$renderHeight = function (h) {
	switch (h.$) {
		case 'Px':
			var px = h.a;
			var val = $elm$core$String$fromInt(px);
			var name = 'height-px-' + val;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.heightExact + (' ' + name),
				_List_fromArray(
					[
						A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height', val + 'px')
					]));
		case 'Content':
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.heightContent,
				_List_Nil);
		case 'Fill':
			var portion = h.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.heightFill,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.heightFillPortion + (' height-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.any + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.column + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'height-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 'Min':
			var minSize = h.a;
			var len = h.b;
			var cls = 'min-height-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-height',
				$elm$core$String$fromInt(minSize) + 'px !important');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = h.a;
			var len = h.b;
			var cls = 'max-height-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-height',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$widthContent = $mdgriffith$elm_ui$Internal$Flag$flag(38);
var $mdgriffith$elm_ui$Internal$Model$renderWidth = function (w) {
	switch (w.$) {
		case 'Px':
			var px = w.a;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.widthExact + (' width-px-' + $elm$core$String$fromInt(px)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						'width-px-' + $elm$core$String$fromInt(px),
						'width',
						$elm$core$String$fromInt(px) + 'px')
					]));
		case 'Content':
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.widthContent,
				_List_Nil);
		case 'Fill':
			var portion = w.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.widthFill,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.widthFillPortion + (' width-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.any + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.row + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'width-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 'Min':
			var minSize = w.a;
			var len = w.b;
			var cls = 'min-width-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-width',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = w.a;
			var len = w.b;
			var cls = 'max-width-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-width',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$borderWidth = $mdgriffith$elm_ui$Internal$Flag$flag(27);
var $elm$core$Basics$ge = _Utils_ge;
var $mdgriffith$elm_ui$Internal$Model$skippable = F2(
	function (flag, style) {
		if (_Utils_eq(flag, $mdgriffith$elm_ui$Internal$Flag$borderWidth)) {
			if (style.$ === 'Single') {
				var val = style.c;
				switch (val) {
					case '0px':
						return true;
					case '1px':
						return true;
					case '2px':
						return true;
					case '3px':
						return true;
					case '4px':
						return true;
					case '5px':
						return true;
					case '6px':
						return true;
					default:
						return false;
				}
			} else {
				return false;
			}
		} else {
			switch (style.$) {
				case 'FontSize':
					var i = style.a;
					return (i >= 8) && (i <= 32);
				case 'PaddingStyle':
					var name = style.a;
					var t = style.b;
					var r = style.c;
					var b = style.d;
					var l = style.e;
					return _Utils_eq(t, b) && (_Utils_eq(t, r) && (_Utils_eq(t, l) && ((t >= 0) && (t <= 24))));
				default:
					return false;
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$width = $mdgriffith$elm_ui$Internal$Flag$flag(6);
var $mdgriffith$elm_ui$Internal$Flag$xAlign = $mdgriffith$elm_ui$Internal$Flag$flag(30);
var $mdgriffith$elm_ui$Internal$Flag$yAlign = $mdgriffith$elm_ui$Internal$Flag$flag(29);
var $mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive = F8(
	function (classes, node, has, transform, styles, attrs, children, elementAttrs) {
		gatherAttrRecursive:
		while (true) {
			if (!elementAttrs.b) {
				var _v1 = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				if (_v1.$ === 'Nothing') {
					return {
						attributes: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes),
							attrs),
						children: children,
						has: has,
						node: node,
						styles: styles
					};
				} else {
					var _class = _v1.a;
					return {
						attributes: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes + (' ' + _class)),
							attrs),
						children: children,
						has: has,
						node: node,
						styles: A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$Transform(transform),
							styles)
					};
				}
			} else {
				var attribute = elementAttrs.a;
				var remaining = elementAttrs.b;
				switch (attribute.$) {
					case 'NoAttribute':
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 'Class':
						var flag = attribute.a;
						var exactClassName = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = exactClassName + (' ' + classes),
								$temp$node = node,
								$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					case 'Attr':
						var actualAttribute = attribute.a;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = A2($elm$core$List$cons, actualAttribute, attrs),
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 'StyleClass':
						var flag = attribute.a;
						var style = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							if (A2($mdgriffith$elm_ui$Internal$Model$skippable, flag, style)) {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							} else {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = A2($elm$core$List$cons, style, styles),
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							}
						}
					case 'TransformComponent':
						var flag = attribute.a;
						var component = attribute.b;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
							$temp$transform = A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, transform, component),
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 'Width':
						var width = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$width, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (width.$) {
								case 'Px':
									var px = width.a;
									var $temp$classes = ($mdgriffith$elm_ui$Internal$Style$classes.widthExact + (' width-px-' + $elm$core$String$fromInt(px))) + (' ' + classes),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3(
											$mdgriffith$elm_ui$Internal$Model$Single,
											'width-px-' + $elm$core$String$fromInt(px),
											'width',
											$elm$core$String$fromInt(px) + 'px'),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 'Content':
									var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.widthContent),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$widthContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 'Fill':
									var portion = width.a;
									if (portion === 1) {
										var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.widthFill),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.widthFillPortion + (' width-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.any + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.row + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'width-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v4 = $mdgriffith$elm_ui$Internal$Model$renderWidth(width);
									var addToFlags = _v4.a;
									var newClass = _v4.b;
									var newStyles = _v4.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 'Height':
						var height = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$height, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (height.$) {
								case 'Px':
									var px = height.a;
									var val = $elm$core$String$fromInt(px) + 'px';
									var name = 'height-px-' + val;
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.heightExact + (' ' + (name + (' ' + classes))),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height ', val),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 'Content':
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.heightContent + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$heightContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 'Fill':
									var portion = height.a;
									if (portion === 1) {
										var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.heightFill + (' ' + classes),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.heightFillPortion + (' height-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.any + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.column + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'height-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v6 = $mdgriffith$elm_ui$Internal$Model$renderHeight(height);
									var addToFlags = _v6.a;
									var newClass = _v6.b;
									var newStyles = _v6.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 'Describe':
						var description = attribute.a;
						switch (description.$) {
							case 'Main':
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'main', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 'Navigation':
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'nav', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 'ContentInfo':
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'footer', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 'Complementary':
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'aside', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 'Heading':
								var i = description.a;
								if (i <= 1) {
									var $temp$classes = classes,
										$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h1', node),
										$temp$has = has,
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								} else {
									if (i < 7) {
										var $temp$classes = classes,
											$temp$node = A2(
											$mdgriffith$elm_ui$Internal$Model$addNodeName,
											'h' + $elm$core$String$fromInt(i),
											node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes,
											$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h6', node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								}
							case 'Paragraph':
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 'Button':
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'role', 'button'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 'Label':
								var label = description.a;
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-label', label),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 'LivePolite':
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'polite'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							default:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'assertive'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
						}
					case 'Nearby':
						var location = attribute.a;
						var elem = attribute.b;
						var newStyles = function () {
							switch (elem.$) {
								case 'Empty':
									return styles;
								case 'Text':
									var str = elem.a;
									return styles;
								case 'Unstyled':
									var html = elem.a;
									return styles;
								default:
									var styled = elem.a;
									return _Utils_ap(styles, styled.styles);
							}
						}();
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = newStyles,
							$temp$attrs = attrs,
							$temp$children = A3($mdgriffith$elm_ui$Internal$Model$addNearbyElement, location, elem, children),
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 'AlignX':
						var x = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignXName(x) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (x.$) {
									case 'CenterX':
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerX, flags);
									case 'Right':
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignRight, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					default:
						var y = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignYName(y) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (y.$) {
									case 'CenterY':
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerY, flags);
									case 'Bottom':
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignBottom, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Untransformed = {$: 'Untransformed'};
var $mdgriffith$elm_ui$Internal$Model$untransformed = $mdgriffith$elm_ui$Internal$Model$Untransformed;
var $mdgriffith$elm_ui$Internal$Model$element = F4(
	function (context, node, attributes, children) {
		return A3(
			$mdgriffith$elm_ui$Internal$Model$createElement,
			context,
			children,
			A8(
				$mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive,
				$mdgriffith$elm_ui$Internal$Model$contextClasses(context),
				node,
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Model$untransformed,
				_List_Nil,
				_List_Nil,
				$mdgriffith$elm_ui$Internal$Model$NoNearbyChildren,
				$elm$core$List$reverse(attributes)));
	});
var $mdgriffith$elm_ui$Internal$Model$htmlClass = function (cls) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		$elm$html$Html$Attributes$class(cls));
};
var $mdgriffith$elm_ui$Element$Input$applyLabel = F3(
	function (attrs, label, input) {
		if (label.$ === 'HiddenLabel') {
			var labelText = label.a;
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asColumn,
				$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
				attrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[input])));
		} else {
			var position = label.a;
			var labelAttrs = label.b;
			var labelChild = label.c;
			var labelElement = A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				labelAttrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[labelChild])));
			switch (position.$) {
				case 'Above':
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.inputLabel),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
				case 'Below':
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.inputLabel),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				case 'OnRight':
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.inputLabel),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				default:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.inputLabel),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
			}
		}
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $mdgriffith$elm_ui$Element$Input$autofill = A2(
	$elm$core$Basics$composeL,
	$mdgriffith$elm_ui$Internal$Model$Attr,
	$elm$html$Html$Attributes$attribute('autocomplete'));
var $mdgriffith$elm_ui$Internal$Model$Behind = {$: 'Behind'};
var $mdgriffith$elm_ui$Internal$Model$Nearby = F2(
	function (a, b) {
		return {$: 'Nearby', a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$createNearby = F2(
	function (loc, element) {
		if (element.$ === 'Empty') {
			return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
		} else {
			return A2($mdgriffith$elm_ui$Internal$Model$Nearby, loc, element);
		}
	});
var $mdgriffith$elm_ui$Element$behindContent = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, $mdgriffith$elm_ui$Internal$Model$Behind, element);
};
var $mdgriffith$elm_ui$Internal$Model$MoveY = function (a) {
	return {$: 'MoveY', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$TransformComponent = F2(
	function (a, b) {
		return {$: 'TransformComponent', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$moveY = $mdgriffith$elm_ui$Internal$Flag$flag(26);
var $mdgriffith$elm_ui$Element$moveUp = function (y) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveY,
		$mdgriffith$elm_ui$Internal$Model$MoveY(-y));
};
var $mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding = function (attrs) {
	var gatherSpacing = F2(
		function (attr, found) {
			if ((attr.$ === 'StyleClass') && (attr.b.$ === 'SpacingStyle')) {
				var _v2 = attr.b;
				var x = _v2.b;
				var y = _v2.c;
				if (found.$ === 'Nothing') {
					return $elm$core$Maybe$Just(y);
				} else {
					return found;
				}
			} else {
				return found;
			}
		});
	var _v0 = A3($elm$core$List$foldr, gatherSpacing, $elm$core$Maybe$Nothing, attrs);
	if (_v0.$ === 'Nothing') {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	} else {
		var vSpace = _v0.a;
		return $mdgriffith$elm_ui$Element$moveUp(
			$elm$core$Basics$floor(vSpace / 2));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$overflow = $mdgriffith$elm_ui$Internal$Flag$flag(20);
var $mdgriffith$elm_ui$Element$clip = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.clip);
var $mdgriffith$elm_ui$Internal$Flag$cursor = $mdgriffith$elm_ui$Internal$Flag$flag(21);
var $mdgriffith$elm_ui$Internal$Model$Colored = F3(
	function (a, b, c) {
		return {$: 'Colored', a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$StyleClass = F2(
	function (a, b) {
		return {$: 'StyleClass', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$bgColor = $mdgriffith$elm_ui$Internal$Flag$flag(8);
var $mdgriffith$elm_ui$Internal$Model$formatColorClass = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return $mdgriffith$elm_ui$Internal$Model$floatClass(red) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(green) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(blue) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(alpha))))));
};
var $mdgriffith$elm_ui$Element$Background$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$bgColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'background-color',
			clr));
};
var $mdgriffith$elm_ui$Internal$Flag$borderColor = $mdgriffith$elm_ui$Internal$Flag$flag(28);
var $mdgriffith$elm_ui$Element$Border$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'border-color',
			clr));
};
var $mdgriffith$elm_ui$Internal$Model$Rgba = F4(
	function (a, b, c, d) {
		return {$: 'Rgba', a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Element$rgb = F3(
	function (r, g, b) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, r, g, b, 1);
	});
var $mdgriffith$elm_ui$Element$Input$darkGrey = A3($mdgriffith$elm_ui$Element$rgb, 186 / 255, 189 / 255, 182 / 255);
var $mdgriffith$elm_ui$Internal$Model$PaddingStyle = F5(
	function (a, b, c, d, e) {
		return {$: 'PaddingStyle', a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Flag$padding = $mdgriffith$elm_ui$Internal$Flag$flag(2);
var $mdgriffith$elm_ui$Element$paddingXY = F2(
	function (x, y) {
		if (_Utils_eq(x, y)) {
			var f = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + $elm$core$String$fromInt(x),
					f,
					f,
					f,
					f));
		} else {
			var yFloat = y;
			var xFloat = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
					yFloat,
					xFloat,
					yFloat,
					xFloat));
		}
	});
var $mdgriffith$elm_ui$Element$Input$defaultTextPadding = A2($mdgriffith$elm_ui$Element$paddingXY, 12, 12);
var $mdgriffith$elm_ui$Internal$Model$Fill = function (a) {
	return {$: 'Fill', a: a};
};
var $mdgriffith$elm_ui$Element$fill = $mdgriffith$elm_ui$Internal$Model$Fill(1);
var $mdgriffith$elm_ui$Internal$Model$Height = function (a) {
	return {$: 'Height', a: a};
};
var $mdgriffith$elm_ui$Element$height = $mdgriffith$elm_ui$Internal$Model$Height;
var $mdgriffith$elm_ui$Internal$Flag$borderRound = $mdgriffith$elm_ui$Internal$Flag$flag(17);
var $mdgriffith$elm_ui$Element$Border$rounded = function (radius) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + $elm$core$String$fromInt(radius),
			'border-radius',
			$elm$core$String$fromInt(radius) + 'px'));
};
var $mdgriffith$elm_ui$Internal$Model$Content = {$: 'Content'};
var $mdgriffith$elm_ui$Element$shrink = $mdgriffith$elm_ui$Internal$Model$Content;
var $mdgriffith$elm_ui$Internal$Model$SpacingStyle = F3(
	function (a, b, c) {
		return {$: 'SpacingStyle', a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Flag$spacing = $mdgriffith$elm_ui$Internal$Flag$flag(3);
var $mdgriffith$elm_ui$Internal$Model$spacingName = F2(
	function (x, y) {
		return 'spacing-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y)));
	});
var $mdgriffith$elm_ui$Element$spacing = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$spacing,
		A3(
			$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
			A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, x),
			x,
			x));
};
var $mdgriffith$elm_ui$Element$Input$white = A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1);
var $mdgriffith$elm_ui$Internal$Model$Width = function (a) {
	return {$: 'Width', a: a};
};
var $mdgriffith$elm_ui$Element$width = $mdgriffith$elm_ui$Internal$Model$Width;
var $mdgriffith$elm_ui$Internal$Model$BorderWidth = F5(
	function (a, b, c, d, e) {
		return {$: 'BorderWidth', a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Element$Border$width = function (v) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + $elm$core$String$fromInt(v),
			v,
			v,
			v,
			v));
};
var $mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Input$defaultTextPadding,
		$mdgriffith$elm_ui$Element$Border$rounded(3),
		$mdgriffith$elm_ui$Element$Border$color($mdgriffith$elm_ui$Element$Input$darkGrey),
		$mdgriffith$elm_ui$Element$Background$color($mdgriffith$elm_ui$Element$Input$white),
		$mdgriffith$elm_ui$Element$Border$width(1),
		$mdgriffith$elm_ui$Element$spacing(5),
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
		$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
	]);
var $mdgriffith$elm_ui$Element$Input$getHeight = function (attr) {
	if (attr.$ === 'Height') {
		var h = attr.a;
		return $elm$core$Maybe$Just(h);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Element$Input$hasFocusStyle = function (attr) {
	if (((attr.$ === 'StyleClass') && (attr.b.$ === 'PseudoSelector')) && (attr.b.a.$ === 'Focus')) {
		var _v1 = attr.b;
		var _v2 = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Label = function (a) {
	return {$: 'Label', a: a};
};
var $mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute = function (label) {
	if (label.$ === 'HiddenLabel') {
		var textLabel = label.a;
		return $mdgriffith$elm_ui$Internal$Model$Describe(
			$mdgriffith$elm_ui$Internal$Model$Label(textLabel));
	} else {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	}
};
var $mdgriffith$elm_ui$Internal$Model$InFront = {$: 'InFront'};
var $mdgriffith$elm_ui$Element$inFront = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, $mdgriffith$elm_ui$Internal$Model$InFront, element);
};
var $mdgriffith$elm_ui$Element$Input$isConstrained = function (len) {
	isConstrained:
	while (true) {
		switch (len.$) {
			case 'Content':
				return false;
			case 'Px':
				return true;
			case 'Fill':
				return true;
			case 'Min':
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isConstrained;
			default:
				var l = len.b;
				return true;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isHiddenLabel = function (label) {
	if (label.$ === 'HiddenLabel') {
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Element$Input$isStacked = function (label) {
	if (label.$ === 'Label') {
		var loc = label.a;
		switch (loc.$) {
			case 'OnRight':
				return false;
			case 'OnLeft':
				return false;
			case 'Above':
				return true;
			default:
				return true;
		}
	} else {
		return true;
	}
};
var $mdgriffith$elm_ui$Element$Input$negateBox = function (box) {
	return {bottom: -box.bottom, left: -box.left, right: -box.right, top: -box.top};
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 'MayStopPropagation', a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $mdgriffith$elm_ui$Internal$Model$paddingName = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left)))))));
	});
var $mdgriffith$elm_ui$Element$paddingEach = function (_v0) {
	var top = _v0.top;
	var right = _v0.right;
	var bottom = _v0.bottom;
	var left = _v0.left;
	if (_Utils_eq(top, right) && (_Utils_eq(top, bottom) && _Utils_eq(top, left))) {
		var topFloat = top;
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + $elm$core$String$fromInt(top),
				topFloat,
				topFloat,
				topFloat,
				topFloat));
	} else {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				A4($mdgriffith$elm_ui$Internal$Model$paddingName, top, right, bottom, left),
				top,
				right,
				bottom,
				left));
	}
};
var $mdgriffith$elm_ui$Element$htmlAttribute = $mdgriffith$elm_ui$Internal$Model$Attr;
var $mdgriffith$elm_ui$Element$Input$isFill = function (len) {
	isFill:
	while (true) {
		switch (len.$) {
			case 'Fill':
				return true;
			case 'Content':
				return false;
			case 'Px':
				return false;
			case 'Min':
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isPixel = function (len) {
	isPixel:
	while (true) {
		switch (len.$) {
			case 'Content':
				return false;
			case 'Px':
				return true;
			case 'Fill':
				return false;
			case 'Min':
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
		}
	}
};
var $mdgriffith$elm_ui$Internal$Model$paddingNameFloat = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(top) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(right) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(bottom) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(left)))))));
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $mdgriffith$elm_ui$Element$Input$redistributeOver = F4(
	function (isMultiline, stacked, attr, els) {
		switch (attr.$) {
			case 'Nearby':
				return _Utils_update(
					els,
					{
						parent: A2($elm$core$List$cons, attr, els.parent)
					});
			case 'Width':
				var width = attr.a;
				return $mdgriffith$elm_ui$Element$Input$isFill(width) ? _Utils_update(
					els,
					{
						fullParent: A2($elm$core$List$cons, attr, els.fullParent),
						input: A2($elm$core$List$cons, attr, els.input),
						parent: A2($elm$core$List$cons, attr, els.parent)
					}) : (stacked ? _Utils_update(
					els,
					{
						fullParent: A2($elm$core$List$cons, attr, els.fullParent)
					}) : _Utils_update(
					els,
					{
						parent: A2($elm$core$List$cons, attr, els.parent)
					}));
			case 'Height':
				var height = attr.a;
				return (!stacked) ? _Utils_update(
					els,
					{
						fullParent: A2($elm$core$List$cons, attr, els.fullParent),
						parent: A2($elm$core$List$cons, attr, els.parent)
					}) : ($mdgriffith$elm_ui$Element$Input$isFill(height) ? _Utils_update(
					els,
					{
						fullParent: A2($elm$core$List$cons, attr, els.fullParent),
						parent: A2($elm$core$List$cons, attr, els.parent)
					}) : ($mdgriffith$elm_ui$Element$Input$isPixel(height) ? _Utils_update(
					els,
					{
						parent: A2($elm$core$List$cons, attr, els.parent)
					}) : _Utils_update(
					els,
					{
						parent: A2($elm$core$List$cons, attr, els.parent)
					})));
			case 'AlignX':
				return _Utils_update(
					els,
					{
						fullParent: A2($elm$core$List$cons, attr, els.fullParent)
					});
			case 'AlignY':
				return _Utils_update(
					els,
					{
						fullParent: A2($elm$core$List$cons, attr, els.fullParent)
					});
			case 'StyleClass':
				switch (attr.b.$) {
					case 'SpacingStyle':
						var _v1 = attr.b;
						return _Utils_update(
							els,
							{
								fullParent: A2($elm$core$List$cons, attr, els.fullParent),
								input: A2($elm$core$List$cons, attr, els.input),
								parent: A2($elm$core$List$cons, attr, els.parent),
								wrapper: A2($elm$core$List$cons, attr, els.wrapper)
							});
					case 'PaddingStyle':
						var cls = attr.a;
						var _v2 = attr.b;
						var pad = _v2.a;
						var t = _v2.b;
						var r = _v2.c;
						var b = _v2.d;
						var l = _v2.e;
						if (isMultiline) {
							return _Utils_update(
								els,
								{
									cover: A2($elm$core$List$cons, attr, els.cover),
									parent: A2($elm$core$List$cons, attr, els.parent)
								});
						} else {
							var newTop = t - A2($elm$core$Basics$min, t, b);
							var newLineHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'line-height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newBottom = b - A2($elm$core$Basics$min, t, b);
							var reducedVerticalPadding = A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, r, newBottom, l),
									newTop,
									r,
									newBottom,
									l));
							return _Utils_update(
								els,
								{
									cover: A2($elm$core$List$cons, attr, els.cover),
									input: A2(
										$elm$core$List$cons,
										newHeight,
										A2($elm$core$List$cons, newLineHeight, els.input)),
									parent: A2($elm$core$List$cons, reducedVerticalPadding, els.parent)
								});
						}
					case 'BorderWidth':
						var _v3 = attr.b;
						return _Utils_update(
							els,
							{
								cover: A2($elm$core$List$cons, attr, els.cover),
								parent: A2($elm$core$List$cons, attr, els.parent)
							});
					case 'Transform':
						return _Utils_update(
							els,
							{
								cover: A2($elm$core$List$cons, attr, els.cover),
								parent: A2($elm$core$List$cons, attr, els.parent)
							});
					case 'FontSize':
						return _Utils_update(
							els,
							{
								fullParent: A2($elm$core$List$cons, attr, els.fullParent)
							});
					case 'FontFamily':
						var _v4 = attr.b;
						return _Utils_update(
							els,
							{
								fullParent: A2($elm$core$List$cons, attr, els.fullParent)
							});
					default:
						var flag = attr.a;
						var cls = attr.b;
						return _Utils_update(
							els,
							{
								parent: A2($elm$core$List$cons, attr, els.parent)
							});
				}
			case 'NoAttribute':
				return els;
			case 'Attr':
				var a = attr.a;
				return _Utils_update(
					els,
					{
						input: A2($elm$core$List$cons, attr, els.input)
					});
			case 'Describe':
				return _Utils_update(
					els,
					{
						input: A2($elm$core$List$cons, attr, els.input)
					});
			case 'Class':
				return _Utils_update(
					els,
					{
						parent: A2($elm$core$List$cons, attr, els.parent)
					});
			default:
				return _Utils_update(
					els,
					{
						input: A2($elm$core$List$cons, attr, els.input)
					});
		}
	});
var $mdgriffith$elm_ui$Element$Input$redistribute = F3(
	function (isMultiline, stacked, attrs) {
		return function (redist) {
			return {
				cover: $elm$core$List$reverse(redist.cover),
				fullParent: $elm$core$List$reverse(redist.fullParent),
				input: $elm$core$List$reverse(redist.input),
				parent: $elm$core$List$reverse(redist.parent),
				wrapper: $elm$core$List$reverse(redist.wrapper)
			};
		}(
			A3(
				$elm$core$List$foldl,
				A2($mdgriffith$elm_ui$Element$Input$redistributeOver, isMultiline, stacked),
				{cover: _List_Nil, fullParent: _List_Nil, input: _List_Nil, parent: _List_Nil, wrapper: _List_Nil},
				attrs));
	});
var $mdgriffith$elm_ui$Element$Input$renderBox = function (_v0) {
	var top = _v0.top;
	var right = _v0.right;
	var bottom = _v0.bottom;
	var left = _v0.left;
	return $elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px'))))));
};
var $mdgriffith$elm_ui$Internal$Model$Transparency = F2(
	function (a, b) {
		return {$: 'Transparency', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$transparency = $mdgriffith$elm_ui$Internal$Flag$flag(0);
var $mdgriffith$elm_ui$Element$alpha = function (o) {
	var transparency = function (x) {
		return 1 - x;
	}(
		A2(
			$elm$core$Basics$min,
			1.0,
			A2($elm$core$Basics$max, 0.0, o)));
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Transparency,
			'transparency-' + $mdgriffith$elm_ui$Internal$Model$floatClass(transparency),
			transparency));
};
var $mdgriffith$elm_ui$Element$Input$charcoal = A3($mdgriffith$elm_ui$Element$rgb, 136 / 255, 138 / 255, 133 / 255);
var $mdgriffith$elm_ui$Internal$Flag$fontColor = $mdgriffith$elm_ui$Internal$Flag$flag(14);
var $mdgriffith$elm_ui$Element$Font$color = function (fontColor) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(fontColor),
			'color',
			fontColor));
};
var $mdgriffith$elm_ui$Element$el = F2(
	function (attrs, child) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[child])));
	});
var $mdgriffith$elm_ui$Element$rgba = $mdgriffith$elm_ui$Internal$Model$Rgba;
var $mdgriffith$elm_ui$Element$Input$renderPlaceholder = F3(
	function (_v0, forPlaceholder, on) {
		var placeholderAttrs = _v0.a;
		var placeholderEl = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				forPlaceholder,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($mdgriffith$elm_ui$Element$Input$charcoal),
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.noTextSelection + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.passPointerEvents)),
							$mdgriffith$elm_ui$Element$clip,
							$mdgriffith$elm_ui$Element$Border$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$Background$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alpha(
							on ? 1 : 0)
						]),
					placeholderAttrs)),
			placeholderEl);
	});
var $mdgriffith$elm_ui$Element$scrollbarY = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.scrollbarsY);
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$spellcheck = $elm$html$Html$Attributes$boolProperty('spellcheck');
var $mdgriffith$elm_ui$Element$Input$spellcheck = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$spellcheck);
var $mdgriffith$elm_ui$Internal$Model$Text = function (a) {
	return {$: 'Text', a: a};
};
var $mdgriffith$elm_ui$Element$text = function (content) {
	return $mdgriffith$elm_ui$Internal$Model$Text(content);
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $mdgriffith$elm_ui$Internal$Model$unstyled = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Unstyled, $elm$core$Basics$always);
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $mdgriffith$elm_ui$Element$Input$value = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$value);
var $mdgriffith$elm_ui$Element$Input$textHelper = F3(
	function (textInput, attrs, textOptions) {
		var withDefaults = _Utils_ap($mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle, attrs);
		var redistributed = A3(
			$mdgriffith$elm_ui$Element$Input$redistribute,
			_Utils_eq(textInput.type_, $mdgriffith$elm_ui$Element$Input$TextArea),
			$mdgriffith$elm_ui$Element$Input$isStacked(textOptions.label),
			withDefaults);
		var onlySpacing = function (attr) {
			if ((attr.$ === 'StyleClass') && (attr.b.$ === 'SpacingStyle')) {
				var _v9 = attr.b;
				return true;
			} else {
				return false;
			}
		};
		var heightConstrained = function () {
			var _v7 = textInput.type_;
			if (_v7.$ === 'TextInputNode') {
				var inputType = _v7.a;
				return false;
			} else {
				return A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						$mdgriffith$elm_ui$Element$Input$isConstrained,
						$elm$core$List$head(
							$elm$core$List$reverse(
								A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Element$Input$getHeight, withDefaults)))));
			}
		}();
		var getPadding = function (attr) {
			if ((attr.$ === 'StyleClass') && (attr.b.$ === 'PaddingStyle')) {
				var cls = attr.a;
				var _v6 = attr.b;
				var pad = _v6.a;
				var t = _v6.b;
				var r = _v6.c;
				var b = _v6.d;
				var l = _v6.e;
				return $elm$core$Maybe$Just(
					{
						bottom: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(b - 3)),
						left: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(l - 3)),
						right: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(r - 3)),
						top: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(t - 3))
					});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var parentPadding = A2(
			$elm$core$Maybe$withDefault,
			{bottom: 0, left: 0, right: 0, top: 0},
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2($elm$core$List$filterMap, getPadding, withDefaults))));
		var inputElement = A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			function () {
				var _v3 = textInput.type_;
				if (_v3.$ === 'TextInputNode') {
					var inputType = _v3.a;
					return $mdgriffith$elm_ui$Internal$Model$NodeName('input');
				} else {
					return $mdgriffith$elm_ui$Internal$Model$NodeName('textarea');
				}
			}(),
			_Utils_ap(
				function () {
					var _v4 = textInput.type_;
					if (_v4.$ === 'TextInputNode') {
						var inputType = _v4.a;
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$type_(inputType)),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.inputText)
							]);
					} else {
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$clip,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.inputMultiline),
								$mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding(withDefaults),
								$mdgriffith$elm_ui$Element$paddingEach(parentPadding),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2(
									$elm$html$Html$Attributes$style,
									'margin',
									$mdgriffith$elm_ui$Element$Input$renderBox(
										$mdgriffith$elm_ui$Element$Input$negateBox(parentPadding)))),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$style, 'box-sizing', 'content-box'))
							]);
					}
				}(),
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Input$value(textOptions.text),
							$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Events$onInput(textOptions.onChange)),
							$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(textOptions.label),
							$mdgriffith$elm_ui$Element$Input$spellcheck(textInput.spellchecked),
							A2(
							$elm$core$Maybe$withDefault,
							$mdgriffith$elm_ui$Internal$Model$NoAttribute,
							A2($elm$core$Maybe$map, $mdgriffith$elm_ui$Element$Input$autofill, textInput.autofill))
						]),
					redistributed.input)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil));
		var wrappedInput = function () {
			var _v0 = textInput.type_;
			if (_v0.$ === 'TextArea') {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_Utils_ap(
						(heightConstrained ? $elm$core$List$cons($mdgriffith$elm_ui$Element$scrollbarY) : $elm$core$Basics$identity)(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.focusedWithin),
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.inputMultilineWrapper)
								])),
						redistributed.parent),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asParagraph,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Element$inFront(inputElement),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.inputMultilineParent),
												redistributed.wrapper)))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(
									function () {
										if (textOptions.text === '') {
											var _v1 = textOptions.placeholder;
											if (_v1.$ === 'Nothing') {
												return _List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('\u00A0')
													]);
											} else {
												var place = _v1.a;
												return _List_fromArray(
													[
														A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, _List_Nil, textOptions.text === '')
													]);
											}
										} else {
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Internal$Model$unstyled(
													A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.inputMultilineFiller)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(textOptions.text + '\u00A0')
															])))
												]);
										}
									}()))
							])));
			} else {
				var inputType = _v0.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						A2(
							$elm$core$List$cons,
							A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.focusedWithin),
							$elm$core$List$concat(
								_List_fromArray(
									[
										redistributed.parent,
										function () {
										var _v2 = textOptions.placeholder;
										if (_v2.$ === 'Nothing') {
											return _List_Nil;
										} else {
											var place = _v2.a;
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Element$behindContent(
													A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, redistributed.cover, textOptions.text === ''))
												]);
										}
									}()
									])))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[inputElement])));
			}
		}();
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				$elm$core$List$cons,
				A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.cursorText),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(textOptions.label) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(5),
					A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Region$announce, redistributed.fullParent))),
			textOptions.label,
			wrappedInput);
	});
var $mdgriffith$elm_ui$Element$Input$currentPassword = F2(
	function (attrs, pass) {
		return A3(
			$mdgriffith$elm_ui$Element$Input$textHelper,
			{
				autofill: $elm$core$Maybe$Just('current-password'),
				spellchecked: false,
				type_: $mdgriffith$elm_ui$Element$Input$TextInputNode(
					pass.show ? 'text' : 'password')
			},
			attrs,
			{label: pass.label, onChange: pass.onChange, placeholder: pass.placeholder, text: pass.text});
	});
var $author$project$UI$Form$Field = function (a) {
	return {$: 'Field', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AlignX = function (a) {
	return {$: 'AlignX', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Right = {$: 'Right'};
var $mdgriffith$elm_ui$Element$alignRight = $mdgriffith$elm_ui$Internal$Model$AlignX($mdgriffith$elm_ui$Internal$Model$Right);
var $mdgriffith$elm_ui$Internal$Model$Below = {$: 'Below'};
var $mdgriffith$elm_ui$Element$below = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, $mdgriffith$elm_ui$Internal$Model$Below, element);
};
var $author$project$UI$defaultSize = 13;
var $elm$core$Basics$pow = _Basics_pow;
var $mdgriffith$elm_ui$Element$modular = F3(
	function (normal, ratio, rescale) {
		return (!rescale) ? normal : ((rescale < 0) ? (normal * A2($elm$core$Basics$pow, ratio, rescale)) : (normal * A2($elm$core$Basics$pow, ratio, rescale - 1)));
	});
var $author$project$UI$scaled = A2(
	$elm$core$Basics$composeR,
	A2($mdgriffith$elm_ui$Element$modular, $author$project$UI$defaultSize, 1.25),
	$elm$core$Basics$round);
var $mdgriffith$elm_ui$Internal$Flag$borderStyle = $mdgriffith$elm_ui$Internal$Flag$flag(11);
var $mdgriffith$elm_ui$Element$Border$solid = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$borderStyle, $mdgriffith$elm_ui$Internal$Style$classes.borderSolid);
var $author$project$UI$border = function (color) {
	return _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Border$rounded(
			$author$project$UI$scaled(-2)),
			$mdgriffith$elm_ui$Element$Border$width(1),
			$mdgriffith$elm_ui$Element$Border$solid,
			$mdgriffith$elm_ui$Element$Border$color(color)
		]);
};
var $author$project$UI$colors = {
	bad: A3($mdgriffith$elm_ui$Element$rgb, 0.1, 0.1, 1),
	error: A3($mdgriffith$elm_ui$Element$rgb, 0.6, 0, 0),
	good: A3($mdgriffith$elm_ui$Element$rgb, 0.2, 1, 0.2),
	neutral_0: A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1),
	neutral_100: A3($mdgriffith$elm_ui$Element$rgb, 0, 0, 0),
	neutral_20: A3($mdgriffith$elm_ui$Element$rgb, 0.8, 0.8, 0.8),
	neutral_60: A3($mdgriffith$elm_ui$Element$rgb, 0.4, 0.4, 0.4),
	primary: A3($mdgriffith$elm_ui$Element$rgb, 187 / 255, 79 / 255, 59 / 255),
	transparent: A4($mdgriffith$elm_ui$Element$rgba, 1, 1, 1, 0),
	transparentRed: A4($mdgriffith$elm_ui$Element$rgba, 0.7, 0, 0, 0.6),
	uncertain: A3($mdgriffith$elm_ui$Element$rgb, 0.2, 0.4, 1)
};
var $mdgriffith$elm_ui$Element$column = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentTop + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.contentLeft)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Internal$Model$Focus = {$: 'Focus'};
var $mdgriffith$elm_ui$Internal$Model$PseudoSelector = F2(
	function (a, b) {
		return {$: 'PseudoSelector', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$focus = $mdgriffith$elm_ui$Internal$Flag$flag(31);
var $mdgriffith$elm_ui$Internal$Model$AlignY = function (a) {
	return {$: 'AlignY', a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Empty = {$: 'Empty'};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $mdgriffith$elm_ui$Internal$Model$map = F2(
	function (fn, el) {
		switch (el.$) {
			case 'Styled':
				var styled = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						html: F2(
							function (add, context) {
								return A2(
									$elm$virtual_dom$VirtualDom$map,
									fn,
									A2(styled.html, add, context));
							}),
						styles: styled.styles
					});
			case 'Unstyled':
				var html = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A2(
						$elm$core$Basics$composeL,
						$elm$virtual_dom$VirtualDom$map(fn),
						html));
			case 'Text':
				var str = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Text(str);
			default:
				return $mdgriffith$elm_ui$Internal$Model$Empty;
		}
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 'NoAttribute':
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 'Describe':
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 'AlignX':
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 'AlignY':
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 'Width':
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 'Height':
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 'Class':
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 'StyleClass':
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 'Nearby':
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 'Attr':
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$removeNever = function (style) {
	return A2($mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle, $elm$core$Basics$never, style);
};
var $mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper = F2(
	function (attr, _v0) {
		var styles = _v0.a;
		var trans = _v0.b;
		var _v1 = $mdgriffith$elm_ui$Internal$Model$removeNever(attr);
		switch (_v1.$) {
			case 'StyleClass':
				var style = _v1.b;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, style, styles),
					trans);
			case 'TransformComponent':
				var flag = _v1.a;
				var component = _v1.b;
				return _Utils_Tuple2(
					styles,
					A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, trans, component));
			default:
				return _Utils_Tuple2(styles, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$unwrapDecorations = function (attrs) {
	var _v0 = A3(
		$elm$core$List$foldl,
		$mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper,
		_Utils_Tuple2(_List_Nil, $mdgriffith$elm_ui$Internal$Model$Untransformed),
		attrs);
	var styles = _v0.a;
	var transform = _v0.b;
	return A2(
		$elm$core$List$cons,
		$mdgriffith$elm_ui$Internal$Model$Transform(transform),
		styles);
};
var $mdgriffith$elm_ui$Element$focused = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$focus,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			$mdgriffith$elm_ui$Internal$Model$Focus,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $author$project$UI$focused = function (attrs) {
	return $mdgriffith$elm_ui$Element$focused(
		A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Element$Border$color($author$project$UI$colors.primary),
			attrs));
};
var $elm$html$Html$Attributes$autofocus = $elm$html$Html$Attributes$boolProperty('autofocus');
var $mdgriffith$elm_ui$Element$Input$focusedOnLoad = $mdgriffith$elm_ui$Internal$Model$Attr(
	$elm$html$Html$Attributes$autofocus(true));
var $author$project$UI$Form$isRequired = function (_v0) {
	var required = _v0.required;
	switch (required.$) {
		case 'Required':
			return true;
		case 'NotRequired':
			return false;
		default:
			return false;
	}
};
var $mdgriffith$elm_ui$Element$html = $mdgriffith$elm_ui$Internal$Model$unstyled;
var $author$project$UI$materialIcon = F2(
	function (name, size) {
		return $mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('material-symbols-outlined'),
						A2(
						$elm$html$Html$Attributes$style,
						'font-size',
						$elm$core$String$fromInt(size) + 'px'),
						A2($elm$html$Html$Attributes$style, 'user-select', 'none')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(name)
					])));
	});
var $mdgriffith$elm_ui$Internal$Model$Hover = {$: 'Hover'};
var $mdgriffith$elm_ui$Internal$Flag$hover = $mdgriffith$elm_ui$Internal$Flag$flag(33);
var $mdgriffith$elm_ui$Element$mouseOver = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$hover,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			$mdgriffith$elm_ui$Internal$Model$Hover,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $mdgriffith$elm_ui$Element$none = $mdgriffith$elm_ui$Internal$Model$Empty;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 'Normal', a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $author$project$Utils$onEnter = function (msg) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$elm$html$Html$Events$on,
			'keyup',
			A2(
				$elm$json$Json$Decode$andThen,
				function (key) {
					return (key === 'Enter') ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('Not the enter key');
				},
				A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string))));
};
var $elm$html$Html$Events$onMouseDown = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mousedown',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onMouseDown = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onMouseDown);
var $mdgriffith$elm_ui$Element$padding = function (x) {
	var f = x;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + $elm$core$String$fromInt(x),
			f,
			f,
			f,
			f));
};
var $mdgriffith$elm_ui$Internal$Model$Top = {$: 'Top'};
var $mdgriffith$elm_ui$Element$alignTop = $mdgriffith$elm_ui$Internal$Model$AlignY($mdgriffith$elm_ui$Internal$Model$Top);
var $mdgriffith$elm_ui$Element$row = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentLeft + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.contentCenterY)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $elm$core$String$words = _String_words;
var $author$project$UI$Form$Label$content = F2(
	function (txt, required) {
		var asterisk = required ? A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.error),
					$mdgriffith$elm_ui$Element$alignTop
				]),
			A2(
				$author$project$UI$materialIcon,
				'asterisk',
				$author$project$UI$scaled(1))) : $mdgriffith$elm_ui$Element$none;
		var _v0 = $elm$core$List$reverse(
			$elm$core$String$words(txt));
		if (_v0.b) {
			if (_v0.b.b) {
				var last = _v0.a;
				var rest = _v0.b;
				return _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(
						A2(
							$elm$core$String$join,
							' ',
							$elm$core$List$reverse(
								A2($elm$core$List$cons, '', rest)))),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(last),
								asterisk
							]))
					]);
			} else {
				var last = _v0.a;
				return _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(last),
								asterisk
							]))
					]);
			}
		} else {
			return _List_Nil;
		}
	});
var $author$project$UI$Form$Label$plain = F2(
	function (txt, required) {
		var _v0 = A2($author$project$UI$Form$Label$content, txt, required);
		if (!_v0.b) {
			return $mdgriffith$elm_ui$Element$none;
		} else {
			var elements = _v0;
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 2, 0),
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				elements);
		}
	});
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $author$project$UI$withTabindex = $mdgriffith$elm_ui$Element$htmlAttribute(
	$elm$html$Html$Attributes$tabindex(0));
var $author$project$UI$Form$Dropdown$render = function (_v0) {
	var specs = _v0.a;
	var message = function (vis) {
		return specs.onChange(
			$author$project$UI$Form$Field(
				_Utils_update(
					specs,
					{displayed: vis, open: false, state: vis})));
	};
	var altStyle = _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.primary),
			$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0)
		]);
	var renderOption = function (opt) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Events$onMouseDown(
					message(opt)),
					$author$project$Utils$onEnter(
					message(opt)),
					$mdgriffith$elm_ui$Element$mouseOver(altStyle),
					$mdgriffith$elm_ui$Element$focused(altStyle),
					$author$project$UI$withTabindex,
					$mdgriffith$elm_ui$Element$padding(12),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$mdgriffith$elm_ui$Element$text(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.name;
						},
						opt))));
	};
	var options = A2(
		$mdgriffith$elm_ui$Element$column,
		_Utils_ap(
			$author$project$UI$border($author$project$UI$colors.neutral_20),
			_Utils_ap(
				specs.focusedOnLoad ? _List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad]) : _List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$clip,
						$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_0)
					]))),
		A2(
			$elm$core$List$map,
			renderOption,
			A2(
				$elm$core$List$cons,
				$elm$core$Maybe$Nothing,
				A2($elm$core$List$map, $elm$core$Maybe$Just, specs.options))));
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(6),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$htmlAttribute(
				A2($elm$html$Html$Attributes$style, 'user-select', 'none'))
			]),
		_Utils_ap(
			specs.showLabel ? _List_fromArray(
				[
					A2(
					$author$project$UI$Form$Label$plain,
					specs.name,
					$author$project$UI$Form$isRequired(specs))
				]) : _List_Nil,
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						specs.open ? _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$below(
								$elm$core$List$isEmpty(specs.options) ? $mdgriffith$elm_ui$Element$none : options)
							]) : _List_Nil),
					A2(
						$mdgriffith$elm_ui$Element$el,
						_Utils_ap(
							$author$project$UI$border($author$project$UI$colors.neutral_20),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2($mdgriffith$elm_ui$Element$paddingXY, 12, 6),
									$author$project$UI$withTabindex,
									$author$project$UI$focused(_List_Nil),
									$author$project$Utils$onEnter(specs.toggle)
								])),
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Events$onMouseDown(specs.toggle)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(
									A2(
										$elm$core$Maybe$withDefault,
										'',
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.name;
											},
											specs.state))),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignRight]),
									A2(
										$author$project$UI$materialIcon,
										specs.open ? 'arrow_drop_down' : 'arrow_left',
										32))
								]))))
				])));
};
var $author$project$UI$Form$Dropdown$default = F2(
	function (selected, toggled) {
		return {
			check: $elm$core$Basics$always($elm$core$Maybe$Nothing),
			displayed: $elm$core$Maybe$Nothing,
			focusedOnLoad: false,
			hint: $elm$core$Maybe$Nothing,
			name: '',
			onChange: selected,
			open: false,
			options: _List_Nil,
			render: $author$project$UI$Form$Dropdown$render,
			required: $author$project$UI$Form$Required,
			showLabel: true,
			state: $elm$core$Maybe$Nothing,
			toggle: toggled
		};
	});
var $author$project$UI$Button$Filled = {$: 'Filled'};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (maybeValue.$ === 'Just') {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$fontWeight = $mdgriffith$elm_ui$Internal$Flag$flag(13);
var $mdgriffith$elm_ui$Element$Font$bold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.bold);
var $mdgriffith$elm_ui$Internal$Model$Button = {$: 'Button'};
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $mdgriffith$elm_ui$Element$Input$enter = 'Enter';
var $mdgriffith$elm_ui$Element$Input$focusDefault = function (attrs) {
	return A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, attrs) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
};
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onClick = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onClick);
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 'MayPreventDefault', a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $mdgriffith$elm_ui$Element$Input$onKeyLookup = function (lookup) {
	var decode = function (code) {
		var _v0 = lookup(code);
		if (_v0.$ === 'Nothing') {
			return $elm$json$Json$Decode$fail('No key matched');
		} else {
			var msg = _v0.a;
			return $elm$json$Json$Decode$succeed(msg);
		}
	};
	var isKey = A2(
		$elm$json$Json$Decode$andThen,
		decode,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2(
			$elm$html$Html$Events$preventDefaultOn,
			'keydown',
			A2(
				$elm$json$Json$Decode$map,
				function (fired) {
					return _Utils_Tuple2(fired, true);
				},
				isKey)));
};
var $mdgriffith$elm_ui$Element$pointer = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.cursorPointer);
var $mdgriffith$elm_ui$Element$Input$space = ' ';
var $mdgriffith$elm_ui$Element$Input$button = F2(
	function (attrs, _v0) {
		var onPress = _v0.onPress;
		var label = _v0.label;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentCenterX + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.contentCenterY + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.seButton + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.noTextSelection)))))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$pointer,
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Button),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											$elm$html$Html$Attributes$tabindex(0)),
										function () {
											if (onPress.$ === 'Nothing') {
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$Attr(
														$elm$html$Html$Attributes$disabled(true)),
													attrs);
											} else {
												var msg = onPress.a;
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Element$Events$onClick(msg),
													A2(
														$elm$core$List$cons,
														$mdgriffith$elm_ui$Element$Input$onKeyLookup(
															function (code) {
																return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(msg) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(msg) : $elm$core$Maybe$Nothing);
															}),
														attrs));
											}
										}()))))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $author$project$UI$durations = {fast: '0.15s', instant: '0s', medium: '0.25s', slow: '0.5s'};
var $author$project$UI$transitionDuration = function (duration) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2($elm$html$Html$Attributes$style, 'transition-duration', duration));
};
var $author$project$UI$Button$buttonConstructor = F3(
	function (specs, commonStyle, altStyle) {
		var attrs = _Utils_ap(
			commonStyle,
			_Utils_ap(
				function () {
					var _v0 = specs.action;
					if (_v0.$ === 'Just') {
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$mouseOver(altStyle),
								$author$project$UI$focused(altStyle)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(
							$author$project$UI$scaled(1)),
							$author$project$UI$transitionDuration($author$project$UI$durations.fast)
						]),
					specs.attrs)));
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			attrs,
			{label: specs.label, onPress: specs.action});
	});
var $mdgriffith$elm_ui$Internal$Model$boxShadowClass = function (shadow) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				shadow.inset ? 'box-inset' : 'box-',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.offset.a) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.offset.b) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.blur) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.size) + 'px',
				$mdgriffith$elm_ui$Internal$Model$formatColorClass(shadow.color)
			]));
};
var $mdgriffith$elm_ui$Internal$Flag$shadows = $mdgriffith$elm_ui$Internal$Flag$flag(19);
var $mdgriffith$elm_ui$Element$Border$shadow = function (almostShade) {
	var shade = {blur: almostShade.blur, color: almostShade.color, inset: false, offset: almostShade.offset, size: almostShade.size};
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$shadows,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			$mdgriffith$elm_ui$Internal$Model$boxShadowClass(shade),
			'box-shadow',
			$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(shade)));
};
var $author$project$UI$shadow = $mdgriffith$elm_ui$Element$Border$shadow(
	{
		blur: 2,
		color: $author$project$UI$colors.neutral_60,
		offset: _Utils_Tuple2(0, 0),
		size: 0
	});
var $author$project$UI$Button$button = function (specs) {
	var clr = function () {
		var _v1 = specs.action;
		if (_v1.$ === 'Just') {
			return $author$project$UI$colors.primary;
		} else {
			return $author$project$UI$colors.neutral_20;
		}
	}();
	var _v0 = specs.style;
	switch (_v0.$) {
		case 'Outline':
			return A3(
				$author$project$UI$Button$buttonConstructor,
				specs,
				$author$project$UI$border($author$project$UI$colors.neutral_20),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.primary),
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
						$mdgriffith$elm_ui$Element$Border$color($author$project$UI$colors.primary),
						$author$project$UI$shadow
					]));
		case 'Filled':
			return A3(
				$author$project$UI$Button$buttonConstructor,
				specs,
				_Utils_ap(
					$author$project$UI$border(clr),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(clr),
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
							$mdgriffith$elm_ui$Element$Font$bold,
							$author$project$UI$shadow
						])),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_0),
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
						$mdgriffith$elm_ui$Element$Border$color($author$project$UI$colors.primary)
					]));
		default:
			return A3(
				$author$project$UI$Button$buttonConstructor,
				specs,
				_Utils_ap(
					$author$project$UI$border($author$project$UI$colors.transparent),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(clr),
							$mdgriffith$elm_ui$Element$Font$bold
						])),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
						$mdgriffith$elm_ui$Element$Border$color($author$project$UI$colors.primary)
					]));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$fontAlignment = $mdgriffith$elm_ui$Internal$Flag$flag(12);
var $mdgriffith$elm_ui$Element$Font$center = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.textCenter);
var $mdgriffith$elm_ui$Internal$Model$FontSize = function (a) {
	return {$: 'FontSize', a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$fontSize = $mdgriffith$elm_ui$Internal$Flag$flag(4);
var $mdgriffith$elm_ui$Element$Font$size = function (i) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontSize,
		$mdgriffith$elm_ui$Internal$Model$FontSize(i));
};
var $author$project$UI$fontsizes = {
	big: $mdgriffith$elm_ui$Element$Font$size(
		$author$project$UI$scaled(3)),
	bigger: $mdgriffith$elm_ui$Element$Font$size(
		$author$project$UI$scaled(4)),
	huge: $mdgriffith$elm_ui$Element$Font$size(
		$author$project$UI$scaled(5)),
	regular: $mdgriffith$elm_ui$Element$Font$size(
		$author$project$UI$scaled(2)),
	small: $mdgriffith$elm_ui$Element$Font$size(
		$author$project$UI$scaled(1)),
	smaller: $mdgriffith$elm_ui$Element$Font$size(
		$author$project$UI$scaled(-1)),
	tiny: $mdgriffith$elm_ui$Element$Font$size(
		$author$project$UI$scaled(-2))
};
var $author$project$UI$Button$ButtonSpecs = F4(
	function (action, label, style, attrs) {
		return {action: action, attrs: attrs, label: label, style: style};
	});
var $author$project$UI$Form$iconButton = F4(
	function (icon, action, style, attrs) {
		return A4(
			$author$project$UI$Button$ButtonSpecs,
			action,
			A2(
				$author$project$UI$materialIcon,
				icon,
				$author$project$UI$scaled(5)),
			style,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$padding(
					$author$project$UI$scaled(-5)),
				attrs));
	});
var $mdgriffith$elm_ui$Element$Input$HiddenLabel = function (a) {
	return {$: 'HiddenLabel', a: a};
};
var $mdgriffith$elm_ui$Element$Input$labelHidden = $mdgriffith$elm_ui$Element$Input$HiddenLabel;
var $mdgriffith$elm_ui$Internal$Model$Min = F2(
	function (a, b) {
		return {$: 'Min', a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$minimum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Min, i, l);
	});
var $mdgriffith$elm_ui$Internal$Model$Paragraph = {$: 'Paragraph'};
var $mdgriffith$elm_ui$Element$paragraph = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asParagraph,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Paragraph),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$spacing(5),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$Input$Placeholder = F2(
	function (a, b) {
		return {$: 'Placeholder', a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Input$placeholder = $mdgriffith$elm_ui$Element$Input$Placeholder;
var $mdgriffith$elm_ui$Internal$Model$Px = function (a) {
	return {$: 'Px', a: a};
};
var $mdgriffith$elm_ui$Element$px = $mdgriffith$elm_ui$Internal$Model$Px;
var $author$project$UI$Form$Error = function (a) {
	return {$: 'Error', a: a};
};
var $author$project$UI$Form$Omitted = {$: 'Omitted'};
var $author$project$UI$Form$WithResult = function (a) {
	return {$: 'WithResult', a: a};
};
var $author$project$UI$Form$result = function (specs) {
	var check = specs.check;
	var required = specs.required;
	var state = specs.state;
	var _v0 = _Utils_Tuple3(
		check(
			$author$project$UI$Form$Field(specs)),
		required,
		state);
	if (_v0.a.$ === 'Just') {
		var error = _v0.a.a;
		return $author$project$UI$Form$Error(error);
	} else {
		if (_v0.c.$ === 'Just') {
			var _v7 = _v0.a;
			var res = _v0.c.a;
			return $author$project$UI$Form$WithResult(res);
		} else {
			switch (_v0.b.$) {
				case 'Required':
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					var _v3 = _v0.c;
					return $author$project$UI$Form$Error('Поле не заполнено!');
				case 'Optional':
					var _v4 = _v0.a;
					var _v5 = _v0.b;
					var _v6 = _v0.c;
					return $author$project$UI$Form$Omitted;
				default:
					var _v8 = _v0.a;
					var _default = _v0.b.a;
					var _v9 = _v0.c;
					return $author$project$UI$Form$WithResult(_default);
			}
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$text = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		autofill: $elm$core$Maybe$Nothing,
		spellchecked: false,
		type_: $mdgriffith$elm_ui$Element$Input$TextInputNode('text')
	});
var $author$project$UI$Form$Number$render = function (_v0) {
	var specs = _v0.a;
	var message = function (vis) {
		return specs.onChange(
			$author$project$UI$Form$Field(
				_Utils_update(
					specs,
					{
						displayed: vis,
						state: function () {
							if (vis === '') {
								return $elm$core$Maybe$Nothing;
							} else {
								return specs.ops.fromString(vis);
							}
						}()
					})));
	};
	var textField = A2(
		$mdgriffith$elm_ui$Element$Input$text,
		_Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2(
						$mdgriffith$elm_ui$Element$minimum,
						$author$project$UI$scaled(5) + ($author$project$UI$scaled(-1) * A2(
							$elm$core$Basics$max,
							1,
							$elm$core$String$length(specs.displayed))),
						$mdgriffith$elm_ui$Element$shrink)),
					$author$project$UI$focused(_List_Nil),
					$mdgriffith$elm_ui$Element$Font$center,
					specs.ops.inputmode
				]),
			_Utils_ap(
				specs.focusedOnLoad ? _List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad]) : _List_Nil,
				$author$project$UI$border($author$project$UI$colors.neutral_20))),
		{
			label: $mdgriffith$elm_ui$Element$Input$labelHidden(specs.name),
			onChange: message,
			placeholder: A2(
				$elm$core$Maybe$map,
				$mdgriffith$elm_ui$Element$Input$placeholder(_List_Nil),
				A2($elm$core$Maybe$map, $mdgriffith$elm_ui$Element$text, specs.hint)),
			text: specs.displayed
		});
	var ifValid = function (num) {
		var _v4 = $author$project$UI$Form$result(
			_Utils_update(
				specs,
				{
					state: $elm$core$Maybe$Just(num)
				}));
		switch (_v4.$) {
			case 'Error':
				return $elm$core$Maybe$Nothing;
			case 'WithResult':
				var x = _v4.a;
				return $elm$core$Maybe$Just(x);
			default:
				return $elm$core$Maybe$Nothing;
		}
	};
	var process = function (action) {
		return A2(
			$elm$core$Basics$composeR,
			action,
			A2(
				$elm$core$Basics$composeR,
				ifValid,
				$elm$core$Maybe$map(
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$Just,
						A2($elm$core$Basics$composeR, specs.ops.toString, message)))));
	};
	var errorMessage = A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.error),
				$author$project$UI$fontsizes.regular
			]),
		_List_fromArray(
			[
				function () {
				var _v3 = $author$project$UI$Form$result(specs);
				if (_v3.$ === 'Error') {
					var error = _v3.a;
					return $mdgriffith$elm_ui$Element$text(error);
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}()
			]));
	var btn = F2(
		function (action, icon) {
			return $author$project$UI$Button$button(
				A4(
					$author$project$UI$Form$iconButton,
					icon,
					function () {
						var _v1 = _Utils_Tuple2(specs.state, specs.hint);
						if (_v1.a.$ === 'Just') {
							var num = _v1.a.a;
							return A2(process, action, num);
						} else {
							if (_v1.b.$ === 'Just') {
								var _v2 = _v1.a;
								var hint = _v1.b.a;
								return A2(
									$elm$core$Maybe$andThen,
									process(action),
									specs.ops.fromString(hint));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}(),
					$author$project$UI$Button$Filled,
					_List_Nil));
		});
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(6),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-3)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_Utils_ap(
					specs.showLabel ? _List_fromArray(
						[
							A2(
							$author$project$UI$Form$Label$plain,
							specs.name,
							$author$project$UI$Form$isRequired(specs)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(
										$author$project$UI$scaled(1)))
								]),
							$mdgriffith$elm_ui$Element$none)
						]) : _List_Nil,
					_List_fromArray(
						[
							A2(btn, specs.ops.dec, 'remove'),
							textField,
							A2(btn, specs.ops.inc, 'add')
						]))),
			specs.showLabel ? _List_fromArray(
				[errorMessage]) : _List_Nil));
};
var $author$project$UI$Form$Number$default = F2(
	function (ops, msgFunc) {
		return {
			check: $elm$core$Basics$always($elm$core$Maybe$Nothing),
			displayed: '',
			focusedOnLoad: false,
			hint: $elm$core$Maybe$Nothing,
			name: '',
			onChange: msgFunc,
			ops: ops,
			render: $author$project$UI$Form$Number$render,
			required: $author$project$UI$Form$Required,
			showLabel: true,
			state: $elm$core$Maybe$Nothing
		};
	});
var $author$project$UI$Form$NotRequired = function (a) {
	return {$: 'NotRequired', a: a};
};
var $mdgriffith$elm_ui$Element$Input$Above = {$: 'Above'};
var $mdgriffith$elm_ui$Element$Input$Label = F3(
	function (a, b, c) {
		return {$: 'Label', a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Element$Input$labelAbove = $mdgriffith$elm_ui$Element$Input$Label($mdgriffith$elm_ui$Element$Input$Above);
var $author$project$UI$Form$Label$label = F3(
	function (txt, required, visible) {
		return visible ? A2(
			$mdgriffith$elm_ui$Element$Input$labelAbove,
			_List_Nil,
			A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60)
					]),
				A2($author$project$UI$Form$Label$content, txt, required))) : $mdgriffith$elm_ui$Element$Input$labelHidden(txt);
	});
var $mdgriffith$elm_ui$Element$Input$multiline = F2(
	function (attrs, multi) {
		return A3(
			$mdgriffith$elm_ui$Element$Input$textHelper,
			{autofill: $elm$core$Maybe$Nothing, spellchecked: multi.spellcheck, type_: $mdgriffith$elm_ui$Element$Input$TextArea},
			attrs,
			{label: multi.label, onChange: multi.onChange, placeholder: multi.placeholder, text: multi.text});
	});
var $author$project$UI$Form$Text$render = function (_v0) {
	var specs = _v0.a;
	var message = function (vis) {
		return specs.onChange(
			$author$project$UI$Form$Field(
				_Utils_update(
					specs,
					{
						displayed: vis,
						state: function () {
							if (vis === '') {
								return $elm$core$Maybe$Nothing;
							} else {
								return $elm$core$Maybe$Just(vis);
							}
						}()
					})));
	};
	var stopEnterPropagation = $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$elm$html$Html$Events$stopPropagationOn,
			'keyup',
			A2(
				$elm$json$Json$Decode$andThen,
				function (key) {
					return (key === 'Enter') ? $elm$json$Json$Decode$succeed(
						_Utils_Tuple2(
							message(specs.displayed),
							true)) : $elm$json$Json$Decode$fail('not the enter key');
				},
				A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string))));
	var errorMessage = A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.error),
				$author$project$UI$fontsizes.regular
			]),
		_List_fromArray(
			[
				function () {
				var _v2 = $author$project$UI$Form$result(specs);
				if (_v2.$ === 'Error') {
					var error = _v2.a;
					return $mdgriffith$elm_ui$Element$text(error);
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}()
			]));
	var attrs = _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$author$project$UI$focused(_List_Nil)
			]),
		_Utils_ap(
			specs.focusedOnLoad ? _List_fromArray(
				[$mdgriffith$elm_ui$Element$Input$focusedOnLoad]) : _List_Nil,
			$author$project$UI$border($author$project$UI$colors.neutral_20)));
	var textField = function () {
		var _v1 = specs.kind;
		switch (_v1.$) {
			case 'Regular':
				var inputFunc = _v1.a;
				return A2(
					inputFunc,
					attrs,
					{
						label: A3(
							$author$project$UI$Form$Label$label,
							specs.name,
							$author$project$UI$Form$isRequired(specs),
							specs.showLabel),
						onChange: message,
						placeholder: A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$Input$placeholder(_List_Nil)),
							specs.hint),
						text: specs.displayed
					});
			case 'Password':
				var show = _v1.a;
				var inputFunc = _v1.b;
				return A2(
					inputFunc,
					attrs,
					{
						label: A3(
							$author$project$UI$Form$Label$label,
							specs.name,
							$author$project$UI$Form$isRequired(specs),
							specs.showLabel),
						onChange: message,
						placeholder: A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								$mdgriffith$elm_ui$Element$Input$placeholder(_List_Nil)),
							specs.hint),
						show: show,
						text: specs.displayed
					});
			default:
				var spellcheck = _v1.a;
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$htmlAttribute(
							$elm$html$Html$Attributes$class('no-text-overflow'))
						]),
					A2(
						$mdgriffith$elm_ui$Element$Input$multiline,
						attrs,
						{
							label: A3(
								$author$project$UI$Form$Label$label,
								specs.name,
								$author$project$UI$Form$isRequired(specs),
								specs.showLabel),
							onChange: message,
							placeholder: A2(
								$elm$core$Maybe$map,
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									$mdgriffith$elm_ui$Element$Input$placeholder(_List_Nil)),
								specs.hint),
							spellcheck: spellcheck,
							text: specs.displayed
						}));
		}
	}();
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(6),
				stopEnterPropagation
			]),
		_List_fromArray(
			[textField, errorMessage]));
};
var $author$project$UI$Form$Text$default = function (msgFunc) {
	return {
		check: $elm$core$Basics$always($elm$core$Maybe$Nothing),
		displayed: '',
		focusedOnLoad: false,
		hint: $elm$core$Maybe$Nothing,
		kind: $author$project$UI$Form$Text$Regular($mdgriffith$elm_ui$Element$Input$text),
		name: '',
		onChange: msgFunc,
		render: $author$project$UI$Form$Text$render,
		required: $author$project$UI$Form$NotRequired(''),
		showLabel: true,
		state: $elm$core$Maybe$Nothing
	};
};
var $mdgriffith$elm_ui$Element$Input$email = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		autofill: $elm$core$Maybe$Just('email'),
		spellchecked: false,
		type_: $mdgriffith$elm_ui$Element$Input$TextInputNode('email')
	});
var $author$project$UI$Form$Number$int = {
	dec: function (x) {
		return x - 1;
	},
	fromString: $elm$core$String$toInt,
	inc: $elm$core$Basics$add(1),
	inputmode: $mdgriffith$elm_ui$Element$htmlAttribute(
		A2($elm$html$Html$Attributes$attribute, 'inputmode', 'numeric')),
	toString: A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map($elm$core$String$fromInt),
		$elm$core$Maybe$withDefault(''))
};
var $author$project$UI$Form$simpleCheck = F3(
	function (validator, message, _v0) {
		var state = _v0.a.state;
		var _v1 = A2($elm$core$Maybe$map, validator, state);
		if ((_v1.$ === 'Just') && (!_v1.a)) {
			return $elm$core$Maybe$Just(message);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$trim = _String_trim;
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {index: index, match: match, number: number, submatches: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{caseInsensitive: false, multiline: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $author$project$UI$Form$Text$validateEmail = function (email) {
	return A2(
		$elm$regex$Regex$contains,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('^.+@.+\\..+$')),
		email);
};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$UI$Form$Text$validatePassword = function (pwd) {
	return A2(
		$elm$core$List$all,
		$elm$core$Basics$identity,
		A2(
			$elm$core$List$cons,
			$elm$core$String$length(pwd) >= 8,
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$regex$Regex$fromString,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$withDefault($elm$regex$Regex$never),
						function (expression) {
							return A2($elm$regex$Regex$contains, expression, pwd);
						})),
				_List_fromArray(
					['\\d', '[A-Z]', '[a-z]', '[!?,./\\()*^%&<>`~\'\"-+{}|\\[\\]]']))));
};
var $author$project$Page$Auth$defaultFields = function () {
	var nonempty = A2(
		$elm$core$Basics$composeR,
		$elm$core$String$trim,
		A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not));
	return {
		building: function () {
			var _default = A2(
				$author$project$UI$Form$Dropdown$default,
				$author$project$Page$Auth$UpdatedBuildingField,
				$author$project$Page$Auth$SetBuildingDropdownOpen(true));
			return _Utils_update(
				_default,
				{name: 'Адрес'});
		}(),
		email: function () {
			var _default = $author$project$UI$Form$Text$default($author$project$Page$Auth$UpdatedEmailField);
			return _Utils_update(
				_default,
				{
					check: A2($author$project$UI$Form$simpleCheck, $author$project$UI$Form$Text$validateEmail, 'Неверный формат email!'),
					focusedOnLoad: true,
					hint: $elm$core$Maybe$Just('example@gmail.com'),
					kind: $author$project$UI$Form$Text$Regular($mdgriffith$elm_ui$Element$Input$email),
					name: 'Адрес электронной почты',
					required: $author$project$UI$Form$Required
				});
		}(),
		flat: function () {
			var _default = A2($author$project$UI$Form$Number$default, $author$project$UI$Form$Number$int, $author$project$Page$Auth$UpdatedFlatField);
			return _Utils_update(
				_default,
				{
					check: $elm$core$Basics$always($elm$core$Maybe$Nothing),
					name: 'Квартира'
				});
		}(),
		name: function () {
			var _default = $author$project$UI$Form$Text$default($author$project$Page$Auth$UpdatedNameField);
			return _Utils_update(
				_default,
				{
					check: A2($author$project$UI$Form$simpleCheck, nonempty, 'Неверный формат имени!'),
					hint: $elm$core$Maybe$Just('Иван'),
					kind: $author$project$UI$Form$Text$Regular($mdgriffith$elm_ui$Element$Input$text),
					name: 'Имя',
					required: $author$project$UI$Form$Required
				});
		}(),
		password: function () {
			var _default = $author$project$UI$Form$Text$default($author$project$Page$Auth$UpdatedPasswordField);
			return _Utils_update(
				_default,
				{
					check: A2($author$project$UI$Form$simpleCheck, $author$project$UI$Form$Text$validatePassword, 'Пароль должен быть длиннее 7 символов и содержать ' + ('строчные и заглавные латинские буквы, цифры ' + 'и знаки препинания')),
					kind: A2($author$project$UI$Form$Text$Password, false, $mdgriffith$elm_ui$Element$Input$currentPassword),
					name: 'Пароль',
					required: $author$project$UI$Form$Required
				});
		}(),
		passwordAgain: function () {
			var _default = $author$project$UI$Form$Text$default($author$project$Page$Auth$UpdatedPasswordAgainField);
			return _Utils_update(
				_default,
				{
					kind: A2($author$project$UI$Form$Text$Password, false, $mdgriffith$elm_ui$Element$Input$currentPassword),
					name: 'Повторите пароль',
					required: $author$project$UI$Form$Required
				});
		}(),
		patronymic: function () {
			var _default = $author$project$UI$Form$Text$default($author$project$Page$Auth$UpdatedPatronymicField);
			return _Utils_update(
				_default,
				{
					check: A2($author$project$UI$Form$simpleCheck, nonempty, 'Неверный формат отчества!'),
					hint: $elm$core$Maybe$Just('Иванович'),
					kind: $author$project$UI$Form$Text$Regular($mdgriffith$elm_ui$Element$Input$text),
					name: 'Отчество'
				});
		}(),
		surname: function () {
			var _default = $author$project$UI$Form$Text$default($author$project$Page$Auth$UpdatedSurnameField);
			return _Utils_update(
				_default,
				{
					check: A2($author$project$UI$Form$simpleCheck, nonempty, 'Неверный формат фамилии!'),
					hint: $elm$core$Maybe$Just('Иванов'),
					kind: $author$project$UI$Form$Text$Regular($mdgriffith$elm_ui$Element$Input$text),
					name: 'Фамилия',
					required: $author$project$UI$Form$Required
				});
		}()
	};
}();
var $author$project$API$Sort = function (a) {
	return {$: 'Sort', a: a};
};
var $author$project$API$Get = F2(
	function (a, b) {
		return {$: 'Get', a: a, b: b};
	});
var $author$project$Domain$Building = F3(
	function (address, numberOfFloors, numberOfSections) {
		return {address: address, numberOfFloors: numberOfFloors, numberOfSections: numberOfSections};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Domain$buildingDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Domain$Building,
	A2($elm$json$Json$Decode$field, 'address', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'floors', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'sections', $elm$json$Json$Decode$int));
var $author$project$Domain$PB$Record = F2(
	function (meta, domain) {
		return {domain: domain, meta: meta};
	});
var $author$project$Domain$PB$idDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		return ($elm$core$String$length(s) === 15) ? $elm$json$Json$Decode$succeed(s) : $elm$json$Json$Decode$fail('invalid ID length, expected 15 characters: ' + s);
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Domain$PB$nonEmptyString = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		return ($elm$core$String$length(s) > 0) ? $elm$json$Json$Decode$succeed(s) : $elm$json$Json$Decode$fail('invalid value: empty string');
	},
	$elm$json$Json$Decode$string);
var $elm$time$Time$Apr = {$: 'Apr'};
var $elm$time$Time$Aug = {$: 'Aug'};
var $elm$time$Time$Dec = {$: 'Dec'};
var $elm$time$Time$Feb = {$: 'Feb'};
var $elm$time$Time$Jan = {$: 'Jan'};
var $elm$time$Time$Jul = {$: 'Jul'};
var $elm$time$Time$Jun = {$: 'Jun'};
var $elm$time$Time$Mar = {$: 'Mar'};
var $elm$time$Time$May = {$: 'May'};
var $elm$time$Time$Nov = {$: 'Nov'};
var $elm$time$Time$Oct = {$: 'Oct'};
var $justinmimbs$time_extra$Time$Extra$Parts = F7(
	function (year, month, day, hour, minute, second, millisecond) {
		return {day: day, hour: hour, millisecond: millisecond, minute: minute, month: month, second: second, year: year};
	});
var $elm$time$Time$Sep = {$: 'Sep'};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$RD = function (a) {
	return {$: 'RD', a: a};
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m.$) {
			case 'Jan':
				return 0;
			case 'Feb':
				return 31;
			case 'Mar':
				return 59 + leapDays;
			case 'Apr':
				return 90 + leapDays;
			case 'May':
				return 120 + leapDays;
			case 'Jun':
				return 151 + leapDays;
			case 'Jul':
				return 181 + leapDays;
			case 'Aug':
				return 212 + leapDays;
			case 'Sep':
				return 243 + leapDays;
			case 'Oct':
				return 273 + leapDays;
			case 'Nov':
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m.$) {
			case 'Jan':
				return 31;
			case 'Feb':
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 'Mar':
				return 31;
			case 'Apr':
				return 30;
			case 'May':
				return 31;
			case 'Jun':
				return 30;
			case 'Jul':
				return 31;
			case 'Aug':
				return 31;
			case 'Sep':
				return 30;
			case 'Oct':
				return 31;
			case 'Nov':
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return $justinmimbs$date$Date$RD(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
				$elm$core$Basics$clamp,
				1,
				A2($justinmimbs$date$Date$daysInMonth, y, m),
				d));
	});
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0.a;
	return rd;
};
var $justinmimbs$time_extra$Time$Extra$dateToMillis = function (date) {
	var daysSinceEpoch = $justinmimbs$date$Date$toRataDie(date) - 719163;
	return daysSinceEpoch * 86400000;
};
var $elm$time$Time$Posix = function (a) {
	return {$: 'Posix', a: a};
};
var $elm$time$Time$millisToPosix = $elm$time$Time$Posix;
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0.a;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.start, posixMinutes) < 0) {
					return posixMinutes + era.offset;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		day: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		month: month,
		year: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).day;
	});
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).month;
		switch (_v0) {
			case 1:
				return $elm$time$Time$Jan;
			case 2:
				return $elm$time$Time$Feb;
			case 3:
				return $elm$time$Time$Mar;
			case 4:
				return $elm$time$Time$Apr;
			case 5:
				return $elm$time$Time$May;
			case 6:
				return $elm$time$Time$Jun;
			case 7:
				return $elm$time$Time$Jul;
			case 8:
				return $elm$time$Time$Aug;
			case 9:
				return $elm$time$Time$Sep;
			case 10:
				return $elm$time$Time$Oct;
			case 11:
				return $elm$time$Time$Nov;
			default:
				return $elm$time$Time$Dec;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).year;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$timeFromClock = F4(
	function (hour, minute, second, millisecond) {
		return (((hour * 3600000) + (minute * 60000)) + (second * 1000)) + millisecond;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $justinmimbs$time_extra$Time$Extra$timeFromPosix = F2(
	function (zone, posix) {
		return A4(
			$justinmimbs$time_extra$Time$Extra$timeFromClock,
			A2($elm$time$Time$toHour, zone, posix),
			A2($elm$time$Time$toMinute, zone, posix),
			A2($elm$time$Time$toSecond, zone, posix),
			A2($elm$time$Time$toMillis, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$toOffset = F2(
	function (zone, posix) {
		var millis = $elm$time$Time$posixToMillis(posix);
		var localMillis = $justinmimbs$time_extra$Time$Extra$dateToMillis(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix);
		return ((localMillis - millis) / 60000) | 0;
	});
var $justinmimbs$time_extra$Time$Extra$posixFromDateTime = F3(
	function (zone, date, time) {
		var millis = $justinmimbs$time_extra$Time$Extra$dateToMillis(date) + time;
		var offset0 = A2(
			$justinmimbs$time_extra$Time$Extra$toOffset,
			zone,
			$elm$time$Time$millisToPosix(millis));
		var posix1 = $elm$time$Time$millisToPosix(millis - (offset0 * 60000));
		var offset1 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix1);
		if (_Utils_eq(offset0, offset1)) {
			return posix1;
		} else {
			var posix2 = $elm$time$Time$millisToPosix(millis - (offset1 * 60000));
			var offset2 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix2);
			return _Utils_eq(offset1, offset2) ? posix2 : posix1;
		}
	});
var $justinmimbs$time_extra$Time$Extra$partsToPosix = F2(
	function (zone, _v0) {
		var year = _v0.year;
		var month = _v0.month;
		var day = _v0.day;
		var hour = _v0.hour;
		var minute = _v0.minute;
		var second = _v0.second;
		var millisecond = _v0.millisecond;
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A3($justinmimbs$date$Date$fromCalendarDate, year, month, day),
			A4(
				$justinmimbs$time_extra$Time$Extra$timeFromClock,
				A3($elm$core$Basics$clamp, 0, 23, hour),
				A3($elm$core$Basics$clamp, 0, 59, minute),
				A3($elm$core$Basics$clamp, 0, 59, second),
				A3($elm$core$Basics$clamp, 0, 999, millisecond)));
	});
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 'Zone', a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Utils$parseTimestamp = function () {
	var timestampRegex = A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('(\\d{4})-(\\d{2})-(\\d{2}) (\\d{2}):(\\d{2}):(\\d{2})\\.(\\d{3})Z'));
	var monthByNumber = function (n) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$drop,
				n,
				_List_fromArray(
					[$elm$time$Time$Jan, $elm$time$Time$Feb, $elm$time$Time$Mar, $elm$time$Time$Apr, $elm$time$Time$May, $elm$time$Time$Jun, $elm$time$Time$Jul, $elm$time$Time$Aug, $elm$time$Time$Sep, $elm$time$Time$Oct, $elm$time$Time$Nov, $elm$time$Time$Dec])));
	};
	return A2(
		$elm$json$Json$Decode$map,
		$justinmimbs$time_extra$Time$Extra$partsToPosix($elm$time$Time$utc),
		A2(
			$elm$json$Json$Decode$andThen,
			function (string) {
				var _v0 = A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.submatches;
						},
						$elm$core$List$map(
							$elm$core$Maybe$andThen($elm$core$String$toInt))),
					A2($elm$regex$Regex$find, timestampRegex, string));
				if ((((((((((((((((_v0.b && _v0.a.b) && (_v0.a.a.$ === 'Just')) && _v0.a.b.b) && (_v0.a.b.a.$ === 'Just')) && _v0.a.b.b.b) && (_v0.a.b.b.a.$ === 'Just')) && _v0.a.b.b.b.b) && (_v0.a.b.b.b.a.$ === 'Just')) && _v0.a.b.b.b.b.b) && (_v0.a.b.b.b.b.a.$ === 'Just')) && _v0.a.b.b.b.b.b.b) && (_v0.a.b.b.b.b.b.a.$ === 'Just')) && _v0.a.b.b.b.b.b.b.b) && (_v0.a.b.b.b.b.b.b.a.$ === 'Just')) && (!_v0.a.b.b.b.b.b.b.b.b)) && (!_v0.b.b)) {
					var _v1 = _v0.a;
					var y = _v1.a.a;
					var _v2 = _v1.b;
					var monthNum = _v2.a.a;
					var _v3 = _v2.b;
					var d = _v3.a.a;
					var _v4 = _v3.b;
					var h = _v4.a.a;
					var _v5 = _v4.b;
					var m = _v5.a.a;
					var _v6 = _v5.b;
					var s = _v6.a.a;
					var _v7 = _v6.b;
					var ms = _v7.a.a;
					var _v8 = monthByNumber(monthNum - 1);
					if (_v8.$ === 'Just') {
						var month = _v8.a;
						return $elm$json$Json$Decode$succeed(
							A7($justinmimbs$time_extra$Time$Extra$Parts, y, month, d, h, m, s, ms));
					} else {
						return $elm$json$Json$Decode$fail('invalid timestamp ' + (string + ': month is ill-formated'));
					}
				} else {
					return $elm$json$Json$Decode$fail('invalid timestamp ' + string);
				}
			},
			$elm$json$Json$Decode$string));
}();
var $author$project$Domain$PB$metaRealDecoder = A6(
	$elm$json$Json$Decode$map5,
	F5(
		function (a, b, c, d, e) {
			return {collectionId: b, collectionName: c, created: d, id: a, updated: e};
		}),
	A2($elm$json$Json$Decode$field, 'id', $author$project$Domain$PB$idDecoder),
	A2($elm$json$Json$Decode$field, 'collectionId', $author$project$Domain$PB$nonEmptyString),
	A2($elm$json$Json$Decode$field, 'collectionName', $author$project$Domain$PB$nonEmptyString),
	A2($elm$json$Json$Decode$field, 'created', $author$project$Utils$parseTimestamp),
	A2($elm$json$Json$Decode$field, 'updated', $author$project$Utils$parseTimestamp));
var $author$project$Domain$PB$decoderWithID = function (domainDecoder) {
	return A3($elm$json$Json$Decode$map2, $author$project$Domain$PB$Record, $author$project$Domain$PB$metaRealDecoder, domainDecoder);
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 'BadStatus_', a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 'BadUrl_', a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 'GoodStatus_', a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 'NetworkError_'};
var $elm$http$Http$Receiving = function (a) {
	return {$: 'Receiving', a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 'Sending', a: a};
};
var $elm$http$Http$Timeout_ = {$: 'Timeout_'};
var $elm$core$Maybe$isJust = function (maybe) {
	if (maybe.$ === 'Just') {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.e.d.$ === 'RBNode_elm_builtin') && (dict.e.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.d.d.$ === 'RBNode_elm_builtin') && (dict.d.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Black')) {
					if (right.d.$ === 'RBNode_elm_builtin') {
						if (right.d.a.$ === 'Black') {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor.$ === 'Black') {
			if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === 'RBNode_elm_builtin') {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Black')) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === 'RBNode_elm_builtin') {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBNode_elm_builtin') {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === 'RBNode_elm_builtin') {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (_v0.$ === 'Just') {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 'QueryParameter', a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (result.$ === 'Ok') {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $author$project$API$Error$BadRequest = {$: 'BadRequest'};
var $author$project$API$Error$NetworkError = {$: 'NetworkError'};
var $author$project$API$Error$NotFound = {$: 'NotFound'};
var $author$project$API$Error$Timeout = {$: 'Timeout'};
var $author$project$API$Error$Unauthenticated = {$: 'Unauthenticated'};
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $author$project$API$stringResolver = function (decoder) {
	return $elm$http$Http$stringResolver(
		function (response) {
			switch (response.$) {
				case 'BadUrl_':
					var url = response.a;
					return $elm$core$Result$Err(
						$author$project$API$Error$Bug('bad URL: ' + url));
				case 'Timeout_':
					return $elm$core$Result$Err($author$project$API$Error$Timeout);
				case 'BadStatus_':
					var statusCode = response.a.statusCode;
					switch (statusCode) {
						case 400:
							return $elm$core$Result$Err($author$project$API$Error$BadRequest);
						case 401:
							return $elm$core$Result$Err($author$project$API$Error$Unauthenticated);
						case 403:
							return $elm$core$Result$Err($author$project$API$Error$Forbidden);
						case 404:
							return $elm$core$Result$Err($author$project$API$Error$NotFound);
						case 500:
							return $elm$core$Result$Err(
								$author$project$API$Error$Bug('server-side error'));
						default:
							return $elm$core$Result$Err(
								$author$project$API$Error$Bug(
									'unexpected status code: ' + $elm$core$String$fromInt(statusCode)));
					}
				case 'NetworkError_':
					return $elm$core$Result$Err($author$project$API$Error$NetworkError);
				default:
					var body = response.b;
					return decoder(body);
			}
		});
};
var $author$project$API$jsonResolver = function (decoder) {
	return $author$project$API$stringResolver(
		A2(
			$elm$core$Basics$composeR,
			$elm$json$Json$Decode$decodeString(decoder),
			$elm$core$Result$mapError(
				A2($elm$core$Basics$composeR, $elm$json$Json$Decode$errorToString, $author$project$API$Error$Bug))));
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$API$filterExpressionToString = function (expression) {
	var f = function (exp) {
		switch (exp.$) {
			case 'Eq':
				var a = exp.a;
				var b = exp.b;
				return a + ('=' + b);
			case 'AND':
				var expressions = exp.a;
				return A2(
					$elm$core$String$join,
					' && ',
					A2($elm$core$List$map, f, expressions));
			default:
				var expressions = exp.a;
				return A2(
					$elm$core$String$join,
					' || ',
					A2($elm$core$List$map, f, expressions));
		}
	};
	return '(' + (function () {
		switch (expression.$) {
			case 'AND':
				return f(expression);
			case 'OR':
				return f(expression);
			default:
				return f(expression);
		}
	}() + ')');
};
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$API$paramsToQuery = function () {
	var fold = $elm$core$String$join(',');
	var toQuery = function (p) {
		switch (p.$) {
			case 'Sort':
				var l = p.a;
				return A2(
					$elm$url$Url$Builder$string,
					'sort',
					fold(l));
			case 'Filter':
				var l = p.a;
				return A2(
					$elm$url$Url$Builder$string,
					'filter',
					$author$project$API$filterExpressionToString(l));
			case 'Expand':
				var l = p.a;
				return A2(
					$elm$url$Url$Builder$string,
					'expand',
					fold(l));
			default:
				var l = p.a;
				return A2(
					$elm$url$Url$Builder$string,
					'fields',
					fold(l));
		}
	};
	return $elm$core$List$map(toQuery);
}();
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (result.$ === 'Ok') {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		_Utils_Tuple0,
		$elm$http$Http$resultToTask,
		{allowCookiesFromOtherDomains: false, body: r.body, expect: r.resolver, headers: r.headers, method: r.method, timeout: r.timeout, tracker: $elm$core$Maybe$Nothing, url: r.url});
};
var $author$project$API$timeout = $elm$core$Maybe$Just(15000);
var $author$project$API$getAll = F4(
	function (name, _v0, headers, extraParams) {
		var decoder = _v0.a;
		var parameters = _v0.b;
		var decodePage = A3(
			$elm$json$Json$Decode$map2,
			$elm$core$Tuple$pair,
			A2(
				$elm$json$Json$Decode$field,
				'items',
				$elm$json$Json$Decode$list(decoder)),
			A2($elm$json$Json$Decode$field, 'totalPages', $elm$json$Json$Decode$int));
		var getPage = function (page) {
			return $elm$http$Http$task(
				{
					body: $elm$http$Http$emptyBody,
					headers: headers,
					method: 'GET',
					resolver: $author$project$API$jsonResolver(decodePage),
					timeout: $author$project$API$timeout,
					url: A2(
						$elm$url$Url$Builder$absolute,
						_List_fromArray(
							['api', 'collections', name, 'records']),
						A2(
							$elm$core$List$cons,
							A2($elm$url$Url$Builder$int, 'perPage', 500),
							A2(
								$elm$core$List$cons,
								A2($elm$url$Url$Builder$int, 'page', page),
								$author$project$API$paramsToQuery(
									_Utils_ap(extraParams, parameters)))))
				});
		};
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				var firstItems = _v1.a;
				var totalPages = _v1.b;
				return (totalPages === 1) ? $elm$core$Task$succeed(firstItems) : A2(
					$elm$core$Task$map,
					function (pages) {
						return $elm$core$List$concat(
							A2($elm$core$List$cons, firstItems, pages));
					},
					$elm$core$Task$sequence(
						A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeR,
								getPage,
								$elm$core$Task$map($elm$core$Tuple$first)),
							A2($elm$core$List$range, 2, totalPages))));
			},
			getPage(1));
	});
var $author$project$Collections$getBuildings = A2(
	$author$project$API$getAll,
	'building',
	A2(
		$author$project$API$Get,
		$author$project$Domain$PB$decoderWithID($author$project$Domain$buildingDecoder),
		_List_Nil));
var $author$project$Page$Auth$getBuildingList = A2(
	$author$project$Collections$getBuildings,
	_List_Nil,
	_List_fromArray(
		[
			$author$project$API$Sort(
			_List_fromArray(
				['address']))
		]));
var $author$project$Page$Auth$init = function (args) {
	return _Utils_Tuple2(
		{action: $author$project$Page$Auth$LogIn, building: $author$project$Page$Auth$defaultFields.building, buildings: $author$project$API$Status$Loading, device: args.device, email: $author$project$Page$Auth$defaultFields.email, error: $elm$core$Maybe$Nothing, flat: $author$project$Page$Auth$defaultFields.flat, flats: $author$project$API$Status$Loading, name: $author$project$Page$Auth$defaultFields.name, next: args.next, password: $author$project$Page$Auth$defaultFields.password, passwordAgain: $author$project$Page$Auth$defaultFields.passwordAgain, patronymic: $author$project$Page$Auth$defaultFields.patronymic, selectedFlat: $elm$core$Maybe$Nothing, session: args.session, showPasswords: false, surname: $author$project$Page$Auth$defaultFields.surname},
		A2($elm$core$Task$attempt, $author$project$Page$Auth$GotBuildingList, $author$project$Page$Auth$getBuildingList));
};
var $author$project$Page$Auth$title = function (form) {
	return function () {
		var _v0 = form.action;
		if (_v0.$ === 'LogIn') {
			return 'Вход';
		} else {
			return 'Регистрация';
		}
	}() + ' | УК \"Хорошая\"';
};
var $author$project$UI$Form$Dropdown$DropdownEntry = F2(
	function (name, tag) {
		return {name: name, tag: tag};
	});
var $author$project$API$Eq = F2(
	function (a, b) {
		return {$: 'Eq', a: a, b: b};
	});
var $author$project$API$Filter = function (a) {
	return {$: 'Filter', a: a};
};
var $author$project$Page$Auth$GotFlatList = function (a) {
	return {$: 'GotFlatList', a: a};
};
var $author$project$Page$Auth$GotLogInResponse = function (a) {
	return {$: 'GotLogInResponse', a: a};
};
var $author$project$Page$Auth$GotSignUpResponse = function (a) {
	return {$: 'GotSignUpResponse', a: a};
};
var $author$project$API$Status$Loaded = function (a) {
	return {$: 'Loaded', a: a};
};
var $author$project$Session$LogIn = function (a) {
	return {$: 'LogIn', a: a};
};
var $author$project$Framework$NoOp = {$: 'NoOp'};
var $author$project$Page$Auth$NoOp = {$: 'NoOp'};
var $author$project$Session$SignUp = function (a) {
	return {$: 'SignUp', a: a};
};
var $author$project$Framework$UpdateLocalStorage = function (a) {
	return {$: 'UpdateLocalStorage', a: a};
};
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 'BadBody', a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 'BadStatus', a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 'BadUrl', a: a};
};
var $elm$http$Http$NetworkError = {$: 'NetworkError'};
var $elm$http$Http$Timeout = {$: 'Timeout'};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 'BadUrl_':
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 'Timeout_':
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 'NetworkError_':
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 'BadStatus_':
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.statusCode));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(_Utils_Tuple0);
			}));
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$Domain$PublicFlatData = F3(
	function (id, number, buildingId) {
		return {buildingId: buildingId, id: id, number: number};
	});
var $author$project$Domain$publicFlatDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Domain$PublicFlatData,
	A2($elm$json$Json$Decode$field, 'id', $author$project$Domain$PB$idDecoder),
	A2($elm$json$Json$Decode$field, 'number', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'building', $elm$json$Json$Decode$string));
var $author$project$Collections$getPublicFlatData = A2(
	$author$project$API$getAll,
	'public_flat_data',
	A2(
		$author$project$API$Get,
		$author$project$Domain$PB$decoderWithID($author$project$Domain$publicFlatDecoder),
		_List_Nil));
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Session$Authenticated = function (a) {
	return {$: 'Authenticated', a: a};
};
var $author$project$Session$LocalStorage = F2(
	function (a, b) {
		return {$: 'LocalStorage', a: a, b: b};
	});
var $author$project$Session$ManagerLocalStorage = function (a) {
	return {$: 'ManagerLocalStorage', a: a};
};
var $author$project$Session$TenantLocalStorage = function (a) {
	return {$: 'TenantLocalStorage', a: a};
};
var $author$project$Domain$Manager = F3(
	function (email, name, role) {
		return {email: email, name: name, role: role};
	});
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Domain$managerDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Domain$Manager,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'role_comment', $elm$json$Json$Decode$string));
var $author$project$Domain$Tenant = F6(
	function (name, surname, patronymic, email, flat, verified) {
		return {email: email, flat: flat, name: name, patronymic: patronymic, surname: surname, verified: verified};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Domain$Flat = F9(
	function (section, floor, number, tenantIds, building, utilityBills, serviceBills, accountNumber, balance) {
		return {accountNumber: accountNumber, balance: balance, building: building, floor: floor, number: number, section: section, serviceBills: serviceBills, tenantIds: tenantIds, utilityBills: utilityBills};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$map8 = _Json_map8;
var $author$project$API$Status$NotStarted = {$: 'NotStarted'};
var $author$project$API$Resource$Resource = F2(
	function (a, b) {
		return {$: 'Resource', a: a, b: b};
	});
var $author$project$Domain$PB$resourceDecoder = F4(
	function (metaLocation, metaValueDecoder, bodyLocation, bodyDecoder) {
		return A3(
			$elm$json$Json$Decode$map2,
			$author$project$API$Resource$Resource,
			A2($elm$json$Json$Decode$at, metaLocation, metaValueDecoder),
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$json$Json$Decode$map,
						$author$project$API$Status$Loaded,
						A2($elm$json$Json$Decode$at, bodyLocation, bodyDecoder)),
						$elm$json$Json$Decode$succeed($author$project$API$Status$NotStarted)
					])));
	});
var $author$project$Domain$PB$shortResourceDecoderWithID = F3(
	function (field, metaValueDecoder, bodyDecoder) {
		return A4(
			$author$project$Domain$PB$resourceDecoder,
			_List_fromArray(
				[field]),
			metaValueDecoder,
			_List_fromArray(
				['expand', field]),
			$author$project$Domain$PB$decoderWithID(bodyDecoder));
	});
var $author$project$Domain$UtilityBill = function (a) {
	return {$: 'UtilityBill', a: a};
};
var $author$project$Domain$BillInfo = F5(
	function (totalCost, status, issued, description, qrCodeLink) {
		return {description: description, issued: issued, qrCodeLink: qrCodeLink, status: status, totalCost: totalCost};
	});
var $author$project$Domain$Created = {$: 'Created'};
var $author$project$Domain$Cancelled = {$: 'Cancelled'};
var $author$project$Domain$Paid = {$: 'Paid'};
var $author$project$Domain$Rejected = {$: 'Rejected'};
var $author$project$Domain$Unconfirmed = {$: 'Unconfirmed'};
var $author$project$Domain$billStatusDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (status) {
		switch (status) {
			case 'created':
				return $elm$json$Json$Decode$succeed($author$project$Domain$Created);
			case 'paid':
				return $elm$json$Json$Decode$succeed($author$project$Domain$Paid);
			case 'cancelled':
				return $elm$json$Json$Decode$succeed($author$project$Domain$Cancelled);
			case 'unconfirmed':
				return $elm$json$Json$Decode$succeed($author$project$Domain$Unconfirmed);
			case 'rejected':
				return $elm$json$Json$Decode$succeed($author$project$Domain$Rejected);
			default:
				return $elm$json$Json$Decode$fail('invalid bill status: ' + status);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Utils$decodeFilePath = function (field) {
	return A4(
		$elm$json$Json$Decode$map3,
		F3(
			function (collectionId, recordId, path) {
				return (path !== '') ? A2(
					$elm$core$String$join,
					'/',
					_List_fromArray(
						['/api/files', collectionId, recordId, path])) : '';
			}),
		A2($elm$json$Json$Decode$field, 'collectionId', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, field, $elm$json$Json$Decode$string));
};
var $author$project$Domain$billInfoDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Domain$BillInfo,
	A2($elm$json$Json$Decode$field, 'total_cost', $elm$json$Json$Decode$float),
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$field, 'status', $author$project$Domain$billStatusDecoder),
				$elm$json$Json$Decode$succeed($author$project$Domain$Created)
			])),
	A2($elm$json$Json$Decode$field, 'issued', $author$project$Utils$parseTimestamp),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	$author$project$Utils$decodeFilePath('qr'));
var $author$project$Domain$utilityBillDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Domain$UtilityBill,
	A4(
		$elm$json$Json$Decode$map3,
		F3(
			function (flat, file, info) {
				return {file: file, flatId: flat, info: info};
			}),
		A2($elm$json$Json$Decode$field, 'flat', $elm$json$Json$Decode$string),
		$author$project$Utils$decodeFilePath('bill'),
		$author$project$Domain$billInfoDecoder));
var $author$project$Domain$flatDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (incomplete) {
		return A2(
			$elm$json$Json$Decode$map,
			incomplete,
			A2($elm$json$Json$Decode$field, 'balance', $elm$json$Json$Decode$float));
	},
	A9(
		$elm$json$Json$Decode$map8,
		$author$project$Domain$Flat,
		A2($elm$json$Json$Decode$field, 'section', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'floor', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'number', $elm$json$Json$Decode$int),
		A2(
			$elm$json$Json$Decode$field,
			'tenants',
			$elm$json$Json$Decode$list($author$project$Domain$PB$idDecoder)),
		A3($author$project$Domain$PB$shortResourceDecoderWithID, 'building', $author$project$Domain$PB$idDecoder, $author$project$Domain$buildingDecoder),
		$elm$json$Json$Decode$maybe(
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['expand', 'utilityBills']),
				$elm$json$Json$Decode$list(
					$author$project$Domain$PB$decoderWithID($author$project$Domain$utilityBillDecoder)))),
		$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing),
		A2($elm$json$Json$Decode$field, 'account_number', $elm$json$Json$Decode$int)));
var $elm$json$Json$Decode$map6 = _Json_map6;
var $author$project$Domain$tenantDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Domain$Tenant,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'surname', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'patronymic', $elm$json$Json$Decode$string),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string)),
	A4(
		$author$project$Domain$PB$resourceDecoder,
		_List_fromArray(
			['flat']),
		$elm$json$Json$Decode$list($author$project$Domain$PB$idDecoder),
		_List_fromArray(
			['expand', 'flat']),
		$elm$json$Json$Decode$list(
			$author$project$Domain$PB$decoderWithID($author$project$Domain$flatDecoder))),
	A2($elm$json$Json$Decode$field, 'verified', $elm$json$Json$Decode$bool));
var $author$project$Session$authResponseDecoder = F2(
	function (time, cart) {
		return A2(
			$elm$json$Json$Decode$map,
			$author$project$Session$Authenticated,
			A2(
				$elm$json$Json$Decode$map,
				$author$project$Session$LocalStorage(time),
				$elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A3(
							$elm$json$Json$Decode$map2,
							F2(
								function (a, m) {
									return $author$project$Session$ManagerLocalStorage(
										{authToken: a, manager: m});
								}),
							A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
							A2(
								$elm$json$Json$Decode$field,
								'record',
								$author$project$Domain$PB$decoderWithID($author$project$Domain$managerDecoder))),
							A4(
							$elm$json$Json$Decode$map3,
							F3(
								function (a, u, c) {
									return $author$project$Session$TenantLocalStorage(
										{authToken: a, cart: c, user: u});
								}),
							A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
							A2(
								$elm$json$Json$Decode$field,
								'record',
								$author$project$Domain$PB$decoderWithID($author$project$Domain$tenantDecoder)),
							$elm$json$Json$Decode$succeed(cart))
						]))));
	});
var $author$project$API$OR = function (a) {
	return {$: 'OR', a: a};
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 'Header', a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $author$project$Session$authorizationHeader = function (token) {
	return A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token);
};
var $elmcraft$core_extra$Dict$Extra$fromListBy = F2(
	function (keyfn, xs) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (x, acc) {
					return A3(
						$elm$core$Dict$insert,
						keyfn(x),
						x,
						acc);
				}),
			$elm$core$Dict$empty,
			xs);
	});
var $author$project$Domain$Service = F6(
	function (name, description, image, category, unit, price) {
		return {category: category, description: description, image: image, name: name, price: price, unit: unit};
	});
var $author$project$Domain$PB$maybeString = function (s) {
	return $elm$core$String$isEmpty(
		$elm$core$String$trim(s)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(s);
};
var $author$project$Domain$Price = F2(
	function (a, b) {
		return {$: 'Price', a: a, b: b};
	});
var $author$project$Domain$ExactPrice = function (a) {
	return {$: 'ExactPrice', a: a};
};
var $author$project$Domain$PriceBetween = F2(
	function (a, b) {
		return {$: 'PriceBetween', a: a, b: b};
	});
var $author$project$Domain$PriceFrom = function (a) {
	return {$: 'PriceFrom', a: a};
};
var $author$project$Domain$PriceUpTo = function (a) {
	return {$: 'PriceUpTo', a: a};
};
var $author$project$Domain$priceRangeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (_v0) {
		var min = _v0.a;
		var max = _v0.b;
		if ((min < 0) || (max < 0)) {
			return $elm$json$Json$Decode$fail(
				$elm$core$String$concat(
					_List_fromArray(
						[
							'invalid price range price: (',
							$elm$core$String$fromInt(min),
							', ',
							$elm$core$String$fromInt(max),
							')'
						])));
		} else {
			var _v1 = _Utils_Tuple2(min, max);
			if (!_v1.a) {
				if (!_v1.b) {
					return $elm$json$Json$Decode$fail('invalid price range: 0 - 0');
				} else {
					var x = _v1.b;
					return $elm$json$Json$Decode$succeed(
						$author$project$Domain$PriceUpTo(x));
				}
			} else {
				if (!_v1.b) {
					var x = _v1.a;
					return $elm$json$Json$Decode$succeed(
						$author$project$Domain$PriceFrom(x));
				} else {
					var a = _v1.a;
					var b = _v1.b;
					return _Utils_eq(a, b) ? $elm$json$Json$Decode$succeed(
						$author$project$Domain$ExactPrice(a)) : $elm$json$Json$Decode$succeed(
						A2($author$project$Domain$PriceBetween, a, b));
				}
			}
		}
	},
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$Tuple$pair,
		A2($elm$json$Json$Decode$field, 'price_min', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'price_max', $elm$json$Json$Decode$int)));
var $author$project$Domain$Kopek = {$: 'Kopek'};
var $author$project$Domain$Rub = {$: 'Rub'};
var $author$project$Domain$priceUnitDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (unit) {
		switch (unit) {
			case 'rub':
				return $elm$json$Json$Decode$succeed($author$project$Domain$Rub);
			case 'kopek':
				return $elm$json$Json$Decode$succeed($author$project$Domain$Kopek);
			default:
				return $elm$json$Json$Decode$fail('invalid price unit: ' + unit);
		}
	},
	A2($elm$json$Json$Decode$field, 'price_unit', $elm$json$Json$Decode$string));
var $author$project$Domain$priceDecoder = A3($elm$json$Json$Decode$map2, $author$project$Domain$Price, $author$project$Domain$priceRangeDecoder, $author$project$Domain$priceUnitDecoder);
var $author$project$Domain$serviceDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Domain$Service,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$map,
		$author$project$Domain$PB$maybeString,
		A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$map,
		$author$project$Domain$PB$maybeString,
		$author$project$Utils$decodeFilePath('icon')),
	A2($elm$json$Json$Decode$field, 'category', $author$project$Domain$PB$idDecoder),
	A2($elm$json$Json$Decode$field, 'unit', $elm$json$Json$Decode$string),
	$author$project$Domain$priceDecoder);
var $author$project$Collections$getServices = A2(
	$author$project$API$getAll,
	'service',
	A2(
		$author$project$API$Get,
		$author$project$Domain$PB$decoderWithID($author$project$Domain$serviceDecoder),
		_List_Nil));
var $author$project$API$Expand = function (a) {
	return {$: 'Expand', a: a};
};
var $author$project$API$getSingle = F5(
	function (name, _v0, headers, extraParams, id) {
		var decoder = _v0.a;
		var parameters = _v0.b;
		return $elm$http$Http$task(
			{
				body: $elm$http$Http$emptyBody,
				headers: headers,
				method: 'GET',
				resolver: $author$project$API$jsonResolver(decoder),
				timeout: $author$project$API$timeout,
				url: A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						['api', 'collections', name, 'records', id]),
					$author$project$API$paramsToQuery(
						_Utils_ap(extraParams, parameters)))
			});
	});
var $author$project$Collections$getUser = A2(
	$author$project$API$getSingle,
	'tenant',
	A2(
		$author$project$API$Get,
		$author$project$Domain$PB$decoderWithID($author$project$Domain$tenantDecoder),
		_List_fromArray(
			[
				$author$project$API$Expand(
				_List_fromArray(
					['flat', 'flat.building']))
			])));
var $author$project$Collections$UpdateTimes = F3(
	function (lastUserUpdate, lastFlatUpdate, lastUtilityBillUpdate) {
		return {lastFlatUpdate: lastFlatUpdate, lastUserUpdate: lastUserUpdate, lastUtilityBillUpdate: lastUtilityBillUpdate};
	});
var $author$project$Collections$updateTimesDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Collections$UpdateTimes,
	A2($elm$json$Json$Decode$field, 'user', $author$project$Utils$parseTimestamp),
	A2($elm$json$Json$Decode$field, 'flat', $author$project$Utils$parseTimestamp),
	A2($elm$json$Json$Decode$field, 'utility_bill', $author$project$Utils$parseTimestamp));
var $author$project$Collections$getUserRelatedUpdateTimes = A2(
	$author$project$API$getSingle,
	'update_times',
	A2($author$project$API$Get, $author$project$Collections$updateTimesDecoder, _List_Nil));
var $author$project$Collections$getUtilityBills = A2(
	$author$project$API$getAll,
	'utility_bill',
	A2(
		$author$project$API$Get,
		$author$project$Domain$PB$decoderWithID($author$project$Domain$utilityBillDecoder),
		_List_Nil));
var $author$project$API$Resource$toMeta = function (_v0) {
	var m = _v0.a;
	return m;
};
var $author$project$API$Resource$loaded = F2(
	function (data, status) {
		return A2(
			$author$project$API$Resource$Resource,
			$author$project$API$Resource$toMeta(status),
			$author$project$API$Status$Loaded(data));
	});
var $elm$core$Task$map4 = F5(
	function (func, taskA, taskB, taskC, taskD) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return A2(
							$elm$core$Task$andThen,
							function (c) {
								return A2(
									$elm$core$Task$andThen,
									function (d) {
										return $elm$core$Task$succeed(
											A4(func, a, b, c, d));
									},
									taskD);
							},
							taskC);
					},
					taskB);
			},
			taskA);
	});
var $elm$time$Time$Name = function (a) {
	return {$: 'Name', a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 'Offset', a: a};
};
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Utils$posixLess = F2(
	function (left, right) {
		return _Utils_cmp(
			$elm$time$Time$posixToMillis(left),
			$elm$time$Time$posixToMillis(right)) < 0;
	});
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$API$string = function (s) {
	return '\'' + (s + '\'');
};
var $author$project$API$Status$toMaybe = function (status) {
	if (status.$ === 'Loaded') {
		var result = status.a;
		return $elm$core$Maybe$Just(result);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$API$Resource$toMaybe = function (_v0) {
	var status = _v0.b;
	return $author$project$API$Status$toMaybe(status);
};
var $author$project$Session$fillCache = function (_v0) {
	var key = _v0.a;
	var viewer = _v0.b;
	if (viewer.$ === 'Guest') {
		return $elm$core$Task$fail(
			$author$project$API$Error$Bug('unexpected viewer in fillUser: Guest'));
	} else {
		if (viewer.a.b.$ === 'ManagerLocalStorage') {
			var _v2 = viewer.a;
			return $elm$core$Task$succeed(
				A2($author$project$Session$Session, key, viewer));
		} else {
			var _v3 = viewer.a;
			var lastUpdated = _v3.a;
			var ls = _v3.b.a;
			var authToken = ls.authToken;
			var user = ls.user;
			var cart = ls.cart;
			var serviceRequestsByServiceId = A2(
				$elmcraft$core_extra$Dict$Extra$fromListBy,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.service;
					},
					$author$project$API$Resource$toMeta),
				cart);
			var _new = $author$project$Utils$posixLess(lastUpdated);
			var getUtilityBills = A2(
				$author$project$Collections$getUtilityBills,
				_List_fromArray(
					[
						$author$project$Session$authorizationHeader(authToken)
					]),
				_List_fromArray(
					[
						$author$project$API$Sort(
						_List_fromArray(
							['issued']))
					]));
			var getUserRelatedUpdateTimes = A3(
				$author$project$Collections$getUserRelatedUpdateTimes,
				_List_fromArray(
					[
						$author$project$Session$authorizationHeader(authToken)
					]),
				_List_Nil,
				user.meta.id);
			var getUserInfo = A3(
				$author$project$Collections$getUser,
				_List_fromArray(
					[
						$author$project$Session$authorizationHeader(authToken)
					]),
				_List_Nil,
				user.meta.id);
			var fillCartWithServices = function () {
				if (!cart.b) {
					return $elm$core$Task$succeed(_List_Nil);
				} else {
					return A2(
						$elm$core$Task$map,
						$elm$core$List$filterMap(
							function (service) {
								return A2(
									$elm$core$Maybe$map,
									function (req) {
										return _Utils_update(
											req,
											{
												service: A2($author$project$API$Resource$loaded, service, req.service)
											});
									},
									A2($elm$core$Dict$get, service.meta.id, serviceRequestsByServiceId));
							}),
						A2(
							$author$project$Collections$getServices,
							_List_Nil,
							$elm$core$List$singleton(
								$author$project$API$Filter(
									$author$project$API$OR(
										A2(
											$elm$core$List$map,
											function (_v10) {
												var service = _v10.service;
												return A2(
													$author$project$API$Eq,
													'id',
													$author$project$API$string(
														$author$project$API$Resource$toMeta(service)));
											},
											cart))))));
				}
			}();
			var fillCartWithFlats = function (flats) {
				var flatsById = A2(
					$elmcraft$core_extra$Dict$Extra$fromListBy,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.meta;
						},
						function ($) {
							return $.id;
						}),
					flats);
				return $elm$core$List$filterMap(
					function (request) {
						return A2(
							$elm$core$Maybe$map,
							function (flat) {
								return _Utils_update(
									request,
									{
										flat: A2($author$project$API$Resource$loaded, flat, request.flat)
									});
							},
							A2(
								$elm$core$Dict$get,
								$author$project$API$Resource$toMeta(request.flat),
								flatsById));
					});
			};
			var updateAll = A5(
				$elm$core$Task$map4,
				F4(
					function (userData, bills, filledCart, time) {
						var userDomain = userData.domain;
						var billsByFlat = function (flat) {
							return $elm$core$List$filter(
								function (bill) {
									return function (_v8) {
										var flatId = _v8.a.flatId;
										return _Utils_eq(flatId, flat.meta.id);
									}(bill.domain);
								});
						};
						var flats = function () {
							var _v7 = userData.domain.flat;
							if (_v7.b.$ === 'Loaded') {
								var flatIds = _v7.a;
								var flatList = _v7.b.a;
								return A2(
									$author$project$API$Resource$Resource,
									flatIds,
									$author$project$API$Status$Loaded(
										A2(
											$elm$core$List$map,
											function (flat) {
												var meta = flat.meta;
												var domain = flat.domain;
												return A2(
													$author$project$Domain$PB$Record,
													meta,
													_Utils_update(
														domain,
														{
															utilityBills: $elm$core$Maybe$Just(
																A2(billsByFlat, flat, bills))
														}));
											},
											flatList)));
							} else {
								var somethingElse = _v7;
								return somethingElse;
							}
						}();
						var newStorage = $author$project$Session$TenantLocalStorage(
							_Utils_update(
								ls,
								{
									cart: A2(
										fillCartWithFlats,
										A2(
											$elm$core$Maybe$withDefault,
											_List_Nil,
											$author$project$API$Resource$toMaybe(userData.domain.flat)),
										filledCart),
									user: _Utils_update(
										userData,
										{
											domain: _Utils_update(
												userDomain,
												{flat: flats})
										})
								}));
						return A2(
							$author$project$Session$Session,
							key,
							$author$project$Session$Authenticated(
								A2($author$project$Session$LocalStorage, time, newStorage)));
					}),
				getUserInfo,
				getUtilityBills,
				fillCartWithServices,
				$elm$time$Time$now);
			var billsAreFilled = A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$map(
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.domain;
								},
								function ($) {
									return $.utilityBills;
								})),
						$elm$core$List$all(
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Maybe$map(
									$elm$core$Basics$always(true)),
								$elm$core$Maybe$withDefault(false)))),
					$author$project$API$Resource$toMaybe(user.domain.flat)));
			var _v4 = _Utils_Tuple2(
				$author$project$API$Resource$toMaybe(user.domain.flat),
				billsAreFilled);
			if ((_v4.a.$ === 'Just') && _v4.b) {
				return A2(
					$elm$core$Task$andThen,
					function (_v5) {
						var lastUserUpdate = _v5.lastUserUpdate;
						var lastFlatUpdate = _v5.lastFlatUpdate;
						var lastUtilityBillUpdate = _v5.lastUtilityBillUpdate;
						var _v6 = _Utils_Tuple3(
							_new(lastUserUpdate),
							_new(lastFlatUpdate),
							_new(lastUtilityBillUpdate));
						if (((!_v6.a) && (!_v6.b)) && (!_v6.c)) {
							return A3(
								$elm$core$Task$map2,
								F2(
									function (filledCart, time) {
										return A2(
											$author$project$Session$Session,
											key,
											$author$project$Session$Authenticated(
												A2(
													$author$project$Session$LocalStorage,
													time,
													$author$project$Session$TenantLocalStorage(
														_Utils_update(
															ls,
															{
																cart: A2(
																	fillCartWithFlats,
																	A2(
																		$elm$core$Maybe$withDefault,
																		_List_Nil,
																		$author$project$API$Resource$toMaybe(user.domain.flat)),
																	filledCart)
															})))));
									}),
								fillCartWithServices,
								$elm$time$Time$now);
						} else {
							return updateAll;
						}
					},
					getUserRelatedUpdateTimes);
			} else {
				return updateAll;
			}
		}
	}
};
var $author$project$Session$logIn = F2(
	function (_v0, _v1) {
		var key = _v0.a;
		var credentials = _v1.a;
		return A2(
			$elm$core$Task$andThen,
			$author$project$Session$fillCache,
			A2(
				$elm$core$Task$andThen,
				function (time) {
					return $elm$http$Http$task(
						{
							body: $elm$http$Http$jsonBody(
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'identity',
											$elm$json$Json$Encode$string(credentials.email)),
											_Utils_Tuple2(
											'password',
											$elm$json$Json$Encode$string(credentials.password))
										]))),
							headers: _List_Nil,
							method: 'POST',
							resolver: $author$project$API$jsonResolver(
								A2(
									$elm$json$Json$Decode$map,
									$author$project$Session$Session(key),
									A2($author$project$Session$authResponseDecoder, time, _List_Nil))),
							timeout: $author$project$API$timeout,
							url: '/api/collections/tenant/auth-with-password?expand=flat,flat.building'
						});
				},
				$elm$time$Time$now));
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $mdgriffith$elm_ui$Element$Input$newPassword = F2(
	function (attrs, pass) {
		return A3(
			$mdgriffith$elm_ui$Element$Input$textHelper,
			{
				autofill: $elm$core$Maybe$Just('new-password'),
				spellchecked: false,
				type_: $mdgriffith$elm_ui$Element$Input$TextInputNode(
					pass.show ? 'text' : 'password')
			},
			attrs,
			{label: pass.label, onChange: pass.onChange, placeholder: pass.placeholder, text: pass.text});
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$http$Http$Request = function (a) {
	return {$: 'Request', a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {reqs: reqs, subs: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (cmd.$ === 'Cancel') {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 'Nothing') {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.tracker;
							if (_v4.$ === 'Nothing') {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.reqs));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.subs)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 'Cancel', a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (cmd.$ === 'Cancel') {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					allowCookiesFromOtherDomains: r.allowCookiesFromOtherDomains,
					body: r.body,
					expect: A2(_Http_mapExpect, func, r.expect),
					headers: r.headers,
					method: r.method,
					timeout: r.timeout,
					tracker: r.tracker,
					url: r.url
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 'MySub', a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{allowCookiesFromOtherDomains: false, body: r.body, expect: r.expect, headers: r.headers, method: r.method, timeout: r.timeout, tracker: r.tracker, url: r.url}));
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{body: r.body, expect: r.expect, headers: _List_Nil, method: 'POST', timeout: $elm$core$Maybe$Nothing, tracker: $elm$core$Maybe$Nothing, url: r.url});
};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $elmcraft$core_extra$String$Extra$firstResultHelp = F2(
	function (_default, list) {
		firstResultHelp:
		while (true) {
			if (!list.b) {
				return _default;
			} else {
				if (list.a.$ === 'Just') {
					var a = list.a.a;
					return a;
				} else {
					var _v1 = list.a;
					var rest = list.b;
					var $temp$default = _default,
						$temp$list = rest;
					_default = $temp$default;
					list = $temp$list;
					continue firstResultHelp;
				}
			}
		}
	});
var $elmcraft$core_extra$String$Extra$firstResult = function (list) {
	return A2($elmcraft$core_extra$String$Extra$firstResultHelp, '', list);
};
var $elmcraft$core_extra$String$Extra$regexFromString = function (str) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString(str));
};
var $elmcraft$core_extra$String$Extra$regexEscapeRegex = $elmcraft$core_extra$String$Extra$regexFromString('[-/\\^$*+?.()|[\\]{}]');
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elmcraft$core_extra$String$Extra$regexEscape = A2(
	$elm$regex$Regex$replace,
	$elmcraft$core_extra$String$Extra$regexEscapeRegex,
	function (_v0) {
		var match = _v0.match;
		return '\\' + match;
	});
var $elmcraft$core_extra$String$Extra$rightOf = F2(
	function (pattern, string) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.submatches;
					},
					$elmcraft$core_extra$String$Extra$firstResult),
				A3(
					$elm$regex$Regex$findAtMost,
					1,
					$elmcraft$core_extra$String$Extra$regexFromString(
						$elmcraft$core_extra$String$Extra$regexEscape(pattern) + '(.*)$'),
					string)));
	});
var $author$project$Session$signUp = F2(
	function (guest, _v0) {
		var data = _v0.a;
		var request = $elm$http$Http$task(
			{
				body: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'email',
								$elm$json$Json$Encode$string(data.email)),
								_Utils_Tuple2(
								'password',
								$elm$json$Json$Encode$string(data.password)),
								_Utils_Tuple2(
								'passwordConfirm',
								$elm$json$Json$Encode$string(data.passwordConfirm)),
								_Utils_Tuple2(
								'name',
								$elm$json$Json$Encode$string(data.name)),
								_Utils_Tuple2(
								'surname',
								$elm$json$Json$Encode$string(data.surname)),
								_Utils_Tuple2(
								'patronymic',
								$elm$json$Json$Encode$string(data.patronymic)),
								_Utils_Tuple2(
								'flat',
								A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, data.flat))
							]))),
				headers: _List_Nil,
				method: 'POST',
				resolver: $author$project$API$jsonResolver(
					$elm$json$Json$Decode$succeed(_Utils_Tuple0)),
				timeout: $author$project$API$timeout,
				url: '/api/collections/tenant/records'
			});
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return A2(
					$author$project$Session$logIn,
					guest,
					$author$project$Session$LogIn(
						{email: data.email, password: data.password}));
			},
			request);
	});
var $author$project$UI$Form$toResMaybe = function (fieldResult) {
	switch (fieldResult.$) {
		case 'WithResult':
			var x = fieldResult.a;
			return $elm$core$Result$Ok(
				$elm$core$Maybe$Just(x));
		case 'Omitted':
			return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
		default:
			var x = fieldResult.a;
			return $elm$core$Result$Err(x);
	}
};
var $elm$core$Debug$todo = _Debug_todo;
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (result.$ === 'Ok') {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Page$Auth$update = F2(
	function (msg, form) {
		var withError = function (message) {
			return _Utils_Tuple3(
				_Utils_update(
					form,
					{
						error: $elm$core$Maybe$Just(message)
					}),
				$elm$core$Platform$Cmd$none,
				$author$project$Framework$NoOp);
		};
		var _v0 = _Utils_Tuple2(form.surname, form.patronymic);
		var surnameSpecs = _v0.a;
		var patronymicSpecs = _v0.b;
		var _v1 = _Utils_Tuple3(form.email, form.name, form.password);
		var emailSpecs = _v1.a;
		var nameSpecs = _v1.b;
		var passwordSpecs = _v1.c;
		var _v2 = _Utils_Tuple3(form.passwordAgain, form.building, form.flat);
		var passwordAgainSpecs = _v2.a;
		var buildingSpecs = _v2.b;
		var flatSpecs = _v2.c;
		switch (msg.$) {
			case 'UpdatedEmailField':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{email: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'UpdatedNameField':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{name: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'UpdatedSurnameField':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{surname: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'UpdatedPatronymicField':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{patronymic: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'UpdatedPasswordField':
				var specs = msg.a.a;
				var displayed = specs.displayed;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{
							password: specs,
							passwordAgain: _Utils_update(
								passwordAgainSpecs,
								{
									check: A2(
										$author$project$UI$Form$simpleCheck,
										$elm$core$Basics$eq(displayed),
										'Пароли не совпадают!')
								})
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'UpdatedPasswordAgainField':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{passwordAgain: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'SetFormAction':
				var action = msg.a;
				var show = function () {
					var _v5 = passwordSpecs.kind;
					if (_v5.$ === 'Password') {
						var value = _v5.a;
						return value;
					} else {
						return false;
					}
				}();
				var inputFunction = function () {
					if (action.$ === 'LogIn') {
						return $mdgriffith$elm_ui$Element$Input$currentPassword;
					} else {
						return $mdgriffith$elm_ui$Element$Input$newPassword;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{
							action: action,
							password: _Utils_update(
								passwordSpecs,
								{
									kind: A2($author$project$UI$Form$Text$Password, show, inputFunction)
								})
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'SetShowPassword':
				var show = msg.a;
				var updatedForm = F2(
					function (specs, inputFunction) {
						return _Utils_update(
							specs,
							{
								kind: A2($author$project$UI$Form$Text$Password, show, inputFunction)
							});
					});
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{
							password: A2(updatedForm, passwordSpecs, $mdgriffith$elm_ui$Element$Input$newPassword),
							passwordAgain: A2(updatedForm, passwordAgainSpecs, $mdgriffith$elm_ui$Element$Input$currentPassword),
							showPasswords: show
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'Submitted':
				var _v6 = form.action;
				if (_v6.$ === 'LogIn') {
					var _v7 = _Utils_Tuple2(
						$author$project$UI$Form$result(emailSpecs),
						$author$project$UI$Form$result(passwordSpecs));
					if ((_v7.a.$ === 'WithResult') && (_v7.b.$ === 'WithResult')) {
						var email = _v7.a.a;
						var password = _v7.b.a;
						return _Utils_Tuple3(
							form,
							A2(
								$elm$core$Task$attempt,
								$author$project$Page$Auth$GotLogInResponse,
								A2(
									$author$project$Session$logIn,
									form.session,
									$author$project$Session$LogIn(
										{
											email: $elm$core$String$trim(email),
											password: password
										}))),
							$author$project$Framework$NoOp);
					} else {
						return withError('Проверьте правильность заполнения формы!');
					}
				} else {
					var _v8 = _Utils_Tuple3(
						_Utils_Tuple3(
							$author$project$UI$Form$result(emailSpecs),
							$author$project$UI$Form$result(passwordSpecs),
							$author$project$UI$Form$result(passwordAgainSpecs)),
						_Utils_Tuple3(
							$author$project$UI$Form$result(nameSpecs),
							$author$project$UI$Form$result(surnameSpecs),
							$author$project$UI$Form$result(patronymicSpecs)),
						form.selectedFlat);
					if (((((((_v8.a.a.$ === 'WithResult') && (_v8.a.b.$ === 'WithResult')) && (_v8.a.c.$ === 'WithResult')) && (_v8.b.a.$ === 'WithResult')) && (_v8.b.b.$ === 'WithResult')) && (_v8.b.c.$ === 'WithResult')) && (_v8.c.$ === 'Just')) {
						var _v9 = _v8.a;
						var email = _v9.a.a;
						var password = _v9.b.a;
						var passwordConfirm = _v9.c.a;
						var _v10 = _v8.b;
						var name = _v10.a.a;
						var surname = _v10.b.a;
						var patronymic = _v10.c.a;
						var flatID = _v8.c.a;
						return _Utils_Tuple3(
							form,
							A2(
								$elm$core$Task$attempt,
								$author$project$Page$Auth$GotSignUpResponse,
								A2(
									$author$project$Session$signUp,
									form.session,
									$author$project$Session$SignUp(
										{
											email: $elm$core$String$trim(email),
											flat: _List_fromArray(
												[flatID]),
											name: $elm$core$String$trim(name),
											password: password,
											passwordConfirm: passwordConfirm,
											patronymic: $elm$core$String$trim(patronymic),
											surname: $elm$core$String$trim(surname)
										}))),
							$author$project$Framework$NoOp);
					} else {
						return withError('Проверьте правильность заполнения формы!');
					}
				}
			case 'UpdatedBuildingField':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{building: specs, flat: $author$project$Page$Auth$defaultFields.flat}),
					function () {
						var _v11 = specs.state;
						if (_v11.$ === 'Just') {
							var entry = _v11.a;
							return A2(
								$elm$core$Task$attempt,
								$author$project$Page$Auth$GotFlatList,
								A2(
									$author$project$Collections$getPublicFlatData,
									_List_Nil,
									_List_fromArray(
										[
											$author$project$API$Filter(
											A2(
												$author$project$API$Eq,
												'building',
												$author$project$API$string(entry.tag)))
										])));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}(),
					$author$project$Framework$NoOp);
			case 'SetBuildingDropdownOpen':
				var state = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{
							building: _Utils_update(
								buildingSpecs,
								{
									open: state,
									toggle: $author$project$Page$Auth$SetBuildingDropdownOpen(!state)
								})
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'GotBuildingList':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var buildings = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							form,
							{
								building: function () {
									var unwantedPrefix = 'РОССИЯ, 672040, Забайкальский край, Чита г, ';
									return _Utils_update(
										buildingSpecs,
										{
											options: A2(
												$elm$core$List$map,
												function (x) {
													return A2(
														$author$project$UI$Form$Dropdown$DropdownEntry,
														A2($elm$core$String$startsWith, unwantedPrefix, x.domain.address) ? A2($elmcraft$core_extra$String$Extra$rightOf, unwantedPrefix, x.domain.address) : x.domain.address,
														x.meta.id);
												},
												buildings)
										});
								}(),
								buildings: $author$project$API$Status$Loaded(
									$elm$core$Dict$fromList(
										A2(
											$elm$core$List$map,
											function (x) {
												return _Utils_Tuple2(x.meta.id, x.domain.address);
											},
											buildings)))
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					var err = result.a;
					switch (err.$) {
						case 'Timeout':
							return withError('Превышено время ожидания ответа. Обновите страницу и попробуйте ещё раз.');
						case 'NetworkError':
							return withError('Нет соединения с сервером. Проверьте соединение с сетью.');
						default:
							return withError('Непредвиденная ошибка! Сообщите в поддержку.');
					}
				}
			case 'GotFlatList':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var flats = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							form,
							{
								flat: _Utils_update(
									flatSpecs,
									{
										check: A2(
											$author$project$UI$Form$simpleCheck,
											function (num) {
												return A2(
													$elm$core$List$member,
													num,
													A2(
														$elm$core$List$map,
														A2(
															$elm$core$Basics$composeR,
															function ($) {
																return $.domain;
															},
															function ($) {
																return $.number;
															}),
														flats));
											},
											'Такая квартира не найдена в выбранном доме')
									}),
								flats: $author$project$API$Status$Loaded(
									$elm$core$Dict$fromList(
										A2(
											$elm$core$List$map,
											function (x) {
												return _Utils_Tuple2(x.domain.number, x.meta.id);
											},
											flats)))
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					return _Debug_todo(
						'Page.Auth',
						{
							start: {line: 500, column: 21},
							end: {line: 500, column: 31}
						})('branch \'Err _\' not implemented');
				}
			case 'UpdatedFlatField':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{
							flat: specs,
							selectedFlat: function () {
								var _v15 = form.flats;
								if (_v15.$ === 'Loaded') {
									var flats = _v15.a;
									return A2(
										$elm$core$Maybe$andThen,
										function (x) {
											return A2($elm$core$Dict$get, x, flats);
										},
										specs.state);
								} else {
									return $elm$core$Maybe$Nothing;
								}
							}()
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'GotSignUpResponse':
				var result = msg.a;
				if (result.$ === 'Ok') {
					return _Utils_Tuple3(
						form,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$elm$http$Http$post(
									{
										body: $elm$http$Http$jsonBody(
											$elm$json$Json$Encode$object(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'email',
														$elm$json$Json$Encode$string(
															A2(
																$elm$core$Maybe$withDefault,
																'',
																A2(
																	$elm$core$Result$withDefault,
																	$elm$core$Maybe$Nothing,
																	$author$project$UI$Form$toResMaybe(
																		$author$project$UI$Form$result(emailSpecs))))))
													]))),
										expect: $elm$http$Http$expectWhatever(
											function (_v17) {
												return $author$project$Page$Auth$NoOp;
											}),
										url: '/api/collections/tenant/request-verification'
									}),
									A2(
									$elm$core$Task$attempt,
									$author$project$Page$Auth$GotLogInResponse,
									A2(
										$author$project$Session$logIn,
										form.session,
										$author$project$Session$LogIn(
											{
												email: A2(
													$elm$core$Maybe$withDefault,
													'',
													A2(
														$elm$core$Result$withDefault,
														$elm$core$Maybe$Nothing,
														$author$project$UI$Form$toResMaybe(
															$author$project$UI$Form$result(emailSpecs)))),
												password: A2(
													$elm$core$Maybe$withDefault,
													'',
													A2(
														$elm$core$Result$withDefault,
														$elm$core$Maybe$Nothing,
														$author$project$UI$Form$toResMaybe(
															$author$project$UI$Form$result(passwordSpecs))))
											})))
								])),
						$author$project$Framework$NoOp);
				} else {
					var err = result.a;
					switch (err.$) {
						case 'BadRequest':
							return withError('Возможно, этот email уже занят или мы не знаем о таком жильце');
						case 'Timeout':
							return withError('Превышено время ожидания ответа. Попробуйте ещё раз.');
						case 'NetworkError':
							return withError('Нет соединения с сервером. Проверьте соединение с сетью.');
						default:
							return withError('Непредвиденная ошибка! Сообщите в поддержку');
					}
				}
			case 'GotLogInResponse':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var session = result.a;
					if (session.b.$ === 'Authenticated') {
						var key = session.a;
						var _v21 = session.b.a;
						var lsData = _v21.b;
						return _Utils_Tuple3(
							_Utils_update(
								form,
								{session: session}),
							A2($elm$browser$Browser$Navigation$pushUrl, key, form.next),
							$author$project$Framework$UpdateLocalStorage(lsData));
					} else {
						return _Utils_Tuple3(form, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
					}
				} else {
					var err = result.a;
					switch (err.$) {
						case 'Timeout':
							return withError('Превышено время ожидания ответа! Попробуйте ещё раз');
						case 'NetworkError':
							return withError('Нет соединения с сервером! Проверьте подключение к сети.');
						case 'BadRequest':
							return withError('Неверная комбинация email и пароля');
						default:
							return withError('Непредвиденная ошибка! Сообщите в поддержку.');
					}
				}
			default:
				return _Utils_Tuple3(form, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
		}
	});
var $author$project$UI$Button$Outline = {$: 'Outline'};
var $author$project$Page$Auth$SetFormAction = function (a) {
	return {$: 'SetFormAction', a: a};
};
var $author$project$Page$Auth$SetShowPassword = function (a) {
	return {$: 'SetShowPassword', a: a};
};
var $author$project$Page$Auth$SignUp = {$: 'SignUp'};
var $author$project$Page$Auth$Submitted = {$: 'Submitted'};
var $mdgriffith$elm_ui$Internal$Model$CenterX = {$: 'CenterX'};
var $mdgriffith$elm_ui$Element$centerX = $mdgriffith$elm_ui$Internal$Model$AlignX($mdgriffith$elm_ui$Internal$Model$CenterX);
var $author$project$UI$Form$empty = {body: _List_Nil, defaultAction: $elm$core$Maybe$Nothing, under: $mdgriffith$elm_ui$Element$none, valid: true};
var $mdgriffith$elm_ui$Element$Font$heavy = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.textHeavy);
var $author$project$UI$Form$errorCard = function (error) {
	if (error.$ === 'Nothing') {
		return $mdgriffith$elm_ui$Element$none;
	} else {
		var errorMessage = error.a;
		return A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_Utils_ap(
				$author$project$UI$border($author$project$UI$colors.error),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$padding(
						$author$project$UI$scaled(2)),
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
						$mdgriffith$elm_ui$Element$Font$heavy,
						$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.transparentRed)
					])),
			$elm$core$List$singleton(
				$mdgriffith$elm_ui$Element$text(errorMessage)));
	}
};
var $mdgriffith$elm_ui$Internal$Model$CenterY = {$: 'CenterY'};
var $mdgriffith$elm_ui$Element$centerY = $mdgriffith$elm_ui$Internal$Model$AlignY($mdgriffith$elm_ui$Internal$Model$CenterY);
var $mdgriffith$elm_ui$Internal$Model$Max = F2(
	function (a, b) {
		return {$: 'Max', a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$maximum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Max, i, l);
	});
var $author$project$UI$Form$form = F2(
	function (extraAttrs, _v0) {
		var body = _v0.body;
		var under = _v0.under;
		var defaultAction = _v0.defaultAction;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(6),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						$mdgriffith$elm_ui$Element$centerY
					]),
				extraAttrs),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_Utils_ap(
						$author$project$UI$border($author$project$UI$colors.transparent),
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$padding(
									$author$project$UI$scaled(2)),
									$mdgriffith$elm_ui$Element$spacing(
									$author$project$UI$scaled(2)),
									$mdgriffith$elm_ui$Element$width(
									A2(
										$mdgriffith$elm_ui$Element$maximum,
										800,
										A2($mdgriffith$elm_ui$Element$minimum, 300, $mdgriffith$elm_ui$Element$fill))),
									$author$project$UI$shadow,
									$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_0)
								]),
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2(
									$elm$core$Maybe$map,
									A2($elm$core$Basics$composeR, $author$project$Utils$onEnter, $elm$core$List$singleton),
									defaultAction)))),
					body),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					under)
				]));
	});
var $author$project$UI$Form$ifValid = F2(
	function (func, content) {
		return content.valid ? func(content) : content;
	});
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $mdgriffith$elm_ui$Element$link = F2(
	function (attrs, _v0) {
		var url = _v0.url;
		var label = _v0.label;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentCenterX + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.contentCenterY + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.link)))),
								attrs))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Internal$Model$Scale = function (a) {
	return {$: 'Scale', a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$scale = $mdgriffith$elm_ui$Internal$Flag$flag(23);
var $mdgriffith$elm_ui$Element$scale = function (n) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$scale,
		$mdgriffith$elm_ui$Internal$Model$Scale(
			_Utils_Tuple3(n, n, 1)));
};
var $mdgriffith$elm_ui$Element$Font$underline = $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.underline);
var $author$project$UI$Form$withButton = F4(
	function (makeDefault, wrapper, specs, content) {
		return _Utils_update(
			content,
			{
				body: _Utils_ap(
					content.body,
					_List_fromArray(
						[
							A3(
							$elm$core$Basics$apR,
							wrapper,
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							$author$project$UI$Button$button(specs))
						])),
				defaultAction: function () {
					var _v0 = _Utils_Tuple3(makeDefault, content.defaultAction, specs.action);
					if (_v0.a) {
						return specs.action;
					} else {
						if ((_v0.b.$ === 'Nothing') && (_v0.c.$ === 'Just')) {
							var _v1 = _v0.b;
							return specs.action;
						} else {
							return content.defaultAction;
						}
					}
				}()
			});
	});
var $author$project$UI$Form$withExternal = F2(
	function (external, content) {
		return _Utils_update(
			content,
			{
				body: _Utils_ap(
					content.body,
					_List_fromArray(
						[external]))
			});
	});
var $author$project$UI$Form$withField = F2(
	function (specs, content) {
		return _Utils_update(
			content,
			{
				body: _Utils_ap(
					content.body,
					_List_fromArray(
						[
							specs.render(
							$author$project$UI$Form$Field(specs))
						])),
				valid: function () {
					var _v0 = $author$project$UI$Form$result(specs);
					if (_v0.$ === 'Error') {
						return false;
					} else {
						return content.valid;
					}
				}()
			});
	});
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$UI$withGlobalBackgroundImage = function (url) {
	return A3(
		$elm$html$Html$node,
		'style',
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				$elm$core$String$concat(
					_List_fromArray(
						['\nbody::before {\n    content: \'\';\n    background-image: url(\"', url, '");\n    background-size: cover;\n    background-position: center;\n    width: 110%;\n    height: 110%;\n    position: fixed;\n    top: -5%;\n    left: -5%;\n    z-index: -1;\n    filter: contrast(120%) brightness(80%) blur(0.3vw) grayscale(50%);\n}\n'])))
			]));
};
var $author$project$UI$Form$withRow = F4(
	function (resetDefault, attrs, row, content) {
		return _Utils_update(
			content,
			{
				body: _Utils_ap(
					content.body,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_Utils_ap(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(
										$author$project$UI$scaled(-1))
									]),
								attrs),
							row.body)
						])),
				defaultAction: resetDefault ? row.defaultAction : content.defaultAction,
				valid: content.valid && row.valid
			});
	});
var $author$project$UI$Form$withTitle = F2(
	function (title, content) {
		return _Utils_update(
			content,
			{
				body: _Utils_ap(
					content.body,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$author$project$UI$fontsizes.big, $mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$center]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(title)
								]))
						]))
			});
	});
var $author$project$UI$Form$withUnder = F2(
	function (under, content) {
		return _Utils_update(
			content,
			{under: under});
	});
var $author$project$Page$Auth$view = function (model) {
	var withSubmissionButton = function (label) {
		return $author$project$UI$Form$ifValid(
			A3(
				$author$project$UI$Form$withButton,
				true,
				$elm$core$Maybe$Nothing,
				A4(
					$author$project$UI$Button$ButtonSpecs,
					$elm$core$Maybe$Just($author$project$Page$Auth$Submitted),
					$mdgriffith$elm_ui$Element$text(label),
					$author$project$UI$Button$Filled,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]))));
	};
	var visibilityButton = function (show) {
		return A4(
			$author$project$UI$Button$ButtonSpecs,
			$elm$core$Maybe$Just(
				$author$project$Page$Auth$SetShowPassword(show)),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_Nil,
				A2(
					$author$project$UI$materialIcon,
					show ? 'visibility' : 'visibility_off',
					32)),
			$author$project$UI$Button$Outline,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(
					$author$project$UI$scaled(-3)),
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60)
				]));
	};
	var withFieldAndVisibilityButton = function (field) {
		return A3(
			$author$project$UI$Form$withRow,
			false,
			_List_Nil,
			A4(
				$author$project$UI$Form$withButton,
				false,
				$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{
									bottom: 0,
									left: 0,
									right: 0,
									top: $author$project$UI$scaled(3)
								}),
								$mdgriffith$elm_ui$Element$alignTop
							]))),
				visibilityButton(!model.showPasswords),
				A2($author$project$UI$Form$withField, field, $author$project$UI$Form$empty)));
	};
	var under = F2(
		function (state, text) {
			return A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(6),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$focused(_List_Nil)
					]),
				{
					label: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
								$mdgriffith$elm_ui$Element$Font$underline,
								$mdgriffith$elm_ui$Element$Font$center,
								$mdgriffith$elm_ui$Element$focused(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$scale(1.1)
									]))
							]),
						$mdgriffith$elm_ui$Element$text(text)),
					onPress: $elm$core$Maybe$Just(
						$author$project$Page$Auth$SetFormAction(state))
				});
		});
	var linkToResetPassword = A2(
		$mdgriffith$elm_ui$Element$link,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(6),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
				$mdgriffith$elm_ui$Element$Font$underline,
				$mdgriffith$elm_ui$Element$Font$center,
				$mdgriffith$elm_ui$Element$focused(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$scale(1.1)
					]))
			]),
		{
			label: A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Забыли пароль?')
					])),
			url: '/auth/reset-password'
		});
	var linkToManagerAuth = A2(
		$mdgriffith$elm_ui$Element$link,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(6),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
				$mdgriffith$elm_ui$Element$Font$underline,
				$mdgriffith$elm_ui$Element$Font$center,
				$mdgriffith$elm_ui$Element$focused(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$scale(1.1)
					]))
			]),
		{
			label: A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Вход для сотрудников')
					])),
			url: '/manage/auth'
		});
	var loginContent = A2(
		$author$project$UI$Form$withUnder,
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			_List_fromArray(
				[
					A2(under, $author$project$Page$Auth$SignUp, 'Регистрация'),
					linkToResetPassword,
					linkToManagerAuth,
					$author$project$UI$Form$errorCard(model.error)
				])),
		A2(
			withSubmissionButton,
			'Вход',
			A2(
				withFieldAndVisibilityButton,
				model.password,
				A2(
					$author$project$UI$Form$withField,
					model.email,
					A2($author$project$UI$Form$withTitle, 'Вход для жильцов', $author$project$UI$Form$empty)))));
	var signupContent = A2(
		$author$project$UI$Form$withUnder,
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			_List_fromArray(
				[
					A2(under, $author$project$Page$Auth$LogIn, 'Вход'),
					linkToManagerAuth,
					$author$project$UI$Form$errorCard(model.error)
				])),
		A2(
			withSubmissionButton,
			'Регистрация',
			A2(
				$author$project$UI$Form$withField,
				model.flat,
				A2(
					$author$project$UI$Form$withField,
					model.building,
					A2(
						withFieldAndVisibilityButton,
						model.passwordAgain,
						A2(
							withFieldAndVisibilityButton,
							model.password,
							A2(
								$author$project$UI$Form$withField,
								model.patronymic,
								A2(
									$author$project$UI$Form$withField,
									model.name,
									A2(
										$author$project$UI$Form$withField,
										model.surname,
										A2(
											$author$project$UI$Form$withField,
											model.email,
											A2($author$project$UI$Form$withTitle, 'Регистрация для жильцов', $author$project$UI$Form$empty)))))))))));
	return A2(
		$author$project$UI$Form$form,
		_List_Nil,
		A2(
			$author$project$UI$Form$withExternal,
			$mdgriffith$elm_ui$Element$html(
				$author$project$UI$withGlobalBackgroundImage(require('/build/front/public/background.webp'))),
			function () {
				var _v0 = model.action;
				if (_v0.$ === 'LogIn') {
					return loginContent;
				} else {
					return signupContent;
				}
			}()));
};
var $author$project$Page$Auth$wrap = F2(
	function (base, args) {
		return {device: base.device, next: args.next, session: base.session};
	});
var $author$project$Page$Auth$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Protected($author$project$Page$Auth$accessFilter),
	{
		baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
		init: $author$project$Page$Auth$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $author$project$Page$Auth$title,
		update: $author$project$Framework$EffectUpdate($author$project$Page$Auth$update),
		view: $author$project$Framework$PlainView($author$project$Page$Auth$view),
		wrapArgs: $author$project$Page$Auth$wrap
	});
var $author$project$Framework$AuthenticationRequired = {$: 'AuthenticationRequired'};
var $author$project$Page$Cart$accessFilter = function (args) {
	var _v0 = $author$project$Session$getViewer(args.session);
	if (_v0.$ === 'Authenticated') {
		return $author$project$Framework$AccessGranted;
	} else {
		return $author$project$Framework$AuthenticationRequired;
	}
};
var $author$project$Page$Cart$ChangedComment = F2(
	function (a, b) {
		return {$: 'ChangedComment', a: a, b: b};
	});
var $author$project$Page$Cart$ChangedDate = F2(
	function (a, b) {
		return {$: 'ChangedDate', a: a, b: b};
	});
var $author$project$Page$Cart$ChangedNumber = F2(
	function (a, b) {
		return {$: 'ChangedNumber', a: a, b: b};
	});
var $author$project$UI$Form$Date$Day = F3(
	function (a, b, c) {
		return {$: 'Day', a: a, b: b, c: c};
	});
var $author$project$UI$Form$Date$Empty = {$: 'Empty'};
var $author$project$Page$Cart$Fieldset = F3(
	function (numberField, commentField, dateField) {
		return {commentField: commentField, dateField: dateField, numberField: numberField};
	});
var $author$project$Page$Cart$GotPosix = function (a) {
	return {$: 'GotPosix', a: a};
};
var $author$project$UI$Form$Text$Multiline = function (a) {
	return {$: 'Multiline', a: a};
};
var $author$project$UI$Form$Optional = {$: 'Optional'};
var $author$project$UI$Button$Borderless = {$: 'Borderless'};
var $justinmimbs$time_extra$Time$Extra$Day = {$: 'Day'};
var $justinmimbs$time_extra$Time$Extra$Month = {$: 'Month'};
var $author$project$UI$Form$Date$Month = F2(
	function (a, b) {
		return {$: 'Month', a: a, b: b};
	});
var $justinmimbs$time_extra$Time$Extra$Week = {$: 'Week'};
var $justinmimbs$time_extra$Time$Extra$Year = {$: 'Year'};
var $author$project$UI$Form$Date$Year = function (a) {
	return {$: 'Year', a: a};
};
var $justinmimbs$date$Date$Days = {$: 'Days'};
var $justinmimbs$time_extra$Time$Extra$Millisecond = {$: 'Millisecond'};
var $justinmimbs$date$Date$Months = {$: 'Months'};
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m.$) {
		case 'Jan':
			return 1;
		case 'Feb':
			return 2;
		case 'Mar':
			return 3;
		case 'Apr':
			return 4;
		case 'May':
			return 5;
		case 'Jun':
			return 6;
		case 'Jul':
			return 7;
		case 'Aug':
			return 8;
		case 'Sep':
			return 9;
		case 'Oct':
			return 10;
		case 'Nov':
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return $elm$time$Time$Jan;
		case 2:
			return $elm$time$Time$Feb;
		case 3:
			return $elm$time$Time$Mar;
		case 4:
			return $elm$time$Time$Apr;
		case 5:
			return $elm$time$Time$May;
		case 6:
			return $elm$time$Time$Jun;
		case 7:
			return $elm$time$Time$Jul;
		case 8:
			return $elm$time$Time$Aug;
		case 9:
			return $elm$time$Time$Sep;
		case 10:
			return $elm$time$Time$Oct;
		case 11:
			return $elm$time$Time$Nov;
		default:
			return $elm$time$Time$Dec;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {day: d, month: m, year: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0.a;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0.a;
	var y = $justinmimbs$date$Date$year(
		$justinmimbs$date$Date$RD(rd));
	return {
		ordinalDay: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		year: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0.a;
	var date = $justinmimbs$date$Date$toOrdinalDate(
		$justinmimbs$date$Date$RD(rd));
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.year, $elm$time$Time$Jan, date.ordinalDay);
};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0.a;
		switch (unit.$) {
			case 'Years':
				return A3(
					$justinmimbs$date$Date$add,
					$justinmimbs$date$Date$Months,
					12 * n,
					$justinmimbs$date$Date$RD(rd));
			case 'Months':
				var date = $justinmimbs$date$Date$toCalendarDate(
					$justinmimbs$date$Date$RD(rd));
				var wholeMonths = ((12 * (date.year - 1)) + ($justinmimbs$date$Date$monthToNumber(date.month) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return $justinmimbs$date$Date$RD(
					($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
						$elm$core$Basics$min,
						date.day,
						A2($justinmimbs$date$Date$daysInMonth, y, m)));
			case 'Weeks':
				return $justinmimbs$date$Date$RD(rd + (7 * n));
			default:
				return $justinmimbs$date$Date$RD(rd + n);
		}
	});
var $justinmimbs$time_extra$Time$Extra$add = F4(
	function (interval, n, zone, posix) {
		add:
		while (true) {
			switch (interval.$) {
				case 'Millisecond':
					return $elm$time$Time$millisToPosix(
						$elm$time$Time$posixToMillis(posix) + n);
				case 'Second':
					var $temp$interval = $justinmimbs$time_extra$Time$Extra$Millisecond,
						$temp$n = n * 1000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 'Minute':
					var $temp$interval = $justinmimbs$time_extra$Time$Extra$Millisecond,
						$temp$n = n * 60000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 'Hour':
					var $temp$interval = $justinmimbs$time_extra$Time$Extra$Millisecond,
						$temp$n = n * 3600000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 'Day':
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							$justinmimbs$date$Date$Days,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 'Month':
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							$justinmimbs$date$Date$Months,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 'Year':
					var $temp$interval = $justinmimbs$time_extra$Time$Extra$Month,
						$temp$n = n * 12,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 'Quarter':
					var $temp$interval = $justinmimbs$time_extra$Time$Extra$Month,
						$temp$n = n * 3,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 'Week':
					var $temp$interval = $justinmimbs$time_extra$Time$Extra$Day,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				default:
					var weekday = interval;
					var $temp$interval = $justinmimbs$time_extra$Time$Extra$Day,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
			}
		}
	});
var $justinmimbs$date$Date$Day = {$: 'Day'};
var $justinmimbs$date$Date$Friday = {$: 'Friday'};
var $justinmimbs$date$Date$Monday = {$: 'Monday'};
var $justinmimbs$date$Date$Month = {$: 'Month'};
var $justinmimbs$date$Date$Quarter = {$: 'Quarter'};
var $justinmimbs$date$Date$Saturday = {$: 'Saturday'};
var $justinmimbs$date$Date$Sunday = {$: 'Sunday'};
var $justinmimbs$date$Date$Thursday = {$: 'Thursday'};
var $justinmimbs$date$Date$Tuesday = {$: 'Tuesday'};
var $justinmimbs$date$Date$Wednesday = {$: 'Wednesday'};
var $justinmimbs$date$Date$Week = {$: 'Week'};
var $justinmimbs$date$Date$Year = {$: 'Year'};
var $elm$time$Time$Fri = {$: 'Fri'};
var $elm$time$Time$Mon = {$: 'Mon'};
var $elm$time$Time$Sat = {$: 'Sat'};
var $elm$time$Time$Sun = {$: 'Sun'};
var $elm$time$Time$Thu = {$: 'Thu'};
var $elm$time$Time$Tue = {$: 'Tue'};
var $elm$time$Time$Wed = {$: 'Wed'};
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0.a;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd.$) {
		case 'Mon':
			return 1;
		case 'Tue':
			return 2;
		case 'Wed':
			return 3;
		case 'Thu':
			return 4;
		case 'Fri':
			return 5;
		case 'Sat':
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return $justinmimbs$date$Date$RD(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1);
	});
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$RD(
		$justinmimbs$date$Date$daysBeforeYear(y) + 1);
};
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.month;
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date.a;
		switch (interval.$) {
			case 'Year':
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 'Quarter':
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 'Month':
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 'Week':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Mon, date));
			case 'Monday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Mon, date));
			case 'Tuesday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Tue, date));
			case 'Wednesday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Wed, date));
			case 'Thursday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Thu, date));
			case 'Friday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Fri, date));
			case 'Saturday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Sat, date));
			case 'Sunday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Sun, date));
			default:
				return date;
		}
	});
var $justinmimbs$time_extra$Time$Extra$floorDate = F3(
	function (dateInterval, zone, posix) {
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A2(
				$justinmimbs$date$Date$floor,
				dateInterval,
				A2($justinmimbs$date$Date$fromPosix, zone, posix)),
			0);
	});
var $justinmimbs$time_extra$Time$Extra$floor = F3(
	function (interval, zone, posix) {
		switch (interval.$) {
			case 'Millisecond':
				return posix;
			case 'Second':
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						A2($elm$time$Time$toMinute, zone, posix),
						A2($elm$time$Time$toSecond, zone, posix),
						0));
			case 'Minute':
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						A2($elm$time$Time$toMinute, zone, posix),
						0,
						0));
			case 'Hour':
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						0,
						0,
						0));
			case 'Day':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Day, zone, posix);
			case 'Month':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Month, zone, posix);
			case 'Year':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Year, zone, posix);
			case 'Quarter':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Quarter, zone, posix);
			case 'Week':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Week, zone, posix);
			case 'Monday':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Monday, zone, posix);
			case 'Tuesday':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Tuesday, zone, posix);
			case 'Wednesday':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Wednesday, zone, posix);
			case 'Thursday':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Thursday, zone, posix);
			case 'Friday':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Friday, zone, posix);
			case 'Saturday':
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Saturday, zone, posix);
			default:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, $justinmimbs$date$Date$Sunday, zone, posix);
		}
	});
var $justinmimbs$time_extra$Time$Extra$ceiling = F3(
	function (interval, zone, posix) {
		var floored = A3($justinmimbs$time_extra$Time$Extra$floor, interval, zone, posix);
		return _Utils_eq(floored, posix) ? posix : A4($justinmimbs$time_extra$Time$Extra$add, interval, 1, zone, floored);
	});
var $author$project$UI$Card$cardAttrs = function (_v0) {
	var borderColor = _v0.borderColor;
	var backgroundColor = _v0.backgroundColor;
	var border = function () {
		if (borderColor.$ === 'Just') {
			var color = borderColor.a;
			return $author$project$UI$border(color);
		} else {
			return $author$project$UI$border($author$project$UI$colors.transparent);
		}
	}();
	var background = _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Background$color(backgroundColor)
		]);
	return _Utils_ap(
		_List_fromArray(
			[
				$author$project$UI$shadow,
				$mdgriffith$elm_ui$Element$paddingEach(
				{
					bottom: $author$project$UI$scaled(1),
					left: $author$project$UI$scaled(-1),
					right: $author$project$UI$scaled(-1),
					top: $author$project$UI$scaled(-1)
				})
			]),
		_Utils_ap(border, background));
};
var $author$project$UI$Card$contentCard = F2(
	function (attrs, content) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_0),
				_Utils_ap(
					$author$project$UI$Card$cardAttrs(
						{backgroundColor: $author$project$UI$colors.neutral_0, borderColor: $elm$core$Maybe$Nothing}),
					attrs)),
			content);
	});
var $justinmimbs$time_extra$Time$Extra$toFractionalDay = F2(
	function (zone, posix) {
		return A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix) / 86400000;
	});
var $justinmimbs$time_extra$Time$Extra$toMonths = F2(
	function (zone, posix) {
		var wholeMonths = (12 * (A2($elm$time$Time$toYear, zone, posix) - 1)) + ($justinmimbs$date$Date$monthToNumber(
			A2($elm$time$Time$toMonth, zone, posix)) - 1);
		var fractionalMonth = (A2($elm$time$Time$toDay, zone, posix) + A2($justinmimbs$time_extra$Time$Extra$toFractionalDay, zone, posix)) / 100;
		return wholeMonths + fractionalMonth;
	});
var $justinmimbs$time_extra$Time$Extra$toRataDieMoment = F2(
	function (zone, posix) {
		return $justinmimbs$date$Date$toRataDie(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$toFractionalDay, zone, posix);
	});
var $elm$core$Basics$truncate = _Basics_truncate;
var $justinmimbs$time_extra$Time$Extra$diff = F4(
	function (interval, zone, posix1, posix2) {
		diff:
		while (true) {
			switch (interval.$) {
				case 'Millisecond':
					return $elm$time$Time$posixToMillis(posix2) - $elm$time$Time$posixToMillis(posix1);
				case 'Second':
					return (A4($justinmimbs$time_extra$Time$Extra$diff, $justinmimbs$time_extra$Time$Extra$Millisecond, zone, posix1, posix2) / 1000) | 0;
				case 'Minute':
					return (A4($justinmimbs$time_extra$Time$Extra$diff, $justinmimbs$time_extra$Time$Extra$Millisecond, zone, posix1, posix2) / 60000) | 0;
				case 'Hour':
					return (A4($justinmimbs$time_extra$Time$Extra$diff, $justinmimbs$time_extra$Time$Extra$Millisecond, zone, posix1, posix2) / 3600000) | 0;
				case 'Day':
					return (A2($justinmimbs$time_extra$Time$Extra$toRataDieMoment, zone, posix2) - A2($justinmimbs$time_extra$Time$Extra$toRataDieMoment, zone, posix1)) | 0;
				case 'Month':
					return (A2($justinmimbs$time_extra$Time$Extra$toMonths, zone, posix2) - A2($justinmimbs$time_extra$Time$Extra$toMonths, zone, posix1)) | 0;
				case 'Year':
					return (A4($justinmimbs$time_extra$Time$Extra$diff, $justinmimbs$time_extra$Time$Extra$Month, zone, posix1, posix2) / 12) | 0;
				case 'Quarter':
					return (A4($justinmimbs$time_extra$Time$Extra$diff, $justinmimbs$time_extra$Time$Extra$Month, zone, posix1, posix2) / 3) | 0;
				case 'Week':
					return (A4($justinmimbs$time_extra$Time$Extra$diff, $justinmimbs$time_extra$Time$Extra$Day, zone, posix1, posix2) / 7) | 0;
				default:
					var weekday = interval;
					var $temp$interval = $justinmimbs$time_extra$Time$Extra$Week,
						$temp$zone = zone,
						$temp$posix1 = A3($justinmimbs$time_extra$Time$Extra$floor, weekday, zone, posix1),
						$temp$posix2 = A3($justinmimbs$time_extra$Time$Extra$floor, weekday, zone, posix2);
					interval = $temp$interval;
					zone = $temp$zone;
					posix1 = $temp$posix1;
					posix2 = $temp$posix2;
					continue diff;
			}
		}
	});
var $author$project$UI$Form$Date$getComponents = function (selected) {
	switch (selected.$) {
		case 'Empty':
			return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
		case 'Year':
			var year = selected.a;
			return _Utils_Tuple3(
				$elm$core$Maybe$Just(year),
				$elm$core$Maybe$Nothing,
				$elm$core$Maybe$Nothing);
		case 'Month':
			var year = selected.a;
			var month = selected.b;
			return _Utils_Tuple3(
				$elm$core$Maybe$Just(year),
				$elm$core$Maybe$Just(month),
				$elm$core$Maybe$Nothing);
		default:
			var year = selected.a;
			var month = selected.b;
			var day = selected.c;
			return _Utils_Tuple3(
				$elm$core$Maybe$Just(year),
				$elm$core$Maybe$Just(month),
				$elm$core$Maybe$Just(day));
	}
};
var $author$project$UI$Form$Date$monthName = function (month) {
	switch (month.$) {
		case 'Jan':
			return _Utils_Tuple2('Январь', 1);
		case 'Feb':
			return _Utils_Tuple2('Февраль', 2);
		case 'Mar':
			return _Utils_Tuple2('Март', 3);
		case 'Apr':
			return _Utils_Tuple2('Апрель', 4);
		case 'May':
			return _Utils_Tuple2('Май', 5);
		case 'Jun':
			return _Utils_Tuple2('Июнь', 6);
		case 'Jul':
			return _Utils_Tuple2('Июль', 7);
		case 'Aug':
			return _Utils_Tuple2('Август', 8);
		case 'Sep':
			return _Utils_Tuple2('Сентябрь', 9);
		case 'Oct':
			return _Utils_Tuple2('Октябрь', 10);
		case 'Nov':
			return _Utils_Tuple2('Ноябрь', 11);
		default:
			return _Utils_Tuple2('Декабрь', 12);
	}
};
var $mdgriffith$elm_ui$Internal$Model$MoveX = function (a) {
	return {$: 'MoveX', a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$moveX = $mdgriffith$elm_ui$Internal$Flag$flag(25);
var $mdgriffith$elm_ui$Element$moveLeft = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveX,
		$mdgriffith$elm_ui$Internal$Model$MoveX(-x));
};
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $justinmimbs$time_extra$Time$Extra$posixToParts = F2(
	function (zone, posix) {
		return {
			day: A2($elm$time$Time$toDay, zone, posix),
			hour: A2($elm$time$Time$toHour, zone, posix),
			millisecond: A2($elm$time$Time$toMillis, zone, posix),
			minute: A2($elm$time$Time$toMinute, zone, posix),
			month: A2($elm$time$Time$toMonth, zone, posix),
			second: A2($elm$time$Time$toSecond, zone, posix),
			year: A2($elm$time$Time$toYear, zone, posix)
		};
	});
var $author$project$UI$preventSelect = $mdgriffith$elm_ui$Element$htmlAttribute(
	$elm$html$Html$Attributes$class('prevent-select'));
var $justinmimbs$time_extra$Time$Extra$rangeHelp = F6(
	function (interval, step, zone, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(
				$elm$time$Time$posixToMillis(current),
				$elm$time$Time$posixToMillis(until)) < 0) {
				var $temp$interval = interval,
					$temp$step = step,
					$temp$zone = zone,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = A4($justinmimbs$time_extra$Time$Extra$add, interval, step, zone, current);
				interval = $temp$interval;
				step = $temp$step;
				zone = $temp$zone;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$time_extra$Time$Extra$range = F5(
	function (interval, step, zone, start, until) {
		return A6(
			$justinmimbs$time_extra$Time$Extra$rangeHelp,
			interval,
			A2($elm$core$Basics$max, 1, step),
			zone,
			until,
			_List_Nil,
			A3($justinmimbs$time_extra$Time$Extra$ceiling, interval, zone, start));
	});
var $mdgriffith$elm_ui$Element$Font$regular = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.textNormalWeight);
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $mdgriffith$elm_ui$Element$toRgb = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	return {alpha: a, blue: b, green: g, red: r};
};
var $author$project$UI$colorToHex = function (color) {
	var rgba = $mdgriffith$elm_ui$Element$toRgb(color);
	var hexDigits = $elm$core$Dict$fromList(
		A3(
			$elm$core$List$map2,
			$elm$core$Tuple$pair,
			A2($elm$core$List$range, 0, 15),
			A2($elm$core$String$split, '', '0123456789abcdef')));
	var intToHex = function (num) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				function (x) {
					return A2(
						$elm$core$Maybe$withDefault,
						'',
						A2($elm$core$Dict$get, x, hexDigits));
				},
				_List_fromArray(
					[
						(num / 16) | 0,
						A2($elm$core$Basics$modBy, 16, num)
					])));
	};
	return $elm$core$String$concat(
		A2(
			$elm$core$List$cons,
			'#',
			A2(
				$elm$core$List$map,
				function (x) {
					return intToHex(
						$elm$core$Basics$round(x * 255));
				},
				_List_fromArray(
					[rgba.red, rgba.green, rgba.blue, rgba.alpha]))));
};
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Ease = function (a) {
	return {$: 'Ease', a: a};
};
var $andrewMacmurray$elm_simple_animation$Internal$Ease$Linear = {$: 'Linear'};
var $andrewMacmurray$elm_simple_animation$Simple$Animation$linear = $andrewMacmurray$elm_simple_animation$Internal$Animation$Ease($andrewMacmurray$elm_simple_animation$Internal$Ease$Linear);
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Iteration = function (a) {
	return {$: 'Iteration', a: a};
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Loop = {$: 'Loop'};
var $andrewMacmurray$elm_simple_animation$Simple$Animation$loop = $andrewMacmurray$elm_simple_animation$Internal$Animation$Iteration($andrewMacmurray$elm_simple_animation$Internal$Animation$Loop);
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$Transform = function (a) {
	return {$: 'Transform', a: a};
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$Rotate = function (a) {
	return {$: 'Rotate', a: a};
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$rotate = $andrewMacmurray$elm_simple_animation$Internal$Transform$Rotate;
var $andrewMacmurray$elm_simple_animation$Simple$Animation$Property$rotate = A2($elm$core$Basics$composeL, $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$Transform, $andrewMacmurray$elm_simple_animation$Internal$Transform$rotate);
var $andrewMacmurray$elm_simple_animation$Simple$Animation$Step = F2(
	function (a, b) {
		return {$: 'Step', a: a, b: b};
	});
var $andrewMacmurray$elm_simple_animation$Simple$Animation$step = $andrewMacmurray$elm_simple_animation$Simple$Animation$Step;
var $andrewMacmurray$elm_simple_animation$Simple$Animation$Stepped = function (a) {
	return {$: 'Stepped', a: a};
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Animation = F3(
	function (a, b, c) {
		return {$: 'Animation', a: a, b: b, c: c};
	});
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Frame = F2(
	function (a, b) {
		return {$: 'Frame', a: a, b: b};
	});
var $andrewMacmurray$elm_simple_animation$Internal$Animation$duration_ = function (_v0) {
	var d = _v0.a;
	return d;
};
var $andrewMacmurray$elm_simple_animation$Simple$Animation$duration = $andrewMacmurray$elm_simple_animation$Internal$Animation$duration_;
var $andrewMacmurray$elm_simple_animation$Simple$Animation$adjustCompleteWait = F2(
	function (anim, timePassed) {
		var duration_ = $andrewMacmurray$elm_simple_animation$Simple$Animation$duration(anim);
		return ((duration_ - timePassed) >= 1) ? duration_ : (timePassed + 1);
	});
var $andrewMacmurray$elm_simple_animation$Simple$Animation$frameProps = function (_v0) {
	var props = _v0.b;
	return props;
};
var $andrewMacmurray$elm_simple_animation$Simple$Animation$accumDuration = F2(
	function (step_, curr) {
		switch (step_.$) {
			case 'Step':
				var d = step_.a;
				return d + curr;
			case 'Wait':
				var d = step_.a;
				return d + curr;
			default:
				var anim = step_.a;
				return A2($andrewMacmurray$elm_simple_animation$Simple$Animation$adjustCompleteWait, anim, curr);
		}
	});
var $andrewMacmurray$elm_simple_animation$Simple$Animation$totalDuration = A2($elm$core$List$foldl, $andrewMacmurray$elm_simple_animation$Simple$Animation$accumDuration, 0);
var $andrewMacmurray$elm_simple_animation$Simple$Animation$toFrames = F2(
	function (firstFrame, steps_) {
		var percentPerMs = 100 / $andrewMacmurray$elm_simple_animation$Simple$Animation$totalDuration(steps_);
		var getFrame = F2(
			function (f, _v2) {
				var n = _v2.a;
				var xs = _v2.b;
				var cur = _v2.c;
				switch (f.$) {
					case 'Step':
						var d = f.a;
						var props = f.b;
						return _Utils_Tuple3(
							n + d,
							_Utils_ap(
								xs,
								_List_fromArray(
									[cur])),
							A2($andrewMacmurray$elm_simple_animation$Internal$Animation$Frame, percentPerMs * (n + d), props));
					case 'Wait':
						var d = f.a;
						return _Utils_Tuple3(
							n + d,
							_Utils_ap(
								xs,
								_List_fromArray(
									[cur])),
							A2(
								$andrewMacmurray$elm_simple_animation$Internal$Animation$Frame,
								percentPerMs * (n + d),
								$andrewMacmurray$elm_simple_animation$Simple$Animation$frameProps(cur)));
					default:
						var d = f.a;
						var dur = A2($andrewMacmurray$elm_simple_animation$Simple$Animation$adjustCompleteWait, d, n);
						return _Utils_Tuple3(
							dur,
							_Utils_ap(
								xs,
								_List_fromArray(
									[cur])),
							A2(
								$andrewMacmurray$elm_simple_animation$Internal$Animation$Frame,
								percentPerMs * dur,
								$andrewMacmurray$elm_simple_animation$Simple$Animation$frameProps(cur)));
				}
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			getFrame,
			_Utils_Tuple3(
				0,
				_List_Nil,
				A2($andrewMacmurray$elm_simple_animation$Internal$Animation$Frame, 0, firstFrame)),
			steps_);
		var frames_ = _v0.b;
		var currentFrame = _v0.c;
		return _Utils_ap(
			frames_,
			_List_fromArray(
				[currentFrame]));
	});
var $andrewMacmurray$elm_simple_animation$Simple$Animation$toAnimation = function (_v0) {
	var s = _v0.a;
	return A3(
		$andrewMacmurray$elm_simple_animation$Internal$Animation$Animation,
		$andrewMacmurray$elm_simple_animation$Simple$Animation$totalDuration(s.steps),
		s.options,
		A2($andrewMacmurray$elm_simple_animation$Simple$Animation$toFrames, s.startAt, s.steps));
};
var $andrewMacmurray$elm_simple_animation$Simple$Animation$steps = F2(
	function (_v0, steps_) {
		var options = _v0.options;
		var startAt = _v0.startAt;
		return $andrewMacmurray$elm_simple_animation$Simple$Animation$toAnimation(
			$andrewMacmurray$elm_simple_animation$Simple$Animation$Stepped(
				{options: options, startAt: startAt, steps: steps_}));
	});
var $author$project$UI$Animations$spinning = A2(
	$andrewMacmurray$elm_simple_animation$Simple$Animation$steps,
	{
		options: _List_fromArray(
			[$andrewMacmurray$elm_simple_animation$Simple$Animation$loop, $andrewMacmurray$elm_simple_animation$Simple$Animation$linear]),
		startAt: _List_fromArray(
			[
				$andrewMacmurray$elm_simple_animation$Simple$Animation$Property$rotate(0)
			])
	},
	_List_fromArray(
		[
			A2(
			$andrewMacmurray$elm_simple_animation$Simple$Animation$step,
			1500,
			_List_fromArray(
				[
					$andrewMacmurray$elm_simple_animation$Simple$Animation$Property$rotate(360)
				]))
		]));
var $andrewMacmurray$elm_simple_animation$Internal$Animation$joinWith = function (f) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(f),
		$elm$core$String$concat);
};
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$escapedChars_ = $elm$core$Set$fromList(
	_List_fromArray(
		[
			_Utils_chr('.'),
			_Utils_chr(' '),
			_Utils_chr(','),
			_Utils_chr('#'),
			_Utils_chr('$'),
			_Utils_chr('%'),
			_Utils_chr('('),
			_Utils_chr(')'),
			_Utils_chr('&'),
			_Utils_chr(';'),
			_Utils_chr(':'),
			_Utils_chr('\"'),
			_Utils_chr('\''),
			_Utils_chr('*'),
			_Utils_chr('~'),
			_Utils_chr('!'),
			_Utils_chr('@'),
			_Utils_chr('^'),
			_Utils_chr('+'),
			_Utils_chr('='),
			_Utils_chr('/'),
			_Utils_chr('?'),
			_Utils_chr('>'),
			_Utils_chr('<'),
			_Utils_chr('['),
			_Utils_chr(']'),
			_Utils_chr('{'),
			_Utils_chr('}'),
			_Utils_chr('|'),
			_Utils_chr('`')
		]));
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$escapedChars = function (c) {
	return !A2($elm$core$Set$member, c, $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$escapedChars_);
};
var $elm$core$String$filter = _String_filter;
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$escape = $elm$core$String$filter($andrewMacmurray$elm_simple_animation$Internal$Animation$Property$escapedChars);
var $andrewMacmurray$elm_simple_animation$Internal$Transform$rounded = F2(
	function (n, val) {
		return $elm$core$String$fromInt(
			$elm$core$Basics$round(val) * n);
	});
var $andrewMacmurray$elm_simple_animation$Internal$Transform$name = function (t) {
	switch (t.$) {
		case 'Translate':
			switch (t.a.$) {
				case 'Y':
					var y_ = t.a.a;
					return 'y' + A2($andrewMacmurray$elm_simple_animation$Internal$Transform$rounded, 1, y_);
				case 'X':
					var x_ = t.a.a;
					return 'x' + A2($andrewMacmurray$elm_simple_animation$Internal$Transform$rounded, 1, x_);
				default:
					var _v1 = t.a;
					var x_ = _v1.a;
					var y_ = _v1.b;
					return 'x' + (A2($andrewMacmurray$elm_simple_animation$Internal$Transform$rounded, 1, x_) + ('y' + A2($andrewMacmurray$elm_simple_animation$Internal$Transform$rounded, 1, y_)));
			}
		case 'Rotate':
			var r_ = t.a;
			return 'r' + A2($andrewMacmurray$elm_simple_animation$Internal$Transform$rounded, 1, r_);
		default:
			var x_ = t.a;
			var y_ = t.b;
			return 'sx' + (A2($andrewMacmurray$elm_simple_animation$Internal$Transform$rounded, 100, x_) + ('sy' + A2($andrewMacmurray$elm_simple_animation$Internal$Transform$rounded, 100, y_)));
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$rounded = F2(
	function (n, val) {
		return $elm$core$String$fromInt(
			$elm$core$Basics$round(val * n));
	});
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$name = function (prop) {
	switch (prop.$) {
		case 'Opacity':
			var n = prop.a;
			return 'o' + A2($andrewMacmurray$elm_simple_animation$Internal$Animation$Property$rounded, 100, n);
		case 'Transform':
			var t = prop.a;
			return $andrewMacmurray$elm_simple_animation$Internal$Transform$name(t);
		default:
			var n = prop.a;
			var p = prop.b;
			return _Utils_ap(
				$andrewMacmurray$elm_simple_animation$Internal$Animation$Property$escape(n),
				$andrewMacmurray$elm_simple_animation$Internal$Animation$Property$escape(p));
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$frameName = function (_v0) {
	var dur = _v0.a;
	var props = _v0.b;
	return 'f' + ($elm$core$String$fromInt(
		$elm$core$Basics$round(dur)) + A2($andrewMacmurray$elm_simple_animation$Internal$Animation$joinWith, $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$name, props));
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$framesNames = $andrewMacmurray$elm_simple_animation$Internal$Animation$joinWith($andrewMacmurray$elm_simple_animation$Internal$Animation$frameName);
var $andrewMacmurray$elm_simple_animation$Internal$Animation$frames_ = function (_v0) {
	var f = _v0.c;
	return f;
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$isEmpty = function (anim) {
	return !$andrewMacmurray$elm_simple_animation$Internal$Animation$duration_(anim);
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$iterationName = function (i) {
	if (i.$ === 'Loop') {
		return 'infinite';
	} else {
		var count = i.a;
		return 'count-' + $elm$core$String$fromInt(count);
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Ease$toString = function (e) {
	switch (e.$) {
		case 'Cubic':
			var a = e.a;
			var b = e.b;
			var c = e.c;
			var d = e.d;
			return 'cubic-bezier(' + (A2(
				$elm$core$String$join,
				',',
				_List_fromArray(
					[
						$elm$core$String$fromFloat(a),
						$elm$core$String$fromFloat(b),
						$elm$core$String$fromFloat(c),
						$elm$core$String$fromFloat(d)
					])) + ')');
		case 'Linear':
			return 'linear';
		case 'Ease':
			return 'ease';
		case 'EaseIn':
			return 'ease-in';
		case 'EaseOut':
			return 'ease-out';
		default:
			return 'ease-in-out';
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$optionName = function (o) {
	switch (o.$) {
		case 'Delay':
			var n = o.a;
			return 'd' + $elm$core$String$fromInt(n);
		case 'Ease':
			var ease = o.a;
			return $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$escape(
				$andrewMacmurray$elm_simple_animation$Internal$Ease$toString(ease));
		case 'Iteration':
			var i = o.a;
			return $andrewMacmurray$elm_simple_animation$Internal$Animation$iterationName(i);
		case 'Yoyo':
			return 'yoyo';
		default:
			return 'rev';
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$optionNames = $andrewMacmurray$elm_simple_animation$Internal$Animation$joinWith($andrewMacmurray$elm_simple_animation$Internal$Animation$optionName);
var $andrewMacmurray$elm_simple_animation$Internal$Animation$rawOptions_ = function (_v0) {
	var o = _v0.b;
	return o;
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$name_ = function (anim) {
	return $andrewMacmurray$elm_simple_animation$Internal$Animation$isEmpty(anim) ? 'anim-empty' : ('anim-' + ($elm$core$String$fromInt(
		$andrewMacmurray$elm_simple_animation$Internal$Animation$duration_(anim)) + ($andrewMacmurray$elm_simple_animation$Internal$Animation$optionNames(
		$andrewMacmurray$elm_simple_animation$Internal$Animation$rawOptions_(anim)) + $andrewMacmurray$elm_simple_animation$Internal$Animation$framesNames(
		$andrewMacmurray$elm_simple_animation$Internal$Animation$frames_(anim)))));
};
var $andrewMacmurray$elm_simple_animation$Internal$Unit$ms = function (n) {
	return $elm$core$String$fromInt(n) + 'ms';
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$animationDuration = function (anim) {
	return $andrewMacmurray$elm_simple_animation$Internal$Unit$ms(
		$andrewMacmurray$elm_simple_animation$Internal$Animation$duration_(anim));
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$collectOption = F2(
	function (o, opts) {
		switch (o.$) {
			case 'Delay':
				var ms = o.a;
				return _Utils_update(
					opts,
					{
						delay: $elm$core$Maybe$Just(ms)
					});
			case 'Iteration':
				var i = o.a;
				return _Utils_update(
					opts,
					{
						iteration: $elm$core$Maybe$Just(i)
					});
			case 'Ease':
				var e = o.a;
				return _Utils_update(
					opts,
					{
						timingFunction: $elm$core$Maybe$Just(e)
					});
			case 'Yoyo':
				return _Utils_update(
					opts,
					{isYoyo: true});
			default:
				return _Utils_update(
					opts,
					{reversed: true});
		}
	});
var $andrewMacmurray$elm_simple_animation$Internal$Animation$defaults = {delay: $elm$core$Maybe$Nothing, isYoyo: false, iteration: $elm$core$Maybe$Nothing, reversed: false, timingFunction: $elm$core$Maybe$Nothing};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Count = function (a) {
	return {$: 'Count', a: a};
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$iterationForYoyo = function (opts) {
	var _v0 = opts.iteration;
	if (_v0.$ === 'Just') {
		if (_v0.a.$ === 'Loop') {
			var _v1 = _v0.a;
			return $andrewMacmurray$elm_simple_animation$Internal$Animation$Loop;
		} else {
			var n = _v0.a.a;
			return $andrewMacmurray$elm_simple_animation$Internal$Animation$Count(n * 2);
		}
	} else {
		return $andrewMacmurray$elm_simple_animation$Internal$Animation$Count(2);
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$normalise = function (opts) {
	return opts.isYoyo ? _Utils_update(
		opts,
		{
			iteration: $elm$core$Maybe$Just(
				$andrewMacmurray$elm_simple_animation$Internal$Animation$iterationForYoyo(opts))
		}) : opts;
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$options_ = A2(
	$elm$core$Basics$composeR,
	$andrewMacmurray$elm_simple_animation$Internal$Animation$rawOptions_,
	A2(
		$elm$core$Basics$composeR,
		A2($elm$core$List$foldl, $andrewMacmurray$elm_simple_animation$Internal$Animation$collectOption, $andrewMacmurray$elm_simple_animation$Internal$Animation$defaults),
		$andrewMacmurray$elm_simple_animation$Internal$Animation$normalise));
var $andrewMacmurray$elm_simple_animation$Internal$Animation$renderDirection = function (d) {
	switch (d.$) {
		case 'Alternate_':
			return 'alternate';
		case 'Reverse_':
			return 'reverse';
		default:
			return 'alternate-reverse';
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$renderIteration = function (i) {
	if (i.$ === 'Loop') {
		return 'infinite';
	} else {
		var count = i.a;
		return $elm$core$String$fromInt(count);
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$renderOption = F2(
	function (name, toProp) {
		return $elm$core$Maybe$map(
			function (x) {
				return name + (': ' + toProp(x));
			});
	});
var $andrewMacmurray$elm_simple_animation$Internal$Animation$AlternateReverse_ = {$: 'AlternateReverse_'};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Alternate_ = {$: 'Alternate_'};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Reverse_ = {$: 'Reverse_'};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$toDirection = function (opts) {
	return (opts.isYoyo && opts.reversed) ? $elm$core$Maybe$Just($andrewMacmurray$elm_simple_animation$Internal$Animation$AlternateReverse_) : (opts.reversed ? $elm$core$Maybe$Just($andrewMacmurray$elm_simple_animation$Internal$Animation$Reverse_) : (opts.isYoyo ? $elm$core$Maybe$Just($andrewMacmurray$elm_simple_animation$Internal$Animation$Alternate_) : $elm$core$Maybe$Nothing));
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$renderOptions_ = function (opts) {
	return _List_fromArray(
		[
			A3($andrewMacmurray$elm_simple_animation$Internal$Animation$renderOption, 'animation-delay', $andrewMacmurray$elm_simple_animation$Internal$Unit$ms, opts.delay),
			A3($andrewMacmurray$elm_simple_animation$Internal$Animation$renderOption, 'animation-timing-function', $andrewMacmurray$elm_simple_animation$Internal$Ease$toString, opts.timingFunction),
			A3($andrewMacmurray$elm_simple_animation$Internal$Animation$renderOption, 'animation-iteration-count', $andrewMacmurray$elm_simple_animation$Internal$Animation$renderIteration, opts.iteration),
			A3(
			$andrewMacmurray$elm_simple_animation$Internal$Animation$renderOption,
			'animation-direction',
			$andrewMacmurray$elm_simple_animation$Internal$Animation$renderDirection,
			$andrewMacmurray$elm_simple_animation$Internal$Animation$toDirection(opts))
		]);
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$renderOptions = A2(
	$elm$core$Basics$composeR,
	$andrewMacmurray$elm_simple_animation$Internal$Animation$options_,
	A2(
		$elm$core$Basics$composeR,
		$andrewMacmurray$elm_simple_animation$Internal$Animation$renderOptions_,
		$elm$core$List$filterMap($elm$core$Basics$identity)));
var $andrewMacmurray$elm_simple_animation$Internal$Animation$classProperties = function (anim) {
	return A2(
		$elm$core$String$join,
		';\n',
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					'animation-name: ' + $andrewMacmurray$elm_simple_animation$Internal$Animation$name_(anim),
					'animation-duration: ' + $andrewMacmurray$elm_simple_animation$Internal$Animation$animationDuration(anim),
					'animation-fill-mode: both'
				]),
			$andrewMacmurray$elm_simple_animation$Internal$Animation$renderOptions(anim)));
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$classDefinition_ = function (anim) {
	return '.' + ($andrewMacmurray$elm_simple_animation$Internal$Animation$name_(anim) + ('{\n' + ($andrewMacmurray$elm_simple_animation$Internal$Animation$classProperties(anim) + '\n};')));
};
var $andrewMacmurray$elm_simple_animation$Internal$Unit$pc = function (n) {
	return $elm$core$String$fromFloat(n) + '%';
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$filterMaybes = $elm$core$List$filterMap($elm$core$Basics$identity);
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$getProp = function (f) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$filterMap(f),
		$elm$core$List$head);
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$opacity_ = function (p) {
	if (p.$ === 'Opacity') {
		var n = p.a;
		return $elm$core$Maybe$Just(
			'opacity:' + $elm$core$String$fromFloat(n));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$raw_ = function (p) {
	if (p.$ === 'Raw') {
		var k = p.a;
		var v = p.b;
		return $elm$core$Maybe$Just(k + (':' + v));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$collectTransforms = A2(
	$elm$core$List$foldl,
	F2(
		function (val, acc) {
			if (val.$ === 'Transform') {
				var t = val.a;
				return A2($elm$core$List$cons, t, acc);
			} else {
				return acc;
			}
		}),
	_List_Nil);
var $andrewMacmurray$elm_simple_animation$Internal$Transform$combine = F2(
	function (transform, combined) {
		switch (transform.$) {
			case 'Rotate':
				var n = transform.a;
				return _Utils_update(
					combined,
					{
						rotate: $elm$core$Maybe$Just(n)
					});
			case 'ScaleXY':
				var x_ = transform.a;
				var y_ = transform.b;
				return _Utils_update(
					combined,
					{
						scale: $elm$core$Maybe$Just(
							_Utils_Tuple2(x_, y_))
					});
			default:
				switch (transform.a.$) {
					case 'XY':
						var _v1 = transform.a;
						var x_ = _v1.a;
						var y_ = _v1.b;
						return _Utils_update(
							combined,
							{
								xy: $elm$core$Maybe$Just(
									_Utils_Tuple2(x_, y_))
							});
					case 'X':
						var n = transform.a.a;
						return _Utils_update(
							combined,
							{
								x: $elm$core$Maybe$Just(n)
							});
					default:
						var n = transform.a.a;
						return _Utils_update(
							combined,
							{
								y: $elm$core$Maybe$Just(n)
							});
				}
		}
	});
var $andrewMacmurray$elm_simple_animation$Internal$Transform$empty = {rotate: $elm$core$Maybe$Nothing, scale: $elm$core$Maybe$Nothing, x: $elm$core$Maybe$Nothing, xy: $elm$core$Maybe$Nothing, y: $elm$core$Maybe$Nothing};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$render_ = function (f) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(f),
		$elm$core$Maybe$withDefault(''));
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$deg = function (n) {
	return (!n) ? '0' : ($elm$core$String$fromFloat(n) + 'deg');
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$rotate_ = function (n) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'rotate3d(0,0,1,',
				$andrewMacmurray$elm_simple_animation$Internal$Transform$deg(n),
				')'
			]));
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$scale_ = function (_v0) {
	var x_ = _v0.a;
	var y_ = _v0.b;
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'scale3d(',
				$elm$core$String$fromFloat(x_),
				',',
				$elm$core$String$fromFloat(y_),
				',1)'
			]));
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$px = function (n) {
	return (!n) ? '0' : ($elm$core$String$fromFloat(n) + 'px');
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$translateXY_ = F2(
	function (x_, y_) {
		return $elm$core$String$concat(
			_List_fromArray(
				[
					'translate3d(',
					$andrewMacmurray$elm_simple_animation$Internal$Transform$px(x_),
					',',
					$andrewMacmurray$elm_simple_animation$Internal$Transform$px(y_),
					',0)'
				]));
	});
var $andrewMacmurray$elm_simple_animation$Internal$Transform$translateX_ = function (n) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'translate3d(',
				$andrewMacmurray$elm_simple_animation$Internal$Transform$px(n),
				',0,0)'
			]));
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$translateY_ = function (n) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'translate3d(0,',
				$andrewMacmurray$elm_simple_animation$Internal$Transform$px(n),
				',0)'
			]));
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$translate_ = function (combined) {
	var _v0 = _Utils_Tuple3(combined.xy, combined.x, combined.y);
	if (_v0.a.$ === 'Just') {
		var _v1 = _v0.a.a;
		var x_ = _v1.a;
		var y_ = _v1.b;
		return A2($andrewMacmurray$elm_simple_animation$Internal$Transform$translateXY_, x_, y_);
	} else {
		if (_v0.b.$ === 'Just') {
			if (_v0.c.$ === 'Nothing') {
				var _v2 = _v0.a;
				var x_ = _v0.b.a;
				var _v3 = _v0.c;
				return $andrewMacmurray$elm_simple_animation$Internal$Transform$translateX_(x_);
			} else {
				var _v6 = _v0.a;
				var x_ = _v0.b.a;
				var y_ = _v0.c.a;
				return A2($andrewMacmurray$elm_simple_animation$Internal$Transform$translateXY_, x_, y_);
			}
		} else {
			if (_v0.c.$ === 'Just') {
				var _v4 = _v0.a;
				var _v5 = _v0.b;
				var y_ = _v0.c.a;
				return $andrewMacmurray$elm_simple_animation$Internal$Transform$translateY_(y_);
			} else {
				var _v7 = _v0.a;
				var _v8 = _v0.b;
				var _v9 = _v0.c;
				return '';
			}
		}
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$render = function (combined) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
			_List_fromArray(
				[
					$andrewMacmurray$elm_simple_animation$Internal$Transform$translate_(combined),
					A2($andrewMacmurray$elm_simple_animation$Internal$Transform$render_, $andrewMacmurray$elm_simple_animation$Internal$Transform$scale_, combined.scale),
					A2($andrewMacmurray$elm_simple_animation$Internal$Transform$render_, $andrewMacmurray$elm_simple_animation$Internal$Transform$rotate_, combined.rotate)
				])));
};
var $andrewMacmurray$elm_simple_animation$Internal$Transform$toString = A2(
	$elm$core$Basics$composeR,
	A2($elm$core$List$foldl, $andrewMacmurray$elm_simple_animation$Internal$Transform$combine, $andrewMacmurray$elm_simple_animation$Internal$Transform$empty),
	$andrewMacmurray$elm_simple_animation$Internal$Transform$render);
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$transform_ = function (props) {
	var _v0 = $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$collectTransforms(props);
	if (!_v0.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var transforms = _v0;
		return $elm$core$Maybe$Just(
			'transform:' + $andrewMacmurray$elm_simple_animation$Internal$Transform$toString(transforms));
	}
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$render = function (props) {
	return A2(
		$elm$core$String$join,
		';',
		$andrewMacmurray$elm_simple_animation$Internal$Animation$Property$filterMaybes(
			$elm$core$List$concat(
				_List_fromArray(
					[
						A2($elm$core$List$map, $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$raw_, props),
						_List_fromArray(
						[
							A2($andrewMacmurray$elm_simple_animation$Internal$Animation$Property$getProp, $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$opacity_, props),
							$andrewMacmurray$elm_simple_animation$Internal$Animation$Property$transform_(props)
						])
					]))));
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$renderFrame = function (_v0) {
	var percent = _v0.a;
	var properties = _v0.b;
	return $andrewMacmurray$elm_simple_animation$Internal$Unit$pc(percent) + ('{' + ($andrewMacmurray$elm_simple_animation$Internal$Animation$Property$render(properties) + ';}'));
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$keyframes_ = A2(
	$elm$core$Basics$composeR,
	$andrewMacmurray$elm_simple_animation$Internal$Animation$frames_,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map($andrewMacmurray$elm_simple_animation$Internal$Animation$renderFrame),
		$elm$core$String$join('\n')));
var $andrewMacmurray$elm_simple_animation$Internal$Animation$keyframesAnimation_ = function (anim) {
	return '@keyframes ' + ($andrewMacmurray$elm_simple_animation$Internal$Animation$name_(anim) + ('{' + ($andrewMacmurray$elm_simple_animation$Internal$Animation$keyframes_(anim) + '}')));
};
var $andrewMacmurray$elm_simple_animation$Internal$Animation$stylesheet_ = function (anim) {
	return $andrewMacmurray$elm_simple_animation$Internal$Animation$keyframesAnimation_(anim) + ('\n' + $andrewMacmurray$elm_simple_animation$Internal$Animation$classDefinition_(anim));
};
var $andrewMacmurray$elm_simple_animation$Simple$Animation$Animated$toStylesheet_ = function (anim) {
	return A3(
		$elm$html$Html$node,
		'style',
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				$andrewMacmurray$elm_simple_animation$Internal$Animation$stylesheet_(anim))
			]));
};
var $andrewMacmurray$elm_simple_animation$Simple$Animation$Animated$node = F5(
	function (options, node_, anim, attrs, els) {
		return A2(
			node_,
			A2(
				$elm$core$List$cons,
				options._class(
					$andrewMacmurray$elm_simple_animation$Internal$Animation$name_(anim)),
				attrs),
			A2(
				$elm$core$List$cons,
				$andrewMacmurray$elm_simple_animation$Simple$Animation$Animated$toStylesheet_(anim),
				els));
	});
var $andrewMacmurray$elm_simple_animation$Simple$Animation$Animated$svg = $andrewMacmurray$elm_simple_animation$Simple$Animation$Animated$node;
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$svg$Svg$use = $elm$svg$Svg$trustedNode('use');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$xlinkHref = function (value) {
	return A3(
		_VirtualDom_attributeNS,
		'http://www.w3.org/1999/xlink',
		'xlink:href',
		_VirtualDom_noJavaScriptUri(value));
};
var $author$project$UI$Loading$spinner = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
		]),
	A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 200, $mdgriffith$elm_ui$Element$fill)),
				$mdgriffith$elm_ui$Element$height(
				A2($mdgriffith$elm_ui$Element$maximum, 200, $mdgriffith$elm_ui$Element$fill))
			]),
		$mdgriffith$elm_ui$Element$html(
			A2(
				$elm$svg$Svg$svg,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$viewBox('-80 -80 160 160')
					]),
				_List_fromArray(
					[
						A5(
						$andrewMacmurray$elm_simple_animation$Simple$Animation$Animated$svg,
						{_class: $elm$svg$Svg$Attributes$class},
						$elm$svg$Svg$g,
						$author$project$UI$Animations$spinning,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$svg$Svg$g,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$id('circle')
									]),
								_List_fromArray(
									[
										A2(
										$elm$svg$Svg$circle,
										_List_fromArray(
											[
												$elm$svg$Svg$Attributes$x('20'),
												$elm$svg$Svg$Attributes$cx('50'),
												$elm$svg$Svg$Attributes$r('30'),
												$elm$svg$Svg$Attributes$fill(
												$author$project$UI$colorToHex($author$project$UI$colors.primary))
											]),
										_List_Nil)
									])),
								A2(
								$elm$svg$Svg$use,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$xlinkHref('#circle'),
										$elm$svg$Svg$Attributes$transform('rotate(120)')
									]),
								_List_Nil),
								A2(
								$elm$svg$Svg$use,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$xlinkHref('#circle'),
										$elm$svg$Svg$Attributes$transform('rotate(-120)')
									]),
								_List_Nil)
							]))
					])))));
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return $elm$time$Time$Thu;
			case 1:
				return $elm$time$Time$Fri;
			case 2:
				return $elm$time$Time$Sat;
			case 3:
				return $elm$time$Time$Sun;
			case 4:
				return $elm$time$Time$Mon;
			case 5:
				return $elm$time$Time$Tue;
			default:
				return $elm$time$Time$Wed;
		}
	});
var $author$project$Domain$zone = A2($elm$time$Time$customZone, 9 * 60, _List_Nil);
var $author$project$UI$Form$Date$render = function (_v0) {
	var specs = _v0.a;
	var weeks = A2(
		$elm$core$Maybe$withDefault,
		_List_fromArray(
			[_List_Nil, _List_Nil, _List_Nil, _List_Nil, _List_Nil]),
		A2(
			$elm$core$Maybe$map,
			function (start) {
				var addWeeks = F2(
					function (to, x) {
						return A4($justinmimbs$time_extra$Time$Extra$add, $justinmimbs$time_extra$Time$Extra$Week, x, $author$project$Domain$zone, to);
					});
				return A2(
					$elm$core$List$map,
					function (x) {
						return A5(
							$justinmimbs$time_extra$Time$Extra$range,
							$justinmimbs$time_extra$Time$Extra$Day,
							1,
							$author$project$Domain$zone,
							A2(addWeeks, start, x),
							A2(addWeeks, start, x + 1));
					},
					_List_fromArray(
						[0, 1, 2, 3, 4]));
			},
			A2(
				$elm$core$Maybe$map,
				A2($justinmimbs$time_extra$Time$Extra$floor, $justinmimbs$time_extra$Time$Extra$Week, $author$project$Domain$zone),
				A2(
					$elm$core$Maybe$map,
					$justinmimbs$time_extra$Time$Extra$partsToPosix($author$project$Domain$zone),
					function () {
						var _v23 = specs.displayed;
						switch (_v23.$) {
							case 'Month':
								var year = _v23.a;
								var month = _v23.b;
								return $elm$core$Maybe$Just(
									A7($justinmimbs$time_extra$Time$Extra$Parts, year, month, 1, 0, 0, 0, 0));
							case 'Day':
								var year = _v23.a;
								var month = _v23.b;
								return $elm$core$Maybe$Just(
									A7($justinmimbs$time_extra$Time$Extra$Parts, year, month, 1, 0, 0, 0, 0));
							default:
								return $elm$core$Maybe$Nothing;
						}
					}()))));
	var stylizeDay = F2(
		function (day, action) {
			var todayDay = A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.day;
				},
				A2(
					$elm$core$Maybe$map,
					$justinmimbs$time_extra$Time$Extra$posixToParts($author$project$Domain$zone),
					specs.today));
			var selectedMonth = function () {
				var _v22 = specs.displayed;
				switch (_v22.$) {
					case 'Day':
						var month = _v22.b;
						return $elm$core$Maybe$Just(month);
					case 'Month':
						var month = _v22.b;
						return $elm$core$Maybe$Just(month);
					default:
						return $elm$core$Maybe$Nothing;
				}
			}();
			var parts = A2($justinmimbs$time_extra$Time$Extra$posixToParts, $author$project$Domain$zone, day);
			var fontColor = function () {
				if (action.$ === 'Nothing') {
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_20)
						]);
				} else {
					if (_Utils_eq(
						$elm$core$Maybe$Just(parts.month),
						selectedMonth)) {
						if (_Utils_eq(
							_Utils_Tuple3(
								$elm$core$Maybe$Just(parts.year),
								$elm$core$Maybe$Just(parts.month),
								$elm$core$Maybe$Just(parts.day)),
							$author$project$UI$Form$Date$getComponents(specs.displayed))) {
							return _List_Nil;
						} else {
							var _v21 = A2($elm$time$Time$toWeekday, $author$project$Domain$zone, day);
							switch (_v21.$) {
								case 'Sat':
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
										]);
								case 'Sun':
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
										]);
								default:
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_100)
										]);
							}
						}
					} else {
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_20)
							]);
					}
				}
			}();
			var border = _Utils_eq(
				$elm$core$Maybe$Just(parts.day),
				todayDay) ? $author$project$UI$border($author$project$UI$colors.neutral_60) : _List_Nil;
			return _Utils_ap(fontColor, border);
		});
	var shift = F3(
		function (times, interval, selected) {
			var op = A2(
				$elm$core$Basics$composeR,
				$justinmimbs$time_extra$Time$Extra$partsToPosix($elm$time$Time$utc),
				A2(
					$elm$core$Basics$composeR,
					A3($justinmimbs$time_extra$Time$Extra$add, interval, times, $elm$time$Time$utc),
					$justinmimbs$time_extra$Time$Extra$posixToParts($elm$time$Time$utc)));
			var dumbTime = A7($justinmimbs$time_extra$Time$Extra$Parts, 2024, $elm$time$Time$Feb, 19, 0, 0, 0, 0);
			switch (selected.$) {
				case 'Empty':
					return $author$project$UI$Form$Date$Empty;
				case 'Year':
					var year = selected.a;
					return function (x) {
						return $author$project$UI$Form$Date$Year(x.year);
					}(
						op(
							_Utils_update(
								dumbTime,
								{year: year})));
				case 'Month':
					var year = selected.a;
					var month = selected.b;
					return function (x) {
						return A2($author$project$UI$Form$Date$Month, x.year, x.month);
					}(
						op(
							_Utils_update(
								dumbTime,
								{month: month, year: year})));
				default:
					var year = selected.a;
					var month = selected.b;
					var day = selected.c;
					return function (x) {
						return A3($author$project$UI$Form$Date$Day, x.year, x.month, x.day);
					}(
						op(
							_Utils_update(
								dumbTime,
								{day: day, month: month, year: year})));
			}
		});
	var openButton = $author$project$UI$Button$button(
		A4(
			$author$project$UI$Form$iconButton,
			'calendar_month',
			$elm$core$Maybe$Just(
				specs.onChange(
					$author$project$UI$Form$Field(
						_Utils_update(
							specs,
							{open: !specs.open})))),
			$author$project$UI$Button$Filled,
			_List_Nil));
	var message = F2(
		function (close, vis) {
			var valid = function () {
				var _v12 = $author$project$UI$Form$Date$getComponents(vis);
				if (_v12.a.$ === 'Nothing') {
					if ((_v12.b.$ === 'Nothing') && (_v12.c.$ === 'Nothing')) {
						var _v13 = _v12.a;
						var _v14 = _v12.b;
						var _v15 = _v12.c;
						return false;
					} else {
						return false;
					}
				} else {
					var year = _v12.a.a;
					var month = _v12.b;
					var day = _v12.c;
					var interval = function () {
						var _v16 = _Utils_Tuple2(month, day);
						_v16$2:
						while (true) {
							if (_v16.a.$ === 'Nothing') {
								if (_v16.b.$ === 'Nothing') {
									var _v17 = _v16.a;
									var _v18 = _v16.b;
									return $justinmimbs$time_extra$Time$Extra$Year;
								} else {
									break _v16$2;
								}
							} else {
								if (_v16.b.$ === 'Just') {
									return $justinmimbs$time_extra$Time$Extra$Day;
								} else {
									break _v16$2;
								}
							}
						}
						return $justinmimbs$time_extra$Time$Extra$Month;
					}();
					var dumb = A2(
						$justinmimbs$time_extra$Time$Extra$partsToPosix,
						$author$project$Domain$zone,
						A7(
							$justinmimbs$time_extra$Time$Extra$Parts,
							year,
							A2($elm$core$Maybe$withDefault, $elm$time$Time$Jan, month),
							A2($elm$core$Maybe$withDefault, 1, day),
							0,
							0,
							0,
							0));
					var max = A3($justinmimbs$time_extra$Time$Extra$ceiling, interval, $author$project$Domain$zone, dumb);
					var min = A3($justinmimbs$time_extra$Time$Extra$floor, interval, $author$project$Domain$zone, dumb);
					return (A4($justinmimbs$time_extra$Time$Extra$diff, $justinmimbs$time_extra$Time$Extra$Day, $author$project$Domain$zone, specs.from, max) >= 0) && (A4($justinmimbs$time_extra$Time$Extra$diff, $justinmimbs$time_extra$Time$Extra$Day, $author$project$Domain$zone, min, specs.to) >= 0);
				}
			}();
			var makeMessage = $elm$core$Maybe$Just(
				specs.onChange(
					$author$project$UI$Form$Field(
						_Utils_update(
							specs,
							{
								displayed: vis,
								open: close ? false : specs.open,
								state: function () {
									if (vis.$ === 'Day') {
										var year = vis.a;
										var month = vis.b;
										var day = vis.c;
										return $elm$core$Maybe$Just(
											A2(
												$justinmimbs$time_extra$Time$Extra$partsToPosix,
												$author$project$Domain$zone,
												A7($justinmimbs$time_extra$Time$Extra$Parts, year, month, day, 0, 0, 0, 0)));
									} else {
										return $elm$core$Maybe$Nothing;
									}
								}()
							}))));
			return valid ? makeMessage : $elm$core$Maybe$Nothing;
		});
	var errorMessage = A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.error),
				$author$project$UI$fontsizes.regular
			]),
		_List_fromArray(
			[
				function () {
				var _v10 = $author$project$UI$Form$result(specs);
				if (_v10.$ === 'Error') {
					var error = _v10.a;
					return $mdgriffith$elm_ui$Element$text(error);
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}()
			]));
	var dateLabel = function () {
		var _v9 = A2(
			$elm$core$Maybe$map,
			$justinmimbs$time_extra$Time$Extra$posixToParts($author$project$Domain$zone),
			specs.state);
		if (_v9.$ === 'Just') {
			var year = _v9.a.year;
			var month = _v9.a.month;
			var day = _v9.a.day;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$author$project$UI$preventSelect]),
				$mdgriffith$elm_ui$Element$text(
					A2(
						$elm$core$String$join,
						'.',
						_List_fromArray(
							[
								A3(
								$elm$core$String$padLeft,
								2,
								_Utils_chr('0'),
								$elm$core$String$fromInt(day)),
								A3(
								$elm$core$String$padLeft,
								2,
								_Utils_chr('0'),
								$elm$core$String$fromInt(
									$author$project$UI$Form$Date$monthName(month).b)),
								$elm$core$String$fromInt(year)
							]))));
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	}();
	var cellSize = $mdgriffith$elm_ui$Element$px(
		$author$project$UI$scaled(5));
	var monthGrid = A2(
		$elm$core$List$map,
		function (week) {
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(1)),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_100),
						$author$project$UI$preventSelect,
						$author$project$UI$transitionDuration($author$project$UI$durations.instant)
					]),
				A2(
					$elm$core$List$map,
					function (day) {
						var label = A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
							$mdgriffith$elm_ui$Element$text(
								$elm$core$String$fromInt(
									A2($elm$time$Time$toDay, $author$project$Domain$zone, day))));
						var action = function (x) {
							return A2(
								message,
								true,
								A3($author$project$UI$Form$Date$Day, x.year, x.month, x.day));
						}(
							A2($justinmimbs$time_extra$Time$Extra$posixToParts, $author$project$Domain$zone, day));
						return $author$project$UI$Button$button(
							A4(
								$author$project$UI$Button$ButtonSpecs,
								action,
								label,
								_Utils_eq(
									_Utils_Tuple3(
										$elm$core$Maybe$Just(
											A2($elm$time$Time$toYear, $author$project$Domain$zone, day)),
										$elm$core$Maybe$Just(
											A2($elm$time$Time$toMonth, $author$project$Domain$zone, day)),
										$elm$core$Maybe$Just(
											A2($elm$time$Time$toDay, $author$project$Domain$zone, day))),
									$author$project$UI$Form$Date$getComponents(specs.displayed)) ? $author$project$UI$Button$Filled : $author$project$UI$Button$Borderless,
								_Utils_ap(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$padding(0),
											$mdgriffith$elm_ui$Element$Font$regular,
											$mdgriffith$elm_ui$Element$width(cellSize),
											$mdgriffith$elm_ui$Element$height(cellSize)
										]),
									A2(stylizeDay, day, action))));
					},
					week));
		},
		weeks);
	var weekDays = A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$height(cellSize),
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(1)),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
				$author$project$UI$preventSelect
			]),
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				$mdgriffith$elm_ui$Element$text,
				$mdgriffith$elm_ui$Element$el(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(cellSize),
							$mdgriffith$elm_ui$Element$centerX
						]))),
			_List_fromArray(
				['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'])));
	var btn = F2(
		function (x, icon) {
			return $author$project$UI$Button$button(
				A4($author$project$UI$Form$iconButton, icon, x, $author$project$UI$Button$Borderless, _List_Nil));
		});
	var monthButton = function (dir) {
		return btn(
			function () {
				var _v6 = specs.displayed;
				switch (_v6.$) {
					case 'Empty':
						return $elm$core$Maybe$Nothing;
					case 'Year':
						return $elm$core$Maybe$Nothing;
					default:
						var candidate = A3(shift, dir, $justinmimbs$time_extra$Time$Extra$Month, specs.displayed);
						var fallback = function () {
							var _v8 = $author$project$UI$Form$Date$getComponents(candidate);
							if (((_v8.a.$ === 'Just') && (_v8.b.$ === 'Just')) && (_v8.c.$ === 'Just')) {
								var year = _v8.a.a;
								var month = _v8.b.a;
								var day = _v8.c.a;
								return $elm$core$Maybe$Just(
									function (x) {
										return A3($author$project$UI$Form$Date$Day, x.year, x.month, x.day);
									}(
										A2(
											$justinmimbs$time_extra$Time$Extra$posixToParts,
											$author$project$Domain$zone,
											function (posix) {
												return (dir > 0) ? A3($justinmimbs$time_extra$Time$Extra$floor, $justinmimbs$time_extra$Time$Extra$Month, $author$project$Domain$zone, posix) : A4(
													$justinmimbs$time_extra$Time$Extra$add,
													$justinmimbs$time_extra$Time$Extra$Day,
													-1,
													$author$project$Domain$zone,
													A3(
														$justinmimbs$time_extra$Time$Extra$ceiling,
														$justinmimbs$time_extra$Time$Extra$Month,
														$author$project$Domain$zone,
														function (x) {
															return (day === 1) ? A4($justinmimbs$time_extra$Time$Extra$add, $justinmimbs$time_extra$Time$Extra$Day, 1, $author$project$Domain$zone, x) : x;
														}(posix)));
											}(
												A2(
													$justinmimbs$time_extra$Time$Extra$partsToPosix,
													$author$project$Domain$zone,
													A7($justinmimbs$time_extra$Time$Extra$Parts, year, month, day, 0, 0, 0, 0))))));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}();
						var _v7 = A2(message, false, candidate);
						if (_v7.$ === 'Just') {
							var m = _v7.a;
							return $elm$core$Maybe$Just(m);
						} else {
							return A2(
								$elm$core$Maybe$andThen,
								message(false),
								fallback);
						}
				}
			}());
	};
	var yearButton = function (dir) {
		return btn(
			function () {
				var _v3 = specs.displayed;
				if (_v3.$ === 'Empty') {
					return $elm$core$Maybe$Nothing;
				} else {
					var candidate = A3(shift, dir, $justinmimbs$time_extra$Time$Extra$Year, specs.displayed);
					var fallback = function () {
						var _v5 = $author$project$UI$Form$Date$getComponents(candidate);
						if (((_v5.a.$ === 'Just') && (_v5.b.$ === 'Just')) && (_v5.c.$ === 'Just')) {
							var year = _v5.a.a;
							var month = _v5.b.a;
							var day = _v5.c.a;
							return $elm$core$Maybe$Just(
								function (x) {
									return A3($author$project$UI$Form$Date$Day, x.year, x.month, x.day);
								}(
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$author$project$Domain$zone,
										function (posix) {
											return (dir > 0) ? A3($justinmimbs$time_extra$Time$Extra$floor, $justinmimbs$time_extra$Time$Extra$Year, $author$project$Domain$zone, posix) : A4(
												$justinmimbs$time_extra$Time$Extra$add,
												$justinmimbs$time_extra$Time$Extra$Day,
												-1,
												$author$project$Domain$zone,
												A3(
													$justinmimbs$time_extra$Time$Extra$ceiling,
													$justinmimbs$time_extra$Time$Extra$Year,
													$author$project$Domain$zone,
													function (x) {
														return (_Utils_eq(month, $elm$time$Time$Jan) && (day === 1)) ? A4($justinmimbs$time_extra$Time$Extra$add, $justinmimbs$time_extra$Time$Extra$Day, 1, $author$project$Domain$zone, x) : x;
													}(posix)));
										}(
											A2(
												$justinmimbs$time_extra$Time$Extra$partsToPosix,
												$author$project$Domain$zone,
												A7($justinmimbs$time_extra$Time$Extra$Parts, year, month, day, 0, 0, 0, 0))))));
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}();
					var _v4 = A2(message, false, candidate);
					if (_v4.$ === 'Just') {
						var m = _v4.a;
						return $elm$core$Maybe$Just(m);
					} else {
						return A2(
							$elm$core$Maybe$andThen,
							message(false),
							fallback);
					}
				}
			}());
	};
	var controlsRow = A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(-3)),
				$mdgriffith$elm_ui$Element$centerX
			]),
		_List_fromArray(
			[
				A2(yearButton, -1, 'keyboard_double_arrow_left'),
				A2(monthButton, -1, 'chevron_left'),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$width(
						A2(
							$mdgriffith$elm_ui$Element$minimum,
							$author$project$UI$scaled(10),
							$mdgriffith$elm_ui$Element$shrink)),
						$author$project$UI$preventSelect
					]),
				function () {
					var _v2 = specs.displayed;
					switch (_v2.$) {
						case 'Empty':
							return _List_Nil;
						case 'Year':
							var year = _v2.a;
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(
									$elm$core$String$fromInt(year))
								]);
						case 'Month':
							var year = _v2.a;
							var month = _v2.b;
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text(
										$elm$core$String$fromInt(year))),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text(
										$author$project$UI$Form$Date$monthName(month).a))
								]);
						default:
							var year = _v2.a;
							var month = _v2.b;
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text(
										$elm$core$String$fromInt(year))),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text(
										$author$project$UI$Form$Date$monthName(month).a))
								]);
					}
				}()),
				A2(monthButton, 1, 'chevron_right'),
				A2(yearButton, 1, 'keyboard_double_arrow_right')
			]));
	var card = A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$paddingXY,
				0,
				$author$project$UI$scaled(-1))
			]),
		A2(
			$author$project$UI$Card$contentCard,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$Font$center]),
			function () {
				var _v1 = specs.displayed;
				if (_v1.$ === 'Empty') {
					return _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(
										$author$project$UI$scaled(10))),
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(
										$author$project$UI$scaled(10))),
									$mdgriffith$elm_ui$Element$padding(
									$author$project$UI$scaled(3))
								]),
							$author$project$UI$Loading$spinner)
						]);
				} else {
					return _Utils_ap(
						_List_fromArray(
							[controlsRow, weekDays]),
						monthGrid);
				}
			}()));
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(6),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-3)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_Utils_ap(
					specs.showLabel ? _List_fromArray(
						[
							A2(
							$author$project$UI$Form$Label$plain,
							specs.name,
							$author$project$UI$Form$isRequired(specs)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(
										$author$project$UI$scaled(1)))
								]),
							$mdgriffith$elm_ui$Element$none)
						]) : _List_Nil,
					_List_fromArray(
						[
							dateLabel,
							A2(
							$mdgriffith$elm_ui$Element$el,
							specs.open ? _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$below(
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$moveLeft(
												$author$project$UI$scaled(15) - 20)
											]),
										card))
								]) : _List_Nil,
							openButton)
						]))),
				errorMessage
			]));
};
var $author$project$UI$Form$Date$default = F4(
	function (from, to, today, msgFunc) {
		return {
			check: $elm$core$Basics$always($elm$core$Maybe$Nothing),
			displayed: $author$project$UI$Form$Date$Empty,
			focusedOnLoad: false,
			from: from,
			hint: $elm$core$Maybe$Nothing,
			name: '',
			onChange: msgFunc,
			open: false,
			render: $author$project$UI$Form$Date$render,
			required: $author$project$UI$Form$Required,
			showLabel: true,
			state: $elm$core$Maybe$Nothing,
			to: to,
			today: today
		};
	});
var $author$project$Session$getLocalStorageData = function (session) {
	var _v0 = $author$project$Session$getViewer(session);
	if (_v0.$ === 'Authenticated') {
		var _v1 = _v0.a;
		var ls = _v1.b;
		return $elm$core$Maybe$Just(ls);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Domain$orderDateRange = function (today) {
	if (today.$ === 'Nothing') {
		return _Utils_Tuple2(
			$elm$time$Time$millisToPosix(0),
			A2(
				$justinmimbs$time_extra$Time$Extra$partsToPosix,
				$author$project$Domain$zone,
				A7($justinmimbs$time_extra$Time$Extra$Parts, 2100, $elm$time$Time$Jan, 1, 0, 0, 0, 0)));
	} else {
		var time = today.a;
		var start = A3($justinmimbs$time_extra$Time$Extra$floor, $justinmimbs$time_extra$Time$Extra$Day, $author$project$Domain$zone, time);
		return _Utils_Tuple2(
			start,
			A4($justinmimbs$time_extra$Time$Extra$add, $justinmimbs$time_extra$Time$Extra$Month, 1, $author$project$Domain$zone, start));
	}
};
var $author$project$Page$Cart$init = function (args) {
	return _Utils_Tuple2(
		{
			awaitingResponse: false,
			device: args.device,
			fields: function () {
				var _v0 = $author$project$Session$getLocalStorageData(args.session);
				if ((_v0.$ === 'Just') && (_v0.a.$ === 'TenantLocalStorage')) {
					var cart = _v0.a.a.cart;
					return $elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (req) {
								var numberField = A2(
									$author$project$UI$Form$Number$default,
									$author$project$UI$Form$Number$int,
									$author$project$Page$Cart$ChangedNumber(
										$author$project$API$Resource$toMeta(req.service)));
								var commentField = $author$project$UI$Form$Text$default(
									$author$project$Page$Cart$ChangedComment(
										$author$project$API$Resource$toMeta(req.service)));
								var _v1 = $author$project$Domain$orderDateRange($elm$core$Maybe$Nothing);
								var start = _v1.a;
								var end = _v1.b;
								var dateField = A4(
									$author$project$UI$Form$Date$default,
									start,
									end,
									$elm$core$Maybe$Nothing,
									$author$project$Page$Cart$ChangedDate(
										$author$project$API$Resource$toMeta(req.service)));
								return _Utils_Tuple2(
									$author$project$API$Resource$toMeta(req.service),
									A3(
										$author$project$Page$Cart$Fieldset,
										_Utils_update(
											numberField,
											{
												check: A2(
													$author$project$UI$Form$simpleCheck,
													function (x) {
														return x > 0;
													},
													'Количество не может быть отрицательным'),
												displayed: $elm$core$String$fromInt(req.number),
												name: 'Количество',
												render: A2(
													$elm$core$Basics$composeR,
													numberField.render,
													$mdgriffith$elm_ui$Element$el(
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$centerY,
																$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
															]))),
												showLabel: false,
												state: $elm$core$Maybe$Just(req.number)
											}),
										_Utils_update(
											commentField,
											{
												displayed: req.comment,
												hint: $elm$core$Maybe$Just('Комментарий'),
												kind: $author$project$UI$Form$Text$Multiline(true),
												name: 'Комментарий',
												showLabel: false,
												state: $elm$core$Maybe$Just(req.comment)
											}),
										_Utils_update(
											dateField,
											{
												displayed: function () {
													var _v2 = req.when;
													if (_v2.$ === 'Just') {
														var when = _v2.a;
														return function (_v3) {
															var year = _v3.year;
															var month = _v3.month;
															var day = _v3.day;
															return A3($author$project$UI$Form$Date$Day, year, month, day);
														}(
															A2($justinmimbs$time_extra$Time$Extra$posixToParts, $author$project$Domain$zone, when));
													} else {
														return $author$project$UI$Form$Date$Empty;
													}
												}(),
												name: 'Когда',
												render: A2(
													$elm$core$Basics$composeR,
													dateField.render,
													$mdgriffith$elm_ui$Element$el(
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
															]))),
												required: $author$project$UI$Form$Optional,
												showLabel: false,
												state: req.when
											})));
							},
							cart));
				} else {
					return $elm$core$Dict$empty;
				}
			}(),
			session: args.session,
			today: $elm$core$Maybe$Nothing
		},
		A2($elm$core$Task$perform, $author$project$Page$Cart$GotPosix, $elm$time$Time$now));
};
var $author$project$Page$Cart$GotPostResponse = function (a) {
	return {$: 'GotPostResponse', a: a};
};
var $author$project$PopUp$Important = {$: 'Important'};
var $author$project$PopUp$PlainText = F2(
	function (a, b) {
		return {$: 'PlainText', a: a, b: b};
	});
var $author$project$Page$Cart$RemoveFromCart = function (a) {
	return {$: 'RemoveFromCart', a: a};
};
var $author$project$Framework$ShowPopUp = F2(
	function (a, b) {
		return {$: 'ShowPopUp', a: a, b: b};
	});
var $author$project$Session$getToken = function (_v0) {
	var viewer = _v0.b;
	if (viewer.$ === 'Authenticated') {
		if (viewer.a.b.$ === 'TenantLocalStorage') {
			var _v2 = viewer.a;
			var authToken = _v2.b.a.authToken;
			return $elm$core$Maybe$Just(authToken);
		} else {
			var _v3 = viewer.a;
			var authToken = _v3.b.a.authToken;
			return $elm$core$Maybe$Just(authToken);
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $elmcraft$core_extra$Cmd$Extra$perform = A2(
	$elm$core$Basics$composeL,
	$elm$core$Task$perform($elm$core$Basics$identity),
	$elm$core$Task$succeed);
var $author$project$API$Post = F3(
	function (a, b, c) {
		return {$: 'Post', a: a, b: b, c: c};
	});
var $author$project$API$post = F5(
	function (name, _v0, headers, extraParams, object) {
		var encoder = _v0.a;
		var decoder = _v0.b;
		var parameters = _v0.c;
		return $elm$http$Http$task(
			{
				body: $elm$http$Http$jsonBody(
					encoder(object)),
				headers: headers,
				method: 'POST',
				resolver: $author$project$API$jsonResolver(decoder),
				timeout: $author$project$API$timeout,
				url: A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						['api', 'collections', name, 'records']),
					$author$project$API$paramsToQuery(
						_Utils_ap(extraParams, parameters)))
			});
	});
var $author$project$Domain$ServiceRequest = F8(
	function (user, when, comment, number, flat, service, status, created) {
		return {comment: comment, created: created, flat: flat, number: number, service: service, status: status, user: user, when: when};
	});
var $elm$json$Json$Decode$map7 = _Json_map7;
var $author$project$Domain$SRAssigned = {$: 'SRAssigned'};
var $author$project$Domain$SRCancelled = {$: 'SRCancelled'};
var $author$project$Domain$SRCreated = {$: 'SRCreated'};
var $author$project$Domain$SRDone = {$: 'SRDone'};
var $author$project$Domain$serviceRequestStatusDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		switch (s) {
			case 'created':
				return $elm$json$Json$Decode$succeed($author$project$Domain$SRCreated);
			case 'assigned':
				return $elm$json$Json$Decode$succeed($author$project$Domain$SRAssigned);
			case 'done':
				return $elm$json$Json$Decode$succeed($author$project$Domain$SRDone);
			case 'cancelled':
				return $elm$json$Json$Decode$succeed($author$project$Domain$SRCancelled);
			default:
				return $elm$json$Json$Decode$fail('invalid ServiceRequestStatus value: \"' + (s + '\"'));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Domain$serviceRequestDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (x) {
		return A2(
			$elm$json$Json$Decode$map,
			x,
			$elm$json$Json$Decode$maybe(
				A2($elm$json$Json$Decode$field, 'created', $author$project$Utils$parseTimestamp)));
	},
	A8(
		$elm$json$Json$Decode$map7,
		$author$project$Domain$ServiceRequest,
		A3($author$project$Domain$PB$shortResourceDecoderWithID, 'user', $author$project$Domain$PB$idDecoder, $author$project$Domain$tenantDecoder),
		A2(
			$elm$json$Json$Decode$field,
			'when',
			$elm$json$Json$Decode$maybe($author$project$Utils$parseTimestamp)),
		A2($elm$json$Json$Decode$field, 'comment', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'number', $elm$json$Json$Decode$int),
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$map,
					function (x) {
						return A2(
							$author$project$API$Resource$Resource,
							x.meta.id,
							$author$project$API$Status$Loaded(x));
					},
					A2(
						$elm$json$Json$Decode$at,
						_List_fromArray(
							['expand', 'flat']),
						$author$project$Domain$PB$decoderWithID($author$project$Domain$flatDecoder))),
					A2(
					$elm$json$Json$Decode$map,
					function (x) {
						return A2($author$project$API$Resource$Resource, x, $author$project$API$Status$NotStarted);
					},
					A2($elm$json$Json$Decode$field, 'flat', $elm$json$Json$Decode$string))
				])),
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$map,
					function (x) {
						return A2(
							$author$project$API$Resource$Resource,
							x.meta.id,
							$author$project$API$Status$Loaded(x));
					},
					A2(
						$elm$json$Json$Decode$at,
						_List_fromArray(
							['expand', 'service']),
						$author$project$Domain$PB$decoderWithID($author$project$Domain$serviceDecoder))),
					A2(
					$elm$json$Json$Decode$map,
					function (x) {
						return A2($author$project$API$Resource$Resource, x, $author$project$API$Status$NotStarted);
					},
					A2($elm$json$Json$Decode$field, 'service', $elm$json$Json$Decode$string))
				])),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'status', $author$project$Domain$serviceRequestStatusDecoder))));
var $author$project$Utils$monthToInt = function (month) {
	var months = _List_fromArray(
		[$elm$time$Time$Jan, $elm$time$Time$Feb, $elm$time$Time$Mar, $elm$time$Time$Apr, $elm$time$Time$May, $elm$time$Time$Jun, $elm$time$Time$Jul, $elm$time$Time$Aug, $elm$time$Time$Sep, $elm$time$Time$Oct, $elm$time$Time$Nov, $elm$time$Time$Dec]);
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$List$head(
			A2(
				$elm$core$List$filter,
				$elm$core$Basics$neq(0),
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, m) {
							return _Utils_eq(m, month) ? (i + 1) : 0;
						}),
					months))));
};
var $author$project$Utils$encodeTimestamp = function (time) {
	var parts = A2($justinmimbs$time_extra$Time$Extra$posixToParts, $elm$time$Time$utc, time);
	return $elm$json$Json$Encode$string(
		$elm$core$String$concat(
			_List_fromArray(
				[
					$elm$core$String$fromInt(parts.year),
					'-',
					A3(
					$elm$core$String$padLeft,
					2,
					_Utils_chr('0'),
					$elm$core$String$fromInt(
						$author$project$Utils$monthToInt(parts.month))),
					'-',
					A3(
					$elm$core$String$padLeft,
					2,
					_Utils_chr('0'),
					$elm$core$String$fromInt(parts.day)),
					' ',
					A3(
					$elm$core$String$padLeft,
					2,
					_Utils_chr('0'),
					$elm$core$String$fromInt(parts.hour)),
					':',
					A3(
					$elm$core$String$padLeft,
					2,
					_Utils_chr('0'),
					$elm$core$String$fromInt(parts.minute)),
					':',
					A3(
					$elm$core$String$padLeft,
					2,
					_Utils_chr('0'),
					$elm$core$String$fromInt(parts.second)),
					'.',
					A3(
					$elm$core$String$padLeft,
					3,
					_Utils_chr('0'),
					$elm$core$String$fromInt(parts.millisecond)),
					'Z'
				])));
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Domain$serviceRequestStatusEncoder = function (status) {
	return $elm$json$Json$Encode$string(
		function () {
			switch (status.$) {
				case 'SRCreated':
					return 'created';
				case 'SRAssigned':
					return 'assigned';
				case 'SRDone':
					return 'done';
				default:
					return 'cancelled';
			}
		}());
};
var $author$project$Domain$serviceRequestEncoder = function (request) {
	return _Utils_ap(
		function () {
			var _v0 = request.when;
			if (_v0.$ === 'Just') {
				var when = _v0.a;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'when',
						$author$project$Utils$encodeTimestamp(when))
					]);
			} else {
				return _List_Nil;
			}
		}(),
		_List_fromArray(
			[
				_Utils_Tuple2(
				'user',
				$elm$json$Json$Encode$string(
					$author$project$API$Resource$toMeta(request.user))),
				_Utils_Tuple2(
				'comment',
				$elm$json$Json$Encode$string(request.comment)),
				_Utils_Tuple2(
				'number',
				$elm$json$Json$Encode$int(request.number)),
				_Utils_Tuple2(
				'flat',
				$elm$json$Json$Encode$string(
					$author$project$API$Resource$toMeta(request.flat))),
				_Utils_Tuple2(
				'service',
				$elm$json$Json$Encode$string(
					$author$project$API$Resource$toMeta(request.service))),
				_Utils_Tuple2(
				'status',
				$author$project$Domain$serviceRequestStatusEncoder(
					A2($elm$core$Maybe$withDefault, $author$project$Domain$SRCreated, request.status)))
			]));
};
var $author$project$Collections$postServiceRequest = function (authHeader) {
	return A4(
		$author$project$API$post,
		'service_request',
		A3(
			$author$project$API$Post,
			A2($elm$core$Basics$composeR, $author$project$Domain$serviceRequestEncoder, $elm$json$Json$Encode$object),
			$author$project$Domain$PB$decoderWithID($author$project$Domain$serviceRequestDecoder),
			_List_Nil),
		_List_fromArray(
			[authHeader]),
		_List_Nil);
};
var $elmcraft$core_extra$List$Extra$updateIf = F3(
	function (predicate, update, list) {
		return A2(
			$elm$core$List$map,
			function (item) {
				return predicate(item) ? update(item) : item;
			},
			list);
	});
var $author$project$Page$Cart$update = F2(
	function (msg, model) {
		var ignore = _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
		var handleFieldUpdate = F3(
			function (_v8, updField, updReq) {
				var serviceId = _v8.a;
				var specs = _v8.b;
				var lsData = _v8.c;
				var cart = lsData.cart;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							fields: A3(
								$elm$core$Dict$update,
								serviceId,
								$elm$core$Maybe$map(updField),
								model.fields)
						}),
					$elm$core$Platform$Cmd$none,
					function () {
						var _v7 = $author$project$UI$Form$result(specs);
						switch (_v7.$) {
							case 'WithResult':
								var state = _v7.a;
								return $author$project$Framework$UpdateLocalStorage(
									$author$project$Session$TenantLocalStorage(
										_Utils_update(
											lsData,
											{
												cart: A3(
													$elmcraft$core_extra$List$Extra$updateIf,
													function (req) {
														return _Utils_eq(
															$author$project$API$Resource$toMeta(req.service),
															serviceId);
													},
													updReq(state),
													cart)
											})));
							case 'Error':
								return $author$project$Framework$NoOp;
							default:
								return $author$project$Framework$NoOp;
						}
					}());
			});
		var authHeader = $author$project$Session$authorizationHeader(
			A2(
				$elm$core$Maybe$withDefault,
				'',
				$author$project$Session$getToken(model.session)));
		var _v0 = _Utils_Tuple2(
			msg,
			$author$project$Session$getLocalStorageData(model.session));
		_v0$8:
		while (true) {
			switch (_v0.a.$) {
				case 'GotPosix':
					var now = _v0.a.a;
					var _v1 = $author$project$Domain$orderDateRange(
						$elm$core$Maybe$Just(now));
					var start = _v1.a;
					var end = _v1.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								fields: A2(
									$elm$core$Dict$map,
									F2(
										function (_v2, fields) {
											var dateField = fields.dateField;
											return _Utils_update(
												fields,
												{
													dateField: _Utils_update(
														dateField,
														{from: start, to: end})
												});
										}),
									model.fields),
								today: $elm$core$Maybe$Just(now)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				case 'RemoveFromCart':
					if ((_v0.b.$ === 'Just') && (_v0.b.a.$ === 'TenantLocalStorage')) {
						var serviceId = _v0.a.a;
						var lsData = _v0.b.a.a;
						var cart = lsData.cart;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									fields: A2($elm$core$Dict$remove, serviceId, model.fields)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$UpdateLocalStorage(
								$author$project$Session$TenantLocalStorage(
									_Utils_update(
										lsData,
										{
											cart: A2(
												$elm$core$List$filter,
												function (req) {
													return !_Utils_eq(
														$author$project$API$Resource$toMeta(req.service),
														serviceId);
												},
												cart)
										}))));
					} else {
						break _v0$8;
					}
				case 'ChangedNumber':
					if ((_v0.b.$ === 'Just') && (_v0.b.a.$ === 'TenantLocalStorage')) {
						var _v3 = _v0.a;
						var serviceId = _v3.a;
						var specs = _v3.b.a;
						var ls = _v0.b.a.a;
						return A3(
							handleFieldUpdate,
							_Utils_Tuple3(serviceId, specs, ls),
							function (x) {
								return _Utils_update(
									x,
									{numberField: specs});
							},
							F2(
								function (state, req) {
									return _Utils_update(
										req,
										{number: state});
								}));
					} else {
						break _v0$8;
					}
				case 'ChangedComment':
					if ((_v0.b.$ === 'Just') && (_v0.b.a.$ === 'TenantLocalStorage')) {
						var _v4 = _v0.a;
						var serviceId = _v4.a;
						var specs = _v4.b.a;
						var ls = _v0.b.a.a;
						return A3(
							handleFieldUpdate,
							_Utils_Tuple3(serviceId, specs, ls),
							function (x) {
								return _Utils_update(
									x,
									{commentField: specs});
							},
							F2(
								function (state, req) {
									return _Utils_update(
										req,
										{comment: state});
								}));
					} else {
						break _v0$8;
					}
				case 'ChangedDate':
					if ((_v0.b.$ === 'Just') && (_v0.b.a.$ === 'TenantLocalStorage')) {
						var _v5 = _v0.a;
						var serviceId = _v5.a;
						var specs = _v5.b.a;
						var ls = _v0.b.a.a;
						return A3(
							handleFieldUpdate,
							_Utils_Tuple3(serviceId, specs, ls),
							function (x) {
								return _Utils_update(
									x,
									{dateField: specs});
							},
							F2(
								function (state, req) {
									return _Utils_update(
										req,
										{
											when: $elm$core$Maybe$Just(state)
										});
								}));
					} else {
						break _v0$8;
					}
				case 'PressedSubmit':
					if ((_v0.b.$ === 'Just') && (_v0.b.a.$ === 'TenantLocalStorage')) {
						var _v6 = _v0.a;
						var cart = _v0.b.a.a.cart;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{awaitingResponse: true}),
							$elm$core$Platform$Cmd$batch(
								A2(
									$elm$core$List$map,
									A2(
										$elm$core$Basics$composeR,
										$author$project$Collections$postServiceRequest(authHeader),
										$elm$core$Task$attempt($author$project$Page$Cart$GotPostResponse)),
									cart)),
							$author$project$Framework$NoOp);
					} else {
						break _v0$8;
					}
				default:
					if (_v0.a.a.$ === 'Ok') {
						if ((_v0.b.$ === 'Just') && (_v0.b.a.$ === 'TenantLocalStorage')) {
							var req = _v0.a.a.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{awaitingResponse: false}),
								$elmcraft$core_extra$Cmd$Extra$perform(
									$author$project$Page$Cart$RemoveFromCart(
										$author$project$API$Resource$toMeta(req.domain.service))),
								$author$project$Framework$NoOp);
						} else {
							break _v0$8;
						}
					} else {
						if ((_v0.b.$ === 'Just') && (_v0.b.a.$ === 'TenantLocalStorage')) {
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{awaitingResponse: false}),
								$elm$core$Platform$Cmd$none,
								A2(
									$author$project$Framework$ShowPopUp,
									A2($author$project$PopUp$PlainText, 'Ошибка!', 'Не удалось создать заказ, что-то пошло не так'),
									$author$project$PopUp$Important));
						} else {
							break _v0$8;
						}
					}
			}
		}
		return ignore;
	});
var $author$project$Page$Cart$PressedSubmit = {$: 'PressedSubmit'};
var $elmcraft$core_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Domain$flatAddress = function (flat) {
	var unwantedPrefix = 'РОССИЯ, 672040, Забайкальский край, Чита г, Хороший мкр, ';
	var buildingAddress = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (building) {
				return building.domain.address + ', ';
			},
			$author$project$API$Resource$toMaybe(flat.building)));
	return $elm$core$String$concat(
		_List_fromArray(
			[
				A2($elm$core$String$startsWith, unwantedPrefix, buildingAddress) ? A2(
				$elm$core$String$dropLeft,
				$elm$core$String$length(unwantedPrefix),
				buildingAddress) : buildingAddress,
				'кв. ',
				$elm$core$String$fromInt(flat.number)
			]));
};
var $elmcraft$core_extra$Dict$Extra$groupBy = F2(
	function (keyfn, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A3(
						$elm$core$Dict$update,
						keyfn(x),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$map(
								$elm$core$List$cons(x)),
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Maybe$withDefault(
									_List_fromArray(
										[x])),
								$elm$core$Maybe$Just)),
						acc);
				}),
			$elm$core$Dict$empty,
			list);
	});
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === 'RBEmpty_elm_builtin') {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elmcraft$core_extra$Dict$Extra$mapKeys = F2(
	function (keyMapper, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, acc) {
					return A3(
						$elm$core$Dict$insert,
						keyMapper(k),
						v,
						acc);
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Page$Cart$renderEmptyCart = A2(
	$mdgriffith$elm_ui$Element$column,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$centerY,
			$mdgriffith$elm_ui$Element$Font$center,
			$author$project$UI$fontsizes.big,
			$mdgriffith$elm_ui$Element$spacing(
			$author$project$UI$scaled(1))
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Ваша корзина пуста!')
				])),
			A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Чтобы заказать услугу, перейдите в раздел '),
					A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
						]),
					{
						label: $mdgriffith$elm_ui$Element$text('\"Услуги\"'),
						url: '/service-category/'
					})
				]))
		]));
var $elm$core$Debug$log = _Debug_log;
var $author$project$API$Resource$toStatus = function (_v0) {
	var status = _v0.b;
	return status;
};
var $author$project$Page$Cart$renderServiceRequest = F3(
	function (model, serviceId, fieldset) {
		var topRow = function (service) {
			return A2(
				$author$project$UI$Form$withField,
				fieldset.numberField,
				A4(
					$author$project$UI$Form$withButton,
					false,
					$elm$core$Maybe$Nothing,
					A4(
						$author$project$UI$Form$iconButton,
						'delete',
						$elm$core$Maybe$Just(
							$author$project$Page$Cart$RemoveFromCart(serviceId)),
						$author$project$UI$Button$Filled,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerY])),
					A2(
						$author$project$UI$Form$withExternal,
						A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$link,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									{
										label: $mdgriffith$elm_ui$Element$text(service.domain.name + (' (' + (service.domain.unit + ')'))),
										url: '/service/' + service.meta.id
									})
								])),
						$author$project$UI$Form$empty)));
		};
		var serviceRequest = function () {
			var _v1 = $author$project$Session$getLocalStorageData(model.session);
			if ((_v1.$ === 'Just') && (_v1.a.$ === 'TenantLocalStorage')) {
				var cart = _v1.a.a.cart;
				return A2(
					$elmcraft$core_extra$List$Extra$find,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.service;
						},
						A2(
							$elm$core$Basics$composeR,
							$author$project$API$Resource$toMeta,
							$elm$core$Basics$eq(serviceId))),
					cart);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var _v0 = A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.service;
				},
				$author$project$API$Resource$toStatus),
			serviceRequest);
		if ((_v0.$ === 'Just') && (_v0.a.$ === 'Loaded')) {
			var service = _v0.a.a;
			return A2(
				$elm$core$Basics$composeR,
				A3(
					$author$project$UI$Form$withRow,
					false,
					_List_Nil,
					topRow(service)),
				A3(
					$author$project$UI$Form$withRow,
					false,
					_List_Nil,
					A2(
						$author$project$UI$Form$withField,
						fieldset.dateField,
						A2($author$project$UI$Form$withField, fieldset.commentField, $author$project$UI$Form$empty))));
		} else {
			return A2(
				$elm$core$Basics$composeR,
				$author$project$UI$Form$withExternal(
					$mdgriffith$elm_ui$Element$text('Нет данных, попробуйте обновить страницу')),
				function (x) {
					return A2(
						$elm$core$Debug$log,
						'erronous model',
						_Utils_Tuple2(model, x)).b;
				});
		}
	});
var $author$project$Page$Cart$withSeparator = $author$project$UI$Form$withExternal(
	A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(
				$author$project$UI$scaled(-1)),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(1)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_20)
				]),
			$mdgriffith$elm_ui$Element$none)));
var $author$project$Page$Cart$renderFlatRequests = F3(
	function (model, _v0, content) {
		var address = _v0.a;
		var requests = _v0.b;
		return A3(
			$elm$core$Basics$apR,
			A2(
				$elm$core$List$intersperse,
				$author$project$Page$Cart$withSeparator,
				A2(
					$elm$core$List$map,
					function (_v1) {
						var sId = _v1.a;
						var fieldSet = _v1.b;
						return A3($author$project$Page$Cart$renderServiceRequest, model, sId, fieldSet);
					},
					A2(
						$elm$core$List$filterMap,
						function (req) {
							return A2(
								$elm$core$Maybe$map,
								function (qux) {
									return _Utils_Tuple2(
										$author$project$API$Resource$toMeta(req.service),
										qux);
								},
								A2(
									$elm$core$Dict$get,
									$author$project$API$Resource$toMeta(req.service),
									model.fields));
						},
						requests))),
			A2($elm$core$List$foldl, $elm$core$Basics$composeR, $elm$core$Basics$identity),
			A2(
				$author$project$UI$Form$withExternal,
				A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[$author$project$UI$fontsizes.big, $mdgriffith$elm_ui$Element$Font$center]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(address)
						])),
				content));
	});
var $author$project$Page$Cart$view = function (model) {
	var _v0 = $author$project$Session$getLocalStorageData(model.session);
	if (_v0.$ === 'Just') {
		if (_v0.a.$ === 'TenantLocalStorage') {
			var lsData = _v0.a.a;
			if ($elm$core$Dict$isEmpty(model.fields)) {
				return $author$project$Page$Cart$renderEmptyCart;
			} else {
				var flats = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$API$Resource$toMaybe(lsData.user.domain.flat));
				var byFlat = $elm$core$Dict$toList(
					A2(
						$elmcraft$core_extra$Dict$Extra$mapKeys,
						function (flatId) {
							return A2(
								$elm$core$Maybe$withDefault,
								flatId,
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.domain;
										},
										$author$project$Domain$flatAddress),
									A2(
										$elmcraft$core_extra$List$Extra$find,
										function (f) {
											return _Utils_eq(f.meta.id, flatId);
										},
										flats)));
						},
						A2(
							$elmcraft$core_extra$Dict$Extra$groupBy,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.flat;
								},
								$author$project$API$Resource$toMeta),
							lsData.cart)));
				return A2(
					$author$project$UI$Form$form,
					_List_Nil,
					A3(
						$elm$core$Basics$apL,
						$author$project$UI$Form$ifValid,
						A3(
							$author$project$UI$Form$withButton,
							false,
							$elm$core$Maybe$Nothing,
							A4(
								$author$project$UI$Button$ButtonSpecs,
								$elm$core$Maybe$Just($author$project$Page$Cart$PressedSubmit),
								model.awaitingResponse ? $author$project$UI$Loading$spinner : $mdgriffith$elm_ui$Element$text('Оформить заказ'),
								$author$project$UI$Button$Filled,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]))),
						A3(
							$elm$core$Basics$apR,
							A2(
								$elm$core$List$intersperse,
								$author$project$Page$Cart$withSeparator,
								A2(
									$elm$core$List$map,
									$author$project$Page$Cart$renderFlatRequests(model),
									byFlat)),
							A2($elm$core$List$foldl, $elm$core$Basics$composeR, $elm$core$Basics$identity),
							A2($author$project$UI$Form$withTitle, 'Заказ', $author$project$UI$Form$empty))));
			}
		} else {
			return $author$project$Page$Cart$renderEmptyCart;
		}
	} else {
		return $author$project$Page$Cart$renderEmptyCart;
	}
};
var $author$project$Page$Cart$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Protected($author$project$Page$Cart$accessFilter),
	{
		baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
		init: $author$project$Page$Cart$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $elm$core$Basics$always('Корзина'),
		update: $author$project$Framework$EffectUpdate($author$project$Page$Cart$update),
		view: $author$project$Framework$PlainView($author$project$Page$Cart$view),
		wrapArgs: A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$core$Basics$always)
	});
var $author$project$Framework$StaticPage = function (a) {
	return {$: 'StaticPage', a: a};
};
var $author$project$Framework$PlainUpdate = function (a) {
	return {$: 'PlainUpdate', a: a};
};
var $author$project$Framework$pageFromStatic = function (_v0) {
	var view = _v0.a.view;
	var title = _v0.a.title;
	return A2(
		$author$project$Framework$Page,
		$author$project$Framework$Public,
		{
			baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
			init: function (args) {
				return _Utils_Tuple2(args, $elm$core$Platform$Cmd$none);
			},
			subs: $elm$core$Basics$always(_List_Nil),
			title: $elm$core$Basics$always(title),
			update: $author$project$Framework$PlainUpdate(
				F2(
					function (_v1, model) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					})),
			view: $author$project$Framework$PlainView(
				function (model) {
					return view(model);
				}),
			wrapArgs: F2(
				function (x, _v2) {
					return x;
				})
		});
};
var $author$project$Page$Contacts$Email = function (a) {
	return {$: 'Email', a: a};
};
var $author$project$Page$Contacts$PhoneNumber = function (a) {
	return {$: 'PhoneNumber', a: a};
};
var $author$project$UI$centerJustifiedRowContent = $mdgriffith$elm_ui$Element$htmlAttribute(
	$elm$html$Html$Attributes$class('centered-row-content'));
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $mdgriffith$elm_ui$Element$image = F2(
	function (attrs, _v0) {
		var src = _v0.src;
		var description = _v0.description;
		var imageAttributes = A2(
			$elm$core$List$filter,
			function (a) {
				switch (a.$) {
					case 'Width':
						return true;
					case 'Height':
						return true;
					default:
						return false;
				}
			},
			attrs);
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.imageContainer),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[
						A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asEl,
						$mdgriffith$elm_ui$Internal$Model$NodeName('img'),
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$src(src)),
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$alt(description))
								]),
							imageAttributes),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil))
					])));
	});
var $author$project$Page$Contacts$contactCard = F3(
	function (name, contacts, imageUrl) {
		return A2(
			$author$project$UI$Card$contentCard,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$center,
					$mdgriffith$elm_ui$Element$Font$bold,
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(-1)),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$image,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$px(256))
							]),
						{description: '', src: imageUrl}),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
							]),
						$elm$core$List$singleton(
							$mdgriffith$elm_ui$Element$text(name)))
					]),
				A2(
					$elm$core$List$map,
					function (contact) {
						return A2(
							$mdgriffith$elm_ui$Element$link,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							{
								label: $mdgriffith$elm_ui$Element$text(
									function () {
										if (contact.$ === 'PhoneNumber') {
											var number = contact.a;
											return number;
										} else {
											var email = contact.a;
											return email;
										}
									}()),
								url: function () {
									if (contact.$ === 'PhoneNumber') {
										var number = contact.a;
										return 'tel:' + number;
									} else {
										var email = contact.a;
										return 'mailto:' + email;
									}
								}()
							});
					},
					contacts)));
	});
var $mdgriffith$elm_ui$Internal$Model$formatTextShadow = function (shadow) {
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				$elm$core$String$fromFloat(shadow.offset.a) + 'px',
				$elm$core$String$fromFloat(shadow.offset.b) + 'px',
				$elm$core$String$fromFloat(shadow.blur) + 'px',
				$mdgriffith$elm_ui$Internal$Model$formatColor(shadow.color)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$textShadowClass = function (shadow) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'txt',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.offset.a) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.offset.b) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.blur) + 'px',
				$mdgriffith$elm_ui$Internal$Model$formatColorClass(shadow.color)
			]));
};
var $mdgriffith$elm_ui$Internal$Flag$txtShadows = $mdgriffith$elm_ui$Internal$Flag$flag(18);
var $mdgriffith$elm_ui$Element$Font$glow = F2(
	function (clr, i) {
		var shade = {
			blur: i * 2,
			color: clr,
			offset: _Utils_Tuple2(0, 0)
		};
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$txtShadows,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				$mdgriffith$elm_ui$Internal$Model$textShadowClass(shade),
				'text-shadow',
				$mdgriffith$elm_ui$Internal$Model$formatTextShadow(shade)));
	});
var $mdgriffith$elm_ui$Internal$Model$Padding = F5(
	function (a, b, c, d, e) {
		return {$: 'Padding', a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Model$Spaced = F3(
	function (a, b, c) {
		return {$: 'Spaced', a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, _v0) {
				var pad = _v0.a;
				var spacing = _v0.b;
				return _Utils_Tuple2(
					function () {
						if (pad.$ === 'Just') {
							var x = pad.a;
							return pad;
						} else {
							if ((attr.$ === 'StyleClass') && (attr.b.$ === 'PaddingStyle')) {
								var _v3 = attr.b;
								var name = _v3.a;
								var t = _v3.b;
								var r = _v3.c;
								var b = _v3.d;
								var l = _v3.e;
								return $elm$core$Maybe$Just(
									A5($mdgriffith$elm_ui$Internal$Model$Padding, name, t, r, b, l));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}(),
					function () {
						if (spacing.$ === 'Just') {
							var x = spacing.a;
							return spacing;
						} else {
							if ((attr.$ === 'StyleClass') && (attr.b.$ === 'SpacingStyle')) {
								var _v6 = attr.b;
								var name = _v6.a;
								var x = _v6.b;
								var y = _v6.c;
								return $elm$core$Maybe$Just(
									A3($mdgriffith$elm_ui$Internal$Model$Spaced, name, x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}());
			}),
		_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
		attrs);
};
var $mdgriffith$elm_ui$Element$wrappedRow = F2(
	function (attrs, children) {
		var _v0 = $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding(attrs);
		var padded = _v0.a;
		var spaced = _v0.b;
		if (spaced.$ === 'Nothing') {
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asRow,
				$mdgriffith$elm_ui$Internal$Model$div,
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentLeft + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.contentCenterY + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.wrapped)))),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							attrs))),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
		} else {
			var _v2 = spaced.a;
			var spaceName = _v2.a;
			var x = _v2.b;
			var y = _v2.c;
			var newPadding = function () {
				if (padded.$ === 'Just') {
					var _v5 = padded.a;
					var name = _v5.a;
					var t = _v5.b;
					var r = _v5.c;
					var b = _v5.d;
					var l = _v5.e;
					if ((_Utils_cmp(r, x / 2) > -1) && (_Utils_cmp(b, y / 2) > -1)) {
						var newTop = t - (y / 2);
						var newRight = r - (x / 2);
						var newLeft = l - (x / 2);
						var newBottom = b - (y / 2);
						return $elm$core$Maybe$Just(
							A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, newRight, newBottom, newLeft),
									newTop,
									newRight,
									newBottom,
									newLeft)));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}();
			if (newPadding.$ === 'Just') {
				var pad = newPadding.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asRow,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentLeft + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.contentCenterY + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.wrapped)))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								_Utils_ap(
									attrs,
									_List_fromArray(
										[pad]))))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
			} else {
				var halfY = -(y / 2);
				var halfX = -(x / 2);
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					attrs,
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asRow,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentLeft + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.contentCenterY + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.wrapped)))),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											A2(
												$elm$html$Html$Attributes$style,
												'margin',
												$elm$core$String$fromFloat(halfY) + ('px' + (' ' + ($elm$core$String$fromFloat(halfX) + 'px'))))),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Internal$Model$Attr(
												A2(
													$elm$html$Html$Attributes$style,
													'width',
													'calc(100% + ' + ($elm$core$String$fromInt(x) + 'px)'))),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$Attr(
													A2(
														$elm$html$Html$Attributes$style,
														'height',
														'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))),
												A2(
													$elm$core$List$cons,
													A2(
														$mdgriffith$elm_ui$Internal$Model$StyleClass,
														$mdgriffith$elm_ui$Internal$Flag$spacing,
														A3($mdgriffith$elm_ui$Internal$Model$SpacingStyle, spaceName, x, y)),
													_List_Nil))))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(children))
							])));
			}
		}
	});
var $author$project$Page$Contacts$view = function (_v0) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				A2(
				$mdgriffith$elm_ui$Element$paddingXY,
				$author$project$UI$scaled(-4),
				$author$project$UI$scaled(1))
			]),
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(1))
				]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$html(
					$author$project$UI$withGlobalBackgroundImage(require('/build/front/public/background.webp'))),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$heavy,
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
							A2($mdgriffith$elm_ui$Element$Font$glow, $author$project$UI$colors.neutral_100, 2),
							$author$project$UI$fontsizes.huge
						]),
					$mdgriffith$elm_ui$Element$text('Контакты')),
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(1)),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							$author$project$UI$centerJustifiedRowContent,
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 610, $mdgriffith$elm_ui$Element$fill))
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Page$Contacts$contactCard,
							'Офис',
							_List_fromArray(
								[
									$author$project$Page$Contacts$PhoneNumber('+7 (3022) 21-77-17'),
									$author$project$Page$Contacts$Email('ukhoroshaya@gmail.com')
								]),
							require('/build/front/public/img/office.png')),
							A3(
							$author$project$Page$Contacts$contactCard,
							'Дежурный электрик',
							_List_fromArray(
								[
									$author$project$Page$Contacts$PhoneNumber('+7 (914) 130-08-79')
								]),
							require('/build/front/public/img/electrician.png')),
							A3(
							$author$project$Page$Contacts$contactCard,
							'Диспетчер лифта',
							_List_fromArray(
								[
									$author$project$Page$Contacts$PhoneNumber('+7 (924) 271-46-30')
								]),
							require('/build/front/public/img/elevator.png')),
							A3(
							$author$project$Page$Contacts$contactCard,
							'Дежурный сантехник',
							_List_fromArray(
								[
									$author$project$Page$Contacts$PhoneNumber('+7 (914) 460-08-56')
								]),
							require('/build/front/public/img/plumber.png')),
							A3(
							$author$project$Page$Contacts$contactCard,
							'Обслуживание домофонов',
							_List_fromArray(
								[
									$author$project$Page$Contacts$PhoneNumber('+7 (800) 1000-800')
								]),
							require('/build/front/public/img/intercom.png')),
							A3(
							$author$project$Page$Contacts$contactCard,
							'Техподдержка',
							_List_fromArray(
								[
									$author$project$Page$Contacts$Email('support@uk-good.ru')
								]),
							require('/build/front/public/img/support.png'))
						]))
				])));
};
var $author$project$Page$Contacts$page = $author$project$Framework$pageFromStatic(
	$author$project$Framework$StaticPage(
		{title: 'Контакты', view: $author$project$Page$Contacts$view}));
var $author$project$Page$Email$EmailVerification$UpdatedBaseModel = function (a) {
	return {$: 'UpdatedBaseModel', a: a};
};
var $author$project$Page$Email$EmailVerification$GotConfirmationResponse = function (a) {
	return {$: 'GotConfirmationResponse', a: a};
};
var $author$project$Collections$verifyTenantEmail = function (token) {
	return $elm$http$Http$task(
		{
			body: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'token',
							$elm$json$Json$Encode$string(token))
						]))),
			headers: _List_Nil,
			method: 'POST',
			resolver: $author$project$API$stringResolver(
				$elm$core$Basics$always(
					$elm$core$Result$Ok(_Utils_Tuple0))),
			timeout: $author$project$API$timeout,
			url: '/api/collections/tenant/confirm-verification'
		});
};
var $author$project$Page$Email$EmailVerification$init = function (args) {
	return _Utils_Tuple2(
		{
			device: args.device,
			session: args.session,
			state: A2($author$project$API$Resource$Resource, args.emailConfirmationToken, $author$project$API$Status$Loading)
		},
		A2(
			$elm$core$Task$attempt,
			$author$project$Page$Email$EmailVerification$GotConfirmationResponse,
			$author$project$Collections$verifyTenantEmail(args.emailConfirmationToken)));
};
var $author$project$Page$Email$EmailVerification$SessionRefreshed = function (a) {
	return {$: 'SessionRefreshed', a: a};
};
var $author$project$API$Status$Failed = function (a) {
	return {$: 'Failed', a: a};
};
var $author$project$API$Error$LoadingFailure = F2(
	function (code, message) {
		return {code: code, message: message};
	});
var $author$project$API$Resource$failed = F3(
	function (code, errorMessages, _v0) {
		var m = _v0.a;
		var status = _v0.b;
		return A2(
			$author$project$API$Resource$Resource,
			m,
			function () {
				if (status.$ === 'Loading') {
					return $author$project$API$Status$Failed(
						A2($author$project$API$Error$LoadingFailure, code, errorMessages));
				} else {
					var rest = status;
					return rest;
				}
			}());
	});
var $author$project$Session$refresh = F2(
	function (_v0, token) {
		var key = _v0.a;
		var viewer = _v0.b;
		var quux = F2(
			function (cart, fragment) {
				return A2(
					$elm$core$Task$andThen,
					$author$project$Session$fillCache,
					A2(
						$elm$core$Task$andThen,
						function (time) {
							return $elm$http$Http$task(
								{
									body: $elm$http$Http$emptyBody,
									headers: _List_fromArray(
										[
											$author$project$Session$authorizationHeader(token)
										]),
									method: 'POST',
									resolver: $author$project$API$jsonResolver(
										A2(
											$elm$json$Json$Decode$map,
											$author$project$Session$Session(key),
											A2($author$project$Session$authResponseDecoder, time, cart))),
									timeout: $author$project$API$timeout,
									url: '/api/' + (fragment + '/auth-refresh')
								});
						},
						$elm$time$Time$now));
			});
		if (viewer.$ === 'Guest') {
			return A2(quux, _List_Nil, 'collections/tenant');
		} else {
			if (viewer.a.b.$ === 'TenantLocalStorage') {
				var _v2 = viewer.a;
				var cart = _v2.b.a.cart;
				return A2(quux, cart, 'collections/tenant');
			} else {
				var _v3 = viewer.a;
				return A2(quux, _List_Nil, 'collections/manager');
			}
		}
	});
var $author$project$Framework$updateBase = F2(
	function (model, base) {
		return _Utils_update(
			model,
			{device: base.device, session: base.session});
	});
var $author$project$Page$Email$EmailVerification$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'UpdatedBaseModel':
				var base = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Framework$updateBase, model, base),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'GotConfirmationResponse':
				if (msg.a.$ === 'Err') {
					var errorCode = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: A3(
									$author$project$API$Resource$failed,
									errorCode,
									_List_fromArray(
										['Ошибка!']),
									model.state)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: A2($author$project$API$Resource$loaded, _Utils_Tuple0, model.state)
							}),
						function () {
							var _v1 = $author$project$Session$getViewer(model.session);
							if ((_v1.$ === 'Authenticated') && (_v1.a.b.$ === 'TenantLocalStorage')) {
								var _v2 = _v1.a;
								var authToken = _v2.b.a.authToken;
								return A2(
									$elm$core$Task$attempt,
									$author$project$Page$Email$EmailVerification$SessionRefreshed,
									A2($author$project$Session$refresh, model.session, authToken));
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}(),
						$author$project$Framework$NoOp);
				}
			default:
				if (msg.a.$ === 'Err') {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						A2(
							$author$project$Framework$ShowPopUp,
							A2($author$project$PopUp$PlainText, 'Не удалось обновить данные', 'Попробуйте перезагрузить страницу!'),
							$author$project$PopUp$Important));
				} else {
					if (msg.a.a.b.$ === 'Authenticated') {
						var _v3 = msg.a.a;
						var _v4 = _v3.b.a;
						var data = _v4.b;
						return _Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$UpdateLocalStorage(data));
					} else {
						var _v5 = msg.a.a;
						var _v6 = _v5.b;
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$ClearLocalStorage);
					}
				}
		}
	});
var $author$project$Page$Email$EmailVerification$view = function (model) {
	return A2(
		$author$project$UI$Card$contentCard,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$html(
				$author$project$UI$withGlobalBackgroundImage(require('/build/front/public/background.webp'))),
				function () {
				var _v0 = $author$project$API$Resource$toStatus(model.state);
				switch (_v0.$) {
					case 'Loaded':
						return A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$author$project$UI$fontsizes.bigger,
									$mdgriffith$elm_ui$Element$Font$bold,
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
									$mdgriffith$elm_ui$Element$paddingEach(
									{
										bottom: 0,
										left: 0,
										right: 0,
										top: $author$project$UI$scaled(1)
									}),
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$centerY
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Адрес электронной почты успешно подтверждён!')
								]));
					case 'Loading':
						return A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
							$author$project$UI$Loading$spinner);
					case 'NotStarted':
						return A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$author$project$UI$fontsizes.bigger,
									$mdgriffith$elm_ui$Element$Font$bold,
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
									$mdgriffith$elm_ui$Element$paddingEach(
									{
										bottom: 0,
										left: 0,
										right: 0,
										top: $author$project$UI$scaled(1)
									}),
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$centerY
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Что-то пошло не так!')
								]));
					default:
						var message = _v0.a.message;
						return A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							A2(
								$elm$core$List$map,
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$List$singleton,
										$mdgriffith$elm_ui$Element$paragraph(
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$Font$center
												])))),
								message));
				}
			}()
			]));
};
var $author$project$Page$Email$EmailVerification$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Public,
	{
		baseUpdated: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, $author$project$Page$Email$EmailVerification$UpdatedBaseModel),
		init: $author$project$Page$Email$EmailVerification$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: function (_v0) {
			return 'Подтверждение почты';
		},
		update: $author$project$Framework$EffectUpdate($author$project$Page$Email$EmailVerification$update),
		view: $author$project$Framework$PlainView($author$project$Page$Email$EmailVerification$view),
		wrapArgs: F2(
			function (args, extra) {
				return {device: args.device, emailConfirmationToken: extra.emailConfirmationToken, session: args.session};
			})
	});
var $author$project$Page$Error$init = function (args) {
	return _Utils_Tuple2(
		{code: args.code, device: args.device, session: args.session},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Error$title = function (_v0) {
	return 'Ошибка';
};
var $author$project$Page$Error$update = F2(
	function (_v0, model) {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Error$codeToExtraMessage = function (code) {
	switch (code.$) {
		case 'NotFound':
			return $elm$core$Maybe$Just('Если Вы попали сюда по ссылке с другой страницы этого сайта,' + ' сообщите в техподдержку!');
		case 'Forbidden':
			return $elm$core$Maybe$Just('Если Вы попали сюда по ссылке с другой страницы этого сайта,' + ' сообщите в техподдержку!');
		case 'Bug':
			return $elm$core$Maybe$Just('Если это не помогло, сообщите в техподдержку!');
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Error$codeToMessage = function (code) {
	switch (code.$) {
		case 'NotFound':
			return 'Страница не найдена!';
		case 'Forbidden':
			return 'Доступ запрещён!';
		case 'Bug':
			return 'Что-то пошло не так... Перезагрузите страницу!';
		case 'NetworkError':
			return 'Проблемы с сетью. Проверьте подключение к Интернету!';
		case 'Timeout':
			return 'Превышено время ожидания - возможно, соединение слишком медленное.';
		case 'BadRequest':
			return 'Что-то пошло не так... Перезагрузите страницу!';
		default:
			return 'Для доступа к ресурсу необходимо войти в учётную запись';
	}
};
var $mdgriffith$elm_ui$Element$Font$extraBold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.textExtraBold);
var $author$project$Page$Error$view = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(1))
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$author$project$UI$fontsizes.huge,
						$mdgriffith$elm_ui$Element$Font$extraBold,
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
						$mdgriffith$elm_ui$Element$Font$center
					]),
				$elm$core$List$singleton(
					$mdgriffith$elm_ui$Element$text(
						$author$project$Page$Error$codeToMessage(model.code)))),
				function () {
				var _v0 = $author$project$Page$Error$codeToExtraMessage(model.code);
				if (_v0.$ === 'Just') {
					var extraMessage = _v0.a;
					return A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$center]),
						$elm$core$List$singleton(
							$mdgriffith$elm_ui$Element$text(extraMessage)));
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}()
			]));
};
var $author$project$Page$Error$wrap = F2(
	function (_v0, _v1) {
		var session = _v0.session;
		var device = _v0.device;
		var code = _v1.code;
		return {code: code, device: device, session: session};
	});
var $author$project$Page$Error$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Public,
	{
		baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
		init: $author$project$Page$Error$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $author$project$Page$Error$title,
		update: $author$project$Framework$PlainUpdate($author$project$Page$Error$update),
		view: $author$project$Framework$PlainView($author$project$Page$Error$view),
		wrapArgs: $author$project$Page$Error$wrap
	});
var $author$project$Page$Manager$UpdatedBaseModel = function (a) {
	return {$: 'UpdatedBaseModel', a: a};
};
var $author$project$Framework$VerificationRequired = {$: 'VerificationRequired'};
var $author$project$Framework$authenticatedOnly = function (args) {
	var _v0 = $author$project$Session$getViewer(args.session);
	if (_v0.$ === 'Authenticated') {
		if (_v0.a.b.$ === 'TenantLocalStorage') {
			var _v1 = _v0.a;
			var user = _v1.b.a.user;
			return user.domain.verified ? $author$project$Framework$AccessGranted : $author$project$Framework$VerificationRequired;
		} else {
			var _v2 = _v0.a;
			return $author$project$Framework$AccessGranted;
		}
	} else {
		return $author$project$Framework$AuthenticationRequired;
	}
};
var $author$project$Page$Manager$GotManagerData = function (a) {
	return {$: 'GotManagerData', a: a};
};
var $author$project$Collections$getManager = F2(
	function (authHeader, managerID) {
		return $elm$http$Http$task(
			{
				body: $elm$http$Http$emptyBody,
				headers: _List_fromArray(
					[authHeader]),
				method: 'GET',
				resolver: $author$project$API$jsonResolver(
					$author$project$Domain$PB$decoderWithID($author$project$Domain$managerDecoder)),
				timeout: $author$project$API$timeout,
				url: '/api/collections/manager/records/' + managerID
			});
	});
var $author$project$Page$Manager$init = function (args) {
	var _default = {
		device: args.device,
		manager: A2($author$project$API$Resource$Resource, args.managerID, $author$project$API$Status$Loading),
		session: args.session
	};
	var _v0 = args.session;
	if (_v0.b.$ === 'Authenticated') {
		if (_v0.b.a.b.$ === 'ManagerLocalStorage') {
			var _v1 = _v0.b.a;
			var manager = _v1.b.a.manager;
			return _Utils_Tuple2(
				_Utils_update(
					_default,
					{
						manager: A2($author$project$API$Resource$loaded, manager, _default.manager)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			var _v2 = _v0.b.a;
			var authToken = _v2.b.a.authToken;
			return _Utils_Tuple2(
				_default,
				A2(
					$elm$core$Task$attempt,
					$author$project$Page$Manager$GotManagerData,
					A2(
						$author$project$Collections$getManager,
						$author$project$Session$authorizationHeader(authToken),
						args.managerID)));
		}
	} else {
		return _Utils_Tuple2(_default, $elm$core$Platform$Cmd$none);
	}
};
var $elm$browser$Browser$Navigation$reload = _Browser_reload(false);
var $author$project$Page$Manager$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'GotManagerData':
				if (msg.a.$ === 'Ok') {
					var manager = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								manager: A2($author$project$API$Resource$loaded, manager, model.manager)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								manager: A2(
									$author$project$API$Resource$Resource,
									$author$project$API$Resource$toMeta(model.manager),
									$author$project$API$Status$NotStarted)
							}),
						$elm$core$Platform$Cmd$none,
						A2(
							$author$project$Framework$ShowPopUp,
							A2($author$project$PopUp$PlainText, 'Ошибка!', 'Не удалось получить данные о сотруднике. Попробуйте перезагрузить страницу!'),
							$author$project$PopUp$Important));
				}
			case 'LoggedOut':
				return _Utils_Tuple3(model, $elm$browser$Browser$Navigation$reload, $author$project$Framework$ClearLocalStorage);
			default:
				var baseModel = msg.a;
				var session = baseModel.session;
				var newModel = A2($author$project$Framework$updateBase, model, baseModel);
				if (_Utils_eq(
					$author$project$Session$getViewer(session),
					$author$project$Session$getViewer(model.session))) {
					return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
				} else {
					var _v1 = $author$project$Session$getViewer(session);
					if (_v1.$ === 'Authenticated') {
						if (_v1.a.b.$ === 'ManagerLocalStorage') {
							var _v2 = _v1.a;
							var authToken = _v2.b.a.authToken;
							var manager = _v2.b.a.manager;
							if (_Utils_eq(
								$author$project$API$Resource$toMeta(newModel.manager),
								manager.meta.id)) {
								return _Utils_Tuple3(
									_Utils_update(
										newModel,
										{
											manager: A2($author$project$API$Resource$loaded, manager, newModel.manager)
										}),
									$elm$core$Platform$Cmd$none,
									$author$project$Framework$NoOp);
							} else {
								var _v3 = $author$project$API$Resource$toMaybe(model.manager);
								if (_v3.$ === 'Just') {
									return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
								} else {
									return _Utils_Tuple3(
										newModel,
										A2(
											$elm$core$Task$attempt,
											$author$project$Page$Manager$GotManagerData,
											A2(
												$author$project$Collections$getManager,
												$author$project$Session$authorizationHeader(authToken),
												$author$project$API$Resource$toMeta(model.manager))),
										$author$project$Framework$NoOp);
								}
							}
						} else {
							var _v4 = _v1.a;
							var authToken = _v4.b.a.authToken;
							return _Utils_Tuple3(
								_Utils_update(
									newModel,
									{
										manager: A2(
											$author$project$API$Resource$Resource,
											$author$project$API$Resource$toMeta(model.manager),
											$author$project$API$Status$Loading)
									}),
								A2(
									$elm$core$Task$attempt,
									$author$project$Page$Manager$GotManagerData,
									A2(
										$author$project$Collections$getManager,
										$author$project$Session$authorizationHeader(authToken),
										$author$project$API$Resource$toMeta(model.manager))),
								$author$project$Framework$NoOp);
						}
					} else {
						return _Utils_Tuple3(newModel, $elm$browser$Browser$Navigation$reload, $author$project$Framework$NoOp);
					}
				}
		}
	});
var $mdgriffith$elm_ui$Element$map = $mdgriffith$elm_ui$Internal$Model$map;
var $author$project$Page$Manager$LoggedOut = {$: 'LoggedOut'};
var $author$project$UI$TooltipBelow = {$: 'TooltipBelow'};
var $author$project$UI$TooltipLeft = {$: 'TooltipLeft'};
var $author$project$UI$TooltipPosition = F2(
	function (a, b) {
		return {$: 'TooltipPosition', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Bottom = {$: 'Bottom'};
var $mdgriffith$elm_ui$Element$alignBottom = $mdgriffith$elm_ui$Internal$Model$AlignY($mdgriffith$elm_ui$Internal$Model$Bottom);
var $mdgriffith$elm_ui$Internal$Model$Above = {$: 'Above'};
var $mdgriffith$elm_ui$Element$above = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, $mdgriffith$elm_ui$Internal$Model$Above, element);
};
var $mdgriffith$elm_ui$Internal$Model$Left = {$: 'Left'};
var $mdgriffith$elm_ui$Element$alignLeft = $mdgriffith$elm_ui$Internal$Model$AlignX($mdgriffith$elm_ui$Internal$Model$Left);
var $mdgriffith$elm_ui$Element$Font$semiBold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.textSemiBold);
var $author$project$UI$withTooltip = F3(
	function (_v0, text, attrs) {
		var vertical = _v0.a;
		var horizontal = _v0.b;
		var wideSide = function () {
			if (vertical.$ === 'TooltipAbove') {
				return 'border-top';
			} else {
				return 'border-bottom';
			}
		}();
		var triangle = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 10, 0),
					function () {
					switch (horizontal.$) {
						case 'TooltipLeft':
							return $mdgriffith$elm_ui$Element$alignRight;
						case 'TooltipCenter':
							return $mdgriffith$elm_ui$Element$centerX;
						default:
							return $mdgriffith$elm_ui$Element$alignLeft;
					}
				}()
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(0)),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(0)),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'border-left', '7px solid transparent')),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'border-right', '7px solid transparent')),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, wideSide, '7px solid white'))
					]),
				$mdgriffith$elm_ui$Element$none));
		var horizontalClass = function () {
			switch (horizontal.$) {
				case 'TooltipLeft':
					return 'tooltip-left';
				case 'TooltipCenter':
					return 'tooltip-center';
				default:
					return 'tooltip-right';
			}
		}();
		var _v1 = function () {
			if (vertical.$ === 'TooltipAbove') {
				return _Utils_Tuple2($mdgriffith$elm_ui$Element$above, $mdgriffith$elm_ui$Element$below);
			} else {
				return _Utils_Tuple2($mdgriffith$elm_ui$Element$below, $mdgriffith$elm_ui$Element$above);
			}
		}();
		var textPosition = _v1.a;
		var trianglePosition = _v1.b;
		return _Utils_ap(
			attrs,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$elm$html$Html$Attributes$class('with-tooltip')),
					textPosition(
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$class('tooltip')),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$class(horizontalClass)),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'filter',
									'drop-shadow(0 0 1px ' + ($author$project$UI$colorToHex($author$project$UI$colors.neutral_60) + ')'))),
								trianglePosition(triangle)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_Utils_ap(
								$author$project$UI$border($author$project$UI$colors.neutral_0),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$padding(
										$author$project$UI$scaled(-1)),
										$mdgriffith$elm_ui$Element$Font$semiBold,
										$author$project$UI$fontsizes.small,
										$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_0),
										$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60)
									])),
							$mdgriffith$elm_ui$Element$text(text))))
				]));
	});
var $author$project$Page$Manager$viewManager = F2(
	function (model, record) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$spacing(12),
					$mdgriffith$elm_ui$Element$padding(
					$author$project$UI$scaled(1))
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-1))
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$author$project$UI$fontsizes.bigger]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(record.domain.name)
								])),
							function () {
							var _v0 = $author$project$Session$getViewer(model.session);
							if ((_v0.$ === 'Authenticated') && (_v0.a.b.$ === 'ManagerLocalStorage')) {
								var _v1 = _v0.a;
								var manager = _v1.b.a.manager;
								return _Utils_eq(manager.meta.id, record.meta.id) ? A2(
									$mdgriffith$elm_ui$Element$Input$button,
									A3(
										$author$project$UI$withTooltip,
										A2($author$project$UI$TooltipPosition, $author$project$UI$TooltipBelow, $author$project$UI$TooltipLeft),
										'Выход',
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.bad),
												$mdgriffith$elm_ui$Element$alignBottom
											])),
									{
										label: A2(
											$author$project$UI$materialIcon,
											'logout',
											$author$project$UI$scaled(4)),
										onPress: $elm$core$Maybe$Just($author$project$Page$Manager$LoggedOut)
									}) : $mdgriffith$elm_ui$Element$none;
							} else {
								return $mdgriffith$elm_ui$Element$none;
							}
						}()
						])),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_Nil,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(record.domain.role)
						])),
					function () {
					var _v2 = record.domain.email;
					if (_v2.$ === 'Just') {
						var email = _v2.a;
						return $mdgriffith$elm_ui$Element$text(email);
					} else {
						return $mdgriffith$elm_ui$Element$none;
					}
				}()
				]));
	});
var $author$project$Page$Manager$view = function (model) {
	var _v0 = $author$project$API$Resource$toStatus(model.manager);
	switch (_v0.$) {
		case 'NotStarted':
			return $mdgriffith$elm_ui$Element$text('Ошибка, перезагрузите страницу');
		case 'Loading':
			return A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, $author$project$UI$Loading$spinner);
		case 'Failed':
			return $mdgriffith$elm_ui$Element$text('Не удалось получить данные');
		default:
			var manager = _v0.a;
			return A2($author$project$Page$Manager$viewManager, model, manager);
	}
};
var $author$project$Page$Manager$wrap = F2(
	function (_v0, _v1) {
		var session = _v0.session;
		var device = _v0.device;
		var managerID = _v1.managerID;
		return {device: device, managerID: managerID, session: session};
	});
var $author$project$Page$Manager$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Protected($author$project$Framework$authenticatedOnly),
	{
		baseUpdated: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, $author$project$Page$Manager$UpdatedBaseModel),
		init: $author$project$Page$Manager$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $elm$core$Basics$always('Профиль сотрудника'),
		update: $author$project$Framework$EffectUpdate($author$project$Page$Manager$update),
		view: $author$project$Framework$PlainView($author$project$Page$Manager$view),
		wrapArgs: $author$project$Page$Manager$wrap
	});
var $author$project$Page$ManagerAuth$accessFilter = function (_v0) {
	var session = _v0.session;
	var _v1 = $author$project$Session$getViewer(session);
	if (_v1.$ === 'Authenticated') {
		return $author$project$Framework$Skip;
	} else {
		return $author$project$Framework$AccessGranted;
	}
};
var $author$project$Page$ManagerAuth$UpdatedEmailField = function (a) {
	return {$: 'UpdatedEmailField', a: a};
};
var $author$project$Page$ManagerAuth$UpdatedPasswordField = function (a) {
	return {$: 'UpdatedPasswordField', a: a};
};
var $author$project$Page$ManagerAuth$validateEmail = function (email) {
	return A2(
		$elm$regex$Regex$contains,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('^.+@.+\\..+$')),
		email);
};
var $author$project$Page$ManagerAuth$validatePassword = function (pwd) {
	return A2(
		$elm$core$List$all,
		$elm$core$Basics$identity,
		A2(
			$elm$core$List$cons,
			$elm$core$String$length(pwd) >= 8,
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$regex$Regex$fromString,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$withDefault($elm$regex$Regex$never),
						function (expression) {
							return A2($elm$regex$Regex$contains, expression, pwd);
						})),
				_List_fromArray(
					['\\d', '[A-Z]', '[a-z]', '[!?,./\\()*^%&<>`~\'\"-+{}|\\[\\]]']))));
};
var $author$project$Page$ManagerAuth$defaultFields = {
	email: function () {
		var _default = $author$project$UI$Form$Text$default($author$project$Page$ManagerAuth$UpdatedEmailField);
		return _Utils_update(
			_default,
			{
				check: A2($author$project$UI$Form$simpleCheck, $author$project$Page$ManagerAuth$validateEmail, 'Неверный формат email!'),
				focusedOnLoad: true,
				hint: $elm$core$Maybe$Just('example@gmail.com'),
				kind: $author$project$UI$Form$Text$Regular($mdgriffith$elm_ui$Element$Input$email),
				name: 'Адрес электронной почты',
				required: $author$project$UI$Form$Required
			});
	}(),
	password: function () {
		var _default = $author$project$UI$Form$Text$default($author$project$Page$ManagerAuth$UpdatedPasswordField);
		return _Utils_update(
			_default,
			{
				check: A2($author$project$UI$Form$simpleCheck, $author$project$Page$ManagerAuth$validatePassword, 'Пароль должен быть длиннее 7 символов и содержать ' + ('строчные и заглавные латинские буквы, цифры ' + 'и знаки препинания')),
				kind: A2($author$project$UI$Form$Text$Password, false, $mdgriffith$elm_ui$Element$Input$currentPassword),
				name: 'Пароль',
				required: $author$project$UI$Form$Required
			});
	}()
};
var $author$project$Page$ManagerAuth$init = function (args) {
	return _Utils_Tuple2(
		{device: args.device, email: $author$project$Page$ManagerAuth$defaultFields.email, error: $elm$core$Maybe$Nothing, next: args.next, password: $author$project$Page$ManagerAuth$defaultFields.password, session: args.session, showPasswords: false},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$ManagerAuth$title = function (_v0) {
	return 'Вход для сотрудников | УК \"Хорошая\"';
};
var $author$project$Page$ManagerAuth$GotLogInResponse = function (a) {
	return {$: 'GotLogInResponse', a: a};
};
var $author$project$Session$managerLogIn = F2(
	function (_v0, _v1) {
		var key = _v0.a;
		var credentials = _v1.a;
		return A2(
			$elm$core$Task$andThen,
			function (time) {
				return $elm$http$Http$task(
					{
						body: $elm$http$Http$jsonBody(
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'identity',
										$elm$json$Json$Encode$string(credentials.email)),
										_Utils_Tuple2(
										'password',
										$elm$json$Json$Encode$string(credentials.password))
									]))),
						headers: _List_Nil,
						method: 'POST',
						resolver: $author$project$API$jsonResolver(
							A2(
								$elm$json$Json$Decode$map,
								$author$project$Session$Session(key),
								A2($author$project$Session$authResponseDecoder, time, _List_Nil))),
						timeout: $author$project$API$timeout,
						url: '/api/collections/manager/auth-with-password'
					});
			},
			$elm$time$Time$now);
	});
var $author$project$Page$ManagerAuth$update = F2(
	function (msg, form) {
		var withError = function (message) {
			return _Utils_Tuple3(
				_Utils_update(
					form,
					{
						error: $elm$core$Maybe$Just(message)
					}),
				$elm$core$Platform$Cmd$none,
				$author$project$Framework$NoOp);
		};
		var _v0 = _Utils_Tuple2(form.email, form.password);
		var emailSpecs = _v0.a;
		var passwordSpecs = _v0.b;
		switch (msg.$) {
			case 'UpdatedEmailField':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{email: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'UpdatedPasswordField':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{password: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'SetShowPassword':
				var show = msg.a;
				var updatedForm = F2(
					function (specs, inputFunction) {
						return _Utils_update(
							specs,
							{
								kind: A2($author$project$UI$Form$Text$Password, show, inputFunction)
							});
					});
				return _Utils_Tuple3(
					_Utils_update(
						form,
						{
							password: A2(updatedForm, passwordSpecs, $mdgriffith$elm_ui$Element$Input$newPassword),
							showPasswords: show
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'Submitted':
				var _v2 = _Utils_Tuple2(
					$author$project$UI$Form$result(emailSpecs),
					$author$project$UI$Form$result(passwordSpecs));
				if ((_v2.a.$ === 'WithResult') && (_v2.b.$ === 'WithResult')) {
					var email = _v2.a.a;
					var password = _v2.b.a;
					return _Utils_Tuple3(
						form,
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$ManagerAuth$GotLogInResponse,
							A2(
								$author$project$Session$managerLogIn,
								form.session,
								$author$project$Session$LogIn(
									{email: email, password: password}))),
						$author$project$Framework$NoOp);
				} else {
					return withError('Проверьте правильность заполнения формы!');
				}
			case 'GotLogInResponse':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var session = result.a;
					if (session.b.$ === 'Authenticated') {
						var key = session.a;
						var _v5 = session.b.a;
						var lsData = _v5.b;
						return _Utils_Tuple3(
							_Utils_update(
								form,
								{session: session}),
							A2($elm$browser$Browser$Navigation$pushUrl, key, form.next),
							$author$project$Framework$UpdateLocalStorage(lsData));
					} else {
						return _Utils_Tuple3(form, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
					}
				} else {
					var err = result.a;
					switch (err.$) {
						case 'Timeout':
							return withError('Превышено время ожидания ответа! Попробуйте ещё раз');
						case 'NetworkError':
							return withError('Нет соединения с сервером! Проверьте подключение к сети.');
						case 'BadRequest':
							return withError('Неверная комбинация email и пароля');
						default:
							return withError('Непредвиденная ошибка! Сообщите в поддержку.');
					}
				}
			default:
				return _Utils_Tuple3(form, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
		}
	});
var $author$project$Page$ManagerAuth$SetShowPassword = function (a) {
	return {$: 'SetShowPassword', a: a};
};
var $author$project$Page$ManagerAuth$Submitted = {$: 'Submitted'};
var $author$project$Page$ManagerAuth$view = function (model) {
	var withSubmissionButton = function (label) {
		return $author$project$UI$Form$ifValid(
			A3(
				$author$project$UI$Form$withButton,
				true,
				$elm$core$Maybe$Nothing,
				A4(
					$author$project$UI$Button$ButtonSpecs,
					$elm$core$Maybe$Just($author$project$Page$ManagerAuth$Submitted),
					$mdgriffith$elm_ui$Element$text(label),
					$author$project$UI$Button$Filled,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]))));
	};
	var visibilityButton = function (show) {
		return A4(
			$author$project$UI$Button$ButtonSpecs,
			$elm$core$Maybe$Just(
				$author$project$Page$ManagerAuth$SetShowPassword(show)),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_Nil,
				A2(
					$author$project$UI$materialIcon,
					show ? 'visibility' : 'visibility_off',
					32)),
			$author$project$UI$Button$Outline,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(
					$author$project$UI$scaled(-3)),
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60)
				]));
	};
	var withFieldAndVisibilityButton = function (field) {
		return A3(
			$author$project$UI$Form$withRow,
			false,
			_List_Nil,
			A4(
				$author$project$UI$Form$withButton,
				false,
				$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{
									bottom: 0,
									left: 0,
									right: 0,
									top: $author$project$UI$scaled(3)
								}),
								$mdgriffith$elm_ui$Element$alignTop
							]))),
				visibilityButton(!model.showPasswords),
				A2($author$project$UI$Form$withField, field, $author$project$UI$Form$empty)));
	};
	var linkToTenantAuth = A2(
		$mdgriffith$elm_ui$Element$link,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(6),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
				$mdgriffith$elm_ui$Element$Font$underline,
				$mdgriffith$elm_ui$Element$Font$center,
				$mdgriffith$elm_ui$Element$focused(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$scale(1.1)
					]))
			]),
		{
			label: A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Вход для жильцов')
					])),
			url: '/auth'
		});
	var loginContent = A2(
		$author$project$UI$Form$withUnder,
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			_List_fromArray(
				[
					linkToTenantAuth,
					$author$project$UI$Form$errorCard(model.error)
				])),
		A2(
			withSubmissionButton,
			'Вход',
			A2(
				withFieldAndVisibilityButton,
				model.password,
				A2(
					$author$project$UI$Form$withField,
					model.email,
					A2($author$project$UI$Form$withTitle, 'Вход для сотрудников', $author$project$UI$Form$empty)))));
	return A2(
		$author$project$UI$Form$form,
		_List_Nil,
		A2(
			$author$project$UI$Form$withExternal,
			$mdgriffith$elm_ui$Element$html(
				$author$project$UI$withGlobalBackgroundImage(require('/build/front/public/background.webp'))),
			loginContent));
};
var $author$project$Page$ManagerAuth$wrap = F2(
	function (base, args) {
		return {device: base.device, next: args.next, session: base.session};
	});
var $author$project$Page$ManagerAuth$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Protected($author$project$Page$ManagerAuth$accessFilter),
	{
		baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
		init: $author$project$Page$ManagerAuth$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $author$project$Page$ManagerAuth$title,
		update: $author$project$Framework$EffectUpdate($author$project$Page$ManagerAuth$update),
		view: $author$project$Framework$PlainView($author$project$Page$ManagerAuth$view),
		wrapArgs: $author$project$Page$ManagerAuth$wrap
	});
var $author$project$Page$MinibusRoute$UpdatedBaseModel = function (a) {
	return {$: 'UpdatedBaseModel', a: a};
};
var $author$project$Page$MinibusRoute$init = function (args) {
	return _Utils_Tuple2(
		{device: args.device, session: args.session, stop: args.stop},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$MinibusRoute$update = F2(
	function (_v0, model) {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$MinibusRoute$view = function (_v0) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
			]),
		$mdgriffith$elm_ui$Element$html(
			A3($elm$html$Html$node, 'minibus-map', _List_Nil, _List_Nil)));
};
var $author$project$Page$MinibusRoute$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Public,
	{
		baseUpdated: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, $author$project$Page$MinibusRoute$UpdatedBaseModel),
		init: $author$project$Page$MinibusRoute$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $elm$core$Basics$always('Маршрутки'),
		update: $author$project$Framework$PlainUpdate($author$project$Page$MinibusRoute$update),
		view: $author$project$Framework$PlainView($author$project$Page$MinibusRoute$view),
		wrapArgs: F2(
			function (base, args) {
				return {device: base.device, session: base.session, stop: args.stop};
			})
	});
var $author$project$Page$Notification$UpdatedBaseModel = function (a) {
	return {$: 'UpdatedBaseModel', a: a};
};
var $author$project$Page$Notification$CategoryPickerSources = F3(
	function (buildings, flats, tenants) {
		return {buildings: buildings, flats: flats, tenants: tenants};
	});
var $author$project$Page$Notification$EditFormState = F6(
	function (title, category, targets, content, search, contentState) {
		return {category: category, content: content, contentState: contentState, search: search, targets: targets, title: title};
	});
var $author$project$Page$Notification$New = function (a) {
	return {$: 'New', a: a};
};
var $author$project$Domain$PublicNotification = {$: 'PublicNotification'};
var $author$project$Page$Notification$Ready = {$: 'Ready'};
var $author$project$Page$Notification$Viewing = F2(
	function (a, b) {
		return {$: 'Viewing', a: a, b: b};
	});
var $author$project$Page$Notification$GotNotificationData = function (a) {
	return {$: 'GotNotificationData', a: a};
};
var $author$project$Domain$Notification = F7(
	function (title, content, rawContent, attachments, category, published, target) {
		return {attachments: attachments, category: category, content: content, published: published, rawContent: rawContent, target: target, title: title};
	});
var $author$project$Utils$decodeFilePathList = function (field) {
	return A4(
		$elm$json$Json$Decode$map3,
		F3(
			function (collectionId, recordId, cs) {
				return A2(
					$elm$core$List$map,
					$elm$core$String$join('/'),
					A2(
						$elm$core$List$map,
						$elm$core$Basics$append(
							_List_fromArray(
								['/api/files', collectionId, recordId])),
						A2($elm$core$List$map, $elm$core$List$singleton, cs)));
			}),
		A2($elm$json$Json$Decode$field, 'collectionId', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			field,
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
};
var $author$project$Domain$BuildingNotification = {$: 'BuildingNotification'};
var $author$project$Domain$FlatNotification = {$: 'FlatNotification'};
var $author$project$Domain$PersonalNotification = {$: 'PersonalNotification'};
var $author$project$Domain$notificationCategoryDecoder = function (category) {
	switch (category) {
		case 'public':
			return $elm$json$Json$Decode$succeed($author$project$Domain$PublicNotification);
		case 'personal':
			return $elm$json$Json$Decode$succeed($author$project$Domain$PersonalNotification);
		case 'flats':
			return $elm$json$Json$Decode$succeed($author$project$Domain$FlatNotification);
		case 'buildings':
			return $elm$json$Json$Decode$succeed($author$project$Domain$BuildingNotification);
		default:
			return $elm$json$Json$Decode$fail('invalid notification category: ' + category);
	}
};
var $author$project$Domain$Buildings = F2(
	function (a, b) {
		return {$: 'Buildings', a: a, b: b};
	});
var $author$project$Domain$Flats = F2(
	function (a, b) {
		return {$: 'Flats', a: a, b: b};
	});
var $author$project$Domain$Personal = F2(
	function (a, b) {
		return {$: 'Personal', a: a, b: b};
	});
var $author$project$Domain$Public = {$: 'Public'};
var $author$project$Domain$notificationTargetDecoder = function (category) {
	switch (category.$) {
		case 'PublicNotification':
			return $elm$json$Json$Decode$succeed($author$project$Domain$Public);
		case 'PersonalNotification':
			return A2(
				$elm$json$Json$Decode$map,
				function (x) {
					return A2(
						$author$project$Domain$Personal,
						x,
						$elm$core$List$length(x));
				},
				A2(
					$elm$json$Json$Decode$field,
					'people',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
		case 'FlatNotification':
			return A2(
				$elm$json$Json$Decode$map,
				function (x) {
					return A2(
						$author$project$Domain$Flats,
						x,
						$elm$core$List$length(x));
				},
				A2(
					$elm$json$Json$Decode$field,
					'flats',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
		default:
			return A2(
				$elm$json$Json$Decode$map,
				function (x) {
					return A2(
						$author$project$Domain$Buildings,
						x,
						$elm$core$List$length(x));
				},
				A2(
					$elm$json$Json$Decode$field,
					'buildings',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
	}
};
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 'Element', a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 'Bad', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 'Good', a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = function (a) {
	return {$: 'Parser', a: a};
};
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parseA(s0);
				if (_v1.$ === 'Bad') {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p1 = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					var _v2 = callback(a);
					var parseB = _v2.a;
					var _v3 = parseB(s1);
					if (_v3.$ === 'Bad') {
						var p2 = _v3.a;
						var x = _v3.b;
						return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
					} else {
						var p2 = _v3.a;
						var b = _v3.b;
						var s2 = _v3.c;
						return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
					}
				}
			});
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0.a;
	return $elm$parser$Parser$Advanced$Parser(
		function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 'Bad') {
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, false, x);
			} else {
				var a = _v1.b;
				var s1 = _v1.c;
				return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
			}
		});
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$UnexpectedChar = {$: 'UnexpectedChar'};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 'AddRight', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {col: col, contextStack: contextStack, problem: problem, row: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 'Empty'};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.row, s.col, x, s.context));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return $elm$parser$Parser$Advanced$Parser(
			function (s) {
				var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.offset, s.src);
				return _Utils_eq(newOffset, -1) ? A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
					$elm$parser$Parser$Advanced$Good,
					true,
					_Utils_Tuple0,
					{col: 1, context: s.context, indent: s.indent, offset: s.offset + 1, row: s.row + 1, src: s.src}) : A3(
					$elm$parser$Parser$Advanced$Good,
					true,
					_Utils_Tuple0,
					{col: s.col + 1, context: s.context, indent: s.indent, offset: newOffset, row: s.row, src: s.src}));
			});
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.src);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.offset, offset) < 0,
					_Utils_Tuple0,
					{col: col, context: s0.context, indent: s0.indent, offset: offset, row: row, src: s0.src});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.offset, s.row, s.col, s);
		});
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0.a;
		var parseB = _v1.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v2 = parseA(s0);
				if (_v2.$ === 'Bad') {
					var p = _v2.a;
					var x = _v2.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p1 = _v2.a;
					var a = _v2.b;
					var s1 = _v2.c;
					var _v3 = parseB(s1);
					if (_v3.$ === 'Bad') {
						var p2 = _v3.a;
						var x = _v3.b;
						return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
					} else {
						var p2 = _v3.a;
						var b = _v3.b;
						var s2 = _v3.c;
						return A3(
							$elm$parser$Parser$Advanced$Good,
							p1 || p2,
							A2(func, a, b),
							s2);
					}
				}
			});
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parse(s0);
				if (_v1.$ === 'Bad') {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p,
						A2(
							func,
							A3($elm$core$String$slice, s0.offset, s1.offset, s0.src),
							a),
						s1);
				}
			});
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return _Utils_eq(
		c,
		_Utils_chr(' ')) || (_Utils_eq(
		c,
		_Utils_chr('\t')) || (_Utils_eq(
		c,
		_Utils_chr('\n')) || (_Utils_eq(
		c,
		_Utils_chr('\u000D')) || (_Utils_eq(
		c,
		_Utils_chr('\u000C')) || _Utils_eq(
		c,
		_Utils_chr('\u00A0'))))));
};
var $elm$parser$Parser$Problem = function (a) {
	return {$: 'Problem', a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		});
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A3($elm$parser$Parser$Advanced$Good, false, a, s);
		});
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toLower = _String_toLower;
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(_Utils_Tuple0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (!_Utils_eq(
						c,
						_Utils_chr('>')));
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('<'))),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('/')))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq(
				_Utils_chr('>'))));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 'Comment', a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.offset, s.row, s.col, s.src);
			var newOffset = _v1.a;
			var newRow = _v1.b;
			var newCol = _v1.c;
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.context)) : A3(
				$elm$parser$Parser$Advanced$Good,
				_Utils_cmp(s.offset, newOffset) < 0,
				_Utils_Tuple0,
				{col: newCol, context: s.context, indent: s.indent, offset: newOffset, row: newRow, src: s.src});
		});
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 'Expecting', a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 'Token', a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.offset, s.row, s.col, s.src);
			var newOffset = _v1.a;
			var newRow = _v1.b;
			var newCol = _v1.c;
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
				$elm$parser$Parser$Advanced$Good,
				progress,
				_Utils_Tuple0,
				{col: newCol, context: s.context, indent: s.indent, offset: newOffset, row: newRow, src: s.src});
		});
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$commentString = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parse(s0);
				if (_v1.$ === 'Good') {
					var p = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p,
						func(a),
						s1);
				} else {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				}
			});
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $hecrj$html_parser$Html$Parser$comment = A2($elm$parser$Parser$map, $hecrj$html_parser$Html$Parser$Comment, $hecrj$html_parser$Html$Parser$commentString);
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $elm$parser$Parser$Done = function (a) {
	return {$: 'Done', a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 'Loop', a: a};
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0.a;
			var _v1 = parse(s0);
			if (_v1.$ === 'Good') {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (step.$ === 'Loop') {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return $elm$parser$Parser$Advanced$Parser(
			function (s) {
				return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
			});
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 'Done', a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 'Loop', a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (step.$ === 'Loop') {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 'Append', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (_v1.$ === 'Good') {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
		});
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((!_Utils_eq(
		c,
		_Utils_chr('\"'))) && ((!_Utils_eq(
		c,
		_Utils_chr('\''))) && ((!_Utils_eq(
		c,
		_Utils_chr('>'))) && ((!_Utils_eq(
		c,
		_Utils_chr('/'))) && (!_Utils_eq(
		c,
		_Utils_chr('=')))))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(
		_Utils_chr(';')));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$Char$fromCode = _Char_fromCode;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char.valueOf()) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (ra.$ === 'Ok') {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (_v0.$ === 'Ok') {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 'ExpectingInt'};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {col: s.col + (newOffset - s.offset), context: s.context, indent: s.indent, offset: newOffset, row: s.row, src: s.src};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 'Err') {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.offset, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$core$String$toFloat = _String_toFloat;
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.src);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.row, s.col - (floatOffset + s.offset), invalid, s.context));
		} else {
			if (_Utils_eq(s.offset, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.offset, intPair, s);
				} else {
					if (floatSettings.$ === 'Err') {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.offset, floatOffset, s.src));
						if (_v1.$ === 'Nothing') {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.offset, s.src)) {
				var zeroOffset = s.offset + 1;
				var baseOffset = zeroOffset + 1;
				return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.src) ? A5(
					$elm$parser$Parser$Advanced$finalizeInt,
					c.invalid,
					c.hex,
					baseOffset,
					A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.src),
					s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.src) ? A5(
					$elm$parser$Parser$Advanced$finalizeInt,
					c.invalid,
					c.octal,
					baseOffset,
					A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.src),
					s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.src) ? A5(
					$elm$parser$Parser$Advanced$finalizeInt,
					c.invalid,
					c.binary,
					baseOffset,
					A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.src),
					s) : A6(
					$elm$parser$Parser$Advanced$finalizeFloat,
					c.invalid,
					c.expecting,
					c._int,
					c._float,
					_Utils_Tuple2(zeroOffset, 0),
					s)));
			} else {
				return A6(
					$elm$parser$Parser$Advanced$finalizeFloat,
					c.invalid,
					c.expecting,
					c._int,
					c._float,
					A3($elm$parser$Parser$Advanced$consumeBase, 10, s.offset, s.src),
					s);
			}
		});
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				binary: $elm$core$Result$Err(invalid),
				expecting: expecting,
				_float: $elm$core$Result$Err(invalid),
				hex: $elm$core$Result$Err(invalid),
				_int: $elm$core$Result$Ok($elm$core$Basics$identity),
				invalid: invalid,
				octal: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return _Utils_eq(
								c,
								_Utils_chr('x')) || _Utils_eq(
								c,
								_Utils_chr('X'));
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(
							_Utils_chr('0')))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(
					_Utils_chr('#')))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq(
				_Utils_chr('&')))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (!_Utils_eq(
			c,
			_Utils_chr('&')));
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((!_Utils_eq(
			c,
			_Utils_chr('\"'))) && ((!_Utils_eq(
			c,
			_Utils_chr('\''))) && ((!_Utils_eq(
			c,
			_Utils_chr('='))) && ((!_Utils_eq(
			c,
			_Utils_chr('<'))) && ((!_Utils_eq(
			c,
			_Utils_chr('>'))) && ((!_Utils_eq(
			c,
			_Utils_chr('`'))) && (!_Utils_eq(
			c,
			_Utils_chr('&')))))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('=')))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue(
						_Utils_chr('\"')),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue(
						_Utils_chr('\''))
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || _Utils_eq(
						c,
						_Utils_chr('-'));
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 'Text', a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (!_Utils_eq(
								c,
								_Utils_chr('<'))) && (!_Utils_eq(
								c,
								_Utils_chr('&')));
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq(
									_Utils_chr('/'))),
								$elm$parser$Parser$succeed(_Utils_Tuple0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq(
						_Utils_chr('>')))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('>')))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('<')))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
try {
	var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
	$hecrj$html_parser$Html$Parser$cyclic$node = function () {
		return $hecrj$html_parser$Html$Parser$node;
	};
	var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
	$hecrj$html_parser$Html$Parser$cyclic$element = function () {
		return $hecrj$html_parser$Html$Parser$element;
	};
} catch ($) {
	throw 'Some top-level definitions from `Html.Parser` are causing infinite recursion:\n\n  ┌─────┐\n  │    node\n  │     ↓\n  │    element\n  └─────┘\n\nThese errors are very tricky, so read https://elm-lang.org/0.19.1/bad-recursion to learn how to fix it!';}
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {col: col, problem: problem, row: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.row, p.col, p.problem);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 'Empty':
					return list;
				case 'AddRight':
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0.a;
		var _v1 = parse(
			{col: 1, context: _List_Nil, indent: 1, offset: 0, row: 1, src: src});
		if (_v1.$ === 'Good') {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (_v0.$ === 'Ok') {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $author$project$Domain$parseHtml = function (content) {
	var parserNodeToElement = function (node) {
		switch (node.$) {
			case 'Comment':
				return $elm$html$Html$text('');
			case 'Text':
				var text = node.a;
				return $elm$html$Html$text(text);
			default:
				var htmlTag = node.a;
				var attrs = node.b;
				var children = node.c;
				return A3(
					$elm$html$Html$node,
					htmlTag,
					A2(
						$elm$core$List$map,
						function (_v1) {
							var a = _v1.a;
							var b = _v1.b;
							return A2($elm$html$Html$Attributes$attribute, a, b);
						},
						attrs),
					A2($elm$core$List$map, parserNodeToElement, children));
		}
	};
	var _v2 = $hecrj$html_parser$Html$Parser$run(content);
	if (_v2.$ === 'Err') {
		return $elm$core$Maybe$Nothing;
	} else {
		var nodes = _v2.a;
		return $elm$core$Maybe$Just(
			$mdgriffith$elm_ui$Element$html(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('html-content')
						]),
					A2($elm$core$List$map, parserNodeToElement, nodes))));
	}
};
var $author$project$Domain$notificationDecoder = A8(
	$elm$json$Json$Decode$map7,
	$author$project$Domain$Notification,
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$andThen,
		function (maybe) {
			if (maybe.$ === 'Nothing') {
				return $elm$json$Json$Decode$fail('invalid HTML');
			} else {
				var el = maybe.a;
				return $elm$json$Json$Decode$succeed(el);
			}
		},
		A2(
			$elm$json$Json$Decode$map,
			$author$project$Domain$parseHtml,
			A2($elm$json$Json$Decode$field, 'content', $elm$json$Json$Decode$string))),
	A2($elm$json$Json$Decode$field, 'content', $elm$json$Json$Decode$string),
	$author$project$Utils$decodeFilePathList('attachments'),
	A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Domain$notificationCategoryDecoder,
		A2($elm$json$Json$Decode$field, 'category', $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'created', $author$project$Utils$parseTimestamp),
	A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Domain$notificationTargetDecoder,
		A2(
			$elm$json$Json$Decode$andThen,
			$author$project$Domain$notificationCategoryDecoder,
			A2($elm$json$Json$Decode$field, 'category', $elm$json$Json$Decode$string))));
var $author$project$Collections$getNotification = A2(
	$author$project$API$getSingle,
	'notification',
	A2(
		$author$project$API$Get,
		$author$project$Domain$PB$decoderWithID($author$project$Domain$notificationDecoder),
		_List_Nil));
var $author$project$Session$withAuth = F2(
	function (session, headers) {
		var _v0 = $author$project$Session$getToken(session);
		if (_v0.$ === 'Nothing') {
			return headers;
		} else {
			var token = _v0.a;
			return A2(
				$elm$core$List$cons,
				$author$project$Session$authorizationHeader(token),
				headers);
		}
	});
var $author$project$Page$Notification$notificationRequest = F2(
	function (id, session) {
		return A2(
			$elm$core$Task$attempt,
			$author$project$Page$Notification$GotNotificationData,
			A3(
				$author$project$Collections$getNotification,
				A2($author$project$Session$withAuth, session, _List_Nil),
				_List_Nil,
				id));
	});
var $author$project$Page$Notification$ChangedSearch = function (a) {
	return {$: 'ChangedSearch', a: a};
};
var $author$project$Page$Notification$searchFieldSpecs = function () {
	var defaultSearch = $author$project$UI$Form$Text$default($author$project$Page$Notification$ChangedSearch);
	return _Utils_update(
		defaultSearch,
		{
			hint: $elm$core$Maybe$Just('Поиск'),
			name: 'Поиск',
			required: $author$project$UI$Form$Optional,
			showLabel: false
		});
}();
var $author$project$Page$Notification$ChangedTitle = function (a) {
	return {$: 'ChangedTitle', a: a};
};
var $author$project$Page$Notification$titleFieldSpecs = function (initialContent) {
	var defaultTitle = $author$project$UI$Form$Text$default($author$project$Page$Notification$ChangedTitle);
	return _Utils_update(
		defaultTitle,
		{
			displayed: initialContent,
			name: 'Тема',
			required: $author$project$UI$Form$Required,
			state: $elm$core$Maybe$Just(initialContent)
		});
};
var $author$project$Page$Notification$init = function (args) {
	var emptySources = A3($author$project$Page$Notification$CategoryPickerSources, $author$project$API$Status$NotStarted, $author$project$API$Status$NotStarted, $author$project$API$Status$NotStarted);
	var _v0 = args.state;
	if (_v0.$ === 'InitViewing') {
		var preview = _v0.a;
		var id = _v0.b;
		return _Utils_Tuple2(
			{
				device: args.device,
				session: args.session,
				sources: emptySources,
				state: A2(
					$author$project$Page$Notification$Viewing,
					preview,
					A2($author$project$API$Resource$Resource, id, $author$project$API$Status$Loading))
			},
			A2($author$project$Page$Notification$notificationRequest, id, args.session));
	} else {
		return _Utils_Tuple2(
			{
				device: args.device,
				session: args.session,
				sources: emptySources,
				state: $author$project$Page$Notification$New(
					A6(
						$author$project$Page$Notification$EditFormState,
						$author$project$Page$Notification$titleFieldSpecs(''),
						$author$project$Domain$PublicNotification,
						_List_Nil,
						'',
						$author$project$Page$Notification$searchFieldSpecs,
						$author$project$Page$Notification$Ready))
			},
			$elm$core$Platform$Cmd$none);
	}
};
var $author$project$Page$Notification$GotRichTextContent = F2(
	function (a, b) {
		return {$: 'GotRichTextContent', a: a, b: b};
	});
var $author$project$UI$RichTextEditor$receiveContent = _Platform_incomingPort('receiveContent', $elm$json$Json$Decode$string);
var $author$project$Page$Notification$subscriptions = function (_v0) {
	var state = _v0.state;
	switch (state.$) {
		case 'Editing':
			return _List_fromArray(
				[
					$author$project$UI$RichTextEditor$receiveContent(
					$author$project$Page$Notification$GotRichTextContent('notificationEditor'))
				]);
		case 'Viewing':
			return _List_Nil;
		default:
			return _List_fromArray(
				[
					$author$project$UI$RichTextEditor$receiveContent(
					$author$project$Page$Notification$GotRichTextContent('notificationEditor'))
				]);
	}
};
var $author$project$Page$Notification$title = function (model) {
	var _v0 = model.state;
	switch (_v0.$) {
		case 'Viewing':
			switch (_v0.b.b.$) {
				case 'Loading':
					if (_v0.a.$ === 'Just') {
						var preview = _v0.a.a;
						var _v1 = _v0.b;
						var _v2 = _v1.b;
						return preview.title;
					} else {
						var _v3 = _v0.a;
						var _v4 = _v0.b;
						var _v5 = _v4.b;
						return 'Загрузка...';
					}
				case 'Loaded':
					var _v6 = _v0.b;
					var notification = _v6.b.a;
					return notification.domain.title;
				default:
					return 'Ошибка';
			}
		case 'New':
			return 'Создание новости';
		default:
			return 'Редактирование новости';
	}
};
var $author$project$API$Status$fromResult = F2(
	function (toMessage, result) {
		if (result.$ === 'Ok') {
			var body = result.a;
			return $author$project$API$Status$Loaded(body);
		} else {
			var errorCode = result.a;
			return $author$project$API$Status$Failed(
				A2(
					$author$project$API$Error$LoadingFailure,
					errorCode,
					toMessage(errorCode)));
		}
	});
var $arturopala$elm_monocle$Monocle$Lens$Lens = F2(
	function (get, set) {
		return {get: get, set: set};
	});
var $arturopala$elm_monocle$Monocle$Lens$compose = F2(
	function (outer, inner) {
		var set = F2(
			function (c, a) {
				return function (b) {
					return A2(outer.set, b, a);
				}(
					A2(
						inner.set,
						c,
						outer.get(a)));
			});
		return A2(
			$arturopala$elm_monocle$Monocle$Lens$Lens,
			A2($elm$core$Basics$composeR, outer.get, inner.get),
			set);
	});
var $arturopala$elm_monocle$Monocle$Compose$lensWithLens = F2(
	function (inner, outer) {
		return A2($arturopala$elm_monocle$Monocle$Lens$compose, outer, inner);
	});
var $author$project$Page$Notification$modelSourcesLens = A2(
	$arturopala$elm_monocle$Monocle$Lens$Lens,
	function ($) {
		return $.sources;
	},
	F2(
		function (b, a) {
			return _Utils_update(
				a,
				{sources: b});
		}));
var $author$project$Page$Notification$modelSourcesBuildingsLens = A2(
	$arturopala$elm_monocle$Monocle$Compose$lensWithLens,
	A2(
		$arturopala$elm_monocle$Monocle$Lens$Lens,
		function ($) {
			return $.buildings;
		},
		F2(
			function (b, a) {
				return _Utils_update(
					a,
					{buildings: b});
			})),
	$author$project$Page$Notification$modelSourcesLens);
var $author$project$Page$Notification$modelSourcesFlatsLens = A2(
	$arturopala$elm_monocle$Monocle$Compose$lensWithLens,
	A2(
		$arturopala$elm_monocle$Monocle$Lens$Lens,
		function ($) {
			return $.flats;
		},
		F2(
			function (b, a) {
				return _Utils_update(
					a,
					{flats: b});
			})),
	$author$project$Page$Notification$modelSourcesLens);
var $author$project$Page$Notification$modelSourcesTenantsLens = A2(
	$arturopala$elm_monocle$Monocle$Compose$lensWithLens,
	A2(
		$arturopala$elm_monocle$Monocle$Lens$Lens,
		function ($) {
			return $.tenants;
		},
		F2(
			function (b, a) {
				return _Utils_update(
					a,
					{tenants: b});
			})),
	$author$project$Page$Notification$modelSourcesLens);
var $author$project$Page$Notification$AwaitingForCategoryChange = function (a) {
	return {$: 'AwaitingForCategoryChange', a: a};
};
var $author$project$Page$Notification$AwaitingForSubmit = {$: 'AwaitingForSubmit'};
var $author$project$Page$Notification$Editing = F3(
	function (a, b, c) {
		return {$: 'Editing', a: a, b: b, c: c};
	});
var $author$project$Page$Notification$GotUpdateResponse = function (a) {
	return {$: 'GotUpdateResponse', a: a};
};
var $author$project$Page$Notification$ReadyForCategoryChange = function (a) {
	return {$: 'ReadyForCategoryChange', a: a};
};
var $author$project$PopUp$Unimportant = {$: 'Unimportant'};
var $author$project$Domain$PB$attach = F2(
	function (real, _v0) {
		var domain = _v0.domain;
		var meta = _v0.meta;
		return {
			domain: domain,
			meta: {collectionId: meta.collectionId, collectionName: meta.collectionName, created: real.created, id: real.id, updated: real.updated}
		};
	});
var $author$project$Page$Notification$GotBuildingList = function (a) {
	return {$: 'GotBuildingList', a: a};
};
var $author$project$Page$Notification$GotFlatList = function (a) {
	return {$: 'GotFlatList', a: a};
};
var $author$project$Page$Notification$GotTenantList = function (a) {
	return {$: 'GotTenantList', a: a};
};
var $author$project$Collections$getFlats = A2(
	$author$project$API$getAll,
	'flat',
	A2(
		$author$project$API$Get,
		$author$project$Domain$PB$decoderWithID($author$project$Domain$flatDecoder),
		_List_Nil));
var $author$project$Collections$getTenants = A2(
	$author$project$API$getAll,
	'tenant',
	A2(
		$author$project$API$Get,
		$author$project$Domain$PB$decoderWithID($author$project$Domain$tenantDecoder),
		_List_Nil));
var $author$project$Page$Notification$handleChangedCategory = F3(
	function (category, model, newState) {
		var _default = _Utils_Tuple3(
			_Utils_update(
				model,
				{state: newState}),
			$elm$core$Platform$Cmd$none,
			$author$project$Framework$NoOp);
		var authHeader = $author$project$Session$authorizationHeader(
			A2(
				$elm$core$Maybe$withDefault,
				'',
				$author$project$Session$getToken(model.session)));
		var processCategoryChange = F4(
			function (lens, fetch, params, gotResponseMsg) {
				var load = _Utils_Tuple3(
					A2(
						lens.set,
						$author$project$API$Status$Loading,
						_Utils_update(
							model,
							{state: newState})),
					A2(
						$elm$core$Task$attempt,
						gotResponseMsg,
						A2(
							fetch,
							_List_fromArray(
								[authHeader]),
							params)),
					$author$project$Framework$NoOp);
				var _v1 = lens.get(model);
				switch (_v1.$) {
					case 'NotStarted':
						return load;
					case 'Failed':
						return load;
					default:
						return _default;
				}
			});
		switch (category.$) {
			case 'PublicNotification':
				return _default;
			case 'BuildingNotification':
				return A4(
					processCategoryChange,
					$author$project$Page$Notification$modelSourcesBuildingsLens,
					$author$project$Collections$getBuildings,
					_List_fromArray(
						[
							$author$project$API$Sort(
							_List_fromArray(
								['address']))
						]),
					$author$project$Page$Notification$GotBuildingList);
			case 'FlatNotification':
				return A4(
					processCategoryChange,
					$author$project$Page$Notification$modelSourcesFlatsLens,
					$author$project$Collections$getFlats,
					_List_fromArray(
						[
							$author$project$API$Sort(
							_List_fromArray(
								['building.address', 'number'])),
							$author$project$API$Expand(
							_List_fromArray(
								['building']))
						]),
					$author$project$Page$Notification$GotFlatList);
			default:
				return A4(
					processCategoryChange,
					$author$project$Page$Notification$modelSourcesTenantsLens,
					$author$project$Collections$getTenants,
					_List_fromArray(
						[
							$author$project$API$Sort(
							_List_fromArray(
								['surname', 'name', 'patronymic'])),
							$author$project$API$Expand(
							_List_fromArray(
								['flat']))
						]),
					$author$project$Page$Notification$GotTenantList);
		}
	});
var $author$project$API$Status$andThen = F2(
	function (f, status) {
		switch (status.$) {
			case 'Loaded':
				var result = status.a;
				return f(result);
			case 'NotStarted':
				return $author$project$API$Status$NotStarted;
			case 'Loading':
				return $author$project$API$Status$Loading;
			default:
				var x = status.a;
				return $author$project$API$Status$Failed(x);
		}
	});
var $author$project$API$Status$map = function (f) {
	return $author$project$API$Status$andThen(
		A2($elm$core$Basics$composeR, f, $author$project$API$Status$Loaded));
};
var $elmcraft$core_extra$List$Extra$reverseAppend = F2(
	function (list1, list2) {
		return A3($elm$core$List$foldl, $elm$core$List$cons, list2, list1);
	});
var $elmcraft$core_extra$List$Extra$removeHelp = F4(
	function (list, x, xs, previousElements) {
		removeHelp:
		while (true) {
			if (!xs.b) {
				return list;
			} else {
				var y = xs.a;
				var ys = xs.b;
				if (_Utils_eq(x, y)) {
					return A2($elmcraft$core_extra$List$Extra$reverseAppend, previousElements, ys);
				} else {
					var $temp$list = list,
						$temp$x = x,
						$temp$xs = ys,
						$temp$previousElements = A2($elm$core$List$cons, y, previousElements);
					list = $temp$list;
					x = $temp$x;
					xs = $temp$xs;
					previousElements = $temp$previousElements;
					continue removeHelp;
				}
			}
		}
	});
var $elmcraft$core_extra$List$Extra$remove = F2(
	function (x, xs) {
		return A4($elmcraft$core_extra$List$Extra$removeHelp, xs, x, xs, _List_Nil);
	});
var $author$project$UI$RichTextEditor$requestGetContent = _Platform_outgoingPort('requestGetContent', $elm$json$Json$Encode$string);
var $author$project$API$Patch = F3(
	function (a, b, c) {
		return {$: 'Patch', a: a, b: b, c: c};
	});
var $author$project$Domain$PB$encodeNoID = F2(
	function (domainToFields, _v0) {
		var meta = _v0.meta;
		var domain = _v0.domain;
		return $elm$json$Json$Encode$object(
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'collectionId',
						$elm$json$Json$Encode$string(meta.collectionId)),
						_Utils_Tuple2(
						'collectionName',
						$elm$json$Json$Encode$string(meta.collectionName))
					]),
				domainToFields(domain)));
	});
var $author$project$Domain$encodeNotificationCategory = function (category) {
	switch (category.$) {
		case 'PublicNotification':
			return 'public';
		case 'PersonalNotification':
			return 'personal';
		case 'FlatNotification':
			return 'flats';
		default:
			return 'buildings';
	}
};
var $author$project$API$patch = F6(
	function (name, _v0, headers, extraParams, id, object) {
		var encoder = _v0.a;
		var decoder = _v0.b;
		var parameters = _v0.c;
		return $elm$http$Http$task(
			{
				body: $elm$http$Http$jsonBody(
					encoder(object)),
				headers: headers,
				method: 'PATCH',
				resolver: $author$project$API$jsonResolver(decoder),
				timeout: $author$project$API$timeout,
				url: A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						['api', 'collections', name, 'records', id]),
					$author$project$API$paramsToQuery(
						_Utils_ap(extraParams, parameters)))
			});
	});
var $author$project$Collections$updateNotification = F3(
	function (authHeader, id, data) {
		return A6(
			$author$project$API$patch,
			'notification',
			A3(
				$author$project$API$Patch,
				$author$project$Domain$PB$encodeNoID(
					function (x) {
						return _Utils_ap(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'title',
									$elm$json$Json$Encode$string(x.title)),
									_Utils_Tuple2(
									'content',
									$elm$json$Json$Encode$string(x.rawContent)),
									_Utils_Tuple2(
									'category',
									$elm$json$Json$Encode$string(
										$author$project$Domain$encodeNotificationCategory(x.category)))
								]),
							A2(
								$elm$core$List$map,
								$elm$core$Tuple$mapSecond(
									$elm$json$Json$Encode$list($elm$json$Json$Encode$string)),
								function () {
									var _v0 = x.target;
									switch (_v0.$) {
										case 'Public':
											return _List_fromArray(
												[
													_Utils_Tuple2('people', _List_Nil),
													_Utils_Tuple2('flats', _List_Nil),
													_Utils_Tuple2('buildings', _List_Nil)
												]);
										case 'Personal':
											var targets = _v0.a;
											return _List_fromArray(
												[
													_Utils_Tuple2('people', targets),
													_Utils_Tuple2('flats', _List_Nil),
													_Utils_Tuple2('buildings', _List_Nil)
												]);
										case 'Flats':
											var targets = _v0.a;
											return _List_fromArray(
												[
													_Utils_Tuple2('people', _List_Nil),
													_Utils_Tuple2('flats', targets),
													_Utils_Tuple2('buildings', _List_Nil)
												]);
										default:
											var targets = _v0.a;
											return _List_fromArray(
												[
													_Utils_Tuple2('people', _List_Nil),
													_Utils_Tuple2('flats', _List_Nil),
													_Utils_Tuple2('buildings', targets)
												]);
									}
								}()));
					}),
				$author$project$Domain$PB$decoderWithID($author$project$Domain$notificationDecoder),
				_List_Nil),
			_List_fromArray(
				[authHeader]),
			_List_Nil,
			id,
			data);
	});
var $author$project$Page$Notification$updateEdit = F5(
	function (real, _v0, form, msg, model) {
		var id = _v0.a;
		var status = _v0.b;
		var _v1 = _Utils_Tuple2(status, msg);
		_v1$11:
		while (true) {
			switch (_v1.b.$) {
				case 'PressedBackToViewing':
					var _v2 = _v1.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: A2(
									$author$project$Page$Notification$Viewing,
									$elm$core$Maybe$Nothing,
									A2(
										$author$project$API$Resource$Resource,
										id,
										A2(
											$author$project$API$Status$map,
											$author$project$Domain$PB$attach(real),
											status)))
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				case 'ChangedTitle':
					var specs = _v1.b.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: A3(
									$author$project$Page$Notification$Editing,
									real,
									A2($author$project$API$Resource$Resource, id, status),
									_Utils_update(
										form,
										{title: specs}))
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				case 'ChangedSearch':
					var specs = _v1.b.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: A3(
									$author$project$Page$Notification$Editing,
									real,
									A2($author$project$API$Resource$Resource, id, status),
									_Utils_update(
										form,
										{search: specs}))
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				case 'SetNotificationTargetSelection':
					if (_v1.b.a) {
						var _v3 = _v1.b;
						var recordId = _v3.b;
						return _Utils_Tuple3(
							A2(
								$elm$core$Debug$log,
								'model',
								_Utils_update(
									model,
									{
										state: A3(
											$author$project$Page$Notification$Editing,
											real,
											A2($author$project$API$Resource$Resource, id, status),
											_Utils_update(
												form,
												{
													targets: A2($elm$core$List$cons, recordId, form.targets)
												}))
									})),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						var _v4 = _v1.b;
						var recordId = _v4.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A3(
										$author$project$Page$Notification$Editing,
										real,
										A2($author$project$API$Resource$Resource, id, status),
										_Utils_update(
											form,
											{
												targets: A2($elmcraft$core_extra$List$Extra$remove, recordId, form.targets)
											}))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					}
				case 'PressedChangeCategory':
					var category = _v1.b.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: A3(
									$author$project$Page$Notification$Editing,
									real,
									A2($author$project$API$Resource$Resource, id, status),
									_Utils_update(
										form,
										{
											contentState: $author$project$Page$Notification$AwaitingForCategoryChange(category)
										}))
							}),
						$author$project$UI$RichTextEditor$requestGetContent('notificationEditor'),
						$author$project$Framework$NoOp);
				case 'ReadyForCategoryChange':
					var category = _v1.b.a;
					return A3(
						$author$project$Page$Notification$handleChangedCategory,
						category,
						model,
						A3(
							$author$project$Page$Notification$Editing,
							real,
							A2($author$project$API$Resource$Resource, id, status),
							_Utils_update(
								form,
								{category: category, targets: _List_Nil})));
				case 'PressedSubmit':
					var _v5 = _v1.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: A3(
									$author$project$Page$Notification$Editing,
									real,
									A2($author$project$API$Resource$Resource, id, status),
									_Utils_update(
										form,
										{contentState: $author$project$Page$Notification$AwaitingForSubmit}))
							}),
						$author$project$UI$RichTextEditor$requestGetContent('notificationEditor'),
						$author$project$Framework$NoOp);
				case 'GotRichTextContent':
					if ((_v1.a.$ === 'Loaded') && (_v1.b.a === 'notificationEditor')) {
						var virtual = _v1.a.a;
						var domain = virtual.domain;
						var _v6 = _v1.b;
						var content = _v6.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A3(
										$author$project$Page$Notification$Editing,
										real,
										A2(
											$author$project$API$Resource$Resource,
											id,
											$author$project$API$Status$Loaded(virtual)),
										_Utils_update(
											form,
											{content: content, contentState: $author$project$Page$Notification$Ready}))
								}),
							function () {
								var _v7 = form.contentState;
								switch (_v7.$) {
									case 'AwaitingForSubmit':
										return A2(
											$elm$core$Task$attempt,
											$author$project$Page$Notification$GotUpdateResponse,
											A3(
												$author$project$Collections$updateNotification,
												$author$project$Session$authorizationHeader(
													A2(
														$elm$core$Maybe$withDefault,
														'',
														$author$project$Session$getToken(model.session))),
												id,
												_Utils_update(
													virtual,
													{
														domain: _Utils_update(
															domain,
															{
																category: form.category,
																rawContent: content,
																target: function () {
																	var _v8 = form.category;
																	switch (_v8.$) {
																		case 'PublicNotification':
																			return $author$project$Domain$Public;
																		case 'PersonalNotification':
																			return A2($author$project$Domain$Personal, form.targets, 0);
																		case 'FlatNotification':
																			return A2($author$project$Domain$Flats, form.targets, 0);
																		default:
																			return A2($author$project$Domain$Buildings, form.targets, 0);
																	}
																}(),
																title: function () {
																	var _v9 = $author$project$UI$Form$result(form.title);
																	if (_v9.$ === 'WithResult') {
																		var result = _v9.a;
																		return result;
																	} else {
																		return '';
																	}
																}()
															})
													})));
									case 'AwaitingForCategoryChange':
										var category = _v7.a;
										return $elmcraft$core_extra$Cmd$Extra$perform(
											$author$project$Page$Notification$ReadyForCategoryChange(category));
									default:
										return $elm$core$Platform$Cmd$none;
								}
							}(),
							$author$project$Framework$NoOp);
					} else {
						break _v1$11;
					}
				case 'GotUpdateResponse':
					if (_v1.a.$ === 'Loaded') {
						if (_v1.b.a.$ === 'Ok') {
							var notification = _v1.b.a.a;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										state: A2(
											$author$project$Page$Notification$Viewing,
											$elm$core$Maybe$Nothing,
											A2(
												$author$project$API$Resource$Resource,
												notification.meta.id,
												$author$project$API$Status$Loaded(notification)))
									}),
								$elm$core$Platform$Cmd$none,
								A2(
									$author$project$Framework$ShowPopUp,
									A2($author$project$PopUp$PlainText, 'Готово!', 'Запись успешно обновлена!'),
									$author$project$PopUp$Unimportant));
						} else {
							return _Utils_Tuple3(
								model,
								$elm$core$Platform$Cmd$none,
								A2(
									$author$project$Framework$ShowPopUp,
									A2($author$project$PopUp$PlainText, 'Ошибка!', 'Что-то пошло не так!'),
									$author$project$PopUp$Unimportant));
						}
					} else {
						break _v1$11;
					}
				default:
					break _v1$11;
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
	});
var $author$project$Page$Notification$GotCreateResponse = function (a) {
	return {$: 'GotCreateResponse', a: a};
};
var $author$project$Collections$createNotification = F2(
	function (authHeader, data) {
		return A5(
			$author$project$API$post,
			'notification',
			A3(
				$author$project$API$Post,
				A2(
					$elm$core$Basics$composeR,
					function (x) {
						return _Utils_ap(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'title',
									$elm$json$Json$Encode$string(x.title)),
									_Utils_Tuple2(
									'content',
									$elm$json$Json$Encode$string(x.rawContent)),
									_Utils_Tuple2(
									'category',
									$elm$json$Json$Encode$string(
										$author$project$Domain$encodeNotificationCategory(x.category)))
								]),
							A2(
								$elm$core$List$map,
								$elm$core$Tuple$mapSecond(
									$elm$json$Json$Encode$list($elm$json$Json$Encode$string)),
								function () {
									var _v0 = x.target;
									switch (_v0.$) {
										case 'Public':
											return _List_fromArray(
												[
													_Utils_Tuple2('people', _List_Nil),
													_Utils_Tuple2('flats', _List_Nil),
													_Utils_Tuple2('buildings', _List_Nil)
												]);
										case 'Personal':
											var targets = _v0.a;
											return _List_fromArray(
												[
													_Utils_Tuple2('people', targets),
													_Utils_Tuple2('flats', _List_Nil),
													_Utils_Tuple2('buildings', _List_Nil)
												]);
										case 'Flats':
											var targets = _v0.a;
											return _List_fromArray(
												[
													_Utils_Tuple2('people', _List_Nil),
													_Utils_Tuple2('flats', targets),
													_Utils_Tuple2('buildings', _List_Nil)
												]);
										default:
											var targets = _v0.a;
											return _List_fromArray(
												[
													_Utils_Tuple2('people', _List_Nil),
													_Utils_Tuple2('flats', _List_Nil),
													_Utils_Tuple2('buildings', targets)
												]);
									}
								}()));
					},
					$elm$json$Json$Encode$object),
				$author$project$Domain$PB$decoderWithID($author$project$Domain$notificationDecoder),
				_List_Nil),
			_List_fromArray(
				[authHeader]),
			_List_Nil,
			data);
	});
var $author$project$Domain$emptyNotification = A7(
	$author$project$Domain$Notification,
	'',
	$mdgriffith$elm_ui$Element$none,
	'',
	_List_Nil,
	$author$project$Domain$PublicNotification,
	$elm$time$Time$millisToPosix(0),
	$author$project$Domain$Public);
var $author$project$Session$getKey = function (_v0) {
	var key = _v0.a;
	return key;
};
var $author$project$Page$Notification$updateNew = F3(
	function (form, msg, model) {
		_v0$11:
		while (true) {
			switch (msg.$) {
				case 'ChangedTitle':
					var specs = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: $author$project$Page$Notification$New(
									_Utils_update(
										form,
										{title: specs}))
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				case 'ChangedSearch':
					var specs = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: $author$project$Page$Notification$New(
									_Utils_update(
										form,
										{search: specs}))
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				case 'SetNotificationTargetSelection':
					if (msg.a) {
						var recordId = msg.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: $author$project$Page$Notification$New(
										_Utils_update(
											form,
											{
												targets: A2($elm$core$List$cons, recordId, form.targets)
											}))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						var recordId = msg.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: $author$project$Page$Notification$New(
										_Utils_update(
											form,
											{
												targets: A2($elmcraft$core_extra$List$Extra$remove, recordId, form.targets)
											}))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					}
				case 'PressedChangeCategory':
					var category = msg.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: $author$project$Page$Notification$New(
									_Utils_update(
										form,
										{
											contentState: $author$project$Page$Notification$AwaitingForCategoryChange(category)
										}))
							}),
						$author$project$UI$RichTextEditor$requestGetContent('notificationEditor'),
						$author$project$Framework$NoOp);
				case 'ReadyForCategoryChange':
					var category = msg.a;
					return A3(
						$author$project$Page$Notification$handleChangedCategory,
						category,
						model,
						$author$project$Page$Notification$New(
							_Utils_update(
								form,
								{category: category, targets: _List_Nil})));
				case 'PressedBackToViewing':
					return _Utils_Tuple3(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							$author$project$Session$getKey(model.session),
							'/'),
						$author$project$Framework$NoOp);
				case 'PressedSubmit':
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: $author$project$Page$Notification$New(
									_Utils_update(
										form,
										{contentState: $author$project$Page$Notification$AwaitingForSubmit}))
							}),
						$author$project$UI$RichTextEditor$requestGetContent('notificationEditor'),
						$author$project$Framework$NoOp);
				case 'GotRichTextContent':
					if (msg.a === 'notificationEditor') {
						var content = msg.b;
						var empty = $author$project$Domain$emptyNotification;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: $author$project$Page$Notification$New(
										_Utils_update(
											form,
											{content: content, contentState: $author$project$Page$Notification$Ready}))
								}),
							function () {
								var _v1 = form.contentState;
								switch (_v1.$) {
									case 'AwaitingForSubmit':
										return A2(
											$elm$core$Task$attempt,
											$author$project$Page$Notification$GotCreateResponse,
											A2(
												$author$project$Collections$createNotification,
												$author$project$Session$authorizationHeader(
													A2(
														$elm$core$Maybe$withDefault,
														'',
														$author$project$Session$getToken(model.session))),
												_Utils_update(
													empty,
													{
														category: form.category,
														rawContent: content,
														title: function () {
															var _v2 = $author$project$UI$Form$result(form.title);
															if (_v2.$ === 'WithResult') {
																var result = _v2.a;
																return result;
															} else {
																return '';
															}
														}()
													})));
									case 'AwaitingForCategoryChange':
										var category = _v1.a;
										return $elmcraft$core_extra$Cmd$Extra$perform(
											$author$project$Page$Notification$ReadyForCategoryChange(category));
									default:
										return $elm$core$Platform$Cmd$none;
								}
							}(),
							$author$project$Framework$NoOp);
					} else {
						break _v0$11;
					}
				case 'GotCreateResponse':
					if (msg.a.$ === 'Ok') {
						var notification = msg.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A2(
										$author$project$Page$Notification$Viewing,
										$elm$core$Maybe$Nothing,
										A2(
											$author$project$API$Resource$Resource,
											notification.meta.id,
											$author$project$API$Status$Loaded(notification)))
								}),
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								$author$project$Session$getKey(model.session),
								'/notification/' + notification.meta.id),
							A2(
								$author$project$Framework$ShowPopUp,
								A2($author$project$PopUp$PlainText, 'Готово!', 'Запись успешно создана!'),
								$author$project$PopUp$Unimportant));
					} else {
						return _Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							A2(
								$author$project$Framework$ShowPopUp,
								A2($author$project$PopUp$PlainText, 'Ошибка!', 'Что-то пошло не так!'),
								$author$project$PopUp$Unimportant));
					}
				default:
					break _v0$11;
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
	});
var $author$project$Page$Notification$GotDeleteResponse = function (a) {
	return {$: 'GotDeleteResponse', a: a};
};
var $author$project$API$Delete = function (a) {
	return {$: 'Delete', a: a};
};
var $author$project$API$delete = F3(
	function (name, _v0, headers) {
		var id = _v0.a;
		return $elm$http$Http$task(
			{
				body: $elm$http$Http$emptyBody,
				headers: headers,
				method: 'DELETE',
				resolver: $author$project$API$stringResolver(
					$elm$core$Basics$always(
						$elm$core$Result$Ok(_Utils_Tuple0))),
				timeout: $author$project$API$timeout,
				url: A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						['api', 'collections', name, 'records', id]),
					_List_Nil)
			});
	});
var $author$project$Collections$deleteNotification = F2(
	function (id, authHeader) {
		return A3(
			$author$project$API$delete,
			'notification',
			$author$project$API$Delete(id),
			_List_fromArray(
				[authHeader]));
	});
var $author$project$Domain$PB$detach = function (_v0) {
	var domain = _v0.domain;
	var meta = _v0.meta;
	return _Utils_Tuple2(
		{created: meta.created, id: meta.id, updated: meta.updated},
		{
			domain: domain,
			meta: {collectionId: meta.collectionId, collectionName: meta.collectionName}
		});
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Page$Notification$updateViewing = F4(
	function (preview, res, msg, model) {
		var _v0 = _Utils_Tuple2(res, msg);
		_v0$6:
		while (true) {
			switch (_v0.b.$) {
				case 'GotNotificationData':
					if (_v0.b.a.$ === 'Ok') {
						var notification = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A2(
										$author$project$Page$Notification$Viewing,
										$elm$core$Maybe$Nothing,
										A2($author$project$API$Resource$loaded, notification, res))
								}),
							function () {
								var _v1 = $author$project$Session$getViewer(model.session);
								if ((_v1.$ === 'Authenticated') && (_v1.a.b.$ === 'ManagerLocalStorage')) {
									var _v2 = _v1.a;
									var authToken = _v2.b.a.authToken;
									var _v3 = notification.domain.category;
									switch (_v3.$) {
										case 'PublicNotification':
											return $elm$core$Platform$Cmd$none;
										case 'PersonalNotification':
											return A2(
												$elm$core$Task$attempt,
												$author$project$Page$Notification$GotTenantList,
												A2(
													$author$project$Collections$getTenants,
													_List_fromArray(
														[
															$author$project$Session$authorizationHeader(authToken)
														]),
													_List_fromArray(
														[
															$author$project$API$Sort(
															_List_fromArray(
																['surname', 'name', 'patronymic'])),
															$author$project$API$Expand(
															_List_fromArray(
																['flat']))
														])));
										case 'FlatNotification':
											return A2(
												$elm$core$Task$attempt,
												$author$project$Page$Notification$GotFlatList,
												A2(
													$author$project$Collections$getFlats,
													_List_fromArray(
														[
															$author$project$Session$authorizationHeader(authToken)
														]),
													_List_fromArray(
														[
															$author$project$API$Sort(
															_List_fromArray(
																['building.address', 'number'])),
															$author$project$API$Expand(
															_List_fromArray(
																['building']))
														])));
										default:
											return A2(
												$elm$core$Task$attempt,
												$author$project$Page$Notification$GotBuildingList,
												A2(
													$author$project$Collections$getBuildings,
													_List_fromArray(
														[
															$author$project$Session$authorizationHeader(authToken)
														]),
													_List_fromArray(
														[
															$author$project$API$Sort(
															_List_fromArray(
																['address']))
														])));
									}
								} else {
									return $elm$core$Platform$Cmd$none;
								}
							}(),
							$author$project$Framework$NoOp);
					} else {
						var code = _v0.b.a.a;
						var _v4 = A2($elm$core$Debug$log, 'error code', code);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A2(
										$author$project$Page$Notification$Viewing,
										preview,
										A3(
											$author$project$API$Resource$failed,
											code,
											_List_fromArray(
												['Не удалось получить данные. Попробуйте обновить страницу!']),
											res))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					}
				case 'PressedDelete':
					var _v5 = _v0.a;
					var id = _v5.a;
					var authToken = _v0.b.a;
					return _Utils_Tuple3(
						model,
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Notification$GotDeleteResponse,
							A2(
								$author$project$Collections$deleteNotification,
								id,
								$author$project$Session$authorizationHeader(authToken))),
						$author$project$Framework$NoOp);
				case 'GotDeleteResponse':
					if (_v0.b.a.$ === 'Ok') {
						return _Utils_Tuple3(
							model,
							$elm$browser$Browser$Navigation$load('/'),
							A2(
								$author$project$Framework$ShowPopUp,
								A2($author$project$PopUp$PlainText, 'Готово!', 'Новость успешно удалена.'),
								$author$project$PopUp$Unimportant));
					} else {
						return _Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							A2(
								$author$project$Framework$ShowPopUp,
								A2($author$project$PopUp$PlainText, 'Ошибка!', 'Не удалось удалить новость!'),
								$author$project$PopUp$Important));
					}
				case 'PressedEdit':
					if (_v0.a.b.$ === 'Loaded') {
						var _v6 = _v0.a;
						var id = _v6.a;
						var body = _v6.b.a;
						var _v7 = $author$project$Domain$PB$detach(body);
						var real = _v7.a;
						var virtual = _v7.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A3(
										$author$project$Page$Notification$Editing,
										real,
										A2(
											$author$project$API$Resource$Resource,
											id,
											$author$project$API$Status$Loaded(virtual)),
										A6(
											$author$project$Page$Notification$EditFormState,
											$author$project$Page$Notification$titleFieldSpecs(body.domain.title),
											body.domain.category,
											function () {
												var _v8 = body.domain.target;
												switch (_v8.$) {
													case 'Public':
														return _List_Nil;
													case 'Personal':
														var x = _v8.a;
														return x;
													case 'Flats':
														var x = _v8.a;
														return x;
													default:
														var x = _v8.a;
														return x;
												}
											}(),
											body.domain.rawContent,
											$author$project$Page$Notification$searchFieldSpecs,
											$author$project$Page$Notification$Ready))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						break _v0$6;
					}
				default:
					break _v0$6;
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
	});
var $author$project$Page$Notification$update = F2(
	function (msg, model) {
		var handleGotList = F2(
			function (lens, res) {
				return _Utils_Tuple3(
					A2(
						lens.set,
						A2(
							$author$project$API$Status$fromResult,
							function (_v3) {
								return _List_Nil;
							},
							res),
						model),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			});
		var _v0 = _Utils_Tuple2(model.state, msg);
		switch (_v0.b.$) {
			case 'UpdatedBaseModel':
				var base = _v0.b.a;
				return _Utils_Tuple3(
					A2($author$project$Framework$updateBase, model, base),
					(!_Utils_eq(base.session, model.session)) ? $elm$browser$Browser$Navigation$reload : $elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'GotBuildingList':
				var res = _v0.b.a;
				return A2(handleGotList, $author$project$Page$Notification$modelSourcesBuildingsLens, res);
			case 'GotFlatList':
				var res = _v0.b.a;
				return A2(handleGotList, $author$project$Page$Notification$modelSourcesFlatsLens, res);
			case 'GotTenantList':
				var res = _v0.b.a;
				return A2(handleGotList, $author$project$Page$Notification$modelSourcesTenantsLens, res);
			default:
				switch (_v0.a.$) {
					case 'Viewing':
						var _v1 = _v0.a;
						var preview = _v1.a;
						var res = _v1.b;
						return A4($author$project$Page$Notification$updateViewing, preview, res, msg, model);
					case 'Editing':
						var _v2 = _v0.a;
						var real = _v2.a;
						var res = _v2.b;
						var form = _v2.c;
						return A5($author$project$Page$Notification$updateEdit, real, res, form, msg, model);
					default:
						var form = _v0.a.a;
						return A3($author$project$Page$Notification$updateNew, form, msg, model);
				}
		}
	});
var $author$project$Page$Notification$PressedBackToViewing = {$: 'PressedBackToViewing'};
var $author$project$Page$Notification$PressedChangeCategory = function (a) {
	return {$: 'PressedChangeCategory', a: a};
};
var $author$project$Page$Notification$PressedDelete = function (a) {
	return {$: 'PressedDelete', a: a};
};
var $author$project$Page$Notification$PressedEdit = function (a) {
	return {$: 'PressedEdit', a: a};
};
var $author$project$Page$Notification$PressedSubmit = {$: 'PressedSubmit'};
var $author$project$Page$Notification$SetNotificationTargetSelection = F2(
	function (a, b) {
		return {$: 'SetNotificationTargetSelection', a: a, b: b};
	});
var $author$project$UI$TooltipAbove = {$: 'TooltipAbove'};
var $author$project$UI$TooltipCenter = {$: 'TooltipCenter'};
var $elm$html$Html$Attributes$download = function (fileName) {
	return A2($elm$html$Html$Attributes$stringProperty, 'download', fileName);
};
var $mdgriffith$elm_ui$Element$download = F2(
	function (attrs, _v0) {
		var url = _v0.url;
		var label = _v0.label;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$download('')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentCenterX),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentCenterY),
									attrs)))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $author$project$UI$withBorder = F2(
	function (color, attrs) {
		return _Utils_ap(
			attrs,
			$author$project$UI$border(color));
	});
var $author$project$UI$Card$attachment = function (url) {
	var renderAttachment = F2(
		function (filename, extension) {
			return A2(
				$mdgriffith$elm_ui$Element$row,
				A3(
					$author$project$UI$withTooltip,
					A2($author$project$UI$TooltipPosition, $author$project$UI$TooltipAbove, $author$project$UI$TooltipCenter),
					filename,
					A2(
						$author$project$UI$withBorder,
						$author$project$UI$colors.primary,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(
								$author$project$UI$scaled(-4)),
								$mdgriffith$elm_ui$Element$padding(
								$author$project$UI$scaled(-4)),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$class('alt-rotate-icon'))
							]))),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
								$author$project$UI$transitionDuration('100ms')
							]),
						A2(
							$author$project$UI$materialIcon,
							'attachment',
							$author$project$UI$scaled(4))),
						$mdgriffith$elm_ui$Element$text(extension)
					]));
		});
	var regex = A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('/(\\w+)_(?:\\w+)\\.(\\w+)$'));
	return A2(
		$mdgriffith$elm_ui$Element$download,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$pointer]),
		{
			label: function () {
				var _v0 = A2($elm$regex$Regex$find, regex, url);
				if (_v0.b && (!_v0.b.b)) {
					var submatches = _v0.a.submatches;
					if ((((submatches.b && (submatches.a.$ === 'Just')) && submatches.b.b) && (submatches.b.a.$ === 'Just')) && (!submatches.b.b.b)) {
						var filename = submatches.a.a;
						var _v2 = submatches.b;
						var extension = _v2.a.a;
						return A2(renderAttachment, filename, extension);
					} else {
						return $mdgriffith$elm_ui$Element$none;
					}
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}(),
			url: url
		});
};
var $author$project$Page$Notification$categoryIcon = function (category) {
	switch (category.$) {
		case 'PublicNotification':
			return 'public';
		case 'PersonalNotification':
			return 'person';
		case 'FlatNotification':
			return 'door_front';
		default:
			return 'apartment';
	}
};
var $author$project$UI$expandParagraphs = $mdgriffith$elm_ui$Element$htmlAttribute(
	A2($elm$html$Html$Attributes$style, 'flex-basis', 'fit-content'));
var $author$project$UI$Form$ifTrue = F2(
	function (condition, func) {
		return condition ? func : $elm$core$Basics$identity;
	});
var $author$project$UI$editIcon = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.bad),
			$mdgriffith$elm_ui$Element$Font$bold,
			$mdgriffith$elm_ui$Element$spacing(
			$author$project$UI$scaled(-1)),
			$mdgriffith$elm_ui$Element$padding(
			$author$project$UI$scaled(-4)),
			$author$project$UI$transitionDuration($author$project$UI$durations.fast)
		]),
	A2(
		$author$project$UI$materialIcon,
		'edit',
		$author$project$UI$scaled(2)));
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $mdgriffith$elm_ui$Element$newTabLink = F2(
	function (attrs, _v0) {
		var url = _v0.url;
		var label = _v0.label;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Attributes$target('_blank')),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.contentCenterX + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.contentCenterY + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.link)))),
									attrs)))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $author$project$UI$managerEditLinkCompact = F2(
	function (collectionId, recordId) {
		return A2(
			$mdgriffith$elm_ui$Element$newTabLink,
			_List_Nil,
			{
				label: $author$project$UI$editIcon,
				url: A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						['_', '#', 'collections']),
					A2(
						$elm$core$List$cons,
						A2($elm$url$Url$Builder$string, 'collectionId', collectionId),
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								A2(
									$elm$core$Basics$composeR,
									$elm$url$Url$Builder$string('recordId'),
									$elm$core$List$singleton),
								recordId))))
			});
	});
var $author$project$Page$Notification$categoryName = function (category) {
	switch (category.$) {
		case 'PublicNotification':
			return 'Общедоступные';
		case 'PersonalNotification':
			return 'Персональные';
		case 'FlatNotification':
			return 'Квартирам';
		default:
			return 'Зданиям';
	}
};
var $author$project$UI$Card$dateLabel = function (time) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
				$author$project$UI$fontsizes.small,
				$mdgriffith$elm_ui$Element$alignRight,
				$author$project$UI$preventSelect
			]),
		$mdgriffith$elm_ui$Element$text(
			function (p) {
				return A2(
					$elm$core$String$join,
					'.',
					A2(
						$elm$core$List$map,
						A2(
							$elm$core$String$padLeft,
							2,
							_Utils_chr('0')),
						A2(
							$elm$core$List$map,
							$elm$core$String$fromInt,
							_List_fromArray(
								[
									p.day,
									$author$project$Utils$monthToInt(p.month),
									p.year
								]))));
			}(
				A2($justinmimbs$time_extra$Time$Extra$posixToParts, $author$project$Domain$zone, time))));
};
var $author$project$Page$Notification$meta = function (info) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignRight,
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(-2))
			]),
		_List_fromArray(
			[
				$author$project$UI$Card$dateLabel(info.published),
				A2(
				$mdgriffith$elm_ui$Element$el,
				A3(
					$author$project$UI$withTooltip,
					A2($author$project$UI$TooltipPosition, $author$project$UI$TooltipBelow, $author$project$UI$TooltipLeft),
					$author$project$Page$Notification$categoryName(info.category),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
						])),
				A2(
					$author$project$UI$materialIcon,
					$author$project$Page$Notification$categoryIcon(info.category),
					$author$project$UI$scaled(4)))
			]));
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$UI$RichTextEditor$richTextEditor = F3(
	function (attrs, id, initContent) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			attrs,
			$mdgriffith$elm_ui$Element$html(
				A3(
					$elm$html$Html$node,
					'rich-editor',
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(id),
							A2($elm$html$Html$Attributes$attribute, 'initial-content', initContent)
						]),
					_List_Nil)));
	});
var $author$project$Page$Notification$view = function (model) {
	var categoryItemsSelectionList = F3(
		function (selected, onClick, form) {
			var searchString = function () {
				var _v14 = $author$project$UI$Form$result(form.search);
				if (_v14.$ === 'WithResult') {
					var x = _v14.a;
					return x;
				} else {
					return '';
				}
			}();
			var showItems = F3(
				function (status, content, searchableText) {
					switch (status.$) {
						case 'Loaded':
							var data = status.a;
							return A2(
								$elm$core$List$map,
								function (x) {
									return A2(
										$author$project$UI$Card$contentCard,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												selected ? $mdgriffith$elm_ui$Element$shrink : $mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$Events$onClick(
												onClick(x.meta.id)),
												$author$project$UI$preventSelect,
												$author$project$UI$expandParagraphs
											]),
										content(x));
								},
								A2(
									$elm$core$List$filter,
									function (x) {
										return _Utils_eq(
											A2($elm$core$List$member, x.meta.id, form.targets),
											selected) && (selected || A2(
											$elm$core$String$contains,
											$elm$core$String$toLower(searchString),
											$elm$core$String$toLower(
												searchableText(x))));
									},
									data));
						case 'NotStarted':
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$center]),
										$elm$core$List$singleton(
											$mdgriffith$elm_ui$Element$text('Что-то пошло не так! Перезагрузите страницу!'))))
								]);
						case 'Loading':
							return _List_fromArray(
								[$author$project$UI$Loading$spinner]);
						default:
							var message = status.a.message;
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									A2(
										$elm$core$List$map,
										A2(
											$elm$core$Basics$composeR,
											$mdgriffith$elm_ui$Element$text,
											A2(
												$elm$core$Basics$composeR,
												$elm$core$List$singleton,
												$mdgriffith$elm_ui$Element$paragraph(
													_List_fromArray(
														[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$center])))),
										message))
								]);
					}
				});
			return (selected ? $mdgriffith$elm_ui$Element$wrappedRow(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(
						$author$project$UI$scaled(-1)),
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-1))
					])) : A2(
				$elm$core$Basics$composeR,
				$mdgriffith$elm_ui$Element$column(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$padding(
							$author$project$UI$scaled(-1)),
							$mdgriffith$elm_ui$Element$scrollbarY,
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-1))
						])),
				$mdgriffith$elm_ui$Element$el(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							A2($mdgriffith$elm_ui$Element$maximum, 200, $mdgriffith$elm_ui$Element$fill))
						]))))(
				function () {
					var _v12 = form.category;
					switch (_v12.$) {
						case 'PublicNotification':
							return _List_Nil;
						case 'BuildingNotification':
							return A3(
								showItems,
								model.sources.buildings,
								function (b) {
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(b.domain.address)
										]);
								},
								function (b) {
									return b.domain.address;
								});
						case 'PersonalNotification':
							var txt = function (t) {
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[
											A2(
											$elm$core$Maybe$withDefault,
											'',
											A2(
												$elm$core$Maybe$map,
												A2(
													$elm$core$Basics$composeR,
													$elm$core$List$map(
														function (f) {
															return 'л/с №' + $elm$core$String$fromInt(f.domain.accountNumber);
														}),
													A2(
														$elm$core$Basics$composeR,
														$elm$core$String$join(', '),
														function (x) {
															return '(' + (x + ')');
														})),
												$author$project$API$Resource$toMaybe(t.domain.flat))),
											t.domain.surname,
											t.domain.name,
											t.domain.patronymic
										]));
							};
							return A3(
								showItems,
								model.sources.tenants,
								function (t) {
									return _List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text(
													txt(t))
												]))
										]);
								},
								txt);
						default:
							return A3(
								showItems,
								model.sources.flats,
								function (f) {
									return _List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text(
													$author$project$Domain$flatAddress(f.domain))
												]))
										]);
								},
								function (f) {
									return $author$project$Domain$flatAddress(f.domain);
								});
					}
				}());
		});
	var categoryButton = F2(
		function (form, category) {
			return A4(
				$author$project$UI$Button$ButtonSpecs,
				$elm$core$Maybe$Just(
					$author$project$Page$Notification$PressedChangeCategory(category)),
				A2(
					$author$project$UI$materialIcon,
					$author$project$Page$Notification$categoryIcon(category),
					$author$project$UI$scaled(4)),
				_Utils_eq(form.category, category) ? $author$project$UI$Button$Filled : $author$project$UI$Button$Outline,
				_List_fromArray(
					[
						$author$project$UI$transitionDuration($author$project$UI$durations.instant)
					]));
		});
	var notificationEditForm = F2(
		function (formTitle, form) {
			return A2(
				$author$project$UI$Form$form,
				_List_Nil,
				A2(
					$author$project$UI$Form$withUnder,
					$author$project$UI$Button$button(
						A4(
							$author$project$UI$Button$ButtonSpecs,
							$elm$core$Maybe$Just($author$project$Page$Notification$PressedBackToViewing),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$underline]),
								$mdgriffith$elm_ui$Element$text('Назад')),
							$author$project$UI$Button$Borderless,
							_List_Nil)),
					A4(
						$author$project$UI$Form$withButton,
						false,
						$elm$core$Maybe$Nothing,
						A4(
							$author$project$UI$Button$ButtonSpecs,
							$elm$core$Maybe$Just($author$project$Page$Notification$PressedSubmit),
							$mdgriffith$elm_ui$Element$text('Сохранить'),
							$author$project$UI$Button$Filled,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX])),
						A3(
							$author$project$UI$Form$ifTrue,
							true,
							$author$project$UI$Form$withExternal(
								A3(
									$author$project$UI$RichTextEditor$richTextEditor,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									'notificationEditor',
									form.content)),
							A3(
								$author$project$UI$Form$ifTrue,
								!_Utils_eq(form.category, $author$project$Domain$PublicNotification),
								A2(
									$elm$core$Basics$composeR,
									$author$project$UI$Form$withExternal(
										A3(
											categoryItemsSelectionList,
											true,
											$author$project$Page$Notification$SetNotificationTargetSelection(false),
											form)),
									A2(
										$elm$core$Basics$composeR,
										$author$project$UI$Form$withField(form.search),
										$author$project$UI$Form$withExternal(
											A3(
												categoryItemsSelectionList,
												false,
												$author$project$Page$Notification$SetNotificationTargetSelection(true),
												form)))),
								A4(
									$author$project$UI$Form$withRow,
									false,
									_List_Nil,
									A3(
										$elm$core$Basics$apR,
										A2(
											$elm$core$List$map,
											A2(
												$elm$core$Basics$composeR,
												categoryButton(form),
												A2($author$project$UI$Form$withButton, false, $elm$core$Maybe$Nothing)),
											_List_fromArray(
												[$author$project$Domain$PublicNotification, $author$project$Domain$PersonalNotification, $author$project$Domain$FlatNotification, $author$project$Domain$BuildingNotification])),
										A2($elm$core$List$foldl, $elm$core$Basics$composeR, $elm$core$Basics$identity),
										$author$project$UI$Form$empty),
									A2(
										$author$project$UI$Form$withField,
										form.title,
										A2($author$project$UI$Form$withTitle, formTitle, $author$project$UI$Form$empty))))))));
		});
	var _v0 = model.state;
	switch (_v0.$) {
		case 'Viewing':
			switch (_v0.b.b.$) {
				case 'NotStarted':
					var _v1 = _v0.b;
					var _v2 = _v1.b;
					return A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY, $mdgriffith$elm_ui$Element$Font$center]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Что-то пошло не так... Перезагрузите страницу!')
							]));
				case 'Failed':
					var _v3 = _v0.b;
					var message = _v3.b.a.message;
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY, $mdgriffith$elm_ui$Element$Font$center]),
						A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$singleton,
									$mdgriffith$elm_ui$Element$paragraph(_List_Nil))),
							message));
				case 'Loading':
					if (_v0.a.$ === 'Nothing') {
						var _v4 = _v0.a;
						var _v5 = _v0.b;
						var _v6 = _v5.b;
						return A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, $author$project$UI$Loading$spinner);
					} else {
						var preview = _v0.a.a;
						var _v7 = _v0.b;
						var _v8 = _v7.b;
						return A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, $author$project$UI$Loading$spinner);
					}
				default:
					var _v9 = _v0.b;
					var notification = _v9.b.a;
					var caption = A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$center,
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Font$extraBold,
								$author$project$UI$fontsizes.huge,
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
							]),
						$elm$core$List$singleton(
							$mdgriffith$elm_ui$Element$text(notification.domain.title)));
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								A2($mdgriffith$elm_ui$Element$maximum, 1000, $mdgriffith$elm_ui$Element$fill)),
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$centerY,
								A2(
								$mdgriffith$elm_ui$Element$paddingXY,
								$author$project$UI$scaled(-4),
								$author$project$UI$scaled(1))
							]),
						A2(
							$author$project$UI$Card$contentCard,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$html(
									$author$project$UI$withGlobalBackgroundImage(require('/build/front/public/background.webp'))),
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									function () {
										var _v10 = $author$project$Session$getLocalStorageData(model.session);
										if ((_v10.$ === 'Just') && (_v10.a.$ === 'ManagerLocalStorage')) {
											var authToken = _v10.a.a.authToken;
											return _List_fromArray(
												[
													A2(
													$author$project$UI$managerEditLinkCompact,
													notification.meta.collectionId,
													$elm$core$Maybe$Just(notification.meta.id)),
													$author$project$UI$Button$button(
													A4(
														$author$project$UI$Button$ButtonSpecs,
														$elm$core$Maybe$Just(
															$author$project$Page$Notification$PressedEdit(authToken)),
														A2(
															$author$project$UI$materialIcon,
															'edit_note',
															$author$project$UI$scaled(5)),
														$author$project$UI$Button$Borderless,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$padding(0)
															]))),
													$author$project$UI$Button$button(
													A4(
														$author$project$UI$Button$ButtonSpecs,
														$elm$core$Maybe$Just(
															$author$project$Page$Notification$PressedDelete(authToken)),
														A2(
															$author$project$UI$materialIcon,
															'delete',
															$author$project$UI$scaled(5)),
														$author$project$UI$Button$Borderless,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$padding(0)
															]))),
													$author$project$Page$Notification$meta(notification.domain)
												]);
										} else {
											return _List_fromArray(
												[
													$author$project$Page$Notification$meta(notification.domain)
												]);
										}
									}()),
									caption,
									A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, notification.domain.content),
									A2(
									$mdgriffith$elm_ui$Element$map,
									$elm$core$Basics$never,
									A2(
										$mdgriffith$elm_ui$Element$wrappedRow,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(
												$author$project$UI$scaled(-2))
											]),
										A2($elm$core$List$map, $author$project$UI$Card$attachment, notification.domain.attachments)))
								])));
			}
		case 'New':
			var form = _v0.a;
			return A2(notificationEditForm, 'Создание записи', form);
		default:
			if (_v0.b.b.$ === 'Loaded') {
				var _v11 = _v0.b;
				var form = _v0.c;
				return A2(notificationEditForm, 'Редактирование записи', form);
			} else {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
					$mdgriffith$elm_ui$Element$text('Что-то пошло не так, перезагрузите страницу!'));
			}
	}
};
var $author$project$Page$Notification$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Public,
	{
		baseUpdated: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, $author$project$Page$Notification$UpdatedBaseModel),
		init: $author$project$Page$Notification$init,
		subs: $author$project$Page$Notification$subscriptions,
		title: $author$project$Page$Notification$title,
		update: $author$project$Framework$EffectUpdate($author$project$Page$Notification$update),
		view: $author$project$Framework$PlainView($author$project$Page$Notification$view),
		wrapArgs: F2(
			function (base, args) {
				return {device: base.device, session: base.session, state: args.state};
			})
	});
var $author$project$Page$NotificationList$UpdatedBaseModel = function (a) {
	return {$: 'UpdatedBaseModel', a: a};
};
var $author$project$Page$NotificationList$FilterState = F4(
	function (_public, personal, flats, buildings) {
		return {buildings: buildings, flats: flats, personal: personal, _public: _public};
	});
var $author$project$Page$NotificationList$GotBuildings = function (a) {
	return {$: 'GotBuildings', a: a};
};
var $author$project$Page$NotificationList$GotFlats = function (a) {
	return {$: 'GotFlats', a: a};
};
var $author$project$Page$NotificationList$GotPersonal = function (a) {
	return {$: 'GotPersonal', a: a};
};
var $author$project$Page$NotificationList$GotPublic = function (a) {
	return {$: 'GotPublic', a: a};
};
var $author$project$Domain$NotificationPreview = F5(
	function (id, title, category, published, target) {
		return {category: category, id: id, published: published, target: target, title: title};
	});
var $author$project$Domain$notificationPreviewTargetDecoder = function (category) {
	switch (category.$) {
		case 'PublicNotification':
			return $elm$json$Json$Decode$succeed($author$project$Domain$Public);
		case 'PersonalNotification':
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Domain$Personal(_List_Nil),
				A2($elm$json$Json$Decode$field, 'targets', $elm$json$Json$Decode$int));
		case 'FlatNotification':
			return A3(
				$elm$json$Json$Decode$map2,
				$author$project$Domain$Flats,
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$List$singleton,
					A2($elm$json$Json$Decode$field, 'flat_id', $elm$json$Json$Decode$string)),
				A2($elm$json$Json$Decode$field, 'targets', $elm$json$Json$Decode$int));
		default:
			return A3(
				$elm$json$Json$Decode$map2,
				$author$project$Domain$Buildings,
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$List$singleton,
					A2($elm$json$Json$Decode$field, 'building_id', $elm$json$Json$Decode$string)),
				A2($elm$json$Json$Decode$field, 'targets', $elm$json$Json$Decode$int));
	}
};
var $author$project$Domain$notificationPreviewDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Domain$NotificationPreview,
	A2($elm$json$Json$Decode$field, 'notification_id', $author$project$Domain$PB$idDecoder),
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Domain$notificationCategoryDecoder,
		A2($elm$json$Json$Decode$field, 'category', $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'created', $author$project$Utils$parseTimestamp),
	A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Domain$notificationPreviewTargetDecoder,
		A2(
			$elm$json$Json$Decode$andThen,
			$author$project$Domain$notificationCategoryDecoder,
			A2($elm$json$Json$Decode$field, 'category', $elm$json$Json$Decode$string))));
var $author$project$Collections$getNotificationPreviews = function () {
	var call = function (fragment) {
		return A2(
			$author$project$API$getAll,
			'notification_preview_' + fragment,
			A2($author$project$API$Get, $author$project$Domain$notificationPreviewDecoder, _List_Nil));
	};
	return {
		buildings: call('buildings'),
		flats: call('flats'),
		personal: call('personal'),
		_public: call('public')
	};
}();
var $author$project$Page$NotificationList$GotCollectionID = function (a) {
	return {$: 'GotCollectionID', a: a};
};
var $author$project$Collections$getCollectionID = F2(
	function (authHeader, collectionName) {
		return $elm$http$Http$task(
			{
				body: $elm$http$Http$emptyBody,
				headers: _List_fromArray(
					[authHeader]),
				method: 'GET',
				resolver: $author$project$API$jsonResolver($elm$json$Json$Decode$string),
				timeout: $author$project$API$timeout,
				url: '/manage/collection-id/' + collectionName
			});
	});
var $author$project$Page$NotificationList$requestCollectionID = function (session) {
	var _v0 = $author$project$Session$getViewer(session);
	if ((_v0.$ === 'Authenticated') && (_v0.a.b.$ === 'ManagerLocalStorage')) {
		var _v1 = _v0.a;
		var authToken = _v1.b.a.authToken;
		return A2(
			$elm$core$Task$attempt,
			$author$project$Page$NotificationList$GotCollectionID,
			A2(
				$author$project$Collections$getCollectionID,
				$author$project$Session$authorizationHeader(authToken),
				'notification'));
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Page$NotificationList$requestNotificationList = F3(
	function (session, get, message) {
		return A2(
			$elm$core$Task$attempt,
			message,
			A2(
				get,
				A2($author$project$Session$withAuth, session, _List_Nil),
				_List_Nil));
	});
var $author$project$Page$NotificationList$init = function (args) {
	var loadingOrNotStarted = function () {
		var _v3 = $author$project$Session$getViewer(args.session);
		if (_v3.$ === 'Guest') {
			return $author$project$API$Status$NotStarted;
		} else {
			return $author$project$API$Status$Loading;
		}
	}();
	return _Utils_Tuple2(
		{
			buildings: loadingOrNotStarted,
			device: args.device,
			filter: A4($author$project$Page$NotificationList$FilterState, true, true, true, true),
			flats: loadingOrNotStarted,
			managerUtilityData: function () {
				var _v0 = $author$project$Session$getViewer(args.session);
				if ((_v0.$ === 'Authenticated') && (_v0.a.b.$ === 'ManagerLocalStorage')) {
					var _v1 = _v0.a;
					return {collectionID: $author$project$API$Status$Loading};
				} else {
					return {collectionID: $author$project$API$Status$NotStarted};
				}
			}(),
			notifications: $elm$core$Dict$empty,
			personal: loadingOrNotStarted,
			_public: $author$project$API$Status$Loading,
			session: args.session
		},
		$elm$core$Platform$Cmd$batch(
			A2(
				$elm$core$List$cons,
				$author$project$Page$NotificationList$requestCollectionID(args.session),
				A3(
					$elm$core$List$map2,
					function (f) {
						return A2(
							$author$project$Page$NotificationList$requestNotificationList,
							args.session,
							f($author$project$Collections$getNotificationPreviews));
					},
					_List_fromArray(
						[
							function ($) {
							return $._public;
						},
							function ($) {
							return $.personal;
						},
							function ($) {
							return $.flats;
						},
							function ($) {
							return $.buildings;
						}
						]),
					function () {
						var _v2 = $author$project$Session$getViewer(args.session);
						if (_v2.$ === 'Authenticated') {
							return _List_fromArray(
								[$author$project$Page$NotificationList$GotPublic, $author$project$Page$NotificationList$GotPersonal, $author$project$Page$NotificationList$GotFlats, $author$project$Page$NotificationList$GotBuildings]);
						} else {
							return _List_fromArray(
								[$author$project$Page$NotificationList$GotPublic]);
						}
					}()))));
};
var $author$project$Page$NotificationList$mergeNotifications = F2(
	function (preview, dict) {
		return A3(
			$elm$core$Dict$update,
			preview.id,
			function (have) {
				if (have.$ === 'Nothing') {
					return $elm$core$Maybe$Just(preview);
				} else {
					var old = have.a;
					var _v1 = _Utils_Tuple2(old.target, preview.target);
					_v1$2:
					while (true) {
						switch (_v1.a.$) {
							case 'Flats':
								if (_v1.b.$ === 'Flats') {
									var _v2 = _v1.a;
									var oldFlats = _v2.a;
									var n = _v2.b;
									var _v3 = _v1.b;
									var newFlats = _v3.a;
									return $elm$core$Maybe$Just(
										_Utils_update(
											preview,
											{
												target: A2(
													$author$project$Domain$Flats,
													_Utils_ap(oldFlats, newFlats),
													n)
											}));
								} else {
									break _v1$2;
								}
							case 'Buildings':
								if (_v1.b.$ === 'Buildings') {
									var _v4 = _v1.a;
									var oldBuildings = _v4.a;
									var n = _v4.b;
									var _v5 = _v1.b;
									var newBuildings = _v5.a;
									return $elm$core$Maybe$Just(
										_Utils_update(
											preview,
											{
												target: A2(
													$author$project$Domain$Buildings,
													_Utils_ap(oldBuildings, newBuildings),
													n)
											}));
								} else {
									break _v1$2;
								}
							default:
								break _v1$2;
						}
					}
					return $elm$core$Maybe$Nothing;
				}
			},
			dict);
	});
var $author$project$Page$NotificationList$showLoadingErrorPopUp = F2(
	function (resource, errorCode) {
		return A2(
			$author$project$Framework$ShowPopUp,
			A2(
				$author$project$PopUp$PlainText,
				'Ошибка!',
				'Не удалось загрузить ' + (resource + ('. ' + function () {
					switch (errorCode.$) {
						case 'NetworkError':
							return 'Попробуйте перезагрузить страницу.';
						case 'Timeout':
							return 'Попробуйте перезагрузить страницу.';
						default:
							return 'Сообщите в техподдержку!';
					}
				}()))),
			$author$project$PopUp$Important);
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $author$project$Page$NotificationList$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'UpdatedBaseModel':
				var base = msg.a;
				var session = base.session;
				var newModel = A2($author$project$Framework$updateBase, model, base);
				var cmd = function () {
					var _v1 = $author$project$Session$getViewer(session);
					if (_v1.$ === 'Guest') {
						return $elm$core$Platform$Cmd$none;
					} else {
						return $elm$core$Platform$Cmd$batch(
							A2(
								$elm$core$List$cons,
								$author$project$Page$NotificationList$requestCollectionID(session),
								A2(
									$elm$core$List$filterMap,
									function (_v2) {
										var status = _v2.a;
										var fragment = _v2.b;
										var message = _v2.c;
										if (status.$ === 'NotStarted') {
											return $elm$core$Maybe$Just(
												A3(
													$author$project$Page$NotificationList$requestNotificationList,
													session,
													fragment($author$project$Collections$getNotificationPreviews),
													message));
										} else {
											return $elm$core$Maybe$Nothing;
										}
									},
									_List_fromArray(
										[
											_Utils_Tuple3(
											model.personal,
											function ($) {
												return $.personal;
											},
											$author$project$Page$NotificationList$GotPersonal),
											_Utils_Tuple3(
											model.flats,
											function ($) {
												return $.flats;
											},
											$author$project$Page$NotificationList$GotFlats),
											_Utils_Tuple3(
											model.buildings,
											function ($) {
												return $.buildings;
											},
											$author$project$Page$NotificationList$GotBuildings)
										]))));
					}
				}();
				return _Utils_Tuple3(newModel, cmd, $author$project$Framework$NoOp);
			case 'GotPublic':
				if (msg.a.$ === 'Ok') {
					var result = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								notifications: A2(
									$elm$core$Dict$union,
									model.notifications,
									$elm$core$Dict$fromList(
										A2(
											$elm$core$List$map,
											function (x) {
												return _Utils_Tuple2(x.id, x);
											},
											result))),
								_public: $author$project$API$Status$Loaded(result)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					var errorCode = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{_public: $author$project$API$Status$NotStarted}),
						$elm$core$Platform$Cmd$none,
						A2($author$project$Page$NotificationList$showLoadingErrorPopUp, 'общедоступные новости', errorCode));
				}
			case 'GotPersonal':
				if (msg.a.$ === 'Ok') {
					var result = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								notifications: A2(
									$elm$core$Dict$union,
									model.notifications,
									$elm$core$Dict$fromList(
										A2(
											$elm$core$List$map,
											function (x) {
												return _Utils_Tuple2(x.id, x);
											},
											result))),
								personal: $author$project$API$Status$Loaded(result)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					var errorCode = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{personal: $author$project$API$Status$NotStarted}),
						$elm$core$Platform$Cmd$none,
						A2($author$project$Page$NotificationList$showLoadingErrorPopUp, 'персональные новости', errorCode));
				}
			case 'GotFlats':
				if (msg.a.$ === 'Ok') {
					var result = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								flats: $author$project$API$Status$Loaded(result),
								notifications: A3($elm$core$List$foldl, $author$project$Page$NotificationList$mergeNotifications, model.notifications, result)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					var errorCode = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{flats: $author$project$API$Status$NotStarted}),
						$elm$core$Platform$Cmd$none,
						A2($author$project$Page$NotificationList$showLoadingErrorPopUp, 'новости для Ваших квартир', errorCode));
				}
			case 'GotBuildings':
				if (msg.a.$ === 'Ok') {
					var result = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								buildings: $author$project$API$Status$Loaded(result),
								notifications: A3($elm$core$List$foldl, $author$project$Page$NotificationList$mergeNotifications, model.notifications, result)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					var errorCode = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{buildings: $author$project$API$Status$NotStarted}),
						$elm$core$Platform$Cmd$none,
						A2($author$project$Page$NotificationList$showLoadingErrorPopUp, 'новости для Ваших домов', errorCode));
				}
			case 'UpdatedFilter':
				var filterState = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{filter: filterState}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'GotCollectionID':
				if (msg.a.$ === 'Err') {
					var errorCode = msg.a.a;
					var solution = function () {
						switch (errorCode.$) {
							case 'NetworkError':
								return 'Попробуйте перезагрузить страницу!';
							case 'Timeout':
								return 'Попробуйте перезагрузить страницу!';
							case 'NotFound':
								return 'Похоже, в структуре базы данных произошли важные изменения и ' + ('данная версия сайта не может корректно с ней работать. ' + 'Сообщите разработчику!');
							case 'Unauthenticated':
								return 'Ваша пользовательская сессия истекла. Требуется повторный вход!';
							default:
								return 'Сообщите разработчику о неожиданной ошибке.';
						}
					}();
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						A2(
							$author$project$Framework$ShowPopUp,
							A2($author$project$PopUp$PlainText, 'Ошибка!', 'Не удалось получить данные для создания новостей. ' + solution),
							$author$project$PopUp$Important));
				} else {
					var collectionID = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								managerUtilityData: {
									collectionID: $author$project$API$Status$Loaded(collectionID)
								}
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				}
			default:
				return _Utils_Tuple3(
					model,
					$elm$browser$Browser$Navigation$load('/notification/new'),
					$author$project$Framework$NoOp);
		}
	});
var $author$project$UI$emptyContentHint = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$centerY,
			$mdgriffith$elm_ui$Element$Font$semiBold,
			$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_20),
			$author$project$UI$preventSelect
		]),
	$mdgriffith$elm_ui$Element$text('Пусто!'));
var $author$project$Page$NotificationList$PressedCreate = {$: 'PressedCreate'};
var $author$project$Page$NotificationList$UpdatedFilter = function (a) {
	return {$: 'UpdatedFilter', a: a};
};
var $author$project$UI$filterIcon = F4(
	function (icon, description, shown, message) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			A3(
				$author$project$UI$withTooltip,
				A2($author$project$UI$TooltipPosition, $author$project$UI$TooltipAbove, $author$project$UI$TooltipLeft),
				description,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color(
						shown ? $author$project$UI$colors.primary : $author$project$UI$colors.neutral_20),
						$mdgriffith$elm_ui$Element$Events$onClick(message)
					])),
			A2(
				$author$project$UI$materialIcon,
				icon,
				$author$project$UI$scaled(4)));
	});
var $author$project$Page$NotificationList$filterCard = function (model) {
	var filter = model.filter;
	return A2(
		$mdgriffith$elm_ui$Element$wrappedRow,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(
				$author$project$UI$scaled(1)),
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(1)),
				$mdgriffith$elm_ui$Element$alignRight,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				function () {
				var _v0 = _Utils_Tuple2(
					$author$project$Session$getViewer(model.session),
					model.managerUtilityData.collectionID);
				if (((_v0.a.$ === 'Authenticated') && (_v0.a.a.b.$ === 'ManagerLocalStorage')) && (_v0.b.$ === 'Loaded')) {
					var _v1 = _v0.a.a;
					var collectionID = _v0.b.a;
					return A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$alignLeft
							]),
						_List_fromArray(
							[
								A2($author$project$UI$managerEditLinkCompact, collectionID, $elm$core$Maybe$Nothing),
								$author$project$UI$Button$button(
								A4(
									$author$project$UI$Button$ButtonSpecs,
									$elm$core$Maybe$Just($author$project$Page$NotificationList$PressedCreate),
									A2(
										$author$project$UI$materialIcon,
										'add',
										$author$project$UI$scaled(5)),
									$author$project$UI$Button$Borderless,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$padding(0)
										])))
							]));
				} else {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						$mdgriffith$elm_ui$Element$none);
				}
			}(),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60)
					]),
				$mdgriffith$elm_ui$Element$text('Фильтр')),
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignRight]),
				A2(
					$elm$core$List$map,
					function (_v2) {
						var category = _v2.a;
						var shown = _v2.b;
						var message = _v2.c;
						return A4(
							$author$project$UI$filterIcon,
							$author$project$Page$Notification$categoryIcon(category),
							$author$project$Page$Notification$categoryName(category),
							shown,
							message);
					},
					_List_fromArray(
						[
							_Utils_Tuple3(
							$author$project$Domain$PublicNotification,
							filter._public,
							$author$project$Page$NotificationList$UpdatedFilter(
								_Utils_update(
									filter,
									{_public: !filter._public}))),
							_Utils_Tuple3(
							$author$project$Domain$PersonalNotification,
							filter.personal,
							$author$project$Page$NotificationList$UpdatedFilter(
								_Utils_update(
									filter,
									{personal: !filter.personal}))),
							_Utils_Tuple3(
							$author$project$Domain$FlatNotification,
							filter.flats,
							$author$project$Page$NotificationList$UpdatedFilter(
								_Utils_update(
									filter,
									{flats: !filter.flats}))),
							_Utils_Tuple3(
							$author$project$Domain$BuildingNotification,
							filter.buildings,
							$author$project$Page$NotificationList$UpdatedFilter(
								_Utils_update(
									filter,
									{buildings: !filter.buildings})))
						])))
			]));
};
var $elmcraft$core_extra$List$Extra$removeWhen = F2(
	function (pred, list) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, pred),
			list);
	});
var $author$project$UI$listWithDefault = F2(
	function (_default, content) {
		return $elm$core$List$isEmpty(
			A2(
				$elmcraft$core_extra$List$Extra$removeWhen,
				$elm$core$Basics$eq($mdgriffith$elm_ui$Element$none),
				content)) ? _default : content;
	});
var $mdgriffith$elm_ui$Element$Font$alignRight = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.textRight);
var $author$project$Page$NotificationList$displayTargets = F2(
	function (viewer, preview) {
		return A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
					$mdgriffith$elm_ui$Element$Font$alignRight,
					$author$project$UI$fontsizes.small
				]),
			$elm$core$List$singleton(
				function () {
					var _v0 = preview.target;
					switch (_v0.$) {
						case 'Public':
							return $mdgriffith$elm_ui$Element$none;
						case 'Personal':
							var targets = _v0.b;
							return $mdgriffith$elm_ui$Element$text(
								function () {
									var _v1 = _Utils_Tuple2(viewer, targets);
									_v1$0:
									while (true) {
										switch (_v1.b) {
											case 1:
												if ((_v1.a.$ === 'Authenticated') && (_v1.a.a.b.$ === 'ManagerLocalStorage')) {
													break _v1$0;
												} else {
													return 'Только для Вас';
												}
											case 2:
												if ((_v1.a.$ === 'Authenticated') && (_v1.a.a.b.$ === 'ManagerLocalStorage')) {
													break _v1$0;
												} else {
													return 'Для Вас и ещё 1 человека';
												}
											default:
												if ((_v1.a.$ === 'Authenticated') && (_v1.a.a.b.$ === 'ManagerLocalStorage')) {
													break _v1$0;
												} else {
													return A2(
														$elm$core$String$join,
														' ',
														_List_fromArray(
															[
																'Для Вас и ещё',
																$elm$core$String$fromInt(targets - 1),
																'человек'
															]));
												}
										}
									}
									var _v2 = _v1.a.a;
									return 'Для ' + ($elm$core$String$fromInt(targets) + ' человек(а)');
								}());
						case 'Flats':
							var flats = _v0.a;
							var targets = _v0.b;
							if (viewer.$ === 'Guest') {
								return $mdgriffith$elm_ui$Element$none;
							} else {
								if (viewer.a.b.$ === 'TenantLocalStorage') {
									var _v4 = viewer.a;
									var user = _v4.b.a.user;
									var _v5 = $author$project$API$Resource$toMaybe(user.domain.flat);
									if (_v5.$ === 'Nothing') {
										return $mdgriffith$elm_ui$Element$none;
									} else {
										var userFlats = _v5.a;
										var flatStrings = A2(
											$elm$core$List$map,
											function (f) {
												return $elm$core$String$concat(
													_List_fromArray(
														[
															A2(
															$elm$core$Maybe$withDefault,
															'',
															A2(
																$elm$core$Maybe$map,
																function (b) {
																	return b.domain.address + ', ';
																},
																$author$project$API$Resource$toMaybe(f.domain.building))),
															'кв. ',
															$elm$core$String$fromInt(f.domain.number)
														]));
											},
											A2(
												$elm$core$List$filter,
												function (f) {
													return A2($elm$core$List$member, f.meta.id, flats);
												},
												userFlats));
										return $mdgriffith$elm_ui$Element$text(
											function () {
												if (flatStrings.b && (!flatStrings.b.b)) {
													var flatString = flatStrings.a;
													return 'Для ' + flatString;
												} else {
													return A2(
														$elm$core$String$join,
														' ',
														_Utils_eq(
															targets,
															$elm$core$List$length(flatStrings)) ? _List_fromArray(
															[
																'Для',
																A2($elm$core$String$join, '; ', flatStrings)
															]) : _List_fromArray(
															[
																'Для',
																A2($elm$core$String$join, '; ', flatStrings),
																'и ещё',
																$elm$core$String$fromInt(
																targets - $elm$core$List$length(flatStrings)),
																'квартир'
															]));
												}
											}());
									}
								} else {
									var _v7 = viewer.a;
									return $mdgriffith$elm_ui$Element$text(
										'Для ' + ($elm$core$String$fromInt(targets) + ' квартир'));
								}
							}
						default:
							var buildings = _v0.a;
							var targets = _v0.b;
							if (viewer.$ === 'Guest') {
								return $mdgriffith$elm_ui$Element$none;
							} else {
								if (viewer.a.b.$ === 'TenantLocalStorage') {
									var _v9 = viewer.a;
									var user = _v9.b.a.user;
									var _v10 = $author$project$API$Resource$toMaybe(user.domain.flat);
									if (_v10.$ === 'Nothing') {
										return $mdgriffith$elm_ui$Element$none;
									} else {
										var flats = _v10.a;
										var addresses = A2(
											$elm$core$List$filterMap,
											function (f) {
												return A2(
													$elm$core$List$member,
													$author$project$API$Resource$toMeta(f.domain.building),
													buildings) ? A2(
													$elm$core$Maybe$map,
													A2(
														$elm$core$Basics$composeR,
														function ($) {
															return $.domain;
														},
														function ($) {
															return $.address;
														}),
													$author$project$API$Resource$toMaybe(f.domain.building)) : $elm$core$Maybe$Nothing;
											},
											flats);
										if (addresses.b && (!addresses.b.b)) {
											var address = addresses.a;
											return $mdgriffith$elm_ui$Element$text('Для ' + address);
										} else {
											return $mdgriffith$elm_ui$Element$text(
												A2(
													$elm$core$String$join,
													' ',
													_Utils_eq(
														targets,
														$elm$core$List$length(addresses)) ? _List_fromArray(
														[
															'Для',
															A2($elm$core$String$join, '; ', addresses)
														]) : _List_fromArray(
														[
															'Для',
															A2($elm$core$String$join, '; ', addresses),
															'и',
															$elm$core$String$fromInt(
															targets - $elm$core$List$length(addresses)),
															'других домов'
														])));
										}
									}
								} else {
									var _v12 = viewer.a;
									return $mdgriffith$elm_ui$Element$text(
										'Для ' + ($elm$core$String$fromInt(targets) + ' домов'));
								}
							}
					}
				}()));
	});
var $author$project$Page$NotificationList$previewCard = F2(
	function (viewer, preview) {
		return A2(
			$mdgriffith$elm_ui$Element$link,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			{
				label: A2(
					$author$project$UI$Card$contentCard,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(
									$author$project$UI$scaled(1)),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
											$mdgriffith$elm_ui$Element$Font$bold
										]),
									$elm$core$List$singleton(
										$mdgriffith$elm_ui$Element$text(preview.title))),
									$author$project$Page$Notification$meta(preview)
								])),
							A2(
							$mdgriffith$elm_ui$Element$map,
							$elm$core$Basics$never,
							A2($author$project$Page$NotificationList$displayTargets, viewer, preview))
						])),
				url: '/notification/' + preview.id
			});
	});
var $elm$core$List$sortBy = _List_sortBy;
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Page$NotificationList$view = function (model) {
	var ready = A2(
		$elm$core$List$sortBy,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.published;
			},
			A2(
				$elm$core$Basics$composeR,
				$elm$time$Time$posixToMillis,
				$elm$core$Basics$mul(-1))),
		A2(
			$elm$core$List$filter,
			function (preview) {
				var _v0 = preview.target;
				switch (_v0.$) {
					case 'Public':
						return model.filter._public;
					case 'Personal':
						return model.filter.personal;
					case 'Flats':
						return model.filter.flats;
					default:
						return model.filter.buildings;
				}
			},
			$elm$core$Dict$values(model.notifications)));
	return A2(
		$author$project$UI$Card$contentCard,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$html(
				$author$project$UI$withGlobalBackgroundImage(require('/build/front/public/background.webp'))),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$author$project$UI$fontsizes.bigger,
						$mdgriffith$elm_ui$Element$Font$bold,
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
						$mdgriffith$elm_ui$Element$paddingEach(
						{
							bottom: 0,
							left: 0,
							right: 0,
							top: $author$project$UI$scaled(1)
						}),
						$mdgriffith$elm_ui$Element$centerX
					]),
				$mdgriffith$elm_ui$Element$text('Уведомления и новости')),
				$author$project$Page$NotificationList$filterCard(model),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-1)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$author$project$UI$listWithDefault,
					_List_fromArray(
						[$author$project$UI$emptyContentHint]),
					A2(
						$elm$core$List$map,
						$author$project$Page$NotificationList$previewCard(
							$author$project$Session$getViewer(model.session)),
						ready)))
			]));
};
var $author$project$Page$NotificationList$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Public,
	{
		baseUpdated: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, $author$project$Page$NotificationList$UpdatedBaseModel),
		init: $author$project$Page$NotificationList$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: function (_v0) {
			return 'Новости и уведомления';
		},
		update: $author$project$Framework$EffectUpdate($author$project$Page$NotificationList$update),
		view: $author$project$Framework$PlainView($author$project$Page$NotificationList$view),
		wrapArgs: F2(
			function (args, _v1) {
				return args;
			})
	});
var $author$project$Page$Orders$accessFilter = function (args) {
	var _v0 = $author$project$Session$getViewer(args.session);
	if (_v0.$ === 'Authenticated') {
		return $author$project$Framework$AccessGranted;
	} else {
		return $author$project$Framework$AuthenticationRequired;
	}
};
var $author$project$Page$Orders$GotServiceRequests = function (a) {
	return {$: 'GotServiceRequests', a: a};
};
var $author$project$Collections$getServiceRequests = function (authHeader) {
	return A3(
		$author$project$API$getAll,
		'service_request',
		A2(
			$author$project$API$Get,
			$author$project$Domain$PB$decoderWithID($author$project$Domain$serviceRequestDecoder),
			_List_fromArray(
				[
					$author$project$API$Expand(
					_List_fromArray(
						['service', 'flat', 'flat.building'])),
					$author$project$API$Sort(
					_List_fromArray(
						['-created']))
				])),
		_List_fromArray(
			[authHeader]));
};
var $author$project$Page$Orders$init = function (args) {
	return _Utils_Tuple2(
		{device: args.device, serviceRequests: $author$project$API$Status$Loading, session: args.session},
		A2(
			$elm$core$Task$attempt,
			$author$project$Page$Orders$GotServiceRequests,
			A2(
				$author$project$Collections$getServiceRequests,
				$author$project$Session$authorizationHeader(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						$author$project$Session$getToken(args.session))),
				_List_Nil)));
};
var $author$project$Page$Orders$update = F2(
	function (msg, model) {
		var _v0 = $author$project$Session$getViewer(model.session);
		if (_v0.$ === 'Guest') {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		} else {
			if (msg.a.$ === 'Ok') {
				var requests = msg.a.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							serviceRequests: $author$project$API$Status$Loaded(requests)
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				return _Debug_todo(
					'Page.Orders',
					{
						start: {line: 91, column: 21},
						end: {line: 91, column: 31}
					})('branch \'GotServiceRequests (Err _)\' not implemented');
			}
		}
	});
var $author$project$Page$Orders$renderDate = F3(
	function (icon, time, tooltip) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(-3))
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					A3(
						$author$project$UI$withTooltip,
						A2($author$project$UI$TooltipPosition, $author$project$UI$TooltipBelow, $author$project$UI$TooltipLeft),
						tooltip,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
							])),
					A2(
						$author$project$UI$materialIcon,
						icon,
						$author$project$UI$scaled(3))),
					A2(
					$elm$core$Maybe$withDefault,
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
								$author$project$UI$fontsizes.small,
								$mdgriffith$elm_ui$Element$alignRight
							]),
						$mdgriffith$elm_ui$Element$text('--.--.----')),
					A2($elm$core$Maybe$map, $author$project$UI$Card$dateLabel, time))
				]));
	});
var $author$project$Page$Orders$renderServiceRequestStatus = function (status) {
	return function (_v1) {
		var icon = _v1.a;
		var label = _v1.b;
		var tooltip = _v1.c;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			A3(
				$author$project$UI$withTooltip,
				A2($author$project$UI$TooltipPosition, $author$project$UI$TooltipAbove, $author$project$UI$TooltipLeft),
				tooltip,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-3))
					])),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
						]),
					A2(
						$author$project$UI$materialIcon,
						icon,
						$author$project$UI$scaled(3))),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
							$author$project$UI$fontsizes.small
						]),
					$mdgriffith$elm_ui$Element$text(label))
				]));
	}(
		function () {
			switch (status.$) {
				case 'SRCreated':
					return _Utils_Tuple3('pending', 'Создан', 'Заказ ожидает обработки');
				case 'SRAssigned':
					return _Utils_Tuple3('work', 'В работе', 'Заказ передан исполнителям');
				case 'SRDone':
					return _Utils_Tuple3('check_circle', 'Выполнен', 'Услуга оказана');
				default:
					return _Utils_Tuple3('cancel', 'Отменён', 'Заказ отменён');
			}
		}());
};
var $author$project$Page$Orders$serviceRequestCard = F2(
	function (viewer, _v0) {
		var domain = _v0.domain;
		var meta = _v0.meta;
		return A2(
			$author$project$UI$Card$contentCard,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$UI$preventSelect,
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(-1))
				]),
			function () {
				var _v1 = _Utils_Tuple2(
					$author$project$API$Resource$toMaybe(domain.service),
					$author$project$API$Resource$toMaybe(domain.flat));
				if ((_v1.a.$ === 'Just') && (_v1.b.$ === 'Just')) {
					var service = _v1.a.a;
					return _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$spacing(
											$author$project$UI$scaled(1))
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											_List_fromArray(
												[
													function () {
													if ((viewer.$ === 'Authenticated') && (viewer.a.b.$ === 'ManagerLocalStorage')) {
														var _v3 = viewer.a;
														return A2(
															$author$project$UI$managerEditLinkCompact,
															meta.collectionId,
															$elm$core$Maybe$Just(meta.id));
													} else {
														return $mdgriffith$elm_ui$Element$none;
													}
												}(),
													A2(
													$mdgriffith$elm_ui$Element$paragraph,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$bold,
															$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
														]),
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$text(service.domain.name)
														]))
												])),
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$spacing(
													$author$project$UI$scaled(-3))
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
														]),
													A2(
														$author$project$UI$materialIcon,
														'tag',
														$author$project$UI$scaled(3))),
													$mdgriffith$elm_ui$Element$text(
													$elm$core$String$fromInt(domain.number) + (' ' + service.domain.unit))
												])),
											function () {
											var _v4 = $author$project$API$Resource$toMaybe(domain.flat);
											if (_v4.$ === 'Just') {
												var flat = _v4.a;
												return A2(
													$mdgriffith$elm_ui$Element$row,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$spacing(
															$author$project$UI$scaled(-3))
														]),
													_List_fromArray(
														[
															A2(
															$mdgriffith$elm_ui$Element$el,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
																]),
															A2(
																$author$project$UI$materialIcon,
																'apartment',
																$author$project$UI$scaled(3))),
															A2(
															$mdgriffith$elm_ui$Element$paragraph,
															_List_Nil,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$text(
																	$author$project$Domain$flatAddress(flat.domain))
																]))
														]));
											} else {
												return $mdgriffith$elm_ui$Element$none;
											}
										}()
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$alignRight,
											$mdgriffith$elm_ui$Element$alignTop,
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
											$mdgriffith$elm_ui$Element$spacing(
											$author$project$UI$scaled(-1))
										]),
									_List_fromArray(
										[
											A3($author$project$Page$Orders$renderDate, 'start', domain.created, 'Дата создания'),
											A3($author$project$Page$Orders$renderDate, 'schedule', domain.when, 'Желаемая дата выполнения'),
											A2(
											$elm$core$Maybe$withDefault,
											$mdgriffith$elm_ui$Element$none,
											A2($elm$core$Maybe$map, $author$project$Page$Orders$renderServiceRequestStatus, domain.status))
										]))
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
									$mdgriffith$elm_ui$Element$htmlAttribute(
									$elm$html$Html$Attributes$class('render-new-lines'))
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(domain.comment)
								]))
						]);
				} else {
					return _List_Nil;
				}
			}());
	});
var $author$project$Page$Orders$view = function (model) {
	var _v0 = model.serviceRequests;
	switch (_v0.$) {
		case 'Loading':
			return $author$project$UI$Loading$spinner;
		case 'NotStarted':
			return _Debug_todo(
				'Page.Orders',
				{
					start: {line: 101, column: 13},
					end: {line: 101, column: 23}
				})('branch \'NotStarted\' not implemented');
		case 'Failed':
			return _Debug_todo(
				'Page.Orders',
				{
					start: {line: 104, column: 13},
					end: {line: 104, column: 23}
				})('branch \'Failed _\' not implemented');
		default:
			if (!_v0.a.b) {
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$Font$center,
							$author$project$UI$fontsizes.big,
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(1))
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Ваш список заказов пуст!')
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Чтобы заказать услугу, перейдите в раздел '),
									A2(
									$mdgriffith$elm_ui$Element$link,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
										]),
									{
										label: $mdgriffith$elm_ui$Element$text('\"Услуги\"'),
										url: '/service-category/'
									})
								]))
						]));
			} else {
				var requests = _v0.a;
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$padding(
							$author$project$UI$scaled(-1)),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-1))
						]),
					A2(
						$elm$core$List$map,
						$author$project$Page$Orders$serviceRequestCard(
							$author$project$Session$getViewer(model.session)),
						requests));
			}
	}
};
var $author$project$Page$Orders$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Protected($author$project$Page$Orders$accessFilter),
	{
		baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
		init: $author$project$Page$Orders$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: function (_v0) {
			return 'Заказы';
		},
		update: $author$project$Framework$PlainUpdate($author$project$Page$Orders$update),
		view: $author$project$Framework$PlainView($author$project$Page$Orders$view),
		wrapArgs: F2(
			function (args, _v1) {
				return args;
			})
	});
var $author$project$Page$ResetPassword$UpdatedBaseModel = function (a) {
	return {$: 'UpdatedBaseModel', a: a};
};
var $author$project$Page$ResetPassword$EmailStage = F2(
	function (a, b) {
		return {$: 'EmailStage', a: a, b: b};
	});
var $author$project$Page$ResetPassword$PasswordStage = F4(
	function (a, b, c, d) {
		return {$: 'PasswordStage', a: a, b: b, c: c, d: d};
	});
var $author$project$Page$ResetPassword$Prompting = {$: 'Prompting'};
var $author$project$Page$ResetPassword$UpdatedEmailField = function (a) {
	return {$: 'UpdatedEmailField', a: a};
};
var $author$project$Page$ResetPassword$UpdatedPasswordAgainField = function (a) {
	return {$: 'UpdatedPasswordAgainField', a: a};
};
var $author$project$Page$ResetPassword$UpdatedPasswordField = function (a) {
	return {$: 'UpdatedPasswordField', a: a};
};
var $author$project$Page$ResetPassword$init = function (args) {
	var defaultPasswordAgain = $author$project$UI$Form$Text$default($author$project$Page$ResetPassword$UpdatedPasswordAgainField);
	var passwordAgainField = _Utils_update(
		defaultPasswordAgain,
		{
			kind: A2($author$project$UI$Form$Text$Password, false, $mdgriffith$elm_ui$Element$Input$newPassword),
			name: 'Повторите пароль',
			required: $author$project$UI$Form$Required
		});
	var defaultPassword = $author$project$UI$Form$Text$default($author$project$Page$ResetPassword$UpdatedPasswordField);
	var passwordField = _Utils_update(
		defaultPassword,
		{
			check: A2($author$project$UI$Form$simpleCheck, $author$project$UI$Form$Text$validatePassword, 'Пароль должен быть длиннее 7 символов и содержать ' + ('строчные и заглавные латинские буквы, цифры ' + 'и знаки препинания')),
			kind: A2($author$project$UI$Form$Text$Password, false, $mdgriffith$elm_ui$Element$Input$newPassword),
			name: 'Пароль',
			required: $author$project$UI$Form$Required
		});
	var defaultEmail = $author$project$UI$Form$Text$default($author$project$Page$ResetPassword$UpdatedEmailField);
	var emailField = _Utils_update(
		defaultEmail,
		{
			check: A2($author$project$UI$Form$simpleCheck, $author$project$UI$Form$Text$validateEmail, 'Неверный формат email!'),
			focusedOnLoad: true,
			hint: $elm$core$Maybe$Just('example@gmail.com'),
			kind: $author$project$UI$Form$Text$Regular($mdgriffith$elm_ui$Element$Input$email),
			name: 'Адрес электронной почты',
			required: $author$project$UI$Form$Required
		});
	return _Utils_Tuple2(
		{
			device: args.device,
			session: args.session,
			state: (args.token === '') ? A2($author$project$Page$ResetPassword$EmailStage, emailField, $author$project$Page$ResetPassword$Prompting) : A4($author$project$Page$ResetPassword$PasswordStage, args.token, passwordField, passwordAgainField, $author$project$Page$ResetPassword$Prompting)
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$ResetPassword$Done = {$: 'Done'};
var $author$project$Page$ResetPassword$GotEmailResponse = function (a) {
	return {$: 'GotEmailResponse', a: a};
};
var $author$project$Page$ResetPassword$GotPasswordResponse = function (a) {
	return {$: 'GotPasswordResponse', a: a};
};
var $author$project$Page$ResetPassword$Requesting = {$: 'Requesting'};
var $author$project$Collections$confirmPasswordReset = F3(
	function (token, password, passwordAgain) {
		return $elm$http$Http$task(
			{
				body: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'token',
								$elm$json$Json$Encode$string(token)),
								_Utils_Tuple2(
								'password',
								$elm$json$Json$Encode$string(password)),
								_Utils_Tuple2(
								'passwordConfirm',
								$elm$json$Json$Encode$string(passwordAgain))
							]))),
				headers: _List_Nil,
				method: 'POST',
				resolver: $author$project$API$stringResolver(
					$elm$core$Basics$always(
						$elm$core$Result$Ok(_Utils_Tuple0))),
				timeout: $author$project$API$timeout,
				url: '/api/collections/tenant/confirm-password-reset'
			});
	});
var $author$project$Collections$requestPasswordReset = function (email) {
	return $elm$http$Http$task(
		{
			body: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'email',
							$elm$json$Json$Encode$string(email))
						]))),
			headers: _List_Nil,
			method: 'POST',
			resolver: $author$project$API$stringResolver(
				$elm$core$Basics$always(
					$elm$core$Result$Ok(_Utils_Tuple0))),
			timeout: $author$project$API$timeout,
			url: '/api/collections/tenant/request-password-reset'
		});
};
var $author$project$Page$ResetPassword$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(model.state, msg);
		_v0$13:
		while (true) {
			switch (_v0.b.$) {
				case 'UpdatedBaseModel':
					var base = _v0.b.a;
					return _Utils_Tuple3(
						A2($author$project$Framework$updateBase, model, base),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				case 'SessionRefreshed':
					if (_v0.b.a.$ === 'Err') {
						return _Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							A2(
								$author$project$Framework$ShowPopUp,
								A2($author$project$PopUp$PlainText, 'Не удалось обновить данные', 'Попробуйте перезагрузить страницу!'),
								$author$project$PopUp$Important));
					} else {
						if (_v0.b.a.a.b.$ === 'Authenticated') {
							var _v1 = _v0.b.a.a;
							var _v2 = _v1.b.a;
							var data = _v2.b;
							return _Utils_Tuple3(
								model,
								$elm$core$Platform$Cmd$none,
								$author$project$Framework$UpdateLocalStorage(data));
						} else {
							var _v3 = _v0.b.a.a;
							var _v4 = _v3.b;
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$ClearLocalStorage);
						}
					}
				case 'UpdatedEmailField':
					if ((_v0.a.$ === 'EmailStage') && (_v0.a.b.$ === 'Prompting')) {
						var _v5 = _v0.a;
						var _v6 = _v5.b;
						var specs = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A2($author$project$Page$ResetPassword$EmailStage, specs, $author$project$Page$ResetPassword$Prompting)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						break _v0$13;
					}
				case 'SubmittedEmail':
					if ((_v0.a.$ === 'EmailStage') && (_v0.a.b.$ === 'Prompting')) {
						var _v7 = _v0.a;
						var specs = _v7.a;
						var _v8 = _v7.b;
						var email = _v0.b.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A2($author$project$Page$ResetPassword$EmailStage, specs, $author$project$Page$ResetPassword$Requesting)
								}),
							A2(
								$elm$core$Task$attempt,
								$author$project$Page$ResetPassword$GotEmailResponse,
								$author$project$Collections$requestPasswordReset(email)),
							$author$project$Framework$NoOp);
					} else {
						break _v0$13;
					}
				case 'GotEmailResponse':
					if ((_v0.a.$ === 'EmailStage') && (_v0.a.b.$ === 'Requesting')) {
						if (_v0.b.a.$ === 'Ok') {
							var _v9 = _v0.a;
							var specs = _v9.a;
							var _v10 = _v9.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										state: A2($author$project$Page$ResetPassword$EmailStage, specs, $author$project$Page$ResetPassword$Done)
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Framework$NoOp);
						} else {
							var _v11 = _v0.a;
							var specs = _v11.a;
							var _v12 = _v11.b;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										state: A2($author$project$Page$ResetPassword$EmailStage, specs, $author$project$Page$ResetPassword$Prompting)
									}),
								$elm$core$Platform$Cmd$none,
								A2(
									$author$project$Framework$ShowPopUp,
									A2($author$project$PopUp$PlainText, 'Ошибка!', 'Что-то пошло не так! Не удалось отправить письмо для смены пароля!'),
									$author$project$PopUp$Important));
						}
					} else {
						break _v0$13;
					}
				case 'UpdatedPasswordField':
					if ((_v0.a.$ === 'PasswordStage') && (_v0.a.d.$ === 'Prompting')) {
						var _v13 = _v0.a;
						var token = _v13.a;
						var paSpecs = _v13.c;
						var _v14 = _v13.d;
						var specs = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A4($author$project$Page$ResetPassword$PasswordStage, token, specs, paSpecs, $author$project$Page$ResetPassword$Prompting)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						break _v0$13;
					}
				case 'UpdatedPasswordAgainField':
					if ((_v0.a.$ === 'PasswordStage') && (_v0.a.d.$ === 'Prompting')) {
						var _v15 = _v0.a;
						var token = _v15.a;
						var pSpecs = _v15.b;
						var _v16 = _v15.d;
						var specs = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A4($author$project$Page$ResetPassword$PasswordStage, token, pSpecs, specs, $author$project$Page$ResetPassword$Prompting)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						break _v0$13;
					}
				case 'SubmittedPassword':
					if ((_v0.a.$ === 'PasswordStage') && (_v0.a.d.$ === 'Prompting')) {
						var _v17 = _v0.a;
						var token = _v17.a;
						var p = _v17.b;
						var pa = _v17.c;
						var _v18 = _v17.d;
						var _v19 = _v0.b;
						var password = _v19.a;
						var passwordAgain = _v19.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A4($author$project$Page$ResetPassword$PasswordStage, token, p, pa, $author$project$Page$ResetPassword$Requesting)
								}),
							A2(
								$elm$core$Task$attempt,
								$author$project$Page$ResetPassword$GotPasswordResponse,
								A3($author$project$Collections$confirmPasswordReset, token, password, passwordAgain)),
							$author$project$Framework$NoOp);
					} else {
						break _v0$13;
					}
				default:
					if ((_v0.a.$ === 'PasswordStage') && (_v0.a.d.$ === 'Requesting')) {
						if (_v0.b.a.$ === 'Ok') {
							var _v20 = _v0.a;
							var token = _v20.a;
							var p = _v20.b;
							var pa = _v20.c;
							var _v21 = _v20.d;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										state: A4($author$project$Page$ResetPassword$PasswordStage, token, p, pa, $author$project$Page$ResetPassword$Done)
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Framework$NoOp);
						} else {
							var _v22 = _v0.a;
							var token = _v22.a;
							var p = _v22.b;
							var pa = _v22.c;
							var _v23 = _v22.d;
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										state: A4($author$project$Page$ResetPassword$PasswordStage, token, p, pa, $author$project$Page$ResetPassword$Prompting)
									}),
								$elm$core$Platform$Cmd$none,
								A2(
									$author$project$Framework$ShowPopUp,
									A2($author$project$PopUp$PlainText, 'Ошибка!', 'Что-то пошло не так! Не удалось подтвердить смену пароля!'),
									$author$project$PopUp$Important));
						}
					} else {
						break _v0$13;
					}
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
	});
var $author$project$Page$ResetPassword$SubmittedEmail = function (a) {
	return {$: 'SubmittedEmail', a: a};
};
var $author$project$Page$ResetPassword$SubmittedPassword = F2(
	function (a, b) {
		return {$: 'SubmittedPassword', a: a, b: b};
	});
var $author$project$Utils$justIf = F2(
	function (condition, data) {
		return condition ? $elm$core$Maybe$Just(data) : $elm$core$Maybe$Nothing;
	});
var $author$project$Page$ResetPassword$view = function (model) {
	var _v0 = model.state;
	if (_v0.$ === 'EmailStage') {
		switch (_v0.b.$) {
			case 'Prompting':
				var specs = _v0.a;
				var _v1 = _v0.b;
				var action = function () {
					var _v2 = $author$project$UI$Form$result(specs);
					if (_v2.$ === 'WithResult') {
						var email = _v2.a;
						return $elm$core$Maybe$Just(
							$author$project$Page$ResetPassword$SubmittedEmail(email));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return A2(
					$author$project$UI$Form$form,
					_List_Nil,
					A4(
						$author$project$UI$Form$withButton,
						true,
						$elm$core$Maybe$Nothing,
						A4(
							$author$project$UI$Button$ButtonSpecs,
							action,
							$mdgriffith$elm_ui$Element$text('Далее'),
							$author$project$UI$Button$Filled,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX])),
						A2(
							$author$project$UI$Form$withField,
							specs,
							A2($author$project$UI$Form$withTitle, 'Сброс пароля', $author$project$UI$Form$empty))));
			case 'Requesting':
				var _v3 = _v0.b;
				return $author$project$UI$Loading$spinner;
			default:
				var _v4 = _v0.b;
				return A2(
					$author$project$UI$Card$contentCard,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$center, $author$project$UI$fontsizes.big]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Проверьте почту!')
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $author$project$UI$fontsizes.big]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('В течение нескольких минут должно прийти письмо для ' + 'подтверждения сброса пароля.')
								]))
						]));
		}
	} else {
		switch (_v0.d.$) {
			case 'Prompting':
				var p = _v0.b;
				var pa = _v0.c;
				var _v5 = _v0.d;
				var action = function () {
					var _v6 = _Utils_Tuple2(
						$author$project$UI$Form$result(p),
						$author$project$UI$Form$result(pa));
					if ((_v6.a.$ === 'WithResult') && (_v6.b.$ === 'WithResult')) {
						var password = _v6.a.a;
						var passwordAgain = _v6.b.a;
						return A2(
							$author$project$Utils$justIf,
							_Utils_eq(password, passwordAgain),
							A2($author$project$Page$ResetPassword$SubmittedPassword, password, passwordAgain));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return A2(
					$author$project$UI$Form$form,
					_List_Nil,
					A4(
						$author$project$UI$Form$withButton,
						true,
						$elm$core$Maybe$Nothing,
						A4(
							$author$project$UI$Button$ButtonSpecs,
							action,
							$mdgriffith$elm_ui$Element$text('Подтвердить'),
							$author$project$UI$Button$Filled,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX])),
						A2(
							$author$project$UI$Form$withField,
							pa,
							A2(
								$author$project$UI$Form$withField,
								p,
								A2($author$project$UI$Form$withTitle, 'Новый пароль', $author$project$UI$Form$empty)))));
			case 'Requesting':
				var _v7 = _v0.d;
				return $author$project$UI$Loading$spinner;
			default:
				var _v8 = _v0.d;
				return A2(
					$author$project$UI$Card$contentCard,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$center, $author$project$UI$fontsizes.big]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Пароль заменён!')
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $author$project$UI$fontsizes.big]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Можете пользоваться новым паролем.')
								]))
						]));
		}
	}
};
var $author$project$Page$ResetPassword$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Public,
	{
		baseUpdated: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, $author$project$Page$ResetPassword$UpdatedBaseModel),
		init: $author$project$Page$ResetPassword$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: function (_v0) {
			return 'Сброс пароля';
		},
		update: $author$project$Framework$EffectUpdate($author$project$Page$ResetPassword$update),
		view: $author$project$Framework$PlainView($author$project$Page$ResetPassword$view),
		wrapArgs: F2(
			function (args, extra) {
				return {device: args.device, session: args.session, token: extra.token};
			})
	});
var $author$project$Page$Service$BaseUpdated = function (a) {
	return {$: 'BaseUpdated', a: a};
};
var $author$project$Page$Service$CommentFieldChanged = function (a) {
	return {$: 'CommentFieldChanged', a: a};
};
var $author$project$Page$Service$DateFieldChanged = function (a) {
	return {$: 'DateFieldChanged', a: a};
};
var $author$project$Page$Service$FlatDropdownOpen = function (a) {
	return {$: 'FlatDropdownOpen', a: a};
};
var $author$project$Page$Service$FlatFieldChanged = function (a) {
	return {$: 'FlatFieldChanged', a: a};
};
var $author$project$Page$Service$GotCurrentTime = function (a) {
	return {$: 'GotCurrentTime', a: a};
};
var $author$project$Page$Service$GotServiceData = function (a) {
	return {$: 'GotServiceData', a: a};
};
var $author$project$Page$Service$NumberFieldChanged = function (a) {
	return {$: 'NumberFieldChanged', a: a};
};
var $author$project$API$Status$withDefault = F2(
	function (_default, status) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			$author$project$API$Status$toMaybe(status));
	});
var $author$project$API$Resource$withDefault = F2(
	function (_default, _v0) {
		var status = _v0.b;
		return A2($author$project$API$Status$withDefault, _default, status);
	});
var $author$project$Page$Service$extractFlats = function (viewer) {
	if (viewer.$ === 'Guest') {
		return _List_Nil;
	} else {
		if (viewer.a.b.$ === 'TenantLocalStorage') {
			var _v1 = viewer.a;
			var user = _v1.b.a.user;
			return A2(
				$elm$core$List$map,
				function (x) {
					return A2(
						$author$project$UI$Form$Dropdown$DropdownEntry,
						$author$project$Domain$flatAddress(x.domain),
						x.meta.id);
				},
				A2($author$project$API$Resource$withDefault, _List_Nil, user.domain.flat));
		} else {
			var _v2 = viewer.a;
			return _List_Nil;
		}
	}
};
var $author$project$Collections$getService = A2(
	$author$project$API$getSingle,
	'service',
	A2(
		$author$project$API$Get,
		$author$project$Domain$PB$decoderWithID($author$project$Domain$serviceDecoder),
		_List_Nil));
var $author$project$Page$Service$syncModelToSession = function (model) {
	var comment = model.comment;
	var number = model.number;
	var date = model.date;
	var flat = model.flat;
	var _v0 = $author$project$Session$getViewer(model.session);
	if (_v0.$ === 'Guest') {
		return model;
	} else {
		if (_v0.a.b.$ === 'ManagerLocalStorage') {
			var _v1 = _v0.a;
			return model;
		} else {
			var _v2 = _v0.a;
			var cart = _v2.b.a.cart;
			var requestData = A2(
				$elmcraft$core_extra$List$Extra$find,
				function (req) {
					return _Utils_eq(
						$author$project$API$Resource$toMeta(req.service),
						$author$project$API$Resource$toMeta(model.service));
				},
				cart);
			var flatDropdownState = A2(
				$elm$core$Maybe$map,
				function (x) {
					return A2(
						$author$project$UI$Form$Dropdown$DropdownEntry,
						$author$project$Domain$flatAddress(x.domain),
						x.meta.id);
				},
				A2(
					$elm$core$Maybe$andThen,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.flat;
						},
						$author$project$API$Resource$toMaybe),
					requestData));
			return _Utils_update(
				model,
				{
					comment: _Utils_update(
						comment,
						{
							displayed: A2(
								$elm$core$Maybe$withDefault,
								'',
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.comment;
									},
									requestData)),
							state: A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.comment;
								},
								requestData)
						}),
					date: _Utils_update(
						date,
						{
							displayed: A2(
								$elm$core$Maybe$withDefault,
								model.date.displayed,
								A2(
									$elm$core$Maybe$andThen,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.when;
										},
										$elm$core$Maybe$map(
											A2(
												$elm$core$Basics$composeR,
												$justinmimbs$time_extra$Time$Extra$posixToParts($author$project$Domain$zone),
												function (_v3) {
													var year = _v3.year;
													var month = _v3.month;
													var day = _v3.day;
													return A3($author$project$UI$Form$Date$Day, year, month, day);
												}))),
									requestData)),
							state: A2(
								$elm$core$Maybe$andThen,
								function ($) {
									return $.when;
								},
								requestData)
						}),
					flat: _Utils_update(
						flat,
						{displayed: flatDropdownState, state: flatDropdownState}),
					number: _Utils_update(
						number,
						{
							displayed: A2(
								$elm$core$Maybe$withDefault,
								'',
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.number;
										},
										$elm$core$String$fromInt),
									requestData)),
							state: A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.number;
								},
								requestData)
						})
				});
		}
	}
};
var $author$project$Page$Service$init = function (args) {
	var defaultNumber = A2($author$project$UI$Form$Number$default, $author$project$UI$Form$Number$int, $author$project$Page$Service$NumberFieldChanged);
	var defaultFlat = A2(
		$author$project$UI$Form$Dropdown$default,
		$author$project$Page$Service$FlatFieldChanged,
		$author$project$Page$Service$FlatDropdownOpen(true));
	var defaultDate = A4(
		$author$project$UI$Form$Date$default,
		A2(
			$justinmimbs$time_extra$Time$Extra$partsToPosix,
			$author$project$Domain$zone,
			A7($justinmimbs$time_extra$Time$Extra$Parts, 2024, $elm$time$Time$Jan, 1, 0, 0, 0, 0)),
		A2(
			$justinmimbs$time_extra$Time$Extra$partsToPosix,
			$author$project$Domain$zone,
			A7($justinmimbs$time_extra$Time$Extra$Parts, 2100, $elm$time$Time$Jan, 1, 0, 0, 0, 0)),
		$elm$core$Maybe$Nothing,
		$author$project$Page$Service$DateFieldChanged);
	var defaultComment = $author$project$UI$Form$Text$default($author$project$Page$Service$CommentFieldChanged);
	return _Utils_Tuple2(
		$author$project$Page$Service$syncModelToSession(
			{
				breadcrumbs: A2($author$project$API$Resource$Resource, args.serviceID, $author$project$API$Status$Loading),
				comment: _Utils_update(
					defaultComment,
					{
						hint: $elm$core$Maybe$Just('Дополнительные пожелания'),
						kind: $author$project$UI$Form$Text$Multiline(true),
						name: 'Комментарий'
					}),
				currentTime: $author$project$API$Status$Loading,
				date: _Utils_update(
					defaultDate,
					{name: 'Когда'}),
				device: args.device,
				flat: _Utils_update(
					defaultFlat,
					{
						name: 'Квартира',
						options: $author$project$Page$Service$extractFlats(
							$author$project$Session$getViewer(args.session))
					}),
				number: _Utils_update(
					defaultNumber,
					{
						check: A2(
							$author$project$UI$Form$simpleCheck,
							function (x) {
								return x > 0;
							},
							'Количество должно быть положительным'),
						hint: $elm$core$Maybe$Just('0'),
						name: 'Количество'
					}),
				service: A2($author$project$API$Resource$Resource, args.serviceID, $author$project$API$Status$Loading),
				session: args.session
			}),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2(
					$elm$core$Task$attempt,
					$author$project$Page$Service$GotServiceData,
					A3($author$project$Collections$getService, _List_Nil, _List_Nil, args.serviceID)),
					A2($elm$core$Task$perform, $author$project$Page$Service$GotCurrentTime, $elm$time$Time$now)
				])));
};
var $author$project$Page$Service$title = function (model) {
	var _v0 = $author$project$API$Resource$toStatus(model.service);
	switch (_v0.$) {
		case 'Loaded':
			var service = _v0.a;
			return service.domain.name;
		case 'NotStarted':
			return '';
		case 'Loading':
			return 'Загрузка...';
		default:
			return 'Ошибка';
	}
};
var $author$project$Page$Service$GotBreadcrumbs = function (a) {
	return {$: 'GotBreadcrumbs', a: a};
};
var $author$project$Page$Service$BreadcrumbEntry = F2(
	function (id, name) {
		return {id: id, name: name};
	});
var $author$project$Graph$AcyclicGraph = F2(
	function (a, b) {
		return {$: 'AcyclicGraph', a: a, b: b};
	});
var $author$project$Graph$Edge = F3(
	function (from, to, label) {
		return {from: from, label: label, to: to};
	});
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (result.$ === 'Ok') {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm_community$intdict$IntDict$Empty = {$: 'Empty'};
var $elm_community$intdict$IntDict$empty = $elm_community$intdict$IntDict$Empty;
var $elm_community$intdict$IntDict$findMin = function (dict) {
	findMin:
	while (true) {
		switch (dict.$) {
			case 'Empty':
				return $elm$core$Maybe$Nothing;
			case 'Leaf':
				var l = dict.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(l.key, l.value));
			default:
				var i = dict.a;
				var $temp$dict = i.left;
				dict = $temp$dict;
				continue findMin;
		}
	}
};
var $elm_community$intdict$IntDict$Inner = function (a) {
	return {$: 'Inner', a: a};
};
var $elm_community$intdict$IntDict$size = function (dict) {
	switch (dict.$) {
		case 'Empty':
			return 0;
		case 'Leaf':
			return 1;
		default:
			var i = dict.a;
			return i.size;
	}
};
var $elm_community$intdict$IntDict$inner = F3(
	function (p, l, r) {
		var _v0 = _Utils_Tuple2(l, r);
		if (_v0.a.$ === 'Empty') {
			var _v1 = _v0.a;
			return r;
		} else {
			if (_v0.b.$ === 'Empty') {
				var _v2 = _v0.b;
				return l;
			} else {
				return $elm_community$intdict$IntDict$Inner(
					{
						left: l,
						prefix: p,
						right: r,
						size: $elm_community$intdict$IntDict$size(l) + $elm_community$intdict$IntDict$size(r)
					});
			}
		}
	});
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm_community$intdict$IntDict$highestBitSet = function (n) {
	var shiftOr = F2(
		function (i, shift) {
			return i | (i >>> shift);
		});
	var n1 = A2(shiftOr, n, 1);
	var n2 = A2(shiftOr, n1, 2);
	var n3 = A2(shiftOr, n2, 4);
	var n4 = A2(shiftOr, n3, 8);
	var n5 = A2(shiftOr, n4, 16);
	return n5 & (~(n5 >>> 1));
};
var $elm_community$intdict$IntDict$signBit = $elm_community$intdict$IntDict$highestBitSet(-1);
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $elm_community$intdict$IntDict$isBranchingBitSet = function (p) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Bitwise$xor($elm_community$intdict$IntDict$signBit),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Bitwise$and(p.branchingBit),
			$elm$core$Basics$neq(0)));
};
var $elm_community$intdict$IntDict$higherBitMask = function (branchingBit) {
	return branchingBit ^ (~(branchingBit - 1));
};
var $elm_community$intdict$IntDict$lcp = F2(
	function (x, y) {
		var branchingBit = $elm_community$intdict$IntDict$highestBitSet(x ^ y);
		var mask = $elm_community$intdict$IntDict$higherBitMask(branchingBit);
		var prefixBits = x & mask;
		return {branchingBit: branchingBit, prefixBits: prefixBits};
	});
var $elm_community$intdict$IntDict$Leaf = function (a) {
	return {$: 'Leaf', a: a};
};
var $elm_community$intdict$IntDict$leaf = F2(
	function (k, v) {
		return $elm_community$intdict$IntDict$Leaf(
			{key: k, value: v});
	});
var $elm_community$intdict$IntDict$prefixMatches = F2(
	function (p, n) {
		return _Utils_eq(
			n & $elm_community$intdict$IntDict$higherBitMask(p.branchingBit),
			p.prefixBits);
	});
var $elm_community$intdict$IntDict$update = F3(
	function (key, alter, dict) {
		var join = F2(
			function (_v2, _v3) {
				var k1 = _v2.a;
				var l = _v2.b;
				var k2 = _v3.a;
				var r = _v3.b;
				var prefix = A2($elm_community$intdict$IntDict$lcp, k1, k2);
				return A2($elm_community$intdict$IntDict$isBranchingBitSet, prefix, k2) ? A3($elm_community$intdict$IntDict$inner, prefix, l, r) : A3($elm_community$intdict$IntDict$inner, prefix, r, l);
			});
		var alteredNode = function (mv) {
			var _v1 = alter(mv);
			if (_v1.$ === 'Just') {
				var v = _v1.a;
				return A2($elm_community$intdict$IntDict$leaf, key, v);
			} else {
				return $elm_community$intdict$IntDict$empty;
			}
		};
		switch (dict.$) {
			case 'Empty':
				return alteredNode($elm$core$Maybe$Nothing);
			case 'Leaf':
				var l = dict.a;
				return _Utils_eq(l.key, key) ? alteredNode(
					$elm$core$Maybe$Just(l.value)) : A2(
					join,
					_Utils_Tuple2(
						key,
						alteredNode($elm$core$Maybe$Nothing)),
					_Utils_Tuple2(l.key, dict));
			default:
				var i = dict.a;
				return A2($elm_community$intdict$IntDict$prefixMatches, i.prefix, key) ? (A2($elm_community$intdict$IntDict$isBranchingBitSet, i.prefix, key) ? A3(
					$elm_community$intdict$IntDict$inner,
					i.prefix,
					i.left,
					A3($elm_community$intdict$IntDict$update, key, alter, i.right)) : A3(
					$elm_community$intdict$IntDict$inner,
					i.prefix,
					A3($elm_community$intdict$IntDict$update, key, alter, i.left),
					i.right)) : A2(
					join,
					_Utils_Tuple2(
						key,
						alteredNode($elm$core$Maybe$Nothing)),
					_Utils_Tuple2(i.prefix.prefixBits, dict));
		}
	});
var $elm_community$intdict$IntDict$insert = F3(
	function (key, value, dict) {
		return A3(
			$elm_community$intdict$IntDict$update,
			key,
			$elm$core$Basics$always(
				$elm$core$Maybe$Just(value)),
			dict);
	});
var $elm_community$intdict$IntDict$Disjunct = F2(
	function (a, b) {
		return {$: 'Disjunct', a: a, b: b};
	});
var $elm_community$intdict$IntDict$Left = {$: 'Left'};
var $elm_community$intdict$IntDict$Parent = F2(
	function (a, b) {
		return {$: 'Parent', a: a, b: b};
	});
var $elm_community$intdict$IntDict$Right = {$: 'Right'};
var $elm_community$intdict$IntDict$SamePrefix = {$: 'SamePrefix'};
var $elm_community$intdict$IntDict$combineBits = F3(
	function (a, b, mask) {
		return (a & (~mask)) | (b & mask);
	});
var $elm_community$intdict$IntDict$mostSignificantBranchingBit = F2(
	function (a, b) {
		return (_Utils_eq(a, $elm_community$intdict$IntDict$signBit) || _Utils_eq(b, $elm_community$intdict$IntDict$signBit)) ? $elm_community$intdict$IntDict$signBit : A2($elm$core$Basics$max, a, b);
	});
var $elm_community$intdict$IntDict$determineBranchRelation = F2(
	function (l, r) {
		var rp = r.prefix;
		var lp = l.prefix;
		var mask = $elm_community$intdict$IntDict$highestBitSet(
			A2($elm_community$intdict$IntDict$mostSignificantBranchingBit, lp.branchingBit, rp.branchingBit));
		var modifiedRightPrefix = A3($elm_community$intdict$IntDict$combineBits, rp.prefixBits, ~lp.prefixBits, mask);
		var prefix = A2($elm_community$intdict$IntDict$lcp, lp.prefixBits, modifiedRightPrefix);
		var childEdge = F2(
			function (branchPrefix, c) {
				return A2($elm_community$intdict$IntDict$isBranchingBitSet, branchPrefix, c.prefix.prefixBits) ? $elm_community$intdict$IntDict$Right : $elm_community$intdict$IntDict$Left;
			});
		return _Utils_eq(lp, rp) ? $elm_community$intdict$IntDict$SamePrefix : (_Utils_eq(prefix, lp) ? A2(
			$elm_community$intdict$IntDict$Parent,
			$elm_community$intdict$IntDict$Left,
			A2(childEdge, l.prefix, r)) : (_Utils_eq(prefix, rp) ? A2(
			$elm_community$intdict$IntDict$Parent,
			$elm_community$intdict$IntDict$Right,
			A2(childEdge, r.prefix, l)) : A2(
			$elm_community$intdict$IntDict$Disjunct,
			prefix,
			A2(childEdge, prefix, l))));
	});
var $elm_community$intdict$IntDict$get = F2(
	function (key, dict) {
		get:
		while (true) {
			switch (dict.$) {
				case 'Empty':
					return $elm$core$Maybe$Nothing;
				case 'Leaf':
					var l = dict.a;
					return _Utils_eq(l.key, key) ? $elm$core$Maybe$Just(l.value) : $elm$core$Maybe$Nothing;
				default:
					var i = dict.a;
					if (!A2($elm_community$intdict$IntDict$prefixMatches, i.prefix, key)) {
						return $elm$core$Maybe$Nothing;
					} else {
						if (A2($elm_community$intdict$IntDict$isBranchingBitSet, i.prefix, key)) {
							var $temp$key = key,
								$temp$dict = i.right;
							key = $temp$key;
							dict = $temp$dict;
							continue get;
						} else {
							var $temp$key = key,
								$temp$dict = i.left;
							key = $temp$key;
							dict = $temp$dict;
							continue get;
						}
					}
			}
		}
	});
var $elm_community$intdict$IntDict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm_community$intdict$IntDict$get, key, dict);
		if (_v0.$ === 'Just') {
			return true;
		} else {
			return false;
		}
	});
var $elm_community$intdict$IntDict$intersect = F2(
	function (l, r) {
		intersect:
		while (true) {
			var _v0 = _Utils_Tuple2(l, r);
			_v0$1:
			while (true) {
				_v0$2:
				while (true) {
					switch (_v0.a.$) {
						case 'Empty':
							var _v1 = _v0.a;
							return $elm_community$intdict$IntDict$Empty;
						case 'Leaf':
							switch (_v0.b.$) {
								case 'Empty':
									break _v0$1;
								case 'Leaf':
									break _v0$2;
								default:
									break _v0$2;
							}
						default:
							switch (_v0.b.$) {
								case 'Empty':
									break _v0$1;
								case 'Leaf':
									var lr = _v0.b.a;
									var _v3 = A2($elm_community$intdict$IntDict$get, lr.key, l);
									if (_v3.$ === 'Just') {
										var v = _v3.a;
										return A2($elm_community$intdict$IntDict$leaf, lr.key, v);
									} else {
										return $elm_community$intdict$IntDict$Empty;
									}
								default:
									var il = _v0.a.a;
									var ir = _v0.b.a;
									var _v4 = A2($elm_community$intdict$IntDict$determineBranchRelation, il, ir);
									switch (_v4.$) {
										case 'SamePrefix':
											return A3(
												$elm_community$intdict$IntDict$inner,
												il.prefix,
												A2($elm_community$intdict$IntDict$intersect, il.left, ir.left),
												A2($elm_community$intdict$IntDict$intersect, il.right, ir.right));
										case 'Parent':
											if (_v4.a.$ === 'Left') {
												if (_v4.b.$ === 'Right') {
													var _v5 = _v4.a;
													var _v6 = _v4.b;
													var $temp$l = il.right,
														$temp$r = r;
													l = $temp$l;
													r = $temp$r;
													continue intersect;
												} else {
													var _v9 = _v4.a;
													var _v10 = _v4.b;
													var $temp$l = il.left,
														$temp$r = r;
													l = $temp$l;
													r = $temp$r;
													continue intersect;
												}
											} else {
												if (_v4.b.$ === 'Right') {
													var _v7 = _v4.a;
													var _v8 = _v4.b;
													var $temp$l = l,
														$temp$r = ir.right;
													l = $temp$l;
													r = $temp$r;
													continue intersect;
												} else {
													var _v11 = _v4.a;
													var _v12 = _v4.b;
													var $temp$l = l,
														$temp$r = ir.left;
													l = $temp$l;
													r = $temp$r;
													continue intersect;
												}
											}
										default:
											return $elm_community$intdict$IntDict$Empty;
									}
							}
					}
				}
				var ll = _v0.a.a;
				return A2($elm_community$intdict$IntDict$member, ll.key, r) ? l : $elm_community$intdict$IntDict$Empty;
			}
			var _v2 = _v0.b;
			return $elm_community$intdict$IntDict$Empty;
		}
	});
var $author$project$Graph$crashHack = function (msg) {
	crashHack:
	while (true) {
		var $temp$msg = msg;
		msg = $temp$msg;
		continue crashHack;
	}
};
var $author$project$Graph$unGraph = function (graph) {
	var rep = graph.a;
	return rep;
};
var $author$project$Graph$get = function (nodeId) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Graph$unGraph,
		$elm_community$intdict$IntDict$get(nodeId));
};
var $author$project$Graph$unsafeGet = F3(
	function (msg, id, graph) {
		var _v0 = A2($author$project$Graph$get, id, graph);
		if (_v0.$ === 'Nothing') {
			return $author$project$Graph$crashHack(msg);
		} else {
			var ctx = _v0.a;
			return ctx;
		}
	});
var $author$project$Graph$checkForBackEdges = F2(
	function (ordering, graph) {
		var success = function (_v3) {
			return A2($author$project$Graph$AcyclicGraph, graph, ordering);
		};
		var check = F2(
			function (id, _v2) {
				var backSet = _v2.a;
				var error = 'Graph.checkForBackEdges: `ordering` didn\'t contain `id`';
				var ctx = A3($author$project$Graph$unsafeGet, error, id, graph);
				var backSetWithId = A3($elm_community$intdict$IntDict$insert, id, _Utils_Tuple0, backSet);
				var backEdges = A2($elm_community$intdict$IntDict$intersect, ctx.outgoing, backSetWithId);
				var _v0 = $elm_community$intdict$IntDict$findMin(backEdges);
				if (_v0.$ === 'Nothing') {
					return $elm$core$Result$Ok(
						_Utils_Tuple2(backSetWithId, _Utils_Tuple0));
				} else {
					var _v1 = _v0.a;
					var to = _v1.a;
					var label = _v1.b;
					return $elm$core$Result$Err(
						A3($author$project$Graph$Edge, id, to, label));
				}
			});
		return A2(
			$elm$core$Result$map,
			success,
			A3(
				$elm$core$List$foldl,
				F2(
					function (id, res) {
						return A2(
							$elm$core$Result$andThen,
							check(id),
							res);
					}),
				$elm$core$Result$Ok(
					_Utils_Tuple2($elm_community$intdict$IntDict$empty, _Utils_Tuple0)),
				ordering));
	});
var $elm_community$intdict$IntDict$foldr = F3(
	function (f, acc, dict) {
		foldr:
		while (true) {
			switch (dict.$) {
				case 'Empty':
					return acc;
				case 'Leaf':
					var l = dict.a;
					return A3(f, l.key, l.value, acc);
				default:
					var i = dict.a;
					var $temp$f = f,
						$temp$acc = A3($elm_community$intdict$IntDict$foldr, f, acc, i.right),
						$temp$dict = i.left;
					f = $temp$f;
					acc = $temp$acc;
					dict = $temp$dict;
					continue foldr;
			}
		}
	});
var $elm_community$intdict$IntDict$keys = function (dict) {
	return A3(
		$elm_community$intdict$IntDict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Graph$alongOutgoingEdges = function (ctx) {
	return $elm_community$intdict$IntDict$keys(ctx.outgoing);
};
var $author$project$Graph$Graph = function (a) {
	return {$: 'Graph', a: a};
};
var $elm_community$intdict$IntDict$foldl = F3(
	function (f, acc, dict) {
		foldl:
		while (true) {
			switch (dict.$) {
				case 'Empty':
					return acc;
				case 'Leaf':
					var l = dict.a;
					return A3(f, l.key, l.value, acc);
				default:
					var i = dict.a;
					var $temp$f = f,
						$temp$acc = A3($elm_community$intdict$IntDict$foldl, f, acc, i.left),
						$temp$dict = i.right;
					f = $temp$f;
					acc = $temp$acc;
					dict = $temp$dict;
					continue foldl;
			}
		}
	});
var $author$project$Graph$applyEdgeDiff = F3(
	function (nodeId, diff, graphRep) {
		var updateOutgoingEdge = F2(
			function (upd, node) {
				return _Utils_update(
					node,
					{
						outgoing: A3($elm_community$intdict$IntDict$update, nodeId, upd, node.outgoing)
					});
			});
		var updateIncomingEdge = F2(
			function (upd, node) {
				return _Utils_update(
					node,
					{
						incoming: A3($elm_community$intdict$IntDict$update, nodeId, upd, node.incoming)
					});
			});
		var flippedFoldl = F3(
			function (f, dict, acc) {
				return A3($elm_community$intdict$IntDict$foldl, f, acc, dict);
			});
		var edgeUpdateToMaybe = function (edgeUpdate) {
			if (edgeUpdate.$ === 'Insert') {
				var lbl = edgeUpdate.a;
				return $elm$core$Maybe$Just(lbl);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var updateAdjacency = F3(
			function (updateEdge, updatedId, edgeUpdate) {
				var updateLbl = updateEdge(
					$elm$core$Basics$always(
						edgeUpdateToMaybe(edgeUpdate)));
				return A2(
					$elm_community$intdict$IntDict$update,
					updatedId,
					$elm$core$Maybe$map(updateLbl));
			});
		return A3(
			flippedFoldl,
			updateAdjacency(updateOutgoingEdge),
			diff.outgoing,
			A3(
				flippedFoldl,
				updateAdjacency(updateIncomingEdge),
				diff.incoming,
				graphRep));
	});
var $author$project$Graph$Insert = function (a) {
	return {$: 'Insert', a: a};
};
var $author$project$Graph$Remove = function (a) {
	return {$: 'Remove', a: a};
};
var $author$project$Graph$emptyDiff = {incoming: $elm_community$intdict$IntDict$empty, outgoing: $elm_community$intdict$IntDict$empty};
var $author$project$Graph$computeEdgeDiff = F2(
	function (old, _new) {
		var collectUpdates = F3(
			function (edgeUpdate, updatedId, label) {
				var replaceUpdate = function (old_) {
					var _v5 = _Utils_Tuple2(
						old_,
						edgeUpdate(label));
					if (_v5.a.$ === 'Just') {
						if (_v5.a.a.$ === 'Remove') {
							if (_v5.b.$ === 'Insert') {
								var oldLbl = _v5.a.a.a;
								var newLbl = _v5.b.a;
								return _Utils_eq(oldLbl, newLbl) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
									$author$project$Graph$Insert(newLbl));
							} else {
								return $author$project$Graph$crashHack('Graph.computeEdgeDiff: Collected two removals for the same edge. This is an error in the implementation of Graph and you should file a bug report!');
							}
						} else {
							return $author$project$Graph$crashHack('Graph.computeEdgeDiff: Collected inserts before removals. This is an error in the implementation of Graph and you should file a bug report!');
						}
					} else {
						var _v6 = _v5.a;
						var eu = _v5.b;
						return $elm$core$Maybe$Just(eu);
					}
				};
				return A2($elm_community$intdict$IntDict$update, updatedId, replaceUpdate);
			});
		var collect = F3(
			function (edgeUpdate, adj, updates) {
				return A3(
					$elm_community$intdict$IntDict$foldl,
					collectUpdates(edgeUpdate),
					updates,
					adj);
			});
		var _v0 = _Utils_Tuple2(old, _new);
		if (_v0.a.$ === 'Nothing') {
			if (_v0.b.$ === 'Nothing') {
				var _v1 = _v0.a;
				var _v2 = _v0.b;
				return $author$project$Graph$emptyDiff;
			} else {
				var _v4 = _v0.a;
				var ins = _v0.b.a;
				return {
					incoming: A3(collect, $author$project$Graph$Insert, ins.outgoing, $elm_community$intdict$IntDict$empty),
					outgoing: A3(collect, $author$project$Graph$Insert, ins.incoming, $elm_community$intdict$IntDict$empty)
				};
			}
		} else {
			if (_v0.b.$ === 'Nothing') {
				var rem = _v0.a.a;
				var _v3 = _v0.b;
				return {
					incoming: A3(collect, $author$project$Graph$Remove, rem.outgoing, $elm_community$intdict$IntDict$empty),
					outgoing: A3(collect, $author$project$Graph$Remove, rem.incoming, $elm_community$intdict$IntDict$empty)
				};
			} else {
				var rem = _v0.a.a;
				var ins = _v0.b.a;
				return _Utils_eq(rem, ins) ? $author$project$Graph$emptyDiff : {
					incoming: A3(
						collect,
						$author$project$Graph$Insert,
						ins.outgoing,
						A3(collect, $author$project$Graph$Remove, rem.outgoing, $elm_community$intdict$IntDict$empty)),
					outgoing: A3(
						collect,
						$author$project$Graph$Insert,
						ins.incoming,
						A3(collect, $author$project$Graph$Remove, rem.incoming, $elm_community$intdict$IntDict$empty))
				};
			}
		}
	});
var $elm_community$intdict$IntDict$filter = F2(
	function (predicate, dict) {
		var add = F3(
			function (k, v, d) {
				return A2(predicate, k, v) ? A3($elm_community$intdict$IntDict$insert, k, v, d) : d;
			});
		return A3($elm_community$intdict$IntDict$foldl, add, $elm_community$intdict$IntDict$empty, dict);
	});
var $author$project$Graph$update = F2(
	function (nodeId, updater) {
		var wrappedUpdater = function (rep) {
			var old = A2($elm_community$intdict$IntDict$get, nodeId, rep);
			var filterInvalidEdges = function (ctx) {
				return $elm_community$intdict$IntDict$filter(
					F2(
						function (id, _v0) {
							return _Utils_eq(id, ctx.node.id) || A2($elm_community$intdict$IntDict$member, id, rep);
						}));
			};
			var cleanUpEdges = function (ctx) {
				return _Utils_update(
					ctx,
					{
						incoming: A2(filterInvalidEdges, ctx, ctx.incoming),
						outgoing: A2(filterInvalidEdges, ctx, ctx.outgoing)
					});
			};
			var _new = A2(
				$elm$core$Maybe$map,
				cleanUpEdges,
				updater(old));
			var diff = A2($author$project$Graph$computeEdgeDiff, old, _new);
			return A3(
				$elm_community$intdict$IntDict$update,
				nodeId,
				$elm$core$Basics$always(_new),
				A3($author$project$Graph$applyEdgeDiff, nodeId, diff, rep));
		};
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Graph$unGraph,
			A2($elm$core$Basics$composeR, wrappedUpdater, $author$project$Graph$Graph));
	});
var $author$project$Graph$remove = F2(
	function (nodeId, graph) {
		return A3(
			$author$project$Graph$update,
			nodeId,
			$elm$core$Basics$always($elm$core$Maybe$Nothing),
			graph);
	});
var $author$project$Graph$guidedDfs = F5(
	function (selectNeighbors, visitNode, startingSeeds, startingAcc, startingGraph) {
		var go = F3(
			function (seeds, acc, graph) {
				go:
				while (true) {
					if (!seeds.b) {
						return _Utils_Tuple2(acc, graph);
					} else {
						var next = seeds.a;
						var seeds1 = seeds.b;
						var _v1 = A2($author$project$Graph$get, next, graph);
						if (_v1.$ === 'Nothing') {
							var $temp$seeds = seeds1,
								$temp$acc = acc,
								$temp$graph = graph;
							seeds = $temp$seeds;
							acc = $temp$acc;
							graph = $temp$graph;
							continue go;
						} else {
							var ctx = _v1.a;
							var _v2 = A2(visitNode, ctx, acc);
							var accAfterDiscovery = _v2.a;
							var finishNode = _v2.b;
							var _v3 = A3(
								go,
								selectNeighbors(ctx),
								accAfterDiscovery,
								A2($author$project$Graph$remove, next, graph));
							var accBeforeFinish = _v3.a;
							var graph1 = _v3.b;
							var accAfterFinish = finishNode(accBeforeFinish);
							var $temp$seeds = seeds1,
								$temp$acc = accAfterFinish,
								$temp$graph = graph1;
							seeds = $temp$seeds;
							acc = $temp$acc;
							graph = $temp$graph;
							continue go;
						}
					}
				}
			});
		return A3(go, startingSeeds, startingAcc, startingGraph);
	});
var $author$project$Graph$nodeIds = A2($elm$core$Basics$composeR, $author$project$Graph$unGraph, $elm_community$intdict$IntDict$keys);
var $author$project$Graph$dfs = F3(
	function (visitNode, acc, graph) {
		return A5(
			$author$project$Graph$guidedDfs,
			$author$project$Graph$alongOutgoingEdges,
			visitNode,
			$author$project$Graph$nodeIds(graph),
			acc,
			graph).a;
	});
var $author$project$Graph$onFinish = F3(
	function (visitor, ctx, acc) {
		return _Utils_Tuple2(
			acc,
			visitor(ctx));
	});
var $author$project$Graph$checkAcyclic = function (graph) {
	var reversePostOrder = A3(
		$author$project$Graph$dfs,
		$author$project$Graph$onFinish(
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.node;
				},
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.id;
					},
					$elm$core$List$cons))),
		_List_Nil,
		graph);
	return A2($author$project$Graph$checkForBackEdges, reversePostOrder, graph);
};
var $author$project$Graph$Node = F2(
	function (id, label) {
		return {id: id, label: label};
	});
var $author$project$Domain$ServiceCategory = F2(
	function (a, b) {
		return {$: 'ServiceCategory', a: a, b: b};
	});
var $author$project$Graph$NodeContext = F3(
	function (node, incoming, outgoing) {
		return {incoming: incoming, node: node, outgoing: outgoing};
	});
var $author$project$Graph$fromNodesAndEdges = F2(
	function (nodes_, edges_) {
		var nodeRep = A3(
			$elm$core$List$foldl,
			function (n) {
				return A2(
					$elm_community$intdict$IntDict$insert,
					n.id,
					A3($author$project$Graph$NodeContext, n, $elm_community$intdict$IntDict$empty, $elm_community$intdict$IntDict$empty));
			},
			$elm_community$intdict$IntDict$empty,
			nodes_);
		var addEdge = F2(
			function (edge, rep) {
				var updateOutgoing = function (ctx) {
					return _Utils_update(
						ctx,
						{
							outgoing: A3($elm_community$intdict$IntDict$insert, edge.to, edge.label, ctx.outgoing)
						});
				};
				var updateIncoming = function (ctx) {
					return _Utils_update(
						ctx,
						{
							incoming: A3($elm_community$intdict$IntDict$insert, edge.from, edge.label, ctx.incoming)
						});
				};
				return A3(
					$elm_community$intdict$IntDict$update,
					edge.to,
					$elm$core$Maybe$map(updateIncoming),
					A3(
						$elm_community$intdict$IntDict$update,
						edge.from,
						$elm$core$Maybe$map(updateOutgoing),
						rep));
			});
		var addEdgeIfValid = F2(
			function (edge, rep) {
				return (A2($elm_community$intdict$IntDict$member, edge.from, rep) && A2($elm_community$intdict$IntDict$member, edge.to, rep)) ? A2(addEdge, edge, rep) : rep;
			});
		return $author$project$Graph$Graph(
			A3($elm$core$List$foldl, addEdgeIfValid, nodeRep, edges_));
	});
var $author$project$Domain$graphRootID = -1;
var $author$project$Domain$ServiceCategoryInfo = F5(
	function (name, description, image, services, parents) {
		return {description: description, image: image, name: name, parents: parents, services: services};
	});
var $author$project$Domain$PB$emptyMetaReal = {
	collectionId: '',
	collectionName: '',
	created: $elm$time$Time$millisToPosix(0),
	id: '',
	updated: $elm$time$Time$millisToPosix(0)
};
var $author$project$Domain$rootServiceCategory = A2(
	$author$project$Domain$ServiceCategory,
	'',
	{
		domain: A5($author$project$Domain$ServiceCategoryInfo, 'Категории услуг', $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, _List_Nil, _List_Nil),
		meta: $author$project$Domain$PB$emptyMetaReal
	});
var $author$project$Domain$serviceCategoryPreInfoToGraph = function (pres) {
	var root = A2($author$project$Graph$Node, $author$project$Domain$graphRootID, $author$project$Domain$rootServiceCategory);
	var nodes = A2(
		$elm$core$List$indexedMap,
		F2(
			function (id, pre) {
				var info = {
					description: A2(
						$author$project$Utils$justIf,
						$elm$core$String$length(pre.domain.description) > 0,
						pre.domain.description),
					image: A2(
						$author$project$Utils$justIf,
						$elm$core$String$length(pre.domain.image) > 0,
						pre.domain.image),
					name: pre.domain.name,
					parents: pre.domain.parents,
					services: _List_Nil
				};
				return A2(
					$author$project$Graph$Node,
					id,
					A2(
						$author$project$Domain$ServiceCategory,
						pre.meta.id,
						A2($author$project$Domain$PB$Record, pre.meta, info)));
			}),
		pres);
	var graphRoots = A2(
		$elm$core$List$map,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.meta;
			},
			function ($) {
				return $.id;
			}),
		A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.domain;
				},
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.parents;
					},
					$elm$core$List$isEmpty)),
			pres));
	var categories = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$indexedMap,
			$elm$core$Tuple$pair,
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.meta;
					},
					function ($) {
						return $.id;
					}),
				pres)));
	var ids = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (_v0) {
				var a = _v0.a;
				var b = _v0.b;
				return _Utils_Tuple2(b, a);
			},
			$elm$core$Dict$toList(categories)));
	var edges = A2(
		$elm$core$List$concatMap,
		function (x) {
			return A2(
				$elm$core$List$map,
				function (parent) {
					return A3(
						$author$project$Graph$Edge,
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Domain$graphRootID,
							A2($elm$core$Dict$get, parent, ids)),
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Domain$graphRootID,
							A2($elm$core$Dict$get, x.meta.id, ids)),
						_Utils_Tuple0);
				},
				x.domain.parents);
		},
		pres);
	var rootEdges = A2(
		$elm$core$List$map,
		function (id) {
			return A3(
				$author$project$Graph$Edge,
				$author$project$Domain$graphRootID,
				A2(
					$elm$core$Maybe$withDefault,
					$author$project$Domain$graphRootID,
					A2($elm$core$Dict$get, id, ids)),
				_Utils_Tuple0);
		},
		graphRoots);
	return A2(
		$author$project$Graph$fromNodesAndEdges,
		A2($elm$core$List$cons, root, nodes),
		_Utils_ap(edges, rootEdges));
};
var $author$project$Domain$serviceCategoriesToGraph = function () {
	var getCategoryName = F2(
		function (node, graph) {
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (_v0) {
						var domain = _v0.b.domain;
						return domain.name;
					},
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.node;
							},
							function ($) {
								return $.label;
							}),
						A2($author$project$Graph$get, node, graph))));
		});
	var checkGraph = function (graph) {
		return A2(
			$elm$core$Result$mapError,
			function (edge) {
				return $elm$core$String$concat(
					_List_fromArray(
						[
							'category graph is cyclic: edge (',
							A2(getCategoryName, edge.from, graph),
							') -> (',
							A2(getCategoryName, edge.to, graph),
							') is a part of the cycle'
						]));
			},
			$author$project$Graph$checkAcyclic(graph));
	};
	return A2($elm$core$Basics$composeR, $author$project$Domain$serviceCategoryPreInfoToGraph, checkGraph);
}();
var $author$project$Domain$ServiceCategoryPreInfo = F4(
	function (name, description, image, parents) {
		return {description: description, image: image, name: name, parents: parents};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Domain$serviceCategoryPreInfoDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Domain$ServiceCategoryPreInfo,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	$author$project$Utils$decodeFilePath('icon'),
	A2(
		$elm$json$Json$Decode$field,
		'parent_categories',
		$elm$json$Json$Decode$list($author$project$Domain$PB$idDecoder)));
var $author$project$Collections$getServiceCategories = F2(
	function (headers, params) {
		return A2(
			$elm$core$Task$andThen,
			function (x) {
				var _v0 = $author$project$Domain$serviceCategoriesToGraph(x);
				if (_v0.$ === 'Ok') {
					var acyclic = _v0.a;
					return $elm$core$Task$succeed(acyclic);
				} else {
					var message = _v0.a;
					return $elm$core$Task$fail(
						$author$project$API$Error$Bug(message));
				}
			},
			A4(
				$author$project$API$getAll,
				'service_category',
				A2(
					$author$project$API$Get,
					$author$project$Domain$PB$decoderWithID($author$project$Domain$serviceCategoryPreInfoDecoder),
					_List_Nil),
				headers,
				params));
	});
var $author$project$Utils$maxBy = F3(
	function (comparator, left, right) {
		return (_Utils_cmp(
			comparator(left),
			comparator(right)) > -1) ? left : right;
	});
var $author$project$Graph$Tree$root = function (tree) {
	var maybe = tree.b;
	return maybe;
};
var $author$project$Graph$forgetAcyclic = function (_v0) {
	var graph = _v0.a;
	return graph;
};
var $author$project$Graph$Tree$MkTree = F2(
	function (a, b) {
		return {$: 'MkTree', a: a, b: b};
	});
var $author$project$Graph$Tree$empty = A2($author$project$Graph$Tree$MkTree, 0, $elm$core$Maybe$Nothing);
var $author$project$Graph$Tree$isEmpty = function (tree) {
	return _Utils_eq(tree, $author$project$Graph$Tree$empty);
};
var $author$project$Graph$Tree$size = function (tree) {
	var n = tree.a;
	return n;
};
var $author$project$Graph$Tree$inner = F2(
	function (label, children) {
		var nonEmptyChildren = A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Graph$Tree$isEmpty),
			children);
		var totalSize = A3(
			$elm$core$List$foldl,
			A2($elm$core$Basics$composeL, $elm$core$Basics$add, $author$project$Graph$Tree$size),
			1,
			nonEmptyChildren);
		return A2(
			$author$project$Graph$Tree$MkTree,
			totalSize,
			$elm$core$Maybe$Just(
				_Utils_Tuple2(label, nonEmptyChildren)));
	});
var $author$project$Graph$Tree$unfoldTree = F2(
	function (next, seed) {
		var _v0 = next(seed);
		var label = _v0.a;
		var seeds = _v0.b;
		return A2(
			$author$project$Graph$Tree$inner,
			label,
			A2(
				$elm$core$List$map,
				$author$project$Graph$Tree$unfoldTree(next),
				seeds));
	});
var $author$project$Page$ServiceCategory$treeify = F3(
	function (rootId, unneededDefault, graph) {
		return A2(
			$author$project$Graph$Tree$unfoldTree,
			function (id) {
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(unneededDefault, _List_Nil),
					A2(
						$elm$core$Maybe$map,
						function (_v0) {
							var node = _v0.node;
							var outgoing = _v0.outgoing;
							return _Utils_Tuple2(
								node.label,
								$elm_community$intdict$IntDict$keys(outgoing));
						},
						A2(
							$author$project$Graph$get,
							id,
							$author$project$Graph$forgetAcyclic(graph))));
			},
			rootId);
	});
var $author$project$Page$Service$getBreadcrumbs = F2(
	function (session, leafID) {
		var getRoutes = function (tree) {
			var _v0 = $author$project$Graph$Tree$root(tree);
			if (_v0.$ === 'Nothing') {
				return _List_Nil;
			} else {
				var _v1 = _v0.a;
				var _v2 = _v1.a;
				var meta = _v2.b.meta;
				var domain = _v2.b.domain;
				var subforest = _v1.b;
				if (_Utils_eq(meta.id, leafID)) {
					return _List_fromArray(
						[
							_List_fromArray(
							[
								A2($author$project$Page$Service$BreadcrumbEntry, meta.id, domain.name)
							])
						]);
				} else {
					var _v3 = A2($elm$core$List$concatMap, getRoutes, subforest);
					if (!_v3.b) {
						return _List_Nil;
					} else {
						var routes = _v3;
						return A2(
							$elm$core$List$map,
							$elm$core$List$cons(
								A2($author$project$Page$Service$BreadcrumbEntry, meta.id, domain.name)),
							routes);
					}
				}
			}
		};
		var toBreadcrumbs = function (graph) {
			return A3(
				$elm$core$List$foldl,
				$author$project$Utils$maxBy($elm$core$List$length),
				_List_Nil,
				getRoutes(
					A3($author$project$Page$ServiceCategory$treeify, $author$project$Domain$graphRootID, $author$project$Domain$rootServiceCategory, graph)));
		};
		var getGraph = A2(
			$author$project$Collections$getServiceCategories,
			A2($author$project$Session$withAuth, session, _List_Nil),
			_List_Nil);
		return A2($elm$core$Task$map, toBreadcrumbs, getGraph);
	});
var $author$project$Page$Service$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'ServiceRequested':
				var ignore = _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
				var _v1 = _Utils_Tuple2(
					$author$project$API$Resource$toStatus(model.service),
					$author$project$Session$getViewer(model.session));
				if (((_v1.a.$ === 'Loaded') && (_v1.b.$ === 'Authenticated')) && (_v1.b.a.b.$ === 'TenantLocalStorage')) {
					var service = _v1.a.a;
					var _v2 = _v1.b.a;
					var oldStorage = _v2.b.a;
					var user = oldStorage.user;
					var cart = oldStorage.cart;
					if (A2(
						$elm$core$List$any,
						function (req) {
							return _Utils_eq(
								$author$project$API$Resource$toMeta(req.service),
								service.meta.id);
						},
						cart)) {
						return ignore;
					} else {
						var _v3 = _Utils_Tuple2(
							_Utils_Tuple2(
								$author$project$UI$Form$result(model.number),
								$author$project$UI$Form$toResMaybe(
									$author$project$UI$Form$result(model.date))),
							_Utils_Tuple2(
								$author$project$UI$Form$result(model.comment),
								$author$project$UI$Form$result(model.flat)));
						if ((((_v3.a.a.$ === 'WithResult') && (_v3.a.b.$ === 'Ok')) && (_v3.b.a.$ === 'WithResult')) && (_v3.b.b.$ === 'WithResult')) {
							var _v4 = _v3.a;
							var number = _v4.a.a;
							var date = _v4.b.a;
							var _v5 = _v3.b;
							var comment = _v5.a.a;
							var flat = _v5.b.a;
							var request = function (flatData) {
								return {
									comment: comment,
									created: $elm$core$Maybe$Nothing,
									flat: A2(
										$author$project$API$Resource$Resource,
										flatData.meta.id,
										$author$project$API$Status$Loaded(flatData)),
									number: number,
									service: A2(
										$author$project$API$Resource$Resource,
										service.meta.id,
										$author$project$API$Status$Loaded(service)),
									status: $elm$core$Maybe$Just($author$project$Domain$SRCreated),
									user: A2(
										$author$project$API$Resource$Resource,
										user.meta.id,
										$author$project$API$Status$Loaded(user)),
									when: date
								};
							};
							var _v6 = $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (x) {
										return _Utils_eq(x.meta.id, flat.tag);
									},
									A2($author$project$API$Resource$withDefault, _List_Nil, user.domain.flat)));
							if (_v6.$ === 'Just') {
								var flatData = _v6.a;
								return _Utils_Tuple3(
									model,
									$elm$core$Platform$Cmd$none,
									$author$project$Framework$UpdateLocalStorage(
										$author$project$Session$TenantLocalStorage(
											_Utils_update(
												oldStorage,
												{
													cart: A2(
														$elm$core$List$cons,
														request(flatData),
														cart)
												}))));
							} else {
								return ignore;
							}
						} else {
							return ignore;
						}
					}
				} else {
					return ignore;
				}
			case 'CommentFieldChanged':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{comment: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'NumberFieldChanged':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{number: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'DateFieldChanged':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{date: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'FlatFieldChanged':
				var specs = msg.a.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{flat: specs}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'FlatDropdownOpen':
				var state = msg.a;
				var flatSpecs = model.flat;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							flat: _Utils_update(
								flatSpecs,
								{
									open: state,
									toggle: $author$project$Page$Service$FlatDropdownOpen(!state)
								})
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'GotServiceData':
				if (msg.a.$ === 'Ok') {
					var service = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								service: A2($author$project$API$Resource$loaded, service, model.service)
							}),
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Service$GotBreadcrumbs,
							A2($author$project$Page$Service$getBreadcrumbs, model.session, service.domain.category)),
						$author$project$Framework$NoOp);
				} else {
					return _Debug_todo(
						'Page.Service',
						{
							start: {line: 366, column: 13},
							end: {line: 366, column: 23}
						})('branch \'GotServiceData (Err _)\' not implemented');
				}
			case 'GotBreadcrumbs':
				if (msg.a.$ === 'Ok') {
					var breadcrumbs = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								breadcrumbs: A2($author$project$API$Resource$loaded, breadcrumbs, model.breadcrumbs)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					return _Debug_todo(
						'Page.Service',
						{
							start: {line: 369, column: 13},
							end: {line: 369, column: 23}
						})('branch \'GotBreadcrumbs (Err _)\' not implemented');
				}
			case 'GotCurrentTime':
				var now = msg.a;
				var oldDate = model.date;
				var _v7 = $author$project$Domain$orderDateRange(
					$elm$core$Maybe$Just(now));
				var today = _v7.a;
				var nextMonth = _v7.b;
				var _v8 = A2($justinmimbs$time_extra$Time$Extra$posixToParts, $author$project$Domain$zone, today);
				var year = _v8.year;
				var month = _v8.month;
				var day = _v8.day;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							currentTime: $author$project$API$Status$Loaded(now),
							date: _Utils_update(
								oldDate,
								{
									displayed: A3($author$project$UI$Form$Date$Day, year, month, day),
									from: today,
									to: nextMonth,
									today: $elm$core$Maybe$Just(today)
								})
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'BaseUpdated':
				var session = msg.a.session;
				var options = $author$project$Page$Service$extractFlats(
					$author$project$Session$getViewer(session));
				var oldFlat = model.flat;
				return _Utils_Tuple3(
					$author$project$Page$Service$syncModelToSession(
						_Utils_update(
							model,
							{
								flat: function () {
									if (!options.b) {
										return _Utils_update(
											oldFlat,
											{displayed: $elm$core$Maybe$Nothing, options: options, state: $elm$core$Maybe$Nothing});
									} else {
										if (!options.b.b) {
											var only = options.a;
											return _Utils_update(
												oldFlat,
												{
													displayed: $elm$core$Maybe$Just(only),
													options: options,
													state: $elm$core$Maybe$Just(only)
												});
										} else {
											return _Utils_update(
												oldFlat,
												{options: options});
										}
									}
								}(),
								session: session
							})),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			default:
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					function () {
						var _v10 = $author$project$Session$getViewer(model.session);
						if (_v10.$ === 'Guest') {
							return $author$project$Framework$NoOp;
						} else {
							if (_v10.a.b.$ === 'TenantLocalStorage') {
								var _v11 = _v10.a;
								var oldStorage = _v11.b.a;
								var cart = oldStorage.cart;
								return $author$project$Framework$UpdateLocalStorage(
									$author$project$Session$TenantLocalStorage(
										_Utils_update(
											oldStorage,
											{
												cart: A2(
													$elm$core$List$filter,
													function (req) {
														return !_Utils_eq(
															$author$project$API$Resource$toMeta(req.service),
															$author$project$API$Resource$toMeta(model.service));
													},
													cart)
											})));
							} else {
								var _v12 = _v10.a;
								return _Debug_todo(
									'Page.Service',
									{
										start: {line: 447, column: 21},
										end: {line: 447, column: 31}
									})('branch \'Authenticated (LocalStorage _ (AdminLocalStorage _))\' not implemented');
							}
						}
					}());
		}
	});
var $author$project$Page$Service$breadcrumbsView = function (model) {
	var divider = A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerY]),
		A2(
			$author$project$UI$materialIcon,
			'chevron_right',
			$author$project$UI$scaled(5)));
	var _v0 = $author$project$API$Resource$toStatus(model.breadcrumbs);
	if (_v0.$ === 'Loaded') {
		var crumbs = _v0.a;
		return A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
					$mdgriffith$elm_ui$Element$Font$bold
				]),
			A2(
				$elm$core$List$intersperse,
				divider,
				A2(
					$elm$core$List$map,
					function (_v1) {
						var id = _v1.id;
						var name = _v1.name;
						return A2(
							$mdgriffith$elm_ui$Element$link,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerY]),
							{
								label: $mdgriffith$elm_ui$Element$text(name),
								url: '/service-category/' + id
							});
					},
					crumbs)));
	} else {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(
						$author$project$UI$scaled(5)))
				]),
			$mdgriffith$elm_ui$Element$none);
	}
};
var $author$project$Page$Service$DeletedServiceRequest = {$: 'DeletedServiceRequest'};
var $author$project$Page$Service$ServiceRequested = {$: 'ServiceRequested'};
var $author$project$Domain$mapPriceRange = F2(
	function (process, range) {
		switch (range.$) {
			case 'ExactPrice':
				var x = range.a;
				return $author$project$Domain$ExactPrice(
					process(x));
			case 'PriceFrom':
				var x = range.a;
				return $author$project$Domain$PriceFrom(
					process(x));
			case 'PriceUpTo':
				var x = range.a;
				return $author$project$Domain$PriceUpTo(
					process(x));
			default:
				var a = range.a;
				var b = range.b;
				return A2(
					$author$project$Domain$PriceBetween,
					process(a),
					process(b));
		}
	});
var $author$project$Page$Service$preliminaryCost = function (_v0) {
	var service = _v0.service;
	var number = _v0.number;
	var _v1 = _Utils_Tuple2(
		$author$project$API$Resource$toStatus(service),
		number.state);
	if ((_v1.a.$ === 'Loaded') && (_v1.b.$ === 'Just')) {
		var domain = _v1.a.a.domain;
		var quantity = _v1.b.a;
		var renderPriceNumber = function (x) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
					]),
				$mdgriffith$elm_ui$Element$text(
					$elm$core$String$fromInt(x)));
		};
		var _v2 = domain.price;
		var range = _v2.a;
		var unit = _v2.b;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$UI$expandParagraphs
				]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Предварительная стоимость: '),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$Font$alignRight,
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-2))
						]),
					_Utils_ap(
						function () {
							var _v3 = A2(
								$author$project$Domain$mapPriceRange,
								$elm$core$Basics$mul(quantity),
								range);
							switch (_v3.$) {
								case 'PriceFrom':
									var startingPrice = _v3.a;
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('от '),
											renderPriceNumber(startingPrice)
										]);
								case 'ExactPrice':
									var exactPrice = _v3.a;
									return _List_fromArray(
										[
											renderPriceNumber(exactPrice)
										]);
								case 'PriceUpTo':
									var priceCap = _v3.a;
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('до '),
											renderPriceNumber(priceCap)
										]);
								default:
									var startingPrice = _v3.a;
									var priceCap = _v3.b;
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('от '),
											renderPriceNumber(startingPrice),
											$mdgriffith$elm_ui$Element$text(' до '),
											renderPriceNumber(priceCap)
										]);
							}
						}(),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(
								function () {
									if (unit.$ === 'Rub') {
										return ' рублей';
									} else {
										return ' копеек';
									}
								}())
							])))
				]));
	} else {
		return $mdgriffith$elm_ui$Element$none;
	}
};
var $author$project$Page$Service$form = function (model) {
	return A2(
		$author$project$UI$Form$form,
		_List_Nil,
		A3(
			$elm$core$Basics$apL,
			$author$project$UI$Form$ifValid,
			A3(
				$author$project$UI$Form$withButton,
				true,
				$elm$core$Maybe$Nothing,
				function () {
					var _v0 = $author$project$Session$getViewer(model.session);
					if (_v0.$ === 'Guest') {
						return A4(
							$author$project$UI$Button$ButtonSpecs,
							$elm$core$Maybe$Nothing,
							$mdgriffith$elm_ui$Element$text('Войдите в учётную запись, чтобы продолжить'),
							$author$project$UI$Button$Filled,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]));
					} else {
						if (_v0.a.b.$ === 'TenantLocalStorage') {
							var _v1 = _v0.a;
							var cart = _v1.b.a.cart;
							return A2(
								$elm$core$Maybe$withDefault,
								A4(
									$author$project$UI$Button$ButtonSpecs,
									$elm$core$Maybe$Just($author$project$Page$Service$ServiceRequested),
									$mdgriffith$elm_ui$Element$text('Добавить в корзину'),
									$author$project$UI$Button$Filled,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX])),
								A2(
									$elm$core$Maybe$map,
									$elm$core$Basics$always(
										A4(
											$author$project$UI$Button$ButtonSpecs,
											$elm$core$Maybe$Just($author$project$Page$Service$DeletedServiceRequest),
											$mdgriffith$elm_ui$Element$text('Удалить из корзины'),
											$author$project$UI$Button$Filled,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]))),
									A2(
										$elmcraft$core_extra$List$Extra$find,
										function (req) {
											return _Utils_eq(
												$author$project$API$Resource$toMeta(req.service),
												$author$project$API$Resource$toMeta(model.service));
										},
										cart)));
						} else {
							var _v2 = _v0.a;
							return A4(
								$author$project$UI$Button$ButtonSpecs,
								$elm$core$Maybe$Nothing,
								$mdgriffith$elm_ui$Element$text('Заказ доступен только жильцам'),
								$author$project$UI$Button$Filled,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]));
						}
					}
				}()),
			A2(
				$author$project$UI$Form$withExternal,
				$author$project$Page$Service$preliminaryCost(model),
				A2(
					$author$project$UI$Form$withField,
					model.comment,
					A2(
						$author$project$UI$Form$withField,
						model.flat,
						A2(
							$author$project$UI$Form$withField,
							model.date,
							A2(
								$author$project$UI$Form$withField,
								model.number,
								A2($author$project$UI$Form$withTitle, 'Детали заказа', $author$project$UI$Form$empty))))))));
};
var $author$project$Page$Service$view = function (model) {
	var _v0 = $author$project$API$Resource$toStatus(model.service);
	switch (_v0.$) {
		case 'Loading':
			return A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, $author$project$UI$Loading$spinner);
		case 'Loaded':
			var service = _v0.a;
			var renderPriceNumber = function (price) {
				return $mdgriffith$elm_ui$Element$text(
					$elm$core$String$fromInt(price));
			};
			var _v1 = service.domain.price;
			var priceRange = _v1.a;
			var priceUnit = _v1.b;
			var priceTag = A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$text('Цена:'),
					_Utils_ap(
						function () {
							switch (priceRange.$) {
								case 'PriceFrom':
									var startingPrice = priceRange.a;
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(' от '),
											renderPriceNumber(startingPrice)
										]);
								case 'ExactPrice':
									var exactPrice = priceRange.a;
									return _List_fromArray(
										[
											renderPriceNumber(exactPrice)
										]);
								case 'PriceUpTo':
									var priceCap = priceRange.a;
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(' до '),
											renderPriceNumber(priceCap)
										]);
								default:
									var startingPrice = priceRange.a;
									var priceCap = priceRange.b;
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(' от '),
											renderPriceNumber(startingPrice),
											$mdgriffith$elm_ui$Element$text(' до '),
											renderPriceNumber(priceCap)
										]);
							}
						}(),
						_List_fromArray(
							[
								function () {
								if (priceUnit.$ === 'Rub') {
									return $mdgriffith$elm_ui$Element$text(' руб. / ' + service.domain.unit);
								} else {
									return $mdgriffith$elm_ui$Element$text(' коп. / ' + service.domain.unit);
								}
							}()
							]))));
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$width(
						A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
						$mdgriffith$elm_ui$Element$padding(12),
						$mdgriffith$elm_ui$Element$spacing(12)
					]),
				_List_fromArray(
					[
						$author$project$Page$Service$breadcrumbsView(model),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$bold,
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
								$mdgriffith$elm_ui$Element$Font$center,
								$mdgriffith$elm_ui$Element$centerX,
								$author$project$UI$fontsizes.huge
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(service.domain.name)
							])),
						priceTag,
						function () {
						var _v2 = service.domain.description;
						if (_v2.$ === 'Just') {
							var description = _v2.a;
							return A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill))
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(description)
									]));
						} else {
							return $mdgriffith$elm_ui$Element$none;
						}
					}(),
						$author$project$Page$Service$form(model)
					]));
		case 'NotStarted':
			return A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, $mdgriffith$elm_ui$Element$none);
		default:
			return _Debug_todo(
				'Page.Service',
				{
					start: {line: 666, column: 13},
					end: {line: 666, column: 23}
				})('branch \'Failed\' not implemented');
	}
};
var $author$project$Page$Service$wrap = F2(
	function (base, args) {
		return {device: base.device, serviceID: args.serviceID, session: base.session};
	});
var $author$project$Page$Service$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Public,
	{
		baseUpdated: A2($elm$core$Basics$composeR, $author$project$Page$Service$BaseUpdated, $elm$core$Maybe$Just),
		init: $author$project$Page$Service$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $author$project$Page$Service$title,
		update: $author$project$Framework$EffectUpdate($author$project$Page$Service$update),
		view: $author$project$Framework$PlainView($author$project$Page$Service$view),
		wrapArgs: $author$project$Page$Service$wrap
	});
var $author$project$Page$ServiceCategory$GotGraph = function (a) {
	return {$: 'GotGraph', a: a};
};
var $author$project$Page$ServiceCategory$getCategoryGraph = function (session) {
	return A2(
		$elm$core$Task$attempt,
		$author$project$Page$ServiceCategory$GotGraph,
		A2(
			$author$project$Collections$getServiceCategories,
			A2($author$project$Session$withAuth, session, _List_Nil),
			_List_Nil));
};
var $author$project$Page$ServiceCategory$GotServices = function (a) {
	return {$: 'GotServices', a: a};
};
var $author$project$Page$ServiceCategory$getServices = function (session) {
	return A2(
		$elm$core$Task$attempt,
		$author$project$Page$ServiceCategory$GotServices,
		A2(
			$author$project$Collections$getServices,
			A2($author$project$Session$withAuth, session, _List_Nil),
			_List_Nil));
};
var $author$project$Page$ServiceCategory$init = function (args) {
	return _Utils_Tuple2(
		{breadcrumbs: $elm$core$Maybe$Nothing, categories: $elm$core$Maybe$Nothing, categoryID: args.categoryId, currentCategory: $elm$core$Maybe$Nothing, device: args.device, graph: $author$project$API$Status$Loading, ids: $elm$core$Maybe$Nothing, services: $author$project$API$Status$Loading, session: args.session},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Page$ServiceCategory$getCategoryGraph(args.session),
					$author$project$Page$ServiceCategory$getServices(args.session)
				])));
};
var $author$project$Page$ServiceCategory$title = function (model) {
	var _v0 = model.graph;
	switch (_v0.$) {
		case 'Loading':
			return 'Загрузка...';
		case 'NotStarted':
			return '';
		case 'Loaded':
			return function (_v1) {
				var info = _v1.b;
				return info.domain.name;
			}(
				A2($elm$core$Maybe$withDefault, $author$project$Domain$rootServiceCategory, model.currentCategory));
		default:
			return 'Ошибка';
	}
};
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $author$project$Page$ServiceCategory$longestRoutes = F2(
	function (routePrefix, tree) {
		var _v0 = $author$project$Graph$Tree$root(tree);
		if (_v0.$ === 'Just') {
			if (!_v0.a.b.b) {
				var _v1 = _v0.a;
				var s = _v1.a;
				return $elm$core$Dict$fromList(
					_List_fromArray(
						[
							_Utils_Tuple2(s, routePrefix)
						]));
			} else {
				var _v2 = _v0.a;
				var s = _v2.a;
				var forest = _v2.b;
				return A3(
					$elm$core$List$foldl,
					F2(
						function (t, d) {
							return A6(
								$elm$core$Dict$merge,
								$elm$core$Dict$insert,
								F3(
									function (k, a, b) {
										return A2(
											$elm$core$Dict$insert,
											k,
											A3($author$project$Utils$maxBy, $elm$core$List$length, a, b));
									}),
								$elm$core$Dict$insert,
								d,
								A2(
									$author$project$Page$ServiceCategory$longestRoutes,
									_Utils_ap(
										routePrefix,
										_List_fromArray(
											[s])),
									t),
								$elm$core$Dict$empty);
						}),
					$elm$core$Dict$fromList(
						_List_fromArray(
							[
								_Utils_Tuple2(s, routePrefix)
							])),
					forest);
			}
		} else {
			return $elm$core$Dict$empty;
		}
	});
var $author$project$Graph$Tree$map = F2(
	function (f, _v0) {
		var totalSize = _v0.a;
		var maybeLabelAndChildren = _v0.b;
		return A2(
			$author$project$Graph$Tree$MkTree,
			totalSize,
			A2(
				$elm$core$Maybe$map,
				function (_v1) {
					var label = _v1.a;
					var children = _v1.b;
					return _Utils_Tuple2(
						f(label),
						A2(
							$elm$core$List$map,
							$author$project$Graph$Tree$map(f),
							children));
				},
				maybeLabelAndChildren));
	});
var $author$project$Graph$empty = $author$project$Graph$Graph($elm_community$intdict$IntDict$empty);
var $elm_community$intdict$IntDict$findMax = function (dict) {
	findMax:
	while (true) {
		switch (dict.$) {
			case 'Empty':
				return $elm$core$Maybe$Nothing;
			case 'Leaf':
				var l = dict.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(l.key, l.value));
			default:
				var i = dict.a;
				var $temp$dict = i.right;
				dict = $temp$dict;
				continue findMax;
		}
	}
};
var $author$project$Graph$nodeIdRange = function (graph) {
	return A2(
		$elm$core$Maybe$andThen,
		function (_v0) {
			var min = _v0.a;
			return A2(
				$elm$core$Maybe$andThen,
				function (_v1) {
					var max = _v1.a;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(min, max));
				},
				$elm_community$intdict$IntDict$findMax(
					$author$project$Graph$unGraph(graph)));
		},
		$elm_community$intdict$IntDict$findMin(
			$author$project$Graph$unGraph(graph)));
};
var $author$project$Graph$fold = F3(
	function (f, acc, graph) {
		var go = F2(
			function (acc1, graph1) {
				go:
				while (true) {
					var maybeContext = A2(
						$elm$core$Maybe$andThen,
						function (id) {
							return A2($author$project$Graph$get, id, graph);
						},
						A2(
							$elm$core$Maybe$map,
							$elm$core$Tuple$first,
							$author$project$Graph$nodeIdRange(graph1)));
					if (maybeContext.$ === 'Just') {
						var ctx = maybeContext.a;
						var $temp$acc1 = A2(f, ctx, acc1),
							$temp$graph1 = A2($author$project$Graph$remove, ctx.node.id, graph1);
						acc1 = $temp$acc1;
						graph1 = $temp$graph1;
						continue go;
					} else {
						return acc1;
					}
				}
			});
		return A2(go, acc, graph);
	});
var $author$project$Graph$insert = F2(
	function (nodeContext, graph) {
		return A3(
			$author$project$Graph$update,
			nodeContext.node.id,
			$elm$core$Basics$always(
				$elm$core$Maybe$Just(nodeContext)),
			graph);
	});
var $author$project$Graph$mapNodes = function (f) {
	return A2(
		$author$project$Graph$fold,
		function (_v0) {
			var node = _v0.node;
			var incoming = _v0.incoming;
			var outgoing = _v0.outgoing;
			return $author$project$Graph$insert(
				{
					incoming: incoming,
					node: {
						id: node.id,
						label: f(node.label)
					},
					outgoing: outgoing
				});
		},
		$author$project$Graph$empty);
};
var $elm_community$intdict$IntDict$values = function (dict) {
	return A3(
		$elm_community$intdict$IntDict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Graph$nodes = A2(
	$elm$core$Basics$composeR,
	$author$project$Graph$unGraph,
	A2(
		$elm$core$Basics$composeR,
		$elm_community$intdict$IntDict$values,
		$elm$core$List$map(
			function ($) {
				return $.node;
			})));
var $author$project$Page$ServiceCategory$update = F2(
	function (msg, model) {
		var fillIds = F2(
			function (graph, mdl) {
				return _Utils_update(
					mdl,
					{
						ids: $elm$core$Maybe$Just(
							$elm$core$Dict$fromList(
								A2(
									$elm$core$List$map,
									function (_v8) {
										var id = _v8.id;
										var label = _v8.label;
										return function (_v9) {
											var catId = _v9.a;
											return _Utils_Tuple2(catId, id);
										}(label);
									},
									$author$project$Graph$nodes(
										$author$project$Graph$forgetAcyclic(graph)))))
					});
			});
		var fillCategoriesField = F2(
			function (graph, mdl) {
				return _Utils_update(
					mdl,
					{
						categories: $elm$core$Maybe$Just(
							A2(
								$elm$core$Dict$map,
								F2(
									function (_v6, id) {
										return function (_v7) {
											var info = _v7.b;
											return info;
										}(
											A2(
												$elm$core$Maybe$withDefault,
												$author$project$Domain$rootServiceCategory,
												A2(
													$elm$core$Maybe$map,
													A2(
														$elm$core$Basics$composeR,
														function ($) {
															return $.node;
														},
														function ($) {
															return $.label;
														}),
													A2(
														$author$project$Graph$get,
														id,
														$author$project$Graph$forgetAcyclic(graph)))));
									}),
								A2($elm$core$Maybe$withDefault, $elm$core$Dict$empty, mdl.ids)))
					});
			});
		var fillCategories = F3(
			function (graph, services, mdl) {
				var categorized = A3(
					$elm$core$List$foldl,
					function (service) {
						return A2(
							$elm$core$Dict$update,
							service.domain.category,
							function (v) {
								return $elm$core$Maybe$Just(
									A2(
										$elm$core$List$cons,
										service,
										A2($elm$core$Maybe$withDefault, _List_Nil, v)));
							});
					},
					$elm$core$Dict$empty,
					$elm$core$Dict$values(services));
				var fillCategory = function (_v5) {
					var id = _v5.a;
					var meta = _v5.b.meta;
					var domain = _v5.b.domain;
					return A2(
						$author$project$Domain$ServiceCategory,
						id,
						A2(
							$author$project$Domain$PB$Record,
							meta,
							_Utils_update(
								domain,
								{
									services: A2(
										$elm$core$Maybe$withDefault,
										_List_Nil,
										A2($elm$core$Dict$get, id, categorized))
								})));
				};
				var filled = A2($author$project$Graph$mapNodes, fillCategory, graph);
				return _Utils_update(
					mdl,
					{
						currentCategory: $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (_v4) {
									var id = _v4.a;
									return _Utils_eq(
										id,
										A2($elm$core$Maybe$withDefault, '', model.categoryID));
								},
								A2(
									$elm$core$List$map,
									function ($) {
										return $.label;
									},
									$author$project$Graph$nodes(filled)))),
						graph: $author$project$API$Status$Loaded(filled)
					});
			});
		var fillBreadcrumbs = F2(
			function (graph, mdl) {
				return _Utils_update(
					mdl,
					{
						breadcrumbs: $elm$core$Maybe$Just(
							A2(
								$author$project$Page$ServiceCategory$longestRoutes,
								_List_Nil,
								A2(
									$author$project$Graph$Tree$map,
									function (_v3) {
										var id = _v3.a;
										return id;
									},
									A3($author$project$Page$ServiceCategory$treeify, $author$project$Domain$graphRootID, $author$project$Domain$rootServiceCategory, graph))))
					});
			});
		switch (msg.$) {
			case 'GotGraph':
				if (msg.a.$ === 'Ok') {
					var graph = msg.a.a;
					return _Utils_Tuple2(
						A2(
							fillCategoriesField,
							graph,
							A2(
								fillBreadcrumbs,
								graph,
								A2(
									fillIds,
									graph,
									function () {
										var _v1 = model.services;
										if (_v1.$ === 'Loaded') {
											var services = _v1.a;
											return A3(
												fillCategories,
												$author$project$Graph$forgetAcyclic(graph),
												services,
												model);
										} else {
											return _Utils_update(
												model,
												{
													graph: $author$project$API$Status$Loaded(
														$author$project$Graph$forgetAcyclic(graph))
												});
										}
									}()))),
						$elm$core$Platform$Cmd$none);
				} else {
					var code = msg.a.a;
					return _Debug_todo(
						'Page.ServiceCategory',
						{
							start: {line: 234, column: 13},
							end: {line: 234, column: 23}
						})('got graph error');
				}
			case 'GotServices':
				if (msg.a.$ === 'Ok') {
					var services = msg.a.a;
					var serviceDict = $elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (s) {
								return _Utils_Tuple2(s.meta.id, s);
							},
							services));
					var _v2 = model.graph;
					if (_v2.$ === 'Loaded') {
						var graph = _v2.a;
						return _Utils_Tuple2(
							A3(
								fillCategories,
								graph,
								serviceDict,
								_Utils_update(
									model,
									{
										services: $author$project$API$Status$Loaded(serviceDict)
									})),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									services: $author$project$API$Status$Loaded(serviceDict)
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var code = msg.a.a;
					return _Debug_todo(
						'Page.ServiceCategory',
						{
							start: {line: 237, column: 13},
							end: {line: 237, column: 23}
						})('got services error');
				}
			case 'ClickedSubcategory':
				var categoryId = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$browser$Browser$Navigation$pushUrl,
						$author$project$Session$getKey(model.session),
						'/service-category/' + categoryId));
			default:
				var serviceID = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$browser$Browser$Navigation$pushUrl,
						$author$project$Session$getKey(model.session),
						'/service/' + serviceID));
		}
	});
var $author$project$Page$ServiceCategory$ClickedService = function (a) {
	return {$: 'ClickedService', a: a};
};
var $author$project$Page$ServiceCategory$ClickedSubcategory = function (a) {
	return {$: 'ClickedSubcategory', a: a};
};
var $author$project$Page$ServiceCategory$breadcrumbs = function (model) {
	var divider = A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerY]),
		A2(
			$author$project$UI$materialIcon,
			'chevron_right',
			$author$project$UI$scaled(5)));
	var _v0 = _Utils_Tuple3(model.categoryID, model.breadcrumbs, model.categories);
	if (((_v0.a.$ === 'Just') && (_v0.b.$ === 'Just')) && (_v0.c.$ === 'Just')) {
		var categoryId = _v0.a.a;
		var crumbs = _v0.b.a;
		var categories = _v0.c.a;
		return A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
					$mdgriffith$elm_ui$Element$Font$bold
				]),
			A2(
				$elm$core$List$intersperse,
				divider,
				A2(
					$elm$core$List$map,
					function (_v1) {
						var meta = _v1.meta;
						var domain = _v1.domain;
						return A2(
							$mdgriffith$elm_ui$Element$link,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerY]),
							{
								label: $mdgriffith$elm_ui$Element$text(domain.name),
								url: '/service-category/' + meta.id
							});
					},
					A2(
						$elm$core$List$filterMap,
						function (x) {
							return A2($elm$core$Dict$get, x, categories);
						},
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2($elm$core$Dict$get, categoryId, crumbs))))));
	} else {
		return $mdgriffith$elm_ui$Element$none;
	}
};
var $author$project$UI$contained = $mdgriffith$elm_ui$Element$htmlAttribute(
	$elm$html$Html$Attributes$class('contained'));
var $author$project$Page$ServiceCategory$cardImage = function (url) {
	var attrs = _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width(
			$mdgriffith$elm_ui$Element$px(256)),
			$mdgriffith$elm_ui$Element$height(
			$mdgriffith$elm_ui$Element$px(256)),
			$author$project$UI$contained,
			$mdgriffith$elm_ui$Element$Border$rounded(
			$author$project$UI$scaled(-2)),
			$mdgriffith$elm_ui$Element$clip
		]);
	if (url.$ === 'Just') {
		var x = url.a;
		return A2(
			$mdgriffith$elm_ui$Element$image,
			attrs,
			{description: '', src: x});
	} else {
		return A2($mdgriffith$elm_ui$Element$el, attrs, $mdgriffith$elm_ui$Element$none);
	}
};
var $author$project$UI$focusable = $mdgriffith$elm_ui$Element$htmlAttribute(
	A2($elm$html$Html$Attributes$attribute, 'tabindex', '0'));
var $author$project$UI$onEnter = function (msg) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$elm$html$Html$Events$on,
			'keyup',
			A2(
				$elm$json$Json$Decode$andThen,
				function (_v0) {
					var key = _v0.a;
					var target = _v0.b;
					var _v1 = _Utils_Tuple2(key, target);
					if (_v1.a === 'Enter') {
						if (_v1.b === 'TEXTAREA') {
							return $elm$json$Json$Decode$fail('ignored since target is a text input');
						} else {
							return $elm$json$Json$Decode$succeed(msg);
						}
					} else {
						return $elm$json$Json$Decode$fail('not the enter key');
					}
				},
				A3(
					$elm$json$Json$Decode$map2,
					$elm$core$Tuple$pair,
					A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string),
					A2(
						$elm$json$Json$Decode$at,
						_List_fromArray(
							['target', 'nodeName']),
						$elm$json$Json$Decode$string)))));
};
var $author$project$Page$ServiceCategory$infoCard = F2(
	function (message, info) {
		var altStyle = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_100),
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0)
			]);
		return A2(
			$author$project$UI$Card$contentCard,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$center,
					$mdgriffith$elm_ui$Element$Font$bold,
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(-1)),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$pointer,
					$mdgriffith$elm_ui$Element$Events$onClick(
					message(info.meta.id)),
					$mdgriffith$elm_ui$Element$focused(altStyle),
					$mdgriffith$elm_ui$Element$mouseOver(altStyle),
					$author$project$UI$onEnter(
					message(info.meta.id)),
					$author$project$UI$focusable,
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
					$author$project$UI$transitionDuration($author$project$UI$durations.medium)
				]),
			_List_fromArray(
				[
					$author$project$Page$ServiceCategory$cardImage(info.domain.image),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_Nil,
					$elm$core$List$singleton(
						$mdgriffith$elm_ui$Element$text(info.domain.name)))
				]));
	});
var $author$project$Page$ServiceCategory$viewCards = function (cards) {
	if (!cards.b) {
		return $mdgriffith$elm_ui$Element$none;
	} else {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$padding(
					$author$project$UI$scaled(1))
				]),
			A2(
				$mdgriffith$elm_ui$Element$wrappedRow,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(1)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						$author$project$UI$centerJustifiedRowContent,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				cards));
	}
};
var $author$project$Page$ServiceCategory$viewCategory = F4(
	function (graph, ids, model, _v0) {
		var id = _v0.a;
		var info = _v0.b;
		var subcategories = A2(
			$elm$core$List$map,
			function (_v1) {
				var cat = _v1.b;
				return cat;
			},
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.node;
					},
					function ($) {
						return $.label;
					}),
				A2(
					$elm$core$List$filterMap,
					function (out) {
						return A2($author$project$Graph$get, out, graph);
					},
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.outgoing;
								},
								$elm_community$intdict$IntDict$keys),
							A2(
								$author$project$Graph$get,
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$Domain$graphRootID,
									A2($elm$core$Dict$get, id, ids)),
								graph))))));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 900, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$centerX,
					A2(
					$mdgriffith$elm_ui$Element$paddingXY,
					$author$project$UI$scaled(-4),
					$author$project$UI$scaled(1))
				]),
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$html(
						$author$project$UI$withGlobalBackgroundImage(require('/build/front/public/background.webp'))),
						$author$project$Page$ServiceCategory$breadcrumbs(model),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$author$project$UI$fontsizes.huge,
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
								A2($mdgriffith$elm_ui$Element$Font$glow, $author$project$UI$colors.neutral_100, 2),
								$mdgriffith$elm_ui$Element$Font$center,
								$mdgriffith$elm_ui$Element$Font$heavy
							]),
						$elm$core$List$singleton(
							$mdgriffith$elm_ui$Element$text(info.domain.name))),
						A2(
						$elm$core$Maybe$withDefault,
						$mdgriffith$elm_ui$Element$none,
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$mdgriffith$elm_ui$Element$text,
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$singleton,
									$mdgriffith$elm_ui$Element$paragraph(_List_Nil))),
							info.domain.description))
					]),
				A2(
					$author$project$UI$listWithDefault,
					_List_fromArray(
						[$author$project$UI$emptyContentHint]),
					_List_fromArray(
						[
							$author$project$Page$ServiceCategory$viewCards(
							A2(
								$elm$core$List$map,
								$author$project$Page$ServiceCategory$infoCard($author$project$Page$ServiceCategory$ClickedSubcategory),
								subcategories)),
							$author$project$Page$ServiceCategory$viewCards(
							A2(
								$elm$core$List$map,
								$author$project$Page$ServiceCategory$infoCard($author$project$Page$ServiceCategory$ClickedService),
								info.domain.services))
						]))));
	});
var $author$project$Page$ServiceCategory$view = function (model) {
	var _v0 = model.graph;
	switch (_v0.$) {
		case 'Loaded':
			var graph = _v0.a;
			return A4(
				$author$project$Page$ServiceCategory$viewCategory,
				graph,
				A2($elm$core$Maybe$withDefault, $elm$core$Dict$empty, model.ids),
				model,
				A2($elm$core$Maybe$withDefault, $author$project$Domain$rootServiceCategory, model.currentCategory));
		case 'NotStarted':
			return _Debug_todo(
				'Page.ServiceCategory',
				{
					start: {line: 493, column: 13},
					end: {line: 493, column: 23}
				})('branch \'NotStarted\' not implemented');
		case 'Loading':
			return A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, $author$project$UI$Loading$spinner);
		default:
			return _Debug_todo(
				'Page.ServiceCategory',
				{
					start: {line: 499, column: 13},
					end: {line: 499, column: 23}
				})('branch \'Failed\' not implemented');
	}
};
var $author$project$Page$ServiceCategory$wrap = F2(
	function (base, args) {
		return {categoryId: args.categoryId, device: base.device, session: base.session};
	});
var $author$project$Page$ServiceCategory$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Public,
	{
		baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
		init: $author$project$Page$ServiceCategory$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $author$project$Page$ServiceCategory$title,
		update: $author$project$Framework$PlainUpdate($author$project$Page$ServiceCategory$update),
		view: $author$project$Framework$PlainView($author$project$Page$ServiceCategory$view),
		wrapArgs: $author$project$Page$ServiceCategory$wrap
	});
var $author$project$Page$Sync1C$GotBuildingsCollectionID = function (a) {
	return {$: 'GotBuildingsCollectionID', a: a};
};
var $author$project$Page$Sync1C$GotDumps = function (a) {
	return {$: 'GotDumps', a: a};
};
var $author$project$Page$Sync1C$GotFlatsCollectionID = function (a) {
	return {$: 'GotFlatsCollectionID', a: a};
};
var $author$project$Page$Sync1C$ListOfDumps = {$: 'ListOfDumps'};
var $author$project$API$Fields = function (a) {
	return {$: 'Fields', a: a};
};
var $author$project$Domain$Diffs = F3(
	function (buildings, flats, tenants) {
		return {buildings: buildings, flats: flats, tenants: tenants};
	});
var $author$project$Domain$Dump = F5(
	function (author, buildings, flats, tenants, diffs) {
		return {author: author, buildings: buildings, diffs: diffs, flats: flats, tenants: tenants};
	});
var $author$project$Domain$dumpDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Domain$Dump,
	A3($author$project$Domain$PB$shortResourceDecoderWithID, 'author', $author$project$Domain$PB$idDecoder, $author$project$Domain$managerDecoder),
	A2($elm$json$Json$Decode$field, 'buildings', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'flats', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'tenants', $elm$json$Json$Decode$int),
	$elm$json$Json$Decode$succeed(
		A3($author$project$Domain$Diffs, $author$project$API$Status$NotStarted, $author$project$API$Status$NotStarted, $author$project$API$Status$NotStarted)));
var $author$project$Domain$PB$metaFields = _List_fromArray(
	['id', 'collectionId', 'collectionName', 'created', 'updated']);
var $author$project$Collections$getDumps = function (authHeader) {
	return A3(
		$author$project$API$getAll,
		'1C_dump',
		A2(
			$author$project$API$Get,
			$author$project$Domain$PB$decoderWithID($author$project$Domain$dumpDecoder),
			_List_fromArray(
				[
					$author$project$API$Sort(
					_List_fromArray(
						['-created'])),
					$author$project$API$Expand(
					_List_fromArray(
						['author'])),
					$author$project$API$Fields(
					_Utils_ap(
						_List_fromArray(
							['author', 'buildings', 'flats', 'tenants', 'expand.author.*']),
						$author$project$Domain$PB$metaFields))
				])),
		_List_fromArray(
			[authHeader]));
};
var $author$project$Page$Sync1C$init = function (args) {
	var authHeader = $author$project$Session$authorizationHeader(
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$author$project$Session$getToken(args.session)));
	return _Utils_Tuple2(
		{
			collectionIDs: {buildings: $author$project$API$Status$Loading, flats: $author$project$API$Status$Loading},
			device: args.device,
			dumps: $author$project$API$Status$Loading,
			session: args.session,
			state: $author$project$Page$Sync1C$ListOfDumps
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2(
					$elm$core$Task$attempt,
					$author$project$Page$Sync1C$GotDumps,
					A2($author$project$Collections$getDumps, authHeader, _List_Nil)),
					A2(
					$elm$core$Task$attempt,
					$author$project$Page$Sync1C$GotBuildingsCollectionID,
					A2($author$project$Collections$getCollectionID, authHeader, 'building')),
					A2(
					$elm$core$Task$attempt,
					$author$project$Page$Sync1C$GotFlatsCollectionID,
					A2($author$project$Collections$getCollectionID, authHeader, 'flat'))
				])));
};
var $author$project$Framework$ManagerAuthenticationRequired = {$: 'ManagerAuthenticationRequired'};
var $author$project$Framework$managerOnly = function (args) {
	var _v0 = $author$project$Session$getViewer(args.session);
	if ((_v0.$ === 'Authenticated') && (_v0.a.b.$ === 'ManagerLocalStorage')) {
		var _v1 = _v0.a;
		return $author$project$Framework$AccessGranted;
	} else {
		return $author$project$Framework$ManagerAuthenticationRequired;
	}
};
var $author$project$Framework$Batch = function (a) {
	return {$: 'Batch', a: a};
};
var $author$project$Websocket$CloseWebSocket = function (a) {
	return {$: 'CloseWebSocket', a: a};
};
var $author$project$Page$Sync1C$ClosedWebSocket = {$: 'ClosedWebSocket'};
var $author$project$Websocket$ConfirmHealth = F2(
	function (a, b) {
		return {$: 'ConfirmHealth', a: a, b: b};
	});
var $author$project$Page$Sync1C$CouldNotDecodeWSMessage = function (a) {
	return {$: 'CouldNotDecodeWSMessage', a: a};
};
var $author$project$Page$Sync1C$Done = {$: 'Done'};
var $author$project$Page$Sync1C$GotManagerWSToken = F2(
	function (a, b) {
		return {$: 'GotManagerWSToken', a: a, b: b};
	});
var $author$project$Page$Sync1C$GotWebsocket = function (a) {
	return {$: 'GotWebsocket', a: a};
};
var $author$project$Page$Sync1C$Merging = {$: 'Merging'};
var $author$project$Websocket$OpenWebSocket = F5(
	function (a, b, c, d, e) {
		return {$: 'OpenWebSocket', a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Page$Sync1C$ReceivedDataFromWS = function (a) {
	return {$: 'ReceivedDataFromWS', a: a};
};
var $author$project$PopUp$Rich = function (a) {
	return {$: 'Rich', a: a};
};
var $author$project$Websocket$SendMessage = F2(
	function (a, b) {
		return {$: 'SendMessage', a: a, b: b};
	});
var $author$project$Page$Sync1C$SingleDump = F4(
	function (a, b, c, d) {
		return {$: 'SingleDump', a: a, b: b, c: c, d: d};
	});
var $author$project$Page$Sync1C$SyncBuildingsCreationsAndRemovals = function (a) {
	return {$: 'SyncBuildingsCreationsAndRemovals', a: a};
};
var $author$project$Page$Sync1C$SyncBuildingsEdits = {$: 'SyncBuildingsEdits'};
var $author$project$Page$Sync1C$SyncFlatsCreationsAndRemovals = function (a) {
	return {$: 'SyncFlatsCreationsAndRemovals', a: a};
};
var $author$project$Page$Sync1C$SyncFlatsEdits = {$: 'SyncFlatsEdits'};
var $author$project$Page$Sync1C$SyncTenantsCreationsAndRemovals = function (a) {
	return {$: 'SyncTenantsCreationsAndRemovals', a: a};
};
var $author$project$Page$Sync1C$SyncTenantsEdits = {$: 'SyncTenantsEdits'};
var $author$project$Page$Sync1C$TimeToHealthCheckWS = function (a) {
	return {$: 'TimeToHealthCheckWS', a: a};
};
var $author$project$Page$Sync1C$Viewing = {$: 'Viewing'};
var $author$project$Framework$WSCommand = function (a) {
	return {$: 'WSCommand', a: a};
};
var $elmcraft$core_extra$Tuple$Extra$apply = F2(
	function (f, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		return A2(f, a, b);
	});
var $author$project$Page$Sync1C$clearSelection = function (selection) {
	return _Utils_update(
		selection,
		{
			currentSelectionsByCategory: A2(
				$elm$core$Dict$map,
				F2(
					function (_v0, _v1) {
						return 0;
					}),
				selection.currentSelectionsByCategory),
			selected: $elm$core$Dict$empty
		});
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Page$Sync1C$diffCollection = {building: 'building', flat: 'flat', tenant: 'tenant'};
var $author$project$Page$Sync1C$diffKind = {
	create: _Utils_chr('+'),
	edit: _Utils_chr('~'),
	remove: _Utils_chr('-')
};
var $elmcraft$core_extra$List$Extra$andThen = $elm$core$List$concatMap;
var $elmcraft$core_extra$List$Extra$lift2 = F3(
	function (f, la, lb) {
		return A2(
			$elmcraft$core_extra$List$Extra$andThen,
			function (a) {
				return A2(
					$elmcraft$core_extra$List$Extra$andThen,
					function (b) {
						return _List_fromArray(
							[
								A2(f, a, b)
							]);
					},
					lb);
			},
			la);
	});
var $author$project$Page$Sync1C$emptySelection = function () {
	var allCategories = A3(
		$elmcraft$core_extra$List$Extra$lift2,
		$elm$core$Tuple$pair,
		_List_fromArray(
			[$author$project$Page$Sync1C$diffCollection.building, $author$project$Page$Sync1C$diffCollection.flat, $author$project$Page$Sync1C$diffCollection.tenant]),
		_List_fromArray(
			[$author$project$Page$Sync1C$diffKind.create, $author$project$Page$Sync1C$diffKind.edit, $author$project$Page$Sync1C$diffKind.remove]));
	return {
		currentSelectionsByCategory: $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (key) {
					return _Utils_Tuple2(key, 0);
				},
				allCategories)),
		displayed: $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (key) {
					return _Utils_Tuple2(key, true);
				},
				allCategories)),
		selected: $elm$core$Dict$empty
	};
}();
var $author$project$Domain$ExpectedTenant = F5(
	function (name, surname, patronymic, email, flat) {
		return {email: email, flat: flat, name: name, patronymic: patronymic, surname: surname};
	});
var $author$project$Domain$expectedTenantDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Domain$ExpectedTenant,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'surname', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'patronymic', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string),
	A4(
		$author$project$Domain$PB$resourceDecoder,
		_List_fromArray(
			['flat']),
		$elm$json$Json$Decode$list($author$project$Domain$PB$idDecoder),
		_List_fromArray(
			['expand', 'flat']),
		$elm$json$Json$Decode$list(
			$author$project$Domain$PB$decoderWithID($author$project$Domain$flatDecoder))));
var $elmcraft$core_extra$Result$Extra$extract = F2(
	function (f, x) {
		if (x.$ === 'Ok') {
			var a = x.a;
			return a;
		} else {
			var e = x.a;
			return f(e);
		}
	});
var $author$project$Collections$getManagerWSToken = function (authHeader) {
	return $elm$http$Http$task(
		{
			body: $elm$http$Http$emptyBody,
			headers: _List_fromArray(
				[authHeader]),
			method: 'GET',
			resolver: $author$project$API$jsonResolver($elm$json$Json$Decode$string),
			timeout: $author$project$API$timeout,
			url: '/manage/ws-token'
		});
};
var $elm_community$json_extra$Json$Decode$Extra$decodeDictFromTuples = F2(
	function (keyDecoder, tuples) {
		if (!tuples.b) {
			return $elm$json$Json$Decode$succeed($elm$core$Dict$empty);
		} else {
			var _v1 = tuples.a;
			var strKey = _v1.a;
			var value = _v1.b;
			var rest = tuples.b;
			var _v2 = A2($elm$json$Json$Decode$decodeString, keyDecoder, strKey);
			if (_v2.$ === 'Ok') {
				var key = _v2.a;
				return A2(
					$elm$json$Json$Decode$andThen,
					A2(
						$elm$core$Basics$composeR,
						A2($elm$core$Dict$insert, key, value),
						$elm$json$Json$Decode$succeed),
					A2($elm_community$json_extra$Json$Decode$Extra$decodeDictFromTuples, keyDecoder, rest));
			} else {
				var error = _v2.a;
				return $elm$json$Json$Decode$fail(
					$elm$json$Json$Decode$errorToString(error));
			}
		}
	});
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm_community$json_extra$Json$Decode$Extra$dict2 = F2(
	function (keyDecoder, valueDecoder) {
		return A2(
			$elm$json$Json$Decode$andThen,
			$elm_community$json_extra$Json$Decode$Extra$decodeDictFromTuples(keyDecoder),
			$elm$json$Json$Decode$keyValuePairs(valueDecoder));
	});
var $author$project$Domain$DiffCreate = function (_new) {
	return {_new: _new};
};
var $author$project$Domain$PB$metaVirtualDecoder = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (b, c) {
			return {collectionId: b, collectionName: c};
		}),
	A2($elm$json$Json$Decode$field, 'collectionId', $author$project$Domain$PB$nonEmptyString),
	A2($elm$json$Json$Decode$field, 'collectionName', $author$project$Domain$PB$nonEmptyString));
var $author$project$Domain$PB$decoderNoID = function (domainDecoder) {
	return A3($elm$json$Json$Decode$map2, $author$project$Domain$PB$Record, $author$project$Domain$PB$metaVirtualDecoder, domainDecoder);
};
var $author$project$Domain$diffCreateDecoder = function (aDecoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$Domain$DiffCreate,
		A2(
			$elm$json$Json$Decode$field,
			'1c',
			$author$project$Domain$PB$decoderNoID(aDecoder)));
};
var $author$project$Domain$DiffEdit = F3(
	function (id, old, _new) {
		return {id: id, _new: _new, old: old};
	});
var $author$project$Domain$diffEditDecoder = function (aDecoder) {
	return A4(
		$elm$json$Json$Decode$map3,
		$author$project$Domain$DiffEdit,
		A2($elm$json$Json$Decode$field, 'id', $author$project$Domain$PB$idDecoder),
		A2(
			$elm$json$Json$Decode$field,
			'db',
			$author$project$Domain$PB$decoderWithID(aDecoder)),
		A2(
			$elm$json$Json$Decode$field,
			'1c',
			$author$project$Domain$PB$decoderNoID(aDecoder)));
};
var $author$project$Domain$DiffRemove = F2(
	function (id, old) {
		return {id: id, old: old};
	});
var $author$project$Domain$diffRemoveDecoder = function (aDecoder) {
	return A3(
		$elm$json$Json$Decode$map2,
		$author$project$Domain$DiffRemove,
		A2($elm$json$Json$Decode$field, 'id', $author$project$Domain$PB$idDecoder),
		A2(
			$elm$json$Json$Decode$field,
			'db',
			$author$project$Domain$PB$decoderWithID(aDecoder)));
};
var $author$project$Page$Sync1C$loadBasicFields = F2(
	function (decoder, value) {
		var decodeField = F2(
			function (field, diffDecoder) {
				return A2(
					$elm$json$Json$Decode$decodeValue,
					A2(
						$elm$json$Json$Decode$field,
						field,
						A2(
							$elm_community$json_extra$Json$Decode$Extra$dict2,
							$elm$json$Json$Decode$int,
							diffDecoder(decoder))),
					value);
			});
		return _Utils_Tuple3(
			A2(decodeField, 'creations', $author$project$Domain$diffCreateDecoder),
			A2(decodeField, 'edits', $author$project$Domain$diffEditDecoder),
			A2(decodeField, 'removals', $author$project$Domain$diffRemoveDecoder));
	});
var $author$project$Page$Sync1C$loadErrorResponse = $elm$json$Json$Decode$decodeValue($elm$json$Json$Decode$string);
var $author$project$Page$Sync1C$loadMergeResponse = $elm$json$Json$Decode$decodeValue(
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$Tuple$pair,
		A2($elm$json$Json$Decode$field, 'creation', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'removal', $elm$json$Json$Decode$int)));
var $author$project$Page$Sync1C$removeFromSelection = F2(
	function (key, selection) {
		var collection = key.a;
		var kind = key.b;
		var selected = selection.selected;
		var currentSelectionsByCategory = selection.currentSelectionsByCategory;
		return _Utils_update(
			selection,
			{
				currentSelectionsByCategory: A3(
					$elm$core$Dict$update,
					_Utils_Tuple2(collection, kind),
					$elm$core$Maybe$map(
						function (v) {
							return A2($elm$core$Dict$member, key, selected) ? (v - 1) : v;
						}),
					currentSelectionsByCategory),
				selected: A2($elm$core$Dict$remove, key, selected)
			});
	});
var $author$project$Page$Sync1C$setSelection = F3(
	function (key, value, selection) {
		var collection = key.a;
		var kind = key.b;
		var _v0 = _Utils_Tuple2(
			A2($elm$core$Dict$member, key, selection.selected),
			value);
		if (_v0.a) {
			if (_v0.b) {
				return selection;
			} else {
				return _Utils_update(
					selection,
					{
						currentSelectionsByCategory: A3(
							$elm$core$Dict$update,
							_Utils_Tuple2(collection, kind),
							$elm$core$Maybe$map(
								function (x) {
									return x - 1;
								}),
							selection.currentSelectionsByCategory),
						selected: A2($elm$core$Dict$remove, key, selection.selected)
					});
			}
		} else {
			if (!_v0.b) {
				return selection;
			} else {
				return _Utils_update(
					selection,
					{
						currentSelectionsByCategory: A3(
							$elm$core$Dict$update,
							_Utils_Tuple2(collection, kind),
							function (x) {
								return $elm$core$Maybe$Just(
									A2($elm$core$Maybe$withDefault, 0, x) + 1);
							},
							selection.currentSelectionsByCategory),
						selected: A3($elm$core$Dict$insert, key, _Utils_Tuple0, selection.selected)
					});
			}
		}
	});
var $elm$core$Process$sleep = _Process_sleep;
var $elm$core$Debug$toString = _Debug_toString;
var $author$project$Page$Sync1C$warningDecoder = function (keyDecoder) {
	return A3(
		$elm$json$Json$Decode$map2,
		$elm$core$Tuple$pair,
		A2($elm$json$Json$Decode$field, 'key', keyDecoder),
		A2(
			$elm$json$Json$Decode$field,
			'errors',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
};
var $author$project$Page$Sync1C$WSMessage = F4(
	function (content, command, context, expect) {
		return {command: command, content: content, context: context, expect: expect};
	});
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Page$Sync1C$wsMessageDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Page$Sync1C$WSMessage,
	A2($elm$json$Json$Decode$field, 'content', $elm$json$Json$Decode$value),
	A2($elm$json$Json$Decode$field, 'command', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'context', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'expect',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
var $author$project$Page$Sync1C$update = F2(
	function (msg, model) {
		var collectionIDs = model.collectionIDs;
		var _v0 = _Utils_Tuple2(model.state, msg);
		_v0$19:
		while (true) {
			switch (_v0.b.$) {
				case 'GotDumps':
					if (_v0.b.a.$ === 'Ok') {
						var dumps = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									dumps: $author$project$API$Status$Loaded(dumps)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						var code = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									dumps: $author$project$API$Status$Failed(
										A2($author$project$API$Error$LoadingFailure, code, _List_Nil))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					}
				case 'GotBuildingsCollectionID':
					if (_v0.b.a.$ === 'Ok') {
						var id = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									collectionIDs: _Utils_update(
										collectionIDs,
										{
											buildings: $author$project$API$Status$Loaded(id)
										})
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						var errorCode = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									collectionIDs: _Utils_update(
										collectionIDs,
										{
											buildings: $author$project$API$Status$Failed(
												_Debug_todo(
													'Page.Sync1C',
													{
														start: {line: 389, column: 70},
														end: {line: 389, column: 80}
													})(''))
										})
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					}
				case 'GotFlatsCollectionID':
					if (_v0.b.a.$ === 'Ok') {
						var id = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									collectionIDs: _Utils_update(
										collectionIDs,
										{
											flats: $author$project$API$Status$Loaded(id)
										})
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						var errorCode = _v0.b.a.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									collectionIDs: _Utils_update(
										collectionIDs,
										{
											flats: $author$project$API$Status$Failed(
												_Debug_todo(
													'Page.Sync1C',
													{
														start: {line: 407, column: 66},
														end: {line: 407, column: 76}
													})(''))
										})
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					}
				case 'SelectedDump':
					var dump = _v0.b.a;
					var domain = dump.domain;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								state: A4(
									$author$project$Page$Sync1C$SingleDump,
									_Utils_update(
										dump,
										{
											domain: _Utils_update(
												domain,
												{
													diffs: A3($author$project$Domain$Diffs, $author$project$API$Status$Loading, $author$project$API$Status$NotStarted, $author$project$API$Status$NotStarted)
												})
										}),
									$author$project$Page$Sync1C$SyncBuildingsCreationsAndRemovals($author$project$Page$Sync1C$Viewing),
									$author$project$Page$Sync1C$emptySelection,
									$author$project$API$Status$Loading)
							}),
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Sync1C$GotManagerWSToken(dump.meta.id),
							$author$project$Collections$getManagerWSToken(
								$author$project$Session$authorizationHeader(
									A2(
										$elm$core$Maybe$withDefault,
										'',
										$author$project$Session$getToken(model.session))))),
						$author$project$Framework$NoOp);
				case 'GotManagerWSToken':
					if (_v0.b.b.$ === 'Ok') {
						if (_v0.a.$ === 'SingleDump') {
							var _v1 = _v0.a;
							var _v2 = _v0.b;
							var dumpID = _v2.a;
							var authToken = _v2.b.a;
							return _Utils_Tuple3(
								model,
								$elm$core$Platform$Cmd$none,
								$author$project$Framework$WSCommand(
									A5(
										$author$project$Websocket$OpenWebSocket,
										authToken,
										'manage/sync/' + (dumpID + '/ws'),
										$author$project$Page$Sync1C$GotWebsocket,
										function (x) {
											return A2(
												$elmcraft$core_extra$Result$Extra$extract,
												$author$project$Page$Sync1C$CouldNotDecodeWSMessage,
												A2(
													$elm$core$Result$map,
													$author$project$Page$Sync1C$ReceivedDataFromWS,
													A2($elm$json$Json$Decode$decodeValue, $author$project$Page$Sync1C$wsMessageDecoder, x)));
										},
										$author$project$Page$Sync1C$ClosedWebSocket)));
						} else {
							break _v0$19;
						}
					} else {
						var _v3 = _v0.b;
						var dumpID = _v3.a;
						return _Debug_todo(
							'Page.Sync1C',
							{
								start: {line: 463, column: 13},
								end: {line: 463, column: 23}
							})('');
					}
				case 'GotWebsocket':
					if (_v0.a.$ === 'SingleDump') {
						var _v4 = _v0.a;
						var dump = _v4.a;
						var stage = _v4.b;
						var diffSelection = _v4.c;
						var wsID = _v0.b.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A4(
										$author$project$Page$Sync1C$SingleDump,
										dump,
										stage,
										diffSelection,
										$author$project$API$Status$Loaded(wsID))
								}),
							A2(
								$elm$core$Task$perform,
								$author$project$Page$Sync1C$TimeToHealthCheckWS,
								A2(
									$elm$core$Task$andThen,
									$elm$core$Basics$always($elm$time$Time$now),
									$elm$core$Process$sleep(1000 * 15))),
							$author$project$Framework$NoOp);
					} else {
						break _v0$19;
					}
				case 'ToggledSelection':
					if (_v0.a.$ === 'SingleDump') {
						var _v5 = _v0.a;
						var dump = _v5.a;
						var stage = _v5.b;
						var selection = _v5.c;
						var wsID = _v5.d;
						var _v6 = _v0.b;
						var key = _v6.a;
						var value = _v6.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A4(
										$author$project$Page$Sync1C$SingleDump,
										dump,
										stage,
										A3($author$project$Page$Sync1C$setSelection, key, value, selection),
										wsID)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						break _v0$19;
					}
				case 'SetDisplay':
					if (_v0.a.$ === 'SingleDump') {
						var _v7 = _v0.a;
						var dump = _v7.a;
						var stage = _v7.b;
						var selection = _v7.c;
						var wsID = _v7.d;
						var _v8 = _v0.b;
						var key = _v8.a;
						var value = _v8.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A4(
										$author$project$Page$Sync1C$SingleDump,
										dump,
										stage,
										_Utils_update(
											selection,
											{
												displayed: A3($elm$core$Dict$insert, key, value, selection.displayed)
											}),
										wsID)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						break _v0$19;
					}
				case 'ToggledCategorySelection':
					if (_v0.a.$ === 'SingleDump') {
						var _v9 = _v0.a;
						var dump = _v9.a;
						var stage = _v9.b;
						var selection = _v9.c;
						var wsID = _v9.d;
						var _v10 = _v0.b;
						var _v11 = _v10.a;
						var collection = _v11.a;
						var kind = _v11.b;
						var value = _v10.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: function () {
										var toggleCategory = F2(
											function (f1, f2) {
												return A3(
													$elm$core$List$foldl,
													function (key) {
														return A2($author$project$Page$Sync1C$setSelection, key, value);
													},
													selection,
													A2(
														$elm$core$List$map,
														function (i) {
															return _Utils_Tuple3(collection, kind, i);
														},
														A2(
															$author$project$API$Status$withDefault,
															_List_Nil,
															A2(
																$author$project$API$Status$map,
																$elm$core$Dict$keys,
																A2(
																	$author$project$API$Status$andThen,
																	f2,
																	f1(dump.domain.diffs))))));
											});
										return A4(
											$author$project$Page$Sync1C$SingleDump,
											dump,
											stage,
											function () {
												var _v12 = _Utils_Tuple2(collection, kind);
												_v12$9:
												while (true) {
													switch (_v12.a) {
														case 'building':
															switch (_v12.b.valueOf()) {
																case '+':
																	return A2(
																		toggleCategory,
																		function ($) {
																			return $.buildings;
																		},
																		function ($) {
																			return $.creations;
																		});
																case '~':
																	return A2(
																		toggleCategory,
																		function ($) {
																			return $.buildings;
																		},
																		function ($) {
																			return $.edits;
																		});
																case '-':
																	return A2(
																		toggleCategory,
																		function ($) {
																			return $.buildings;
																		},
																		function ($) {
																			return $.removals;
																		});
																default:
																	break _v12$9;
															}
														case 'flat':
															switch (_v12.b.valueOf()) {
																case '+':
																	return A2(
																		toggleCategory,
																		function ($) {
																			return $.flats;
																		},
																		function ($) {
																			return $.creations;
																		});
																case '~':
																	return A2(
																		toggleCategory,
																		function ($) {
																			return $.flats;
																		},
																		function ($) {
																			return $.edits;
																		});
																case '-':
																	return A2(
																		toggleCategory,
																		function ($) {
																			return $.flats;
																		},
																		function ($) {
																			return $.removals;
																		});
																default:
																	break _v12$9;
															}
														case 'tenant':
															switch (_v12.b.valueOf()) {
																case '+':
																	return A2(
																		toggleCategory,
																		function ($) {
																			return $.tenants;
																		},
																		function ($) {
																			return $.creations;
																		});
																case '~':
																	return A2(
																		toggleCategory,
																		function ($) {
																			return $.tenants;
																		},
																		function ($) {
																			return $.edits;
																		});
																case '-':
																	return A2(
																		toggleCategory,
																		function ($) {
																			return $.tenants;
																		},
																		function ($) {
																			return $.removals;
																		});
																default:
																	break _v12$9;
															}
														default:
															break _v12$9;
													}
												}
												return selection;
											}(),
											wsID);
									}()
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						break _v0$19;
					}
				case 'ReceivedDataFromWS':
					if (_v0.a.$ === 'SingleDump') {
						var _v13 = _v0.a;
						var dump = _v13.a;
						var stage = _v13.b;
						var selection = _v13.c;
						var wsID = _v13.d;
						var raw = _v0.b.a;
						var handleError = F2(
							function (unexpectedState, lens) {
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{
											state: A4(
												$author$project$Page$Sync1C$SingleDump,
												A2(
													lens.set,
													$author$project$API$Status$Failed(
														A2(
															$author$project$API$Error$LoadingFailure,
															$author$project$API$Error$Bug(''),
															_List_Nil)),
													dump),
												stage,
												selection,
												wsID)
										}),
									$elm$core$Platform$Cmd$none,
									$author$project$Framework$Batch(
										_List_fromArray(
											[
												A2(
												$author$project$Framework$ShowPopUp,
												A2($author$project$PopUp$PlainText, 'Ошибка синхронизации!', 'Что-то пошло не так, попробуйте перезагрузить страницу'),
												$author$project$PopUp$Important),
												$author$project$Framework$WSCommand(
												$author$project$Websocket$CloseWebSocket(
													A2($author$project$API$Status$withDefault, 0, wsID))),
												A2(
												$elm$core$Basics$always,
												$author$project$Framework$NoOp,
												A2($elm$core$Debug$log, 'unexpected fields combination', unexpectedState))
											])));
							});
						var handleInit = F2(
							function (domainDecoder, lens) {
								var _v32 = A2($author$project$Page$Sync1C$loadBasicFields, domainDecoder, raw.content);
								if (((_v32.a.$ === 'Ok') && (_v32.b.$ === 'Err')) && (_v32.c.$ === 'Ok')) {
									var _new = _v32.a.a;
									var rms = _v32.c.a;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												state: A4(
													$author$project$Page$Sync1C$SingleDump,
													A2(
														lens.set,
														$author$project$API$Status$Loaded(
															{
																collectionID: A2($author$project$API$Status$withDefault, '', model.collectionIDs.flats),
																creations: $author$project$API$Status$Loaded(_new),
																edits: $author$project$API$Status$NotStarted,
																errors: _List_Nil,
																removals: $author$project$API$Status$Loaded(rms)
															}),
														dump),
													stage,
													selection,
													wsID)
											}),
										$elm$core$Platform$Cmd$none,
										$author$project$Framework$NoOp);
								} else {
									var unexpectedFields = _v32;
									return A2(handleError, unexpectedFields, lens);
								}
							});
						var handleEdits = F2(
							function (domainDecoder, lens) {
								var _v30 = _Utils_Tuple2(
									A2($author$project$Page$Sync1C$loadBasicFields, domainDecoder, raw.content),
									lens.get(dump));
								if ((_v30.a.b.$ === 'Ok') && (_v30.b.$ === 'Loaded')) {
									var _v31 = _v30.a;
									var edits = _v31.b.a;
									var records = _v30.b.a;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												state: A4(
													$author$project$Page$Sync1C$SingleDump,
													A2(
														lens.set,
														$author$project$API$Status$Loaded(
															_Utils_update(
																records,
																{
																	edits: $author$project$API$Status$Loaded(edits)
																})),
														dump),
													stage,
													selection,
													wsID)
											}),
										$elm$core$Platform$Cmd$none,
										$author$project$Framework$NoOp);
								} else {
									var unexpectedFields = _v30;
									return A2(handleError, unexpectedFields, lens);
								}
							});
						var domainLens = A2(
							$arturopala$elm_monocle$Monocle$Lens$Lens,
							function ($) {
								return $.domain;
							},
							F2(
								function (b, a) {
									return _Utils_update(
										a,
										{domain: b});
								}));
						var diffsLens = A2(
							$arturopala$elm_monocle$Monocle$Compose$lensWithLens,
							A2(
								$arturopala$elm_monocle$Monocle$Lens$Lens,
								function ($) {
									return $.diffs;
								},
								F2(
									function (b, a) {
										return _Utils_update(
											a,
											{diffs: b});
									})),
							domainLens);
						var dumpBuildingsLens = A2(
							$arturopala$elm_monocle$Monocle$Compose$lensWithLens,
							A2(
								$arturopala$elm_monocle$Monocle$Lens$Lens,
								function ($) {
									return $.buildings;
								},
								F2(
									function (b, a) {
										return _Utils_update(
											a,
											{buildings: b});
									})),
							diffsLens);
						var handleMerged = F3(
							function (lens, collection, next) {
								var _v28 = _Utils_Tuple2(
									$author$project$Page$Sync1C$loadMergeResponse(raw.content),
									lens.get(dump));
								if ((_v28.a.$ === 'Ok') && (_v28.b.$ === 'Loaded')) {
									var _v29 = _v28.a.a;
									var creation = _v29.a;
									var removal = _v29.b;
									var diff = _v28.b.a;
									return _Utils_Tuple3(
										_Utils_update(
											model,
											{
												state: A4(
													$author$project$Page$Sync1C$SingleDump,
													A2(
														lens.set,
														$author$project$API$Status$Loaded(
															_Utils_update(
																diff,
																{
																	creations: A2(
																		$author$project$API$Status$map,
																		$elm$core$Dict$remove(creation),
																		diff.creations),
																	removals: A2(
																		$author$project$API$Status$map,
																		$elm$core$Dict$remove(removal),
																		diff.removals)
																})),
														dump),
													next($author$project$Page$Sync1C$Viewing),
													A2(
														$author$project$Page$Sync1C$removeFromSelection,
														_Utils_Tuple3(collection, $author$project$Page$Sync1C$diffKind.remove, removal),
														A2(
															$author$project$Page$Sync1C$removeFromSelection,
															_Utils_Tuple3(collection, $author$project$Page$Sync1C$diffKind.create, creation),
															selection)),
													wsID)
											}),
										$elm$core$Platform$Cmd$none,
										A2(
											$author$project$Framework$ShowPopUp,
											A2($author$project$PopUp$PlainText, 'Создание и удаление объединены', 'Теперь они считаются одним изменением'),
											$author$project$PopUp$Unimportant));
								} else {
									var unexpectedFields = _v28;
									return A2(handleError, unexpectedFields, dumpBuildingsLens);
								}
							});
						var dumpFlatsLens = A2(
							$arturopala$elm_monocle$Monocle$Compose$lensWithLens,
							A2(
								$arturopala$elm_monocle$Monocle$Lens$Lens,
								function ($) {
									return $.flats;
								},
								F2(
									function (b, a) {
										return _Utils_update(
											a,
											{flats: b});
									})),
							diffsLens);
						var dumpTenantsLens = A2(
							$arturopala$elm_monocle$Monocle$Compose$lensWithLens,
							A2(
								$arturopala$elm_monocle$Monocle$Lens$Lens,
								function ($) {
									return $.tenants;
								},
								F2(
									function (b, a) {
										return _Utils_update(
											a,
											{tenants: b});
									})),
							diffsLens);
						var _v14 = _Utils_Tuple3(stage, raw.context, raw.command);
						_v14$12:
						while (true) {
							switch (_v14.c) {
								case 'error':
									if (((_v14.a.$ === 'SyncBuildingsCreationsAndRemovals') && (_v14.a.a.$ === 'Merging')) && (_v14.b === 'buildings')) {
										var _v17 = _v14.a.a;
										var _v18 = $author$project$Page$Sync1C$loadErrorResponse(raw.content);
										if (_v18.$ === 'Ok') {
											var errorMessage = _v18.a;
											return _Utils_Tuple3(
												_Utils_update(
													model,
													{
														state: A4(
															$author$project$Page$Sync1C$SingleDump,
															dump,
															$author$project$Page$Sync1C$SyncBuildingsCreationsAndRemovals($author$project$Page$Sync1C$Viewing),
															selection,
															wsID)
													}),
												$elm$core$Platform$Cmd$none,
												A2(
													$author$project$Framework$ShowPopUp,
													A2($author$project$PopUp$PlainText, 'Ошибка!', errorMessage),
													$author$project$PopUp$Important));
										} else {
											var unexpectedFields = _v18;
											return A2(handleError, unexpectedFields, dumpBuildingsLens);
										}
									} else {
										break _v14$12;
									}
								case 'init':
									switch (_v14.a.$) {
										case 'SyncBuildingsCreationsAndRemovals':
											if ((_v14.a.a.$ === 'Viewing') && (_v14.b === 'buildings')) {
												var _v15 = _v14.a.a;
												return A2(handleInit, $author$project$Domain$buildingDecoder, dumpBuildingsLens);
											} else {
												break _v14$12;
											}
										case 'SyncFlatsCreationsAndRemovals':
											if ((_v14.a.a.$ === 'Viewing') && (_v14.b === 'flats')) {
												var _v20 = _v14.a.a;
												return A2(handleInit, $author$project$Domain$flatDecoder, dumpFlatsLens);
											} else {
												break _v14$12;
											}
										case 'SyncTenantsCreationsAndRemovals':
											if ((_v14.a.a.$ === 'Viewing') && (_v14.b === 'tenants')) {
												var _v23 = _v14.a.a;
												return A2(handleInit, $author$project$Domain$expectedTenantDecoder, dumpTenantsLens);
											} else {
												break _v14$12;
											}
										default:
											break _v14$12;
									}
								case 'merged':
									switch (_v14.a.$) {
										case 'SyncBuildingsCreationsAndRemovals':
											if ((_v14.a.a.$ === 'Merging') && (_v14.b === 'buildings')) {
												var _v16 = _v14.a.a;
												return A3(handleMerged, dumpBuildingsLens, $author$project$Page$Sync1C$diffCollection.building, $author$project$Page$Sync1C$SyncBuildingsCreationsAndRemovals);
											} else {
												break _v14$12;
											}
										case 'SyncFlatsCreationsAndRemovals':
											if ((_v14.a.a.$ === 'Merging') && (_v14.b === 'flats')) {
												var _v21 = _v14.a.a;
												return A3(handleMerged, dumpFlatsLens, $author$project$Page$Sync1C$diffCollection.flat, $author$project$Page$Sync1C$SyncFlatsCreationsAndRemovals);
											} else {
												break _v14$12;
											}
										case 'SyncTenantsCreationsAndRemovals':
											if ((_v14.a.a.$ === 'Merging') && (_v14.b === 'tenants')) {
												var _v24 = _v14.a.a;
												return A3(handleMerged, dumpTenantsLens, $author$project$Page$Sync1C$diffCollection.tenant, $author$project$Page$Sync1C$SyncTenantsCreationsAndRemovals);
											} else {
												break _v14$12;
											}
										default:
											break _v14$12;
									}
								case 'edits':
									switch (_v14.a.$) {
										case 'SyncBuildingsEdits':
											if (_v14.b === 'buildings') {
												var _v19 = _v14.a;
												return A2(handleEdits, $author$project$Domain$buildingDecoder, dumpBuildingsLens);
											} else {
												break _v14$12;
											}
										case 'SyncFlatsEdits':
											if (_v14.b === 'flats') {
												var _v22 = _v14.a;
												return A2(handleEdits, $author$project$Domain$flatDecoder, dumpFlatsLens);
											} else {
												break _v14$12;
											}
										case 'SyncTenantsEdits':
											if (_v14.b === 'tenants') {
												var _v25 = _v14.a;
												return A2(handleEdits, $author$project$Domain$expectedTenantDecoder, dumpTenantsLens);
											} else {
												break _v14$12;
											}
										default:
											break _v14$12;
									}
								case 'warning':
									var context = _v14.b;
									var showWarningPopUp = F2(
										function (header, errors) {
											return A2(
												$author$project$Framework$ShowPopUp,
												$author$project$PopUp$Rich(
													A2(
														$mdgriffith$elm_ui$Element$column,
														_List_Nil,
														A2(
															$elm$core$List$cons,
															A2(
																$mdgriffith$elm_ui$Element$paragraph,
																_List_fromArray(
																	[$mdgriffith$elm_ui$Element$Font$bold]),
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$text(header)
																	])),
															A2(
																$elm$core$List$map,
																A2(
																	$elm$core$Basics$composeR,
																	$mdgriffith$elm_ui$Element$text,
																	A2(
																		$elm$core$Basics$composeR,
																		$elm$core$List$singleton,
																		$mdgriffith$elm_ui$Element$paragraph(_List_Nil))),
																errors)))),
												$author$project$PopUp$Important);
										});
									var popUpHeader = function () {
										switch (context) {
											case 'buildings':
												return $elm$json$Json$Decode$string;
											case 'flats':
												return A2(
													$elm$json$Json$Decode$map,
													function (x) {
														return 'л/с №' + $elm$core$String$fromInt(x);
													},
													$elm$json$Json$Decode$int);
											case 'tenants':
												return $elm$json$Json$Decode$oneOf(
													_List_fromArray(
														[
															$elm$json$Json$Decode$string,
															A2(
															$elm$json$Json$Decode$map,
															$elm$core$String$join(' '),
															$elm$json$Json$Decode$list($elm$json$Json$Decode$string))
														]));
											default:
												return _Debug_todo(
													'Page.Sync1C',
													{
														start: {line: 759, column: 37},
														end: {line: 759, column: 47}
													})('');
										}
									}();
									var oneOrManyWarnings = $elm$json$Json$Decode$oneOf(
										_List_fromArray(
											[
												A2(
												$elm$json$Json$Decode$map,
												$elm$core$List$singleton,
												$author$project$Page$Sync1C$warningDecoder(popUpHeader)),
												$elm$json$Json$Decode$list(
												$author$project$Page$Sync1C$warningDecoder(popUpHeader))
											]));
									var _v26 = A2($elm$json$Json$Decode$decodeValue, oneOrManyWarnings, raw.content);
									if (_v26.$ === 'Ok') {
										var warnings = _v26.a;
										return _Utils_Tuple3(
											model,
											$elm$core$Platform$Cmd$none,
											$author$project$Framework$Batch(
												A2(
													$elm$core$List$map,
													$elmcraft$core_extra$Tuple$Extra$apply(showWarningPopUp),
													warnings)));
									} else {
										var fail = _v26.a;
										return A2(
											_Debug_todo(
												'Page.Sync1C',
												{
													start: {line: 792, column: 65},
													end: {line: 792, column: 75}
												}),
											'',
											A2($elm$core$Debug$log, 'failed decoding', fail));
									}
								case 'done':
									if (_v14.b === 'sync') {
										return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
									} else {
										break _v14$12;
									}
								default:
									break _v14$12;
							}
						}
						return A2(
							_Debug_todo(
								'Page.Sync1C',
								{
									start: {line: 800, column: 28},
									end: {line: 800, column: 38}
								}),
							'unexpected command',
							A2(
								$elm$core$Debug$log,
								'erronous state',
								_Utils_Tuple2(stage, raw)));
					} else {
						break _v0$19;
					}
				case 'PressedMergeButton':
					if ((_v0.a.$ === 'SingleDump') && (_v0.a.d.$ === 'Loaded')) {
						var _v33 = _v0.a;
						var dump = _v33.a;
						var stage = _v33.b;
						var selection = _v33.c;
						var wsID = _v33.d.a;
						var _v34 = _v0.b;
						var _new = _v34.a;
						var rm = _v34.b;
						var _v35 = function () {
							switch (stage.$) {
								case 'SyncBuildingsCreationsAndRemovals':
									return _Utils_Tuple2(
										$author$project$Page$Sync1C$SyncBuildingsCreationsAndRemovals($author$project$Page$Sync1C$Merging),
										'buildings');
								case 'SyncFlatsCreationsAndRemovals':
									return _Utils_Tuple2(
										$author$project$Page$Sync1C$SyncFlatsCreationsAndRemovals($author$project$Page$Sync1C$Merging),
										'flats');
								case 'SyncTenantsCreationsAndRemovals':
									return _Utils_Tuple2(
										$author$project$Page$Sync1C$SyncTenantsCreationsAndRemovals($author$project$Page$Sync1C$Merging),
										'tenants');
								default:
									var x = stage;
									return _Utils_Tuple2(x, '');
							}
						}();
						var state = _v35.a;
						var context = _v35.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A4(
										$author$project$Page$Sync1C$SingleDump,
										dump,
										state,
										selection,
										$author$project$API$Status$Loaded(wsID))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$WSCommand(
								A2(
									$author$project$Websocket$SendMessage,
									wsID,
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'command',
												$elm$json$Json$Encode$string('merge')),
												_Utils_Tuple2(
												'content',
												$elm$json$Json$Encode$object(
													_List_fromArray(
														[
															_Utils_Tuple2(
															'creation',
															$elm$json$Json$Encode$int(_new)),
															_Utils_Tuple2(
															'removal',
															$elm$json$Json$Encode$int(rm))
														]))),
												_Utils_Tuple2(
												'context',
												$elm$json$Json$Encode$string(context))
											])))));
					} else {
						break _v0$19;
					}
				case 'ProceedToNextPhase':
					if ((_v0.a.$ === 'SingleDump') && (_v0.a.d.$ === 'Loaded')) {
						var _v37 = _v0.a;
						var dump = _v37.a;
						var stage = _v37.b;
						var selection = _v37.c;
						var wsID = _v37.d.a;
						var _v38 = _v0.b;
						var proceedTo = function (nextStage) {
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										state: A4(
											$author$project$Page$Sync1C$SingleDump,
											dump,
											A2($elm$core$Debug$log, 'next stage', nextStage),
											$author$project$Page$Sync1C$clearSelection(selection),
											$author$project$API$Status$Loaded(wsID))
									}),
								$elm$core$Platform$Cmd$none,
								$author$project$Framework$WSCommand(
									A2(
										$author$project$Websocket$SendMessage,
										wsID,
										$elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'command',
													$elm$json$Json$Encode$string('next'))
												])))));
						};
						_v39$6:
						while (true) {
							switch (stage.$) {
								case 'SyncBuildingsCreationsAndRemovals':
									if (stage.a.$ === 'Viewing') {
										var _v40 = stage.a;
										return proceedTo($author$project$Page$Sync1C$SyncBuildingsEdits);
									} else {
										break _v39$6;
									}
								case 'SyncBuildingsEdits':
									return proceedTo(
										$author$project$Page$Sync1C$SyncFlatsCreationsAndRemovals($author$project$Page$Sync1C$Viewing));
								case 'SyncFlatsCreationsAndRemovals':
									if (stage.a.$ === 'Viewing') {
										var _v41 = stage.a;
										return proceedTo($author$project$Page$Sync1C$SyncFlatsEdits);
									} else {
										break _v39$6;
									}
								case 'SyncFlatsEdits':
									return proceedTo(
										$author$project$Page$Sync1C$SyncTenantsCreationsAndRemovals($author$project$Page$Sync1C$Viewing));
								case 'SyncTenantsCreationsAndRemovals':
									if (stage.a.$ === 'Viewing') {
										var _v42 = stage.a;
										return proceedTo($author$project$Page$Sync1C$SyncTenantsEdits);
									} else {
										break _v39$6;
									}
								case 'SyncTenantsEdits':
									return proceedTo($author$project$Page$Sync1C$Done);
								default:
									break _v39$6;
							}
						}
						var unexpected = stage;
						return _Debug_todo(
							'Page.Sync1C',
							{
								start: {line: 875, column: 21},
								end: {line: 875, column: 31}
							})(
							$elm$core$Debug$toString(unexpected));
					} else {
						break _v0$19;
					}
				case 'ClosedWebSocket':
					if (_v0.a.$ === 'SingleDump') {
						var _v43 = _v0.a;
						var dump = _v43.a;
						var stage = _v43.b;
						var selection = _v43.c;
						var _v44 = _v0.b;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: A4($author$project$Page$Sync1C$SingleDump, dump, stage, selection, $author$project$API$Status$NotStarted)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						break _v0$19;
					}
				case 'CouldNotDecodeWSMessage':
					if (_v0.a.$ === 'SingleDump') {
						var _v45 = _v0.a;
						var wsID = _v45.d;
						var error = _v0.b.a;
						return _Debug_todo(
							'Page.Sync1C',
							{
								start: {line: 884, column: 13},
								end: {line: 884, column: 23}
							})('');
					} else {
						break _v0$19;
					}
				case 'TimeToHealthCheckWS':
					if ((_v0.a.$ === 'SingleDump') && (_v0.a.d.$ === 'Loaded')) {
						var _v46 = _v0.a;
						var wsID = _v46.d.a;
						var now = _v0.b.a;
						return _Utils_Tuple3(
							model,
							A2(
								$elm$core$Task$perform,
								$author$project$Page$Sync1C$TimeToHealthCheckWS,
								A2(
									$elm$core$Task$andThen,
									$elm$core$Basics$always($elm$time$Time$now),
									$elm$core$Process$sleep(1000 * 15))),
							$author$project$Framework$WSCommand(
								A2($author$project$Websocket$ConfirmHealth, wsID, now)));
					} else {
						break _v0$19;
					}
				default:
					break _v0$19;
			}
		}
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
	});
var $author$project$Page$Sync1C$PressedMergeButton = F2(
	function (a, b) {
		return {$: 'PressedMergeButton', a: a, b: b};
	});
var $author$project$Page$Sync1C$ProceedToNextPhase = {$: 'ProceedToNextPhase'};
var $author$project$Page$Sync1C$SetDisplay = F2(
	function (a, b) {
		return {$: 'SetDisplay', a: a, b: b};
	});
var $author$project$Page$Sync1C$ToggledCategorySelection = F2(
	function (a, b) {
		return {$: 'ToggledCategorySelection', a: a, b: b};
	});
var $author$project$Page$Sync1C$NoOp = {$: 'NoOp'};
var $author$project$Page$Sync1C$iconedLong = F4(
	function (icon, insignificantIconColor, old, _new) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(-2)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_Utils_eq(old, _new) ? _List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(insignificantIconColor)
						]),
					A2(
						$author$project$UI$materialIcon,
						icon,
						$author$project$UI$scaled(4))),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_Nil,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(old)
						]))
				]) : _List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
						]),
					A2(
						$author$project$UI$materialIcon,
						icon,
						$author$project$UI$scaled(4))),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_20)
								]),
							$mdgriffith$elm_ui$Element$text(old)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paddingXY,
									$author$project$UI$scaled(-1),
									0)
								]),
							A2(
								$author$project$UI$materialIcon,
								'arrow_forward',
								$author$project$UI$scaled(3))),
							$mdgriffith$elm_ui$Element$text(_new)
						]))
				]));
	});
var $author$project$Page$Sync1C$iconedShort = F4(
	function (icon, insignificantIconColor, old, _new) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(-2))
				]),
			_Utils_eq(old, _new) ? _List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color(insignificantIconColor)
						]),
					A2(
						$author$project$UI$materialIcon,
						icon,
						$author$project$UI$scaled(4))),
					$mdgriffith$elm_ui$Element$text(old)
				]) : _List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
						]),
					A2(
						$author$project$UI$materialIcon,
						icon,
						$author$project$UI$scaled(4))),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_20)
						]),
					$mdgriffith$elm_ui$Element$text(old)),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					A2(
						$author$project$UI$materialIcon,
						'arrow_forward',
						$author$project$UI$scaled(3))),
					$mdgriffith$elm_ui$Element$text(_new)
				]));
	});
var $author$project$UI$stopClickPropagation = function (onClick) {
	return $mdgriffith$elm_ui$Element$htmlAttribute(
		A2(
			$elm$html$Html$Events$stopPropagationOn,
			'click',
			$elm$json$Json$Decode$succeed(
				_Utils_Tuple2(onClick, true))));
};
var $author$project$UI$wrappedRowWithFloatingRight = F4(
	function (_v0, attrs, paddingAttrs, items) {
		var floating = _v0.a;
		var floatingAttrs = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			function () {
				if (!paddingAttrs.b) {
					return _Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'flex-direction', 'row-reverse')),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2($elm$html$Html$Attributes$style, 'justify-content', 'flex-end'))
							]),
						attrs);
				} else {
					return A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$htmlAttribute(
							$elm$html$Html$Attributes$class('wrapped-row-with-floating')),
						_Utils_ap(attrs, paddingAttrs));
				}
			}(),
			A2(
				$elm$core$List$cons,
				A2(
					$mdgriffith$elm_ui$Element$el,
					A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$alignRight, floatingAttrs),
					floating),
				$elm$core$List$reverse(items)));
	});
var $author$project$Page$Sync1C$buildingDiffCardElements = F4(
	function (iconColor, edit, old, _new) {
		var editControls = A2(
			$elm$core$Maybe$withDefault,
			$mdgriffith$elm_ui$Element$none,
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var collectionId = _v0.a;
					var recordId = _v0.b;
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$author$project$UI$stopClickPropagation($author$project$Page$Sync1C$NoOp)
							]),
						A2(
							$author$project$UI$managerEditLinkCompact,
							collectionId,
							$elm$core$Maybe$Just(recordId)));
				},
				edit));
		return _List_fromArray(
			[
				A4(
				$author$project$UI$wrappedRowWithFloatingRight,
				_Utils_Tuple2(
					editControls,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignTop])),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-1))
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Page$Sync1C$iconedShort,
						'web_stories',
						iconColor,
						$elm$core$String$fromInt(old.domain.numberOfSections),
						$elm$core$String$fromInt(_new.domain.numberOfSections)),
						A4(
						$author$project$Page$Sync1C$iconedShort,
						'elevator',
						iconColor,
						$elm$core$String$fromInt(old.domain.numberOfFloors),
						$elm$core$String$fromInt(_new.domain.numberOfFloors))
					])),
				A4($author$project$Page$Sync1C$iconedLong, 'location_on', iconColor, old.domain.address, _new.domain.address)
			]);
	});
var $mdgriffith$elm_ui$Element$Input$tabindex = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$tabindex);
var $mdgriffith$elm_ui$Element$Input$checkbox = F2(
	function (attrs, _v0) {
		var label = _v0.label;
		var icon = _v0.icon;
		var checked = _v0.checked;
		var onChange = _v0.onChange;
		var attributes = _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(label) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(6),
					$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Events$onClick(
						onChange(!checked))),
					$mdgriffith$elm_ui$Element$Region$announce,
					$mdgriffith$elm_ui$Element$Input$onKeyLookup(
					function (code) {
						return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(
							onChange(!checked)) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(
							onChange(!checked)) : $elm$core$Maybe$Nothing);
					}),
					$mdgriffith$elm_ui$Element$Input$tabindex(0),
					$mdgriffith$elm_ui$Element$pointer,
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			attrs);
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$html$Html$Attributes$attribute, 'role', 'checkbox')),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						A2(
							$elm$html$Html$Attributes$attribute,
							'aria-checked',
							checked ? 'true' : 'false')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(label),
						attributes))),
			label,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
					]),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[
							icon(checked)
						]))));
	});
var $author$project$Page$Sync1C$checkboxIcon = function (state) {
	return function (style) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
				]),
			A2(
				$author$project$UI$materialIcon,
				style,
				$author$project$UI$scaled(4)));
	}(
		function () {
			switch (state.$) {
				case 'SelectedNone':
					return 'check_box_outline_blank';
				case 'SelectedAll':
					return 'check_box';
				default:
					return 'indeterminate_check_box';
			}
		}());
};
var $author$project$Page$Sync1C$flatDiffCardElements = F4(
	function (iconColor, edit, old, _new) {
		var editControls = A2(
			$elm$core$Maybe$withDefault,
			$mdgriffith$elm_ui$Element$none,
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var collectionId = _v0.a;
					var recordId = _v0.b;
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$author$project$UI$stopClickPropagation($author$project$Page$Sync1C$NoOp)
							]),
						A2(
							$author$project$UI$managerEditLinkCompact,
							collectionId,
							$elm$core$Maybe$Just(recordId)));
				},
				edit));
		return _List_fromArray(
			[
				A4(
				$author$project$UI$wrappedRowWithFloatingRight,
				_Utils_Tuple2(
					editControls,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignTop])),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-1))
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Page$Sync1C$iconedShort,
						'web_stories',
						iconColor,
						$elm$core$String$fromInt(old.domain.section),
						$elm$core$String$fromInt(_new.domain.section)),
						A4(
						$author$project$Page$Sync1C$iconedShort,
						'elevator',
						iconColor,
						$elm$core$String$fromInt(old.domain.floor),
						$elm$core$String$fromInt(_new.domain.floor)),
						A4(
						$author$project$Page$Sync1C$iconedShort,
						'door_front',
						iconColor,
						$elm$core$String$fromInt(old.domain.number),
						$elm$core$String$fromInt(_new.domain.number)),
						A4(
						$author$project$Page$Sync1C$iconedShort,
						'numbers',
						iconColor,
						$elm$core$String$fromInt(old.domain.accountNumber),
						$elm$core$String$fromInt(_new.domain.accountNumber))
					])),
				A4(
				$author$project$Page$Sync1C$iconedLong,
				'location_on',
				iconColor,
				$author$project$Domain$flatAddress(old.domain),
				$author$project$Domain$flatAddress(_new.domain))
			]);
	});
var $mdgriffith$elm_ui$Element$Input$OnRight = {$: 'OnRight'};
var $mdgriffith$elm_ui$Element$Input$labelRight = $mdgriffith$elm_ui$Element$Input$Label($mdgriffith$elm_ui$Element$Input$OnRight);
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $author$project$Page$Sync1C$mergeSelection = F2(
	function (selection, category) {
		var _v0 = _Utils_Tuple3(
			$elm$core$Dict$size(selection.selected),
			A2(
				$elm$core$Dict$get,
				_Utils_Tuple2(category, $author$project$Page$Sync1C$diffKind.create),
				selection.currentSelectionsByCategory),
			A2(
				$elm$core$Dict$get,
				_Utils_Tuple2(category, $author$project$Page$Sync1C$diffKind.remove),
				selection.currentSelectionsByCategory));
		if (((((_v0.a === 2) && (_v0.b.$ === 'Just')) && (_v0.b.a === 1)) && (_v0.c.$ === 'Just')) && (_v0.c.a === 1)) {
			var selections = A2(
				$elm$core$List$map,
				function (_v8) {
					var b = _v8.b;
					var c = _v8.c;
					return _Utils_Tuple2(b, c);
				},
				$elm$core$Dict$keys(selection.selected));
			_v1$2:
			while (true) {
				if ((selections.b && selections.b.b) && (!selections.b.b.b)) {
					switch (selections.a.a.valueOf()) {
						case '+':
							if ('-' === selections.b.a.a.valueOf()) {
								var _v2 = selections.a;
								var a = _v2.b;
								var _v3 = selections.b;
								var _v4 = _v3.a;
								var b = _v4.b;
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(a, b));
							} else {
								break _v1$2;
							}
						case '-':
							if ('+' === selections.b.a.a.valueOf()) {
								var _v5 = selections.a;
								var a = _v5.b;
								var _v6 = selections.b;
								var _v7 = _v6.a;
								var b = _v7.b;
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(b, a));
							} else {
								break _v1$2;
							}
						default:
							break _v1$2;
					}
				} else {
					break _v1$2;
				}
			}
			return $elm$core$Maybe$Nothing;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Page$Sync1C$ToggledSelection = F2(
	function (a, b) {
		return {$: 'ToggledSelection', a: a, b: b};
	});
var $author$project$Page$Sync1C$SelectedAll = {$: 'SelectedAll'};
var $author$project$Page$Sync1C$SelectedNone = {$: 'SelectedNone'};
var $author$project$Page$Sync1C$simpleCheckboxIcon = function (value) {
	return value ? $author$project$Page$Sync1C$checkboxIcon($author$project$Page$Sync1C$SelectedAll) : $author$project$Page$Sync1C$checkboxIcon($author$project$Page$Sync1C$SelectedNone);
};
var $author$project$Page$Sync1C$renderDiffEntry = F6(
	function (cardElements, selection, key, edit, old, _new) {
		var iconColor = _Utils_eq(
			$author$project$Domain$PB$detach(old).b,
			_new) ? $author$project$UI$colors.primary : $author$project$UI$colors.neutral_20;
		return A2(
			$mdgriffith$elm_ui$Element$Input$checkbox,
			_List_Nil,
			{
				checked: A2($elm$core$Dict$member, key, selection.selected),
				icon: $author$project$Page$Sync1C$simpleCheckboxIcon,
				label: A2(
					$mdgriffith$elm_ui$Element$Input$labelRight,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$author$project$UI$Card$contentCard,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(
								$author$project$UI$scaled(-1))
							]),
						A4(cardElements, iconColor, edit, old, _new))),
				onChange: $author$project$Page$Sync1C$ToggledSelection(key)
			});
	});
var $author$project$Page$Sync1C$renderError = function (code) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(-1))
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Font$center,
						$author$project$UI$fontsizes.big,
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
					]),
				$elm$core$List$singleton(
					$mdgriffith$elm_ui$Element$text(
						function () {
							switch (code.$) {
								case 'NetworkError':
									return 'Нет соединения с сервером!';
								case 'Timeout':
									return 'Истекло время ожидания!';
								default:
									return 'Что-то пошло не так!';
							}
						}()))),
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Font$center,
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Перезагрузите страницу!')
					]))
			]));
};
var $author$project$Page$Sync1C$SelectedSome = {$: 'SelectedSome'};
var $author$project$Page$Sync1C$selectionState = F3(
	function (category, selection, entries) {
		var counter = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Dict$get, category, selection.currentSelectionsByCategory));
		return (!counter) ? $author$project$Page$Sync1C$SelectedNone : (_Utils_eq(
			counter,
			$elm$core$List$length(entries)) ? $author$project$Page$Sync1C$SelectedAll : $author$project$Page$Sync1C$SelectedSome);
	});
var $elmcraft$core_extra$Tuple$Extra$map = F2(
	function (f, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		return A2(
			$elm$core$Tuple$pair,
			f(a),
			f(b));
	});
var $author$project$Page$Sync1C$tenantDiffCardElements = F4(
	function (iconColor, edit, old, _new) {
		var pairUp = F3(
			function (left, right, acc) {
				pairUp:
				while (true) {
					var _v0 = _Utils_Tuple2(left, right);
					if (!_v0.a.b) {
						if (!_v0.b.b) {
							return acc;
						} else {
							var _v2 = _v0.b;
							var r = _v2.a;
							var rights = _v2.b;
							var $temp$left = _List_Nil,
								$temp$right = rights,
								$temp$acc = _Utils_ap(
								acc,
								_List_fromArray(
									[
										_Utils_Tuple2(
										$elm$core$Maybe$Nothing,
										$elm$core$Maybe$Just(r))
									]));
							left = $temp$left;
							right = $temp$right;
							acc = $temp$acc;
							continue pairUp;
						}
					} else {
						if (!_v0.b.b) {
							var _v1 = _v0.a;
							var l = _v1.a;
							var lefts = _v1.b;
							var $temp$left = lefts,
								$temp$right = _List_Nil,
								$temp$acc = _Utils_ap(
								acc,
								_List_fromArray(
									[
										_Utils_Tuple2(
										$elm$core$Maybe$Just(l),
										$elm$core$Maybe$Nothing)
									]));
							left = $temp$left;
							right = $temp$right;
							acc = $temp$acc;
							continue pairUp;
						} else {
							var _v3 = _v0.a;
							var l = _v3.a;
							var lefts = _v3.b;
							var _v4 = _v0.b;
							var r = _v4.a;
							var rights = _v4.b;
							if (_Utils_eq(l.domain.accountNumber, r.domain.accountNumber)) {
								var $temp$left = lefts,
									$temp$right = rights,
									$temp$acc = _Utils_ap(
									acc,
									_List_fromArray(
										[
											_Utils_Tuple2(
											$elm$core$Maybe$Just(l),
											$elm$core$Maybe$Just(r))
										]));
								left = $temp$left;
								right = $temp$right;
								acc = $temp$acc;
								continue pairUp;
							} else {
								if (_Utils_cmp(l.domain.accountNumber, r.domain.accountNumber) < 0) {
									var $temp$left = lefts,
										$temp$right = A2($elm$core$List$cons, r, rights),
										$temp$acc = _Utils_ap(
										acc,
										_List_fromArray(
											[
												_Utils_Tuple2(
												$elm$core$Maybe$Just(l),
												$elm$core$Maybe$Nothing)
											]));
									left = $temp$left;
									right = $temp$right;
									acc = $temp$acc;
									continue pairUp;
								} else {
									var $temp$left = A2($elm$core$List$cons, l, lefts),
										$temp$right = rights,
										$temp$acc = _Utils_ap(
										acc,
										_List_fromArray(
											[
												_Utils_Tuple2(
												$elm$core$Maybe$Nothing,
												$elm$core$Maybe$Just(r))
											]));
									left = $temp$left;
									right = $temp$right;
									acc = $temp$acc;
									continue pairUp;
								}
							}
						}
					}
				}
			});
		var nullIcon = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_Nil,
			A2(
				$author$project$UI$materialIcon,
				'close',
				$author$project$UI$scaled(2)));
		var editControls = A2(
			$elm$core$Maybe$withDefault,
			$mdgriffith$elm_ui$Element$none,
			A2(
				$elm$core$Maybe$map,
				function (_v11) {
					var collectionId = _v11.a;
					var recordId = _v11.b;
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$author$project$UI$stopClickPropagation($author$project$Page$Sync1C$NoOp)
							]),
						A2(
							$author$project$UI$managerEditLinkCompact,
							collectionId,
							$elm$core$Maybe$Just(recordId)));
				},
				edit));
		var arrowForwardIcon = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_Nil,
			A2(
				$author$project$UI$materialIcon,
				'arrow_forward',
				$author$project$UI$scaled(2)));
		var _v5 = A2(
			$elmcraft$core_extra$Tuple$Extra$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$API$Resource$withDefault(_List_Nil),
				$elm$core$List$sortBy(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.domain;
						},
						function ($) {
							return $.accountNumber;
						}))),
			_Utils_Tuple2(old.domain.flat, _new.domain.flat));
		var oldFlats = _v5.a;
		var newFlats = _v5.b;
		var flatList = A3(pairUp, oldFlats, newFlats, _List_Nil);
		return _Utils_ap(
			_List_fromArray(
				[
					A4(
					$author$project$UI$wrappedRowWithFloatingRight,
					_Utils_Tuple2(
						editControls,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignTop])),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-1))
						]),
					_List_fromArray(
						[
							A4($author$project$Page$Sync1C$iconedLong, 'alternate_email', iconColor, old.domain.email, _new.domain.email)
						])),
					A4($author$project$Page$Sync1C$iconedLong, 'person', iconColor, old.domain.surname + (' ' + (old.domain.name + (' ' + old.domain.patronymic))), _new.domain.surname + (' ' + (_new.domain.name + (' ' + _new.domain.patronymic))))
				]),
			A2(
				$elm$core$List$map,
				function (tuple) {
					if (tuple.a.$ === 'Nothing') {
						if (tuple.b.$ === 'Nothing') {
							var _v7 = tuple.a;
							var _v8 = tuple.b;
							return $mdgriffith$elm_ui$Element$none;
						} else {
							var _v9 = tuple.a;
							var newFlat = tuple.b.a;
							return A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										nullIcon,
										arrowForwardIcon,
										$mdgriffith$elm_ui$Element$text(
										$author$project$Domain$flatAddress(newFlat.domain))
									]));
						}
					} else {
						if (tuple.b.$ === 'Nothing') {
							var oldFlat = tuple.a.a;
							var _v10 = tuple.b;
							return A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(
										$author$project$Domain$flatAddress(oldFlat.domain)),
										arrowForwardIcon,
										nullIcon
									]));
						} else {
							var oldFlat = tuple.a.a;
							var newFlat = tuple.b.a;
							return A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(
										$author$project$Domain$flatAddress(oldFlat.domain)),
										arrowForwardIcon,
										$mdgriffith$elm_ui$Element$text(
										$author$project$Domain$flatAddress(newFlat.domain))
									]));
						}
					}
				},
				A2(
					$elm$core$Basics$always,
					flatList,
					A2(
						$elm$core$Debug$log,
						'old,new,flatList',
						_Utils_Tuple3(old, _new, flatList)))));
	});
var $author$project$Page$Sync1C$renderDiffs = F3(
	function (diffs, selection, stage) {
		var syncButton = $author$project$UI$Button$button(
			A4(
				$author$project$UI$Button$ButtonSpecs,
				$elm$core$Maybe$Just($author$project$Page$Sync1C$ProceedToNextPhase),
				$mdgriffith$elm_ui$Element$text('Далее'),
				$author$project$UI$Button$Filled,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX])));
		var stageTitle = function (title) {
			return A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$center, $author$project$UI$fontsizes.bigger, $mdgriffith$elm_ui$Element$Font$bold]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(title)
					]));
		};
		var sectionTitle = F4(
			function (text, category, open, entries) {
				var selState = A3($author$project$Page$Sync1C$selectionState, category, selection, entries);
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$Input$checkbox,
							_List_Nil,
							{
								checked: function () {
									switch (selState.$) {
										case 'SelectedNone':
											return false;
										case 'SelectedAll':
											return true;
										default:
											return false;
									}
								}(),
								icon: function (_v10) {
									return $author$project$Page$Sync1C$checkboxIcon(selState);
								},
								label: A2(
									$mdgriffith$elm_ui$Element$Input$labelRight,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$centerX,
												$mdgriffith$elm_ui$Element$Font$center,
												$author$project$UI$fontsizes.bigger,
												$mdgriffith$elm_ui$Element$Font$bold,
												$mdgriffith$elm_ui$Element$padding(
												$author$project$UI$scaled(-2))
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(text)
											]))),
								onChange: $author$project$Page$Sync1C$ToggledCategorySelection(category)
							}),
							$author$project$UI$Button$button(
							A4(
								$author$project$UI$Button$ButtonSpecs,
								$elm$core$Maybe$Just(
									A2($author$project$Page$Sync1C$SetDisplay, category, !open)),
								A2(
									$author$project$UI$materialIcon,
									open ? 'arrow_drop_down' : 'arrow_left',
									$author$project$UI$scaled(5)),
								$author$project$UI$Button$Borderless,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$padding(0),
										$mdgriffith$elm_ui$Element$Border$width(0)
									])))
						]));
			});
		var section = F3(
			function (title, category, entries) {
				var visible = A2(
					$elm$core$Maybe$withDefault,
					false,
					A2($elm$core$Dict$get, category, selection.displayed));
				if (!entries.b) {
					return $author$project$UI$emptyContentHint;
				} else {
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(
								$author$project$UI$scaled(-1))
							]),
						A2(
							$elm$core$List$cons,
							A4(sectionTitle, title, category, visible, entries),
							visible ? entries : _List_Nil));
				}
			});
		var renderErrors = function (errors) {
			if (!errors.b) {
				return $mdgriffith$elm_ui$Element$none;
			} else {
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-1))
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$Font$center,
									$author$project$UI$fontsizes.bigger,
									$mdgriffith$elm_ui$Element$Font$bold,
									$mdgriffith$elm_ui$Element$padding(
									$author$project$UI$scaled(-2))
								]),
							$mdgriffith$elm_ui$Element$text('Ошибки')),
						A2(
							$elm$core$List$map,
							function (err) {
								return A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(err)
										]));
							},
							errors)));
			}
		};
		var prepareDiffElements = F2(
			function (subdiff, processor) {
				switch (subdiff.$) {
					case 'NotStarted':
						return _List_Nil;
					case 'Loaded':
						var elements = subdiff.a;
						return $elm$core$Dict$values(
							A2($elm$core$Dict$map, processor, elements));
					case 'Loading':
						return _List_fromArray(
							[$author$project$UI$Loading$spinner]);
					default:
						return _Debug_todo(
							'Page.Sync1C',
							{
								start: {line: 1146, column: 21},
								end: {line: 1146, column: 31}
							})('branch \'Failed _\' not implemented');
				}
			});
		var mergeButton = function (dcName) {
			return $author$project$UI$Button$button(
				A4(
					$author$project$UI$Button$ButtonSpecs,
					A2(
						$elm$core$Maybe$map,
						$elmcraft$core_extra$Tuple$Extra$apply($author$project$Page$Sync1C$PressedMergeButton),
						A2($author$project$Page$Sync1C$mergeSelection, selection, dcName)),
					$mdgriffith$elm_ui$Element$text('Объединить'),
					$author$project$UI$Button$Filled,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX])));
		};
		var collectionSectionsEdits = F4(
			function (name, collection, diffStatus, entryRenderer) {
				switch (diffStatus.$) {
					case 'Loaded':
						var diff = diffStatus.a;
						return _List_fromArray(
							[
								renderErrors(diff.errors),
								A3(
								section,
								'Изменённые ' + name,
								_Utils_Tuple2(collection, $author$project$Page$Sync1C$diffKind.edit),
								A2(
									prepareDiffElements,
									diff.edits,
									F2(
										function (i, _v5) {
											var id = _v5.id;
											var old = _v5.old;
											var _new = _v5._new;
											return A5(
												entryRenderer,
												selection,
												_Utils_Tuple3(collection, $author$project$Page$Sync1C$diffKind.edit, i),
												$elm$core$Maybe$Just(
													_Utils_Tuple2(diff.collectionID, id)),
												old,
												_new);
										})))
							]);
					case 'Loading':
						return _List_fromArray(
							[$author$project$UI$Loading$spinner]);
					case 'NotStarted':
						return _List_fromArray(
							[
								$author$project$Page$Sync1C$renderError(
								$author$project$API$Error$Bug(''))
							]);
					default:
						var code = diffStatus.a.code;
						return _List_fromArray(
							[
								$author$project$Page$Sync1C$renderError(code)
							]);
				}
			});
		var collectionSectionsCreationsAndRemovals = F4(
			function (name, collection, diffStatus, entryRenderer) {
				switch (diffStatus.$) {
					case 'Loaded':
						var diff = diffStatus.a;
						return _List_fromArray(
							[
								renderErrors(diff.errors),
								A3(
								section,
								'Новые ' + name,
								_Utils_Tuple2(collection, $author$project$Page$Sync1C$diffKind.create),
								A2(
									prepareDiffElements,
									diff.creations,
									F2(
										function (i, _v2) {
											var _new = _v2._new;
											return A5(
												entryRenderer,
												selection,
												_Utils_Tuple3(collection, $author$project$Page$Sync1C$diffKind.create, i),
												$elm$core$Maybe$Nothing,
												{
													domain: _new.domain,
													meta: {
														collectionId: _new.meta.collectionId,
														collectionName: _new.meta.collectionName,
														created: $elm$time$Time$millisToPosix(0),
														id: '',
														updated: $elm$time$Time$millisToPosix(0)
													}
												},
												_new);
										}))),
								A3(
								section,
								'Лишние ' + name,
								_Utils_Tuple2(collection, $author$project$Page$Sync1C$diffKind.remove),
								A2(
									prepareDiffElements,
									diff.removals,
									F2(
										function (i, _v3) {
											var id = _v3.id;
											var old = _v3.old;
											return A5(
												entryRenderer,
												selection,
												_Utils_Tuple3(collection, $author$project$Page$Sync1C$diffKind.remove, i),
												$elm$core$Maybe$Just(
													_Utils_Tuple2(diff.collectionID, id)),
												old,
												$author$project$Domain$PB$detach(old).b);
										})))
							]);
					case 'Loading':
						return _List_fromArray(
							[$author$project$UI$Loading$spinner]);
					case 'NotStarted':
						return _List_fromArray(
							[
								$author$project$Page$Sync1C$renderError(
								$author$project$API$Error$Bug(''))
							]);
					default:
						var code = diffStatus.a.code;
						return _List_fromArray(
							[
								$author$project$Page$Sync1C$renderError(code)
							]);
				}
			});
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$padding(
					$author$project$UI$scaled(-2)),
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(-1))
				]),
			$elm$core$List$concat(
				function () {
					switch (stage.$) {
						case 'SyncBuildingsCreationsAndRemovals':
							return _List_fromArray(
								[
									_List_fromArray(
									[
										stageTitle('Дома')
									]),
									A4(
									collectionSectionsCreationsAndRemovals,
									'дома',
									$author$project$Page$Sync1C$diffCollection.building,
									diffs.buildings,
									$author$project$Page$Sync1C$renderDiffEntry($author$project$Page$Sync1C$buildingDiffCardElements)),
									_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$wrappedRow,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$spacing(
												$author$project$UI$scaled(-1))
											]),
										_List_fromArray(
											[
												mergeButton($author$project$Page$Sync1C$diffCollection.building),
												syncButton
											]))
									])
								]);
						case 'SyncBuildingsEdits':
							return _List_fromArray(
								[
									_List_fromArray(
									[
										stageTitle('Дома')
									]),
									A4(
									collectionSectionsEdits,
									'дома',
									$author$project$Page$Sync1C$diffCollection.building,
									diffs.buildings,
									$author$project$Page$Sync1C$renderDiffEntry($author$project$Page$Sync1C$buildingDiffCardElements)),
									_List_fromArray(
									[syncButton])
								]);
						case 'SyncFlatsCreationsAndRemovals':
							return _List_fromArray(
								[
									_List_fromArray(
									[
										stageTitle('Квартиры')
									]),
									A4(
									collectionSectionsCreationsAndRemovals,
									'квартиры',
									$author$project$Page$Sync1C$diffCollection.flat,
									diffs.flats,
									$author$project$Page$Sync1C$renderDiffEntry($author$project$Page$Sync1C$flatDiffCardElements)),
									_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$wrappedRow,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$spacing(
												$author$project$UI$scaled(-1))
											]),
										_List_fromArray(
											[
												mergeButton($author$project$Page$Sync1C$diffCollection.flat),
												syncButton
											]))
									])
								]);
						case 'SyncFlatsEdits':
							return _List_fromArray(
								[
									_List_fromArray(
									[
										stageTitle('Квартиры')
									]),
									A4(
									collectionSectionsEdits,
									'квартиры',
									$author$project$Page$Sync1C$diffCollection.flat,
									diffs.flats,
									$author$project$Page$Sync1C$renderDiffEntry($author$project$Page$Sync1C$flatDiffCardElements)),
									_List_fromArray(
									[syncButton])
								]);
						case 'SyncTenantsCreationsAndRemovals':
							return _List_fromArray(
								[
									_List_fromArray(
									[
										stageTitle('Жильцы')
									]),
									A4(
									collectionSectionsCreationsAndRemovals,
									'жильцы',
									$author$project$Page$Sync1C$diffCollection.tenant,
									diffs.tenants,
									$author$project$Page$Sync1C$renderDiffEntry($author$project$Page$Sync1C$tenantDiffCardElements)),
									_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$wrappedRow,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$spacing(
												$author$project$UI$scaled(-1))
											]),
										_List_fromArray(
											[
												mergeButton($author$project$Page$Sync1C$diffCollection.tenant),
												syncButton
											]))
									])
								]);
						case 'SyncTenantsEdits':
							return _List_fromArray(
								[
									_List_fromArray(
									[
										stageTitle('Жильцы')
									]),
									A4(
									collectionSectionsEdits,
									'жильцы',
									$author$project$Page$Sync1C$diffCollection.tenant,
									diffs.tenants,
									$author$project$Page$Sync1C$renderDiffEntry($author$project$Page$Sync1C$tenantDiffCardElements)),
									_List_fromArray(
									[syncButton])
								]);
						default:
							return _List_fromArray(
								[
									$elm$core$List$singleton(
									A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$Font$center]),
										$elm$core$List$singleton(
											$mdgriffith$elm_ui$Element$text('Синхронизация успешно завершена!'))))
								]);
					}
				}()));
	});
var $author$project$Page$Sync1C$SelectedDump = function (a) {
	return {$: 'SelectedDump', a: a};
};
var $author$project$Page$Sync1C$renderListOfDumps = function (dumps) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(-1)),
				$mdgriffith$elm_ui$Element$padding(
				$author$project$UI$scaled(-2))
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$center, $mdgriffith$elm_ui$Element$Font$bold, $author$project$UI$fontsizes.bigger]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Данные')
					])),
			A2(
				$elm$core$List$map,
				function (dump) {
					var item = F2(
						function (icon, number) {
							return A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(
										$author$project$UI$scaled(-1))
									]),
								_List_fromArray(
									[
										A2(
										$author$project$UI$materialIcon,
										icon,
										$author$project$UI$scaled(4)),
										$mdgriffith$elm_ui$Element$text(
										$elm$core$String$fromInt(number))
									]));
						});
					var card = A2(
						$author$project$UI$Card$contentCard,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(
								$author$project$UI$scaled(-4))
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$Font$regular
									]),
								_List_fromArray(
									[
										function () {
										var _v0 = $author$project$API$Resource$toMaybe(dump.domain.author);
										if (_v0.$ === 'Just') {
											var author = _v0.a;
											return A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$spacing(
														$author$project$UI$scaled(-4))
													]),
												A2(
													$elm$core$List$map,
													function (x) {
														return A2(
															$mdgriffith$elm_ui$Element$paragraph,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_100)
																]),
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$text(x)
																]));
													},
													_List_fromArray(
														[author.domain.name, author.domain.role])));
										} else {
											return $mdgriffith$elm_ui$Element$none;
										}
									}(),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$alignTop, $mdgriffith$elm_ui$Element$alignRight]),
										$author$project$UI$Card$dateLabel(dump.meta.created))
									])),
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$spacing(
										$author$project$UI$scaled(-1))
									]),
								_List_fromArray(
									[
										A2(item, 'apartment', dump.domain.buildings),
										A2(item, 'door_front', dump.domain.flats),
										A2(item, 'person', dump.domain.tenants)
									]))
							]));
					return $author$project$UI$Button$button(
						A4(
							$author$project$UI$Button$ButtonSpecs,
							$elm$core$Maybe$Just(
								$author$project$Page$Sync1C$SelectedDump(dump)),
							card,
							$author$project$UI$Button$Borderless,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$padding(0)
								])));
				},
				dumps)));
};
var $author$project$Page$Sync1C$view = function (model) {
	var _v0 = _Utils_Tuple2(model.state, model.dumps);
	_v0$5:
	while (true) {
		if (_v0.a.$ === 'ListOfDumps') {
			switch (_v0.b.$) {
				case 'Loading':
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return $author$project$UI$Loading$spinner;
				case 'Failed':
					var _v3 = _v0.a;
					var code = _v0.b.a.code;
					return $author$project$Page$Sync1C$renderError(code);
				case 'NotStarted':
					var _v4 = _v0.a;
					var _v5 = _v0.b;
					return $author$project$Page$Sync1C$renderError(
						$author$project$API$Error$Bug(''));
				default:
					var _v6 = _v0.a;
					var dumps = _v0.b.a;
					return $author$project$Page$Sync1C$renderListOfDumps(dumps);
			}
		} else {
			switch (_v0.a.d.$) {
				case 'Loading':
					var _v7 = _v0.a;
					var _v8 = _v7.d;
					return $author$project$UI$Loading$spinner;
				case 'Loaded':
					if (_v0.a.b.$ === 'Done') {
						break _v0$5;
					} else {
						var _v11 = _v0.a;
						var dump = _v11.a;
						var stage = _v11.b;
						var selection = _v11.c;
						return A3($author$project$Page$Sync1C$renderDiffs, dump.domain.diffs, selection, stage);
					}
				default:
					if (_v0.a.b.$ === 'Done') {
						break _v0$5;
					} else {
						var _v12 = _v0.a;
						var dump = _v12.a;
						var stage = _v12.b;
						var selection = _v12.c;
						var ws = _v12.d;
						return $author$project$Page$Sync1C$renderError(
							$author$project$API$Error$Bug(''));
					}
			}
		}
	}
	var _v9 = _v0.a;
	var dump = _v9.a;
	var _v10 = _v9.b;
	var selection = _v9.c;
	return A3($author$project$Page$Sync1C$renderDiffs, dump.domain.diffs, selection, $author$project$Page$Sync1C$Done);
};
var $author$project$Page$Sync1C$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Protected($author$project$Framework$managerOnly),
	{
		baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
		init: $author$project$Page$Sync1C$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: function (_v0) {
			return 'Синхронизация с 1С';
		},
		update: $author$project$Framework$EffectUpdate($author$project$Page$Sync1C$update),
		view: $author$project$Framework$PlainView($author$project$Page$Sync1C$view),
		wrapArgs: F2(
			function (args, _v1) {
				return args;
			})
	});
var $author$project$Page$Tenant$UpdatedBaseModel = function (a) {
	return {$: 'UpdatedBaseModel', a: a};
};
var $author$project$Framework$andFilter = F3(
	function (before, after, args) {
		var _v0 = before(args);
		if (_v0.$ === 'AccessGranted') {
			return after(args);
		} else {
			var other = _v0;
			return other;
		}
	});
var $author$project$Page$Tenant$BillList = {$: 'BillList'};
var $author$project$Page$Tenant$GotExpectedRommates = function (a) {
	return {$: 'GotExpectedRommates', a: a};
};
var $author$project$Page$Tenant$GotUserData = function (a) {
	return {$: 'GotUserData', a: a};
};
var $author$project$Collections$getExpectedRoommates = function (authHeader) {
	return A4(
		$author$project$API$getAll,
		'expected_tenant',
		A2(
			$author$project$API$Get,
			$author$project$Domain$PB$decoderWithID($author$project$Domain$expectedTenantDecoder),
			_List_Nil),
		_List_fromArray(
			[authHeader]),
		_List_Nil);
};
var $author$project$Page$Tenant$init = function (args) {
	var _default = {
		activeFlat: $elm$core$Maybe$Nothing,
		billFilter: {cancelled: false, created: true, paid: true, rejected: true, unconfirmed: true},
		device: args.device,
		editingExpectedTenantEmail: $elm$core$Maybe$Nothing,
		expectedRoommates: $author$project$API$Status$NotStarted,
		serviceBill: $author$project$API$Status$NotStarted,
		session: args.session,
		state: $author$project$Page$Tenant$BillList,
		user: A2($author$project$API$Resource$Resource, args.userId, $author$project$API$Status$Loading),
		utilityBills: $author$project$API$Status$NotStarted
	};
	var _v0 = args.session;
	if (_v0.b.$ === 'Authenticated') {
		if (_v0.b.a.b.$ === 'TenantLocalStorage') {
			var _v1 = _v0.b.a;
			var authToken = _v1.b.a.authToken;
			var user = _v1.b.a.user;
			var active = A2(
				$elm$core$Maybe$andThen,
				$elm$core$List$head,
				$author$project$API$Resource$toMaybe(user.domain.flat));
			return _Utils_Tuple2(
				_Utils_update(
					_default,
					{
						activeFlat: A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.meta;
								},
								function ($) {
									return $.id;
								}),
							active),
						expectedRoommates: $author$project$API$Status$Loading,
						user: A2(
							$author$project$API$Resource$Resource,
							args.userId,
							$author$project$API$Status$Loaded(user)),
						utilityBills: A2(
							$elm$core$Maybe$withDefault,
							$author$project$API$Status$NotStarted,
							A2(
								$elm$core$Maybe$map,
								$author$project$API$Status$Loaded,
								A2(
									$elm$core$Maybe$andThen,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.domain;
										},
										function ($) {
											return $.utilityBills;
										}),
									active)))
					}),
				A2(
					$elm$core$Task$attempt,
					$author$project$Page$Tenant$GotExpectedRommates,
					$author$project$Collections$getExpectedRoommates(
						$author$project$Session$authorizationHeader(authToken))));
		} else {
			var _v2 = _v0.b.a;
			var authToken = _v2.b.a.authToken;
			return _Utils_Tuple2(
				_default,
				A2(
					$elm$core$Task$attempt,
					$author$project$Page$Tenant$GotUserData,
					A3(
						$author$project$Collections$getUser,
						_List_fromArray(
							[
								$author$project$Session$authorizationHeader(authToken)
							]),
						_List_Nil,
						args.userId)));
		}
	} else {
		return _Utils_Tuple2(
			_Utils_update(
				_default,
				{
					user: A2($author$project$API$Resource$Resource, args.userId, $author$project$API$Status$NotStarted)
				}),
			$elm$core$Platform$Cmd$none);
	}
};
var $author$project$Page$Tenant$ownerOnly = function (args) {
	var _v0 = $author$project$Session$getViewer(args.session);
	if ((_v0.$ === 'Authenticated') && (_v0.a.b.$ === 'TenantLocalStorage')) {
		var _v1 = _v0.a;
		var user = _v1.b.a.user;
		return _Utils_eq(user.meta.id, args.userId) ? $author$project$Framework$AccessGranted : $author$project$Framework$AccessDenied;
	} else {
		return $author$project$Framework$AccessGranted;
	}
};
var $author$project$Page$Tenant$title = function (model) {
	var _v0 = model.session;
	if (_v0.b.$ === 'Authenticated') {
		return 'Профиль';
	} else {
		return 'Профиль пользователя';
	}
};
var $author$project$Page$Tenant$AwaitingPaymentConfirmation = function (a) {
	return {$: 'AwaitingPaymentConfirmation', a: a};
};
var $author$project$Page$Tenant$GotCreatePaymentResponse = function (a) {
	return {$: 'GotCreatePaymentResponse', a: a};
};
var $author$project$Page$Tenant$GotRequestPasswordResetResponse = function (a) {
	return {$: 'GotRequestPasswordResetResponse', a: a};
};
var $author$project$Page$Tenant$GotUpdateExpectedTenantResponse = function (a) {
	return {$: 'GotUpdateExpectedTenantResponse', a: a};
};
var $author$project$Page$Tenant$Payment = function (a) {
	return {$: 'Payment', a: a};
};
var $author$project$Collections$postUtilityBillPayment = function (authHeader) {
	return A4(
		$author$project$API$post,
		'utility_bill',
		A3(
			$author$project$API$Post,
			function (x) {
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'utility_bill',
							$elm$json$Json$Encode$string(x))
						]));
			},
			$elm$json$Json$Decode$succeed(_Utils_Tuple0),
			_List_Nil),
		_List_fromArray(
			[authHeader]),
		_List_Nil);
};
var $author$project$Domain$PB$encodeWithID = F2(
	function (domainToFields, _v0) {
		var meta = _v0.meta;
		var domain = _v0.domain;
		return $elm$json$Json$Encode$object(
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'id',
						$elm$json$Json$Encode$string(meta.id)),
						_Utils_Tuple2(
						'collectionId',
						$elm$json$Json$Encode$string(meta.collectionId)),
						_Utils_Tuple2(
						'collectionName',
						$elm$json$Json$Encode$string(meta.collectionName)),
						_Utils_Tuple2(
						'created',
						$author$project$Utils$encodeTimestamp(meta.created)),
						_Utils_Tuple2(
						'updated',
						$author$project$Utils$encodeTimestamp(meta.updated))
					]),
				domainToFields(domain)));
	});
var $author$project$Domain$expectedTenantEncoder = function (expectedTenant) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'name',
			$elm$json$Json$Encode$string(expectedTenant.name)),
			_Utils_Tuple2(
			'surname',
			$elm$json$Json$Encode$string(expectedTenant.surname)),
			_Utils_Tuple2(
			'patronymic',
			$elm$json$Json$Encode$string(expectedTenant.patronymic)),
			_Utils_Tuple2(
			'email',
			$elm$json$Json$Encode$string(expectedTenant.email)),
			_Utils_Tuple2(
			'flat',
			A2(
				$elm$json$Json$Encode$list,
				$elm$json$Json$Encode$string,
				$author$project$API$Resource$toMeta(expectedTenant.flat)))
		]);
};
var $author$project$Collections$updateExpectedRoommate = F2(
	function (authHeader, record) {
		return A6(
			$author$project$API$patch,
			'expected_tenant',
			A3(
				$author$project$API$Patch,
				$author$project$Domain$PB$encodeWithID($author$project$Domain$expectedTenantEncoder),
				$author$project$Domain$PB$decoderWithID($author$project$Domain$expectedTenantDecoder),
				_List_Nil),
			_List_fromArray(
				[authHeader]),
			_List_Nil,
			record.meta.id,
			record);
	});
var $author$project$Page$Tenant$update = F2(
	function (msg, model) {
		var authHeader = $author$project$Session$authorizationHeader(
			A2(
				$elm$core$Maybe$withDefault,
				'',
				$author$project$Session$getToken(model.session)));
		switch (msg.$) {
			case 'GotUserData':
				if (msg.a.$ === 'Ok') {
					var user = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								activeFlat: function () {
									var _v1 = $author$project$API$Resource$toMaybe(user.domain.flat);
									if ((_v1.$ === 'Just') && _v1.a.b) {
										var _v2 = _v1.a;
										var head = _v2.a;
										return $elm$core$Maybe$Just(head.meta.id);
									} else {
										return $elm$core$Maybe$Nothing;
									}
								}(),
								user: A2($author$project$API$Resource$loaded, user, model.user)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					return _Debug_todo(
						'Page.Tenant',
						{
							start: {line: 214, column: 13},
							end: {line: 214, column: 23}
						})('branch \'GotUserData (Err _)\' not implemented');
				}
			case 'SwitchedActiveFlat':
				var flatId = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							activeFlat: $elm$core$Maybe$Just(flatId)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'UpdatedFilter':
				var filter = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{billFilter: filter}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'LoggedOut':
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$ClearLocalStorage);
			case 'RequestedPayment':
				var bill = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							state: $author$project$Page$Tenant$Payment(bill)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'DismissedPayment':
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{state: $author$project$Page$Tenant$BillList}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'UserConfirmedPayment':
				var bill = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							state: $author$project$Page$Tenant$AwaitingPaymentConfirmation(bill)
						}),
					A2(
						$elm$core$Task$attempt,
						$author$project$Page$Tenant$GotCreatePaymentResponse,
						A2($author$project$Collections$postUtilityBillPayment, authHeader, bill.meta.id)),
					$author$project$Framework$NoOp);
			case 'GotCreatePaymentResponse':
				var result = msg.a;
				if (result.$ === 'Err') {
					var _v4 = model.state;
					if (_v4.$ === 'AwaitingPaymentConfirmation') {
						var bill = _v4.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									state: $author$project$Page$Tenant$Payment(bill)
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Framework$NoOp);
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
					}
				} else {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{state: $author$project$Page$Tenant$BillList}),
						$elm$browser$Browser$Navigation$reload,
						$author$project$Framework$NoOp);
				}
			case 'UpdatedBaseModel':
				var baseModel = msg.a;
				var session = baseModel.session;
				var newModel = A2($author$project$Framework$updateBase, model, baseModel);
				var _v5 = $author$project$Session$getViewer(session);
				if ((_v5.$ === 'Authenticated') && (_v5.a.b.$ === 'TenantLocalStorage')) {
					var _v6 = _v5.a;
					var authToken = _v6.b.a.authToken;
					var user = _v6.b.a.user;
					return A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							function (x) {
								return _Utils_eq(x.meta.id, user.meta.id);
							},
							$author$project$API$Resource$toMaybe(newModel.user))) ? _Utils_Tuple3(
						_Utils_update(
							newModel,
							{
								user: A2($author$project$API$Resource$loaded, user, newModel.user)
							}),
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Tenant$GotExpectedRommates,
							$author$project$Collections$getExpectedRoommates(
								$author$project$Session$authorizationHeader(authToken))),
						$author$project$Framework$NoOp) : _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, $author$project$Framework$NoOp);
				} else {
					return _Utils_Tuple3(newModel, $elm$browser$Browser$Navigation$reload, $author$project$Framework$NoOp);
				}
			case 'GotExpectedRommates':
				if (msg.a.$ === 'Ok') {
					var expectedRoommates = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								expectedRoommates: $author$project$API$Status$Loaded(expectedRoommates)
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Framework$NoOp);
				} else {
					var errorCode = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{expectedRoommates: $author$project$API$Status$NotStarted}),
						$elm$core$Platform$Cmd$none,
						A2(
							$author$project$Framework$ShowPopUp,
							A2($author$project$PopUp$PlainText, 'Ошибка!', 'Не удалось получить данные о незарегистрированных жильцах в Ваших квартирах. ' + 'Попробуйте перезагрузить страницу!'),
							$author$project$PopUp$Important));
				}
			case 'ExpectedTenantEmailFieldChanged':
				var id = msg.a;
				var specs = msg.b.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							editingExpectedTenantEmail: $elm$core$Maybe$Just(
								_Utils_Tuple2(id, specs))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'SaveExpectedTenantEmail':
				var id = msg.a;
				var specs = msg.b;
				var newValue = function () {
					var _v8 = _Utils_Tuple2(
						model.expectedRoommates,
						$author$project$UI$Form$result(specs));
					if ((_v8.a.$ === 'Loaded') && (_v8.b.$ === 'WithResult')) {
						var roommates = _v8.a.a;
						var email = _v8.b.a;
						return A2(
							$elm$core$Maybe$map,
							function (current) {
								var domain = current.domain;
								return _Utils_update(
									current,
									{
										domain: _Utils_update(
											domain,
											{email: email})
									});
							},
							A2(
								$elmcraft$core_extra$List$Extra$find,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.meta;
									},
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.id;
										},
										$elm$core$Basics$eq(id))),
								roommates));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				if (newValue.$ === 'Just') {
					var expectedTenant = newValue.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								editingExpectedTenantEmail: $elm$core$Maybe$Just(
									_Utils_Tuple2(id, specs))
							}),
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Tenant$GotUpdateExpectedTenantResponse,
							A2($author$project$Collections$updateExpectedRoommate, authHeader, expectedTenant)),
						$author$project$Framework$NoOp);
				} else {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						A2(
							$author$project$Framework$ShowPopUp,
							A2($author$project$PopUp$PlainText, 'Ошибка!', 'Не удалось изменить email незарегестрированного жильца. ' + 'Попробуйте обновить страницу!'),
							$author$project$PopUp$Important));
				}
			case 'StopEditingExpectedTenantEmail':
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{editingExpectedTenantEmail: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					$author$project$Framework$NoOp);
			case 'GotUpdateExpectedTenantResponse':
				if (msg.a.$ === 'Ok') {
					var newExpectedTenant = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								editingExpectedTenantEmail: A2(
									$elm$core$Maybe$withDefault,
									false,
									A2(
										$elm$core$Maybe$map,
										A2(
											$elm$core$Basics$composeR,
											$elm$core$Tuple$first,
											$elm$core$Basics$eq(newExpectedTenant.meta.id)),
										model.editingExpectedTenantEmail)) ? $elm$core$Maybe$Nothing : model.editingExpectedTenantEmail,
								expectedRoommates: A2(
									$author$project$API$Status$map,
									A2(
										$elmcraft$core_extra$List$Extra$updateIf,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.meta;
											},
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.id;
												},
												$elm$core$Basics$eq(newExpectedTenant.meta.id))),
										function (_v9) {
											return newExpectedTenant;
										}),
									model.expectedRoommates)
							}),
						$elm$core$Platform$Cmd$none,
						A2(
							$author$project$Framework$ShowPopUp,
							A2($author$project$PopUp$PlainText, 'Email для пререгистрации изменён!', 'Теперь жилец сможет зарегистрироваться с указанным адресом электронной почты.'),
							$author$project$PopUp$Unimportant));
				} else {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						A2(
							$author$project$Framework$ShowPopUp,
							A2($author$project$PopUp$PlainText, 'Ошибка!', 'Проверьте введённый адрес электронной почты и соединение с сетью.'),
							$author$project$PopUp$Important));
				}
			case 'RequestedPasswordReset':
				var email = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Task$attempt,
						$author$project$Page$Tenant$GotRequestPasswordResetResponse,
						$author$project$Collections$requestPasswordReset(email)),
					$author$project$Framework$NoOp);
			default:
				if (msg.a.$ === 'Err') {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						A2(
							$author$project$Framework$ShowPopUp,
							A2($author$project$PopUp$PlainText, 'Ошибка!', 'Не удалось сбросить пароль'),
							$author$project$PopUp$Important));
				} else {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						A2(
							$author$project$Framework$ShowPopUp,
							A2($author$project$PopUp$PlainText, 'Проверьте почту!', 'В течение нескольких минут Вам должно прийти письмо для сброса пароля'),
							$author$project$PopUp$Important));
				}
		}
	});
var $author$project$Page$Tenant$LoggedOut = {$: 'LoggedOut'};
var $author$project$Page$Tenant$RequestedPasswordReset = function (a) {
	return {$: 'RequestedPasswordReset', a: a};
};
var $author$project$Page$Tenant$ExpectedTenantEmailFieldChanged = F2(
	function (a, b) {
		return {$: 'ExpectedTenantEmailFieldChanged', a: a, b: b};
	});
var $author$project$Page$Tenant$SaveExpectedTenantEmail = F2(
	function (a, b) {
		return {$: 'SaveExpectedTenantEmail', a: a, b: b};
	});
var $author$project$Page$Tenant$StopEditingExpectedTenantEmail = {$: 'StopEditingExpectedTenantEmail'};
var $author$project$UI$Form$borderlessForm = F2(
	function (extraAttrs, _v0) {
		var body = _v0.body;
		var under = _v0.under;
		var defaultAction = _v0.defaultAction;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
					]),
				extraAttrs),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$centerY,
								$mdgriffith$elm_ui$Element$spacing(
								$author$project$UI$scaled(2)),
								$mdgriffith$elm_ui$Element$width(
								A2(
									$mdgriffith$elm_ui$Element$maximum,
									800,
									A2($mdgriffith$elm_ui$Element$minimum, 300, $mdgriffith$elm_ui$Element$fill)))
							]),
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								A2($elm$core$Basics$composeR, $author$project$Utils$onEnter, $elm$core$List$singleton),
								defaultAction))),
					body),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					under)
				]));
	});
var $author$project$Page$Tenant$cardElement = function (_v0) {
	var icon = _v0.a;
	var tooltip = _v0.b;
	var text = _v0.c;
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(-4)),
				$author$project$UI$preventSelect,
				$author$project$UI$expandParagraphs
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				A3(
					$author$project$UI$withTooltip,
					A2($author$project$UI$TooltipPosition, $author$project$UI$TooltipAbove, $author$project$UI$TooltipCenter),
					tooltip,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
						])),
				A2(
					$author$project$UI$materialIcon,
					icon,
					$author$project$UI$scaled(4))),
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(text)
					]))
			]));
};
var $author$project$Page$Tenant$defaultExpectedTenantEmailField = function (id) {
	var _default = $author$project$UI$Form$Text$default(
		$author$project$Page$Tenant$ExpectedTenantEmailFieldChanged(id));
	return _Utils_update(
		_default,
		{
			check: A2($author$project$UI$Form$simpleCheck, $author$project$UI$Form$Text$validateEmail, 'Неверный формат email!'),
			hint: $elm$core$Maybe$Just('example@gmail.com'),
			kind: $author$project$UI$Form$Text$Regular($mdgriffith$elm_ui$Element$Input$email),
			name: 'Email',
			required: $author$project$UI$Form$Required,
			showLabel: false
		});
};
var $author$project$Page$Tenant$expectedRoommateCard = F2(
	function (model, _v0) {
		var meta = _v0.meta;
		var domain = _v0.domain;
		var makeIcon = F2(
			function (icon, tooltip) {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					A3(
						$author$project$UI$withTooltip,
						A2($author$project$UI$TooltipPosition, $author$project$UI$TooltipAbove, $author$project$UI$TooltipCenter),
						tooltip,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
							])),
					A2(
						$author$project$UI$materialIcon,
						icon,
						$author$project$UI$scaled(4)));
			});
		var fieldSpecs = function () {
			var _v2 = model.editingExpectedTenantEmail;
			if (_v2.$ === 'Just') {
				var _v3 = _v2.a;
				var id = _v3.a;
				var specs = _v3.b;
				return A2(
					$author$project$Utils$justIf,
					_Utils_eq(id, meta.id),
					specs);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var defaultField = $author$project$Page$Tenant$defaultExpectedTenantEmailField(meta.id);
		return A2(
			$author$project$UI$Card$contentCard,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					$author$project$Page$Tenant$cardElement(
					_Utils_Tuple3(
						'person',
						'ФИО',
						A2(
							$elm$core$String$join,
							' ',
							_List_fromArray(
								[domain.surname, domain.name, domain.patronymic])))),
					function () {
					if (fieldSpecs.$ === 'Just') {
						var specs = fieldSpecs.a;
						return A2(
							$author$project$UI$Form$borderlessForm,
							_List_Nil,
							A4(
								$author$project$UI$Form$withRow,
								true,
								_List_Nil,
								A4(
									$author$project$UI$Form$withButton,
									false,
									$elm$core$Maybe$Nothing,
									A4(
										$author$project$UI$Button$ButtonSpecs,
										$elm$core$Maybe$Just($author$project$Page$Tenant$StopEditingExpectedTenantEmail),
										A2(makeIcon, 'close', 'Отменить'),
										$author$project$UI$Button$Borderless,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$padding(
												$author$project$UI$scaled(-2)),
												$mdgriffith$elm_ui$Element$alignTop
											])),
									A2(
										$author$project$UI$Form$ifValid,
										A3(
											$author$project$UI$Form$withButton,
											true,
											$elm$core$Maybe$Nothing,
											A4(
												$author$project$UI$Button$ButtonSpecs,
												$elm$core$Maybe$Just(
													A2($author$project$Page$Tenant$SaveExpectedTenantEmail, meta.id, specs)),
												A2(makeIcon, 'save', 'Сохранить'),
												$author$project$UI$Button$Borderless,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$padding(
														$author$project$UI$scaled(-2)),
														$mdgriffith$elm_ui$Element$alignTop
													]))),
										A2($author$project$UI$Form$withField, specs, $author$project$UI$Form$empty))),
								$author$project$UI$Form$empty));
					} else {
						return A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									$author$project$Page$Tenant$cardElement(
									_Utils_Tuple3(
										'alternate_email',
										'email',
										$elm$core$String$isEmpty(domain.email) ? 'Не указан!' : domain.email)),
									$author$project$UI$Button$button(
									A4(
										$author$project$UI$Button$ButtonSpecs,
										$elm$core$Maybe$Just(
											A2(
												$author$project$Page$Tenant$ExpectedTenantEmailFieldChanged,
												meta.id,
												$author$project$UI$Form$Field(
													_Utils_update(
														defaultField,
														{displayed: domain.email})))),
										$author$project$UI$editIcon,
										$author$project$UI$Button$Borderless,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$padding(0),
												$mdgriffith$elm_ui$Element$centerY
											])))
								]));
					}
				}()
				]));
	});
var $author$project$Page$Tenant$SwitchedActiveFlat = function (a) {
	return {$: 'SwitchedActiveFlat', a: a};
};
var $author$project$Page$Tenant$flatCard = F2(
	function (model, flat) {
		var _v0 = _Utils_Tuple2(
			_Utils_Tuple2(
				_Utils_Tuple3(
					'apartment',
					'Адрес',
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.domain;
								},
								function ($) {
									return $.address;
								}),
							$author$project$API$Resource$toMaybe(flat.domain.building)))),
				_Utils_Tuple3(
					'web_stories',
					'Подъезд',
					$elm$core$String$fromInt(flat.domain.section))),
			_Utils_Tuple2(
				_Utils_Tuple3(
					'elevator',
					'Этаж',
					$elm$core$String$fromInt(flat.domain.floor)),
				_Utils_Tuple3(
					'door_front',
					'Квартира',
					$elm$core$String$fromInt(flat.domain.number))));
		var _v1 = _v0.a;
		var address = _v1.a;
		var section = _v1.b;
		var _v2 = _v0.b;
		var floor = _v2.a;
		var apartment = _v2.b;
		var contents = A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(3)),
					$mdgriffith$elm_ui$Element$padding(
					$author$project$UI$scaled(1)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$elm$core$List$map,
				$author$project$Page$Tenant$cardElement,
				_List_fromArray(
					[address, section, floor, apartment])));
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(3)),
						$mdgriffith$elm_ui$Element$padding(
						$author$project$UI$scaled(1)),
						$mdgriffith$elm_ui$Element$Events$onClick(
						$author$project$Page$Tenant$SwitchedActiveFlat(flat.meta.id)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_Utils_eq(
					model.activeFlat,
					$elm$core$Maybe$Just(flat.meta.id)) ? $author$project$UI$border($author$project$UI$colors.primary) : _List_Nil),
			contents);
	});
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elmcraft$core_extra$Float$Extra$roundToDecimal = F2(
	function (places, value) {
		if (places < 0) {
			return value;
		} else {
			var exp = A2($elm$core$Basics$pow, 10.0, places);
			var multiplyByExp = $elm$core$Basics$mul(exp);
			var divByExp = function (v) {
				return v / exp;
			};
			return divByExp(
				$elm$core$Basics$round(
					multiplyByExp(value)));
		}
	});
var $elmcraft$core_extra$Float$Extra$roundAsFloat = F2(
	function (places, strNum) {
		return (places < 0) ? strNum : A2($elmcraft$core_extra$Float$Extra$roundToDecimal, places, strNum);
	});
var $elmcraft$core_extra$Float$Extra$sign = function (x) {
	return (x >= 0) ? '' : '-';
};
var $elmcraft$core_extra$Float$Extra$toFixedDecimalPlaces = F2(
	function (decimalPlaces, value) {
		var padString = function (s) {
			var _v0 = A2($elm$core$String$split, '.', s);
			_v0$2:
			while (true) {
				if (_v0.b) {
					if (_v0.b.b) {
						if (!_v0.b.b.b) {
							var v1 = _v0.a;
							var _v1 = _v0.b;
							var v2 = _v1.a;
							return v1 + ('.' + A3(
								$elm$core$String$padRight,
								decimalPlaces,
								_Utils_chr('0'),
								v2));
						} else {
							break _v0$2;
						}
					} else {
						var v1 = _v0.a;
						return v1 + ('.' + A2($elm$core$String$repeat, decimalPlaces, '0'));
					}
				} else {
					break _v0$2;
				}
			}
			return s;
		};
		return $elm$core$Basics$isNaN(value) ? 'NaN' : ($elm$core$Basics$isInfinite(value) ? ($elmcraft$core_extra$Float$Extra$sign(value) + 'Infinity') : ((decimalPlaces <= 0) ? $elm$core$String$fromFloat(
			A2($elmcraft$core_extra$Float$Extra$roundAsFloat, decimalPlaces, value)) : padString(
			$elm$core$String$fromFloat(
				A2($elmcraft$core_extra$Float$Extra$roundToDecimal, decimalPlaces, value)))));
	});
var $author$project$Page$Tenant$RequestedPayment = function (a) {
	return {$: 'RequestedPayment', a: a};
};
var $author$project$Utils$prettifyInt = function (num) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (digit, res) {
				return (3 === A2(
					$elm$core$Maybe$withDefault,
					$elm$core$String$length(res),
					$elm$core$List$head(
						A2($elm$core$String$indexes, ' ', res)))) ? (digit + (' ' + res)) : _Utils_ap(digit, res);
			}),
		'',
		A2(
			$elm$core$String$split,
			'',
			$elm$core$String$fromInt(num)));
};
var $author$project$Utils$prettifyFloat = function (num) {
	return _Utils_ap(
		$author$project$Utils$prettifyInt(num | 0),
		A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$append('.'),
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$String$slice, 0, 2),
					$elm$core$List$head(
						A2(
							$elm$core$List$drop,
							1,
							A2(
								$elm$core$String$split,
								'.',
								$elm$core$String$fromFloat(num))))))));
};
var $author$project$Page$Tenant$utilityBillCard = function (bill) {
	var _v0 = bill.domain;
	var file = _v0.a.file;
	var info = _v0.a.info;
	var attachments = A2(
		$mdgriffith$elm_ui$Element$map,
		$elm$core$Basics$never,
		$author$project$UI$Card$attachment(file));
	var cost = $mdgriffith$elm_ui$Element$text(
		$author$project$Utils$prettifyFloat(info.totalCost));
	var description = A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$shrink))
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$text(info.description)
			]));
	var issued = $author$project$UI$Card$dateLabel(info.issued);
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_Utils_ap(
			$author$project$UI$border($author$project$UI$colors.transparent),
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(
						$author$project$UI$scaled(1)),
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(1)),
						$author$project$UI$shadow,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				(_Utils_eq(info.status, $author$project$Domain$Created) || _Utils_eq(info.status, $author$project$Domain$Rejected)) ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$behindContent(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$behindContent(
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$alignBottom,
												$mdgriffith$elm_ui$Element$alignRight,
												$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
												$mdgriffith$elm_ui$Element$padding(
												$author$project$UI$scaled(1))
											]),
										A2(
											$author$project$UI$materialIcon,
											'qr_code_2',
											$author$project$UI$scaled(6)))),
									$mdgriffith$elm_ui$Element$Events$onClick(
									$author$project$Page$Tenant$RequestedPayment(bill)),
									$mdgriffith$elm_ui$Element$pointer
								]),
							$mdgriffith$elm_ui$Element$none))
					]) : _List_Nil)),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(1)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[cost, issued])),
				description,
				attachments
			]));
};
var $author$project$Page$Tenant$viewBillList = F2(
	function (model, user) {
		var flatHeader = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$author$project$UI$fontsizes.bigger,
					A2(
					$mdgriffith$elm_ui$Element$paddingXY,
					0,
					$author$project$UI$scaled(1))
				]),
			$mdgriffith$elm_ui$Element$text('Квартиры'));
		var flatPicker = function (width) {
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(width)
					]),
				A2(
					$elm$core$List$cons,
					flatHeader,
					A2(
						$elm$core$List$map,
						$author$project$Page$Tenant$flatCard(model),
						A2($author$project$API$Resource$withDefault, _List_Nil, user.flat))));
		};
		var expectedRoommatesHeader = A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[$author$project$UI$fontsizes.bigger]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Ещё не зарегистрировавшиеся жильцы')
				]));
		var currentFilter = model.billFilter;
		var billsHeader = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$author$project$UI$fontsizes.bigger]),
			$mdgriffith$elm_ui$Element$text('Счета на оплату'));
		var activeFlat = A2(
			$elm$core$Maybe$andThen,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$filter(
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Basics$always(false),
						A2(
							$elm$core$Maybe$map,
							F2(
								function (a, b) {
									return _Utils_eq(a, b.meta.id);
								}),
							model.activeFlat))),
				$elm$core$List$head),
			$author$project$API$Resource$toMaybe(user.flat));
		var pageContent = _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-1))
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$author$project$UI$fontsizes.bigger]),
						$elm$core$List$singleton(
							$mdgriffith$elm_ui$Element$text(
								A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[user.surname, user.name, user.patronymic]))))),
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						A3(
							$author$project$UI$withTooltip,
							A2($author$project$UI$TooltipPosition, $author$project$UI$TooltipBelow, $author$project$UI$TooltipLeft),
							'Выход',
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.bad),
									$mdgriffith$elm_ui$Element$alignBottom
								])),
						{
							label: A2(
								$author$project$UI$materialIcon,
								'logout',
								$author$project$UI$scaled(4)),
							onPress: $elm$core$Maybe$Just($author$project$Page$Tenant$LoggedOut)
						})
					])),
				function () {
				var _v0 = user.email;
				if (_v0.$ === 'Just') {
					var email = _v0.a;
					return $mdgriffith$elm_ui$Element$text(email);
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}(),
				function () {
				var _v1 = $author$project$Session$getLocalStorageData(model.session);
				if ((_v1.$ === 'Just') && (_v1.a.$ === 'TenantLocalStorage')) {
					var data = _v1.a.a;
					return $author$project$UI$Button$button(
						A4(
							$author$project$UI$Button$ButtonSpecs,
							$elm$core$Maybe$Just(
								$author$project$Page$Tenant$RequestedPasswordReset(
									A2($elm$core$Maybe$withDefault, '', data.user.domain.email))),
							$mdgriffith$elm_ui$Element$text('Сменить пароль'),
							$author$project$UI$Button$Outline,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.bad)
								])));
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}(),
				flatPicker($mdgriffith$elm_ui$Element$shrink),
				function () {
				var _v2 = _Utils_Tuple2(model.expectedRoommates, activeFlat);
				if ((_v2.a.$ === 'Loaded') && (_v2.b.$ === 'Just')) {
					var expectedRoommates = _v2.a.a;
					var active = _v2.b.a;
					var relevantRoommates = A2(
						$elm$core$List$map,
						$author$project$Page$Tenant$expectedRoommateCard(model),
						A2(
							$elm$core$List$filter,
							function (_v4) {
								var domain = _v4.domain;
								return A2(
									$elm$core$List$member,
									active.meta.id,
									$author$project$API$Resource$toMeta(domain.flat));
							},
							expectedRoommates));
					if (!relevantRoommates.b) {
						return $mdgriffith$elm_ui$Element$none;
					} else {
						return A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(
									$author$project$UI$scaled(1))
								]),
							A2($elm$core$List$cons, expectedRoommatesHeader, relevantRoommates));
					}
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}(),
				function () {
				var balance = A2(
					$elm$core$Maybe$withDefault,
					0.0,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.domain;
							},
							function ($) {
								return $.balance;
							}),
						activeFlat));
				return (balance < 0) ? A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-2))
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$author$project$UI$fontsizes.bigger]),
							$mdgriffith$elm_ui$Element$text('Задолженность:')),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$author$project$UI$fontsizes.bigger,
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.bad)
								]),
							$mdgriffith$elm_ui$Element$text(
								A2($elmcraft$core_extra$Float$Extra$toFixedDecimalPlaces, 2, -balance) + ' руб.'))
						])) : $mdgriffith$elm_ui$Element$none;
			}(),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(1))
					]),
				A2(
					$elm$core$List$cons,
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(
								$author$project$UI$scaled(1))
							]),
						_List_fromArray(
							[billsHeader])),
					A2(
						$elm$core$Maybe$withDefault,
						_List_fromArray(
							[$author$project$UI$emptyContentHint]),
						A2(
							$elm$core$Maybe$map,
							$elm$core$List$map($author$project$Page$Tenant$utilityBillCard),
							A2(
								$elm$core$Maybe$andThen,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.domain;
									},
									function ($) {
										return $.utilityBills;
									}),
								activeFlat)))))
			]);
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					$mdgriffith$elm_ui$Element$spacing(12),
					$mdgriffith$elm_ui$Element$padding(
					$author$project$UI$scaled(1))
				]),
			pageContent);
	});
var $author$project$Page$Tenant$DismissedPayment = {$: 'DismissedPayment'};
var $author$project$Page$Tenant$UserConfirmedPayment = function (a) {
	return {$: 'UserConfirmedPayment', a: a};
};
var $author$project$Page$Tenant$viewPayment = F3(
	function (model, _v0, bill) {
		var proceedSpecs = function () {
			var _v2 = model.state;
			switch (_v2.$) {
				case 'Payment':
					return A4(
						$author$project$UI$Button$ButtonSpecs,
						$elm$core$Maybe$Just(
							$author$project$Page$Tenant$UserConfirmedPayment(bill)),
						$mdgriffith$elm_ui$Element$text('Оплачено!'),
						$author$project$UI$Button$Filled,
						_List_Nil);
				case 'AwaitingPaymentConfirmation':
					return A4(
						$author$project$UI$Button$ButtonSpecs,
						$elm$core$Maybe$Nothing,
						$mdgriffith$elm_ui$Element$text('Отправляем...'),
						$author$project$UI$Button$Filled,
						_List_Nil);
				default:
					return _Debug_todo(
						'Page.Tenant',
						{
							start: {line: 817, column: 21},
							end: {line: 817, column: 31}
						})('');
			}
		}();
		var orderedList = function (points) {
			var maxWidth = $elm$core$Basics$ceiling(
				A2(
					$elm$core$Basics$logBase,
					10,
					$elm$core$List$length(points)));
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-1)),
						$mdgriffith$elm_ui$Element$paddingEach(
						{
							bottom: 0,
							left: $author$project$UI$scaled(-1),
							right: 0,
							top: 0
						})
					]),
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (number, fragments) {
							return A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(
										$author$project$UI$scaled(1))
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$bold,
												$mdgriffith$elm_ui$Element$alignTop,
												$mdgriffith$elm_ui$Element$alignLeft,
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$px(
													maxWidth * $author$project$UI$scaled(1)))
											]),
										$mdgriffith$elm_ui$Element$text(
											$elm$core$String$fromInt(number + 1) + '.')),
										A2($mdgriffith$elm_ui$Element$paragraph, _List_Nil, fragments)
									]));
						}),
					points));
		};
		var backSpecs = A4(
			$author$project$UI$Button$ButtonSpecs,
			$elm$core$Maybe$Just($author$project$Page$Tenant$DismissedPayment),
			$mdgriffith$elm_ui$Element$text('Назад'),
			$author$project$UI$Button$Outline,
			_List_Nil);
		var _v1 = bill.domain;
		var info = _v1.a.info;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			A2(
				$author$project$UI$withBorder,
				$author$project$UI$colors.transparent,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(
						$author$project$UI$scaled(1)),
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(1)),
						$author$project$UI$shadow,
						$mdgriffith$elm_ui$Element$width(
						A2($mdgriffith$elm_ui$Element$maximum, 500, $mdgriffith$elm_ui$Element$fill)),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY
					])),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$download,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
						]),
					{
						label: A2(
							$mdgriffith$elm_ui$Element$image,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$author$project$UI$contained
								]),
							{description: 'QR-код с данными для платежа', src: info.qrCodeLink}),
						url: info.qrCodeLink
					}),
					orderedList(
					A2(
						$elm$core$List$map,
						A2($elm$core$Basics$composeR, $mdgriffith$elm_ui$Element$text, $elm$core$List$singleton),
						_List_fromArray(
							['Откройте приложение Вашего онлайн-банка (например, Tinkoff или Сбер)', 'Отсканируйте QR-код', 'Проверьте правильность заполнения данных платежа', 'Осуществите перевод', 'Бухгалтер проверит оплату и подтвердит её. Это займёт некоторое время!']))),
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(1)),
							$mdgriffith$elm_ui$Element$paddingEach(
							{
								bottom: 0,
								left: 0,
								right: 0,
								top: $author$project$UI$scaled(-1)
							})
						]),
					_List_fromArray(
						[
							$author$project$UI$Button$button(backSpecs),
							$author$project$UI$Button$button(proceedSpecs)
						]))
				]));
	});
var $author$project$Page$Tenant$view = function (model) {
	var _v0 = $author$project$API$Resource$toStatus(model.user);
	switch (_v0.$) {
		case 'NotStarted':
			return $mdgriffith$elm_ui$Element$text('Ошибка, перезагрузите страницу');
		case 'Loading':
			return A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, $author$project$UI$Loading$spinner);
		case 'Failed':
			return $mdgriffith$elm_ui$Element$text('Не удалось получить данные');
		default:
			var user = _v0.a;
			var _v1 = model.state;
			switch (_v1.$) {
				case 'BillList':
					return A2($author$project$Page$Tenant$viewBillList, model, user.domain);
				case 'Payment':
					var bill = _v1.a;
					return A3($author$project$Page$Tenant$viewPayment, model, user.domain, bill);
				default:
					var bill = _v1.a;
					return A3($author$project$Page$Tenant$viewPayment, model, user.domain, bill);
			}
	}
};
var $author$project$Page$Tenant$wrap = F2(
	function (_v0, _v1) {
		var session = _v0.session;
		var device = _v0.device;
		var userId = _v1.userId;
		return {device: device, session: session, userId: userId};
	});
var $author$project$Page$Tenant$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Protected(
		A2($author$project$Framework$andFilter, $author$project$Page$Tenant$ownerOnly, $author$project$Framework$authenticatedOnly)),
	{
		baseUpdated: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, $author$project$Page$Tenant$UpdatedBaseModel),
		init: $author$project$Page$Tenant$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $author$project$Page$Tenant$title,
		update: $author$project$Framework$EffectUpdate($author$project$Page$Tenant$update),
		view: $author$project$Framework$PlainView($author$project$Page$Tenant$view),
		wrapArgs: $author$project$Page$Tenant$wrap
	});
var $author$project$Framework$ModalView = F2(
	function (a, b) {
		return {$: 'ModalView', a: a, b: b};
	});
var $author$project$Page$Topic$Closed = {$: 'Closed'};
var $author$project$Page$Topic$GotMessages = function (a) {
	return {$: 'GotMessages', a: a};
};
var $author$project$Page$Topic$GotProtectedFileToken = function (a) {
	return {$: 'GotProtectedFileToken', a: a};
};
var $author$project$Page$Topic$GotTopic = function (a) {
	return {$: 'GotTopic', a: a};
};
var $author$project$Page$Topic$FileNameFieldChanged = function (a) {
	return {$: 'FileNameFieldChanged', a: a};
};
var $author$project$Page$Topic$defaultFileNameSpecs = function () {
	var defaultFileName = $author$project$UI$Form$Text$default($author$project$Page$Topic$FileNameFieldChanged);
	return _Utils_update(
		defaultFileName,
		{
			hint: $elm$core$Maybe$Just('Описание файла'),
			kind: $author$project$UI$Form$Text$Regular($mdgriffith$elm_ui$Element$Input$text),
			name: 'Описание',
			required: $author$project$UI$Form$Required
		});
}();
var $author$project$Page$Topic$TextFieldChanged = function (a) {
	return {$: 'TextFieldChanged', a: a};
};
var $author$project$Page$Topic$defaultTextSpecs = function () {
	var defaultText = $author$project$UI$Form$Text$default($author$project$Page$Topic$TextFieldChanged);
	return _Utils_update(
		defaultText,
		{
			hint: $elm$core$Maybe$Just('Текст сообщения'),
			kind: $author$project$UI$Form$Text$Multiline(true),
			name: 'Текст сообщения',
			showLabel: false
		});
}();
var $author$project$Domain$FromManager = F2(
	function (a, b) {
		return {$: 'FromManager', a: a, b: b};
	});
var $author$project$Domain$FromTenant = function (a) {
	return {$: 'FromTenant', a: a};
};
var $author$project$Domain$Message = F5(
	function (content, author, attachments, topic, created) {
		return {attachments: attachments, author: author, content: content, created: created, topic: topic};
	});
var $author$project$Domain$PrivateAttachment = F4(
	function (name, owner, created, contentURL) {
		return {contentURL: contentURL, created: created, name: name, owner: owner};
	});
var $author$project$Domain$privateAttachmentDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Domain$PrivateAttachment,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'owner',
		$elm$json$Json$Decode$maybe(
			A2(
				$elm$json$Json$Decode$map,
				function (x) {
					return A2($author$project$API$Resource$Resource, x, $author$project$API$Status$NotStarted);
				},
				$author$project$Domain$PB$idDecoder))),
	A2($elm$json$Json$Decode$field, 'created', $author$project$Utils$parseTimestamp),
	$author$project$Utils$decodeFilePath('content'));
var $author$project$Domain$messageDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Domain$Message,
	A2($elm$json$Json$Decode$field, 'content', $elm$json$Json$Decode$string),
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A3(
				$elm$json$Json$Decode$map2,
				$author$project$Domain$FromManager,
				A3($author$project$Domain$PB$shortResourceDecoderWithID, 'manager_author', $author$project$Domain$PB$idDecoder, $author$project$Domain$managerDecoder),
				A2(
					$elm$json$Json$Decode$field,
					'shared_with',
					$elm$json$Json$Decode$list($author$project$Domain$PB$idDecoder))),
				A2(
				$elm$json$Json$Decode$map,
				$author$project$Domain$FromTenant,
				A3($author$project$Domain$PB$shortResourceDecoderWithID, 'author', $author$project$Domain$PB$idDecoder, $author$project$Domain$tenantDecoder))
			])),
	A4(
		$author$project$Domain$PB$resourceDecoder,
		_List_fromArray(
			['attachments']),
		$elm$json$Json$Decode$list($author$project$Domain$PB$idDecoder),
		_List_fromArray(
			['expand', 'attachments']),
		$elm$json$Json$Decode$list(
			$author$project$Domain$PB$decoderWithID($author$project$Domain$privateAttachmentDecoder))),
	A2(
		$elm$json$Json$Decode$map,
		function (x) {
			return A2($author$project$API$Resource$Resource, x, $author$project$API$Status$NotStarted);
		},
		A2($elm$json$Json$Decode$field, 'topic', $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'created', $author$project$Utils$parseTimestamp));
var $author$project$Collections$getMessages = F2(
	function (authHeader, topicId) {
		return A3(
			$author$project$API$getAll,
			'message',
			A2(
				$author$project$API$Get,
				$author$project$Domain$PB$decoderWithID($author$project$Domain$messageDecoder),
				_List_fromArray(
					[
						$author$project$API$Filter(
						A2(
							$author$project$API$Eq,
							'topic',
							$author$project$API$string(topicId))),
						$author$project$API$Sort(
						_List_fromArray(
							['created'])),
						$author$project$API$Expand(
						_List_fromArray(
							['attachments', 'author', 'manager_author']))
					])),
			_List_fromArray(
				[authHeader]));
	});
var $author$project$Domain$Topic = F4(
	function (title, author, closed_at, created) {
		return {author: author, closed_at: closed_at, created: created, title: title};
	});
var $author$project$Domain$topicDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Domain$Topic,
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A3($author$project$Domain$PB$shortResourceDecoderWithID, 'author', $author$project$Domain$PB$idDecoder, $author$project$Domain$tenantDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'closed_at',
		$elm$json$Json$Decode$maybe($author$project$Utils$parseTimestamp)),
	A2($elm$json$Json$Decode$field, 'created', $author$project$Utils$parseTimestamp));
var $author$project$Collections$getTopic = function (authHeader) {
	return A4(
		$author$project$API$getSingle,
		'topic',
		A2(
			$author$project$API$Get,
			$author$project$Domain$PB$decoderWithID($author$project$Domain$topicDecoder),
			_List_Nil),
		_List_fromArray(
			[authHeader]),
		_List_Nil);
};
var $author$project$Collections$protectedFileToken = function (authHeader) {
	return $elm$http$Http$task(
		{
			body: $elm$http$Http$emptyBody,
			headers: _List_fromArray(
				[authHeader]),
			method: 'POST',
			resolver: $author$project$API$jsonResolver(
				A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string)),
			timeout: $author$project$API$timeout,
			url: '/api/files/token'
		});
};
var $author$project$Page$Topic$init = function (args) {
	var quuuux = {
		device: args.device,
		error: $elm$core$Maybe$Nothing,
		filePicker: $author$project$Page$Topic$Closed,
		filenameField: $author$project$Page$Topic$defaultFileNameSpecs,
		messages: $author$project$API$Status$Loading,
		newMessage: $author$project$API$Status$Loading,
		protectedFileToken: $author$project$API$Status$Loading,
		session: args.session,
		textSpecs: $author$project$Page$Topic$defaultTextSpecs,
		topic: A2($author$project$API$Resource$Resource, args.topicId, $author$project$API$Status$Loading)
	};
	var authHeader = $author$project$Session$authorizationHeader(
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$author$project$Session$getToken(args.session)));
	return _Utils_Tuple2(
		quuuux,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2(
					$elm$core$Task$attempt,
					$author$project$Page$Topic$GotMessages,
					A3($author$project$Collections$getMessages, authHeader, args.topicId, _List_Nil)),
					A2(
					$elm$core$Task$attempt,
					$author$project$Page$Topic$GotTopic,
					A2($author$project$Collections$getTopic, authHeader, args.topicId)),
					A2(
					$elm$core$Task$attempt,
					$author$project$Page$Topic$GotProtectedFileToken,
					$author$project$Collections$protectedFileToken(authHeader))
				])));
};
var $author$project$Page$Topic$title = function (model) {
	var _v0 = $author$project$API$Resource$toMaybe(model.topic);
	if (_v0.$ === 'Nothing') {
		return 'Обращение';
	} else {
		var topic = _v0.a;
		return 'Обращение: ' + topic.domain.title;
	}
};
var $author$project$Page$Topic$DeletedAttachment = F2(
	function (a, b) {
		return {$: 'DeletedAttachment', a: a, b: b};
	});
var $author$project$Page$Topic$GotCloseTopicResponse = function (a) {
	return {$: 'GotCloseTopicResponse', a: a};
};
var $author$project$Page$Topic$Open = function (a) {
	return {$: 'Open', a: a};
};
var $author$project$Page$Topic$SelectedFile = function (a) {
	return {$: 'SelectedFile', a: a};
};
var $author$project$Page$Topic$SentMessage = function (a) {
	return {$: 'SentMessage', a: a};
};
var $author$project$Page$Topic$UploadedAttachment = function (a) {
	return {$: 'UploadedAttachment', a: a};
};
var $author$project$Page$Topic$Uploading = {$: 'Uploading'};
var $author$project$Collections$deleteAttachment = F2(
	function (authHeader, id) {
		return A3(
			$author$project$API$delete,
			'private_attachment',
			$author$project$API$Delete(id),
			_List_fromArray(
				[authHeader]));
	});
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $author$project$API$Resource$from = F2(
	function (makeMeta, data) {
		return A2(
			$author$project$API$Resource$Resource,
			makeMeta(data),
			$author$project$API$Status$Loaded(data));
	});
var $author$project$Collections$postMessage = function (authHeader) {
	return A4(
		$author$project$API$post,
		'message',
		A3(
			$author$project$API$Post,
			function (message) {
				return $elm$json$Json$Encode$object(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'content',
								$elm$json$Json$Encode$string(message.content)),
								_Utils_Tuple2(
								'attachments',
								A2(
									$elm$json$Json$Encode$list,
									$elm$json$Json$Encode$string,
									$author$project$API$Resource$toMeta(message.attachments))),
								_Utils_Tuple2(
								'topic',
								$elm$json$Json$Encode$string(
									$author$project$API$Resource$toMeta(message.topic)))
							]),
						function () {
							var _v0 = message.author;
							if (_v0.$ === 'FromTenant') {
								var _v1 = _v0.a;
								var userId = _v1.a;
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'author',
										$elm$json$Json$Encode$string(userId))
									]);
							} else {
								var _v2 = _v0.a;
								var managerId = _v2.a;
								var sharedWith = _v0.b;
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'manager_author',
										$elm$json$Json$Encode$string(managerId)),
										_Utils_Tuple2(
										'shared_with',
										A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, sharedWith))
									]);
							}
						}()));
			},
			$author$project$Domain$PB$decoderWithID($author$project$Domain$messageDecoder),
			_List_fromArray(
				[
					$author$project$API$Expand(
					_List_fromArray(
						['attachments', 'author', 'manager_author']))
				])),
		_List_fromArray(
			[authHeader]),
		_List_Nil);
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Domain$topicEncoder = function (topic) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'title',
			$elm$json$Json$Encode$string(topic.title)),
			_Utils_Tuple2(
			'author',
			$elm$json$Json$Encode$string(
				$author$project$API$Resource$toMeta(topic.author))),
			_Utils_Tuple2(
			'closed_at',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $author$project$Utils$encodeTimestamp, topic.closed_at)))
		]);
};
var $author$project$Collections$updateTopic = F2(
	function (authHeader, topic) {
		return A6(
			$author$project$API$patch,
			'topic',
			A3(
				$author$project$API$Patch,
				$author$project$Domain$PB$encodeWithID($author$project$Domain$topicEncoder),
				$author$project$Domain$PB$decoderWithID($author$project$Domain$topicDecoder),
				_List_Nil),
			_List_fromArray(
				[authHeader]),
			_List_Nil,
			topic.meta.id,
			topic);
	});
var $elm$http$Http$filePart = _Http_pair;
var $elm$http$Http$multipartBody = function (parts) {
	return A2(
		_Http_pair,
		'',
		_Http_toFormData(parts));
};
var $elm$http$Http$stringPart = _Http_pair;
var $author$project$Collections$uploadAttachment = F2(
	function (authHeader, _v0) {
		var name = _v0.name;
		var userId = _v0.userId;
		var file = _v0.file;
		var sharedWith = _v0.sharedWith;
		return $elm$http$Http$task(
			{
				body: $elm$http$Http$multipartBody(
					_Utils_ap(
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								A2(
									$elm$core$Basics$composeR,
									$elm$http$Http$stringPart('owner'),
									$elm$core$List$singleton),
								userId)),
						_Utils_ap(
							A2(
								$elm$core$List$map,
								$elm$http$Http$stringPart('shared_with'),
								sharedWith),
							_List_fromArray(
								[
									A2($elm$http$Http$stringPart, 'name', name),
									A2($elm$http$Http$filePart, 'content', file)
								])))),
				headers: _List_fromArray(
					[authHeader]),
				method: 'POST',
				resolver: $author$project$API$jsonResolver(
					$author$project$Domain$PB$decoderWithID($author$project$Domain$privateAttachmentDecoder)),
				timeout: $author$project$API$timeout,
				url: '/api/collections/private_attachment/records'
			});
	});
var $author$project$Page$Topic$update = F2(
	function (msg, model) {
		var withError = function (messages) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						error: $elm$core$Maybe$Just(messages)
					}),
				$elm$core$Platform$Cmd$none);
		};
		var authHeader = $author$project$Session$authorizationHeader(
			A2(
				$elm$core$Maybe$withDefault,
				'',
				$author$project$Session$getToken(model.session)));
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'GotMessages':
				if (msg.a.$ === 'Ok') {
					var messages = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								messages: $author$project$API$Status$Loaded(messages)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errorCode = msg.a.a;
					switch (errorCode.$) {
						case 'NetworkError':
							return withError(
								_List_fromArray(
									['Проблемы с сетью!', 'Попробуйте перезагрузить страницу.']));
						case 'Timeout':
							return withError(
								_List_fromArray(
									['Проблемы с сетью!', 'Попробуйте перезагрузить страницу.']));
						default:
							return withError(
								_List_fromArray(
									['Что-то пошло не так!', 'Попробуйте перезагрузить страницу.']));
					}
				}
			case 'GotTopic':
				if (msg.a.$ === 'Ok') {
					var topic = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								newMessage: $author$project$API$Status$Loaded(
									{
										attachments: A2(
											$author$project$API$Resource$Resource,
											_List_Nil,
											$author$project$API$Status$Loaded(_List_Nil)),
										author: function () {
											var _v1 = $author$project$Session$getLocalStorageData(model.session);
											if (_v1.$ === 'Just') {
												if (_v1.a.$ === 'TenantLocalStorage') {
													var user = _v1.a.a.user;
													return $author$project$Domain$FromTenant(
														A2($author$project$API$Resource$Resource, user.meta.id, $author$project$API$Status$NotStarted));
												} else {
													var manager = _v1.a.a.manager;
													return A2(
														$author$project$Domain$FromManager,
														A2(
															$author$project$API$Resource$Resource,
															manager.meta.id,
															$author$project$API$Status$Loaded(manager)),
														_List_fromArray(
															[
																$author$project$API$Resource$toMeta(topic.domain.author)
															]));
												}
											} else {
												return _Debug_todo(
													'Page.Topic',
													{
														start: {line: 200, column: 37},
														end: {line: 200, column: 47}
													})('branch \'Nothing\' not implemented');
											}
										}(),
										content: '',
										created: $elm$time$Time$millisToPosix(0),
										topic: A2(
											$author$project$API$Resource$Resource,
											topic.meta.id,
											$author$project$API$Status$Loaded(topic))
									}),
								topic: A2($author$project$API$Resource$loaded, topic, model.topic)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errorCode = msg.a.a;
					switch (errorCode.$) {
						case 'NetworkError':
							return withError(
								_List_fromArray(
									['Проблемы с сетью!', 'Попробуйте перезагрузить страницу.']));
						case 'Timeout':
							return withError(
								_List_fromArray(
									['Проблемы с сетью!', 'Попробуйте перезагрузить страницу.']));
						default:
							return withError(
								_List_fromArray(
									['Что-то пошло не так!', 'Попробуйте перезагрузить страницу.']));
					}
				}
			case 'GotProtectedFileToken':
				if (msg.a.$ === 'Ok') {
					var token = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								protectedFileToken: $author$project$API$Status$Loaded(token)
							}),
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Topic$GotProtectedFileToken,
							A2(
								$elm$core$Task$andThen,
								function (_v2) {
									return $author$project$Collections$protectedFileToken(authHeader);
								},
								$elm$core$Process$sleep(1000 * 90))));
				} else {
					var errorCode = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								protectedFileToken: $author$project$API$Status$Failed(
									A2($author$project$API$Error$LoadingFailure, errorCode, _List_Nil))
							}),
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Topic$GotProtectedFileToken,
							A2(
								$elm$core$Task$andThen,
								function (_v5) {
									return $author$project$Collections$protectedFileToken(authHeader);
								},
								$elm$core$Process$sleep(1000 * 10))));
				}
			case 'TextFieldChanged':
				var specs = msg.a.a;
				var _v6 = model.newMessage;
				if (_v6.$ === 'Loaded') {
					var message = _v6.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								newMessage: $author$project$API$Status$Loaded(
									_Utils_update(
										message,
										{
											content: A2($elm$core$Maybe$withDefault, '', specs.state)
										})),
								textSpecs: specs
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'PressedAttachButton':
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['application/x-7z-compressed', 'application/zip', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.oasis.opendocument.text', 'application/vnd.oasis.opendocument.spreadsheet', 'application/pdf', 'application/vnd.ms-excel', 'application/msword', 'image/png', 'image/jpeg', 'image/gif', 'image/webp', 'image/svg+xml', 'application/x-tar', 'audio/mpeg', 'audio/wav', 'video/mpeg', 'video/quicktime', 'video/mp4', 'video/webm', 'video/x-msvideo', 'audio/aac', 'video/x-matroska', 'application/gzip', 'text/plain', 'text/xml', 'text/csv']),
						$author$project$Page$Topic$SelectedFile));
			case 'SelectedFile':
				var file = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							filePicker: $author$project$Page$Topic$Open(file)
						}),
					$elm$core$Platform$Cmd$none);
			case 'CloseFilePicker':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{filePicker: $author$project$Page$Topic$Closed}),
					$elm$core$Platform$Cmd$none);
			case 'FileNameFieldChanged':
				var specs = msg.a.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{filenameField: specs}),
					$elm$core$Platform$Cmd$none);
			case 'AttachFile':
				var _v7 = _Utils_Tuple3(
					model.filePicker,
					$author$project$UI$Form$result(model.filenameField),
					$author$project$Session$getLocalStorageData(model.session));
				if (((_v7.a.$ === 'Open') && (_v7.b.$ === 'WithResult')) && (_v7.c.$ === 'Just')) {
					var file = _v7.a.a;
					var name = _v7.b.a;
					var storage = _v7.c.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{filePicker: $author$project$Page$Topic$Uploading, filenameField: $author$project$Page$Topic$defaultFileNameSpecs}),
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Topic$UploadedAttachment,
							A2(
								$author$project$Collections$uploadAttachment,
								authHeader,
								{
									file: file,
									name: name,
									sharedWith: function () {
										var _v8 = _Utils_Tuple2(storage, model.topic);
										if ((_v8.a.$ === 'ManagerLocalStorage') && (_v8.b.b.$ === 'Loaded')) {
											var _v9 = _v8.b;
											var domain = _v9.b.a.domain;
											return _List_fromArray(
												[
													$author$project$API$Resource$toMeta(domain.author)
												]);
										} else {
											return _List_Nil;
										}
									}(),
									userId: function () {
										if (storage.$ === 'TenantLocalStorage') {
											var user = storage.a.user;
											return $elm$core$Maybe$Just(user.meta.id);
										} else {
											return $elm$core$Maybe$Nothing;
										}
									}()
								})));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'UploadedAttachment':
				if (msg.a.$ === 'Ok') {
					var attachment = msg.a.a;
					var _v11 = model.newMessage;
					if (_v11.$ === 'Loaded') {
						var message = _v11.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									filePicker: $author$project$Page$Topic$Closed,
									newMessage: $author$project$API$Status$Loaded(
										_Utils_update(
											message,
											{
												attachments: function () {
													var _v12 = message.attachments;
													if (_v12.b.$ === 'Loaded') {
														var attachments = _v12.b.a;
														return A2(
															$author$project$API$Resource$Resource,
															_Utils_ap(
																A2(
																	$elm$core$List$map,
																	A2(
																		$elm$core$Basics$composeR,
																		function ($) {
																			return $.meta;
																		},
																		function ($) {
																			return $.id;
																		}),
																	attachments),
																_List_fromArray(
																	[attachment.meta.id])),
															$author$project$API$Status$Loaded(
																_Utils_ap(
																	attachments,
																	_List_fromArray(
																		[attachment]))));
													} else {
														var x = _v12;
														return x;
													}
												}()
											}))
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{filePicker: $author$project$Page$Topic$Closed}),
						$elm$core$Platform$Cmd$none);
				}
			case 'DeleteAttachment':
				var attachmentId = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$attempt,
						$author$project$Page$Topic$DeletedAttachment(attachmentId),
						A2($author$project$Collections$deleteAttachment, authHeader, attachmentId)));
			case 'DeletedAttachment':
				if (msg.b.$ === 'Ok') {
					var attachmentId = msg.a;
					var _v13 = model.newMessage;
					if (_v13.$ === 'Loaded') {
						var message = _v13.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									newMessage: $author$project$API$Status$Loaded(
										_Utils_update(
											message,
											{
												attachments: A2(
													$author$project$API$Resource$from,
													$elm$core$List$map(
														A2(
															$elm$core$Basics$composeR,
															function ($) {
																return $.meta;
															},
															function ($) {
																return $.id;
															})),
													A2(
														$elm$core$List$filter,
														function (x) {
															return !_Utils_eq(x.meta.id, attachmentId);
														},
														A2(
															$elm$core$Maybe$withDefault,
															_List_Nil,
															$author$project$API$Resource$toMaybe(message.attachments))))
											}))
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var attachmentId = msg.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'PressedSendButton':
				return _Utils_Tuple2(
					model,
					function () {
						var _v14 = model.newMessage;
						if (_v14.$ === 'Loaded') {
							var message = _v14.a;
							return A2(
								$elm$core$Task$attempt,
								$author$project$Page$Topic$SentMessage,
								A2($author$project$Collections$postMessage, authHeader, message));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 'SentMessage':
				if (msg.a.$ === 'Ok') {
					var message = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								messages: function () {
									var _v15 = model.messages;
									if (_v15.$ === 'Loaded') {
										var old = _v15.a;
										return $author$project$API$Status$Loaded(
											_Utils_ap(
												old,
												_List_fromArray(
													[message])));
									} else {
										return $author$project$API$Status$Loaded(
											_List_fromArray(
												[message]));
									}
								}(),
								textSpecs: $author$project$Page$Topic$defaultTextSpecs
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Debug_todo(
						'Page.Topic',
						{
							start: {line: 420, column: 13},
							end: {line: 420, column: 23}
						})('branch \'SentMessage (Err _)\' not implemented');
				}
			case 'CloseTopic':
				var _v16 = model.topic;
				if (_v16.b.$ === 'Loaded') {
					var topic = _v16.b.a;
					var domain = topic.domain;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Topic$GotCloseTopicResponse,
							A2(
								$elm$core$Task$andThen,
								function (now) {
									return A2(
										$author$project$Collections$updateTopic,
										authHeader,
										_Utils_update(
											topic,
											{
												domain: _Utils_update(
													domain,
													{
														closed_at: $elm$core$Maybe$Just(now)
													})
											}));
								},
								$elm$time$Time$now)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (msg.a.$ === 'Ok') {
					var topic = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								topic: A2(
									$author$project$API$Resource$Resource,
									topic.meta.id,
									$author$project$API$Status$Loaded(topic))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Debug_todo(
						'Page.Topic',
						{
							start: {line: 450, column: 13},
							end: {line: 450, column: 23}
						})('branch \'GotCloseTopicResponse (Err _)\' not implemented');
				}
		}
	});
var $author$project$Framework$BlockingModal = {$: 'BlockingModal'};
var $author$project$Page$Topic$CloseFilePicker = {$: 'CloseFilePicker'};
var $author$project$Page$Topic$CloseTopic = {$: 'CloseTopic'};
var $author$project$Framework$ModalContent = F2(
	function (a, b) {
		return {$: 'ModalContent', a: a, b: b};
	});
var $author$project$Framework$TintedModal = function (a) {
	return {$: 'TintedModal', a: a};
};
var $author$project$Page$Topic$AttachFile = {$: 'AttachFile'};
var $author$project$Page$Topic$NoOp = {$: 'NoOp'};
var $elm$file$File$name = _File_name;
var $author$project$Page$Topic$fileForm = F2(
	function (model, file) {
		var filenameLabel = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2($author$project$UI$Form$Label$plain, 'Имя файла', false),
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(
							$elm$file$File$name(file))
						]))
				]));
		return A2(
			$author$project$UI$Form$form,
			_List_fromArray(
				[
					$author$project$UI$stopClickPropagation($author$project$Page$Topic$NoOp)
				]),
			A2(
				$author$project$UI$Form$ifValid,
				A3(
					$author$project$UI$Form$withButton,
					true,
					$elm$core$Maybe$Nothing,
					A4(
						$author$project$UI$Button$ButtonSpecs,
						$elm$core$Maybe$Just($author$project$Page$Topic$AttachFile),
						$mdgriffith$elm_ui$Element$text('Прикрепить файл'),
						$author$project$UI$Button$Filled,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]))),
				A2(
					$author$project$UI$Form$withField,
					model.filenameField,
					A2(
						$author$project$UI$Form$withExternal,
						filenameLabel,
						A2($author$project$UI$Form$withTitle, 'Информация о файле', $author$project$UI$Form$empty)))));
	});
var $author$project$Page$Topic$PressedAttachButton = {$: 'PressedAttachButton'};
var $author$project$Page$Topic$PressedSendButton = {$: 'PressedSendButton'};
var $author$project$Page$Topic$DeleteAttachment = function (a) {
	return {$: 'DeleteAttachment', a: a};
};
var $author$project$Page$Topic$renderNewAttachments = function (attachments) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		A2(
			$elm$core$List$map,
			function (attachment) {
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-1))
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerY]),
							A2(
								$author$project$UI$materialIcon,
								'attachment',
								$author$project$UI$scaled(5))),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerY]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(attachment.domain.name)
								])),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$centerY]),
							$author$project$UI$Button$button(
								A4(
									$author$project$UI$Form$iconButton,
									'delete',
									$elm$core$Maybe$Just(
										$author$project$Page$Topic$DeleteAttachment(attachment.meta.id)),
									$author$project$UI$Button$Borderless,
									_List_Nil)))
						]));
			},
			attachments));
};
var $author$project$Page$Topic$newMessageBox = function (model) {
	var _v0 = model.newMessage;
	if (_v0.$ === 'Loaded') {
		var newMessage = _v0.a;
		return A2(
			$author$project$UI$Form$borderlessForm,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$alignBottom]),
			A4(
				$author$project$UI$Form$withRow,
				true,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(0)
					]),
				A4(
					$author$project$UI$Form$withButton,
					true,
					$elm$core$Maybe$Just(
						$mdgriffith$elm_ui$Element$el(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$alignBottom,
									$mdgriffith$elm_ui$Element$moveUp(6)
								]))),
					A4(
						$author$project$UI$Form$iconButton,
						'send',
						$elm$core$Maybe$Just($author$project$Page$Topic$PressedSendButton),
						$author$project$UI$Button$Borderless,
						_List_Nil),
					A4(
						$author$project$UI$Form$withButton,
						true,
						$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignBottom,
										$mdgriffith$elm_ui$Element$moveUp(6)
									]))),
						A4(
							$author$project$UI$Form$iconButton,
							'attach_file',
							$elm$core$Maybe$Just($author$project$Page$Topic$PressedAttachButton),
							$author$project$UI$Button$Borderless,
							_List_Nil),
						A2($author$project$UI$Form$withField, model.textSpecs, $author$project$UI$Form$empty))),
				A3(
					$elm$core$Basics$apR,
					$author$project$Page$Topic$renderNewAttachments(
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							$author$project$API$Resource$toMaybe(newMessage.attachments))),
					$author$project$UI$Form$withExternal,
					$author$project$UI$Form$empty)));
	} else {
		return $author$project$UI$Loading$spinner;
	}
};
var $elmcraft$core_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$Page$Topic$renderAttachment = F2(
	function (model, data) {
		var extension = A2(
			$elm$core$Maybe$withDefault,
			'???',
			$elmcraft$core_extra$List$Extra$last(
				A2($elm$core$String$split, '.', data.contentURL)));
		var _v0 = model.protectedFileToken;
		if (_v0.$ === 'Loaded') {
			var fileToken = _v0.a;
			return A2(
				$mdgriffith$elm_ui$Element$link,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$pointer]),
				{
					label: A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(
								$author$project$UI$scaled(-1))
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_Utils_ap(
									$author$project$UI$border($author$project$UI$colors.primary),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(
											$author$project$UI$scaled(-4)),
											$mdgriffith$elm_ui$Element$padding(
											$author$project$UI$scaled(-4)),
											$mdgriffith$elm_ui$Element$htmlAttribute(
											$elm$html$Html$Attributes$class('alt-rotate-icon')),
											$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_0),
											$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_100)
										])),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
												$author$project$UI$transitionDuration($author$project$UI$durations.fast)
											]),
										A2(
											$author$project$UI$materialIcon,
											'attachment',
											$author$project$UI$scaled(4))),
										$mdgriffith$elm_ui$Element$text(extension)
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(data.name)
									]))
							])),
					url: data.contentURL + ('?token=' + fileToken)
				});
		} else {
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(-1))
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_Utils_ap(
							$author$project$UI$border($author$project$UI$colors.primary),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(
										$author$project$UI$scaled(5))),
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(
										$author$project$UI$scaled(5))),
									$mdgriffith$elm_ui$Element$padding(
									$author$project$UI$scaled(-4)),
									$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_0)
								])),
						$author$project$UI$Loading$spinner),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$author$project$UI$preventSelect]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(data.name)
							]))
					]));
		}
	});
var $author$project$Page$Topic$viewMessage = F2(
	function (model, message) {
		var timeLabel = function (align) {
			return function (_v5) {
				var year = _v5.year;
				var month = _v5.month;
				var day = _v5.day;
				var hour = _v5.hour;
				var minute = _v5.minute;
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignBottom,
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
							$author$project$UI$fontsizes.small,
							$mdgriffith$elm_ui$Element$paddingEach(
							{
								bottom: $author$project$UI$scaled(-1),
								left: $author$project$UI$scaled(-1),
								right: $author$project$UI$scaled(-1),
								top: $author$project$UI$scaled(-8)
							}),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-1)),
							$author$project$UI$preventSelect
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(
							A2(
								$elm$core$String$join,
								'.',
								A2(
									$elm$core$List$map,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$String$fromInt,
										A2(
											$elm$core$String$padLeft,
											2,
											_Utils_chr('0'))),
									_List_fromArray(
										[
											day,
											$author$project$Utils$monthToInt(month),
											year
										])))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[align]),
							$mdgriffith$elm_ui$Element$text(
								A2(
									$elm$core$String$join,
									':',
									A2(
										$elm$core$List$map,
										A2(
											$elm$core$Basics$composeR,
											$elm$core$String$fromInt,
											A2(
												$elm$core$String$padLeft,
												2,
												_Utils_chr('0'))),
										_List_fromArray(
											[hour, minute])))))
						]));
			}(
				A2($justinmimbs$time_extra$Time$Extra$posixToParts, $author$project$Domain$zone, message.created));
		};
		var content = A2(
			$elm$core$List$cons,
			A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$elm$html$Html$Attributes$class('render-new-lines'))
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(message.content)
					])),
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.domain;
					},
					$author$project$Page$Topic$renderAttachment(model)),
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$author$project$API$Resource$toMaybe(message.attachments))));
		var card = function (attrs) {
			return $author$project$UI$Card$contentCard(
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$fill)),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-1))
						]),
					attrs));
		};
		var _v0 = message.author;
		if (_v0.$ === 'FromTenant') {
			var _v1 = _v0.a;
			var userId = _v1.a;
			var userStatus = _v1.b;
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						function () {
						if (userStatus.$ === 'Loaded') {
							var status = userStatus.a;
							return A2(
								$mdgriffith$elm_ui$Element$link,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
										$author$project$UI$fontsizes.small,
										$mdgriffith$elm_ui$Element$Font$alignRight,
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$paddingEach(
										{
											bottom: $author$project$UI$scaled(-4),
											left: 0,
											right: 0,
											top: 0
										}),
										$author$project$UI$preventSelect
									]),
								{
									label: A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										$elm$core$List$singleton(
											$mdgriffith$elm_ui$Element$text(
												$elm$core$String$trim(
													A2(
														$elm$core$String$join,
														' ',
														_List_fromArray(
															[status.domain.surname, status.domain.name, status.domain.patronymic])))))),
									url: '/user/' + userId
								});
						} else {
							return $mdgriffith$elm_ui$Element$none;
						}
					}(),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$alignRight,
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
							]),
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_Nil,
							_List_fromArray(
								[
									timeLabel($mdgriffith$elm_ui$Element$alignRight),
									A2(card, _List_Nil, content)
								])))
					]));
		} else {
			var _v3 = _v0.a;
			var managerId = _v3.a;
			var managerStatus = _v3.b;
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						function () {
						if (managerStatus.$ === 'Loaded') {
							var status = managerStatus.a;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
										$author$project$UI$fontsizes.small,
										$author$project$UI$preventSelect
									]),
								A2(
									$elm$core$List$map,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$String$trim,
										A2(
											$elm$core$Basics$composeR,
											$mdgriffith$elm_ui$Element$text,
											A2(
												$elm$core$Basics$composeR,
												$elm$core$List$singleton,
												$mdgriffith$elm_ui$Element$paragraph(
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$paddingEach(
															{
																bottom: $author$project$UI$scaled(-4),
																left: 0,
																right: 0,
																top: 0
															})
														]))))),
									_List_fromArray(
										[status.domain.name, status.domain.role])));
						} else {
							return $mdgriffith$elm_ui$Element$none;
						}
					}(),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
							]),
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									card,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.primary),
											$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0)
										]),
									content),
									timeLabel($mdgriffith$elm_ui$Element$alignLeft)
								])))
					]));
		}
	});
var $author$project$Page$Topic$view = F2(
	function (model, toGlobal) {
		var _v0 = _Utils_Tuple2(
			$author$project$API$Resource$toStatus(model.topic),
			model.messages);
		if ((_v0.a.$ === 'Loaded') && (_v0.b.$ === 'Loaded')) {
			var topic = _v0.a.a;
			var messages = _v0.b.a;
			return _Utils_Tuple2(
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(1)),
							$mdgriffith$elm_ui$Element$padding(
							$author$project$UI$scaled(-1))
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$author$project$UI$fontsizes.bigger,
									$mdgriffith$elm_ui$Element$Font$bold,
									$mdgriffith$elm_ui$Element$Font$center
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(topic.domain.title)
								])),
						A2(
							$elm$core$List$cons,
							function () {
								var _v1 = _Utils_Tuple2(
									$author$project$Session$getLocalStorageData(model.session),
									topic.domain.closed_at);
								if (((_v1.a.$ === 'Just') && (_v1.a.a.$ === 'ManagerLocalStorage')) && (_v1.b.$ === 'Nothing')) {
									var _v2 = _v1.b;
									return $author$project$UI$Button$button(
										A4(
											$author$project$UI$Button$ButtonSpecs,
											$elm$core$Maybe$Just($author$project$Page$Topic$CloseTopic),
											$mdgriffith$elm_ui$Element$text('Закрыть обращение'),
											$author$project$UI$Button$Filled,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX])));
								} else {
									return $mdgriffith$elm_ui$Element$none;
								}
							}(),
							_Utils_ap(
								A2(
									$elm$core$List$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.domain;
										},
										$author$project$Page$Topic$viewMessage(model)),
									messages),
								_List_fromArray(
									[
										function () {
										var _v3 = topic.domain.closed_at;
										if (_v3.$ === 'Nothing') {
											return $author$project$Page$Topic$newMessageBox(model);
										} else {
											var closedAt = _v3.a;
											return A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$centerX,
														$mdgriffith$elm_ui$Element$Font$center,
														$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
														$author$project$UI$preventSelect
													]),
												A2(
													$elm$core$List$map,
													$mdgriffith$elm_ui$Element$text,
													function (_v4) {
														var year = _v4.year;
														var month = _v4.month;
														var day = _v4.day;
														var hour = _v4.hour;
														var minute = _v4.minute;
														return _List_fromArray(
															[
																'Обращение было закрыто ',
																A2(
																$elm$core$String$join,
																'.',
																A2(
																	$elm$core$List$map,
																	A2(
																		$elm$core$Basics$composeR,
																		$elm$core$String$fromInt,
																		A2(
																			$elm$core$String$padLeft,
																			2,
																			_Utils_chr('0'))),
																	_List_fromArray(
																		[
																			day,
																			$author$project$Utils$monthToInt(month),
																			year
																		]))),
																' в ',
																A2(
																$elm$core$String$join,
																':',
																A2(
																	$elm$core$List$map,
																	A2(
																		$elm$core$Basics$composeR,
																		$elm$core$String$fromInt,
																		A2(
																			$elm$core$String$padLeft,
																			2,
																			_Utils_chr('0'))),
																	_List_fromArray(
																		[hour, minute])))
															]);
													}(
														A2($justinmimbs$time_extra$Time$Extra$posixToParts, $author$project$Domain$zone, closedAt))));
										}
									}()
									]))))),
				function () {
					var _v5 = model.filePicker;
					switch (_v5.$) {
						case 'Open':
							var file = _v5.a;
							return $elm$core$Maybe$Just(
								A2(
									$author$project$Framework$ModalContent,
									$author$project$Framework$TintedModal(
										toGlobal($author$project$Page$Topic$CloseFilePicker)),
									A2(
										$mdgriffith$elm_ui$Element$map,
										toGlobal,
										A2($author$project$Page$Topic$fileForm, model, file))));
						case 'Closed':
							return $elm$core$Maybe$Nothing;
						default:
							return $elm$core$Maybe$Just(
								A2(
									$author$project$Framework$ModalContent,
									$author$project$Framework$BlockingModal,
									A2($mdgriffith$elm_ui$Element$map, toGlobal, $author$project$UI$Loading$spinner)));
					}
				}());
		} else {
			return _Utils_Tuple2($author$project$UI$Loading$spinner, $elm$core$Maybe$Nothing);
		}
	});
var $author$project$Page$Topic$page = function (toGlobal) {
	return A2(
		$author$project$Framework$Page,
		$author$project$Framework$Protected($author$project$Framework$authenticatedOnly),
		{
			baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
			init: $author$project$Page$Topic$init,
			subs: $elm$core$Basics$always(_List_Nil),
			title: $author$project$Page$Topic$title,
			update: $author$project$Framework$PlainUpdate($author$project$Page$Topic$update),
			view: A2($author$project$Framework$ModalView, toGlobal, $author$project$Page$Topic$view),
			wrapArgs: F2(
				function (base, args) {
					return {device: base.device, session: base.session, topicId: args.topicId};
				})
		});
};
var $author$project$Page$Topics$Closed = {$: 'Closed'};
var $author$project$Page$Topics$GotTopics = function (a) {
	return {$: 'GotTopics', a: a};
};
var $author$project$Page$Topics$TopicNameFieldChanged = function (a) {
	return {$: 'TopicNameFieldChanged', a: a};
};
var $author$project$Page$Topics$defaultTopicNameField = function () {
	var _default = $author$project$UI$Form$Text$default($author$project$Page$Topics$TopicNameFieldChanged);
	return _Utils_update(
		_default,
		{
			check: A2(
				$author$project$UI$Form$simpleCheck,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$String$trim,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$String$length,
						function (len) {
							return len > 0;
						})),
				'Тема не должна быть пустой!'),
			name: 'Тема обращения',
			required: $author$project$UI$Form$Required
		});
}();
var $author$project$Collections$getTopics = function (authHeader) {
	return A3(
		$author$project$API$getAll,
		'topic',
		A2(
			$author$project$API$Get,
			$author$project$Domain$PB$decoderWithID($author$project$Domain$topicDecoder),
			_List_fromArray(
				[
					$author$project$API$Sort(
					_List_fromArray(
						['-created']))
				])),
		_List_fromArray(
			[authHeader]));
};
var $author$project$Page$Topics$init = function (args) {
	return _Utils_Tuple2(
		{device: args.device, session: args.session, showNewTopicForm: $author$project$Page$Topics$Closed, topicNameField: $author$project$Page$Topics$defaultTopicNameField, topics: $author$project$API$Status$Loading},
		function () {
			var _v0 = $author$project$Session$getLocalStorageData(args.session);
			if (_v0.$ === 'Just') {
				if (_v0.a.$ === 'TenantLocalStorage') {
					var authToken = _v0.a.a.authToken;
					return A2(
						$elm$core$Task$attempt,
						$author$project$Page$Topics$GotTopics,
						A2(
							$author$project$Collections$getTopics,
							$author$project$Session$authorizationHeader(authToken),
							_List_Nil));
				} else {
					var authToken = _v0.a.a.authToken;
					return A2(
						$elm$core$Task$attempt,
						$author$project$Page$Topics$GotTopics,
						A2(
							$author$project$Collections$getTopics,
							$author$project$Session$authorizationHeader(authToken),
							_List_fromArray(
								[
									$author$project$API$Expand(
									_List_fromArray(
										['author']))
								])));
				}
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}());
};
var $author$project$Page$Topics$GotCreateTopicResponse = function (a) {
	return {$: 'GotCreateTopicResponse', a: a};
};
var $author$project$Collections$postTopic = function (authHeader) {
	return A4(
		$author$project$API$post,
		'topic',
		A3(
			$author$project$API$Post,
			function (topic) {
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'title',
							$elm$json$Json$Encode$string(topic.title)),
							_Utils_Tuple2(
							'author',
							$elm$json$Json$Encode$string(
								$author$project$API$Resource$toMeta(topic.author)))
						]));
			},
			$author$project$Domain$PB$decoderWithID($author$project$Domain$topicDecoder),
			_List_Nil),
		_List_fromArray(
			[authHeader]),
		_List_Nil);
};
var $author$project$Page$Topics$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'GotTopics':
				if (msg.a.$ === 'Ok') {
					var topics = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								topics: $author$project$API$Status$Loaded(topics)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Debug_todo(
						'Page.Topics',
						{
							start: {line: 118, column: 13},
							end: {line: 118, column: 23}
						})('branch \'GotTopics (Err _)\' not implemented');
				}
			case 'SetShowNewTopicForm':
				var show = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{showNewTopicForm: show, topicNameField: $author$project$Page$Topics$defaultTopicNameField}),
					$elm$core$Platform$Cmd$none);
			case 'TopicNameFieldChanged':
				var specs = msg.a.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{topicNameField: specs}),
					$elm$core$Platform$Cmd$none);
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'CreateNewTopic':
				var _v1 = _Utils_Tuple2(
					$author$project$Session$getLocalStorageData(model.session),
					$author$project$UI$Form$result(model.topicNameField));
				if (((_v1.a.$ === 'Just') && (_v1.a.a.$ === 'TenantLocalStorage')) && (_v1.b.$ === 'WithResult')) {
					var authToken = _v1.a.a.a.authToken;
					var user = _v1.a.a.a.user;
					var title = _v1.b.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$core$Task$attempt,
							$author$project$Page$Topics$GotCreateTopicResponse,
							A2(
								$author$project$Collections$postTopic,
								$author$project$Session$authorizationHeader(authToken),
								{
									author: A2(
										$author$project$API$Resource$from,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.meta;
											},
											function ($) {
												return $.id;
											}),
										user),
									closed_at: $elm$core$Maybe$Nothing,
									created: $elm$time$Time$millisToPosix(0),
									title: title
								})));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (msg.a.$ === 'Ok') {
					var newTopic = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							$author$project$Session$getKey(model.session),
							'/topic/' + newTopic.meta.id));
				} else {
					var errorCode = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{showNewTopicForm: $author$project$Page$Topics$Closed}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Topics$Open = {$: 'Open'};
var $author$project$Page$Topics$SetShowNewTopicForm = function (a) {
	return {$: 'SetShowNewTopicForm', a: a};
};
var $author$project$Page$Topics$error = function (message) {
	return A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$Font$center,
				$mdgriffith$elm_ui$Element$padding(
				$author$project$UI$scaled(-1))
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$text(message)
			]));
};
var $elmcraft$core_extra$Maybe$Extra$isNothing = function (m) {
	if (m.$ === 'Nothing') {
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$Topics$CreateNewTopic = {$: 'CreateNewTopic'};
var $author$project$Page$Topics$NoOp = {$: 'NoOp'};
var $author$project$Page$Topics$newTopicForm = function (model) {
	return A2(
		$author$project$UI$Form$form,
		_List_fromArray(
			[
				$author$project$UI$stopClickPropagation($author$project$Page$Topics$NoOp)
			]),
		A2(
			$author$project$UI$Form$ifValid,
			A3(
				$author$project$UI$Form$withButton,
				true,
				$elm$core$Maybe$Nothing,
				A4(
					$author$project$UI$Button$ButtonSpecs,
					$elm$core$Maybe$Just($author$project$Page$Topics$CreateNewTopic),
					$mdgriffith$elm_ui$Element$text('Создать обращение'),
					$author$project$UI$Button$Filled,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]))),
			A2(
				$author$project$UI$Form$withField,
				model.topicNameField,
				A2($author$project$UI$Form$withTitle, 'Новое обращение', $author$project$UI$Form$empty))));
};
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $author$project$Page$Topics$renderTopic = F2(
	function (_v0, topic) {
		var dateLabel = F2(
			function (icon, posix) {
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-4)),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
									$mdgriffith$elm_ui$Element$centerY
								]),
							A2(
								$author$project$UI$materialIcon,
								icon,
								$author$project$UI$scaled(3))),
							$author$project$UI$Card$dateLabel(posix)
						]));
			});
		return A2(
			$mdgriffith$elm_ui$Element$link,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			{
				label: A2(
					$author$project$UI$Card$contentCard,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$spacing(
											$author$project$UI$scaled(-4))
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$bold,
													$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
													$mdgriffith$elm_ui$Element$centerY
												]),
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text(topic.domain.title)
												])),
											function () {
											var _v1 = topic.domain.author;
											if (_v1.b.$ === 'Loaded') {
												var author = _v1.b.a;
												return A2(
													$mdgriffith$elm_ui$Element$paragraph,
													_List_Nil,
													$elm$core$List$singleton(
														$mdgriffith$elm_ui$Element$text(
															$elm$core$String$trim(
																A2(
																	$elm$core$String$join,
																	' ',
																	_List_fromArray(
																		[author.domain.surname, author.domain.name, author.domain.patronymic]))))));
											} else {
												return $mdgriffith$elm_ui$Element$none;
											}
										}()
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
										]),
									_List_fromArray(
										[
											A2(dateLabel, 'add_circle', topic.domain.created),
											A2(
											$elm$core$Maybe$withDefault,
											$mdgriffith$elm_ui$Element$none,
											A2(
												$elm$core$Maybe$map,
												dateLabel('check_circle'),
												topic.domain.closed_at))
										]))
								]))
						])),
				url: '/topic/' + topic.meta.id
			});
	});
var $author$project$Page$Topics$view = F2(
	function (model, toGlobal) {
		var _v0 = model.topics;
		switch (_v0.$) {
			case 'Loading':
				return _Utils_Tuple2($author$project$UI$Loading$spinner, $elm$core$Maybe$Nothing);
			case 'NotStarted':
				return _Utils_Tuple2(
					$author$project$Page$Topics$error('Что-то пошло не так. Попробуйте перезагрузить страницу!'),
					$elm$core$Maybe$Nothing);
			case 'Failed':
				return _Utils_Tuple2(
					_Debug_todo(
						'Page.Topics',
						{
							start: {line: 175, column: 15},
							end: {line: 175, column: 25}
						})('branch \'Failed _\' not implemented'),
					$elm$core$Maybe$Nothing);
			default:
				var topics = _v0.a;
				var _v1 = A2(
					$elm$core$List$partition,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.domain;
						},
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.closed_at;
							},
							$elmcraft$core_extra$Maybe$Extra$isNothing)),
					topics);
				var open = _v1.a;
				var closed = _v1.b;
				var regularContent = A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
							$mdgriffith$elm_ui$Element$padding(
							$author$project$UI$scaled(-1)),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(1))
						]),
					$elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $author$project$UI$fontsizes.bigger, $mdgriffith$elm_ui$Element$Font$bold]),
									$mdgriffith$elm_ui$Element$text('Открытые обращения'))
								]),
								function () {
								var _v5 = $author$project$Session$getLocalStorageData(model.session);
								if ((_v5.$ === 'Just') && (_v5.a.$ === 'TenantLocalStorage')) {
									return _List_fromArray(
										[
											$author$project$UI$Button$button(
											A4(
												$author$project$UI$Button$ButtonSpecs,
												$elm$core$Maybe$Just(
													$author$project$Page$Topics$SetShowNewTopicForm($author$project$Page$Topics$Open)),
												$mdgriffith$elm_ui$Element$text('Создать обращение'),
												$author$project$UI$Button$Filled,
												_List_fromArray(
													[$mdgriffith$elm_ui$Element$centerX])))
										]);
								} else {
									return _List_Nil;
								}
							}(),
								$elm$core$List$isEmpty(open) ? _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$centerX,
											$mdgriffith$elm_ui$Element$Font$center,
											$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
											$author$project$UI$preventSelect
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('Здесь пока пусто')
										]))
								]) : A2(
								$elm$core$List$map,
								$author$project$Page$Topics$renderTopic(model),
								open),
								$elm$core$List$isEmpty(closed) ? _List_Nil : _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $author$project$UI$fontsizes.bigger, $mdgriffith$elm_ui$Element$Font$bold]),
									$mdgriffith$elm_ui$Element$text('Закрытые обращения'))
								]),
								A2(
								$elm$core$List$map,
								$author$project$Page$Topics$renderTopic(model),
								closed)
							])));
				return _Utils_Tuple2(
					regularContent,
					function () {
						var _v2 = _Utils_Tuple2(
							model.showNewTopicForm,
							$author$project$Session$getLocalStorageData(model.session));
						_v2$2:
						while (true) {
							switch (_v2.a.$) {
								case 'Open':
									if ((_v2.b.$ === 'Just') && (_v2.b.a.$ === 'TenantLocalStorage')) {
										var _v3 = _v2.a;
										return $elm$core$Maybe$Just(
											A2(
												$author$project$Framework$ModalContent,
												$author$project$Framework$TintedModal(
													toGlobal(
														$author$project$Page$Topics$SetShowNewTopicForm($author$project$Page$Topics$Closed))),
												A2(
													$mdgriffith$elm_ui$Element$map,
													toGlobal,
													$author$project$Page$Topics$newTopicForm(model))));
									} else {
										break _v2$2;
									}
								case 'Blocked':
									var _v4 = _v2.a;
									return $elm$core$Maybe$Just(
										A2(
											$author$project$Framework$ModalContent,
											$author$project$Framework$BlockingModal,
											A2($mdgriffith$elm_ui$Element$map, toGlobal, $author$project$UI$Loading$spinner)));
								default:
									break _v2$2;
							}
						}
						return $elm$core$Maybe$Nothing;
					}());
		}
	});
var $author$project$Page$Topics$page = function (toGlobal) {
	return A2(
		$author$project$Framework$Page,
		$author$project$Framework$Protected($author$project$Framework$authenticatedOnly),
		{
			baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
			init: $author$project$Page$Topics$init,
			subs: $elm$core$Basics$always(_List_Nil),
			title: function (_v0) {
				return 'Обращения';
			},
			update: $author$project$Framework$PlainUpdate($author$project$Page$Topics$update),
			view: A2($author$project$Framework$ModalView, toGlobal, $author$project$Page$Topics$view),
			wrapArgs: F2(
				function (args, _v1) {
					return args;
				})
		});
};
var $author$project$Page$VerifyEmail$accessFilter = function (args) {
	var _v0 = $author$project$Session$getViewer(args.session);
	if (_v0.$ === 'Guest') {
		return $author$project$Framework$AccessDenied;
	} else {
		if (_v0.a.b.$ === 'TenantLocalStorage') {
			var _v1 = _v0.a;
			var user = _v1.b.a.user;
			return user.domain.verified ? $author$project$Framework$AccessDenied : $author$project$Framework$AccessGranted;
		} else {
			var _v2 = _v0.a;
			return $author$project$Framework$AccessDenied;
		}
	}
};
var $author$project$Page$VerifyEmail$GotInitialTime = function (a) {
	return {$: 'GotInitialTime', a: a};
};
var $author$project$Page$VerifyEmail$sendAgainCooldown = 60;
var $author$project$Page$VerifyEmail$init = function (args) {
	return _Utils_Tuple2(
		{buttonActive: false, cooldown: $author$project$Page$VerifyEmail$sendAgainCooldown, device: args.device, error: $elm$core$Maybe$Nothing, session: args.session, unlockingTime: $elm$core$Maybe$Nothing, user: args.user},
		A2($elm$core$Task$perform, $author$project$Page$VerifyEmail$GotInitialTime, $elm$time$Time$now));
};
var $author$project$Page$VerifyEmail$title = function (_v0) {
	return 'Подтвердите email!';
};
var $justinmimbs$time_extra$Time$Extra$Second = {$: 'Second'};
var $author$project$Page$VerifyEmail$SentVerificationEmail = function (a) {
	return {$: 'SentVerificationEmail', a: a};
};
var $author$project$Page$VerifyEmail$SendAgainTimerTicked = function (a) {
	return {$: 'SendAgainTimerTicked', a: a};
};
var $author$project$Page$VerifyEmail$sendAgainTimer = A2(
	$elm$core$Task$perform,
	$author$project$Page$VerifyEmail$SendAgainTimerTicked,
	A2(
		$elm$core$Task$andThen,
		function (_v0) {
			return $elm$time$Time$now;
		},
		$elm$core$Process$sleep(1000)));
var $author$project$Page$VerifyEmail$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'ClickedSendAgain':
				var user = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{buttonActive: false, cooldown: $author$project$Page$VerifyEmail$sendAgainCooldown, error: $elm$core$Maybe$Nothing, unlockingTime: $elm$core$Maybe$Nothing}),
					function () {
						var _v1 = user.domain.email;
						if (_v1.$ === 'Just') {
							var email = _v1.a;
							return $elm$http$Http$post(
								{
									body: $elm$http$Http$jsonBody(
										$elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'email',
													$elm$json$Json$Encode$string(email))
												]))),
									expect: $elm$http$Http$expectWhatever($author$project$Page$VerifyEmail$SentVerificationEmail),
									url: '/api/collections/tenant/request-verification'
								});
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 'GotInitialTime':
				var now = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cooldown: $author$project$Page$VerifyEmail$sendAgainCooldown,
							unlockingTime: $elm$core$Maybe$Just(
								A4($justinmimbs$time_extra$Time$Extra$add, $justinmimbs$time_extra$Time$Extra$Second, $author$project$Page$VerifyEmail$sendAgainCooldown, $elm$time$Time$utc, now))
						}),
					$author$project$Page$VerifyEmail$sendAgainTimer);
			case 'SendAgainTimerTicked':
				var now = msg.a;
				var timeLeft = function () {
					var _v2 = _Utils_Tuple2(model.buttonActive, model.unlockingTime);
					if ((!_v2.a) && (_v2.b.$ === 'Just')) {
						var unlocking = _v2.b.a;
						return A4($justinmimbs$time_extra$Time$Extra$diff, $justinmimbs$time_extra$Time$Extra$Second, $elm$time$Time$utc, now, unlocking);
					} else {
						return $author$project$Page$VerifyEmail$sendAgainCooldown;
					}
				}();
				return (timeLeft <= 0) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{buttonActive: true, cooldown: 0, error: $elm$core$Maybe$Nothing, unlockingTime: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{cooldown: timeLeft}),
					$author$project$Page$VerifyEmail$sendAgainTimer);
			default:
				if (msg.a.$ === 'Ok') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{buttonActive: false, cooldown: $author$project$Page$VerifyEmail$sendAgainCooldown, error: $elm$core$Maybe$Nothing, unlockingTime: $elm$core$Maybe$Nothing}),
						A2($elm$core$Task$perform, $author$project$Page$VerifyEmail$GotInitialTime, $elm$time$Time$now));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								buttonActive: true,
								cooldown: 0,
								error: $elm$core$Maybe$Just('Возникла ошибка при отправке письма. Попробуйте ещё раз'),
								unlockingTime: $elm$core$Maybe$Nothing
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$VerifyEmail$ClickedSendAgain = function (a) {
	return {$: 'ClickedSendAgain', a: a};
};
var $author$project$Page$VerifyEmail$sendAgainButton = function (model) {
	return $author$project$UI$Button$button(
		model.buttonActive ? A4(
			$author$project$UI$Button$ButtonSpecs,
			$elm$core$Maybe$Just(
				$author$project$Page$VerifyEmail$ClickedSendAgain(model.user)),
			$mdgriffith$elm_ui$Element$text('Отправить ещё раз'),
			$author$project$UI$Button$Filled,
			_List_fromArray(
				[$author$project$UI$fontsizes.regular])) : A4(
			$author$project$UI$Button$ButtonSpecs,
			$elm$core$Maybe$Nothing,
			$mdgriffith$elm_ui$Element$text('Отправить ещё раз'),
			$author$project$UI$Button$Outline,
			_List_fromArray(
				[$author$project$UI$fontsizes.regular])));
};
var $author$project$Page$VerifyEmail$sendAgainTimerLabel = function (model) {
	return model.buttonActive ? $mdgriffith$elm_ui$Element$none : A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_60),
				$author$project$UI$preventSelect
			]),
		$mdgriffith$elm_ui$Element$text(
			$elm$core$String$fromInt(model.cooldown)));
};
var $author$project$Page$VerifyEmail$view = function (model) {
	var wrapperAttrs = _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$centerY,
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$padding(
			$author$project$UI$scaled(1))
		]);
	var wrappedText = function (attrs) {
		return $mdgriffith$elm_ui$Element$paragraph(
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				attrs));
	};
	var errMessage = function () {
		var _v0 = model.error;
		if (_v0.$ === 'Just') {
			var errorMessage = _v0.a;
			return A2(
				wrappedText,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.error),
						$author$project$UI$fontsizes.regular,
						$mdgriffith$elm_ui$Element$alignRight
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(errorMessage)
					]));
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	}();
	return A2(
		$mdgriffith$elm_ui$Element$el,
		wrapperAttrs,
		A2(
			$mdgriffith$elm_ui$Element$column,
			A2(
				$author$project$UI$withBorder,
				$author$project$UI$colors.transparent,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						$mdgriffith$elm_ui$Element$spacing(
						$author$project$UI$scaled(1)),
						$mdgriffith$elm_ui$Element$padding(
						$author$project$UI$scaled(3)),
						$author$project$UI$shadow,
						$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_0)
					])),
			_List_fromArray(
				[
					A2(
					wrappedText,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary),
							$author$project$UI$fontsizes.big
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Подтвердите свой адрес электронной почты!')
						])),
					A2(
					wrappedText,
					_List_fromArray(
						[$author$project$UI$fontsizes.regular]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('На адрес '),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
								]),
							$mdgriffith$elm_ui$Element$text(
								A2($elm$core$Maybe$withDefault, '', model.user.domain.email))),
							$mdgriffith$elm_ui$Element$text(' должно было прийти письмо для подтверждения.')
						])),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(1)),
							$mdgriffith$elm_ui$Element$alignRight
						]),
					_List_fromArray(
						[
							errMessage,
							$author$project$Page$VerifyEmail$sendAgainTimerLabel(model),
							$author$project$Page$VerifyEmail$sendAgainButton(model)
						]))
				])));
};
var $author$project$Page$VerifyEmail$wrap = F2(
	function (_v0, _v1) {
		var session = _v0.session;
		var device = _v0.device;
		var user = _v1.user;
		return {device: device, session: session, user: user};
	});
var $author$project$Page$VerifyEmail$page = A2(
	$author$project$Framework$Page,
	$author$project$Framework$Protected($author$project$Page$VerifyEmail$accessFilter),
	{
		baseUpdated: $elm$core$Basics$always($elm$core$Maybe$Nothing),
		init: $author$project$Page$VerifyEmail$init,
		subs: $elm$core$Basics$always(_List_Nil),
		title: $author$project$Page$VerifyEmail$title,
		update: $author$project$Framework$PlainUpdate($author$project$Page$VerifyEmail$update),
		view: $author$project$Framework$PlainView($author$project$Page$VerifyEmail$view),
		wrapArgs: $author$project$Page$VerifyEmail$wrap
	});
var $author$project$Page$Waiting$page = $author$project$Framework$pageFromStatic(
	$author$project$Framework$StaticPage(
		{
			title: 'Загрузка',
			view: $elm$core$Basics$always($author$project$UI$Loading$spinner)
		}));
var $author$project$Main$pages = {
	auth: $author$project$Page$Auth$page,
	cart: $author$project$Page$Cart$page,
	confirmEmail: $author$project$Page$Email$EmailVerification$page,
	contacts: $author$project$Page$Contacts$page,
	error: $author$project$Page$Error$page,
	manager: $author$project$Page$Manager$page,
	managerAuth: $author$project$Page$ManagerAuth$page,
	minibuses: $author$project$Page$MinibusRoute$page,
	notification: $author$project$Page$Notification$page,
	notificationList: $author$project$Page$NotificationList$page,
	orders: $author$project$Page$Orders$page,
	resetPassword: $author$project$Page$ResetPassword$page,
	service: $author$project$Page$Service$page,
	serviceCategory: $author$project$Page$ServiceCategory$page,
	sync1C: $author$project$Page$Sync1C$page,
	topic: $author$project$Page$Topic$page($author$project$Main$TopicMsg),
	topics: $author$project$Page$Topics$page($author$project$Main$TopicsMsg),
	user: $author$project$Page$Tenant$page,
	verifyEmail: $author$project$Page$VerifyEmail$page,
	waiting: $author$project$Page$Waiting$page
};
var $author$project$Route$toString = function (route) {
	switch (route.$) {
		case 'Tenant':
			var userId = route.a.userId;
			return $elm$core$Maybe$Just('/tenant/' + userId);
		case 'Auth':
			return $elm$core$Maybe$Just('/auth');
		case 'Notification':
			var state = route.a.state;
			if (state.$ === 'InitViewing') {
				var id = state.b;
				return $elm$core$Maybe$Just('/notification/' + id);
			} else {
				return $elm$core$Maybe$Just('/notification/new');
			}
		case 'NotificationList':
			return $elm$core$Maybe$Just('/');
		case 'Contacts':
			return $elm$core$Maybe$Just('/contacts');
		case 'ServiceCategory':
			var categoryId = route.a.categoryId;
			return $elm$core$Maybe$Just(
				'/service-category/' + A2($elm$core$Maybe$withDefault, '', categoryId));
		case 'Service':
			var serviceID = route.a.serviceID;
			return $elm$core$Maybe$Just('/service/' + serviceID);
		case 'Cart':
			return $elm$core$Maybe$Just('/cart');
		case 'Orders':
			return $elm$core$Maybe$Just('/orders');
		case 'Topic':
			var topicId = route.a.topicId;
			return $elm$core$Maybe$Just('/topic/' + topicId);
		case 'Topics':
			return $elm$core$Maybe$Just('/topic/');
		case 'ManagerAuth':
			return $elm$core$Maybe$Just('/manage/auth');
		case 'Sync1C':
			return $elm$core$Maybe$Just('/manage/sync');
		case 'Manager':
			var managerID = route.a.managerID;
			return $elm$core$Maybe$Just('/manager/' + managerID);
		case 'ConfirmEmail':
			var emailConfirmationToken = route.a.emailConfirmationToken;
			return $elm$core$Maybe$Just('/auth/confirm-verification/' + emailConfirmationToken);
		case 'ResetPassword':
			var token = route.a.token;
			return $elm$core$Maybe$Just(
				(token === '') ? '/auth/reset-password' : ('/auth/reset-password/' + token));
		case 'MinibusRoute':
			var stop = route.a.stop;
			return $elm$core$Maybe$Just(
				function () {
					if (stop.$ === 'Nothing') {
						return '/minibuses';
					} else {
						var stopName = stop.a;
						return '/minibuses/' + stopName;
					}
				}());
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$chroute = F3(
	function (redirectHistory, requestedRoute, oldModel) {
		var currentModel = _Utils_update(
			oldModel,
			{modal: $elm$core$Maybe$Nothing});
		var _v0 = $author$project$Main$extractCommon(currentModel.page);
		var baseModel = _v0.a;
		var common = F4(
			function (page, modelWrapper, msgWrapper, args) {
				var pageType = page.a;
				var f = page.b;
				var _v2 = f.init(
					A2(f.wrapArgs, baseModel, args));
				var model = _v2.a;
				var cmd = _v2.b;
				var expectedPage = A2(modelWrapper, page, model);
				var happyPath = _Utils_Tuple3(
					_Utils_update(
						currentModel,
						{page: expectedPage}),
					A2($elm$core$Platform$Cmd$map, msgWrapper, cmd),
					_List_Nil);
				var withModifiedTrace = function (_v10) {
					var mod = _v10.a;
					var msg = _v10.b;
					var history = _v10.c;
					return _Utils_Tuple3(
						mod,
						msg,
						A2($elm$core$List$cons, expectedPage, history));
				};
				var _v3 = _Utils_Tuple2(
					pageType,
					$author$project$Session$getViewer(model.session));
				if (_v3.a.$ === 'Public') {
					var _v4 = _v3.a;
					return happyPath;
				} else {
					var accessFilter = _v3.a.a;
					var _v5 = accessFilter(
						A2(f.wrapArgs, baseModel, args));
					switch (_v5.$) {
						case 'AccessGranted':
							return happyPath;
						case 'AuthenticationRequired':
							return withModifiedTrace(
								A3(
									$author$project$Main$chroute,
									redirectHistory,
									A2(
										$author$project$Route$Auth,
										{
											next: A2(
												$elm$core$Maybe$withDefault,
												'/',
												$author$project$Route$toString(requestedRoute))
										},
										requestedRoute),
									currentModel));
						case 'AccessDenied':
							return withModifiedTrace(
								A3(
									$author$project$Main$chroute,
									redirectHistory,
									$author$project$Route$Error(
										{code: $author$project$API$Error$Forbidden}),
									currentModel));
						case 'UnexpectedError':
							return withModifiedTrace(
								A3(
									$author$project$Main$chroute,
									redirectHistory,
									$author$project$Route$Error(
										{
											code: $author$project$API$Error$Bug('unexpected error handling access filter')
										}),
									currentModel));
						case 'VerificationRequired':
							var _v6 = $author$project$Session$getViewer(baseModel.session);
							if (_v6.$ === 'Authenticated') {
								if (_v6.a.b.$ === 'TenantLocalStorage') {
									var _v7 = _v6.a;
									var user = _v7.b.a.user;
									return withModifiedTrace(
										A3(
											$author$project$Main$chroute,
											redirectHistory,
											A2(
												$author$project$Route$VerifyEmail,
												{user: user},
												requestedRoute),
											currentModel));
								} else {
									var _v8 = _v6.a;
									return withModifiedTrace(
										A3(
											$author$project$Main$chroute,
											redirectHistory,
											$author$project$Route$Error(
												{
													code: $author$project$API$Error$Bug('unexpected \'verification required\' access filter response for a manager')
												}),
											currentModel));
								}
							} else {
								return withModifiedTrace(
									A3(
										$author$project$Main$chroute,
										redirectHistory,
										A2(
											$author$project$Route$Auth,
											{
												next: A2(
													$elm$core$Maybe$withDefault,
													'/',
													$author$project$Route$toString(requestedRoute))
											},
											requestedRoute),
										currentModel));
							}
						case 'Skip':
							switch (requestedRoute.$) {
								case 'Auth':
									var next = requestedRoute.b;
									return withModifiedTrace(
										A3($author$project$Main$chroute, redirectHistory, next, currentModel));
								case 'VerifyEmail':
									var next = requestedRoute.b;
									return withModifiedTrace(
										A3($author$project$Main$chroute, redirectHistory, next, currentModel));
								default:
									return withModifiedTrace(
										A3(
											$author$project$Main$chroute,
											redirectHistory,
											$author$project$Route$Error(
												{
													code: $author$project$API$Error$Bug('FW.Skip for unexpected route')
												}),
											currentModel));
							}
						default:
							return withModifiedTrace(
								A3(
									$author$project$Main$chroute,
									redirectHistory,
									A2(
										$author$project$Route$ManagerAuth,
										{
											next: A2(
												$elm$core$Maybe$withDefault,
												'/',
												$author$project$Route$toString(requestedRoute))
										},
										requestedRoute),
									currentModel));
					}
				}
			});
		switch (requestedRoute.$) {
			case 'Error':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.error, $author$project$Main$Error, $elm$core$Basics$never, args);
			case 'Auth':
				var args = requestedRoute.a;
				var next = requestedRoute.b;
				return A4(
					common,
					$author$project$Main$pages.auth,
					$author$project$Main$Auth(next),
					$author$project$Main$AuthMsg,
					args);
			case 'VerifyEmail':
				var args = requestedRoute.a;
				var next = requestedRoute.b;
				return A4(
					common,
					$author$project$Main$pages.verifyEmail,
					$author$project$Main$VerifyEmail(next),
					$author$project$Main$VerifyEmailMsg,
					args);
			case 'Tenant':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.user, $author$project$Main$Tenant, $author$project$Main$TenantMsg, args);
			case 'Notification':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.notification, $author$project$Main$Notification, $author$project$Main$NotificationMsg, args);
			case 'NotificationList':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.notificationList, $author$project$Main$NotificationList, $author$project$Main$NotificationListMsg, args);
			case 'Contacts':
				return A4(
					common,
					$author$project$Main$pages.contacts,
					$author$project$Main$Contacts,
					$elm$core$Basics$never,
					{});
			case 'ServiceCategory':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.serviceCategory, $author$project$Main$ServiceCategory, $author$project$Main$ServiceCategoryMsg, args);
			case 'Service':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.service, $author$project$Main$Service, $author$project$Main$ServiceMsg, args);
			case 'Cart':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.cart, $author$project$Main$Cart, $author$project$Main$CartMsg, args);
			case 'Orders':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.orders, $author$project$Main$Orders, $author$project$Main$OrdersMsg, args);
			case 'Topic':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.topic, $author$project$Main$Topic, $author$project$Main$TopicMsg, args);
			case 'Topics':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.topics, $author$project$Main$Topics, $author$project$Main$TopicsMsg, args);
			case 'ManagerAuth':
				var args = requestedRoute.a;
				var next = requestedRoute.b;
				return A4(
					common,
					$author$project$Main$pages.managerAuth,
					$author$project$Main$ManagerAuth(next),
					$author$project$Main$ManagerAuthMsg,
					args);
			case 'Sync1C':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.sync1C, $author$project$Main$Sync1C, $author$project$Main$Sync1CMsg, args);
			case 'Manager':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.manager, $author$project$Main$Manager, $author$project$Main$ManagerMsg, args);
			case 'ConfirmEmail':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.confirmEmail, $author$project$Main$EmailConfirm, $author$project$Main$ConfirmEmailMsg, args);
			case 'ResetPassword':
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.resetPassword, $author$project$Main$ResetPassword, $author$project$Main$ResetPasswordMsg, args);
			default:
				var args = requestedRoute.a;
				return A4(common, $author$project$Main$pages.minibuses, $author$project$Main$MinibusRoute, $author$project$Main$MinibusRouteMsg, args);
		}
	});
var $mdgriffith$elm_ui$Element$BigDesktop = {$: 'BigDesktop'};
var $mdgriffith$elm_ui$Element$Desktop = {$: 'Desktop'};
var $mdgriffith$elm_ui$Element$Landscape = {$: 'Landscape'};
var $mdgriffith$elm_ui$Element$Tablet = {$: 'Tablet'};
var $mdgriffith$elm_ui$Element$classifyDevice = function (window) {
	return {
		_class: function () {
			var shortSide = A2($elm$core$Basics$min, window.width, window.height);
			var longSide = A2($elm$core$Basics$max, window.width, window.height);
			return (shortSide < 600) ? $mdgriffith$elm_ui$Element$Phone : ((longSide <= 1200) ? $mdgriffith$elm_ui$Element$Tablet : (((longSide > 1200) && (longSide <= 1920)) ? $mdgriffith$elm_ui$Element$Desktop : $mdgriffith$elm_ui$Element$BigDesktop));
		}(),
		orientation: (_Utils_cmp(window.width, window.height) < 0) ? $mdgriffith$elm_ui$Element$Portrait : $mdgriffith$elm_ui$Element$Landscape
	};
};
var $author$project$Session$decodeLocalStorage = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Session$LocalStorage,
	A2(
		$elm$json$Json$Decode$field,
		'time',
		A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int)),
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A3(
				$elm$json$Json$Decode$map2,
				F2(
					function (a, m) {
						return $author$project$Session$ManagerLocalStorage(
							{authToken: a, manager: m});
					}),
				A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$field,
					'manager',
					$author$project$Domain$PB$decoderWithID($author$project$Domain$managerDecoder))),
				A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, u, c) {
						return $author$project$Session$TenantLocalStorage(
							{authToken: a, cart: c, user: u});
					}),
				A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$field,
					'user',
					$author$project$Domain$PB$decoderWithID($author$project$Domain$tenantDecoder)),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$withDefault(_List_Nil),
					$elm$json$Json$Decode$maybe(
						A2(
							$elm$json$Json$Decode$field,
							'cart',
							$elm$json$Json$Decode$list($author$project$Domain$serviceRequestDecoder)))))
			])));
var $author$project$PopUp$Manager = function (a) {
	return {$: 'Manager', a: a};
};
var $author$project$PopUp$empty = $author$project$PopUp$Manager(
	{displayed: _List_Nil, lastID: 0, popUps: $elm$core$Dict$empty});
var $author$project$Websocket$Manager = F2(
	function (websockets, opening) {
		return {opening: opening, websockets: websockets};
	});
var $author$project$Websocket$emptyManager = A2($author$project$Websocket$Manager, $elm$core$Dict$empty, $elm$core$Dict$empty);
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $author$project$UI$Navbar$init = function (args) {
	return _Utils_Tuple2(
		{device: args.device, session: args.session, state: $author$project$UI$Navbar$Closed},
		$elm$core$Platform$Cmd$none);
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {frag: frag, params: params, unvisited: unvisited, value: value, visited: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.unvisited;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.value);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.value);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 'Nothing') {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 'Nothing') {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 'Nothing') {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 'Nothing') {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0.a;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.path),
					$elm$url$Url$Parser$prepareQuery(url.query),
					url.fragment,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$Cart = function (a) {
	return {$: 'Cart', a: a};
};
var $author$project$Route$ConfirmEmail = function (a) {
	return {$: 'ConfirmEmail', a: a};
};
var $author$project$Route$Contacts = {$: 'Contacts'};
var $author$project$Page$Email$EmailVerification$InitArgs = function (emailConfirmationToken) {
	return {emailConfirmationToken: emailConfirmationToken};
};
var $author$project$Page$Manager$InitArgs = function (managerID) {
	return {managerID: managerID};
};
var $author$project$Page$MinibusRoute$InitArgs = function (stop) {
	return {stop: stop};
};
var $author$project$Page$Notification$InitArgs = function (state) {
	return {state: state};
};
var $author$project$Page$ResetPassword$InitArgs = function (token) {
	return {token: token};
};
var $author$project$Page$Service$InitArgs = function (serviceID) {
	return {serviceID: serviceID};
};
var $author$project$Page$ServiceCategory$InitArgs = function (categoryId) {
	return {categoryId: categoryId};
};
var $author$project$Page$Tenant$InitArgs = function (userId) {
	return {userId: userId};
};
var $author$project$Page$Topic$InitArgs = function (topicId) {
	return {topicId: topicId};
};
var $author$project$Page$Notification$InitNew = {$: 'InitNew'};
var $author$project$Page$Notification$InitViewing = F2(
	function (a, b) {
		return {$: 'InitViewing', a: a, b: b};
	});
var $author$project$Route$Manager = function (a) {
	return {$: 'Manager', a: a};
};
var $author$project$Route$MinibusRoute = function (a) {
	return {$: 'MinibusRoute', a: a};
};
var $author$project$Route$Notification = function (a) {
	return {$: 'Notification', a: a};
};
var $author$project$Route$NotificationList = function (a) {
	return {$: 'NotificationList', a: a};
};
var $author$project$Route$Orders = function (a) {
	return {$: 'Orders', a: a};
};
var $author$project$Route$ResetPassword = function (a) {
	return {$: 'ResetPassword', a: a};
};
var $author$project$Route$Service = function (a) {
	return {$: 'Service', a: a};
};
var $author$project$Route$ServiceCategory = function (a) {
	return {$: 'ServiceCategory', a: a};
};
var $author$project$Route$Sync1C = function (a) {
	return {$: 'Sync1C', a: a};
};
var $author$project$Route$Tenant = function (a) {
	return {$: 'Tenant', a: a};
};
var $author$project$Route$Topic = function (a) {
	return {$: 'Topic', a: a};
};
var $author$project$Route$Topics = function (a) {
	return {$: 'Topics', a: a};
};
var $elm$url$Url$Parser$Parser = function (a) {
	return {$: 'Parser', a: a};
};
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.visited;
		var unvisited = _v0.unvisited;
		var params = _v0.params;
		var frag = _v0.frag;
		var value = _v0.value;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0.a;
		return $elm$url$Url$Parser$Parser(
			function (_v1) {
				var visited = _v1.visited;
				var unvisited = _v1.unvisited;
				var params = _v1.params;
				var frag = _v1.frag;
				var value = _v1.value;
				return A2(
					$elm$core$List$map,
					$elm$url$Url$Parser$mapState(value),
					parseArg(
						A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
			});
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return $elm$url$Url$Parser$Parser(
		function (state) {
			return A2(
				$elm$core$List$concatMap,
				function (_v0) {
					var parser = _v0.a;
					return parser(state);
				},
				parsers);
		});
};
var $elm$url$Url$Parser$s = function (str) {
	return $elm$url$Url$Parser$Parser(
		function (_v0) {
			var visited = _v0.visited;
			var unvisited = _v0.unvisited;
			var params = _v0.params;
			var frag = _v0.frag;
			var value = _v0.value;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				return _Utils_eq(next, str) ? _List_fromArray(
					[
						A5(
						$elm$url$Url$Parser$State,
						A2($elm$core$List$cons, next, visited),
						rest,
						params,
						frag,
						value)
					]) : _List_Nil;
			}
		});
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0.a;
		var parseAfter = _v1.a;
		return $elm$url$Url$Parser$Parser(
			function (state) {
				return A2(
					$elm$core$List$concatMap,
					parseAfter,
					parseBefore(state));
			});
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return $elm$url$Url$Parser$Parser(
			function (_v0) {
				var visited = _v0.visited;
				var unvisited = _v0.unvisited;
				var params = _v0.params;
				var frag = _v0.frag;
				var value = _v0.value;
				if (!unvisited.b) {
					return _List_Nil;
				} else {
					var next = unvisited.a;
					var rest = unvisited.b;
					var _v2 = stringToSomething(next);
					if (_v2.$ === 'Just') {
						var nextValue = _v2.a;
						return _List_fromArray(
							[
								A5(
								$elm$url$Url$Parser$State,
								A2($elm$core$List$cons, next, visited),
								rest,
								params,
								frag,
								value(nextValue))
							]);
					} else {
						return _List_Nil;
					}
				}
			});
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$top = $elm$url$Url$Parser$Parser(
	function (state) {
		return _List_fromArray(
			[state]);
	});
var $author$project$Route$routeParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$NotificationList(
				{}),
			$elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$author$project$Route$Auth,
				{next: '/'},
				$author$project$Route$NotificationList(
					{})),
			$elm$url$Url$Parser$s('auth')),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeR, $author$project$Page$Tenant$InitArgs, $author$project$Route$Tenant),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('tenant'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Notification(
				$author$project$Page$Notification$InitArgs($author$project$Page$Notification$InitNew)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('notification'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Page$Notification$InitViewing($elm$core$Maybe$Nothing),
				A2($elm$core$Basics$composeR, $author$project$Page$Notification$InitArgs, $author$project$Route$Notification)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('notification'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Contacts,
			$elm$url$Url$Parser$s('contacts')),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$Just,
				A2($elm$core$Basics$composeR, $author$project$Page$ServiceCategory$InitArgs, $author$project$Route$ServiceCategory)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('service-category'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ServiceCategory(
				$author$project$Page$ServiceCategory$InitArgs($elm$core$Maybe$Nothing)),
			$elm$url$Url$Parser$s('service-category')),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeR, $author$project$Page$Service$InitArgs, $author$project$Route$Service),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('service'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Cart(
				{}),
			$elm$url$Url$Parser$s('cart')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Orders(
				{}),
			$elm$url$Url$Parser$s('orders')),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeR, $author$project$Page$Topic$InitArgs, $author$project$Route$Topic),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('topic'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Topics(
				{}),
			$elm$url$Url$Parser$s('topic')),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$author$project$Route$ManagerAuth,
				{next: '/'},
				$author$project$Route$NotificationList(
					{})),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('manage'),
				$elm$url$Url$Parser$s('auth'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Sync1C(
				{}),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('manage'),
				$elm$url$Url$Parser$s('sync'))),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeR, $author$project$Page$Manager$InitArgs, $author$project$Route$Manager),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('manager'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeR, $author$project$Page$Email$EmailVerification$InitArgs, $author$project$Route$ConfirmEmail),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('auth'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('confirm-verification'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeR, $author$project$Page$ResetPassword$InitArgs, $author$project$Route$ResetPassword),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('auth'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('reset-password'),
					$elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ResetPassword(
				$author$project$Page$ResetPassword$InitArgs('')),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('auth'),
				$elm$url$Url$Parser$s('reset-password'))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$Just,
				A2($elm$core$Basics$composeR, $author$project$Page$MinibusRoute$InitArgs, $author$project$Route$MinibusRoute)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('minibuses'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$MinibusRoute(
				$author$project$Page$MinibusRoute$InitArgs($elm$core$Maybe$Nothing)),
			$elm$url$Url$Parser$s('minibuses'))
		]));
var $author$project$Route$parse = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Route$Error(
			{code: $author$project$API$Error$NotFound}),
		A2($elm$url$Url$Parser$parse, $author$project$Route$routeParser, url));
};
var $author$project$Session$sync = F2(
	function (_v0, storage) {
		var key = _v0.a;
		if (storage.$ === 'Just') {
			var localStorage = storage.a;
			return A2(
				$author$project$Session$Session,
				key,
				$author$project$Session$Authenticated(localStorage));
		} else {
			return A2($author$project$Session$Session, key, $author$project$Session$Guest);
		}
	});
var $author$project$Main$init = F3(
	function (state, url, key) {
		var guestSession = A2($author$project$Session$Session, key, $author$project$Session$Guest);
		var getDevice = A2(
			$elm$core$Task$perform,
			$author$project$Main$DeviceClassified,
			A2(
				$elm$core$Task$map,
				function (v) {
					return $mdgriffith$elm_ui$Element$classifyDevice(
						{
							height: $elm$core$Basics$round(v.viewport.height),
							width: $elm$core$Basics$round(v.viewport.width)
						});
				},
				$elm$browser$Browser$Dom$getViewport));
		var defaultDevice = A2($mdgriffith$elm_ui$Element$Device, $mdgriffith$elm_ui$Element$Phone, $mdgriffith$elm_ui$Element$Portrait);
		var _v0 = function () {
			var _v1 = $author$project$UI$Navbar$init(
				{device: defaultDevice, session: guestSession, state: $author$project$UI$Navbar$Closed});
			var navbar = _v1.a;
			var navbarMsg = _v1.b;
			var _v2 = A2($elm$json$Json$Decode$decodeValue, $author$project$Session$decodeLocalStorage, state);
			if (_v2.$ === 'Err') {
				return _Utils_Tuple2(
					A5(
						$author$project$Main$Model,
						A4(
							$author$project$Main$Waiting,
							$author$project$Route$parse(url),
							$author$project$Main$EndOnSessionRefreshed,
							$author$project$Main$pages.waiting,
							{device: defaultDevice, session: guestSession}),
						navbar,
						$elm$core$Maybe$Nothing,
						$author$project$Websocket$emptyManager,
						$author$project$PopUp$empty),
					$elmcraft$core_extra$Cmd$Extra$perform(
						$author$project$Main$EffectMsg($author$project$Framework$ClearLocalStorage)));
			} else {
				var localStorage = _v2.a;
				var lsData = localStorage.b;
				var newSession = A2(
					$author$project$Session$sync,
					guestSession,
					$elm$core$Maybe$Just(localStorage));
				var refreshSession = A2(
					$elm$core$Task$attempt,
					$author$project$Main$SessionRefreshed,
					A2(
						$author$project$Session$refresh,
						newSession,
						function () {
							if (lsData.$ === 'TenantLocalStorage') {
								var authToken = lsData.a.authToken;
								return authToken;
							} else {
								var authToken = lsData.a.authToken;
								return authToken;
							}
						}()));
				var empty = A5(
					$author$project$Main$Model,
					A4(
						$author$project$Main$Waiting,
						$author$project$Route$parse(url),
						$author$project$Main$EndOnSessionRefreshed,
						$author$project$Main$pages.waiting,
						{device: defaultDevice, session: guestSession}),
					navbar,
					$elm$core$Maybe$Nothing,
					$author$project$Websocket$emptyManager,
					$author$project$PopUp$empty);
				var _v3 = A3(
					$author$project$Main$chroute,
					_List_Nil,
					$author$project$Route$parse(url),
					empty);
				var candidateModel = _v3.a;
				var candidateCmd = _v3.b;
				var redirects = _v3.c;
				if (!redirects.b) {
					return _Utils_Tuple2(
						candidateModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[candidateCmd, refreshSession])));
				} else {
					return _Utils_Tuple2(empty, refreshSession);
				}
			}
		}();
		var model = _v0.a;
		var routeCmd = _v0.b;
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[routeCmd, getDevice])));
	});
var $author$project$Main$CleanUpWebSockets = function (a) {
	return {$: 'CleanUpWebSockets', a: a};
};
var $author$project$Main$GotWebsocketMessage = function (a) {
	return {$: 'GotWebsocketMessage', a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 'Every', a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {processes: processes, taggers: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 'Nothing') {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.processes;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(_Utils_Tuple0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.taggers);
		if (_v0.$ === 'Nothing') {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Websocket$cleanUpSub = $elm$time$Time$every(60 * 1000);
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$browser$Browser$Events$Window = {$: 'Window'};
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 'MySub', a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {pids: pids, subs: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (node.$ === 'Document') {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {event: event, key: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (node.$ === 'Document') {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.pids,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.key;
		var event = _v0.event;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.subs);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		$elm$browser$Browser$Events$Window,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$Websocket$wsIn = _Platform_incomingPort('wsIn', $elm$json$Json$Decode$value);
var $author$project$Main$subscriptions = function (_v0) {
	var page = _v0.page;
	return $elm$core$Platform$Sub$batch(
		_Utils_ap(
			_List_fromArray(
				[
					$elm$browser$Browser$Events$onResize(
					F2(
						function (w, h) {
							return $author$project$Main$DeviceClassified(
								$mdgriffith$elm_ui$Element$classifyDevice(
									{height: h, width: w}));
						})),
					$author$project$Websocket$wsIn($author$project$Main$GotWebsocketMessage),
					$author$project$Websocket$cleanUpSub($author$project$Main$CleanUpWebSockets)
				]),
			function () {
				if (page.$ === 'Notification') {
					var _v2 = page.a;
					var subs = _v2.b.subs;
					var model = page.b;
					return A2(
						$elm$core$List$map,
						$elm$core$Platform$Sub$map($author$project$Main$NotificationMsg),
						subs(model));
				} else {
					return _List_Nil;
				}
			}()));
};
var $author$project$Main$GotLocalStorage = function (a) {
	return {$: 'GotLocalStorage', a: a};
};
var $author$project$Main$NavbarMsg = function (a) {
	return {$: 'NavbarMsg', a: a};
};
var $author$project$Main$PopUpMsg = function (a) {
	return {$: 'PopUpMsg', a: a};
};
var $author$project$Main$WebsocketInternalMessage = function (a) {
	return {$: 'WebsocketInternalMessage', a: a};
};
var $elmcraft$core_extra$Cmd$Extra$add = F2(
	function (newCmd, _v0) {
		var model = _v0.a;
		var prevCmd = _v0.b;
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[newCmd, prevCmd])));
	});
var $author$project$PopUp$ClosePopUp = function (a) {
	return {$: 'ClosePopUp', a: a};
};
var $author$project$PopUp$PopUp = F3(
	function (a, b, c) {
		return {$: 'PopUp', a: a, b: b, c: c};
	});
var $author$project$PopUp$popUpDuration = 1000 * 30;
var $author$project$PopUp$add = F3(
	function (content, importance, _v0) {
		var popUps = _v0.a.popUps;
		var displayed = _v0.a.displayed;
		var lastID = _v0.a.lastID;
		return _Utils_Tuple2(
			$author$project$PopUp$Manager(
				{
					displayed: _Utils_ap(
						displayed,
						_List_fromArray(
							[lastID + 1])),
					lastID: lastID + 1,
					popUps: A3(
						$elm$core$Dict$insert,
						lastID + 1,
						A3($author$project$PopUp$PopUp, lastID + 1, content, importance),
						popUps)
				}),
			A2(
				$elm$core$Task$map,
				function (_v1) {
					return $author$project$PopUp$ClosePopUp(lastID);
				},
				$elm$core$Process$sleep($author$project$PopUp$popUpDuration)));
	});
var $author$project$Main$baseUpdateToMsg = function (page) {
	switch (page.$) {
		case 'Auth':
			var _v1 = page.b;
			var baseUpdated = _v1.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$AuthMsg));
		case 'VerifyEmail':
			var _v2 = page.b;
			var baseUpdated = _v2.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$VerifyEmailMsg));
		case 'Error':
			var _v3 = page.a;
			var baseUpdated = _v3.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($elm$core$Basics$never));
		case 'Tenant':
			var _v4 = page.a;
			var baseUpdated = _v4.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$TenantMsg));
		case 'Waiting':
			var _v5 = page.c;
			var baseUpdated = _v5.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($elm$core$Basics$never));
		case 'Notification':
			var _v6 = page.a;
			var baseUpdated = _v6.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$NotificationMsg));
		case 'NotificationList':
			var _v7 = page.a;
			var baseUpdated = _v7.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$NotificationListMsg));
		case 'Contacts':
			var _v8 = page.a;
			var baseUpdated = _v8.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($elm$core$Basics$never));
		case 'ServiceCategory':
			var _v9 = page.a;
			var baseUpdated = _v9.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$ServiceCategoryMsg));
		case 'Service':
			var _v10 = page.a;
			var baseUpdated = _v10.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$ServiceMsg));
		case 'Cart':
			var _v11 = page.a;
			var baseUpdated = _v11.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$CartMsg));
		case 'Orders':
			var _v12 = page.a;
			var baseUpdated = _v12.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$OrdersMsg));
		case 'Topic':
			var _v13 = page.a;
			var baseUpdated = _v13.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$TopicMsg));
		case 'Topics':
			var _v14 = page.a;
			var baseUpdated = _v14.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$TopicsMsg));
		case 'ManagerAuth':
			var _v15 = page.b;
			var baseUpdated = _v15.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$ManagerAuthMsg));
		case 'Sync1C':
			var _v16 = page.a;
			var baseUpdated = _v16.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$Sync1CMsg));
		case 'Manager':
			var _v17 = page.a;
			var baseUpdated = _v17.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$ManagerMsg));
		case 'EmailConfirm':
			var _v18 = page.a;
			var baseUpdated = _v18.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$ConfirmEmailMsg));
		case 'ResetPassword':
			var _v19 = page.a;
			var baseUpdated = _v19.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$ResetPasswordMsg));
		default:
			var _v20 = page.a;
			var baseUpdated = _v20.b.baseUpdated;
			return A2(
				$elm$core$Basics$composeR,
				baseUpdated,
				$elm$core$Maybe$map($author$project$Main$MinibusRouteMsg));
	}
};
var $author$project$Main$changeRouteTo = F2(
	function (route, model) {
		var _v0 = A3($author$project$Main$chroute, _List_Nil, route, model);
		var newModel = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(newModel, cmd);
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Websocket$cleanUp = F2(
	function (now, manager) {
		var expiredIfBefore = A2($elm$time$Time$toMillis, $author$project$Domain$zone, now) - ((1000 * 60) * 2);
		var expired = function (time) {
			return _Utils_cmp(
				A2($elm$time$Time$toMillis, $author$project$Domain$zone, time),
				expiredIfBefore) < 0;
		};
		return _Utils_update(
			manager,
			{
				websockets: A2(
					$elm$core$Dict$filter,
					F2(
						function (_v0, _v1) {
							var lastHealthCheck = _v1.b;
							return expired(lastHealthCheck);
						}),
					manager.websockets)
			});
	});
var $author$project$Session$setLocalStorage = _Platform_outgoingPort('setLocalStorage', $elm$core$Basics$identity);
var $author$project$Session$clearLocalStorage = $author$project$Session$setLocalStorage($elm$json$Json$Encode$null);
var $author$project$Websocket$innerUpdate = F2(
	function (message, manager) {
		var url = message.a;
		var id = message.b;
		var now = message.c;
		var _v1 = A2($elm$core$Dict$get, url, manager.opening);
		if (_v1.$ === 'Just') {
			var _v2 = _v1.a;
			var onOpen = _v2.a;
			var onReceive = _v2.b;
			var onClose = _v2.c;
			return _Utils_Tuple2(
				_Utils_update(
					manager,
					{
						opening: A2($elm$core$Dict$remove, url, manager.opening),
						websockets: A3(
							$elm$core$Dict$insert,
							id,
							_Utils_Tuple3(onReceive, now, onClose),
							manager.websockets)
					}),
				$elmcraft$core_extra$Cmd$Extra$perform(
					onOpen(id)));
		} else {
			return _Utils_Tuple2(manager, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Session$logOut = function (_v0) {
	var key = _v0.a;
	return A2($author$project$Session$Session, key, $author$project$Session$Guest);
};
var $author$project$Domain$encodeManager = function (manager) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'email',
			$elm$json$Json$Encode$string(
				A2($elm$core$Maybe$withDefault, '', manager.email))),
			_Utils_Tuple2(
			'name',
			$elm$json$Json$Encode$string(manager.name)),
			_Utils_Tuple2(
			'role_comment',
			$elm$json$Json$Encode$string(manager.role))
		]);
};
var $author$project$Domain$buildingEncoder = function (building) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'address',
			$elm$json$Json$Encode$string(building.address)),
			_Utils_Tuple2(
			'floors',
			$elm$json$Json$Encode$int(building.numberOfFloors)),
			_Utils_Tuple2(
			'sections',
			$elm$json$Json$Encode$int(building.numberOfSections))
		]);
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Domain$PB$resourceEncoderFields = F4(
	function (fieldName, _v0, metaValueEncoder, bodyValueEncoder) {
		var metaValue = _v0.a;
		var bodyStatus = _v0.b;
		return _Utils_Tuple2(
			_Utils_Tuple2(
				fieldName,
				metaValueEncoder(metaValue)),
			function () {
				if (bodyStatus.$ === 'Loaded') {
					var bodyValue = bodyStatus.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							fieldName,
							bodyValueEncoder(bodyValue))
						]);
				} else {
					return _List_Nil;
				}
			}());
	});
var $author$project$Domain$billStatusEncoder = function (status) {
	return $elm$json$Json$Encode$string(
		function () {
			switch (status.$) {
				case 'Created':
					return 'created';
				case 'Paid':
					return 'paid';
				case 'Cancelled':
					return 'cancelled';
				case 'Unconfirmed':
					return 'unconfirmed';
				default:
					return 'rejected';
			}
		}());
};
var $author$project$Utils$encodeFilePath = F2(
	function (field, url) {
		var qux = function (f) {
			var _v2 = A2($elm$core$String$split, '/', f);
			if (((((((((_v2.b && (_v2.a === '')) && _v2.b.b) && (_v2.b.a === 'api')) && _v2.b.b.b) && (_v2.b.b.a === 'files')) && _v2.b.b.b.b) && _v2.b.b.b.b.b) && _v2.b.b.b.b.b.b) && (!_v2.b.b.b.b.b.b.b)) {
				var _v3 = _v2.b;
				var _v4 = _v3.b;
				var _v5 = _v4.b;
				var colId = _v5.a;
				var _v6 = _v5.b;
				var recId = _v6.a;
				var _v7 = _v6.b;
				var fileId = _v7.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple3(colId, recId, fileId));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var _v0 = qux(url);
		if (_v0.$ === 'Just') {
			var _v1 = _v0.a;
			var col = _v1.a;
			var rec = _v1.b;
			var file = _v1.c;
			return _List_fromArray(
				[
					_Utils_Tuple2(
					'collectionId',
					$elm$json$Json$Encode$string(col)),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(rec)),
					_Utils_Tuple2(
					field,
					$elm$json$Json$Encode$string(file))
				]);
		} else {
			return _List_Nil;
		}
	});
var $author$project$Domain$utilityBillEncoder = function (_v0) {
	var flatId = _v0.a.flatId;
	var file = _v0.a.file;
	var info = _v0.a.info;
	return _Utils_ap(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'flat',
				$elm$json$Json$Encode$string(flatId)),
				_Utils_Tuple2(
				'total_cost',
				$elm$json$Json$Encode$float(info.totalCost)),
				_Utils_Tuple2(
				'status',
				$author$project$Domain$billStatusEncoder(info.status)),
				_Utils_Tuple2(
				'issued',
				$author$project$Utils$encodeTimestamp(info.issued)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(info.description)),
				_Utils_Tuple2(
				'qr',
				$elm$json$Json$Encode$string(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							$elm$core$List$reverse(
								A2($elm$core$String$split, '/', info.qrCodeLink))))))
			]),
		A2($author$project$Utils$encodeFilePath, 'bill', file));
};
var $author$project$Domain$flatEncoder = function (flat) {
	var _v0 = A4(
		$author$project$Domain$PB$resourceEncoderFields,
		'building',
		flat.building,
		$elm$json$Json$Encode$string,
		$author$project$Domain$PB$encodeWithID($author$project$Domain$buildingEncoder));
	var building = _v0.a;
	var expandBuilding = _v0.b;
	return _List_fromArray(
		[
			building,
			_Utils_Tuple2(
			'section',
			$elm$json$Json$Encode$int(flat.section)),
			_Utils_Tuple2(
			'floor',
			$elm$json$Json$Encode$int(flat.floor)),
			_Utils_Tuple2(
			'number',
			$elm$json$Json$Encode$int(flat.number)),
			_Utils_Tuple2(
			'tenants',
			A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, flat.tenantIds)),
			_Utils_Tuple2(
			'account_number',
			$elm$json$Json$Encode$int(flat.accountNumber)),
			_Utils_Tuple2(
			'balance',
			$elm$json$Json$Encode$float(flat.balance)),
			_Utils_Tuple2(
			'expand',
			$elm$json$Json$Encode$object(
				_Utils_ap(
					expandBuilding,
					_List_fromArray(
						[
							_Utils_Tuple2(
							'utilityBills',
							A2(
								$elm$core$Maybe$withDefault,
								$elm$json$Json$Encode$null,
								A2(
									$elm$core$Maybe$map,
									$elm$json$Json$Encode$list(
										$author$project$Domain$PB$encodeWithID($author$project$Domain$utilityBillEncoder)),
									flat.utilityBills)))
						]))))
		]);
};
var $author$project$Domain$tenantEncoder = function (user) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'name',
			$elm$json$Json$Encode$string(user.name)),
			_Utils_Tuple2(
			'surname',
			$elm$json$Json$Encode$string(user.surname)),
			_Utils_Tuple2(
			'patronymic',
			$elm$json$Json$Encode$string(user.patronymic)),
			_Utils_Tuple2(
			'email',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, user.email))),
			_Utils_Tuple2(
			'flat',
			A2(
				$elm$json$Json$Encode$list,
				$elm$json$Json$Encode$string,
				$author$project$API$Resource$toMeta(user.flat))),
			_Utils_Tuple2(
			'verified',
			$elm$json$Json$Encode$bool(user.verified)),
			_Utils_Tuple2(
			'expand',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'flat',
						A2(
							$elm$json$Json$Encode$list,
							$author$project$Domain$PB$encodeWithID($author$project$Domain$flatEncoder),
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								$author$project$API$Resource$toMaybe(user.flat))))
					])))
		]);
};
var $author$project$Session$encodeLocalStorage = function (_v0) {
	var time = _v0.a;
	var ls = _v0.b;
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'time',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(time))),
			function () {
				if (ls.$ === 'ManagerLocalStorage') {
					var authToken = ls.a.authToken;
					var manager = ls.a.manager;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'token',
							$elm$json$Json$Encode$string(authToken)),
							_Utils_Tuple2(
							'manager',
							A2($author$project$Domain$PB$encodeWithID, $author$project$Domain$encodeManager, manager))
						]);
				} else {
					var authToken = ls.a.authToken;
					var user = ls.a.user;
					var cart = ls.a.cart;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'token',
							$elm$json$Json$Encode$string(authToken)),
							_Utils_Tuple2(
							'user',
							A2($author$project$Domain$PB$encodeWithID, $author$project$Domain$tenantEncoder, user)),
							_Utils_Tuple2(
							'cart',
							A2(
								$elm$json$Json$Encode$list,
								A2($elm$core$Basics$composeR, $author$project$Domain$serviceRequestEncoder, $elm$json$Json$Encode$object),
								cart))
						]);
				}
			}()));
};
var $author$project$Session$saveLocalStorage = A2($elm$core$Basics$composeR, $author$project$Session$encodeLocalStorage, $author$project$Session$setLocalStorage);
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 'Nothing') {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 'Nothing') {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.protocol;
		if (_v0.$ === 'Http') {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.fragment,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.query,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.port_,
					_Utils_ap(http, url.host)),
				url.path)));
};
var $author$project$PopUp$remove = F2(
	function (id, _v0) {
		var popUps = _v0.a.popUps;
		var displayed = _v0.a.displayed;
		var lastID = _v0.a.lastID;
		return $author$project$PopUp$Manager(
			{
				displayed: A2(
					$elm$core$List$filter,
					$elm$core$Basics$neq(id),
					displayed),
				lastID: lastID,
				popUps: A2($elm$core$Dict$remove, lastID + 1, popUps)
			});
	});
var $author$project$PopUp$update = F2(
	function (msg, model) {
		var id = msg.a;
		return _Utils_Tuple2(
			A2($author$project$PopUp$remove, id, model),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$UI$Navbar$Open = function (a) {
	return {$: 'Open', a: a};
};
var $author$project$UI$Navbar$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(model.state, msg);
		_v0$4:
		while (true) {
			if (_v0.a.$ === 'Closed') {
				if (_v0.b.$ === 'OpenSidebar') {
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								state: $author$project$UI$Navbar$Open(false)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					break _v0$4;
				}
			} else {
				switch (_v0.b.$) {
					case 'CloseSidebar':
						var _v3 = _v0.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{state: $author$project$UI$Navbar$Closed}),
							$elm$core$Platform$Cmd$none);
					case 'Left':
						if (_v0.a.a) {
							var _v4 = _v0.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{state: $author$project$UI$Navbar$Closed}),
								$elm$core$Platform$Cmd$none);
						} else {
							break _v0$4;
						}
					case 'Interacted':
						if (!_v0.a.a) {
							var _v5 = _v0.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										state: $author$project$UI$Navbar$Open(true)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							break _v0$4;
						}
					default:
						break _v0$4;
				}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Main$updateBase = F2(
	function (wrapper, data) {
		var modified = F2(
			function (makePage, model) {
				return makePage(
					A2($author$project$Framework$updateBase, model, data));
			});
		switch (wrapper.$) {
			case 'Auth':
				var next = wrapper.a;
				var page = wrapper.b;
				var model = wrapper.c;
				return A2(
					modified,
					A2($author$project$Main$Auth, next, page),
					model);
			case 'VerifyEmail':
				var next = wrapper.a;
				var page = wrapper.b;
				var model = wrapper.c;
				return A2(
					modified,
					A2($author$project$Main$VerifyEmail, next, page),
					model);
			case 'Error':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Error(page),
					model);
			case 'Tenant':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Tenant(page),
					model);
			case 'Waiting':
				var next = wrapper.a;
				var trigger = wrapper.b;
				var page = wrapper.c;
				var model = wrapper.d;
				return A2(
					modified,
					A3($author$project$Main$Waiting, next, trigger, page),
					model);
			case 'Notification':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Notification(page),
					model);
			case 'NotificationList':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$NotificationList(page),
					model);
			case 'Contacts':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Contacts(page),
					model);
			case 'ServiceCategory':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$ServiceCategory(page),
					model);
			case 'Service':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Service(page),
					model);
			case 'Cart':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Cart(page),
					model);
			case 'Orders':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Orders(page),
					model);
			case 'Topic':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Topic(page),
					model);
			case 'Topics':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Topics(page),
					model);
			case 'ManagerAuth':
				var next = wrapper.a;
				var page = wrapper.b;
				var model = wrapper.c;
				return A2(
					modified,
					A2($author$project$Main$ManagerAuth, next, page),
					model);
			case 'Sync1C':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Sync1C(page),
					model);
			case 'Manager':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$Manager(page),
					model);
			case 'EmailConfirm':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$EmailConfirm(page),
					model);
			case 'ResetPassword':
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$ResetPassword(page),
					model);
			default:
				var page = wrapper.a;
				var model = wrapper.b;
				return A2(
					modified,
					$author$project$Main$MinibusRoute(page),
					model);
		}
	});
var $author$project$Websocket$GotNewWebsocketData = F3(
	function (a, b, c) {
		return {$: 'GotNewWebsocketData', a: a, b: b, c: c};
	});
var $author$project$Websocket$ClosedWebSocket = function (a) {
	return {$: 'ClosedWebSocket', a: a};
};
var $author$project$Websocket$OpenedWebSocket = F2(
	function (a, b) {
		return {$: 'OpenedWebSocket', a: a, b: b};
	});
var $author$project$Websocket$ReceivedMessage = F2(
	function (a, b) {
		return {$: 'ReceivedMessage', a: a, b: b};
	});
var $author$project$Websocket$decodeResponse = A2(
	$elm$json$Json$Decode$andThen,
	function (from) {
		switch (from) {
			case 'open':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Websocket$OpenedWebSocket,
					A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string));
			case 'receive':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Websocket$ReceivedMessage,
					A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value));
			case 'close':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Websocket$ClosedWebSocket,
					A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int));
			default:
				return A2(
					_Debug_todo(
						'Websocket',
						{
							start: {line: 113, column: 32},
							end: {line: 113, column: 42}
						}),
					'branch \'_\' not implemented',
					A2($elm$core$Debug$log, 'from', from));
		}
	},
	A2($elm$json$Json$Decode$field, 'from', $elm$json$Json$Decode$string));
var $author$project$Websocket$updateIn = F3(
	function (toGlobalMsg, value, manager) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Websocket$decodeResponse, value);
		if (_v0.$ === 'Err') {
			return _Debug_todo(
				'Websocket',
				{
					start: {line: 193, column: 13},
					end: {line: 193, column: 23}
				})('');
		} else {
			switch (_v0.a.$) {
				case 'OpenedWebSocket':
					var _v1 = _v0.a;
					var id = _v1.a;
					var url = _v1.b;
					return A2($elm$core$Dict$member, url, manager.opening) ? _Utils_Tuple2(
						manager,
						A2(
							$elm$core$Task$perform,
							A2(
								$elm$core$Basics$composeR,
								A2($author$project$Websocket$GotNewWebsocketData, url, id),
								toGlobalMsg),
							$elm$time$Time$now)) : _Utils_Tuple2(
						manager,
						_Debug_todo(
							'Websocket',
							{
								start: {line: 205, column: 19},
								end: {line: 205, column: 29}
							})(''));
				case 'ReceivedMessage':
					var _v2 = _v0.a;
					var id = _v2.a;
					var messageData = _v2.b;
					return _Utils_Tuple2(
						manager,
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								function (_v3) {
									var onReceive = _v3.a;
									return $elmcraft$core_extra$Cmd$Extra$perform(
										onReceive(messageData));
								},
								A2($elm$core$Dict$get, id, manager.websockets))));
				default:
					var id = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							manager,
							{
								websockets: A2($elm$core$Dict$remove, id, manager.websockets)
							}),
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								function (_v4) {
									var onClose = _v4.c;
									return $elmcraft$core_extra$Cmd$Extra$perform(onClose);
								},
								A2($elm$core$Dict$get, id, manager.websockets))));
			}
		}
	});
var $author$project$Session$updateLocalStorage = function (data) {
	return A2(
		$elm$core$Task$map,
		function (time) {
			return A2($author$project$Session$LocalStorage, time, data);
		},
		$elm$time$Time$now);
};
var $author$project$Websocket$encodeWebsocketCommand = function (command) {
	return $elm$json$Json$Encode$object(
		function () {
			switch (command.$) {
				case 'OpenWebSocket':
					var authToken = command.a;
					var endpoint = command.b;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'command',
							$elm$json$Json$Encode$string('open')),
							_Utils_Tuple2(
							'authToken',
							$elm$json$Json$Encode$string(authToken)),
							_Utils_Tuple2(
							'url',
							$elm$json$Json$Encode$string(endpoint))
						]);
				case 'SendMessage':
					var id = command.a;
					var data = command.b;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'command',
							$elm$json$Json$Encode$string('send')),
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$int(id)),
							_Utils_Tuple2('data', data)
						]);
				case 'ConfirmHealth':
					var id = command.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'command',
							$elm$json$Json$Encode$string('health')),
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$int(id))
						]);
				default:
					var id = command.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'command',
							$elm$json$Json$Encode$string('close')),
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$int(id))
						]);
			}
		}());
};
var $author$project$Websocket$wsOut = _Platform_outgoingPort('wsOut', $elm$core$Basics$identity);
var $author$project$Websocket$updateOut = F2(
	function (command, manager) {
		return A2(
			$elmcraft$core_extra$Cmd$Extra$add,
			$author$project$Websocket$wsOut(
				$author$project$Websocket$encodeWebsocketCommand(command)),
			function () {
				switch (command.$) {
					case 'OpenWebSocket':
						var url = command.b;
						var onOpen = command.c;
						var onReceive = command.d;
						var onClose = command.e;
						return _Utils_Tuple2(
							_Utils_update(
								manager,
								{
									opening: A3(
										$elm$core$Dict$insert,
										url,
										_Utils_Tuple3(onOpen, onReceive, onClose),
										manager.opening)
								}),
							$elm$core$Platform$Cmd$none);
					case 'CloseWebSocket':
						var id = command.a;
						return _Utils_Tuple2(
							_Utils_update(
								manager,
								{
									websockets: A2($elm$core$Dict$remove, id, manager.websockets)
								}),
							A2(
								$elm$core$Maybe$withDefault,
								$elm$core$Platform$Cmd$none,
								A2(
									$elm$core$Maybe$map,
									function (_v1) {
										var onClose = _v1.c;
										return $elmcraft$core_extra$Cmd$Extra$perform(onClose);
									},
									A2($elm$core$Dict$get, id, manager.websockets))));
					case 'SendMessage':
						return _Utils_Tuple2(manager, $elm$core$Platform$Cmd$none);
					default:
						var id = command.a;
						var time = command.b;
						return _Utils_Tuple2(
							_Utils_update(
								manager,
								{
									websockets: A3(
										$elm$core$Dict$update,
										id,
										$elm$core$Maybe$map(
											function (_v2) {
												var callback = _v2.a;
												var onClose = _v2.c;
												return _Utils_Tuple3(callback, time, onClose);
											}),
										manager.websockets)
								}),
							$elm$core$Platform$Cmd$none);
				}
			}());
	});
var $author$project$Websocket$mapCommand = F2(
	function (wrap, cmd) {
		switch (cmd.$) {
			case 'OpenWebSocket':
				var token = cmd.a;
				var url = cmd.b;
				var onOpen = cmd.c;
				var onReceive = cmd.d;
				var onClose = cmd.e;
				return A5(
					$author$project$Websocket$OpenWebSocket,
					token,
					url,
					A2($elm$core$Basics$composeR, onOpen, wrap),
					A2($elm$core$Basics$composeR, onReceive, wrap),
					wrap(onClose));
			case 'SendMessage':
				var id = cmd.a;
				var data = cmd.b;
				return A2($author$project$Websocket$SendMessage, id, data);
			case 'ConfirmHealth':
				var id = cmd.a;
				var time = cmd.b;
				return A2($author$project$Websocket$ConfirmHealth, id, time);
			default:
				var id = cmd.a;
				return $author$project$Websocket$CloseWebSocket(id);
		}
	});
var $author$project$Framework$mapEffect = F2(
	function (f, eff) {
		switch (eff.$) {
			case 'WSCommand':
				var cmd = eff.a;
				return $author$project$Framework$WSCommand(
					A2($author$project$Websocket$mapCommand, f, cmd));
			case 'ShowPopUp':
				var content = eff.a;
				var importance = eff.b;
				return A2($author$project$Framework$ShowPopUp, content, importance);
			case 'NoOp':
				return $author$project$Framework$NoOp;
			case 'ClearLocalStorage':
				return $author$project$Framework$ClearLocalStorage;
			case 'UpdateLocalStorage':
				var data = eff.a;
				return $author$project$Framework$UpdateLocalStorage(data);
			default:
				var effects = eff.a;
				return $author$project$Framework$Batch(
					A2(
						$elm$core$List$map,
						$author$project$Framework$mapEffect(f),
						effects));
		}
	});
var $author$project$Main$updateWith = F6(
	function (toPage, toMsg, _v0, submsg, submodel, model) {
		var f = _v0.b;
		var _v1 = function () {
			var _v2 = f.update;
			if (_v2.$ === 'PlainUpdate') {
				var upd = _v2.a;
				return function (_v3) {
					var a = _v3.a;
					var b = _v3.b;
					return _Utils_Tuple3(a, b, $author$project$Framework$NoOp);
				}(
					A2(upd, submsg, submodel));
			} else {
				var upd = _v2.a;
				return A2(upd, submsg, submodel);
			}
		}();
		var newmod = _v1.a;
		var newcmd = _v1.b;
		var eff = _v1.c;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					page: toPage(newmod)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Platform$Cmd$map, toMsg, newcmd),
						$elmcraft$core_extra$Cmd$Extra$perform(
						$author$project$Main$EffectMsg(
							A2($author$project$Framework$mapEffect, toMsg, eff)))
					])));
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		update:
		while (true) {
			var navbar = model.navbar;
			var handleEffect = F2(
				function (currentModel, effect) {
					switch (effect.$) {
						case 'NoOp':
							return _Utils_Tuple2(currentModel, $elm$core$Platform$Cmd$none);
						case 'ShowPopUp':
							var content = effect.a;
							var importance = effect.b;
							var _v1 = A3($author$project$PopUp$add, content, importance, currentModel.popUps);
							var popUps = _v1.a;
							var callback = _v1.b;
							return _Utils_Tuple2(
								_Utils_update(
									currentModel,
									{popUps: popUps}),
								A2($elm$core$Task$perform, $author$project$Main$PopUpMsg, callback));
						case 'WSCommand':
							var command = effect.a;
							return A2(
								$elm$core$Tuple$mapFirst,
								function (manager) {
									return _Utils_update(
										currentModel,
										{websockets: manager});
								},
								A2($author$project$Websocket$updateOut, command, currentModel.websockets));
						case 'ClearLocalStorage':
							var _v2 = $author$project$Main$extractCommon(currentModel.page);
							var base = _v2.a;
							return _Utils_Tuple2(
								_Utils_update(
									currentModel,
									{
										navbar: _Utils_update(
											navbar,
											{
												session: $author$project$Session$logOut(base.session)
											}),
										page: A2(
											$author$project$Main$updateBase,
											currentModel.page,
											_Utils_update(
												base,
												{
													session: $author$project$Session$logOut(base.session)
												}))
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											$author$project$Session$clearLocalStorage,
											$elmcraft$core_extra$Cmd$Extra$perform(
											$author$project$Main$SessionRefreshed(
												$elm$core$Result$Ok(
													$author$project$Session$logOut(base.session))))
										])));
						case 'UpdateLocalStorage':
							var newLocalStorageData = effect.a;
							return _Utils_Tuple2(
								currentModel,
								A2(
									$elm$core$Task$perform,
									$author$project$Main$GotLocalStorage,
									$author$project$Session$updateLocalStorage(newLocalStorageData)));
						default:
							var effectMsgs = effect.a;
							return A2(
								$elm$core$Tuple$mapSecond,
								$elm$core$Platform$Cmd$batch,
								A3(
									$elm$core$List$foldl,
									F2(
										function (eff, _v3) {
											var mod = _v3.a;
											var cmds = _v3.b;
											var _v4 = A2(handleEffect, mod, eff);
											var resModel = _v4.a;
											var resCmd = _v4.b;
											return _Utils_Tuple2(
												resModel,
												_Utils_ap(
													cmds,
													_List_fromArray(
														[resCmd])));
										}),
									_Utils_Tuple2(currentModel, _List_Nil),
									effectMsgs));
					}
				});
			var _v5 = $author$project$Main$extractCommon(model.page);
			var session = _v5.a.session;
			var _v6 = _Utils_Tuple2(model.page, msg);
			_v6$28:
			while (true) {
				switch (_v6.b.$) {
					case 'UrlRequested':
						var urlRequest = _v6.b.a;
						if (urlRequest.$ === 'Internal') {
							var url = urlRequest.a;
							return _Utils_Tuple2(
								model,
								A2(
									$elm$browser$Browser$Navigation$pushUrl,
									$author$project$Session$getKey(session),
									$elm$url$Url$toString(url)));
						} else {
							var href = urlRequest.a;
							return _Utils_Tuple2(
								model,
								$elm$browser$Browser$Navigation$load(href));
						}
					case 'UrlChanged':
						var url = _v6.b.a;
						return A2(
							$author$project$Main$changeRouteTo,
							$author$project$Route$parse(url),
							model);
					case 'DeviceClassified':
						var page = _v6.a;
						var device = _v6.b.a;
						var _v8 = $author$project$Main$extractCommon(page);
						var base = _v8.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									navbar: _Utils_update(
										navbar,
										{device: device}),
									page: A2(
										$author$project$Main$updateBase,
										page,
										_Utils_update(
											base,
											{device: device}))
								}),
							$elm$core$Platform$Cmd$none);
					case 'EffectMsg':
						var effect = _v6.b.a;
						return A2(handleEffect, model, effect);
					case 'PopUpMsg':
						var submsg = _v6.b.a;
						var _v9 = A2($author$project$PopUp$update, submsg, model.popUps);
						var popUps = _v9.a;
						var cmd = _v9.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{popUps: popUps}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$PopUpMsg, cmd));
					case 'CleanUpWebSockets':
						var now = _v6.b.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									websockets: A2($author$project$Websocket$cleanUp, now, model.websockets)
								}),
							$elm$core$Platform$Cmd$none);
					case 'WebsocketInternalMessage':
						var submsg = _v6.b.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (manager) {
								return _Utils_update(
									model,
									{websockets: manager});
							},
							A2($author$project$Websocket$innerUpdate, submsg, model.websockets));
					case 'GotWebsocketMessage':
						var value = _v6.b.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (manager) {
								return _Utils_update(
									model,
									{websockets: manager});
							},
							A3($author$project$Websocket$updateIn, $author$project$Main$WebsocketInternalMessage, value, model.websockets));
					case 'GotLocalStorage':
						var page = _v6.a;
						var value = _v6.b.a;
						var newSession = A2(
							$author$project$Session$sync,
							session,
							$elm$core$Maybe$Just(value));
						var _v10 = $author$project$Main$extractCommon(page);
						var base = _v10.a;
						var newModel = _Utils_update(
							model,
							{
								navbar: _Utils_update(
									navbar,
									{session: newSession}),
								page: A2(
									$author$project$Main$updateBase,
									page,
									_Utils_update(
										base,
										{session: newSession}))
							});
						return A2(
							$elmcraft$core_extra$Cmd$Extra$add,
							$author$project$Session$saveLocalStorage(value),
							function () {
								var _v11 = A2(
									$author$project$Main$baseUpdateToMsg,
									page,
									_Utils_update(
										base,
										{session: newSession}));
								if (_v11.$ === 'Just') {
									var message = _v11.a;
									return A2($author$project$Main$update, message, newModel);
								} else {
									return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
								}
							}());
					case 'SessionRefreshed':
						var page = _v6.a;
						var result = _v6.b.a;
						var _v12 = $author$project$Main$extractCommon(page);
						var base = _v12.a;
						if (result.$ === 'Ok') {
							var newSession = result.a;
							var newModel = _Utils_update(
								model,
								{
									navbar: _Utils_update(
										navbar,
										{session: newSession}),
									page: A2(
										$author$project$Main$updateBase,
										page,
										_Utils_update(
											base,
											{session: newSession}))
								});
							if (page.$ === 'Waiting') {
								var next = page.a;
								var _v15 = page.b;
								return A2($author$project$Main$changeRouteTo, next, newModel);
							} else {
								var _v16 = A2(
									$author$project$Main$baseUpdateToMsg,
									page,
									_Utils_update(
										base,
										{session: newSession}));
								if (_v16.$ === 'Nothing') {
									return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
								} else {
									var baseUpdateSubmsg = _v16.a;
									var $temp$msg = baseUpdateSubmsg,
										$temp$model = newModel;
									msg = $temp$msg;
									model = $temp$model;
									continue update;
								}
							}
						} else {
							var errorCode = result.a;
							switch (errorCode.$) {
								case 'Bug':
									var message = errorCode.a;
									return A2(
										$author$project$Main$changeRouteTo,
										$author$project$Route$Error(
											{
												code: $author$project$API$Error$Bug(message)
											}),
										model);
								case 'Timeout':
									var refreshSession = function () {
										var _v18 = $author$project$Session$getToken(session);
										if (_v18.$ === 'Just') {
											var token = _v18.a;
											return A2($author$project$Session$refresh, session, token);
										} else {
											return $elm$core$Task$fail(
												$author$project$API$Error$Bug('could not refresh session - token is missing'));
										}
									}();
									return _Utils_Tuple2(
										model,
										A2($elm$core$Task$attempt, $author$project$Main$SessionRefreshed, refreshSession));
								case 'NetworkError':
									return A2(
										$author$project$Main$changeRouteTo,
										$author$project$Route$Error(
											{code: $author$project$API$Error$NetworkError}),
										model);
								case 'Unauthenticated':
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												page: A2(
													$author$project$Main$updateBase,
													page,
													_Utils_update(
														base,
														{
															session: A2(
																$author$project$Session$Session,
																$author$project$Session$getKey(session),
																$author$project$Session$Guest)
														}))
											}),
										$elmcraft$core_extra$Cmd$Extra$perform(
											$author$project$Main$EffectMsg($author$project$Framework$ClearLocalStorage)));
								default:
									return A2(
										$author$project$Main$changeRouteTo,
										$author$project$Route$Error(
											{
												code: $author$project$API$Error$Bug('unexpected error on session refresh')
											}),
										model);
							}
						}
					case 'NavbarMsg':
						var submsg = _v6.b.a;
						var _v19 = A2($author$project$UI$Navbar$update, submsg, model.navbar);
						var newNavbar = _v19.a;
						var cmd = _v19.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{navbar: newNavbar}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$NavbarMsg, cmd));
					case 'AuthMsg':
						if (_v6.a.$ === 'Auth') {
							var _v20 = _v6.a;
							var next = _v20.a;
							var page = _v20.b;
							var submodel = _v20.c;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								A2($author$project$Main$Auth, next, page),
								$author$project$Main$AuthMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'VerifyEmailMsg':
						if (_v6.a.$ === 'VerifyEmail') {
							var _v21 = _v6.a;
							var next = _v21.a;
							var page = _v21.b;
							var submodel = _v21.c;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								A2($author$project$Main$VerifyEmail, next, page),
								$author$project$Main$VerifyEmailMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'TenantMsg':
						if (_v6.a.$ === 'Tenant') {
							var _v22 = _v6.a;
							var page = _v22.a;
							var submodel = _v22.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$Tenant(page),
								$author$project$Main$TenantMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'NotificationMsg':
						if (_v6.a.$ === 'Notification') {
							var _v23 = _v6.a;
							var page = _v23.a;
							var submodel = _v23.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$Notification(page),
								$author$project$Main$NotificationMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'NotificationListMsg':
						if (_v6.a.$ === 'NotificationList') {
							var _v24 = _v6.a;
							var page = _v24.a;
							var submodel = _v24.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$NotificationList(page),
								$author$project$Main$NotificationListMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'ServiceCategoryMsg':
						if (_v6.a.$ === 'ServiceCategory') {
							var _v25 = _v6.a;
							var page = _v25.a;
							var submodel = _v25.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$ServiceCategory(page),
								$author$project$Main$ServiceCategoryMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'ServiceMsg':
						if (_v6.a.$ === 'Service') {
							var _v26 = _v6.a;
							var page = _v26.a;
							var submodel = _v26.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$Service(page),
								$author$project$Main$ServiceMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'CartMsg':
						if (_v6.a.$ === 'Cart') {
							var _v27 = _v6.a;
							var page = _v27.a;
							var submodel = _v27.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$Cart(page),
								$author$project$Main$CartMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'OrdersMsg':
						if (_v6.a.$ === 'Orders') {
							var _v28 = _v6.a;
							var page = _v28.a;
							var submodel = _v28.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$Orders(page),
								$author$project$Main$OrdersMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'TopicMsg':
						if (_v6.a.$ === 'Topic') {
							var _v29 = _v6.a;
							var page = _v29.a;
							var submodel = _v29.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$Topic(page),
								$author$project$Main$TopicMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'TopicsMsg':
						if (_v6.a.$ === 'Topics') {
							var _v30 = _v6.a;
							var page = _v30.a;
							var submodel = _v30.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$Topics(page),
								$author$project$Main$TopicsMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'ManagerAuthMsg':
						if (_v6.a.$ === 'ManagerAuth') {
							var _v31 = _v6.a;
							var next = _v31.a;
							var page = _v31.b;
							var submodel = _v31.c;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								A2($author$project$Main$ManagerAuth, next, page),
								$author$project$Main$ManagerAuthMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'Sync1CMsg':
						if (_v6.a.$ === 'Sync1C') {
							var _v32 = _v6.a;
							var page = _v32.a;
							var submodel = _v32.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$Sync1C(page),
								$author$project$Main$Sync1CMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'ManagerMsg':
						if (_v6.a.$ === 'Manager') {
							var _v33 = _v6.a;
							var page = _v33.a;
							var submodel = _v33.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$Manager(page),
								$author$project$Main$ManagerMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'ConfirmEmailMsg':
						if (_v6.a.$ === 'EmailConfirm') {
							var _v34 = _v6.a;
							var page = _v34.a;
							var submodel = _v34.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$EmailConfirm(page),
								$author$project$Main$ConfirmEmailMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					case 'ResetPasswordMsg':
						if (_v6.a.$ === 'ResetPassword') {
							var _v35 = _v6.a;
							var page = _v35.a;
							var submodel = _v35.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$ResetPassword(page),
								$author$project$Main$ResetPasswordMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
					default:
						if (_v6.a.$ === 'MinibusRoute') {
							var _v36 = _v6.a;
							var page = _v36.a;
							var submodel = _v36.b;
							var submsg = _v6.b.a;
							return A6(
								$author$project$Main$updateWith,
								$author$project$Main$MinibusRoute(page),
								$author$project$Main$MinibusRouteMsg,
								page,
								submsg,
								submodel,
								model);
						} else {
							break _v6$28;
						}
				}
			}
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$browser$Browser$Document = F2(
	function (title, body) {
		return {body: body, title: title};
	});
var $author$project$UI$blurFilter = $mdgriffith$elm_ui$Element$htmlAttribute(
	$elm$html$Html$Attributes$class('with-backdrop-filter'));
var $mdgriffith$elm_ui$Internal$Model$FontFamily = F2(
	function (a, b) {
		return {$: 'FontFamily', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$fontFamily = $mdgriffith$elm_ui$Internal$Flag$flag(5);
var $mdgriffith$elm_ui$Internal$Model$renderFontClassName = F2(
	function (font, current) {
		return _Utils_ap(
			current,
			function () {
				switch (font.$) {
					case 'Serif':
						return 'serif';
					case 'SansSerif':
						return 'sans-serif';
					case 'Monospace':
						return 'monospace';
					case 'Typeface':
						var name = font.a;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					case 'ImportFont':
						var name = font.a;
						var url = font.b;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					default:
						var name = font.a.name;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
				}
			}());
	});
var $mdgriffith$elm_ui$Element$Font$family = function (families) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontFamily,
		A2(
			$mdgriffith$elm_ui$Internal$Model$FontFamily,
			A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'ff-', families),
			families));
};
var $mdgriffith$elm_ui$Internal$Model$SansSerif = {$: 'SansSerif'};
var $mdgriffith$elm_ui$Element$Font$sansSerif = $mdgriffith$elm_ui$Internal$Model$SansSerif;
var $mdgriffith$elm_ui$Internal$Model$Typeface = function (a) {
	return {$: 'Typeface', a: a};
};
var $mdgriffith$elm_ui$Element$Font$typeface = $mdgriffith$elm_ui$Internal$Model$Typeface;
var $author$project$UI$externalFont = $mdgriffith$elm_ui$Element$Font$family(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$typeface('Montserrat'),
			$mdgriffith$elm_ui$Element$Font$sansSerif
		]));
var $author$project$UI$Footer$copyright = A2(
	$mdgriffith$elm_ui$Element$paragraph,
	_List_fromArray(
		[$mdgriffith$elm_ui$Element$Font$center]),
	$elm$core$List$singleton(
		$mdgriffith$elm_ui$Element$text('© 2023-2024 | ООО УК \"Хорошая\"')));
var $author$project$UI$Footer$footer = A2(
	$mdgriffith$elm_ui$Element$column,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$alignBottom,
			$mdgriffith$elm_ui$Element$padding(
			$author$project$UI$scaled(1)),
			$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.primary),
			$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
			$author$project$UI$fontsizes.regular
		]),
	_List_fromArray(
		[$author$project$UI$Footer$copyright]));
var $mdgriffith$elm_ui$Internal$Model$OnlyDynamic = F2(
	function (a, b) {
		return {$: 'OnlyDynamic', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic = F2(
	function (a, b) {
		return {$: 'StaticRootAndDynamic', a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$AllowHover = {$: 'AllowHover'};
var $mdgriffith$elm_ui$Internal$Model$Layout = {$: 'Layout'};
var $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle = {
	backgroundColor: $elm$core$Maybe$Nothing,
	borderColor: $elm$core$Maybe$Nothing,
	shadow: $elm$core$Maybe$Just(
		{
			blur: 0,
			color: A4($mdgriffith$elm_ui$Internal$Model$Rgba, 155 / 255, 203 / 255, 1, 1),
			offset: _Utils_Tuple2(0, 0),
			size: 3
		})
};
var $mdgriffith$elm_ui$Internal$Model$optionsToRecord = function (options) {
	var combine = F2(
		function (opt, record) {
			switch (opt.$) {
				case 'HoverOption':
					var hoverable = opt.a;
					var _v4 = record.hover;
					if (_v4.$ === 'Nothing') {
						return _Utils_update(
							record,
							{
								hover: $elm$core$Maybe$Just(hoverable)
							});
					} else {
						return record;
					}
				case 'FocusStyleOption':
					var focusStyle = opt.a;
					var _v5 = record.focus;
					if (_v5.$ === 'Nothing') {
						return _Utils_update(
							record,
							{
								focus: $elm$core$Maybe$Just(focusStyle)
							});
					} else {
						return record;
					}
				default:
					var renderMode = opt.a;
					var _v6 = record.mode;
					if (_v6.$ === 'Nothing') {
						return _Utils_update(
							record,
							{
								mode: $elm$core$Maybe$Just(renderMode)
							});
					} else {
						return record;
					}
			}
		});
	var andFinally = function (record) {
		return {
			focus: function () {
				var _v0 = record.focus;
				if (_v0.$ === 'Nothing') {
					return $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle;
				} else {
					var focusable = _v0.a;
					return focusable;
				}
			}(),
			hover: function () {
				var _v1 = record.hover;
				if (_v1.$ === 'Nothing') {
					return $mdgriffith$elm_ui$Internal$Model$AllowHover;
				} else {
					var hoverable = _v1.a;
					return hoverable;
				}
			}(),
			mode: function () {
				var _v2 = record.mode;
				if (_v2.$ === 'Nothing') {
					return $mdgriffith$elm_ui$Internal$Model$Layout;
				} else {
					var actualMode = _v2.a;
					return actualMode;
				}
			}()
		};
	};
	return andFinally(
		A3(
			$elm$core$List$foldr,
			combine,
			{focus: $elm$core$Maybe$Nothing, hover: $elm$core$Maybe$Nothing, mode: $elm$core$Maybe$Nothing},
			options));
};
var $mdgriffith$elm_ui$Internal$Model$toHtml = F2(
	function (mode, el) {
		switch (el.$) {
			case 'Unstyled':
				var html = el.a;
				return html($mdgriffith$elm_ui$Internal$Model$asEl);
			case 'Styled':
				var styles = el.a.styles;
				var html = el.a.html;
				return A2(
					html,
					mode(styles),
					$mdgriffith$elm_ui$Internal$Model$asEl);
			case 'Text':
				var text = el.a;
				return $mdgriffith$elm_ui$Internal$Model$textElement(text);
			default:
				return $mdgriffith$elm_ui$Internal$Model$textElement('');
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderRoot = F3(
	function (optionList, attributes, child) {
		var options = $mdgriffith$elm_ui$Internal$Model$optionsToRecord(optionList);
		var embedStyle = function () {
			var _v0 = options.mode;
			if (_v0.$ === 'NoStaticStyleSheet') {
				return $mdgriffith$elm_ui$Internal$Model$OnlyDynamic(options);
			} else {
				return $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic(options);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Internal$Model$toHtml,
			embedStyle,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				attributes,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[child]))));
	});
var $mdgriffith$elm_ui$Internal$Model$rootStyle = function () {
	var families = _List_fromArray(
		[
			$mdgriffith$elm_ui$Internal$Model$Typeface('Open Sans'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Helvetica'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Verdana'),
			$mdgriffith$elm_ui$Internal$Model$SansSerif
		]);
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$bgColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0)),
				'background-color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1)),
				'color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontSize,
			$mdgriffith$elm_ui$Internal$Model$FontSize(20)),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontFamily,
			A2(
				$mdgriffith$elm_ui$Internal$Model$FontFamily,
				A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'font-', families),
				families))
		]);
}();
var $mdgriffith$elm_ui$Element$layoutWith = F3(
	function (_v0, attrs, child) {
		var options = _v0.options;
		return A3(
			$mdgriffith$elm_ui$Internal$Model$renderRoot,
			options,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass(
					A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[$mdgriffith$elm_ui$Internal$Style$classes.root, $mdgriffith$elm_ui$Internal$Style$classes.any, $mdgriffith$elm_ui$Internal$Style$classes.single]))),
				_Utils_ap($mdgriffith$elm_ui$Internal$Model$rootStyle, attrs)),
			child);
	});
var $mdgriffith$elm_ui$Element$layout = $mdgriffith$elm_ui$Element$layoutWith(
	{options: _List_Nil});
var $author$project$UI$Navbar$navbarStub = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.transparent)
		]),
	A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 1920, $mdgriffith$elm_ui$Element$fill)),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(
					$author$project$UI$scaled(8))),
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(1)),
				A2(
				$mdgriffith$elm_ui$Element$paddingXY,
				$author$project$UI$scaled(-1),
				0),
				$author$project$UI$preventSelect
			]),
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$none])));
var $author$project$PopUp$view = function (_v0) {
	var popUps = _v0.a.popUps;
	var displayed = _v0.a.displayed;
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(250)),
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(-1)),
				$mdgriffith$elm_ui$Element$alignRight,
				$mdgriffith$elm_ui$Element$alignBottom,
				$mdgriffith$elm_ui$Element$padding(
				$author$project$UI$scaled(1)),
				$author$project$UI$preventSelect
			]),
		A2(
			$elm$core$List$map,
			function (_v1) {
				var id = _v1.a;
				var content = _v1.b;
				var importance = _v1.c;
				var _v2 = function () {
					if (importance.$ === 'Unimportant') {
						return _Utils_Tuple3($author$project$UI$colors.neutral_0, $author$project$UI$colors.neutral_100, $author$project$UI$colors.primary);
					} else {
						return _Utils_Tuple3($author$project$UI$colors.primary, $author$project$UI$colors.neutral_0, $author$project$UI$colors.neutral_0);
					}
				}();
				var bgColor = _v2.a;
				var fontColor = _v2.b;
				var titleColor = _v2.c;
				return A2(
					$author$project$UI$Card$contentCard,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(bgColor),
							$mdgriffith$elm_ui$Element$Font$color(fontColor),
							$mdgriffith$elm_ui$Element$Border$color(titleColor),
							$mdgriffith$elm_ui$Element$Events$onClick(
							$author$project$PopUp$ClosePopUp(id)),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(-1))
						]),
					function () {
						if (content.$ === 'PlainText') {
							var title = content.a;
							var body = content.b;
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$bold,
											$mdgriffith$elm_ui$Element$Font$color(titleColor)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(title)
										])),
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(body)
										]))
								]);
						} else {
							var rich = content.a;
							return _List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, rich)
								]);
						}
					}());
			},
			A2(
				$elm$core$List$filterMap,
				function (id) {
					return A2($elm$core$Dict$get, id, popUps);
				},
				displayed)));
};
var $author$project$UI$Navbar$CloseSidebar = {$: 'CloseSidebar'};
var $author$project$UI$Navbar$NoOp = {$: 'NoOp'};
var $andrewMacmurray$elm_simple_animation$Simple$Animation$Animated$ui = F5(
	function (options, node_, anim, attrs, els) {
		return A2(
			node_,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						options.behindContent(
						options.html(
							$andrewMacmurray$elm_simple_animation$Simple$Animation$Animated$toStylesheet_(anim))),
						options.htmlAttribute(
						$elm$html$Html$Attributes$class(
							$andrewMacmurray$elm_simple_animation$Internal$Animation$name_(anim)))
					]),
				attrs),
			els);
	});
var $author$project$UI$Animations$animatedUi = $andrewMacmurray$elm_simple_animation$Simple$Animation$Animated$ui(
	{behindContent: $mdgriffith$elm_ui$Element$behindContent, html: $mdgriffith$elm_ui$Element$html, htmlAttribute: $mdgriffith$elm_ui$Element$htmlAttribute});
var $andrewMacmurray$elm_simple_animation$Simple$Animation$fromTo = F3(
	function (o, from_, to_) {
		return $andrewMacmurray$elm_simple_animation$Simple$Animation$toAnimation(
			$andrewMacmurray$elm_simple_animation$Simple$Animation$Stepped(
				{
					options: o.options,
					startAt: from_,
					steps: _List_fromArray(
						[
							A2($andrewMacmurray$elm_simple_animation$Simple$Animation$step, o.duration, to_)
						])
				}));
	});
var $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$Raw = F2(
	function (a, b) {
		return {$: 'Raw', a: a, b: b};
	});
var $andrewMacmurray$elm_simple_animation$Simple$Animation$Property$property = $andrewMacmurray$elm_simple_animation$Internal$Animation$Property$Raw;
var $author$project$UI$Animations$slideLeft = A3(
	$andrewMacmurray$elm_simple_animation$Simple$Animation$fromTo,
	{duration: 100, options: _List_Nil},
	_List_fromArray(
		[
			A2($andrewMacmurray$elm_simple_animation$Simple$Animation$Property$property, 'transform', 'translateX(100%)')
		]),
	_List_fromArray(
		[
			A2($andrewMacmurray$elm_simple_animation$Simple$Animation$Property$property, 'transform', 'translateX(0)')
		]));
var $mdgriffith$elm_ui$Internal$Model$Active = {$: 'Active'};
var $mdgriffith$elm_ui$Internal$Flag$active = $mdgriffith$elm_ui$Internal$Flag$flag(32);
var $mdgriffith$elm_ui$Element$mouseDown = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$active,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			$mdgriffith$elm_ui$Internal$Model$Active,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $author$project$UI$withAltStyle = F2(
	function (altStyle, attrs) {
		return _Utils_ap(
			attrs,
			A2(
				$elm$core$List$map,
				function (f) {
					return f(altStyle);
				},
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$mouseOver, $mdgriffith$elm_ui$Element$focused, $mdgriffith$elm_ui$Element$mouseDown])));
	});
var $author$project$UI$Navbar$modal = F2(
	function (model, wrapMsg) {
		var row = F3(
			function (icon, text, url) {
				return function (x) {
					return A2(
						$mdgriffith$elm_ui$Element$link,
						A2(
							$author$project$UI$withAltStyle,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.primary)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Events$onClick($author$project$UI$Navbar$CloseSidebar),
									$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_100),
									$author$project$UI$transitionDuration($author$project$UI$durations.fast)
								])),
						{label: x, url: url});
				}(
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(
								$author$project$UI$scaled(1))
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_Nil,
								A2(
									$author$project$UI$materialIcon,
									icon,
									$author$project$UI$scaled(6))),
								$mdgriffith$elm_ui$Element$text(text)
							])));
			});
		var ifManager = function (el) {
			var _v5 = $author$project$Session$getViewer(model.session);
			if ((_v5.$ === 'Authenticated') && (_v5.a.b.$ === 'ManagerLocalStorage')) {
				var _v6 = _v5.a;
				return el;
			} else {
				return $mdgriffith$elm_ui$Element$none;
			}
		};
		return A2(
			$author$project$Framework$ModalContent,
			$author$project$Framework$TintedModal(
				wrapMsg($author$project$UI$Navbar$CloseSidebar)),
			A2(
				$mdgriffith$elm_ui$Element$map,
				wrapMsg,
				A4(
					$author$project$UI$Animations$animatedUi,
					$mdgriffith$elm_ui$Element$column,
					$author$project$UI$Animations$slideLeft,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$padding(
							$author$project$UI$scaled(1)),
							$mdgriffith$elm_ui$Element$spacing(
							$author$project$UI$scaled(1)),
							$author$project$UI$stopClickPropagation($author$project$UI$Navbar$NoOp),
							$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.neutral_0)
						]),
					_List_fromArray(
						[
							A2(
							$elm$core$Maybe$withDefault,
							$mdgriffith$elm_ui$Element$none,
							function () {
								var _v0 = $author$project$Session$getViewer(model.session);
								if (_v0.$ === 'Guest') {
									return A2(
										$elm$core$Maybe$map,
										A2(row, 'login', 'Вход и регистрация'),
										$author$project$Route$toString(
											A2(
												$author$project$Route$Auth,
												{next: '/'},
												$author$project$Route$NotificationList(
													{}))));
								} else {
									if (_v0.a.b.$ === 'TenantLocalStorage') {
										var _v1 = _v0.a;
										var user = _v1.b.a.user;
										return A2(
											$elm$core$Maybe$map,
											A2(row, 'account_circle', 'Профиль'),
											$author$project$Route$toString(
												$author$project$Route$Tenant(
													{userId: user.meta.id})));
									} else {
										var _v2 = _v0.a;
										var manager = _v2.b.a.manager;
										return A2(
											$elm$core$Maybe$map,
											A2(row, 'account_circle', 'Профиль'),
											$author$project$Route$toString(
												$author$project$Route$Manager(
													{managerID: manager.meta.id})));
									}
								}
							}()),
							A3(row, 'notifications', 'Новости и уведомления', '/'),
							function () {
							var _v3 = $author$project$Session$getViewer(model.session);
							if ((_v3.$ === 'Authenticated') && (_v3.a.b.$ === 'ManagerLocalStorage')) {
								var _v4 = _v3.a;
								return $mdgriffith$elm_ui$Element$none;
							} else {
								return A3(row, 'shopping_cart', 'Корзина', '/cart');
							}
						}(),
							A3(row, 'store', 'Услуги', '/service-category/'),
							A3(row, 'history', 'Заказы', '/orders'),
							A3(row, 'chat', 'Обращения', '/topic/'),
							A3(row, 'call', 'Контакты', '/contacts'),
							ifManager(
							A3(row, 'sync', 'Синхронизация с 1С', '/manage/sync')),
							A3(row, 'directions_bus', 'Маршрут №23', '/minibuses')
						]))));
	});
var $author$project$UI$Navbar$layout = A2(
	$elm$core$Basics$composeR,
	$mdgriffith$elm_ui$Element$row(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 1920, $mdgriffith$elm_ui$Element$fill)),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(
					$author$project$UI$scaled(8))),
				$mdgriffith$elm_ui$Element$spacing(
				$author$project$UI$scaled(1)),
				A2(
				$mdgriffith$elm_ui$Element$paddingXY,
				$author$project$UI$scaled(-1),
				0),
				$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
				$mdgriffith$elm_ui$Element$Font$bold,
				$author$project$UI$preventSelect
			])),
	$mdgriffith$elm_ui$Element$el(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Background$color($author$project$UI$colors.primary)
			])));
var $author$project$UI$Navbar$OpenSidebar = {$: 'OpenSidebar'};
var $author$project$UI$Navbar$sandwich = function (model) {
	var _v0 = function () {
		var _v1 = model.state;
		if (_v1.$ === 'Closed') {
			return _Utils_Tuple2($author$project$UI$Navbar$OpenSidebar, 'menu');
		} else {
			return _Utils_Tuple2($author$project$UI$Navbar$CloseSidebar, 'close');
		}
	}();
	var message = _v0.a;
	var icon = _v0.b;
	return A2(
		$mdgriffith$elm_ui$Element$Input$button,
		A2(
			$author$project$UI$withAltStyle,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_100)
				]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignRight,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_0),
					$mdgriffith$elm_ui$Element$padding(
					$author$project$UI$scaled(-1)),
					$author$project$UI$transitionDuration($author$project$UI$durations.fast)
				])),
		{
			label: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
				A2(
					$author$project$UI$materialIcon,
					icon,
					$author$project$UI$scaled(6))),
			onPress: $elm$core$Maybe$Just(message)
		});
};
var $author$project$UI$brandFont = $mdgriffith$elm_ui$Element$Font$family(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$typeface('Days One'),
			$mdgriffith$elm_ui$Element$Font$sansSerif
		]));
var $author$project$UI$logo = function (size) {
	return A2(
		$mdgriffith$elm_ui$Element$image,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(size),
				$mdgriffith$elm_ui$Element$height(size),
				$mdgriffith$elm_ui$Element$htmlAttribute(
				A2($elm$html$Html$Attributes$style, 'filter', 'invert()'))
			]),
		{description: 'Логотип', src: require('/build/front/public/logo_black.svg')});
};
var $author$project$UI$Navbar$siteName = A2(
	$mdgriffith$elm_ui$Element$link,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$alignLeft,
			$mdgriffith$elm_ui$Element$centerY,
			$author$project$UI$fontsizes.big,
			$mdgriffith$elm_ui$Element$mouseOver(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_100)
				])),
			$mdgriffith$elm_ui$Element$focused(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$UI$colors.neutral_100)
				])),
			$author$project$UI$transitionDuration($author$project$UI$durations.medium),
			$author$project$UI$stopClickPropagation($author$project$UI$Navbar$CloseSidebar)
		]),
	{
		label: A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(
					$author$project$UI$scaled(1)),
					$author$project$UI$brandFont
				]),
			_List_fromArray(
				[
					$author$project$UI$logo(
					$mdgriffith$elm_ui$Element$px(
						$author$project$UI$scaled(7))),
					$mdgriffith$elm_ui$Element$text('УК «Хорошая»')
				])),
		url: '/'
	});
var $author$project$UI$Navbar$narrowLayout = function (model) {
	return $author$project$UI$Navbar$layout(
		_List_fromArray(
			[
				$author$project$UI$Navbar$siteName,
				$author$project$UI$Navbar$sandwich(model)
			]));
};
var $author$project$UI$Navbar$navbar = function (model) {
	var _v0 = _Utils_Tuple2(model.device._class, model.device.orientation);
	return $author$project$UI$Navbar$narrowLayout(model);
};
var $author$project$UI$Navbar$view = F2(
	function (model, wrapMsg) {
		return _Utils_Tuple2(
			$author$project$UI$Navbar$navbar(model),
			function () {
				var _v0 = model.state;
				if (_v0.$ === 'Closed') {
					return $elm$core$Maybe$Nothing;
				} else {
					return $elm$core$Maybe$Just(
						A2($author$project$UI$Navbar$modal, model, wrapMsg));
				}
			}());
	});
var $author$project$Main$renderPage = F4(
	function (_v0, toMsg, submodel, model) {
		var f = _v0.b;
		var showOverEverything = function (overlays) {
			return A3(
				$elm$core$List$foldl,
				$elm$core$Basics$apL,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$List$map,
					function (overlay) {
						return $mdgriffith$elm_ui$Element$el(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$htmlAttribute(
									A2($elm$html$Html$Attributes$style, 'pointer-events', 'none')),
									$mdgriffith$elm_ui$Element$inFront(overlay)
								]));
					},
					overlays));
		};
		var modalsLayer = function (modal) {
			if (modal.$ === 'Nothing') {
				return $mdgriffith$elm_ui$Element$none;
			} else {
				var _v6 = modal.a;
				var displayType = _v6.a;
				var content = _v6.b;
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$inFront(content)
							]),
						function () {
							switch (displayType.$) {
								case 'TintedModal':
									var callback = displayType.a;
									return _List_fromArray(
										[
											$author$project$UI$blurFilter,
											$mdgriffith$elm_ui$Element$Events$onClick(callback)
										]);
								case 'UntintedModal':
									var callback = displayType.a;
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Events$onClick(callback)
										]);
								default:
									return _List_fromArray(
										[$author$project$UI$blurFilter]);
							}
						}()),
					$mdgriffith$elm_ui$Element$none);
			}
		};
		var _v1 = function () {
			var _v2 = f.view;
			if (_v2.$ === 'PlainView') {
				var viewFunction = _v2.a;
				return _Utils_Tuple2(
					viewFunction(submodel),
					$elm$core$Maybe$Nothing);
			} else {
				var wrapMsg = _v2.a;
				var viewFunction = _v2.b;
				return A2(viewFunction, submodel, wrapMsg);
			}
		}();
		var pageContent = _v1.a;
		var pageModalContent = _v1.b;
		var _v3 = A2($author$project$UI$Navbar$view, model.navbar, $author$project$Main$NavbarMsg);
		var navbarContent = _v3.a;
		var navbarModalContent = _v3.b;
		var absolutePositionLayer = showOverEverything(
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Main$PopUpMsg,
					$author$project$PopUp$view(model.popUps)),
					A2($mdgriffith$elm_ui$Element$map, $author$project$Main$NavbarMsg, navbarContent),
					modalsLayer(navbarModalContent),
					modalsLayer(pageModalContent)
				]));
		return A2(
			$elm$browser$Browser$Document,
			f.title(submodel),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$layout,
					_List_fromArray(
						[
							$author$project$UI$externalFont,
							$author$project$UI$fontsizes.regular,
							$mdgriffith$elm_ui$Element$inFront(absolutePositionLayer)
						]),
					A2(
						$mdgriffith$elm_ui$Element$column,
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							function () {
								var _v4 = model.page;
								if (_v4.$ === 'MinibusRoute') {
									return _List_Nil;
								} else {
									return _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(
											$author$project$UI$scaled(-1))
										]);
								}
							}()),
						_List_fromArray(
							[
								$author$project$UI$Navbar$navbarStub,
								A2($mdgriffith$elm_ui$Element$map, toMsg, pageContent),
								A2($mdgriffith$elm_ui$Element$map, $elm$core$Basics$never, $author$project$UI$Footer$footer)
							])))
				]));
	});
var $author$project$Main$view = function (model) {
	return function () {
		var _v0 = model.page;
		switch (_v0.$) {
			case 'Auth':
				var page = _v0.b;
				var submodel = _v0.c;
				return A3($author$project$Main$renderPage, page, $author$project$Main$AuthMsg, submodel);
			case 'VerifyEmail':
				var page = _v0.b;
				var submodel = _v0.c;
				return A3($author$project$Main$renderPage, page, $author$project$Main$VerifyEmailMsg, submodel);
			case 'Error':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $elm$core$Basics$never, submodel);
			case 'Tenant':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$TenantMsg, submodel);
			case 'Waiting':
				var page = _v0.c;
				var submodel = _v0.d;
				return A3($author$project$Main$renderPage, page, $elm$core$Basics$never, submodel);
			case 'Notification':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$NotificationMsg, submodel);
			case 'NotificationList':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$NotificationListMsg, submodel);
			case 'Contacts':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $elm$core$Basics$never, submodel);
			case 'ServiceCategory':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$ServiceCategoryMsg, submodel);
			case 'Service':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$ServiceMsg, submodel);
			case 'Cart':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$CartMsg, submodel);
			case 'Orders':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$OrdersMsg, submodel);
			case 'Topic':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$TopicMsg, submodel);
			case 'Topics':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$TopicsMsg, submodel);
			case 'ManagerAuth':
				var page = _v0.b;
				var submodel = _v0.c;
				return A3($author$project$Main$renderPage, page, $author$project$Main$ManagerAuthMsg, submodel);
			case 'Sync1C':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$Sync1CMsg, submodel);
			case 'Manager':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$ManagerMsg, submodel);
			case 'EmailConfirm':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$ConfirmEmailMsg, submodel);
			case 'ResetPassword':
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$ResetPasswordMsg, submodel);
			default:
				var page = _v0.a;
				var submodel = _v0.b;
				return A3($author$project$Main$renderPage, page, $author$project$Main$MinibusRouteMsg, submodel);
		}
	}()(model);
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{init: $author$project$Main$init, onUrlChange: $author$project$Main$UrlChanged, onUrlRequest: $author$project$Main$UrlRequested, subscriptions: $author$project$Main$subscriptions, update: $author$project$Main$update, view: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));