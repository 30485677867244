import { ElmWebsocketWrapper } from './websockets.js';
import { registerRichEditor } from './editor.js';
import { registerMinibusMap } from './map.js';

require('../public/logo.svg')
require('../public/logo_black.svg')
require('../public/logo_maskable.png')
require('../public/logo_512.png')
require('../public/logo_1024.png')

require('../public/styles.css');

import { Elm } from '../elm/Main.elm';

const storedData = localStorage.getItem('elm-state');
try {
  var app = Elm.Main.init({ flags: JSON.parse(storedData) });
} catch (e) {
  var app = Elm.Main.init({ flags: null });
}

app.ports.setLocalStorage.subscribe(function(state) {
  localStorage.setItem('elm-state', JSON.stringify(state));
});

new ElmWebsocketWrapper(
  app.ports.wsOut,
  app.ports.wsIn,
);

registerRichEditor(
  app.ports.requestGetContent,
  app.ports.receiveContent,
)

registerMinibusMap()
